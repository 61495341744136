<div class="modal-header">
  <h5 class="modal-title">Chi tiết câu hỏi</h5>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
  (click)="activeModal.dismiss()"><i class="fas fa-times"></i>
</button>
</div>
<div class="modal-body">
<form role="form" [formGroup]="questionForm">
  <!-- <div class="btn-group">
    <button type="button" class="btn btn-default" [disabled]="!formEnable" [ngStyle]="{'background-color': isLevel1 ? '#58FA58' : ''}" (click)="changeLevel(1)">Dễ</button>
    <button type="button" class="btn btn-default" [disabled]="!formEnable" [ngStyle]="{'background-color': isLevel2 ? '#FFBF00' : ''}" (click)="changeLevel(2)">Trung bình</button>
    <button type="button" class="btn btn-default" [disabled]="!formEnable" [ngStyle]="{'background-color': isLevel3 ? '#FE642E' : ''}" (click)="changeLevel(3)">Khó</button>
  </div> -->
  <label for="">Chủ đề câu hỏi</label>
  <select name="level" class="form-control" formControlName="level">
    <option value>Chọn chủ đề câu hỏi</option>
    <option [value]="topic.value" *ngFor="let topic of TOPIC_CONST">
      {{topic.lable}}
    </option>
  </select>
  <div
    *ngIf="questionForm.get('level')!.invalid && (questionForm.get('level')!.dirty || questionForm.get('level')!.touched)">
    <small class="text-danger" *ngIf="questionForm.get('level')?.errors?.required">
      {{ 'error.validate.input_required' | translate }}
    </small>
  </div>
  <div class="col-12 mt-3 overflow-auto" style="height: 50vh;">
      <div class="row mt-3 mb-3">
        <div class="col-md-5 text-right">
          <label for="">Chọn chuyên mục</label>
        </div>
        <div class="col-md-3">
          <select class="custom-select" formControlName="categoryid" name="categoryid">
          <option *ngFor="let item of categoryMap | keyvalue" [value]="item.key">{{item.value}}</option>
          </select>
        </div>
      </div>
        <!-- /.card-header -->
         <h6 class="mt-3">Nhập nội dung câu hỏi</h6>
         <div class="card-body" style="display: block;">
          <div class="row">
          </div>
          <div class="row mt-2">
            <div class="col-md-3 text-right">
              <p>Nhập nội dung <span class="text-danger"> *</span></p>
            </div>
            <div class="col-md-9">
              <ckeditor [editor]="Editor" formControlName="questiontext" [config]="questionEditorConfig"></ckeditor>
              <div *ngIf="questionForm.get('questiontext')!.invalid && (questionForm.get('questiontext')!.dirty || questionForm.get('questiontext')!.touched)">
                <small class="text-danger" *ngIf="questionForm.get('questiontext')?.errors?.required">
                  {{ 'error.validate.input_required' | translate }}
                </small>
                <small class="text-danger" *ngIf="questionForm.get('questiontext')?.errors?.isBlank">
                  {{ 'error.validate.input_required' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3 text-right">
              <p>Đánh dấu câu hỏi</p>
            </div>
            <div class="col-md-1">
              <input type="text" class="form-control " name="defaultmark" formControlName="defaultmark" value="1" size="7">
            </div>
          </div>
          <div class="row mt-2">
              <div class="col-md-3 text-right">
              <p>Lựa chọn kiểu đáp án <span class="text-danger"> *</span></p>
            </div>
            <div class="col-md-2">
              <select class="custom-select" formControlName="single" name="single" *ngIf="questionInput.qtype === questionTypeMulti">
                <option value="1" selected="">01 đáp án</option>
              </select>
              <select class="custom-select" formControlName="single" name="single" *ngIf="questionInput.qtype === questionTypeMultiset">
                <option value="0">Nhiều đáp án</option>
              </select>
              <select class="custom-select" formControlName="answercorrect" name="answercorrect" *ngIf="questionInput.qtype !== questionTypeMulti && questionInput.qtype !== questionTypeMultiset">
                <option value="0">Sai</option>
                <option value="1" selected="">Đúng</option>
              </select>
            </div>
          </div>
          <div class="row mt-2" *ngIf="questionInput.qtype === questionTypeMulti || questionInput.qtype === questionTypeMultiset">
              <div class="col-md-3 text-right">
              <p >Định dạng đáp án <span class="text-danger"> *</span></p>
            </div>
            <div class="col-md-2">
              <select class="custom-select" formControlName="answernumbering" name="answernumbering">
                <option value="ABCD" selected="">A., B., C., ...</option>
                <option value="abc">a., b., c., ...</option>
                <option value="123">1., 2., 3., ...</option>
                <option value="iii">i., ii., iii., ...</option>
                <option value="IIII">I., II., III., ...</option>
                <option value="none">-Không chọn -</option>
              </select>
            </div>
      </div>
    </div>
    <hr>
    <div formArrayName="answer" *ngIf="answer">
    <div *ngFor="let item of answer.controls; let i=index">
      <div class="col-12 mt-3" [formGroupName]="i">
        <!-- /.card-header -->
         <h6 class="mt-3">Đáp án {{i+1}}</h6>
        <div class="card-body" style="display: block;">
              <div class="row mt-2">
            <div class="col-md-3 text-right">
              <p>Nhập nội dung <span class="text-danger"> *</span></p>
            </div>
            <div class="col-md-9">
              <ckeditor [editor]="Editor" formControlName="text" (ngModelChange)="onChangeCkeditorAnswer($event, i)" [config]="answerEditorConfig"></ckeditor>
            </div>
          </div>
               <div class="row mt-2">
              <div class="col-md-3 text-right">
              <p >Điểm số <span class="text-danger"> *</span></p>
            </div>
            <!-- <div class="col-md-2" *ngIf="questionInput.qtype === questionTypeMulti">
              <select class="custom-select" formControlName="grade" name="grade">
                <option value="0.0" selected="">None</option>
                <option value="1.0">100%</option>
                <option value="0.9">90%</option>
                <option value="0.8333333">83.33333%</option>
                <option value="0.8">80%</option>
                <option value="0.75">75%</option>
                <option value="0.7">70%</option>
                <option value="0.6666667">66.66667%</option>
                <option value="0.6">60%</option>
                <option value="0.5">50%</option>
                <option value="0.4">40%</option>
                <option value="0.3333333">33.33333%</option>
                <option value="0.3">30%</option>
                <option value="0.25">25%</option>
                <option value="0.2">20%</option>
                <option value="0.1666667">16.66667%</option>
                <option value="0.1428571">14.28571%</option>
                <option value="0.125">12.5%</option>
                <option value="0.1111111">11.11111%</option>
                <option value="0.1">10%</option>
                <option value="0.05">5%</option>
                <option value="-0.05">-5%</option>
                <option value="-0.1">-10%</option>
                <option value="-0.1111111">-11.11111%</option>
                <option value="-0.125">-12.5%</option>
                <option value="-0.1428571">-14.28571%</option>
                <option value="-0.1666667">-16.66667%</option>
                <option value="-0.2">-20%</option>
                <option value="-0.25">-25%</option>
                <option value="-0.3">-30%</option>
                <option value="-0.3333333">-33.33333%</option>
                <option value="-0.4">-40%</option>
                <option value="-0.5">-50%</option>
                <option value="-0.6">-60%</option>
                <option value="-0.6666667">-66.66667%</option>
                <option value="-0.7">-70%</option>
                <option value="-0.75">-75%</option>
                <option value="-0.8">-80%</option>
                <option value="-0.8333333">-83.33333%</option>
                <option value="-0.9">-90%</option>
                <option value="-1.0">-100%</option>
              </select>
            </div> -->
            <div class="col-md-2" *ngIf="questionInput.qtype === questionTypeMultiset || questionInput.qtype === questionTypeMulti">
              <select class="custom-select" formControlName="grade" name="grade">
                <option value="0.0" selected="">None</option>
                <option value="1.0">100%</option>
              </select>
            </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-3 text-center">
      </div>
      <div class="col-md-6 text-left">
          <button type="submit" *ngIf="i > 0" (click)="removeAnswer(i)" [disabled]="!formEnable" class="btn btn-danger btn-sm"><i class="nav-icon fas fa-plus mr-1"></i>Xóa</button>
      </div>
  </div>
    <hr>
    </div>
    </div>
  </div>
      <div class="row mt-2" *ngIf="questionInput.qtype === questionTypeMulti || questionInput.qtype === questionTypeMultiset">
          <div class="col-md-3 text-center">
          </div>
          <div class="col-md-6 text-left">
              <button type="submit" (click)="addAnswer()" [disabled]="!formEnable" class="btn btn-secondary btn-sm"><i class="nav-icon fas fa-plus mr-1"></i>Thêm đáp án</button>
          </div>
      </div>
  </div>
  <div class="card-footer text-center">
    <button type="submit" class="btn btn-default mr-2" *ngIf="!formEnable && !isUpdate" (click)="activeModal.dismiss()" >Đóng</button>
    <button type="submit" class="btn btn-primary mr-2" *ngIf="!formEnable" (click)="onUpdateQues()" >Cập nhật</button>
    <button type="submit" class="btn btn-default mr-2" *ngIf="isUpdate" (click)="activeModal.dismiss()" >Hủy</button>
    <button type="submit" class="btn btn-default mr-2" *ngIf="formEnable && !isUpdate" (click)="cancelUpdateQues()">Hủy</button>
    <button type="submit" class="btn btn-success" *ngIf="formEnable" [disabled]="btnSaveDisable" (click)="updateQues()" >Lưu lại</button>

  </div>
  </form>
</div>


