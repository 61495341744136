import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ModuleModel} from '../../../shared/model/module.model';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {IntroeditorModel} from '../../../shared/model/introeditor.model';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import CommonUtil from '../../../shared/utils/common-util';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {LIST_HOURS, LIST_MINUTES} from '../../../shared/constants/base.constant';
import * as moment from 'moment';
import {TopicService} from '../../../shared/services/topic.service';
import {CustomValidators} from '../../../shared/utils/custom-validators';
import {BbbRecording} from '../../../shared/model/bbb-recording.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';

@Component({
  selector: 'app-bigbluebutton-content',
  templateUrl: './bigbluebutton-content.component.html',
  styleUrls: ['./bigbluebutton-content.component.css']
})
export class BigbluebuttonContentComponent implements OnInit, OnChanges {

  @Output() reLoadTopics = new EventEmitter<number>();
  @Output() hasChange = new EventEmitter<boolean>();
  @Input() moduleInput: ModuleModel;
  @Input() courseId: number;
  @Input() section: number;
  @Input() courseStartDate: any;
  @Input() courseEndDate: any;
  @Input() courseReadOnly: boolean;
  @Input() courseFinished: boolean;

  // for datetime picker
  dtpCourseStartDate: Date;
  dtpCourseEndDate: Date;

  public Editor = ClassicEditor;

  editorConfig = CKEDITOR_CONFIG.DEFAULT;

  courseModule: ModuleInfo = {
    name: '',
    course: this.courseId,
    section: this.section,
    introeditor: null,
    modulename: 'bigbluebuttonbn',
  };
  minCloseDate: Date;
  introeditor: IntroeditorModel;

  currentDate = new Date();

  hoursList = LIST_HOURS.filter(hour => hour.value !== '');
  minutesList = LIST_MINUTES.filter(minute => minute.value !== '');
  isNew = false;
  isCreateOrEdit = false;
  openingtime: Date;
  closingtime: Date;
  teachers = '';
  bbbRecordings: BbbRecording[] = [];

  formModule: FormGroup;


  constructor(
    private toastrService: ToastrCustomService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private topicService: TopicService,
    private modalService: NgbModal,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDefaulTime();
    if (this.moduleInput) {
      // Cap nhat du lieu
      this.spinner.show();
      this.isNew = false;
      this.isCreateOrEdit = false;
      this.topicService.getModuleDetail(this.moduleInput.id).subscribe(res => {
        this.courseModule = res.body;
        this.teachers = res.body.teachers.length > 0 ? CommonUtil.convertTeacherName(res.body.teachers) : 'Chưa có giảng viên được gán';
        this.openingtime = this.getDateFromDatetime(this.courseModule.openingtime);
        this.closingtime = this.getDateFromDatetime(this.courseModule.closingtime);
        this.setFormValue();
        this.spinner.hide();
      },
      err => {
        this.setFormValue();
        this.spinner.hide();
        this.toastrService.handlerError(err);
      });
      this.getListBBBRecord();
    } else {
      // Tao bai giang moi
      this.setFormValue();
      this.courseModule.id = null;
      this.isNew = true;
      this.isCreateOrEdit = true;
    }
  }

  setDefaulTime(){
    this.dtpCourseStartDate = moment.unix(this.courseStartDate).toDate();
    this.dtpCourseEndDate = moment.unix(this.courseEndDate).toDate();
    this.minCloseDate = this.dtpCourseStartDate;
  }

  getListBBBRecord(){
    // get bbb recording
    const params = {
      courseid: this.courseId,
      moduleid: this.moduleInput.id
    };
    this.spinner.show();
    this.topicService.listBBBRecord(params).subscribe(
      res => {
        this.spinner.hide();
        this.bbbRecordings = res.body;
      },
      error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      }
    );
  }


  setFormValue() {
    const data = this.getDefaultFormData();
    this.formModule = this.fb.group({
        name: [
          data.name,
          Validators.required
        ],
        timelimit: [
          data.timelimit,
          Validators.required
        ],
        description: [
          data.description,
          Validators.required
        ],
        // start date
        openingtime: [data.openingtime],
        houropeningtime: [data.hoursOpeningtime],
        minuteopeningtime: [data.minutesOpeningtime],
        // end date
        closingtime: [data.closingtime],
        hourclosingtime: [data.hoursClosingtime],
        minuteclosingtime: [data.minutesClosingtime]
      },
      {
        validators: CustomValidators.startTimeEndTimeValidator(
          this.courseStartDate,
          this.courseEndDate)
      }
      );
  }

  getDefaultFormData(): any{
    const data = {
      name: this.getDefaultFormName(),
      timelimit: this.getDefaultTimelimit(),
      description: this.getDefaultDescription(),

      openingtime: this.getDefaultFormOpeningtime(),
      hoursOpeningtime: this.getDefaultFormHoursOpeningtime(),
      minutesOpeningtime: this.getDefaultFormMinutesOpeningtime(),

      closingtime: this.getDefaultFormClosingtime(),
      hoursClosingtime: this.getDefaultFormHoursClosingtime(),
      minutesClosingtime: this.getDefaultFormMinutesClosingtime(),
    };
    return data;
  }

  submitForm() {
    if (this.formModule.invalid) { // show error
      CommonUtil.markFormGroupTouched(this.formModule);
      return;
    }
    this.spinner.show();
    const createOrUpdateModule = this.setDataForCreateOrUpdateBBB();

    if (!this.courseModule.id) { // create
      this.topicService.createBBBContent(createOrUpdateModule).subscribe(res => {
        this.setDateAfterUpdateOrCreate(res);
        this.spinner.hide();
        this.toastrService.success(`common.noti.create_success`);
        this.hasChange.emit(false);
        this.reLoadTopics.emit(res.body?.coursemodule);
        this.isCreateOrEdit = false;
        this.isNew = false;
        // this.formModule.reset();
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
    } else { // update
      createOrUpdateModule.coursemodule = this.courseModule.id;
      this.topicService.updateBBBContent(createOrUpdateModule).subscribe(res => {
        this.setDateAfterUpdateOrCreate(res);
        this.spinner.hide();
        this.toastrService.success(`common.noti.update_success`);
        this.hasChange.emit(false);
        this.reLoadTopics.emit(res.body?.coursemodule);
        this.isCreateOrEdit = false;
        this.isNew = false;
        // this.formModule.reset();
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
    }
  }

  setDateAfterUpdateOrCreate(response: any){
    this.courseModule.name = this.formModule.value.name;
    this.courseModule.timelimit = this.formModule.value.timelimit * 60;
    this.courseModule.intro = this.formModule.value.description;
    this.courseModule.openingtime = response.body.openingtime;
    this.courseModule.closingtime = response.body.closingtime;
    this.openingtime = this.getDateFromDatetime(this.courseModule.openingtime);
    this.closingtime = this.getDateFromDatetime(this.courseModule.closingtime);
    this.formModule.patchValue({
      // start date
      openingtime: this.openingtime,
      houropeningtime: this.openingtime.getHours(),
      minuteopeningtime: this.openingtime.getMinutes(),
      // end date
      closingtime: this.closingtime,
      hourclosingtime: this.closingtime.getHours(),
      minuteclosingtime: this.closingtime.getMinutes(),
    });
  }


  setDataForCreateOrUpdateBBB() {
    this.introeditor = {text: '', format: 1, itemid: 1598597784614};
    const createOrUpdate: ModuleInfo = {
      name: this.formModule.value.name,
      course: this.courseId,
      timelimit: this.formModule.value.timelimit * 60,
      section: this.section,
      introeditor: null,
      modulename: 'bigbluebuttonbn',
      closingtime: this.getClosingtime(),
      openingtime: this.getOpeningtime()
    };
    this.introeditor.text = this.formModule.value.description;
    createOrUpdate.introeditor = this.introeditor;
    return createOrUpdate;
  }

  cancel() {
    this.isCreateOrEdit = false;
  }

  /**
   * Dung cho muc dich hien thi datepicker cua ngay ket thuc bai giang
   * khi ngay bat dau duoc chon thi datepicker cua ngay ket thuc se disable nhung ngay < ngay bat dau di
   * param startDate
   */
  setMinEndDate(startDate: any) {
    this.minCloseDate = startDate;
  }

  setDate(date: Date, hours: number, minutes: number) {
    date.setHours(hours);
    date.setMinutes(minutes);
  }


  /**
   * pre-condition: opentime is valid
   */
  getOpeningtime() {
    return CommonUtil.convertDateToTime(
      this.formModule.value.openingtime,
      this.formModule.value.houropeningtime,
      this.formModule.value.minuteopeningtime,
    );

  }

  /**
   * pre-condition: closingtime is valid
   */
  getClosingtime() {
    return CommonUtil.convertDateToTime(
      this.formModule.value.closingtime,
      this.formModule.value.hourclosingtime,
      this.formModule.value.minuteclosingtime,
    );
  }

  joinMeeting(){
    this.spinner.show();
    this.topicService.joinMeeting(this.courseModule.id).subscribe(
      data => {
        this.toastrService.success('done');
        const url = data.body.meetingurl;
        window.open(url.toString(), '_blank');
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      }
    );
  }

  showEdit() {
    this.spinner.show();
    this.topicService.prepareDataForUpdate(this.courseModule.id).subscribe(
      res => {
        this.formModule.patchValue({
          description: res.body.intro,
        });
        this.spinner.hide();
        this.isCreateOrEdit = true;
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      }
    );
  }

  onDataChange() {
    if (this.isCreateOrEdit) {
      this.hasChange.emit(true);
    }
  }

  getDateFromDatetime(dateTime: any) {
    return moment.unix(dateTime).toDate();
  }

  getHourFromDatetime(dateTime: any) {
    return moment.unix(dateTime).toDate().getHours();
  }

  getMinusFromDatetime(dateTime: any) {
    return moment.unix(dateTime).toDate().getMinutes();
  }

  setDefaultStartDate(){
    this.formModule.patchValue({
      // start date
      openingtime: this.getDateFromDatetime(this.courseStartDate),
      houropeningtime: this.getHourFromDatetime(this.courseStartDate),
      minuteopeningtime: this.getMinusFromDatetime(this.courseStartDate),
    });
  }

  setDefaultEndDate(){
    this.formModule.patchValue({
      // end date
      closingtime: this.getDateFromDatetime(this.courseEndDate),
      hourclosingtime: this.getHourFromDatetime(this.courseEndDate),
      minuteclosingtime: this.getMinusFromDatetime(this.courseEndDate),
    });
  }

  numericOnly(event): boolean {
    const patt = /^([0-9])$/;
    return patt.test(event.key);
  }

  onDeleteRecord(recordId){


    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('bbbrecording.delete_popup.confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('bbbrecording.delete_popup.confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');

    modalDep.result.then((result) => {
      const params = {
        recordid: recordId,
        courseid: this.courseId,
        moduleid: this.moduleInput.id
      };
      this.spinner.show();
      this.topicService.deleteBBBRecord(params).subscribe(
        res => {
          this.toastrService.success('common.noti.delete_success');
          this.spinner.hide();
          this.getListBBBRecord();
        },
        error => {
          this.spinner.hide();
          this.toastrService.handlerError(error);
        }
      );
    });
  }

  private getDefaultFormOpeningtime(){
    const openingtime = this.moduleInput == null ?
      this.getDateFromDatetime(this.courseStartDate) : // create
      this.getDateFromDatetime(this.courseModule.openingtime); // update
    return openingtime;
  }

  private getDefaultFormHoursOpeningtime(){
    const hoursOpeningtime = this.moduleInput == null ?
      this.getHourFromDatetime(this.courseStartDate) : // create
      this.getHourFromDatetime(this.courseModule.openingtime); // update
    return hoursOpeningtime;
  }

  private getDefaultFormMinutesOpeningtime(){
    const minutesOpeningtime = this.moduleInput == null ?
      this.getMinusFromDatetime(this.courseStartDate) : // create
      this.getMinusFromDatetime(this.courseModule.openingtime); // update
    return minutesOpeningtime;
  }

  private getDefaultFormClosingtime(){
    const closingtime = this.moduleInput == null ?
      this.getDateFromDatetime(this.courseEndDate) : // create
      this.getDateFromDatetime(this.courseModule.closingtime); // update
    return closingtime;
  }

  private getDefaultFormHoursClosingtime(){
    const hoursClosingtime = this.moduleInput == null ?
      this.getHourFromDatetime(this.courseEndDate) : // create
      this.getHourFromDatetime(this.courseModule.closingtime); // update
    return hoursClosingtime;
  }

  private getDefaultFormMinutesClosingtime(){
    const minutesClosingtime = this.moduleInput == null ?
      this.getMinusFromDatetime(this.courseEndDate) : // create
      this.getMinusFromDatetime(this.courseModule.closingtime); // update
    return minutesClosingtime;
  }

  private getDefaultFormName(){
    const name = this.moduleInput == null ?
      null : // create
      this.courseModule.name; // update
    return name;
  }

  private getDefaultTimelimit(){
    const timelimit = this.moduleInput == null ?
      null : // create
      this.courseModule.timelimit / 60; // update
    return timelimit;
  }

  private getDefaultDescription(){
    const description = this.moduleInput == null ?
      null : // create
      this.courseModule.intro; // update
    return description;

  }
}
