<div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Báo cáo khóa học</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
              <li class="breadcrumb-item active">Báo cáo khóa học</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <mat-tab-group>
            <mat-tab label="Tổng quát">
              <app-report-general [courseType] = 1 [titleTable]="titleTable" [courseLable]="courseLable"></app-report-general>
             </mat-tab>
            <mat-tab label="Chi tiết">
              <app-report-course [courseType]="course" [choosesCourseBtn]="choosesCourseBtn"></app-report-course>
            </mat-tab>
          </mat-tab-group>

      </div><!-- /.container-fluid -->
    </section>
  </div>



