import { AfterViewInit, Component, Input, OnInit, OnDestroy } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmModalComponent} from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import {ToastrCustomService} from 'src/app/shared/services/toastr-custom.service';
import {BadgeService} from '../../../shared/services/badge.service';
import {SelectItemComponent} from '../select-item.component';
import {DataService} from '../../../shared/services/data.service';
import {CourseService} from '../../../shared/services/course.service';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-badge-exam',
  templateUrl: './badge-exam.component.html',
  styleUrls: ['./badge-exam.component.css']
})
export class BadgeExamComponent implements OnInit, OnDestroy {

  @Input() isNotify: boolean;
  @Input() badgeId: any;
  @Input() readonly: boolean;

  idCoursesForNotify: string;
  idCourseArray: any[] = [];
  examArray: any[] = [];
  tempExamArray: any[] = [];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  pageEvent: PageEvent;
  examInBadge: any;

  searchBadgeExam = {
    search : '',
    pageIndex: 1,
    pageSize: 10
  }

  constructor(
    private badgeService: BadgeService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private courseService: CourseService,
  ) {}

  ngOnInit(): void {
    if (this.isNotify === undefined || this.isNotify === null) {
      this.isNotify = false;
    }

    if (!this.isNotify) {
      this.getExamInBadge();
    }
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  openListCourse(open: string) {
    const modalDep = this.modalService.open(SelectItemComponent, {
      size: 'xl' as any,
      centered: false,
      backdrop: 'static'
    });

    modalDep.componentInstance.badgeId = this.badgeId;
    modalDep.componentInstance.open = open;
    modalDep.componentInstance.loadAll.subscribe(($e) => {
      this.getExamInBadge();
    });
  }

  openListCourseForNotify(open: string) {
    const modalDep = this.modalService.open(SelectItemComponent, {
      size: 'xl' as any,
      centered: false,
      backdrop: 'static'
    });

    modalDep.componentInstance.isNotify = true;
    modalDep.componentInstance.badgeId = this.badgeId;
    modalDep.componentInstance.open = open;
    if (this.idCourseArray !== undefined) {
      modalDep.componentInstance.arrayExamForNotify = this.idCourseArray;
    }

    modalDep.componentInstance.loadAll.subscribe((courseIds) => {
      this.idCoursesForNotify = courseIds;

      if (this.idCoursesForNotify.toString().indexOf(',') !== -1) {
        let arrayCourseIds: any[] = [];
        arrayCourseIds = this.idCoursesForNotify.toString().split(',');
        for (let i = 0; i < arrayCourseIds.length; i++) {
          this.courseService.getCoursesInfo(+arrayCourseIds[i]).subscribe(res => {
            const course = res.body;
            this.examArray.push(course);
            this.tempExamArray.push(course);
            this.idCourseArray.push(+arrayCourseIds[i]);
            this.totalRecord = this.examArray.length;
            // ban id
            this.dataService.setDataExam = this.idCourseArray.toString();
            if (this.idCoursesForNotify.length === this.examArray.length) {
              this.toastrService.success('common.noti.assign_exam_success');
            }
          }, error => {
            this.toastrService.handlerError(error);
          });
        }
      } else {
        this.idCourseArray.push(+this.idCoursesForNotify);
        this.courseService.getCoursesInfo(+this.idCoursesForNotify).subscribe(res => {
            const course = res.body;
            this.examArray.push(course);
            this.tempExamArray.push(course);
            // ban id
            this.dataService.setDataExam = this.idCourseArray.toString();
            this.toastrService.success('common.noti.assign_exam_success');
            this.totalRecord = this.examArray.length;
          },
          error => {
            this.toastrService.handlerError(error);
          });
      }
    });
  }

  getExamInBadge() {
    let params = {
      limit: this.searchBadgeExam.pageSize,
      page: this.searchBadgeExam.pageIndex,
      coursetype: 2,
      inbadgeids: this.badgeId,
      keyword: this.searchBadgeExam.search
    };

    this.badgeService.getistCourseCriteria(params).subscribe(data => {
      this.examInBadge = data.body.results;
      this.totalRecord = data.body.total;
    });
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  unAssignExam(courseId) {

    if (!this.isNotify) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });

      modalDep.componentInstance.title = this.translateService.instant('badge.delete_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('badge.delete_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.unassign');

      modalDep.result.then((result) => {
        // console.log('result: ', result);
        this.spinner.show();
        this.badgeService.updateCourseToBadge(this.badgeId, String(courseId), 'delete').subscribe(
          res => {
            this.spinner.hide();
            this.toastrService.success(`badge.unassign_course_success`);
            this.getExamInBadge();
          },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          }
        );
      });
    } else {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });

      modalDep.componentInstance.title = this.translateService.instant('badge.unassign_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('badge.unassign_course_confirm_content_for_notify');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.unassign');

      modalDep.result.then((result) => {
        let index: any;
        let indexId: any;
        for (let i = 0; i < this.examArray.length; i++) {
          if (this.examArray[i].id == courseId) {
            index = i;
            break;
          }
        }
        for (let i = 0; i < this.idCourseArray.length; i++) {
          if (this.idCourseArray[i] == courseId) {
            indexId = i;
          }
        }
        this.idCourseArray.splice(indexId, 1);
        this.examArray.splice(index, 1);
        this.toastrService.success('badge.unassign_course_success');
        this.dataService.setDataExam = this.idCourseArray.toString();
      });
    }
  }

  onChange(event){
    this.searchBadgeExam.pageSize = 10;
    this.searchBadgeExam.pageIndex = 1;
    this.searchBadgeExam.search = event.target.value;
    this.getExamInBadge();
  }

  searchByNotify(value) {
    const filter = value.toString().toLowerCase().trim();
    if (filter.length > 0) {
      this.examArray = this.tempExamArray.filter(data => data.fullname.toLowerCase().includes(filter));
    } else {
      this.examArray = [];
      this.examArray = [...this.tempExamArray];
    }
  }

  changePage(event){
    this.searchBadgeExam.pageIndex = event.pageIndex + 1;
    this.searchBadgeExam.pageSize = event.pageSize;
    this.getExamInBadge();
  }
}
