import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChartOptions, ChartType} from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {Label} from 'ng2-charts';
import {ActivatedRoute} from '@angular/router';
import {SurveyService} from '../../../shared/services/survey.service';
import {Survey} from '../../../shared/model/survey';
import {SelectCourseComponent} from '../../position/position-course/select-course.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Course} from '../../../shared/model/course.model';
import {CourseService} from '../../../shared/services/course.service';
import {QUESTION_TYPE} from '../../../shared/constants/base.constant';
import { forkJoin } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/shared/services/login.service';
import { ToastrCustomService } from 'src/app/shared/services/toastr-custom.service';
import {FileService} from '../../../shared/services/file.service';

@Component({
  selector: 'app-survey-detail',
  templateUrl: './survey-detail.component.html',
  styleUrls: ['./survey-detail.component.css']
})
export class SurveyDetailComponent implements OnInit, OnDestroy {

  isTemplate: boolean;
  listAnswer: any[] = [];
  courses: Course[] = [];
  QUESTION_TYPE = QUESTION_TYPE;


  survey: Survey = {
    name: '',
    sectionid: null,
    introeditor: '',
    ismaster: null,
    published: null,
    publishedat: null,
    startdate: null,
    enddate: '',
    qtype: null,
    id: null,
    questionnaireid: null,
    intro: '',
    cmid: null,
    listquestion: null,
    course: null,
    isactive: null,
    refdepartmentid: null,
    activatedat: null,
    departmentid: null,
    selected: null,
    isteacher: null,
    sid: null
  };

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  barChartLabels: Label[] = ['Số lượng bình chọn của học viên'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [pluginDataLabels];

  users = [];
  currentUserIndex = 0;
  questionaireSurveyId: number;
  isHasQuestionTeacher: any;
  isHasQuestionCourse: any;
  courseId: any;
  constructor(
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    private toastrService: ToastrCustomService
  ) {
  }

  ngOnInit() {

    if (+this.route.snapshot.paramMap.get('id2') === 0) {
      this.isTemplate = false;
      const courseId = +this.route.snapshot.paramMap.get('id');
      this.surveyService.getQuestionNaireIdOfCourse(courseId).subscribe(rs => {
        this.questionaireSurveyId = rs.body.questionnaireid;
        this.getData(this.questionaireSurveyId);
      });
    } else {
      this.isTemplate = true;
      this.questionaireSurveyId = +this.route.snapshot.paramMap.get('id');
      this.getDetailSurveyTemplate();
    }
  }

  getDetailSurveyTemplate(){
    this.surveyService.getDetailSurvey(this.questionaireSurveyId).subscribe(rs => {
      this.survey = rs.body;
    });
  }

  getReportByCourse(courseId){
    this.spinner.show();
    this.surveyService.getQuestionNaireIdOfCourse(courseId).subscribe(rs => {
      this.questionaireSurveyId = rs.body.questionnaireid;
      this.surveyService.report(rs.body.questionnaireid).subscribe(res => {
        this.listAnswer = res.body.result;
        this.users = this.listAnswer.map(item => item.userid)
        .filter((value, index, self) => self.indexOf(value) === index);
        if (this.users.length > 0) {
          this.updateQuestionByUser(this.currentUserIndex);
        } else {
          this.survey.listquestion.forEach(q => {
            q.userAnswers = [];
          });
        }
        this.assignAnswerToQuestion();
        this.spinner.hide();
      });
    });

  }

  getData(idSurvey) {
    const responseSurvey = this.surveyService.getDetailSurvey(idSurvey);
    const responseAnswer = this.surveyService.report(idSurvey);
    forkJoin([responseSurvey, responseAnswer]).subscribe(rs => {
      this.survey = rs[0].body;
      this.listAnswer = rs[1].body.result;
      this.users = this.listAnswer.map(item => item.userid)
        .filter((value, index, self) => self.indexOf(value) === index);
      if (this.users.length > 0) {
        this.updateQuestionByUser(this.currentUserIndex);
      }
      this.assignAnswerToQuestion();
    });
  }

  updateQuestionByUser(userIndex) {
    this.survey.listquestion.forEach(q => {
      q.content = q.content.replace(/<img .*?>/g, '');
      q.userAnswers = this.listAnswer.filter(a => a.userid === this.users[userIndex] && q.id === a.question_id);
    });
  }

  assignAnswerToQuestion() {
    this.survey.listquestion.forEach(q => {
      const answers = this.listAnswer.filter(a => a.question_id === q.id);
      q.content = q.content.replace(/<img .*?>/g, '');
      if (q.type === QUESTION_TYPE.RADIO) {
        const barChartData = [];
        const sumCount = this.getTotalAnswer(answers);

        q.choices.forEach(c => {
          const answerFilter = answers.filter(a => c.id === Number(a.choice_id));
          const countAnswerFilter = this.getTotalAnswer(answerFilter);
          barChartData.push({
            data: [countAnswerFilter || 0, countAnswerFilter + 1, 0],
            label: c.content
          });
        });
        q.chart = barChartData;
        q.countAnswer = sumCount;
      }
      if (q.type === QUESTION_TYPE.RATE) {
        const barChartData = [];
        const sumCount = this.getTotalAnswer(answers);
        const rank = [...Array(q.length).keys()].map(i => i + 1);
        rank.forEach(c => {
          const answerFilter = answers.filter(a => a.rankvalue === c.toString());
          const countAnswerFilter = this.getTotalAnswer(answerFilter);

          barChartData.push({
            data: [countAnswerFilter || 0, countAnswerFilter + 1, 0],
            label: c
          });
        });
        q.chart = barChartData;
        q.countAnswer = sumCount;
      }
      if (q.type === QUESTION_TYPE.TEXT) {
        q.answers = answers;
        q.countAnswer = answers.length;
      }
    });
    this.isHasQuestionCourse = this.survey.listquestion.filter(q => q.isteacher === '0' || !q.isteacher).length > 0;
    this.isHasQuestionTeacher = this.survey.listquestion.filter(q => q.isteacher === '1').length > 0;
  }

  getTotalAnswer(answers: any[]) {
    if (answers.length === 0) {
      return 0;
    }
    const rs = answers.map(a => Number(a.number)).reduce((a, b) => {
      return a + b;
    });
    return rs;
  }



  openListCourse() {
    const modalDep = this.modalService.open(SelectCourseComponent, {
      size: 'xl' as any,
      centered: false,
      backdrop: 'static'
    });
    modalDep.componentInstance.surveyId = this.survey.sid;
    modalDep.componentInstance.transferCourseIds.subscribe((courseId) => {
      this.courseId = courseId;
      this.getReportByCourse(courseId);
    });
  }

  /* displayDetail() {
    const params = {};
    this.courseService.searchCourses(params).subscribe(data => {
      this.courses = data.body.results;
    });
  } */

  /* seeAnswerOfUser(event) {
    const indexUser = event.target.value - 1;
    if (this.totalUserAnswerSurvey !== undefined && this.totalUserAnswerSurvey !== null && this.totalUserAnswerSurvey.length > 0) {
      this.idUser = this.totalUserAnswerSurvey[indexUser];
    }
  } */

  previous() {
    this.currentUserIndex--;
    this.updateQuestionByUser(this.currentUserIndex);
  }

  next() {
    this.currentUserIndex++;
    this.updateQuestionByUser(this.currentUserIndex);
  }

  onExport(){
    const params = {
      questionnaireid: this.questionaireSurveyId,
      istemplate: '1'
    };
    this.spinner.show();
    this.surveyService.exportSingleSurvey(params).subscribe(res => {
    window.open(this.fileService.getFileFromPathUrl(res.body.path));
    this.spinner.hide();
    }, err => {
      this.toastrService.handlerError(err);
      this.spinner.hide();
    });
  }

  ngOnDestroy(){
    this.modalService.dismissAll();
  }
}
