<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Quản lý kỳ thi</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý kỳ thi</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="col-12">
        <div class="card card-default">
        <div class="card-header">
              <!-- <h3 class="card-title">Danh sách kỳ thi</h3> -->
              <div class="row">
                <div class="col-6">
                  <div class="form-inline">
                     <button type="button" *ngIf="checkRoleFunction('CREATE_EXAN', role)" class="btn  btn-primary btn-flat mr-2"><i class="fas fa-plus"></i><a class="text-white" (click)="openExamCreatePopup()">Tạo kỳ thi</a></button>
                     <!-- <button type="button" [disabled]="courses.length === 0" class="btn  btn-default btn-flat  mr-2 " matTooltip="Xuất File Excel" matTooltipClass="tooltip-red"><i class="fas fa-file-excel  mr-1"></i></button> -->
                     <button type="button" *ngIf="checkRoleFunction('DELETE_EXAM', role)" [disabled]="courseIdsChecked?.length === 0"  class="btn  btn-default btn-flat  mr-2"  matTooltip="Xóa" (click)="onDeleteMultipleCourse()" matTooltipClass="tooltip-red"><i class="fas fa-trash mr-1"></i></button>
                     <span *ngIf="checkRoleFunction('DELETE_EXAM', role)">Số kỳ thi đã chọn: {{courseIdsChecked?.length}}</span>
                    </div>
                </div>
              <div class="col-6">
                <form class="form-inline float-right" >
                  <div class="form-group m-2">
                    <label class="mr-2">Tìm kiếm</label>
                    <input type="search" name="keyword" [(ngModel)]="searchExam.search" class="form-control form-control-sm mr-2" placeholder="Tên kỳ thi..." (change)="onChangeKeyword()"
                           autocomplete="off">
                  </div>
                  <div class="card-tools float-right">
                    <a class="btn btn-block btn-default btn-flat" (click)="openExamSearchPopup()"
                       role="button">
                      <i class="fa fa-search"></i> Tìm kiếm nâng cao
                    </a>
                  </div>
                </form>
              </div>
              </div>
            </div>
            <div class="card-body" style="display: block;">
            <div class="col-12 table-responsive">
              <table class="table table-hover" matSort (matSortChange)="sortData($event)">
                <thead>
                <tr>
                  <th *ngIf="checkRoleFunction('DELETE_EXAM', role)">
                    <mat-icon class="mt-1">
                      <mat-checkbox class="example-margin" [(ngModel)]="completedAll" [disabled]="courseCanChecked === 0" (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                    </mat-icon>
                  </th>
                  <th mat-sort-header="stt">STT</th>
                  <th mat-sort-header="fullname">Tên kỳ thi</th>
                  <th mat-sort-header="startdate">Ngày bắt đầu</th>
                  <th mat-sort-header="enddate">Ngày kết thúc</th>
                  <th mat-sort-header="refdepartmentid">Đơn vị chủ trì</th>
                  <th>Hiển thị</th>
                  <th>Trạng thái</th>
                  <th>Thao tác</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of courses; let i = index;">
                    <td *ngIf="checkRoleFunction('DELETE_EXAM', role)">
                      <mat-icon class="mt-1">
                        <mat-checkbox [disabled]="this.checkDisable(item) || (isManage && managedDepartmentId !== item.refdepartmentid)" [(ngModel)]="item.completed" (change)="oncheckboxItem(item.id, $event.checked)" class="example-margin"></mat-checkbox>
                      </mat-icon>
                    </td>
                    <td>{{(searchExam.pageIndex - 1) * searchExam.pageSize + i + 1}}</td>
                    <td  style="width:450px;"><a (click)="goToExamDetail(item)"><span matTooltip="{{item.fullname}}" matTooltipClass="tooltip-inner">{{item.fullname?.length <= 40 ? item.fullname : (item.fullname | slice:0:40) + '...' }}</span></a></td>
                    <td>{{getDateFromUnix(item.startdate) | date:'dd/MM/yyyy'}}</td>
                    <td>{{getDateFromUnix(item.enddate)| date:'dd/MM/yyyy'}}</td>
                    <td><span matTooltip="{{item.departmentname}}" matTooltipClass="tooltip-inner">{{item.departmentname?.length <= 10 ? item.departmentname : (item.departmentname | slice:0:15) + '...' }}</span></td>
                    <td><span class="badge badge-{{item.published === 1 ? 'primary' : 'warning'}}">{{item.published === 1 ? 'Đang hiển thị' : 'Chưa hiển thị'}}</span></td>
                    <td> {{item.status}}</td>
                    <td>
                      <div *ngIf="isManage && managedDepartmentId !== item.refdepartmentid; else elseManage">
                        <button type="button" class="btn btn-default btn-sm mr-1 {{item.id}}" (click)="goToExamDetail(item)" matTooltip="Xem" matTooltipClass="tooltip-red"><i class="fas fa-eye"></i></button>
                      </div>
                      <ng-template #elseManage>
                        <!-- <div *ngIf="item.statusNumber!=2"> -->
                          <button type="button" class="btn btn-default btn-sm mr-1 {{item.id}}" (click)="goToExamDetail(item)" matTooltip="Sửa" matTooltipClass="tooltip-red"><i class="fas fa-edit"></i></button>
                          <button *ngIf="checkRoleFunction('DELETE_EXAM', role)" type="button" class="btn btn-default btn-sm mr-1 {{item.id}}" [disabled]="this.checkDisable(item)" matTooltip="Xóa" matTooltipClass="tooltip-red" (click)="onDeleteSingleCourse(item.id)"><i class="fas fa-trash mr-1"></i></button>
                        <!-- </div> -->
                        <!-- <div *ngIf="item.statusNumber==2">
                          <button type="button" (click)="onCancelExam(item)" class="btn  btn-danger btn-sm rounded mr-2"><i class="fas fa-power-off mr-2"></i><a class="text-white">Dừng thi</a></button>
                        </div> -->
                      </ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <p *ngIf="courses?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
               </div>
               <div class="card-footer text-center">
                <div class="row">
                  <mat-paginator showFirstLastButtons
                                 [length]="totalRecord"
                                 [pageSize]="searchExam.pageSize"
                                 [pageIndex]="searchExam.pageIndex - 1 "
                                 (page)="changePage($event)"
                                 [hidePageSize]="false"
                                 [pageSizeOptions]="pageSizeOptions">
                  </mat-paginator>
                </div>
              </div>
            </div>
            </div>
        </div>
      </div><!-- /.container-fluid -->
  </section>
</div>
