import { ExamLessonService } from '../../../shared/services/exam-lesson.service';
import { Component, OnInit } from '@angular/core';
import { ModuleInfo } from '../../../shared/model/moduleinfo.model';
import { TopicModel } from '../../../shared/model/topic.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  COMPLETION_STATE,
  GRADE_METHOD,
  LOCAL_STORAGE_KEY,
  USER_INFO,
} from '../../../shared/constants/base.constant';
import { element } from 'protractor';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrCustomService } from '../../../shared/services/toastr-custom.service';
import { LoginService } from '../../../shared/services/login.service';

@Component({
  selector: 'app-exam-lesson',
  templateUrl: './exam-lesson.component.html',
  styleUrls: ['./exam-lesson.component.css'],
})
export class ExamLessonComponent implements OnInit {
  modules: any[];
  countQuizPass: number;
  countQuizFail: number;
  countQuizInComplete: number;
  examName: string;
  finished: boolean;
  permission = true;
  currentId = null;
  quizIdActive = null;
  quizAttempts = null;
  listQA = [];
  userInfo: any;
  isOverExam = false;
  result = 0;

  constructor(
    private examLessonService: ExamLessonService,
    private toastrService: ToastrCustomService,
    private route: ActivatedRoute,
    private $localStorage: LocalStorageService,
    private router: Router,
    private loginService: LoginService
  ) {}

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  ngOnInit(): void {
    this.userInfo = this.$localStorage.retrieve(USER_INFO.INFO);
    this.countQuizInComplete = 0;
    this.countQuizPass = 0;
    this.countQuizFail = 0;
    // tslint:disable-next-line:radix
    this.examLessonService.getCurrentQuizActive().subscribe(
      (data) => {
        this.quizIdActive = data.body.quiz_id;
        this.quizAttempts = data.body.attempts;
        this.listQA = data.body.result;
        this.listQA[0]?.attempts - this.listQA[0]?.attempt === 0 ? this.isOverExam = true : this.isOverExam = false;
        this.result = this.listQA[0]?.attempts - this.listQA[0]?.attempt;
        // Sắp xếp nổi bọt giảm dần theo số điểm user đạt được
        const length = this.listQA?.length;
        for (let i = 0; i < length; i++) {
          for (let j = 0; j < length - i - 1; j++) {
            if (this.listQA[j]?.gradeuser < this.listQA[j + 1]?.gradeuser) {
              [this.listQA[j], this.listQA[j + 1]] = [this.listQA[j + 1], this.listQA[j]];
            }
          }
        }
      },
      (err) => {
        this.toastrService.handlerError(err);
      },

    );
    // this.examLessonService.listAllQuiz().subscribe((data) => {

    //   this.modules = data.body.results;
    //   if (this.modules.length > 0) {
    //     this.currentId = this.modules.pop().instance;
    //   }
    //   console.log('content: ' + this.modules);
    //   console.log('size: ' + this.modules.length);
    // tslint:disable-next-line:no-shadowed-variable
    // this.modules.forEach(element => {
    //   element.sumgrades = element.sumgrades ? Math.round(element.sumgrades * 100) / 100 : 0;
    //   element.gradequiz = element.gradequiz ? Math.ceil(element.gradequiz) : 0;
    //   if (element.grademethod === GRADE_METHOD.QUIZ_GRADEHIGHEST) {
    //     element.grademethodName = GRADE_METHOD.QUIZ_GRADEHIGHEST_DESCRIPTION;
    //   } else if (element.grademethod === GRADE_METHOD.QUIZ_GRADEAVERAGE) {
    //     element.grademethodName = GRADE_METHOD.QUIZ_GRADEAVERAGE_DESCRIPTION;
    //   } else if (element.grademethod === GRADE_METHOD.QUIZ_ATTEMPTFIRST) {
    //     element.grademethodName = GRADE_METHOD.QUIZ_ATTEMPTFIRST_DESCRIPTION;
    //   } else if (element.grademethod === GRADE_METHOD.QUIZ_ATTEMPTLAST) {
    //     element.grademethodName = GRADE_METHOD.QUIZ_ATTEMPTLAST_DESCRIPTION;
    //   }
    //   if (element.completionstate === COMPLETION_STATE.YET_EXAM) {
    //     this.countQuizInComplete++;
    //     element.completionStateName = COMPLETION_STATE.YET_EXAM_DESCRIPTION;
    //   } else if (element.completionstate === COMPLETION_STATE.PASS_EXAM) {
    //     this.countQuizPass++;
    //     element.completionStateName = COMPLETION_STATE.PASS_EXAM_DESCRIPTION;
    //   } else if (element.completionstate === COMPLETION_STATE.FAIL_EXAM || element.completionstate === COMPLETION_STATE.USED_EXAM) {
    //     this.countQuizFail++;
    //     element.completionStateName = COMPLETION_STATE.FAIL_EXAM_DESCRIPTION;
    //   }
    // });
    // },
    // (err) => {
    //   this.toastrService.handlerError(err);
    // });
  }

  startAttempt() {
    this.$localStorage.store('quizName', this.listQA[0]?.name);
    this.router.navigate(['/exam-details/' + this.quizIdActive]);
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['/home']);
  }

}
