<div class="navbar_exam">
  <section class="inner_page_breadcrumb bg-exam fixed-top">
    <div class="container">
      <nav>
        <div class="row">
          <div class="dmdoan">
            <div class="iteamdoan hiddenquiz">
              <img src="../../../assets/ladipage/img/doan.png" class="imgdoan" alt="">
            </div>
            <div class="iteamdoan">
              <div class="breadcrumb_content mt-1 pb-1 pt-3" *ngIf="!isTeacher">
                <!-- <h4 class="breadcrumb_title">{{quizName}}</h4> -->
                <p class="text-white sfbold text-uppercase tbtl hiddenquiz mb-1">Tìm hiểu nghị quyết đại hội đại biểu Đoàn TNCS Hồ Chí Minh
                  <br>Khối doanh nghiệp Trung Ương lần thứ IV, nhiệm kỳ 2022-2027</p>
                <div class="text-center" *ngIf="finish===undefined">
                <small class="text-warning mb-0" style="font-size: 11pt;">Thời gian còn lại</small></div>
                <div class="text-center mb-0 w-100" *ngIf="finish===undefined && !checkgraceperiod && !checkautoabandon"><h2
                  class="text-white">
                <countdown #cd *ngIf="timeLimit" (event)="timesUp($event)" [config]="countConfig"></countdown>
                </h2></div>
                <!-- <span class="text-center mb-0" *ngIf="isShowExamAlert"><h5 class="text-white text-uppercase mb-0">
                Sắp hết thời gian làm bài, thí sinh chú ý nộp bài!
                </h5></span> -->
                <!-- <span class="text-center mb-0" *ngIf="checkgraceperiod"><h2 class="text-white text-uppercase mb-0">
                Đã hết thời gian làm bài, yêu cầu thí sinh nộp bài!
                </h2></span> -->
                <!-- <span class="text-center mb-0" *ngIf="checkautoabandon"><h2 class="text-white text-uppercase mb-0">
                Thí sinh chưa nộp bài, bài thi sẽ không được tính điểm!
                </h2></span> -->
              </div>
            </div>
            <!-- <div class="iteamdoan">.</div> -->
          </div>
          <div class="col-xl-12 text-center">
            <div *ngIf="isTeacher">
              <h4 class="breadcrumb_title">{{quizName}}</h4>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </section>
</div>
<main id="main" class="main_exam">
  <!---Phan noi dung bai hoc-->
  <section id="exam">
    <div class="container">
      <div class="row" [ngClass]="preventClickAnswer ? 'disable-click-event' : ''">
        <div *ngIf="checkattemptisfinish" class="col-12 text-center border p-3">
          <img src="/assets/student.svg" style="height: 25vh;" alt="">
          <h5 class="mt-2">Bài thi đã kết thúc ! Cám ơn bạn đã tham gia</h5>
          <span><button (click)="back()" class="btn btn-secondary btn-md rounded-0"><i class="fas fa-arrow-left"></i> Trở lại</button></span>
        </div>
        <div *ngIf="checkattemptislimited" class="col-12 text-center border p-3">
          <img src="/assets/graduation-cap.svg" style="height: 25vh;" alt="">
          <h5 class="mt-2">Bạn đã hoàn thành bài thi !</h5>
          <span><button (click)="back()" class="btn btn-secondary btn-md rounded-0"><i class="fas fa-arrow-left"></i> Trở lại</button></span>
        </div>
        <div *ngIf="checkattemptisopen" class="col-12 text-center border p-3">
          <img src="/assets/wall-clock.svg" style="height: 25vh;" alt="">
          <h5 class="mt-2">Thời gian thi chưa bắt đầu</h5>
          <span><button (click)="back()" class="btn btn-secondary btn-md rounded-0"><i class="fas fa-arrow-left"></i> Trở lại</button></span>
        </div>

        <div *ngIf="checknoquestion" class="col-12 text-center border p-3">
          <img src="/assets/crane.svg" style="height: 25vh;" alt="">
          <h5 class="mt-2" *ngIf="isRandomQuestionNotEnough">
            Xin lỗi bạn, có vẻ không đủ câu hỏi trong ngân hàng câu hỏi để phục vụ cho bài thi.
            Vui lòng liên hệ giảng viên hoặc quản trị viên để được hỗ trợ.
          </h5>
          <h5 class="mt-2" *ngIf="!isRandomQuestionNotEnough">
            Xin lỗi bạn, có vẻ có chút vấn đề với bài thi. Mong bạn quay lại sau
          </h5>
          <span><button (click)="back()" class="btn btn-secondary btn-md rounded-0"><i class="fas fa-arrow-left"></i> Trở lại</button></span>
        </div>
        <div class="col-lg-9 col-md-12 pb-2">
          <div class="overflow hscv">
            <form *ngFor="let question of listQuestion;let i = index;">
              <div class="card card-default rounded " [id]="question.slot">
                <div class="card-body p-3">
                  <div class="row form-inline p-3">
                    <div class="col-8">
                      <div class="">Câu {{i + 1}}:</div>
                    </div>
<!--                    <div class="col-lg-10 col-md-10 noselect"><span [innerHTML]="question.name"></span></div>-->
                    <div class="col-4 noselect " style="white-space: nowrap;">
                      <mat-checkbox class="example-margin" [checked]="question.condition === 3" *ngIf="!isTeacher" [disabled]="question.disabled" (change)="markQuestion($event, question)">Đánh dấu
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="row noselect">
                    <div class="col-lg-12 col-md-6 label-text text-justify noselect pl-3"><span [innerHTML]="question.questiontext"></span>
                      <iframe *ngIf="question.linkVideo" width="420" height="315"
                              [src]="question.linkVideo">
                      </iframe>
                    </div>
                    <div class="col-lg-2 col-md-6">
                    </div>
                  </div>
                  <div class="row pl-2 mt-3 noselect">
                    <div class="col-12">
                      <div class="form-check bg-light p-2" *ngFor="let answer of question.answers; let i = index;">
                        <mat-checkbox [disabled]="checkgraceperiod || isTeacher || question.disabled" *ngIf="question.typeCheckBox=='checkbox'"
                                      class="example-margin"
                                      [checked] = "answer.checked"
                                      (change)="answerMulti(question, i, $event)"
                                      name="check">
                          <div>
                            <div class="form-inline p-2"  *ngIf="question.answernumbertype">{{question.answernumbertype[i]}}.<span [innerHTML]="answer.text"></span></div></div>

                        </mat-checkbox>
                        <mat-radio-button [disabled]="checkgraceperiod || isTeacher || question.disabled" *ngIf="question.typeCheckBox=='radio'"
                                          class="example-margin"
                                          [checked] = "answer.checked"
                                          (change)="answerSingle(question, i)"
                                          [name]="question.slot">
                          <div class="form-inline">
                            <div  *ngIf="question.answernumbertype">{{question.answernumbertype[i]}}.</div>
                            <div><span [innerHTML]="answer.text | translate"></span></div></div>
                     </mat-radio-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 hiddenquiz">
          <div class="stick-area">
          <span class="text-center" *ngIf="finish!==undefined && !isTeacher"><h3
            class="text-primary">Bạn được: {{finish.correctquestion}}/{{finish.totalquestion}}Câu đúng</h3></span>
            <div class="row"
                 *ngIf="finish===undefined && !checkattemptislimited && !checkattemptisopen && !checkattemptisfinish && !checknoquestion && !isTeacher">
              <div class="card shadow-sm rounded">
                <div class="card-body pl-4 pr-4">
                  <div class="row">
                    <div class="col-12 text-center">
                    <h6 class="m-t-0 m-b-0"><small class="text-muted mr-1">Thí sinh</small>{{ userInfo.firstname }} {{userInfo.lastname}}</h6>                    </div>
                    <p class="text-center font-italic pt-3 pb-3">Bạn chỉ có thể <span class="sfbold">Nộp bài thi</span> khi số câu hoàn thành 30/50 câu. <br>
                       Chúc bạn hoàn thành kỳ thi kết quả cao</p>
                  </div>
                </div>
              </div>
              <hr>
              <div class="col-12 ">
                <ul class="fa-ul form-inline">
                  <li><i class="fa fa-square text-primary mr-1"></i><small>Đã làm</small></li>
                  <li class="ml-2"><i class="fa fa-square text-gray-light mr-1"></i><small>Chưa làm</small></li>
                  <li class="ml-2"><i class="fa fa-square text-warning mr-1"></i><small>Xem lại</small></li>
                </ul>
              </div>
            </div>
            <div class="row mb-4 mt-2"  *ngIf="!isTeacher">
              <div class="col-12 text-center">
                <button
                  *ngIf="finish===undefined && !checkattemptislimited && !checkattemptisopen && !checkattemptisfinish &&!checknoquestion"
                  [disabled]="checkautoabandon || totalQuestionAnswered < sumQuestionMustGet" type="button" (click)="openConfirmPopUp()" class="btn btn-primary">Nộp bài thi
                </button>
              </div>
              <hr>
            </div>
            <div class="overflow-auto" style="height: 43vh; padding-left:1rem;" >
              <div *ngFor="let question of listQuestion; let i = index">
                <div [ngClass]="{
                            'square-gray': question.condition==1,
                            'square': question.condition==2 || question.condition==1 || question.condition==3,
                            'square-orange': question.condition==3}" (click)="nextToElement(question.slot)">
                  <div class="content">{{i+1}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- End #main -->
    </div>
    <div class="row bg-white shadow-lg hiddenmb"  data-aos="fade-up" data-aos-delay="10" *ngIf="isShowQuiz" style="position: fixed; width: 100%; transition: 500ms; bottom:50px; padding:20px;">
      <div class="col-12 position-relative">
            <div class="row">
              <div class="col-12 ">
                <ul class="fa-ul form-inline">
                  <li><i class="fa fa-square text-primary mr-1"></i><small>Đã làm</small></li>
                  <li class="ml-2"><i class="fa fa-square text-gray-light mr-1"></i><small>Chưa làm</small></li>
                  <li class="ml-2"><i class="fa fa-square text-warning mr-1"></i><small>Xem lại</small></li>
                </ul>
              </div>
            </div>
            <div class="overflow-auto" style="height: 30vh;" >
              <div *ngFor="let question of listQuestion; let i = index">
                <div [ngClass]="{
                            'square-gray': question.condition==1,
                            'square': question.condition==2 || question.condition==1 || question.condition==3,
                            'square-orange': question.condition==3}" (click)="nextToElement(question.slot)">
                  <div class="content">{{i+1}}</div>
                </div>
              </div>
            </div>
            <div class="" style="position: absolute; top:-13px; right:5px;">
              <button id="closex"  (click)="oncChangeShowQuiz()" class="btn btn-link text-danger btn-lg"><i class="fas fa-times"></i></button>
            </div>
      </div>
    </div>
 <div class="footerbtn shadow-lg">
    <div class="float-left"><button
      *ngIf="finish===undefined && !checkattemptislimited && !checkattemptisopen && !checkattemptisfinish &&!checknoquestion"
                  [disabled]="checkautoabandon || totalQuestionAnswered < sumQuestionMustGet"  type="button" (click)="openConfirmPopUp()" class="btn btn-outline-danger btn-sm">Nộp bài thi
    </button></div>
    <div class="float-right">
      <button class="btn btn-sm btn-primary" (click)="oncChangeShowQuiz()">
        Danh sách câu
      </button>
    </div>
 </div>
  </section>
</main>
