import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import { FormBuilder, Validators , FormArray, FormGroup} from '@angular/forms';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';
@Component({
  selector: 'app-selectbox-survey',
  templateUrl: './selectbox-survey.component.html',
  styleUrls: ['./selectbox-survey.component.css']
})
export class SelectboxSurveyComponent implements OnInit {

  @Input() index: any;
  @Input() questionInput: any;
  @Output() messageEvent = new EventEmitter<any>();
  @Input() checkChooseTemplate: any;
  anwserNumber: number = 1;

  public Editor = ClassicEditor;

  editorConfig = CKEDITOR_CONFIG.NOT_UPLOAD;

  createSelectQuestion = this.fb.group({
    description: ['', [Validators.required]],
    allchoices: this.fb.array([]),
    isteacher: ['0']
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.questionInput) {
      this.createSelectQuestion.patchValue({
        description: this.questionInput.description,
        allchoices: this.questionInput.allchoices,
        isteacher: this.questionInput.isteacher
      });
      
      this.questionInput.allchoices.forEach((choice) => {
        this.addAnswer(choice);
      });
    }else{
      this.addAnswer();
    }

    this.messageEvent.emit({ form: this.createSelectQuestion, index: this.index });
    this.createSelectQuestion.valueChanges.subscribe((value) => {
      this.messageEvent.emit({ form: this.createSelectQuestion, index: this.index });
    });
  }

  get allchoices(): FormArray {
    return this.createSelectQuestion.get('allchoices') as FormArray;
  }

  addAnswer(answer?: any) {
    this.anwserNumber += 1;
    this.allchoices.push(this.newAnwser(answer));
  }

  newAnwser(answer?: any): FormGroup {
    let form = this.fb.group({
      text: ['', [Validators.required]]
    });
    if (answer) {
      form.controls['text'].setValue(answer.text);
    }
    return form;
  }

  removeAnswer(i: number) {
    this.allchoices.removeAt(i);
  }

  onKeydown(event){
    event.preventDefault();
  }
}
