import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'ngx-webstorage';
import { USER_FUNCTIONS, USER_INFO } from 'src/app/shared/constants/base.constant';
import {PageEvent} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TreeViewComponent} from '@syncfusion/ej2-angular-navigations';
import {CourseService} from '../../../shared/services/course.service';
import {ToastrCustomService} from './../../../shared/services/toastr-custom.service';
import {ActivatedRoute} from '@angular/router';
import {PositionService} from '../../../shared/services/position.service';
import {DepartmentService} from '../../../shared/services/department.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import {Department} from 'src/app/shared/model/department.model';
import {AddSingleUserComponent} from './add-single-user/add-single-user.component';
import {DataService} from 'src/app/shared/services/data.service';
import {SurveyService} from '../../../shared/services/survey.service';
import {MatDrawer} from '@angular/material/sidenav';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit, OnDestroy {
  @Input() isNotify: boolean;
  @Input() isServey: boolean;
  // MLMS 668
  @Input() courseDetail;
  @Output() departmentidsEmit = new EventEmitter();
  @Output() positionidsEmit = new EventEmitter();
  @ViewChild(MatDrawer, {static: true}) drawer: MatDrawer;
  @ViewChild('departmentTree')
  public departmentTree: TreeViewComponent;
  checkChooseDepartment: boolean;
  checkNotChooseDepartment: boolean;
  departmentList: Department[] = [];
  departmentId = 0;
  positions: any;
  positionIds: number[] = [];
  departmentIds: number[] = [];
  courseId: number;
  students = new Array();
  studentsOutCourse: any;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  completedAll = false;
  lastSort: any;
  departmentTreeData: any;
  currentSelectedId: number;
  selectedDepartment: boolean;
  selectedPosition: boolean;
  studentIds: number[] = [];
  studentIdsChecked: number[] = [];
  disableAddButton = true;
  happening: string;
  currentDate: Date = new Date();
  role: string;

  // MatPaginator Output
  pageEvent: PageEvent;
  survey = {
    id: null,
    questionnaireid: null,
    course: null
  };

  searchUser = {
    search : '',
    pageIndex: 1,
    pageSize: 10
  };

  constructor(
    private departmentService: DepartmentService,
    private programPositionService: PositionService,
    private toastrService: ToastrCustomService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private dataService: DataService,
    private surveyService: SurveyService,
    private $localStorage: LocalStorageService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.role = this.$localStorage.retrieve(USER_INFO.ROLES)[0].shortname;
    if (this.isNotify) {
      this.drawer?.toggle(true).then(r => {
      });
    }
    if (this.happening === undefined || this.happening === null) {
      this.route.queryParams.subscribe(params => {
        if (params) {
          this.happening = params.happening;
        }
      });
    }
    if (this.route.snapshot.routeConfig.path === 'survey/single-detail/:id') {
      this.survey.questionnaireid = Number(this.route.snapshot.paramMap.get('id'));
      this.getInforSurvey(this.survey.questionnaireid);
    } else {
      this.courseId = Number(this.route.snapshot.paramMap.get('id'));

      // load lại danh sách học viên trong khóa học khi thực hiện gán giảng viên vào khóa học
      this.dataService.assignTeacherSuccess?.subscribe(message => {
        if (message) {
          this.getListStudentsOnCourse();
        }
      });
      this.getListPosition();
      this.getListAllTree();
      this.getListStudentsOnCourse();
    }

  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  getListPosition() {
    this.spinner.show();
    return this.programPositionService.getPosition().subscribe((data) => {
      this.spinner.hide();
      this.positions = data.body.results;
      this.getListPositionOnCourse(data.body.results);
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  getListAllTree() {
    this.spinner.show();
    this.departmentService.getUserDepartmentTree(1).subscribe((data) => {
      this.departmentList = data.body;
      this.departmentTreeData = {
        dataSource: this.departmentList,
        id: 'id',
        parentID: 'parentid',
        text: 'name',
        hasChildren: 'haschild'
      };
      this.getListDepartmentOnCourse();
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  getListStudentsOnCourse() {
    if (!this.courseId) {
      return;
    }
    const params = {
      limit: this.searchUser.pageSize,
      page: this.searchUser.pageIndex,
      courseid: this.courseId,
      search: this.searchUser.search
    };
    this.spinner.show();
    this.studentIds = [];
    this.courseService.listStudentsOnCourse(params).subscribe((data) => {
      data.body?.results?.forEach(c => {
        if (this.studentIdsChecked?.includes(c.id)) {
          c.completed = true;
          this.studentIds?.push(c.id);
        }
      });
      if (this.studentIds?.length > 0 && (this.studentIds?.length === data.body?.results?.length)) {
        this.completedAll = true;
      } else {
        this.completedAll = false;
      }
      this.students = data.body.results;
      this.totalRecord = data.body.total;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  getListDepartmentOnCourse() {
    if (!this.courseId) {
      return;
    }
    const params = {courseid: this.courseId};
    this.spinner.show();
    this.courseService.listDepartmentOnCourse(params).subscribe((data) => {
      data.body.results?.forEach((item) => {
        this.departmentTree.checkedNodes = [...this.departmentTree?.checkedNodes, item.id.toString()];
        // Luc truoc dung bien nay de xac dinh node nao duoc check. Nhung bay h search truc tiep trên tree -> khong can thiet nua
        // this.departmentIds.push(item.id);
      });
      // MLMS-1089 role admin/QLDv tạo khảo sát độc lập đang tự động gán đơn vị nó quản lý
      // -> Bo tu dong gan cho dong bo voi man hinh gan don vi/ chuc danh trong QL khoa hoc/ ky thi
      // if (this.departmentIds.length > 0 && this.students.length === 0 && this.isServey){
      //   this.assignDepartmentPositionTocourse();
      // }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  getListPositionOnCourse(positions: any) {
    if (!this.courseId) {
      return;
    }
    const params = {courseid: this.courseId};
    this.spinner.show();
    this.courseService.listPositionOnCourse(params).subscribe((data) => {
      positions.forEach((po) => {
        data.body.results.forEach((item) => {
          if (item.id === po.id) {
            po.checked = true;
            this.positionIds.push(po.id);
          }
        });
      });
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  assignDepartmentPositionTocourse() {
    if (!this.courseId) {
      return;
    }
    this.spinner.show();
    const departmentIds = [];
    this.departmentTree.checkedNodes.forEach((node) => {
      if (departmentIds.indexOf(parseInt(node)) === -1) {
        departmentIds.push(parseInt(node));
      }
    });

    // MLMS-1096: Dang dung bien local departmentIds (trong ham assignDepartmentPositionTocourse()) de xac dinh id duoc check
    // -> nên khong su dung bien global this.departmentIds nua.
    // -> Tranh case sau: CHU Y: dung tai khoan QLDV
    // B1: Tạo mới khảo sát độc lập
    // B2: Chọn tab Danh sách đơn vị/chức danh
    // B3: Chọn chức danh KHONG co trong don vi -> Click button lua chon
    // B4: Chọn tất cả đơn vị -> Click button lua chon
    // Luc nay thi (this.students.length === 0 && this.isServey) === true, nhung do da khong su dung bien global this.departmentIds nua
    // nen khi gan departmentIds = this.departmentIds -> click "Lua chon" se khong co student nao phu hop de gan
    // if (this.students.length === 0 && this.isServey){
    //   departmentIds = this.departmentIds;
    // }

    this.courseService.assignDepartmentPositionTocourse(this.courseId, departmentIds, this.positionIds).subscribe((data) => {
      this.searchUser.pageIndex = 1;
      this.getListStudentsOnCourse();
      this.spinner.hide();
      this.toastrService.success('common.noti.assign_student_success');
    },
    err => {
      this.spinner.hide();
      this.toastrService.handlerError(err);
    });

  }

  assignDepartmentPositionToNotify() {
    // const data = {
    //   departmentids: departmentIds,
    //   positionids: this.positionIds
    // };
    // this.dataService.changeMessageByNotify(data);
    // this.toastrService.success('common.noti.assign_department_position_success');
    // console.log(departmentIds);
    // console.log(this.positionIds);
  }

  nodeSelected(event) {
    this.selectedDepartment = this.departmentTree.checkedNodes.length > 0;
    if (this.departmentTree.selectedNodes.length > 0) { this.currentSelectedId = +this.departmentTree.selectedNodes[0]; }
    else { this.currentSelectedId = null; }
    if (this.isNotify) {
      const departmentIds = [];
      this.departmentTree.checkedNodes.forEach((node) => {
        if (departmentIds.indexOf(parseInt(node)) === -1) {
          departmentIds.push(parseInt(node));
        }
      });
      this.departmentidsEmit.emit(departmentIds);
    }
  }

  addPositionIds(positionId: number, checked: boolean) {
    if (checked && this.positionIds.indexOf(positionId) === -1) {
      this.positionIds.push(positionId);
    } else if (!checked) {
      this.positionIds.splice(this.positionIds.indexOf(positionId), 1);
    }
    this.selectedPosition = this.positionIds.length > 0;
    if (this.isNotify) {
      this.positionidsEmit.emit(this.positionIds);
    }
  }

  openAddSingleStudentPopup() {
    if (!this.isNotify) {
      const modalRef = this.modalService.open(AddSingleUserComponent, {
        size: 'xl',
        centered: true,
        backdrop: 'static',
        scrollable: false
      });
      modalRef.componentInstance.courseId = this.courseId;
      modalRef.result.then((studentIds) => {
        if (studentIds) {
          this.spinner.show();
          this.courseService.assignStudentToCourse(this.courseId, studentIds).subscribe((data) => {
              this.toastrService.success('common.noti.assign_student_success');
              this.getListStudentsOnCourse();
              this.spinner.hide();
            },
            err => {
              this.spinner.hide();
              this.toastrService.handlerError(err);
            });
        }
      });
    }
  }

  onUnassignStudentOnCourse(studentId: number) {
    this.unAssignStudentOnCourse([studentId]);
  }

  onUnassignStudentsOnCourse() {
    if (this.isNotify) {
      // console.log('isNotify', this.isNotify);
    } else {
      this.unAssignStudentOnCourse(this.studentIdsChecked);
    }
  }

  private unAssignStudentOnCourse(studentIds?: number[]) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.title = this.translateService.instant('unassign_student.delete_confirm_title');
    modalRef.componentInstance.body = this.translateService.instant('unassign_student.delete_confirm_content');
    modalRef.componentInstance.confirmButton = this.translateService.instant('common.unassign');
    modalRef.result.then((result) => {
      if (result === 'confirm' && studentIds.length > 0) {
        this.spinner.show();
        this.courseService.unAssignStudentOnCourse(this.courseId, studentIds).subscribe((data) => {
            this.toastrService.success('common.noti.unassign_student_success');
            this.getListStudentsOnCourse();
            [...studentIds]?.forEach(studentId => {
              this.studentIds.splice(this.studentIds.indexOf(studentId), 1);
              this.studentIdsChecked.splice(this.studentIdsChecked.indexOf(studentId), 1);
            });
            this.spinner.hide();
          },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          });
      }
    });
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.students.forEach(c => {
        c.completed = checked;
        if (!this.studentIds.includes(c.id)) {
          this.studentIds.push(c.id);
        }
        if (!this.studentIdsChecked?.includes(c.id)) {
          this.studentIdsChecked?.push(c.id);
        }
      });
      this.completedAll = true;
    } else {
      this.studentIds?.forEach(id => {
        this.studentIdsChecked?.splice(this.studentIdsChecked?.indexOf(id), 1);
      });
      this.studentIds = [];
      this.students?.forEach(c => {
        c.completed = false;
      });
      this.completedAll = false;
    }
  }

  oncheckboxItem(courseId: number, checked: any) {
    if (checked) {
      this.students?.forEach(c => {
        if (c.id === courseId) {
          c.completed = true;
          this.studentIds?.push(courseId);
          this.studentIdsChecked?.push(courseId);
          return;
        }
      });
      if (this.studentIds?.length > 0 && this.studentIds?.length === this.students?.length && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.completedAll = false;
      this.students?.forEach(c => {
        if (c.id === courseId) {
          c.completed = false;
          this.studentIds?.splice(this.studentIds?.indexOf(courseId), 1);
          this.studentIdsChecked?.splice(this.studentIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
  }

  getInforSurvey(idSurvey: any) {
    this.surveyService.getDetailSurvey(idSurvey).subscribe(response => {
      this.survey = response.body;
      this.courseId = this.survey.course;
      this.getListPosition();
      this.getListAllTree();
      this.searchUser.pageIndex = 1;
      this.getListStudentsOnCourse();
    });
  }

  onSearchStudent(search: string) {
    this.searchUser.pageIndex = 1;
    this.searchUser.search = search;
    this.studentIdsChecked = [];
    this.getListStudentsOnCourse();
  }

  checkRoleFunction(userFunction: any, role: any): boolean{
    return USER_FUNCTIONS[userFunction].includes(role);
  }

  changePage(event){
    this.searchUser.pageIndex = event.pageIndex + 1;
    this.searchUser.pageSize = event.pageSize;
    this.getListStudentsOnCourse();
  }

}
