import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import { FormBuilder, Validators } from '@angular/forms';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';
import {CustomValidators} from '../../../shared/utils/custom-validators';

@Component({
  selector: 'app-rate-survey',
  templateUrl: './rate-survey.component.html',
  styleUrls: ['./rate-survey.component.css']
})
export class RateSurveyComponent implements OnInit {

  //  @Input() isCopy: any;
  @Input() index: any;
  @Input() questionInput: any;
  @Output() messageEvent = new EventEmitter<any>();
  //  @Input() cmid: any;
  @Input() sid: any;
  @Input() checkChooseTemplate: any;
  public Editor = ClassicEditor;

  editorConfig = CKEDITOR_CONFIG.NOT_UPLOAD;

  createRateQuestion = this.fb.group({
    description: ['', [Validators.required]],
    length: ['5', [CustomValidators.checkRangeValue(1, 10), Validators.required]],
    allnameddegrees: [''],
    isteacher: ['0']
  });

  constructor(private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    if (this.questionInput) {
      this.createRateQuestion.patchValue({
        description: this.questionInput.description,
        length: this.questionInput.length,
        allnameddegrees: this.questionInput.extradata,
        isteacher: this.questionInput.isteacher
      });
    }

    this.messageEvent.emit({ form: this.createRateQuestion, index: this.index });
    this.createRateQuestion.valueChanges.subscribe((value) => {
      this.messageEvent.emit({ form: this.createRateQuestion, index: this.index });
    });
  }
}
