<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 *ngIf="!survey.id">Tạo mới khảo sát</h1>
        <h1 *ngIf="survey.id">Cập nhật khảo sát</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a (click)="back()" style="cursor: pointer; color: #09889c;">Quản lý khảo sát</a>
          </li>
          <li class="breadcrumb-item active" *ngIf="!survey.id">Tạo mới khảo sát</li>
          <li class="breadcrumb-item active" *ngIf="survey.id">Cập nhật khảo sát</li>
        </ol>
      </div>
    </div>
    <div class="row pl-5 pr-5">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Thông tin bài khảo sát
          </h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <form [formGroup]="createSurveyForm">
            <div class="row pt-3 bg-white pb-3">
              <div class="col-3 text-right mb-2">
                <p>Mẫu khảo sát</p>
              </div>
              <div class="col-8">
                <mat-radio-group aria-label="Select an option">
                  <small *ngIf="checkChooseTemplate">Mẫu dùng chung</small>
                  <small *ngIf="!checkChooseTemplate">Mẫu độc lập</small>
                </mat-radio-group>
              </div>
              <!-- <div class="col-3 text-right mb-1" *ngIf="!checkChooseTemplate && (idSurveyUpdate || survey.id)"><p>Đơn vị khảo
                sát <span class="text-danger">*</span></p></div>
              <div class="col-8 mb-2" *ngIf="!checkChooseTemplate  && idSurveyUpdate">

                <select class="form-control select2" style="width: 100%;" formControlName="parentid"
                        (change)="selectDepartment()">
                  <option value="" >Chọn đơn vị khảo sát</option>
                  <option *ngFor="let item of departmentMap | keyvalue" [value]="item.key"
                          [disabled]="isDisable">{{item.value}}</option>
                </select>
                <div
                  *ngIf="createSurveyForm.get('parentid')!.invalid &&(createSurveyForm.get('parentid')!.dirty || createSurveyForm.get('parentid')!.touched)">
                  <small class="text-danger" *ngIf="createSurveyForm.get('parentid')?.errors?.required">
                    {{ 'error.validate.input_required' | translate }}
                  </small>
                </div>
                <small class="text-danger"
                       *ngIf="selectedDepartment">{{ 'user.department_choose' | translate }}</small>

              </div> -->
              <div class="col-3 text-right mb-1">
                <p>Tên bài khảo sát <span class="text-danger">*</span></p>
              </div>
              <div class="col-8">
                <input name="name" type="text" class="form-control" placeholder="Nhập tên bài..."
                  formControlName="name" />
                <div
                  *ngIf="createSurveyForm.get('name')!.invalid &&(createSurveyForm.get('name')!.dirty || createSurveyForm.get('name')!.touched)">
                  <small class="text-danger" *ngIf="createSurveyForm.get('name')?.errors?.required">
                    {{ 'error.validate.input_required' | translate }}
                  </small>
                  <small class="text-danger"
                    *ngIf="createSurveyForm.get('name')?.errors && !createSurveyForm.get('name')?.errors?.required">
                    {{ 'error.validate.course.nameMaxlength' | translate }}
                  </small>
                </div>


              </div>
              <ng-container *ngIf="!checkChooseTemplate">
                <div class="col-3 mt-3 text-right mb-1">Thời gian bắt đầu <span class="text-danger">*</span></div>
                <div class="col-8 mt-3">
                  <div class="row">
                    <div class="col-auto">
                      <select class="custom-select" formControlName="hourStartDate">
                        <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
                      </select>
                    </div>
                    <div class="col-auto">
                      <select class="custom-select" formControlName="minuteStartDate">
                        <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field>
                        <mat-label>Chọn ngày</mat-label>
                        <input matInput [matDatepicker]="startdate" formControlName="startdate"
                          [min]="currentDate">
                        <mat-datepicker-toggle matSuffix [for]="startdate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #startdate></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-12"
                      *ngIf="createSurveyForm.get('startdate')!.invalid &&(createSurveyForm.get('startdate')!.dirty || createSurveyForm.get('startdate')!.touched)">
                      <small class="text-danger" *ngIf="createSurveyForm.get('startdate')?.errors?.matDatepickerMin">
                        {{ 'error.validate.start_date_invalid' | translate }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-3 mt-3 text-right mb-1">Thời gian kết thúc <span class="text-danger">*</span></div>
                <div class="col-8 mt-3">
                  <div class="row">
                    <div class="col-auto">
                      <select class="custom-select" formControlName="hoursEndDate">
                        <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
                      </select>
                    </div>
                    <div class="col-auto">
                      <select class="custom-select" formControlName="minuteEndDate">
                        <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field>
                        <mat-label>Chọn ngày</mat-label>
                        <input matInput [matDatepicker]="enddate" formControlName="enddate"
                          [min]="currentDate">
                        <mat-datepicker-toggle matSuffix [for]="enddate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #enddate></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-12"
                      *ngIf="createSurveyForm.get('enddate')!.invalid &&(createSurveyForm.get('enddate')!.dirty || createSurveyForm.get('enddate')!.touched)">
                      <small class="text-danger" *ngIf="createSurveyForm.get('enddate')?.errors?.matDatepickerMin">
                        {{ 'error.validate.end_date_invalid' | translate }}
                      </small>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- <div class="col-3 mt-3 text-right mb-1">Số lần tham gia <span class="text-danger">*</span></div>
              <div class="col-7 mt-3">
                <select class="custom-select" name="attempts"
                        formControlName="qtype">
                  <option value="0">1</option>
                  <option value="1">2</option>
                  <option value="2">3</option>
                </select>
              </div> -->
              <div class="col-12 mt-3 text-center">
                <button type="button" class="btn btn-default round-0 mr-2" (click)="back()"><i
                    class="fas fa-close"></i>Hủy
                </button>
                <button class="btn btn-primary" (click)="createNewSurvey()" type="button" [disabled]="createSurveyForm.status === 'INVALID'">
                  <i class="fas fa-save mr-2"></i>{{buttonText | translate}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row pl-5 pr-5">
      <div class="card ">
        <div class="card-header">
          <h3 class="card-title">
            Nội dung khảo sát
          </h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="container">
                <div class="btn-sticky shadow ">
                  <div aria-label="Vertical button group" role="group" class="btn-group-vertical">
                    <button type="button" matTooltip="text" matTooltipClass="tooltip-red" [disabled]="survey.isactive"
                      class="btn btn-default btn-lg " (click)="addQuestion('textquestion')"><i
                        class="fas fa-spell-check" style="font-size:14pt;"></i>
                    </button>

                    <button type="button" matTooltipClass="tooltip-red" matTooltip="Câu hỏi lựa chọn đáp án"
                      [disabled]="survey.isactive" class="btn btn-default btn-lg "
                      (click)="addQuestion('selectquesiton')"><i class="far fa-question-circle "
                        style="font-size:14pt;"></i>
                    </button>

                    <button type="button" matTooltipClass="tooltip-red" matTooltip="Bình chọn"
                      [disabled]="survey.isactive" class="btn btn-default btn-lg "
                      (click)="addQuestion('ratequestion')"><i class="fas fa-star-half-alt" style="font-size:14pt;"></i>
                    </button>

                  </div>
                </div>

                <div class="row d-flex">
                  <div class="col-12 align-self-center p-2">
                    <ng-container *ngIf="checkChooseTemplate">
                      <div class="col-12">
                        <div class="card-body" style="display: block;">
                          <h6>Danh sách câu khảo sát dành cho khóa học</h6>
                          <table class="table table-hover">
                            <thead>
                              <th>Câu hỏi</th>
                              <th>{{'common.action' | translate}}</th>
                            </thead>
                            <tbody>
                              <tr *ngFor="let question of survey.listquestion; let i = index;">
                                <td *ngIf="question.type === textType && question.isteacher == 0">
                                  <span [innerHTML]=question.content></span>
                                </td>
                                <td *ngIf="question.type === radioType && question.isteacher == 0">
                                  <span [innerHTML]=question.content></span>
                                  <div>
                                    <mat-radio-group aria-label="Select an option">
                                      <mat-radio-button class="ml-2" *ngFor="let choice of question.choices"
                                        [value]="choice.id">
                                        <small>{{choice.content}}</small>
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                </td>

                                <td *ngIf="question.type === rateType && question.isteacher == 0">
                                  <span [innerHTML]=question.content></span>
                                  <div *ngFor="let choice of question.choices">
                                    <small style="margin-right: 1%;">{{choice.content}}</small>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                  </div>
                                </td>
                                <td style="width:100px;" *ngIf="question.isteacher == 0">
                                  <button type="button" matTooltip="Chỉnh sửa" matTooltipClass="tooltip-red"
                                    [disabled]="survey.isactive" (click)="openEditQuestion(question)"
                                    class="btn btn-default btn-sm mr-1"><i class="fas fa-edit"></i></button>
                                  <button type="button" matTooltip="Xóa" matTooltipClass="tooltip-red"
                                    [disabled]="survey.isactive" (click)="onDeleteQuestion(question.id)"
                                    class="btn btn-default btn-sm mr-1"><i class="fas fa-trash"></i></button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!-- danh sách câu hỏi cho giảng viên-->
                      <div class="col-12">
                        <div class="card-body" style="display: block;">
                          <h6>Danh sách câu khảo sát dành cho giảng viên</h6>
                          <table class="table table-hover">
                            <thead>
                              <th>Câu hỏi</th>
                              <th>{{'common.action' | translate}}</th>
                            </thead>
                            <tbody>
                              <tr *ngFor="let question of survey.listquestion; let i = index;">
                                <td *ngIf="question.type === textType && question.isteacher == 1">
                                  <span [innerHTML]=question.content></span>
                                </td>
                                <td *ngIf="question.type === radioType && question.isteacher == 1">
                                  <span [innerHTML]=question.content></span>
                                  <div>
                                    <mat-radio-group aria-label="Select an option">
                                      <mat-radio-button class="ml-2" *ngFor="let choice of question.choices"
                                        [value]="choice.id">
                                        <small>{{choice.content}}</small>
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                </td>
                                <td *ngIf="question.type === rateType && question.isteacher == 1">
                                  <span [innerHTML]=question.content></span>
                                  <div *ngFor="let choice of question.choices">
                                    <small style="margin-right: 1%;">{{choice.content}}</small>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                  </div>
                                </td>
                                <td style="width:100px;" *ngIf="question.isteacher == 1">
                                  <button type="button" matTooltip="Chỉnh sửa" matTooltipClass="tooltip-red"
                                    [disabled]="survey.isactive" (click)="openEditQuestion(question)"
                                    class="btn btn-default btn-sm mr-1"><i class="fas fa-edit"></i></button>
                                  <button type="button" matTooltip="Xóa" matTooltipClass="tooltip-red"
                                    [disabled]="survey.isactive" (click)="onDeleteQuestion(question.id)"
                                    class="btn btn-default btn-sm mr-1"><i class="fas fa-trash"></i></button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!checkChooseTemplate">
                      <div class="col-12">
                        <div class="card-body" style="display: block;">
                          <!--  <h6 style="margin-top: 5%;"></h6> -->
                          <table class="table table-hover">
                            <thead>
                              <th>Câu hỏi</th>
                              <th>{{'common.action' | translate}}</th>
                            </thead>
                            <tbody>
                              <tr *ngFor="let question of survey.listquestion; let i = index;">
                                <td *ngIf="question.type === textType">
                                  <span [innerHTML]=question.content></span>
                                </td>


                                <td *ngIf="question.type === radioType">
                                  <span [innerHTML]=question.content></span>
                                  <div>
                                    <mat-radio-group aria-label="Select an option">
                                      <mat-radio-button *ngFor="let choice of question.choices" [value]="choice.id"
                                        class="ml-2">
                                        <small>{{choice.content}}</small>
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                </td>

                                <td *ngIf="question.type === rateType">
                                  <span [innerHTML]=question.content></span>
                                  <div *ngFor="let choice of question.choices">
                                    <small style="margin-right: 1%;">{{choice.content}}</small>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                  </div>
                                </td>
                                <td style="width:100px;">
                                  <button type="button" matTooltip="Chỉnh sửa" matTooltipClass="tooltip-red"
                                    [disabled]="survey.isactive" (click)="openEditQuestion(question)"
                                    class="btn btn-default btn-sm mr-1"><i class="fas fa-edit"></i></button>
                                  <button type="button" matTooltip="Xóa" matTooltipClass="tooltip-red"
                                    [disabled]="survey.isactive" (click)="onDeleteQuestion(question.id)"
                                    class="btn btn-default btn-sm mr-1"><i class="fas fa-trash"></i></button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ng-container>
                    <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
                      <mat-expansion-panel *ngFor="let panel of accordions; let i = index" cdkDrag
                        (opened)="onOpenPanel(i)" (closed)="onClosePanel(i)" [cdkDragDisabled]="panel.isOpen">
                        <mat-expansion-panel-header>
                          <mat-panel-title class="right-aligned-header">{{i+1}}</mat-panel-title>
                          <mat-icon class="mr-1 icon_drag">swap_vert</mat-icon>
                          <mat-panel-description>Câu hỏi {{i+1}} <span class="badge badge-danger"
                              *ngIf="!panel.valid">Chưa nhập</span></mat-panel-description>
                        </mat-expansion-panel-header>

                        <ng-container *ngIf="panel.type == 'textquestion'">
                          <app-text-question-survey [index]="i" [questionInput]="panel.question"
                            [checkChooseTemplate]="checkChooseTemplate" (messageEvent)="receiveText($event)">
                          </app-text-question-survey>
                        </ng-container>

                        <!--question select-->
                        <ng-container *ngIf="panel.type == 'selectquesiton'">
                          <app-selectbox-survey [index]="i" [questionInput]="panel.question"
                            [checkChooseTemplate]="checkChooseTemplate" (messageEvent)="receiveSelect($event)">
                          </app-selectbox-survey>
                        </ng-container>

                        <!--question rate-->
                        <ng-container *ngIf="panel.type == 'ratequestion'">
                          <app-rate-survey [index]="i" [questionInput]="panel.question"
                            [checkChooseTemplate]="checkChooseTemplate" (messageEvent)="receiveRate($event)">
                          </app-rate-survey>
                        </ng-container>


                        <mat-action-row>
                          <button type="button" matTooltip="Sao chép" (click)="addQuestion(panel.type, i)"
                            matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i
                              class="fas fa-copy"></i></button>
                          <button type="button" matTooltip="Lưu" *ngIf="!panel.question?.id"
                            (click)="onSaveQuestion(panel)" matTooltipClass="tooltip-red"
                            class="btn btn-default btn-lg"><i class="fas fa-save mr-1"></i></button>
                          <button type="button" matTooltip="Xóa" (click)="removeQuestion(panel)"
                            matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i
                              class="fas fa-trash"></i></button>
                        </mat-action-row>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-3 mb-3 text-center">
          <!-- <button type="button" class="btn btn-primary" (click)="publishSurvey()" [disabled]="notCreateSurvey">
            <i class="fas fa-start mr-1"></i>{{buttonPublish | translate}}</button> -->
          <!-- <button type="button" class="btn btn-primary" style="margin-left: 1%;" [disabled]="accordions.length == 0 " (click)="createQuestions()">
            <i class="fas fa-start mr-1"></i>{{'survey.save_all_question' | translate}}
          </button> -->
        </div>
      </div>

    </div>
  </div>
</section>
