import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserInfo} from '../../shared/model/user-info.model';
import {UserService} from '../../shared/services/user.service';
import {USER_INFO} from '../../shared/constants/base.constant';
import {LocalStorageService} from 'ngx-webstorage';
import {LoginService} from '../../shared/services/login.service';
import {ConfirmModalComponent} from '../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {CustomValidators} from '../../shared/utils/custom-validators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  user: UserInfo;

  changePasswordForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    public userService: UserService,
    private $localStorage: LocalStorageService,
    private loginService: LoginService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user = this.$localStorage.retrieve(USER_INFO.INFO);
    this.setFormData();
  }

  setFormData(){
    this.changePasswordForm = this.fb.group({
      currentpassword: ['', Validators.required],
      newpassword: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!@#$%^&*).{8,20}$')
      ])],
      confirmpassword: ['', Validators.required]
    },
      {
        validators: CustomValidators.matchPassword()
      });
  }


  showConfirmPopup(){
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('euchangepassword.warning_title');
    // tslint:disable-next-line:max-line-length
    modalDep.componentInstance.body = this.translateService.instant('euchangepassword.warning_logout');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.confirm');

    modalDep.result.then((result) => {
      this.changePassword();
    });
  }


  changePassword(){
    const params = {
      currentpassword: this.changePasswordForm.value.currentpassword,
      newpassword: this.changePasswordForm.value.newpassword,
      confirmpassword: this.changePasswordForm.value.confirmpassword
    };
    this.spinner.show();
    this.userService.changeOwnPassword(params).subscribe(data => {
        this.toastrService.success(`common.noti.change_password_successful`);
        // thay doi password xong logout
        this.loginService.logout();
        this.spinner.hide();
    },
      error => { // loi thi khong dong popup
      this.spinner.hide();
      this.toastrService.handlerError(error);
      });
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  cancel(){
    this.router.navigate(['/home']);

  }

}
