<div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Quản lý Khảo sát</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
              <li class="breadcrumb-item active">Quản lý khảo sát</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <!--phan danh sach khoa hoc-->
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label>
             Nội dung khảo sát
            </ng-template>
           <app-create-survey [checkChooseTemplate]="false" [idSurveyUpdate]="surveyId"></app-create-survey>
          </mat-tab>
          <mat-tab>
              <ng-template mat-tab-label>
                Danh sách đơn vị/chức danh
              </ng-template>
              <app-add-user [isServey]="true"></app-add-user>
          </mat-tab>
        </mat-tab-group>

      </div><!-- /.container-fluid -->
    </section>
  </div>
