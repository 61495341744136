<div class="member box-object shadow ml-2" style="position: relative;">
  <div class="member-img mb-2" *ngIf="!course.isCanRegister">
    <div class="hovereffect">
      <img
        class="img-responsive"
        [src]="convertLinkimg(course.filename,course.filearea,course.contextid)"
        class="img-fluid rounded"
        alt=""
      />
      <div class="step shadow" *ngIf="courseInPosition">{{index}}</div>
      <a class="overlay" (click)="onRedirectToCourseDetail()">
        <h2>{{course.timecourse? course.timecourse : 'Đang cập nhật'}}</h2>
        <p>
          <a>{{course.status}}</a>
        </p>
      </a>
    </div>
    <img />
  </div>
  <div class="member-img mb-2" *ngIf="course.isCanRegister">
    <div class="hovereffect">
      <img
        class="img-responsive"
        [src]="convertLinkimg(course.filename,course.filearea,course.contextid)"
        class="img-fluid rounded"
        alt=""
      />
      <div class="overlay">
        <h2>{{course.timecourse? course.timecourse : 'Đang cập nhật'}}</h2>
        <p>
          <span>{{course.status}}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="member-info p-2">
    <h5 style="margin-top:10px; float:left;">
      <a>{{course?.fullname?.length <= 60 ? course?.fullname : (course?.fullname | slice:0:50) + '...'}}</a>
    </h5>
      <!-- <span style="font-size: 10pt; text-align: justify;" ><b>Giảng viên: </b> <span>{{convertTeacherName(course.teachers)?.length <= 30 ? convertTeacherName(course.teachers): (convertTeacherName(course.teachers)| slice:0:30) + '...' }}</span>
    </span> -->
    <!-- <p style="font-size: 10pt; text-align: justify;">{{course.summary}}</p> -->
  </div>
  <div class="row pl-2 pr-2">
    <div class="col-md-12">
      <span style="font-size: 10pt; text-align: justify;" ><b>Giảng viên: </b> <span>{{convertTeacherName(course.teachers)?.length <= 30 ? convertTeacherName(course.teachers): (convertTeacherName(course.teachers)| slice:0:30) + '...' }}</span>
    </span> <br>
      <a href="javascript:;" class="linkedin">
        <i class="icofont-calendar mr-2"></i>
        <span style="font-size: 10pt;">{{course._startdate | date:'dd/MM/yyyy'}} - {{course._enddate | date:'dd/MM/yyyy'}} </span></a>
    </div>
    <div class="col-md-12">
      <a href="javascript:;" class="linkedin">
        <i class="icofont-notebook mr-2"></i>
        <span style="font-size: 10pt;">{{course.categoryname}}</span></a>
    </div>
  </div>
  <div class="row m-1" *ngIf="!isTeacher && !course?.isCanRegister">
    <div class="progress mt-1 mb-2" style="width: 100%;">
      <div class="progress-bar progress-bar-success" role="progressbar" attr.aria-valuenow="{{course?.completion ? course?.completion : 0}}"
      aria-valuemin="0"
      aria-valuemax="100" [ngStyle]="{'width.%': course?.completion ? course?.completion : 0}">{{course?.completion ? course?.completion : 0}}% Hoàn thành
    </div>
    </div>
  </div>
  <div class="row m-1" *ngIf="course?.isCanRegister">
    <div class="col-12">
    <button type="button" class="btn btn-outline-primary w-100 mt-1" (click)="onRegisterCourse()">Đăng ký khóa học</button>
  </div>
  </div>
  <div *ngIf="course?.enrol === 'self'" class="course_reigister_a">
    <a href="javascript:;" class="text-white">
      <i class="fas fa-check-circle mr-2 text-warning"></i>
      <span style="font-size: 10pt;">Khóa học tự đăng ký</span></a>
  </div>
</div>
