<div class="container-fluid" *ngIf="!showCreateNotiForm">
  <!--phan danh sach khoa hoc-->
  <div class="col-12">
    <div class="card card-default">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
          </div>
          <div class="col-9">
            <form class="form-inline float-right">
              <div class="form-group m-2">
                <label for="" class="mr-2">Tìm kiếm</label>
                <input
                  type="search"
                  class="form-control mr-2"
                  placeholder="Từ khóa..."
                  (change)="searchNotify()"
                  name="search" [(ngModel)]="searchNoti.search"
                />
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
    <!--ket thuc tieu chi tim kiem-->
  </div>
  <div class="col-12">
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title">Danh sách thông báo</h3>
        <div class="card-tools">
          <button
            type="button"
            class="btn btn-tool"
            data-card-widget="collapse"
          >
            <i class="fas fa-minus"></i>
          </button>
        </div>
      </div>
      <div class="card-body" style="display: block">
        <div class="col-12 table-responsive">
          <table class="table table-hover" matSort (matSortChange)="sortData($event)">
            <thead>
            <tr>
              <th>STT</th>
<!--              id, , templatetype, content, updateduser, updateduserfullname-->
              <th mat-sort-header="title">Tên thông báo</th>
              <th mat-sort-header="templatetype">Mẫu</th>
              <th>Trạng thái</th>
              <th mat-sort-header="updatedat">Ngày tạo</th>
              <th>Thao tác</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let notify of listNotify; let i = index;">
              <td>{{(searchNoti.pageIndex - 1) * searchNoti.pageSize + i + 1}}</td>
              <td>{{notify.title}}</td>
              <td>{{notify.templatetype}}</td>
              <td><span [ngClass]="notify.isactive === 1 ? 'badge badge-success' : 'badge badge-secondary'">{{notify.isactive === 1 ? 'Hoạt động' : 'Không hoạt động'}}</span></td>
              <td>{{notify.updatedat | date: 'dd/MM/yyyy'}}</td>
              <td>
                  <button class="btn btn-default btn-sm" [routerLink]="['/admin/notification/detail', notify.id]" (click)="goToNotiDetail()" [routerLinkActive]="['active']" >  <i class="fas fa-edit"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
          <br>
          <p *ngIf="listNotify?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
        </div>

        <div class="card-footer text-center">
          <div class="row">
            <mat-paginator showFirstLastButtons
              [length]="totalRecord"
              [pageSize]="searchNoti?.pageSize"
              [pageIndex]="searchNoti?.pageIndex - 1"
              (page)="changePage($event)"
              [hidePageSize]="false"
              [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>
          </div>
        </div>
        <div class="text-center"></div>
      </div>
    </div>
  </div>
</div>
