import {Component, EventEmitter, isDevMode, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrCustomService} from '../shared/services/toastr-custom.service';
import {LoginService} from '../shared/services/login.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import CommonUtil from '../shared/utils/common-util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Output() loginResult = new EventEmitter<string>();

  loginForm = this.fb.group({
    username: [, Validators.required],
    password: [, Validators.required],
  });

  isConfirmed = true;

  constructor(private fb: FormBuilder,
              private toastrService: ToastrCustomService,
              private loginService: LoginService,
              private spinner: NgxSpinnerService,
              private modalService: NgbModal,
              private activeModal: NgbActiveModal,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  login() {
    if (!this.loginForm.valid) {
      CommonUtil.markFormGroupTouched(this.loginForm);
      return;
    }
    // MLMS-1090
    this.loginForm.value.username = this.loginForm.value.username.toLowerCase();
    if (!this.loginForm.value.username.match('^[-\.@_a-z0-9]+$')) {
      this.toastrService.error('error.invalidlogin');
      return;

    }

    this.spinner.show();
    this.loginService
      .login({
        ...this.loginForm.value
      }).then(
      data => {
        if (isDevMode()) {
          console.log('login done');
        }
        this.spinner.hide();
        this.toastrService.success(`login.success`);
        this.loginResult.emit('success');
        this.isConfirmed = true;
        this.close();
      },
      err => {
        this.spinner.hide();
        if (err && err.error && err.error.errorcode === 'usernotconfirmed') {
          this.isConfirmed = false;
        }
        this.toastrService.handlerError(err);

      }
    );
  }

  forgetPassword() {
    this.router.navigate(['/forgot-password']);
    this.activeModal.dismiss('close');
  }

  close() {
    this.loginForm.reset();
    this.spinner.hide();
    this.activeModal.dismiss('close');
  }

  gotoContact() {
    this.loginResult.emit('contact');
    this.close();
  }

  resendActivationEmail() {
    this.spinner.show();
    if (this.loginForm.value.username) {
      this.loginService.resendValidationEmail(this.loginForm.value.username).subscribe(res => {
          this.toastrService.success('common.noti.update_success');
          this.spinner.hide();

        },
        err => {
          this.toastrService.handlerError(err);
          this.spinner.hide();
        });
    } else {
      this.loginForm.value.username.markAsTouched();
    }
  }

}
