import {Component, Input, isDevMode, OnInit} from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {FormBuilder} from '@angular/forms';
import {NotifycationModel} from '../../../shared/model/notifycation.model';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {DataService} from '../../../shared/services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {NotificationService} from '../../../shared/services/notification.service';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';

@Component({
  selector: 'app-notify-info',
  templateUrl: './notification-info.component.html',
  styleUrls: ['./notification-info.component.css']
})
export class NotificationInfoComponent implements OnInit {

  @Input() newInforNotifi: any;
  @Input() notifyUpdate: any;
  courseIdsString: string;
  examIdsString: string;
  examids: string;
  userids: string;
  isUpdate: boolean;

  public Editor = ClassicEditor;
  editorConfig = CKEDITOR_CONFIG.NOT_UPLOAD;

  notify: NotifycationModel = {
    title: null,
    content: null,
    userids: null,
    id: null,
    useridfrom: null,
    useridto: null,
    subject: null,
    smallmessage: null,
    timecreated: null,
    timeread: null,
    fullmessage: null,
    fullmessagehtml: null,
    customdata: null,
    createdat: null,
    recipient: null,
    selected: null,
  };

  constructor(private fb: FormBuilder,
              private router: Router,
              private notifyService: NotificationService,
              private toastrService: ToastrCustomService,
              private dataService: DataService,
              private translateService: TranslateService,
              private modalService: NgbModal,
              private spinner: NgxSpinnerService) {
  }

  createFormNotify = this.fb.group({
    titleNotify: [],
    // decription: [],
    contentNoti: [],
    mau1: [],
    selectList: []
  });

  ngOnInit(): void {
    this.courseIdsString = '';
    this.examids = '';
    this.userids = '';

    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'alignment',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote',
          'tableColumn',
          'tableRow',
          'insertTable',
          'imageTextAlternative',
          'imageStyle:full',
          'imageStyle:side',
          'mergeTableCells',
          'undo',
          'redo',
        ]
      },
    };

    this.dataService.onDataChange((value) => {
      this.courseIdsString = value;
    });

    this.dataService.onDataChangeExam((value) => {
      this.examIdsString = value;
    });
    if (this.newInforNotifi !== undefined && this.newInforNotifi !== null
      && this.newInforNotifi.id > 0) {
      this.isUpdate = true;
      this.createFormNotify.patchValue({
        titleNotify: this.newInforNotifi.title,
        contentNoti: this.newInforNotifi.content
      });
    } else {
      this.newInforNotifi = {};
      this.isUpdate = false;
      this.newInforNotifi.title = '';
      this.newInforNotifi.content = '';
    }
  }

  // cap nhat thong bao template
  updateNotity() {
    const notifyUpdate: any = {
      id: this.newInforNotifi.id,
      title: this.createFormNotify.value.titleNotify,
      content: this.createFormNotify.value.contentNoti,
    };
    this.spinner.show();
    this.notifyService.updateNotifyTemplate(notifyUpdate).subscribe(res => {
      this.spinner.hide();
      if (isDevMode()) {
        console.log('notify: ', res.body);
      }
      this.toastrService.success('common.noti.update_success');
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  getName() {
    this.newInforNotifi.title = this.createFormNotify.value.titleNotify;
    this.newInforNotifi.content = this.createFormNotify.value.contentNoti;

    //ban
    this.dataService.setName = this.newInforNotifi.title;
    this.dataService.setContentNoti = this.newInforNotifi.content;
  }

  // getDescription(){
  //   this.newInforNotifi.title = this.createFormNotify.value.titleNotify;
  //   this.newInforNotifi.content = this.createFormNotify.value.contentNoti;
  //
  //   //ban
  //   this.dataService.setName = this.newInforNotifi.title;
  //   this.dataService.setContentNoti = this.newInforNotifi.content;
  // }

  getContent() {
    this.newInforNotifi.title = this.createFormNotify.value.titleNotify;
    this.newInforNotifi.content = this.createFormNotify.value.contentNoti;
    //ban
    this.dataService.setName = this.newInforNotifi.title;
    this.dataService.setContentNoti = this.newInforNotifi.content;
  }

  // gui thong bao dot xuat
  createNotity() {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('notification.send_notify_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('notification.send_notify_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.send_notify');

    modalDep.result.then((result) => {
      this.spinner.show();
      // console.log('id nhan tu course: ' + this.courseIdsString);
      // console.log('id nhan tu exam: ' + this.examIdsString);
      if (this.examIdsString === undefined || this.examIdsString === null) {
        this.examIdsString = '';
      }
      if (this.courseIdsString === undefined || this.courseIdsString === null) {
        this.courseIdsString = '';
      }
      this.notify.title = this.createFormNotify.value.titleNotify;
      this.notify.content = this.createFormNotify.value.contentNoti;

      if (this.courseIdsString == '' && this.examIdsString == '') {
        this.spinner.hide();
        alert(this.translateService.instant('notification.need_select_receiver'));
        return;
      }
      if (this.createFormNotify.value.titleNotify === '' || this.createFormNotify.value.contentNoti === ''
        || this.createFormNotify.value.titleNotify === undefined || this.createFormNotify.value.contentNoti === undefined) {
        this.spinner.hide();
        alert(this.translateService.instant('notification.need_input_content_name'));
        return;
      }
      this.notifyService.createNotifySingle(this.courseIdsString, this.createFormNotify.value.titleNotify,
        this.createFormNotify.value.contentNoti, this.examIdsString.toString())
        .subscribe(res => {
          const notify: any = res.body;
          this.spinner.hide();
          if (isDevMode()) {
            console.log('notify: ', notify);
          }
          this.router.navigate(['/admin/notification']);
          this.toastrService.success('common.noti.send_message_success');
        }, error => {
          // console.log(error);
          this.spinner.hide();
          this.toastrService.handlerError(error);
        });
    });
  }

  // gui thong bao template
  activeOrInactiveNotiTemplate() {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    if(this.newInforNotifi.isactive === 1){
      modalDep.componentInstance.title = this.translateService.instant('notification.inactive_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('notification.inactive_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.hide');
    }else{
      modalDep.componentInstance.title = this.translateService.instant('notification.active_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('notification.active_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.active');
    }

    modalDep.result.then((result) => {
      this.spinner.show();
      const notifyUpdate: any = {
        id: this.newInforNotifi.id,
        isactive: this.newInforNotifi.isactive == 1 ? 0 : 1,
      };
      this.notifyService.updateNotifyTemplate(notifyUpdate).subscribe(res => {
        this.spinner.hide();
        if (isDevMode()) {
          console.log('notify: ', res.body);
        }
        if(this.newInforNotifi.isactive == 1){
          this.toastrService.success('common.noti.inactive_notify_success');
          this.newInforNotifi.isactive = 0;
        }else{
          this.toastrService.success('common.noti.active_notify_success');
          this.newInforNotifi.isactive = 1;
        }

      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
    });
  }

  navigationPage() {
    if (this.isUpdate) {
      this.notifyService.changeMessage(0);
    } else {
      this.notifyService.changeMessage(1);
    }
    this.router.navigate(['/admin/notification']);
  }
}
