import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {BbbLogoutComponent} from './bbb-logout/bbb-logout.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {ActivationEmailComponent} from "./landing-page/activation-email/activation-email.component";


const routes: Routes = [

  {
    path: 'home',
    component: LandingPageComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'bbb_logout',
    component: BbbLogoutComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'activation-account',
    component: ActivationEmailComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
