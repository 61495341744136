import {AfterViewInit, Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {CourseCategory} from '../../shared/model/course-category.model';
import {CourseCategoryService} from '../../shared/services/course-category.service';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {TranslateService} from '@ngx-translate/core';
import {CourseCategoryEditComponent} from './course-category-edit/course-category-edit.component';
import {ConfirmModalComponent} from '../../shared/modal/confirm-modal/confirm-modal.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {TreeViewComponent} from '@syncfusion/ej2-angular-navigations';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-course-category',
  templateUrl: './course-category.component.html',
  styleUrls: ['./course-category.component.css'],
  providers: [NgbModalConfig, NgbModal],
})
export class CourseCategoryComponent implements OnInit, AfterViewInit, OnDestroy {

  // tree
  @ViewChild('categoryTree')
  public categoryTree: TreeViewComponent;
  currentSelectedId: number; // node selected
  public categoryTreeData; // data for tree

  // List category
  categories: CourseCategory[] = [];

  completedAll = false; // checkbox all
  // list id course checked trong cả màn
  courseCateIdsChecked: number[] = [];
  // list id course checked trong current page
  courseCateIds: number[] = [];

  // pagination
  totalRecord: number;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  sortColumn = 'id';
  sortType = 'ASC';
  keyword = '';


  constructor(
    private modalService: NgbModal,
    private categoryService: CourseCategoryService,
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.onSearch();
    this.getCategoryTree();
  }
  /**
   * @method ngAfterViewInit
   */
  ngAfterViewInit() {
    this.onSearch();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.categories.forEach(c => {
        c.completed = checked;
        if (!this.courseCateIds.includes(c.id)) {
          this.courseCateIds.push(c.id);
        }
        if (!this.courseCateIdsChecked?.includes(c.id)) {
          this.courseCateIdsChecked?.push(c.id);
        }
      });
      this.completedAll = true;
    } else {
      this.courseCateIds?.forEach(id => {
        this.courseCateIdsChecked?.splice(this.courseCateIdsChecked?.indexOf(id), 1);
      });
      this.courseCateIds = [];
      this.categories?.forEach(c => {
        c.completed = false;
      });
      this.completedAll = false;
    }
  }

  oncheckboxItem(courseId: number, checked: any) {
    if (checked) {
      this.categories?.forEach(c => {
        if (c.id === courseId) {
          c.completed = true;
          this.courseCateIds?.push(courseId);
          this.courseCateIdsChecked?.push(courseId);
          return;
        }
      });
      if (this.courseCateIds?.length > 0 && this.courseCateIds?.length === this.categories?.length && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.completedAll = false;
      this.categories?.forEach(c => {
        if (c.id === courseId) {
          c.completed = false;
          this.courseCateIds?.splice(this.courseCateIds?.indexOf(courseId), 1);
          this.courseCateIdsChecked?.splice(this.courseCateIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
  }

  onSearch() {
    const params = {
      limit: this.pageSize,
      page: this.pageIndex,
      keyword: this.keyword,
      id: this.currentSelectedId,
      sortcolumn: this.sortColumn,
      sorttype: this.sortType
    };
    this.spinner.show();
    this.courseCateIds = [];
    return this.categoryService.searchCourseCategory(params).subscribe((data) => {
      data?.body?.results.forEach(c => {
        if (this.courseCateIdsChecked?.includes(c.id)) {
          c.completed = true;
          this.courseCateIds?.push(c.id);
        }
      });
      if (this.courseCateIds?.length > 0 && (this.courseCateIds?.length === data?.body?.results?.length)) {
        this.completedAll = true;
      } else {
        this.completedAll = false;
      }
      this.categories = data.body.results;
      this.totalRecord = data.body.total;
      this.spinner.hide();
    }, error => {
      this.toastrService.handlerError(error);
      this.spinner.hide();
    });
  }

  getCategoryTree(){
    return this.categoryService.getCourseCategoryTree(null).subscribe((data) => {
      const dataSource = data.body;
      this.setUpTree(dataSource);
      this.categoryTreeData = { dataSource, id: 'id', parentID: 'parent', text: 'name', hasChildren: 'haschild', selected: 'isSelected' };
    });
  }

  setUpTree(dataSource: any) {

    const indexOfCurrentSelectedId = dataSource.findIndex(x => x.id === this.currentSelectedId);
    if (indexOfCurrentSelectedId === -1){// case currentSelectedId is deleted -> set to deault
      this.currentSelectedId = null;
    }

    dataSource.forEach(value => {
      if (value.parent === 0) // = 0 la root tree
      {
        value.parent = null; // set = null nham muc dich hien thi
        value.expanded = true; // muc dich expand root luon khi khoi tao tree
      } else {
        value.expanded = false;
      }

      if (value.id === this.currentSelectedId){ // high light selected node
        value.isSelected = true;
      }
    });
  }

  onUpdateCourseCategory(item){
    const current: CourseCategory = {
      id: item.id,
      name: item.name,
      description: item.description,
      parent: item.parent
    };
    const title = 'course_category.update_title';
    const button = 'common.save';
    this.openCourseCategoryDetailPopup(current, title, button);
  }

  onCreateCourseCategory() {
    const current: CourseCategory = {
      name: '',
      description: '',
      parent: this.currentSelectedId
    };
    const title = 'course_category.create_title';
    const button = 'common.save';
    this.openCourseCategoryDetailPopup(current, title, button);
  }

  openCourseCategoryDetailPopup(courseCategory: any, title, button){
    const modalDep = this.modalService.open(CourseCategoryEditComponent, {
      size: 'lg' as any,
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.category = courseCategory;
    modalDep.componentInstance.categories = this.categoryTreeData.dataSource;
    modalDep.componentInstance.title = title;
    modalDep.componentInstance.button = button;
    modalDep.componentInstance.updateCategory.subscribe(($e) => {
      this.reloadData();
    });
  }

  onDeleteMultipleCourse() {
    this.deleteCategory([...this.courseCateIdsChecked]);
  }

  onDeleteSingleCourse(categoryId: number) {
    this.deleteCategory([categoryId]);
  }

  deleteCategory(category: number []) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('course_category.delete_confirm_title');
    // tslint:disable-next-line:max-line-length
    modalDep.componentInstance.body = this.translateService.instant('course_category.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');

    modalDep.result.then((result) => {
      this.spinner.show();
      this.categoryService.deleteCourseCate(category).subscribe(
        res => {
          this.spinner.hide();
          if (res.body.errors.length > 0)
          {
            let ids = '';
            res.body.errors?.forEach(element => {
              category.splice(category.indexOf(element.id), 1);
              ids += (element.name + '\n');
            });
            this.toastrService.successCate(`common.noti.delete_err_cate`,  ids );
          } else {
            this.toastrService.success(`common.noti.delete_success`);
          }
          [...category]?.forEach(courseId => {
            this.courseCateIds.splice(this.courseCateIds.indexOf(courseId), 1);
            this.courseCateIdsChecked.splice(this.courseCateIdsChecked.indexOf(courseId), 1);
          });
          this.reloadData();
        },
        err => {
          // Neu co loi xay ra => giu nguyen trang thai truoc do cua checkbox
          this.spinner.hide();
          this.toastrService.handlerError(err);
        }
      );
    });
  }

  /**
   * clear select node
   * clear search param
   */
  clear(){
    // param phuc vu tim kiem
    this.keyword = '';
    this.currentSelectedId = null;
    this.pageSize = 10;
    this.pageIndex = 1;

    // Bo select node tren cay
    this.categoryTree.selectedNodes = [];

    this.onSearch();
  }

  nodeSelected(e) {
    this.courseCateIdsChecked = [];
    this.currentSelectedId = +this.categoryTree.getNode(e.node).id;
    this.pageSize = 10;
    this.pageIndex = 1;
    this.sortColumn = 'id';
    this.sortType = 'ASC';
    this.onSearch();
  }

  reloadData(){
    this.onSearch();
    this.getCategoryTree();
  }

  sortData(sort: Sort) {
    this.pageIndex = 1;
    this.sortColumn = sort.active;
    this.sortType = sort.direction;
    this.onSearch();
  }

  changePage(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.onSearch();
  }

  onChangeKeyWord() {
    this.pageIndex = 1;
    this.courseCateIdsChecked = [];
    this.onSearch();
  }

}
