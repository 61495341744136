<!-- <section class="inner_page_breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 text-center">
        <div class="breadcrumb_content mt-2">
          <h4 class="breadcrumb_title">KỲ THI</h4>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="/home">Trang chủ</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section> -->

<main id="main" style="height: 65vh;">
  <!---Phan noi dung bai hoc-->
  <section id="route">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <h6 class="mb-0">{{totalExam}} kỳ thi</h6>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center float-right">
            <div class="col-auto">
              <select class="form-control" (change)="onChangeDataExam($event.target.value)">
                <option value="0"> Toàn bộ </option>
                <option value="1"> Hoàn thành </option>
                <option value="2"> Chưa hoàn thành </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div *ngFor="let item of exams; let i = index;" class="col-6">
          <div class="card-box mb-2">
            <div class="row align-items-center">
              <div class="col-sm-10">
                <div class="media">
                  <div (click)="localStore(item.fullname, item.finished)" [routerLink]="['/exam-lesson',item.id]" class="person-circle chr-{{item.fullname | uppercase | slice:0:1}}" style="cursor: pointer;">{{item.fullname | uppercase | slice:0:1 }}</div>
                  <div class="media-body">
                    <h5 class="mt-0 mb-2">{{item.fullname}}</h5>
                    <p class="mb-1"> <i class="icofont-calendar mr-2"></i>
                      <span style="font-size: 10pt;">{{getDateFromUnix(item.startdate) | date:'dd/MM/yyyy'}} - {{getDateFromUnix(item.enddate)| date:'dd/MM/yyyy'}} </span></p>
                    <p class="mb-0" *ngIf="item.completion==100"><i class="icofont-tasks mr-2"></i> Đã hoàn thành</p>
                    <p class="mb-0" *ngIf="item.completion<100"><i class="icofont-tasks mr-2"></i> Chưa hoàn thành</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="text-center button-list">
                  <button  *ngIf="!item.finished" type="button" (click)="localStore(item.fullname, item.finished)" [routerLink]="['/exam-lesson',item.id]" class="btn btn-sm btn-primary">Tham gia</button>
                  <button *ngIf="item.finished" type="button" (click)="localStore(item.fullname, item.finished)" [routerLink]="['/exam-lesson',item.id]" class="btn btn-sm btn-primary">Xem lại</button>
                </div>
              </div>
            </div> <!-- end row -->
          </div>
        </div>
      </div>
      <div class="card-footer text-center" *ngIf="totalExam > 0">
        <div class="row">
          <div class="col-12 text-center">
          <mat-paginator showFirstLastButtons
              [length]="totalExam"
              [pageSize]="pageSize"
              [pageIndex]="pageIndex - 1"
              (page)="pageEvent = $event; onChangePage($event)"
              [hidePageSize]="false"
              [pageSizeOptions]="pageSizeOptions">
          </mat-paginator>
        </div>
        </div>
      </div>
    </div>
  </section>
</main>
<!-- End #main -->
