import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CKEDITOR_CONFIG} from '../../../../shared/constants/ckeditor.constant';
import {DataService} from '../../../../shared/services/data.service';
import {ToastrCustomService} from '../../../../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {QuestionBankService} from '../../../../shared/services/question-bank.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {CustomValidators} from '../../../../shared/utils/custom-validators';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import {ConfirmModalComponent} from '../../../../shared/modal/confirm-modal/confirm-modal.component';
import { v1 as uuidv1 } from 'uuid';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import { TOPIC_CONST } from 'src/app/shared/constants/base.constant';

@Component({
  selector: 'app-random-question',
  templateUrl: './random-question.component.html',
  styleUrls: ['./random-question.component.css']
})
export class RandomQuestionComponent implements OnInit, OnChanges {

  @Input() panel: any;
  @Input() index: any;
  @Input() questionInput: any;
  @Output() messageEvent = new EventEmitter<any>();
  @Output() editQuestionIndex = new EventEmitter<number>();

  @Input() data: any;
  @Output() deleteQuestionEvent = new EventEmitter<any>();
  @Output() createQuestionEvent = new EventEmitter<any>(); // logic for question bank only
  @Output() cloneQuestion = new EventEmitter<any>();

  question: any;
  canEdit = false;
  isPublished = true;
  moduleId: number;
  categoryId: number;
  isViewMode = false;
  qindex = -1;

  questionForm: FormGroup;
  isLevel1 = true;
  isLevel2 = false;
  isLevel3 = false;

  formEnable = true;
  TOPIC_CONST = TOPIC_CONST;

  constructor(
    private fb: FormBuilder,
    public dataService: DataService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private questionBankService: QuestionBankService,
    private modalService: NgbModal,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setData();
    this.initForm(this.question);
    if (this.question?.id) {
      this.isViewMode = true;
      this.onUnEditForm();
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    this.qindex = this.data.index;
    this.categoryId = this.data.categoryId;
  }

  setData(){
    this.question = this.data.question;
    this.canEdit = this.data.canEdit;
    this.isPublished = this.data.isPublished;
    this.moduleId = this.data.moduleId;
    this.categoryId = this.data.categoryId;
    this.qindex = this.data.index;
  }

  initForm(question: any) {
    this.questionForm = this.fb.group({
      id: [],
      level: ['', Validators.required],
      questiontext: [''],
    });
    if (question) {
      const keyForm = Object.keys(this.questionForm.value);
      for (const key of keyForm) {
        if (key !== 'answer') {
          this.questionForm.controls[key].setValue(question[key]);
        }
      }
    }
  }

  changeLevel(levelid) {
    if (levelid === 1) {
      this.isLevel1 = true;
      this.isLevel2 = false;
      this.isLevel3 = false;
    } else if (levelid === 2) {
      this.isLevel1 = false;
      this.isLevel2 = true;
      this.isLevel3 = false;
    } else {
      this.isLevel1 = false;
      this.isLevel2 = false;
      this.isLevel3 = true;
    }
    this.questionForm.controls.level.setValue(levelid);
  }

  onEditForm() {
    this.formEnable = true;
    this.questionForm.enable();
  }

  onUnEditForm() {
    this.formEnable = false;
    this.questionForm.disable();
  }

  onSaveQuestion(question: any){
    question.quizid = this.moduleId;
    question.categoryid = this.categoryId;
    question.qtype = 'random';
    this.onUpdateQuestion(question);

  }

  onUpdateQuestion(question){
    this.spinner.show();
    this.questionBankService.updateQuestion(question).subscribe(
      res => {
        this.question = question;
        this.question.questiontext = res.body.questiontext;
        this.onUnEditForm();
        this.initForm(this.question);
        this.questionForm.disable();
        this.isViewMode = true;
        this.toastrService.success(`common.noti.update_success`);
        this.spinner.hide();
      },
      err => {
        this.toastrService.handlerError(err);
        this.spinner.hide();
      });
  }

  onRemoveQuestion(){
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('question.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('question.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        if (this.question?.id) {
          const params = {
            questionids: this.question.id,
            quizid: this.moduleId
          };
          this.spinner.show();
          this.questionBankService.deleteQuestionOnExam(params).subscribe(
            res => {
              this.deleteQuestionEvent.emit(this.qindex);
              this.toastrService.success(`common.noti.delete_success`);
              this.spinner.hide();
            },
            err => {
              this.spinner.hide();
              this.toastrService.handlerError(err);
            });
        } else {
          this.deleteQuestionEvent.emit(this.qindex);
        }

      }
    });
  }

  onTurnOnEditmode(){
    this.spinner.show();
    this.questionBankService.prepareQuestiontextForUpdate(this.question.id).subscribe(
      res => {
        this.isViewMode = false;
        this.onEditForm();
        this.questionForm.patchValue({
          questiontext: res.body.questiontext
        });
        this.spinner.hide();
      }, error => {
        this.toastrService.handlerError(error);
        this.spinner.hide();
      }
    );
  }

  onCloneQuestion(){
    this.cloneQuestion.emit({value: this.questionForm.value, isViewMode: this.isViewMode});
  }

}
