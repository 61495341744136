import { RegisterCourseSuccessModalComponent } from './register-course-success-modal/register-course-success-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalCoursePreviewComponent } from './modal-course-preview/modal-course-preview.component';
import { CourseService } from 'src/app/shared/services/course.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import {CourseCategory} from 'src/app/shared/model/course-category.model';
import * as moment from 'moment';
import {Router} from '@angular/router';
import CommonUtil from 'src/app/shared/utils/common-util';
import {FileService} from '../../shared/services/file.service';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {DomSanitizer} from '@angular/platform-browser';
import {DEFAULT_COURSE_IMAGE} from '../../shared/constants/base.constant';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.css']
})
export class CourseCardComponent implements OnInit, OnDestroy {

  @Input() index: number;
  @Input() isTeacher: boolean = false;
  @Input() courseInPosition: boolean = false;
  @Input() course: {
     fullname:string;
     filename:string;
     contextid:string;
     filearea:string;
     id:any;
     startdate:number;
     enddate:number;
     categoryname:string;
     parentcategoryname:string;
     completion:number;
     summary: string;
     timecourse: any;
     teachers:[{
       firstname:string;
       lastname:string;
    }];
    isCanRegister: boolean;
    enrol: string;
    status: string;
    _startdate: any;
    _enddate: any;
  };
  courseCategoryList: CourseCategory[] = [];
  search = '';
  currentDate: Date = new Date();

  @Output() registerSucc = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private fileService: FileService,
    private toastrCustomService: ToastrCustomService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private courseService: CourseService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.course.timecourse = this.convertMinuteToHour(this.course?.timecourse / 60);
    this.course.status = this.getCourseStatus();
    this.course._startdate = this.getDateFromUnix(this.course.startdate);
    this.course._enddate = this.getDateFromUnix(this.course.enddate);
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  onRegisterCourse() {
    const modalDep = this.modalService.open(ModalCoursePreviewComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.courseInfo = this.course;
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.spinner.show();
         this.courseService.userRegisterCourse(this.course?.id).subscribe(
          res => {
            this.spinner.hide();
            const modalConfirm = this.modalService.open(RegisterCourseSuccessModalComponent, {
              size: 'lg',
              centered: true,
              backdrop: 'static'
            });
            modalConfirm.componentInstance.title = this.translateService.instant('common.noti.resgister_course_success');
            modalConfirm.componentInstance.body = this.translateService.instant('common.noti.goto_course_detail');
            modalConfirm.componentInstance.confirmButton = this.translateService.instant('common.continute');
            modalConfirm.result.then((res) => {
              if (res === 'confirm') {
                this.onRedirectToCourseDetail();
              }
            }).finally(() => {
              this.registerSucc.emit(true);
            });
          }, err => {
            this.spinner.hide();
            this.toastrCustomService.handlerError(err);
          }
        );
      }
    });
  }

  getParentName(name) {
    const currentCategory = this.courseCategoryList.filter(category => category.name === name);
    let parentCategory = [];
    let nameParent = '';
    if (currentCategory.length > 0) {
      parentCategory =  this.courseCategoryList.filter(category => category.id === currentCategory[0].parent);
      if(parentCategory.length > 0)
      {
        nameParent = parentCategory[0].name ;
      }
    }
    return nameParent;

  }

  convertLinkimg(filename, filearea, contextId){
    if (filename && filearea && contextId) {
      const urlFile = this.fileService.getFileUrl(contextId, filename, '/course/overviewfiles/');
      return this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
    }
    return DEFAULT_COURSE_IMAGE;
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  convertMinuteToHour(minutes: number): string {
    if (!minutes) return null;
    const hours = Math.floor(minutes / 60);
    const minute = minutes % 60;
    return hours + ' giờ ' + minute + ' phút';
  }

  convertTeacherName(teachers: any[]) {
    return CommonUtil.convertTeacherName(teachers);
  }

  onRedirectToCourseDetail() {
    this.router.navigate(['/my-course-detail', this.course.id]);
  }

  getCourseStatus() {
    if (this.isTeacher) {
      return 'XEM THÔNG TIN';
    } else {
      if (this.course.startdate && this.getDateFromUnix(this.course.startdate).toDate() > this.currentDate) {
        return 'KHÓA HỌC CHƯA DIỄN RA';
      } else if (this.course.enddate && this.getDateFromUnix(this.course.enddate).toDate() < this.currentDate) {
        return 'KHÓA HỌC ĐÃ KẾT THÚC';
      } else {
        return 'THAM GIA KHÓA HỌC';
      }
    }
  }

}
