// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   apiUrl: 'http://10.4.201.231/api',
//   // apiUrl: 'http://localhost/moodle_mobifone',
//   // apiUrl: 'http://localhost/moodle',
//   serviceName: 'core_service',
//   ribbon: 'dev',
// };
export const environment = {
  //production: false,
  production: true,
  // apiUrl: 'http://192.168.2.100:9040/api',
  // apiUrl: 'http://localhost/moodle_mobifone',

  // apiUrl: 'https://elearningapi.smart-office.vn',
  // serviceName: 'core_service',
  // ribbon: '',
  // login_type: 'sso', // sso or lms
  // appUrl: 'https://elearning.smart-office.vn',
  // ssoUrl: 'https://sso.smart-office.vn/sso',
  // workspaceUrl: 'https://workspace.smart-office.vn/dashboard',
  // baseImgUrlSMO: 'https://images.smart-office.vn/'
//
//
//
  //apiUrl: 'http://localhost/api',
  apiUrl: 'https://thitructuyen.doankhoidntw.vn/api',
  // apiUrl: 'https://mtrithuc.mobifone.vn/api',
//   apiUrl: 'https://mtrithuc.mobifone.vn/api',
  serviceName: 'core_service',
  ribbon: '',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI
