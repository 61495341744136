<div class="row">
  <div class="col-8">
    <div class="form-inline"><p class="teacher mb-0"><strong>Loại: </strong>Tài liệu/Video</p></div>
    <div *ngIf="courseId===undefined" style="color: red">Bạn cần chọn chủ đề để tạo bài giảng trước bằng cách nhấn vào chủ đề đó!</div>
  </div>
  <div class="col-4" *ngIf="!courseReadOnly && !courseFinished">
    <div class="float-right">
      <button *ngIf="!isNew" type="button" matTooltip="Chỉnh sửa" matTooltipClass="tooltip-red"
              (click)="showEdit()" class="btn btn-default btn-lg mr-4"><i class="fas fa-edit"></i></button>
    </div>
  </div>
</div>

<div class="row mt-3" *ngIf="!isCreateOrEdit">
  <div class="col-11">
    <h5>{{courseModule?.name}}</h5>

  </div>
  <div class="col-11 mt-3">
    <small class="text-dark">Giảng viên:
      <span matTooltip="{{teachers}}" matTooltipClass="tooltip-inner">{{teachers?.length > 100 ? (teachers | slice:0:100) : teachers}}</span>
    </small>
  </div>
  <div class="col-11 mt-3">
    <small class="text-dark">Thời lượng (phút): {{courseModule?.timelimit / 60}}</small>
  </div>
  <br>

  <div class="col-11 mt-3" >Miêu tả: </div>
  <div class="col-11 mt-3">
    <div [innerHTML]="courseModule?.intro"></div>
  </div>
  <div class="col-11 mt-3">Nội dung: </div>
  <div class="col-11 mt-3">
    <div *ngIf="courseModule?.mimetype=='application/pdf'">
      <a style="padding-left: 2rem" [href]="urlSafe" target="_blank">
        {{courseModule?.filename}}
      </a> <br/> <br/>
      <a style="padding-left: 2rem">
        <iframe [src]="urlSafe"
                width="900" height="600"></iframe>
      </a>
    </div>
    <div class="col-12" *ngIf="courseModule?.mimetype=='video/mp4' || courseModule?.mimetype=='video/quicktime'">
      <video *ngIf="urlSafe" #videoCourse width="100%" autoplay="autoplay" controls>
        <source [src]="urlSafe" type="video/mp4">
        Trình duyệt bạn không hỗ trợ Video - Vui lòng kiểm tra lại !
      </video>
    </div>
    <div *ngIf="courseModule?.mimetype!=='application/pdf' && courseModule?.mimetype!=='video/mp4' && courseModule?.mimetype!=='video/quicktime'" class="col-12">
      <div class="row mt-2 p-4  bg-light rounded">
        <div class="col-6 form-inline"><p class="mt-3"><i class="fas fa-file-archive font-size-20 mr-2"></i>{{courseModule?.filename}}</p></div>
        <div class="col-6 mt-3">
          <p class=" float-right ml-3"><a [href]="urlSafe"><i class="fas fa-file-download mr-2 font-size-20"></i>Tải xuống</a></p>
        </div>
      </div>
    </div>
  </div>
</div>

<form role="form" [formGroup]="moduleForm" *ngIf="isCreateOrEdit">
  <div class="row mt-3">
    <div class="col-3 text-right mb-1"><p>Tên bài giảng <span class="text-danger">*</span></p></div>
    <div class="col-8">
      <input formControlName="name" type="text"
             class="form-control" placeholder="Nhập tên bài giảng..." (change)="onDataChange()"/>
      <div *ngIf="moduleForm.get('name')!.invalid && (moduleForm.get('name')!.dirty || moduleForm.get('name')!.touched)">
        <small class="text-danger" *ngIf="moduleForm.get('name')?.errors?.required">
          {{ 'error.validate.input_required' | translate }}
        </small>
        <small class="text-danger" *ngIf="moduleForm.get('name')?.errors && !moduleForm.get('name')?.errors?.required">
          {{ 'error.validate.course.nameMaxlength' | translate }}
        </small>
      </div>
    </div>
    <div class="col-3 text-right mb-1"><p>Thời lượng (phút)<span class="text-danger">*</span></p></div>
    <div class="col-8">

      <input type="text" (keypress)="numericOnly($event)" [maxLength]="4" class="form-control"
             formControlName="timelimit" placeholder="Nhập thời lượng bài giảng..." (change)="onDataChange()"/>
      <div *ngIf="moduleForm?.get('timelimit')!.invalid && (moduleForm?.get('timelimit')!.dirty || moduleForm?.get('timelimit')!.touched)">
        <small class="text-danger" *ngIf="moduleForm.get('timelimit')?.errors?.required">
          {{ 'error.validate.input_required' | translate }}
        </small>
      </div>
    </div>

  </div>
</form>

<div class="row mt-3" *ngIf="isCreateOrEdit">
  <div class="col-3 mt-3 text-right mb-1">Miêu tả <span class="text-danger">*</span></div>
  <div class="col-8 mt-3">
    <ckeditor [editor]="Editor" [(ngModel)]="editorData" (change)="onIntroChange($event)" [config]="editorConfig"></ckeditor>
    <small class="text-danger" *ngIf="!validateDes">
      {{ 'error.validate.input_required' | translate }}
    </small>
  </div>
  <div class="col-3 mt-3 text-right mb-1">Chọn File (PDF, PPT, DOCX, VIDEO...) <span class="text-danger">*</span></div>
  <div class="col-8 mt-3">
    <div *ngIf="courseModule.filename">{{courseModule.filename}}</div>
    <div *ngIf="courseModule.filename"><strong>Chọn File khác: </strong></div>
    <br>
    <input type="file" name="fileToUpload" (change)="handleFileInput($event.target.files)" multiple>
    <br />
    <small class="text-danger" *ngIf="!verifyFile">
      {{ 'error.validate.file_needed' | translate }}
    </small>
    <small class="text-danger" *ngIf="fileInvalid">
      File không đúng định dạng
    </small>
  </div>
  <div class="col-12 mt-3 text-center">
    <button type="button" *ngIf="!isNew" class="btn btn-default round-0 mr-2" (click)="cancel()">
      <i class="fas fa-close"></i>{{ 'common.back' | translate}}
    </button>
    <button type="submit" *ngIf="courseId!==undefined"
            (click)="submitForm()" class="btn btn-primary "><i class="fas fa-save mr-1"></i>Lưu lại</button>
<!--    <button type="submit" [disabled]="moduleForm.invalid || !editorData || (isNew && !fileToUpload )" *ngIf="courseId!==undefined"-->
<!--            (click)="submitForm()" class="btn btn-primary "><i class="fas fa-save mr-1"></i>Lưu lại</button>-->
  </div>
</div>
