<div class="modal-header">
  <h4 class="modal-title">Chi tiết thông báo</h4>
  <button type="button" class="btn btn-default" data-card-widget="collapse"
          (click)="activeModal.dismiss('close')"><i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form role="form" [formGroup]="detailNotify">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Tiêu đề thông báo <span class="text-danger"></span></label>
          <div>
            <input type="text" class="form-control" placeholder="" formControlName="titleNotify"/>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">

        <div class="form-group">
          <label>Người nhận thông báo <span class="text-danger"></span></label>
          <div>
            <input type="text" class="form-control" placeholder="" formControlName="recipient"/>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Nội dung thông báo <span class="text-danger"></span></label>
          <div>
            <ckeditor [editor]="Editor" formControlName="contentNotify" [config]="editorConfig"></ckeditor>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>
<div class="modal-footer text-center">
  <button type="button" class="btn ml-1 btn-secondary rounded-0 mr-1"
          (click)="activeModal.dismiss('close')">{{ 'common.cancel' | translate }}
  </button>
  <div>
  </div>
</div>






