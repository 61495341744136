import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {ModuleModel} from '../../../shared/model/module.model';
import {TopicService} from '../../../shared/services/topic.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FileService} from '../../../shared/services/file.service';
import {TreeViewComponent} from '@syncfusion/ej2-angular-navigations';
import CommonUtil from '../../../shared/utils/common-util';

@Component({
  selector: 'app-eu-scorm-content',
  templateUrl: './eu-scorm-content.component.html',
  styleUrls: ['./eu-scorm-content.component.css']
})
export class EuScormContentComponent implements OnInit, OnChanges {
  @ViewChild('scormScoTree')
  public scormScoTree: TreeViewComponent;

  public scormScoTreeData;

  @ViewChild('scormCourse') scormCourse: ElementRef;

  @Input() module: ModuleModel;
  @Input() courseId: number;
  @Input() section: number;

  courseModule: ModuleInfo = {
    name: '',
    course: this.courseId,
    section: this.section,
    introeditor: null,
    modulename: 'scorm'
  };

  urlSafe: SafeResourceUrl;
  currentScormSco = null;
  scormScoTreeList;
  scormContentUrl = null;
  teachers = '';

  constructor(
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private topicService: TopicService,
    private fileService: FileService,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.module) {
      // Cap nhat du lieu
      this.spinner.show();
      this.topicService.getModuleDetail(this.module.id).subscribe(res => {
        this.courseModule = res.body;
        this.teachers = res.body.teachers.length > 0 ? CommonUtil.convertTeacherName(res.body.teachers) : 'Chưa có giảng viên được gán';
        const urlFile = this.fileService.getFileUrl(this.courseModule.contextid, this.courseModule.filename, '/mod_scorm/package/0/');
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);

        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        // this.toastrService.handlerError(err);
      });
      this.topicService.getScormSco(this.module.id).subscribe(res => {
        this.scormScoTreeList = res.body;
        if (this.scormScoTreeList.length > 0) {
          // Do Item dau tien co cha la / nen set bang null de show len tree
          this.scormScoTreeList[0].parent = null;
        }
        this.scormScoTreeData = { dataSource: this.scormScoTreeList, id: 'identifier', parentID: 'parent', text: 'title', hasChildren: 'haschild'};
        });
    }
  }

  nodeSelected($event){
    const currentSelectedId = this.scormScoTree.selectedNodes[0];

    for (const scormSco of this.scormScoTreeList) {
      if (currentSelectedId === scormSco.identifier && !scormSco.haschild) {
        this.currentScormSco = scormSco;
      }
    }


    if (this.currentScormSco) {
      const urlFile = this.fileService.getScormUrl(this.courseModule.contextid, this.currentScormSco.launch, '/mod_scorm/content/0/');
      this.scormContentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
      this.scormCourse?.nativeElement.load();
    }

  }

  getCurrentLeafNode(){
    let currentSelectedId = this.scormScoTreeData.selectedNodes[0];
    let hasChild = false;
    for (const scormSco of this.scormScoTreeList) {

      if (hasChild === false) {
        if (currentSelectedId === scormSco.identifier) {
          if (scormSco.haschild) {
            hasChild = true;
          } else {
            this.currentScormSco = scormSco;
          }
        }
      } else {

      }
    }
  }

}
