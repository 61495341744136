import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrCustomService} from './../../shared/services/toastr-custom.service';
import {ConfirmModalComponent} from '../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PageEvent} from '@angular/material/paginator';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {CourseCategory} from '../../shared/model/course-category.model';
import {QuestionBankService} from '../../shared/services/question-bank.service';
import {CourseCategoryService} from '../../shared/services/course-category.service';
import {LEVEL_QUESTION, TOPIC_CONST} from '../../shared/constants/base.constant';
import CommonUtil from 'src/app/shared/utils/common-util';
import {TranslateService} from '@ngx-translate/core';
import {QuestionDetailComponent} from './question-detail/question-detail.component';
import * as moment from 'moment';
import {BankSearchComponent} from './bank-search/bank-search.component';

@Component({
  selector: 'app-question-bank',
  templateUrl: './question-bank.component.html',
  styleUrls: ['./question-bank.component.css']
})
export class QuestionBankComponent implements OnInit, OnDestroy {

  questionTypeMulti = 'multichoice';
  questionTypeTrueFalse = 'truefalse';
  listQuestion: any[] = [];
  listQuestionFake: any[] = [];
  courseCategoryList: CourseCategory[] = [];
  categoryMap = new Map();
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  completedAll = false;
  lastSort: any;
  questionIds: number[] = [];
  questionIdsChecked: number[] = [];
  // MatPaginator Output
  pageEvent: PageEvent;

  searchQues = {
    categoryid: null,
    qtype: '',
    level: null,
    pageSize: 10,
    pageIndex: 1,
    keyword: ''
  };

  constructor(
    private questionBankService: QuestionBankService,
    private courseCategoryService: CourseCategoryService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.getListQuestion();
    this.spinner.show();
    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      this.courseCategoryList = response.body;
      CommonUtil.convertCategoryListToMap(this.courseCategoryList, this.categoryMap);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
    this.listQuestion.forEach(c => {
      c.completed = false;
    });

  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  openQuestionSearchPopup() {
    const modalDep = this.modalService.open(BankSearchComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.searchQues = this.searchQues;

    modalDep.result.then((result) => {
      if (result) {
        this.searchQues.qtype = result.qtype;
        this.searchQues.level = result.level;
        this.searchQues.categoryid = +result.categoryId;
        this.searchQues.pageIndex = 1;
        this.questionIds = [];
        this.questionIdsChecked = [];
        this.getListQuestion();
      }
    });
  }

  getListQuestion(sortBy?: any, sortType?: any) {
    this.spinner.show();
    if (this.searchQues.categoryid === 0) {
      this.searchQues.categoryid = null;
    }
    if (this.searchQues.level === 0) {
      this.searchQues.level = null;
    }
    const params = {
      limit: this.searchQues.pageSize,
      page: this.searchQues.pageIndex,
      sortcolumn: sortBy ? sortBy : 'id',
      sorttype: sortType ? sortType : 'DESC',
      keyword: this.searchQues.keyword,
      qtype: this.searchQues.qtype,
      level: this.searchQues.level,
      categoryids: this.searchQues.categoryid,
      isloadrandomquestion: 0
    };
    this.questionIds = [];
    return this.questionBankService.getListQuestion(params).subscribe((data) => {
      data.body.results.forEach((element: any) => {
        element.questiontext = element.questiontext.split('<strong>').join('').split('</strong>').join('');
        element.questiontext = element.questiontext.split('<p>').join('').split('</p>').join('');
        element.levelName = this.convertLevelQuestion(element.level);
        if (this.questionIdsChecked?.includes(element.id)) {
          element.completed = true;
          this.questionIds?.push(element.id);
        }
      });

      if (this.questionIds?.length > 0 && (this.questionIds?.length === data.body?.results?.length)) {
        this.completedAll = true;
      } else {
        this.completedAll = false;
      }
      this.listQuestion = data.body.results;
      this.listQuestionFake = this.listQuestion;
      this.totalRecord = data.body.total;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  search(search: string) {
    this.searchQues.keyword = search;
    this.searchQues.pageIndex = 1;
    this.questionIdsChecked = [];
    this.getListQuestion();
  }

  changePage(event) {
    this.searchQues.pageIndex = event.pageIndex + 1;
    this.searchQues.pageSize = event.pageSize;
    this.getListQuestion();
  }

  getParentName(id) {
    const currentCategory = this.courseCategoryList.filter(category => category.id === id);
    if (currentCategory.length > 0) {
      return this.courseCategoryList.filter(category => category.id === currentCategory[0].parent)[0]?.name;
    }
  }

  convertLevelQuestion(levelid) {
    const level = TOPIC_CONST.find(lev => lev.value === levelid);
    return level ? level.lable : "";
  }

  onUpdateQuestion(question: any, isUpdate?: boolean) {
    const modalDep = this.modalService.open(QuestionDetailComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.questionInput = question;
    if (isUpdate) {
      modalDep.componentInstance.formEnable = true;
      modalDep.componentInstance.isUpdate = true;
    }
    modalDep.result.then((result) => {
      if (result === 'ok') {
        this.getListQuestion();
      }
    });
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.listQuestion.forEach(c => {
        c.completed = checked;
        if (!this.questionIds.includes(c.id)) {
          this.questionIds.push(c.id);
        }
        if (!this.questionIdsChecked?.includes(c.id)) {
          this.questionIdsChecked?.push(c.id);
        }
      });
      this.completedAll = true;
    } else {
      this.questionIds?.forEach(id => {
        this.questionIdsChecked?.splice(this.questionIdsChecked?.indexOf(id), 1);
      });
      this.questionIds = [];
      this.listQuestion?.forEach(c => {
        c.completed = false;
      });
      this.completedAll = false;
    }
  }

  oncheckboxItem(courseId: number, checked: any) {
    if (checked) {
      this.listQuestion?.forEach(c => {
        if (c.id === courseId) {
          c.completed = true;
          this.questionIds?.push(courseId);
          this.questionIdsChecked?.push(courseId);
          return;
        }
      });
      if (this.questionIds?.length > 0 && this.questionIds?.length === this.listQuestion?.length && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.completedAll = false;
      this.listQuestion?.forEach(c => {
        if (c.id === courseId) {
          c.completed = false;
          this.questionIds?.splice(this.questionIds?.indexOf(courseId), 1);
          this.questionIdsChecked?.splice(this.questionIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
  }

  onDeleteSingleQues(courseId: number) {
    this.onDeleteQues([courseId]);
  }

  onDeleteMultipleQues() {
    this.onDeleteQues(this.questionIdsChecked);
  }

  private onDeleteQues(courseIds?: number[]) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('question.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('question.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalDep.result.then((result) => {
      if (result === 'confirm' && courseIds.length > 0) {
        this.spinner.show();
        this.questionBankService.deleteQuestion({ids: courseIds.toString()}).subscribe(
          res => {
            this.toastrService.success(`common.noti.delete_success`);
            [...courseIds]?.forEach(courseId => {
              this.questionIds.splice(this.questionIds.indexOf(courseId), 1);
              this.questionIdsChecked.splice(this.questionIdsChecked.indexOf(courseId), 1);
            });
            this.getListQuestion();
            this.spinner.hide();
          },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          });
      }
    });
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

}
