import {NgxSpinnerService} from 'ngx-spinner';
import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import {CourseService} from '../../shared/services/course.service';

@Component({
  selector: 'app-course-route-detail',
  templateUrl: './course-route-detail.component.html',
  styleUrls: ['./course-route-detail.component.css']
})
export class CourseRouteDetailComponent implements OnInit {

  positionName: string;
  id: any;
  listCourseOfPosition: any = [];
  listExamOfPosition: any = [];
  courseCompletion: any = 0;
  examCompletion: any = 0;
  sub: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.positionName = history.state?.positionName;
    this.sub = this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.getCourseByPosition(this.id);
      this.getExamByPosition(this.id);
    });
  }

  getCourseByPosition(positionId: any) {
    const param = {
      positionid: positionId,
      coursetype: 1, // course
      sortcolumn: 'ordernumber',
      sorttype: 'ASC',
      limit: 0
    };
    return this.courseService.getCourseByPosision(param).subscribe((data => {
      this.courseCompletion = data.body.results.filter(c => c.completion === 100).length;
      this.listCourseOfPosition = data.body.results;
    }));
  }

  getExamByPosition(positionId: any) {
    const param = {
      positionid: positionId,
      coursetype: 2, // exam
      sortcolumn: 'ordernumber',
      sorttype: 'ASC',
      limit: 0
    };
    return this.courseService.getCourseByPosision(param).subscribe((data => {
      this.examCompletion = data.body.results.filter(e => e.completion === 100);
      this.listExamOfPosition = data.body.results;
      this.spinner.hide();
    }));
  }

  convertTotDate(date: any) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }
}
