<div class="container-fluid">
  <div class="card card-default">
    <div class="card-header">
      <h3  class="card-title">
        <button class="btn btn-secondary btn-sm" (click)="openListCourseReport()">
          <i class="nav-icon fas fa-plus mr-1"></i>{{choosesCourseBtn}}
        </button>
      </h3>
      <div class="card-tools">
        <button type="button" (click)="reportExportCourseOrgan()" mattooltip="Xuất File Excel" mattooltipclass="tooltip-red" class="btn btn-default btn-lg mr-1" ng-reflect-message="Xuất File Excel" ng-reflect-tooltip-class="tooltip-red" aria-describedby="cdk-describedby-message-0" cdk-describedby-host=""><i class="fas fa-file-excel mr-1"></i></button>
      </div></div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table-stripped table-bordered table table-hover">
                <thead>
                  <tr>
                    <th class="style--width--2">STT</th>
                    <th class="style--width--20">Tên khóa học</th>
                    <th class="style--width--5">Nghiệp vụ</th>
                    <th class="style--width--10">Đơn vị chủ trì</th>
                    <th class="style--width--5">Ngày bắt đầu</th>
                    <th class="style--width--5">Ngày kết thúc</th>
                    <th class="style--width--3">Số người</th>
                    <th class="style--width--5">Email</th>
                    <th class="style--width--10">Tên cán bộ tham dự</th>
                    <th class="style--width--30">Chức danh</th>
                    <th class="style--width--5">Tên viết tắt đơn vị</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of courses; let i = index;">
                    <td>{{(pageIndex - 1) * pageSize + i + 1}}</td>
                    <td>{{item.coursename}}</td>
                    <td>{{item.categoryname}}</td>
                    <td>{{item.departmentname}}</td>
                    <td>{{getDateFromUnix(item.startdate) | date:'dd/MM/yyyy'}}</td>
                    <td>{{getDateFromUnix(item.enddate) | date:'dd/MM/yyyy'}}</td>
                    <td>{{item.totalstudent}}</td>
                    <td>{{item.email}}</td>
                    <td>{{item.firstname + ' ' + item.lastname}}</td>
                    <td>{{item.positionname}}</td>
                    <td>{{item.departmentcode}}</td>
                  </tr>
                </tbody>
                </table>
                <br />
                <p *ngIf="courses?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-center"  *ngIf="courses?.length > 0">
        <div class="row">
          <mat-paginator showFirstLastButtons
              [length]="totalRecord"
              [pageSize]="pageSize"
              [pageIndex]="pageIndex - 1 "
              (page)="changePage($event)"
              [hidePageSize]="false"
              [pageSizeOptions]="pageSizeOptions">
          </mat-paginator>
        </div>
      </div>
  </div>
</div>
