import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css'],
  providers: [NgbModal, NgbModalConfig]
})
export class InfoModalComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;
  @Input() confirmButton: string;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
    // console.log(this.title);
  }
}
