import {BadgeService} from '../../../shared/services/badge.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ToastrCustomService} from 'src/app/shared/services/toastr-custom.service';

@Component({
  selector: 'app-badge-details',
  templateUrl: './badge-details.component.html',
  styleUrls: ['./badge-details.component.css']
})
export class BadgeDetailsComponent implements OnInit {
  id: any;
  sub: any;
  statusBadge: any;
  activedBadge: boolean = false;
  enabaleBadge: any = 0;
  badge: any;

  constructor(
    private _Activatedroute: ActivatedRoute,
    private badgeService: BadgeService,
    private toastrService: ToastrCustomService
  ) {
  }

  ngOnInit(): void {
    this.sub = this._Activatedroute.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.getStatusBadge();
    });
  }

  getStatusBadge() {
    return this.badgeService.detailBadge(this.id).subscribe((data) => {
      this.badge = data.body.results[0];
      this.statusBadge = data.body.results[0].status;
      if (this.statusBadge === 1 || this.statusBadge === 3) {
        this.activedBadge = true;
      }
    }, (err) => {
    });
  }

  chageStatusBadge() {
    if (this.activedBadge === false) {
      this.enabaleBadge = 1;
    } else {
      this.enabaleBadge = 0;
    }
    this.badgeService.activeCourse(this.id, this.enabaleBadge).subscribe(
      res => {
        if (this.enabaleBadge === 1) {
          this.toastrService.success(`common.noti.publish_badge_success`);
          this.activedBadge = true;
        } else {
          this.toastrService.success(`common.noti.un_publish_badge_success`);
          this.activedBadge = false;
        }
        this.getStatusBadge();
      },
      err => {
        this.toastrService.handlerError(err);
      }
    );
  }
}
