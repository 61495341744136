import {BadgeService} from '../../shared/services/badge.service';
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Course} from '../../shared/model/course.model';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {COURSE_TYPE} from '../../shared/constants/base.constant';
import * as moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {CourseService} from '../../shared/services/course.service';
import CommonUtil from 'src/app/shared/utils/common-util';

@Component({
  selector: 'app-item',
  templateUrl: './select-item.component.html',
})
export class SelectItemComponent implements OnInit {

  @Input() isSurveyDetail: boolean;
  @Input() isNotify: boolean;
  public activeIndex: any = 0;
  @Input() badgeId: any;
  @Input() open: string;
  @Output() loadAll = new EventEmitter();
  completedAll = false;
  course: Course[] = [];
  listCourse: any = [];
  exam: Course[] = [];
  courses = new Array();
  courseIds = new Array();
  coursetype: number;
  titleModal: string = '';
  courseIdsChecked: number[] = [];

  thTable: any = {
    order: 'STT',
    col_1: 'Tên khóa học',
    col_2: 'Từ ngày',
    col_3: 'Đến ngày',
    col_4: 'Đơn vị chủ trì'
  };
  search = '';
  pageIndex = 1;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;

  constructor(
    public activeModal: NgbActiveModal,
    private badgeService: BadgeService,
    private toastrService: ToastrCustomService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private courseService: CourseService
  ) {}

  ngOnInit() {
    if (this.open === 'course') {
      this.coursetype = COURSE_TYPE.COURSE;
      this.titleModal = 'Chọn danh sách khóa học';
      this.thTable.col_1 = 'Tên khóa học';
    } else if (this.open === 'exam') {
      this.coursetype = COURSE_TYPE.EXAM;
      this.titleModal = 'Chọn danh sách kì thi';
      this.thTable.col_1 = 'Tên kì thi';
    }
    if (this.badgeId) {
      this.getListCourse();
    }
    if (this.isSurveyDetail === undefined || this.isSurveyDetail === null) {
      this.isSurveyDetail = false;
    }

    // thong bao
    if (this.isNotify) {
      this.getCoursesForNotifi();
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }

  save() {
    if (this.isNotify) {
      if (this.courseIdsChecked?.length === 0) {
        this.toastrService.error(`badge.assign_no_choose_failed`);
        return;
      } else {
        this.loadAll.emit(this.courseIdsChecked);
      }
    }

    // ko phai thong bao
    if (this.isNotify === undefined || this.isNotify === null || this.isNotify === false) {
      this.spinner.show();
      this.badgeService.updateCourseToBadge(this.badgeId, String(this.courseIdsChecked), 'add').subscribe(
        res => {
          this.spinner.hide();
          if (this.open === 'course') {
            this.toastrService.success(`badge.assign_course_success`);
          } else if (this.open === 'exam') {
            this.toastrService.success(`badge.assign_exam_success`);
          }
          this.loadAll.emit('load');
        },
        err => {
          this.spinner.hide();
          this.toastrService.error(`badge.assign_failed`);
        }
      );
    }
    this.modalService.dismissAll();
  }

  getListCourse() {
    this.spinner.show();
    const params = {
      keyword: this.search,
      limit: this.pageSize,
      page: this.pageIndex,
      coursetype: this.coursetype,
      notinbadgeids: this.badgeId
    };
    this.courseIds = [];
    this.badgeService.getistCourseCriteria(params).subscribe(
      data => {
        data.body.results.forEach(course => {
          course.teacherName = CommonUtil.convertTeacherName(course.teachers);
          if (this.courseIdsChecked?.includes(course.id)) {
            course.completed = true;
            this.courseIds?.push(course.id);
          }
        });
        // nếu số khóa học được checked = số khóa học có thể checked thì set completedAll = true
        if (this.courseIds?.length > 0 && (this.courseIds?.length === data.body?.results?.length)) {
          this.completedAll = true;
        } else {
          this.completedAll = false;
        }
        this.courses = data.body.results;
        this.totalRecord = data.body.total;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.toastrService.error(err);
      }
    );
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  // lay ds ky thi cho thong bao
  getCoursesForNotifi() {
    this.spinner.show();
    const params = {
      coursetype: this.coursetype,
      keyword: this.search,
      limit: this.pageSize,
      page: this.pageIndex
      // showmanagedcourse: 1
    };
    this.courseIds = [];
    this.courseService.managedCourse(params).subscribe(data => {
      data.body?.results?.forEach(c => {
        if (this.courseIdsChecked?.includes(c.id)) {
          c.completed = true;
          this.courseIds?.push(c.id);
        }
      });
      // nếu số khóa học được checked = số khóa học có thể checked thì set completedAll = true
      if (this.courseIds?.length > 0 && (this.courseIds?.length === data.body?.results?.length)) {
        this.completedAll = true;
      } else {
        this.completedAll = false;
      }
      this.courses = data.body.results;
      this.totalRecord = data.body.total;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastrService.handlerError(err);
    });
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.courses.forEach(c => {
        c.completed = checked;
        if (!this.courseIds.includes(c.id)) {
          this.courseIds.push(c.id);
        }
        if (!this.courseIdsChecked?.includes(c.id)) {
          this.courseIdsChecked?.push(c.id);
        }
      });
      this.completedAll = true;
    } else {
      this.courseIds?.forEach(id => {
        this.courseIdsChecked?.splice(this.courseIdsChecked?.indexOf(id), 1);
      });
      this.courseIds = [];
      this.courses?.forEach(c => {
        c.completed = false;
      });
      this.completedAll = false;
    }
  }

  oncheckboxItem(courseId: number, checked: any) {
    if (checked) {
      this.courses?.forEach(c => {
        if (c.id === courseId) {
          c.completed = true;
          this.courseIds?.push(courseId);
          this.courseIdsChecked?.push(courseId);
          return;
        }
      });
      // nếu số khóa checked bằng số khóa học có thể checked trong page thì set checkboxall = true
      if (this.courseIds?.length > 0 && this.courseIds?.length === this.courses?.length && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.completedAll = false;
      this.courses?.forEach(c => {
        if (c.id === courseId) {
          c.completed = false;
          this.courseIds?.splice(this.courseIds?.indexOf(courseId), 1);
          this.courseIdsChecked?.splice(this.courseIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
  }

  onKeyUp() {
    this.pageIndex = 1;
    this.courseIdsChecked = [];
    this.getListCourse();
  }

  changePage(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if (this.isNotify) {
      this.getCoursesForNotifi();
    } else {
      this.getListCourse();
    }
  }

}
