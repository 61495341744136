import {Injectable, isDevMode} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {environment} from '../../../environments/environment';
import {AbstractService} from './abstract.service';
import {Token} from '../model/token.model';
import {map} from 'rxjs/operators';
import {MOODLE_INFO, USER_INFO} from '../constants/base.constant';
import {AccountService} from './account.service';
import {UserInfo} from '../model/user-info.model';
import {Router} from '@angular/router';
import {RoleSystem} from '../model/role-system';
import {Menu} from '../model/menu';
import {UserService} from './user.service';
import {FileService} from './file.service';


type EntityResponseType = HttpResponse<Token>;

@Injectable({providedIn: 'root'})
export class LoginService {

  constructor(
    private abstractService: AbstractService,
    private $localStorage: LocalStorageService,
    private accountService: AccountService,
    public userService: UserService,
    private router: Router,
    private fileServide: FileService,
  ) {

  }

  isLoggedIn(): boolean {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  getToken() {
    return this.$localStorage.retrieve(USER_INFO.TOKEN);
  }

  checkTokenToActivateAccount(param) {
    const formData: FormData = new FormData();
    formData.append('token', param.token);
    return this.abstractService.post<boolean>(MOODLE_INFO.ACTIVATION_ACCOUNT, formData)
      .pipe(map((res: HttpResponse<boolean>) => res));
  }

  login(credentials) {

    // Ham login su dung data khac
    const formData: FormData = new FormData();
    formData.append('service', environment.serviceName);
    formData.append('username', credentials.username);
    formData.append('password', credentials.password);

    return new Promise((resolve, reject) => {
      // Map ve kieu tra ve
      this.abstractService.post<Token>(MOODLE_INFO.LOGIN_URL, formData)
        .pipe(map((res: EntityResponseType) => res))
        .subscribe(tokenRes => {
            // Dang nhap thanh cong
            const token: Token = tokenRes.body;
            if (isDevMode()) {
              console.log('token: ', token.token);
            }
            this.$localStorage.store(USER_INFO.TOKEN, token.token);
            let done = false;

            this.userService.getUserInfo().subscribe(res => {
                const userInfo: UserInfo = res.body;
                if (userInfo.pictureid){ // Neu user da thay doi anh dai dien thi lay url cua anh dai dien
                  userInfo.userpictureurl = this.fileServide.getFileUrlWithRevision(
                    userInfo.contextid, userInfo.picturename, '/user/icon/boost/', userInfo.pictureid
                  );
                } else {
                  userInfo.userpictureurl = 'assets/graduated.svg';
                }

                if (isDevMode()) {
                  console.log('user info: ', userInfo);
                }
                this.$localStorage.store(USER_INFO.INFO, userInfo);
                if (done) {
                  resolve(userInfo);
                } else {
                  done = true;
                }
              },
              err => {
                this.logout();
                reject(err);
              }
            );
            this.accountService.getAccountRole().subscribe(res => {
                const roles: RoleSystem[] = res.body.roles;
                const menus: Menu[] = res.body.menus;
                if (isDevMode()) {
                  console.log('user roles: ', roles);
                  console.log('user menus: ', menus);
                }
                this.$localStorage.store(USER_INFO.ROLES, roles);
                this.$localStorage.store(USER_INFO.MENUS, menus);
                if (done) {
                  resolve(roles);
                } else {
                  done = true;
                }
              },
              err => {
                this.logout();
                reject(err);
              }
            );
          },
          err => {
            this.logout();
            reject(err);
          });
    });
  }

  logout() {
    this.$localStorage.clear();
    this.router.navigate(['/home']);  // {4}
  }

  forgotPassword(param){
    const formData: FormData = new FormData();
    if (param.username){
      formData.append('username', param.username);
    }else {
      formData.append('email', param.email);
    }

    return this.abstractService.post<boolean>(MOODLE_INFO.FORGOT_PASSWORD_URL, formData)
      .pipe(map((res: HttpResponse<boolean>) => res));
  }

  resetPassword(param){
    const formData: FormData = new FormData();
    formData.append('newpassword', param.password);
    formData.append('token', param.token);

    return this.abstractService.post<boolean>(MOODLE_INFO.RESET_PASSWORD_URL, formData)
      .pipe(map((res: HttpResponse<boolean>) => res));
  }

  checkForgotPasswordToke(param){
    const formData: FormData = new FormData();
    formData.append('checkinitpage', '1');
    formData.append('token', param.token);

    return this.abstractService.post<boolean>(MOODLE_INFO.RESET_PASSWORD_URL, formData)
      .pipe(map((res: HttpResponse<boolean>) => res));
  }

  resendValidationEmail(username){
    const formData: FormData = new FormData();
    formData.append('username', username);

    return this.abstractService.post<boolean>(MOODLE_INFO.RESEND_VALIDATION_EMAIL, formData)
      .pipe(map((res: HttpResponse<boolean>) => res));
  }

  signup(param) {
    const formData: FormData = new FormData();
    formData.append('email', param.email);
    formData.append('phone1', param.phone1);
    formData.append('firstname', param.firstname);
    formData.append('lastname', param.lastname);
    formData.append('gender', param.gender);
    formData.append('dob', (param.dob.valueOf() / 1000).toString());
    formData.append('departmentid', param.departmentid);
    formData.append('subdepartment', param.subdepartment);
    formData.append('password', param.password);
    formData.append('icq', param.icq);

    return this.abstractService.post<boolean>(MOODLE_INFO.REGISTER_USER_URL, formData)
      .pipe(map((res: HttpResponse<boolean>) => res));
  }


}
