<div class="modal-header">
  <h5 class="modal-title sfbold text-dark">Thông tin cá nhân</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="outline:none;" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
      <div class="overflow-auto" style="height: 55vh; overflow-x: hidden !important;">
        <form role="form" [formGroup]="updateUserInfoForm" *ngIf="user">

              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label>Họ tên</label><span class="text-danger"> *</span>
                    <input type="text" class="form-control" formControlName="fullname" aria-describedby="helpId"
                           placeholder="">
                </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label>Tên đăng nhập</label><span class="text-danger"> *</span>
                    <input type="text" class="form-control" formControlName="username" aria-describedby="helpId"
                           placeholder="">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label>Email</label><span class="text-danger"> *</span>
                    <input type="text" class="form-control" formControlName="email" aria-describedby="helpId"
                           placeholder="">
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label>Giới tính</label><span class="text-danger"> *</span>
                    <select class="form-control" formControlName="gender">
                      <option value="1">Nam</option>
                      <option value="0">Nữ</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label>Ngày sinh</label><span class="text-danger"> *</span>
                    <input type="text" class="form-control" formControlName="dob" aria-describedby="helpId"
                           placeholder="">
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label>CMND/CCCD</label><span class="text-danger"> *</span>
                    <input type="text" class="form-control" formControlName="icq" aria-describedby="helpId"
                           placeholder="">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label>Số điện thoại</label><span class="text-danger"> *</span>
                    <input type="text" class="form-control" formControlName="phone1" aria-describedby="helpId"
                           placeholder="">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label>Đơn vị</label><span class="text-danger"> *</span>
                    <ng-select [searchable]="true" formControlName="departmentid" class="form-control select2 custom-select-box">
                      <ng-option *ngFor="let department of listDepartment" [value]="department.id">{{department.name}}</ng-option>
                    </ng-select>
                    <small
                      *ngIf="updateUserInfoForm.get('departmentid')!.invalid && updateUserInfoForm.get('departmentid')!.touched"
                      class="text-danger">{{ 'error.departmentempty' | translate }}</small>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label>Đơn vị cấp dưới</label><span class="text-danger"> *</span>
                    <input type="text" class="form-control" formControlName="subdepartment" aria-describedby="helpId"
                           placeholder="">
                    <div
                      *ngIf="updateUserInfoForm.get('subdepartment')!.invalid && (updateUserInfoForm.get('subdepartment')!.dirty || updateUserInfoForm.get('subdepartment')!.touched)">
                      <small class="text-danger" *ngIf="updateUserInfoForm.get('subdepartment')?.errors?.required">{{
                        'error.validate.input_required' | translate }}</small>
                      <small class="text-danger" *ngIf="updateUserInfoForm.get('subdepartment')?.errors?.isBlank">{{
                        'error.validate.input_required' | translate }}</small>
                    </div>
                  </div>
                </div>
              </div>
        </form>
      </div>

</div>
<div class="modal-footer m-auto text-center">
  <button type="button"
          [disabled]="updateUserInfoForm.invalid"
          class="btn btn-primary mr-3" (click)="updateUserInfo()" data-dismiss="modal">
    {{ 'common.update' | translate }}
  </button>
  <button type="button" class="btn btn-secondary" style="font-size: 12pt;" (click)="close()"> Đóng</button>
</div>

