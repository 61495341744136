<div class="container-fluid">
 <div class="card card-default">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" (click)="reportExportCourseInSystem()" mattooltip="Xuất File Excel" mattooltipclass="tooltip-red" class="btn btn-default btn-lg mr-1" ng-reflect-message="Xuất File Excel" ng-reflect-tooltip-class="tooltip-red" aria-describedby="cdk-describedby-message-0" cdk-describedby-host=""><i class="fas fa-file-excel mr-1"></i></button>
    </div></div>
     <div class="card-body">
       <div class="row">
         <div class="col-4">
          <div class="form-group">
            <label>Danh mục chủ đề</label>
            <select class="form-control select2" (change)="reportCourseInSystem($event.target.value)" style="width: 100%;">
              <option [value]="0">-Tất cả -</option>
              <option *ngFor="let item of categoryMap | keyvalue" [value]="item.key">{{item.value}}</option>
            </select>
            </div>
         </div>

       </div>
        <div class="row">
            <div class="col-xl-6" >
              <div>
                <canvas baseChart
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [legend]="barChartLegend"
                  [plugins]="barChartPlugins"
                  [chartType]="barChartType">
                </canvas>
              </div>
            </div>
            <div class="col-xl-6">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col" *ngFor="let item of barChartLabels">{{item}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{titleTable.courseLabel}}</td>
                            <td *ngFor="let item of this.barChartData[0].data">{{item}}</td>
                          </tr>
                          <tr>
                            <td>{{titleTable.studentLabel}}</td>
                            <td *ngFor="let item of this.barChartData[1].data">{{item}}</td>
                          </tr>
                          <tr>
                            <td>Tổng số học viên</td>
                            <td colspan="3" style="text-align: center;">{{_parseInt(this.barChartData[1].data[0]) + _parseInt(this.barChartData[1].data[1]) + _parseInt(this.barChartData[1].data[2])}}</td>
                          </tr>
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
     </div>
     <div class="card-footer">

     </div>
 </div>
