import { Component, EventEmitter, OnInit , Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Department } from 'src/app/shared/model/department.model';
import { DepartmentService } from '../../../shared/services/department.service';
import { PositionService } from '../../../shared/services/position.service';
import * as moment from 'moment';

@Component({
  selector: 'app-single-survey-search',
  templateUrl: './single-survey-search.component.html',
  styleUrls: ['./single-survey-search.component.css']
})
export class SingleSurveySearchComponent implements OnInit {
  @Input() searchSurvey;
  startDate: any;
  endDate: any;
  positionList: any[] = [];
  positionData: number [] = [];
  statusSearchData: any;
  status: any;

  departmentMap = new Map();
  departmentList: Department[] = [
    {id: 0, name: '-Chọn đơn vị-', code: '', parentid: null, selected: true}
  ];
  departmentid: number;
  public event: EventEmitter<any> = new EventEmitter();

  dropdownPositionSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Chọn hết',
    unSelectAllText: 'Bỏ chọn hết',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    searchPlaceholderText: 'Tìm kiếm'
  };

  constructor(
    public activeModal: NgbActiveModal,
    private departmentService: DepartmentService,
    private positionService: PositionService,
  ) { }

  ngOnInit(): void {
    this.statusSearchData = [
      {name: 'Chưa kích hoạt', id: 1},
      {name: 'Chưa diễn ra', id: 2},
      {name: 'Đang diễn ra', id: 3},
      {name: 'Đã kết thúc', id: 4}
    ];
    if(this.searchSurvey.status){
      this.status = this.searchSurvey.status;
    }else{
      this.status = null;
    }

    if(this.searchSurvey.refdepartmentid){
      this.departmentid = this.searchSurvey.refdepartmentid;
    }else{
      this.departmentid = 0;
    }

    if(this.searchSurvey.positionData){
      this.positionData = this.searchSurvey.positionData;
    }else{
      this.positionData = null;
    }

    if (this.searchSurvey.startDate) {
      this.startDate = moment.unix(this.searchSurvey.startDate).toDate();
    }
    if (this.searchSurvey.endDate) {
      this.endDate = moment.unix(this.searchSurvey.endDate).toDate();
    }

    this.getListPosition();
    this.getDepartmentList();
  }

  getListPosition() {
    return this.positionService.getPosition().subscribe(positionList => {
      this.positionList = positionList.body.results;
    });
  }

  setDepartmentData() {
    this.departmentMap.clear();
    this.departmentList.forEach(department => {
      if (department.parentid !== null) {
        const parentName = this.departmentMap.get(department.parentid);
        let name = '';
        if (parentName) {
          name = parentName + ' / ' + department.name;
        } else {
          name = department.name;
        }
        this.departmentMap.set(department.id, name);
      } else {
        this.departmentMap.set(department.id, department.name);
      }
    });
  }

  onSearch(){
    if(this.endDate){
      //lay cuoi ngay
      this.endDate.setHours(23);
      this.endDate.setMinutes(59);
      this.endDate.setSeconds(59);
    }
    this.event.emit({
      startDate: this.startDate,
      endDate: this.endDate,
      departmentid: this.departmentid,
      status : (this.status==="null") ? null : this.status,
      positionData: this.positionData,
    });
    this.activeModal.dismiss();
  }

  resetSearch() {
    this.startDate = null;
    this.endDate = null;
    this.status = null;
    this.positionData = [];
    this.departmentid = 0;
  }

  onDeSelectPosition($event) {
    this.positionData = [...this.positionData]?.filter(id => id !== $event.id);
  }

  onDeAllSelectPosition() {
    this.positionData = [];
  }

  getDepartmentList() {
    this.departmentService.getUserDepartmentTree(0).subscribe((data) => {
      this.departmentList.push(...data.body);
      this.setDepartmentData();
    });
  }
}
