import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Survey} from '../model/survey';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import CommonUtil from '../utils/common-util';
import {MOODLE_INFO} from '../constants/base.constant';
import {map} from 'rxjs/operators';
import {Sort} from '@angular/material/sort';

type EntityResponseType = HttpResponse<Survey>;
type EntityArrayResponseType = HttpResponse<Survey[]>;

interface SurveyPageResponse {
  results: Survey[];
  total: number;
}

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(private abstractService: AbstractService) {

  }


  createSingleSurvey(newSingleSurvey: Survey): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_survey_create_single');
    const info: any = {
      name: newSingleSurvey.name,
      // introeditor: newSingleSurvey.introeditor,
      qtype: newSingleSurvey.qtype,
      startdate: newSingleSurvey.startdate,
      enddate: newSingleSurvey.enddate,
    };
    CommonUtil.appendDataToFormData(data, 'info', info);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }


  createTemplateSurvey(newSingleSurvey: Survey): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_survey_create_template');
    const infor: any = {
      name: newSingleSurvey.name,
      // introeditor: newSingleSurvey.introeditor,
      qtype: newSingleSurvey.qtype
    };
    CommonUtil.appendDataToFormData(data, 'info', infor);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  searchSingleSurvey(params: any): Observable<HttpResponse<SurveyPageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_survey_single_list');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<SurveyPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<SurveyPageResponse>) => res));
  }

  searchTemplateSurvey(params): Observable<HttpResponse<SurveyPageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_survey_template_list');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<SurveyPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<SurveyPageResponse>) => res));
  }


  getDetailSurvey(idSurvey: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_survey_detail');
    CommonUtil.appendDataToFormData(data, 'info[id]', idSurvey);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  attemptSurvey(idSurvey: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_survey_enduser_attempt_survey');
    CommonUtil.appendDataToFormData(data, 'info[id]', idSurvey);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }


  updateSurvey(surveyUpdate: any): Observable<EntityResponseType> {
    const info: any = {
      id: surveyUpdate.questionnaireid,
      name: surveyUpdate.name,
      // intro: surveyUpdate.introeditor,
      published: 0,
      startdate: surveyUpdate.startdate,
      enddate: surveyUpdate.enddate,
      qtype: surveyUpdate.qtype
    };
    const data = this.abstractService.getCommonInput('mobifone_survey_update_information');
    CommonUtil.appendDataToFormData(data, 'info', info);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  createQuestionTextInput(params: any): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_survey_create_question_text_input');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: any) => res));
  }

  createQuestionSelect(params: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_survey_create_question_select');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  createQuestionRate(params: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_survey_create_question_rate');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  publishTemplateSurvey(idPublish: any, publishDate: any, type: any): Observable<EntityResponseType> {
    const params: any = {
      id: idPublish,
      qtype: type
    };
    const data = this.abstractService.getCommonInput('mobifone_survey_active_template');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  publishOrStopSingleSurvey(idPublish: any, isActive: any): Observable<EntityResponseType> {
    const params: any = {
      id: idPublish,
    //  publishedat: publishDate,
      active: +isActive
    };
    const data = this.abstractService.getCommonInput('mobifone_survey_publish_single');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  answerQuestionSurvey(contextModuleId: any, questionNaireId: any, arrayAnswer: any): Observable<EntityResponseType> {
    const params: any = {
      cmid: contextModuleId,
      sid: questionNaireId,
      answer: arrayAnswer
    };
    const data = this.abstractService.getCommonInput('mobifone_survey_push_question');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  report(questionNaireId: any): Observable<HttpResponse<any>> {
    const params: any = {
      id: questionNaireId
    };
    const data = this.abstractService.getCommonInput('mobifone_survey_report');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  deleteSurvey(questionnaireSurvey: any): Observable<EntityArrayResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_survey_delete');
    CommonUtil.appendDataToFormData(data, 'params[id]', questionnaireSurvey);
    return this.abstractService.post<Survey[]>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponseType) => res));
  }

  deleteQuestion(contextModuleId: any, questionNaireId: any, questionId: any): Observable<EntityResponseType> {
    const params: any = {
      cmid: contextModuleId,
      qnid: questionNaireId,
      qsid: questionId
    };
    const data = this.abstractService.getCommonInput('mobifone_survey_delete_question');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updateQuestion(params: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_survey_update_question');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  getQuestionNaireIdOfCourse(id: any): Observable<EntityResponseType> {
    const params: any = {
      courseid: id
    };
    const data = this.abstractService.getCommonInput('mobifone_survey_course');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  getEnduserSurveyOfCourse(id: any): Observable<EntityResponseType> {
    const params: any = {
      courseid: id
    };
    const data = this.abstractService.getCommonInput('mobifone_survey_course_enduser');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  deleteTemplateSurvey(idQuestionNaireSurvey: any): Observable<EntityResponseType> {
    const params: any = {
      questionnaireid: idQuestionNaireSurvey
    };
    const data = this.abstractService.getCommonInput('mobifone_survey_delete_template');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  deleteMultiSurvey(params: any): Observable<any>{
    const data = this.abstractService.getCommonInput('mobifone_survey_multi_delete');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: any) => res));
  }

  exportSingleSurvey(params: any): Observable<any>{
    const data = this.abstractService.getCommonInput('mobifone_survey_export_single_survey_detail');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Survey>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: any) => res));
  }
}
