import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {DepartmentDetailComponent} from './department-detail/department-detail.component';
import {DepartmentService} from '../../shared/services/department.service';
import {Department} from '../../shared/model/department.model';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import { TreeViewComponent } from '@syncfusion/ej2-angular-navigations';
import {ConfirmModalComponent} from '../../shared/modal/confirm-modal/confirm-modal.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {USER_INFO, USER_ROLE} from '../../shared/constants/base.constant';
import {LocalStorageService} from 'ngx-webstorage';
import {Sort} from '@angular/material/sort';

// import any = jasmine.any;nodeSelected

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css'],
  providers: [NgbModal, NgbModalConfig]
})
export class DepartmentComponent implements OnInit, OnDestroy {

  // Tree
  @ViewChild('departmentTree')
  public departmentTree: TreeViewComponent;
  public departmentTreeData;
  currentSelectedId: number;

  // List
  departmentList: Department[] = [];

  // For delete
  completedAll = false;
  departmentIds: number[] = [];
  departmentIdsChecked: number[] = [];
  isDepartmentRoot: boolean = false;

  // pagination & search
  totalRecord: number;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  sortColumn = 'id';
  sortType = 'ASC';
  keyword = '';

  isAdmin = false;


  constructor(
    private modalService: NgbModal,
    private departmentService: DepartmentService,
    private toastrService: ToastrCustomService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private $localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    const roles = this.$localStorage.retrieve(USER_INFO.ROLES);
    if (roles) {
      for (const role of roles) {
        if (role.shortname === USER_ROLE.ADMIN) {
          this.isAdmin = true;
        }
      }
    }
    this.reLoadData();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.departmentList.forEach(c => {
        if (c.id !== 1) {
          c.completed = checked;
          if (!this.departmentIds.includes(c.id)) {
            this.departmentIds.push(c.id);
          }
          if (!this.departmentIdsChecked?.includes(c.id)) {
            this.departmentIdsChecked?.push(c.id);
          }
        }
      });
      this.completedAll = true;
    } else {
      this.departmentIds?.forEach(id => {
        this.departmentIdsChecked?.splice(this.departmentIdsChecked?.indexOf(id), 1);
      });
      this.departmentIds = [];
      this.departmentList?.forEach(c => {
        c.completed = false;
      });
      this.completedAll = false;
    }
  }

  oncheckboxItem(courseId: number, checked: any) {
    if (checked) {
      const dpartmentNumber = this.isDepartmentRoot ? (this.departmentList?.length - 1) : this.departmentList?.length;
      this.departmentList?.forEach(c => {
        if (c.id === courseId) {
          c.completed = true;
          this.departmentIds?.push(courseId);
          this.departmentIdsChecked?.push(courseId);
          return;
        }
      });
      if (this.departmentIds?.length > 0 && this.departmentIds?.length === dpartmentNumber && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.completedAll = false;
      this.departmentList?.forEach(c => {
        if (c.id === courseId) {
          c.completed = false;
          this.departmentIds?.splice(this.departmentIds?.indexOf(courseId), 1);
          this.departmentIdsChecked?.splice(this.departmentIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
  }

  onCreateDepartment() {
    const current: Department = {
      name: '',
      description: '',
      parentid: this.currentSelectedId,
      code: null,
      selected: null,
    };
    const title = 'department.create_title';
    const button = 'common.add';
    this.openEditDepartmentPopup(current, title, button);
  }

  onEditDepartment(item) {
    // Set lại để loại bỏ các trường thừa như createduser///
    const current: Department = {
      id: item.id,
      name: item.name,
      description: item.description,
      parentid: item.parentid,
      code: item.code,
      selected: null,
    };
    const title = 'department.update_title';
    const button = 'common.save';
    this.openEditDepartmentPopup(current, title, button);
  }

  openEditDepartmentPopup(item, title, button){
    const modalDep = this.modalService.open(DepartmentDetailComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.department = item;
    modalDep.componentInstance.departmentList = this.departmentTreeData.dataSource;
    modalDep.componentInstance.title = title;
    modalDep.componentInstance.button = button;
    modalDep.componentInstance.treeStatus = this.departmentTree.getTreeData();
    modalDep.componentInstance.newDepartment.subscribe(($e) => {
      this.reLoadData();
    });

    modalDep.componentInstance.update.subscribe(($e) => {
      // console.log('test: ');
      // console.log($e);
    });
  }

  onDeleteMultipleDepartment() {
    this.onDeleteDepartment(this.departmentIdsChecked);
  }

  onDeleteSingleDepartment(departmentId: number) {
    this.onDeleteDepartment([departmentId]);
  }

  onDeleteDepartment(ids: number []) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('department.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('department.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');

    modalDep.result.then((result) => {
      // console.log('result: ', result);
      this.spinner.show();
      this.departmentService.deleteDepartment(ids).subscribe(
        res => {
          const dataDepartment = this.departmentTree.getTreeData();
          [...ids]?.forEach(element => {
            const indexArr = this.departmentList.findIndex( item => item.id === element);
            dataDepartment.splice(indexArr, 1);
            this.departmentTreeData = { dataSource: dataDepartment, id: 'id', parentID: 'parentid', text: 'name', hasChildren: 'haschild'};
            this.departmentIds.splice(this.departmentIds.indexOf(element), 1);
            this.departmentIdsChecked.splice(this.departmentIdsChecked.indexOf(element), 1);
          });
          this.spinner.hide();
          this.toastrService.success(`common.noti.delete_success`);
          this.reLoadData();
        },
        err => {
          this.spinner.hide();
          this.reLoadData();
          this.toastrService.handlerError(err);
        }
      );
    });
  }

  onSearch() {
    const params = {
      limit: this.pageSize,
      page: this.pageIndex,
      keyword: this.keyword,
      id: this.currentSelectedId,
      sortcolumn: this.sortColumn,
      sorttype: this.sortType
    };
    this.spinner.show();
    this.departmentIds = [];
    this.isDepartmentRoot = false;
    this.departmentService.searchDepartment(params).subscribe((data) => {
      data.body.results.forEach(value => {
        if (value.id === 1) {
          this.isDepartmentRoot = true;
        }
        value.expanded = value.haschild !== null;
        value.isChecked = true;
        if (this.departmentIdsChecked?.includes(value.id)) {
          value.completed = true;
          this.departmentIds?.push(value.id);
        }
      });
      if (this.departmentIds?.length > 0 && (this.departmentIds?.length === (this.isDepartmentRoot ? (data.body?.results?.length - 1) : data.body?.results?.length))) {
        this.completedAll = true;
      } else {
        this.completedAll = false;
      }
      this.departmentList = data.body.results;
      this.totalRecord = data.body.total;
      this.spinner.hide();
    },
      error => {
      this.toastrService.handlerError(error);
      this.spinner.hide();
      });
  }

  listAllTree() {
    this.departmentService.getUserDepartmentTree(0).subscribe((data) => {
      const dataSource = data.body;
      this.setUpTree(dataSource);
      this.departmentTreeData = {
        dataSource, id: 'id', parentID: 'parentid', text: 'name', hasChildren: 'haschild', selected: 'isSelected'
      };
    });
  }

  nodeSelected(e) {
    this.currentSelectedId = +this.departmentTree.getNode(e.node).id;
    this.departmentIds = [];
    this.departmentIdsChecked = [];
    this.pageSize = 10;
    this.pageIndex = 1;
    this.sortColumn = 'id';
    this.sortType = 'ASC';
    this.onSearch();
  }

  setUpTree(dataSource: any) {

    const indexOfCurrentSelectedId = dataSource.findIndex(x => x.id === this.currentSelectedId);
    if (indexOfCurrentSelectedId === -1){// case currentSelectedId is deleted -> set to deault
      this.currentSelectedId = null;
    }

    // set thang cha = null de no hien thi len duoc cay
    dataSource[0].parentid = null;
    // muc dich la de expand tree
    dataSource.forEach(value => {
      if (value.parentid == null)
      {
        value.expanded = true; // value.haschild !== null;
      } else {
        value.expanded = false;
      }
      value.isChecked = true;
    });
  }

  reLoadData(){
    this.listAllTree();
    this.onSearch();
  }

  changePage(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.onSearch();
  }

  /**
   * clear select node
   * clear search param
   */
  clear(){
    // param phuc vu tim kiem
    this.keyword = '';
    this.currentSelectedId = null;
    this.pageSize = 10;
    this.pageIndex = 1;

    // Bo select node tren cay
    this.departmentTree.selectedNodes = [];
    this.departmentIdsChecked = [];

    this.onSearch();
  }

  sortData(sort: Sort) {
    this.pageIndex = 1;
    this.sortColumn = sort.active;
    this.sortType = sort.direction;
    this.onSearch();
  }

  onChangeKeyWord(){
    this.pageIndex = 1;
    this.onSearch();
  }

}
