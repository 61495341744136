import {Component, EventEmitter, Input, isDevMode, OnInit, Output} from '@angular/core';
import {UserInfo} from '../../../shared/model/user-info.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import CommonUtil from '../../../shared/utils/common-util';
import {UserService} from '../../../shared/services/user.service';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {RoleSystem} from '../../../shared/model/role-system';
import {RoleService} from '../../../shared/services/role.service';
import {TranslateService} from '@ngx-translate/core';
import {USER_AUTH, USER_INFO, USER_ROLE} from '../../../shared/constants/base.constant';
import {LocalStorageService} from 'ngx-webstorage';
import {CustomValidators} from '../../../shared/utils/custom-validators';
import {Department} from '../../../shared/model/department.model';
import {Position} from '../../../shared/model/position.model';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  @Input() user: UserInfo;
  @Input() isChangePassword: boolean;
  @Input() departmentList: Department[] = [];
  @Input() positionList: Position[] = [];
  @Input() button;

  @Output() newUser = new EventEmitter<UserInfo>();
  authMethods = [
    {
      value: 'manual',
      label: 'manual'
    },
    {
      value: 'ldap',
      label: 'ldap'
    }
  ];

  dropdownPositionSettings = {
    singleSelection: false,
    idField: 'id',
    searchPlaceholderText: 'Tìm kiếm',
    textField: 'name',
    selectAllText: 'Chọn hết',
    unSelectAllText: 'Bỏ chọn hết',
    itemsShowLimit: 5,
    allowSearchFilter: true
  };
  currentUserRole: any;
  roleList: RoleSystem[] = [];

  departmentMap = new Map();
  showManagedDepartment = false;
  isPasswordMatch = true;
  showDepartmentRequired = false;
  disabled = false;

  selectedPositions = [];
  selectedPositionIds = [];
  selectedDepartmentFirst: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private roleService: RoleService,
    private translateService: TranslateService,
    private $localStorage: LocalStorageService,
  ) {

  }

  createUserForm = this.fb.group({
    id: [],
    firstname: [, [Validators.required, CustomValidators.notBlankValidator]],
    lastname: [, [Validators.required, CustomValidators.notBlankValidator]],
    auth: [, Validators.required],
    username: [, [Validators.required, CustomValidators.notBlankValidator]],
    phone1: [, [Validators.pattern('(0|\\+84)(\\s|\\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\\d)(\\s|\\.)?(\\d{3})(\\s|\\.)?(\\d{3})$')]],
    email: [, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')]],
    description: [''],
    roleid: [, Validators.required],
    password: [],
    repassword: [],
    departmentid: [],
    manageddepartmentid: [],
    selectedPositions: []
  });

  ngOnInit(): void {
    this.setDepartmentData();
    const roles: RoleSystem[] = this.$localStorage.retrieve(USER_INFO.ROLES);
    if (roles.length > 0) {
      this.currentUserRole = roles[0].shortname;
    }
    this.getListRoleSystem();
    // Set chuc danh cho user edit

    if (this.user.id == null) {
      this.createUserForm.patchValue({
        departmentid: null,
      });
    } else {
      // Set value cho form
      this.createUserForm.patchValue({
        id: this.user.id,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        auth: this.user.auth,
        username: this.user.username,
        phone1: this.user.phone1,
        email: this.user.email,
        description: this.user.description,
        roleid: this.user.roleid,
        departmentid: this.user.departmentid,
        manageddepartmentid: this.user.manageddepartmentid,
      });

      if (this.user.positionids) {
        const positionIdArray = this.user.positionids.split(';');
        for (const positionId of positionIdArray) {
          const foundPosition = this.positionList.find(value => value.id === +positionId);
          this.selectedPositions.push(foundPosition);
        }
        this.createUserForm.patchValue({
          selectedPositions: this.selectedPositions,
        });
      } else {
        this.selectedPositions = [];
      }

      this.onChangeAuthMethod();
      this.onChangePassword();
      this.onChangePosition();
      this.onChangeDepartment();
      this.onChangeRole();
    }
  }

  setDepartmentData() {
    this.departmentMap.clear();
    this.departmentList.forEach(department => {
      if (department.parentid !== null) {
        const parentName = this.departmentMap.get(department.parentid);
        let name = '';
        if (parentName) {
          name = parentName + ' / ' + department.name;
        } else {
          name = department.name;
        }
        this.departmentMap.set(department.id, name);
      } else {
        this.departmentMap.set(department.id, department.name);
        this.selectedDepartmentFirst = {key: department.id, value: department.name};
      }
    });
  }

  onChangeRole() {
    const selectedRole = this.roleList.find(role => role.id === +this.createUserForm.get('roleid').value);
    if (selectedRole) {
      if (USER_ROLE.TEACHER === selectedRole.shortname) {
        // USER_ROLE.ADMIN === selectedRole.shortname ||
        this.showManagedDepartment = false;
        this.createUserForm.get('departmentid').clearValidators();
        this.createUserForm.get('departmentid').updateValueAndValidity();
        this.createUserForm.get('manageddepartmentid').clearValidators();
        this.createUserForm.get('manageddepartmentid').updateValueAndValidity();
        this.createUserForm.get('selectedPositions').clearValidators();
        this.createUserForm.get('selectedPositions').updateValueAndValidity();
      } else {
        // Khong phai giang vien
        this.createUserForm.get('departmentid').setValidators([Validators.required]);
        this.createUserForm.get('departmentid').updateValueAndValidity();
        this.createUserForm.get('selectedPositions').setValidators([Validators.required]);
        this.createUserForm.get('selectedPositions').updateValueAndValidity();
        if (USER_ROLE.MANAGER === selectedRole.shortname) {
          this.createUserForm.get('manageddepartmentid').setValidators([Validators.required]);
          this.createUserForm.get('manageddepartmentid').setValue(this.selectedDepartmentFirst.key);
          this.createUserForm.get('manageddepartmentid').updateValueAndValidity();
          this.showManagedDepartment = true;
        } else {
          this.createUserForm.get('manageddepartmentid').clearValidators();
          this.createUserForm.get('manageddepartmentid').setValue(null);
          this.createUserForm.get('manageddepartmentid').updateValueAndValidity();
          this.showManagedDepartment = false;
        }
      }
    }
  }

  onChangeDepartment() {
    if (this.createUserForm.get('departmentid').value.toString() === 'null') {
      this.onChangeRole();
    } else {
      this.createUserForm.get('selectedPositions').setValidators([Validators.required]);
      this.createUserForm.get('selectedPositions').updateValueAndValidity();
      this.createUserForm.get('departmentid').setValidators([Validators.required]);
      this.createUserForm.get('departmentid').updateValueAndValidity();
    }
  }

  onChangePosition() {
    if (this.createUserForm.get('selectedPositions').value) {
      if (this.createUserForm.get('selectedPositions').value.length > 0) {
        this.selectedPositionIds = [];
        for (const position of this.createUserForm.get('selectedPositions').value) {
          if (position) {
            this.selectedPositionIds.push(position.id);
          }
        }
        this.showDepartmentRequired = true;
        this.createUserForm.get('departmentid').setValidators([Validators.required]);
        this.createUserForm.get('departmentid').updateValueAndValidity();
      } else {
        this.selectedPositionIds = [];
        this.showDepartmentRequired = false;
        this.onChangeRole();
      }
    } else {
      this.selectedPositionIds = [];
      this.showDepartmentRequired = false;
      this.onChangeRole();
    }
  }

  getListRoleSystem() {
    return this.roleService.getListRoleSystem().subscribe(roleList => {
      this.roleList = roleList.body.results;
      this.roleList.filter(role => {
        if (role.shortname === USER_ROLE.MANAGER) {
          this.showManagedDepartment = role.id === this.user.roleid;
        }
      });
      if (this.currentUserRole === USER_ROLE.MANAGER) {
        this.roleList = this.roleList.filter(role => role.shortname !== USER_ROLE.ADMIN);
      }
    });
  }

  onChangeAuthMethod() {
    if (this.isChangePassword) {
      this.createUserForm.get('password').enable();
      this.createUserForm.get('repassword').enable();
      this.createUserForm.get('password').setValidators([Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!@#$%^&*).{8,20}$')]);
      this.createUserForm.get('repassword').setValidators([Validators.required]);
      this.createUserForm.get('password').updateValueAndValidity();
      this.createUserForm.get('repassword').updateValueAndValidity();
    } else {
      if (this.user.id) {
        this.createUserForm.get('password').clearValidators();
        this.createUserForm.get('repassword').clearValidators();
        this.createUserForm.get('password').updateValueAndValidity();
        this.createUserForm.get('repassword').updateValueAndValidity();
      } else {
        if (USER_AUTH.LDAP === this.createUserForm.get('auth').value) {
          this.createUserForm.get('password').disable();
          this.createUserForm.get('repassword').disable();
          this.createUserForm.get('password').clearValidators();
          this.createUserForm.get('repassword').clearValidators();
          this.createUserForm.get('password').updateValueAndValidity();
          this.createUserForm.get('repassword').updateValueAndValidity();
          this.createUserForm.patchValue({
            password: null,
            repassword: null,
          });
          this.isPasswordMatch = true;
        } else {
          this.createUserForm.get('password').enable();
          this.createUserForm.get('repassword').enable();
          this.createUserForm.get('password').setValidators([Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!@#$%^&*).{8,20}$')]);
          this.createUserForm.get('repassword').setValidators([Validators.required]);
          this.createUserForm.get('password').updateValueAndValidity();
          this.createUserForm.get('repassword').updateValueAndValidity();
        }
      }
    }
  }

  onSubmit() {
    if (this.createUserForm.invalid) {
      CommonUtil.markFormGroupTouched(this.createUserForm);
      return;
    }
    if (!this.isPasswordMatch) {
      return;
    }
    this.user.roleid = this.createUserForm.value.roleid;
    this.user.username = this.createUserForm.value.username;
    this.user.firstname = this.createUserForm.value.firstname;
    this.user.lastname = this.createUserForm.value.lastname;
    this.user.phone1 = this.createUserForm.value.phone1;
    this.user.email = this.createUserForm.value.email;
    this.user.auth = this.createUserForm.value.auth;
    this.user.description = this.createUserForm.value.description;
    this.user.manageddepartmentid = this.createUserForm.value.manageddepartmentid;
    if (!this.user.id) {
      this.user.password = this.createUserForm.value.password;
    }
    if (this.isChangePassword) {
      this.user.password = this.createUserForm.value.password;
    }
    if (this.createUserForm.get('departmentid').value) {
      if (this.createUserForm.get('departmentid').value.toString() === 'null') {
        this.user.departmentid = null;
      } else {
        this.user.departmentid = this.createUserForm.value.departmentid;
      }
    } else {
      this.user.departmentid = null;
    }
    this.user.positionids = this.selectedPositionIds.toString();
    this.disabled = true;
    if (!this.user.id) {
      this.spinner.show();
      this.user.fullname = null;
      this.userService.createUser(this.user).subscribe(res => {
          const user: UserInfo = res.body;
          user.departmentid = this.user.departmentid;
          if (isDevMode()) {
            console.log('user: ', user);
          }
          this.newUser.emit(user);
          this.toastrService.success('common.noti.create_success');
          this.createUserForm.reset();
          this.close();
        },
        err => {
          this.disabled = false;
          this.toastrService.handlerError(err);
          this.spinner.hide();
        });

    } else {
      this.spinner.show();
      // cap nhat nguoi dung
      this.user.departmentname = null;
      this.user.fullname = null;
      this.user.roleshortname = null;
      this.user.rolename = null;
      this.user.suspended = null;
      this.userService.updateUser(this.user).subscribe(res => {
          const user: UserInfo = res.body;
          user.departmentid = this.user.departmentid;
          if (isDevMode()) {
            console.log('user: ', user);
          }
          this.newUser.emit(user);
          this.toastrService.success('common.noti.update_success');
          this.createUserForm.reset();
          this.close();
        },
        err => {
          this.disabled = false;
          this.toastrService.handlerError(err);
          this.spinner.hide();
        });
    }
  }

  onChangePassword() {
    if (this.createUserForm.get('password').value && this.createUserForm.get('repassword').value) {
      if (this.createUserForm.get('password').value !== this.createUserForm.get('repassword').value) {
        this.isPasswordMatch = false;
      } else {
        this.isPasswordMatch = true;
      }
    }
  }

  close() {
    this.createUserForm.reset();
    this.spinner.hide();
    this.activeModal.dismiss('close');
  }

}
