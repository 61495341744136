<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <title>Evotek| Reset Password</title>
  <!-- Tell the browser to be responsive to screen width -->
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <!-- Ionicons -->
  <link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css" />
  <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700" rel="stylesheet" />
</head>
<body class="hold-transition sidebar-mini layout-fixed login-layout">
  <section class="login-block">
<div class="container mt-5">
  <div class="row">
    <div class="col-md-12 login-sec text-center iteamdoan">
      <img src="../../assets/ladipage/img/doan.png" alt="">
      <h2 class="text-center">Thay đổi mật khẩu</h2>
      <span class="fontSize__14">
        <span class="text-danger">*</span>
     Mật khẩu chứa tối thiểu 08 ký tự bao gồm chữ thường, chữ hoa & số
      </span>
      <form class="login-form" [formGroup]="resetPasswordForm" (ngSubmit)="showConfirmPopup()">
        <hr />
        <div class="form-group text-left">
          <label class="text-left">Mật khẩu mới</label><span class="text-danger"> *</span>
          <input type="password" class="form-control"
                 minlength="8" maxlength="20"
                 placeholder="Mật khẩu mới" formControlName="newpassword"/>
          <span class="text-danger"
                *ngIf="resetPasswordForm.get('newpassword').touched && resetPasswordForm.get('newpassword').hasError('required')">
            {{ 'error.validate.input_required' | translate }}
          </span>
          <span class="text-danger"
                *ngIf="resetPasswordForm.get('newpassword').touched && resetPasswordForm.get('newpassword').hasError('pattern')">
            {{ 'error.validate.password.pattern' | translate }}
          </span>
        </div>

        <div class="form-group text-left">
          <label class="">Nhập lại mật khẩu mới</label><span class="text-danger"> *</span>
          <input type="password" class="form-control" placeholder="Nhập lại mật khẩu mới" formControlName="confirmpassword"/>
          <span class="text-danger"
                *ngIf="resetPasswordForm.get('confirmpassword').touched && resetPasswordForm.get('confirmpassword').hasError('required')">
            {{ 'error.validate.input_required' | translate }}
          </span>
          <span class="text-danger"
                *ngIf="resetPasswordForm.get('confirmpassword').touched && resetPasswordForm.hasError('confirmpassword')">
            {{ 'error.validate.password.confirmpassword' | translate }}
          </span>
        </div>

        <div class="form-check-login text-center">
          <button type="submit" class="btn btn-primary">
            Đặt mật khẩu
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</section>
</body>
</html>
