import { BadgeService } from '../../../shared/services/badge.service';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrCustomService } from 'src/app/shared/services/toastr-custom.service';
import CommonUtil from 'src/app/shared/utils/common-util';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {CustomValidators} from '../../../shared/utils/custom-validators';

@Component({
  selector: 'app-badge-create',
  templateUrl: './badge-create.component.html',
  styleUrls: ['./badge-create.component.css']
})
export class BadgeCreateComponent implements OnInit {
  badgeForm = this.fb.group({
    name: ['', [Validators.required, CustomValidators.notBlankValidator, Validators.maxLength(50)]],
    description: ['', Validators.maxLength(200)],
    // expiredate: ['', Validators.required],
    // expireperiod: [, Validators.required]
  });
  expiredate: any;
  expireperiod: any;
  badgeFile: any;
  badeFileInvalid: any;
  badeFileErrorRequired: any;
  selectedLink = '0';
  currentDate: Date = new Date();
  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private toastrService: ToastrCustomService,
    private badeService: BadgeService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }

  setradio(e: string): void {
    this.selectedLink = e;
    switch (e) {
      case 'expiredate':
        this.badgeForm.removeControl('expireperiod');
        this.badgeForm.addControl('expiredate', new FormControl('', Validators.required));
        break;
        case 'expireperiod':
          this.badgeForm.removeControl('expiredate');
          this.badgeForm.addControl('expireperiod',
            new FormControl('', [Validators.required, CustomValidators.isNumberic]));
          break;
      default:
        this.badgeForm.removeControl('expireperiod');
        this.badgeForm.removeControl('expiredate');
        break;
    }
  }
  isSelected(name: string): boolean {
    if (!this.selectedLink) {
      return false;
    }
    return (this.selectedLink === name);
  }

  createNewBadge() {

    if (this.badgeForm.invalid) {
      if (this.badgeForm.invalid) {
        CommonUtil.markFormGroupTouched(this.badgeForm);
      }
      // if (!this.badgeFile) {
      //   this.badeFileErrorRequired = true;
      // } else {
      //   this.badeFileErrorRequired = false;
      // }
      return;
    }
    if (this.selectedLink === 'expiredate'){
      this.badgeForm.value.expiredate = this.badgeForm.value.expiredate.getTime() / 1000;
    }
    if (this.selectedLink === 'expireperiod'){
      this.badgeForm.value.expireperiod = this.badgeForm.value.expireperiod * 86400;
    }
    if (this.selectedLink === '0'){
      this.badgeForm.value.expiredate = 0;
      this.badgeForm.value.expireperiod = 0;
    }
    this.spinner.show();

    this.badeService.createBadge(this.badgeForm.value, this.badgeFile).subscribe(res => {
      this.spinner.hide();
      this.toastrService.success('common.noti.create_success');
      this.activeModal.dismiss();
      // console.log(res);
      this.router.navigate(['/admin/badge/detail', res.body.id]);
    },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      }
    );

  }

  // handleFileInput(files: FileList) {
  //   if (files.length > 0) {
  //     this.badeFileErrorRequired = false;
  //     if (files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg'
  //       && files[0].type !== 'image/png' && files[0].type !== 'image/bmp'
  //       && files[0].type !== 'image/gif') {
  //       this.badeFileInvalid = true;
  //     } else {
  //       this.badgeFile = files[0];
  //       this.badeFileInvalid = false;
  //     }
  //   }
  // }
}
