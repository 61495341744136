<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{course?.fullname}}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']">CMS</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/result-exam']">Báo cáo thi</a></li>
            <li class="breadcrumb-item active">{{course?.fullname}}</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-3 mb-3">
              <label for="" class="ml-2">Danh sách bài thi</label>
              <mat-drawer-container autosize class="example-container">
                <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
                  <mat-selection-list #shoes [multiple]="false">
                    <mat-list-option *ngFor="let module of modules" [selected]="module.selected"
                      (click)="onSelectQuiz(module)">
                      <span matTooltip="{{module.name}}" matTooltipClass="tooltip-inner">{{module.name?.length <= 80 ? module.name : (module.name | slice:0:80) + '...' }}</span>
                    </mat-list-option>
                  </mat-selection-list>
                </mat-drawer>

                <mat-drawer-content>
                  <div class="container-fluid">
                    <div class="row overflow-auto" style="height:auto;">
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12"><label for="" class="ml-2">Chi tiết bài thi</label></div>
                            <div class="col-md-4 col-sm-6 col-xs-12">
                              <div class="info-box">
                                <div class="info-box-content">
                                  <span class="info-box-text">Học viên thi</span>
                                  <h5 class="info-box-number">{{totalStudents}}</h5>
                                </div>
                                <!-- /.info-box-content -->
                              </div>
                              <!-- /.info-box -->
                            </div>
                            <!-- /.col -->
                            <div class="col-md-4 col-sm-6 col-xs-12">
                              <div class="info-box">
                                <div class="info-box-content">
                                  <span class="info-box-text">Học viên đạt</span>
                                  <h5 class="info-box-number">{{passedStudents}}</h5>
                                </div>
                              </div>
                            </div>
                            <div class="clearfix visible-sm-block"></div>
                            <div class="col-md-4 col-sm-6 col-xs-12">
                              <div class="info-box">
                                <div class="info-box-content">
                                  <span class="info-box-text">Học viên không đạt</span>
                                  <h5 class="info-box-number">{{failedStudents}}</h5>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="table-responsive">
                            <table class="table table-hover table-striped">
                              <thead>
                                <tr>
                                  <th>TT</th>
                                  <th>Tên học viên</th>
                                  <!-- <th>Tên khóa học</th> -->
                                  <th class="text-center">Số câu đúng</th>
                                  <th class="text-center">Số câu sai</th>
                                  <th class="text-center">Thời gian tham gia</th>
                                  <th class="text-center">Thời gian làm bài (phút)</th>
                                  <th class="text-center">Điểm thi</th>
                                  <th class="text-center">Điểm chuẩn</th>
                                  <th class="text-center">Trạng thái</th>
                                  <th>Thao tác</th>
                                </tr>
                              </thead>
                              <tbody *ngIf="students.length > 0">
                                <tr *ngFor="let student of students; let i = index;">
                                  <td>{{(pageIndex - 1) * pageSize + i + 1}}</td>
                                  <td>{{student.fullname}}</td>
                                  <!-- <td>Khóa học tìm hiểu văn hóa</td> -->
                                  <td class="text-center">{{student.correctquestion}}</td>
                                  <td class="text-center">{{student.incorrectquestion}}</td>
                                  <td class="text-center">{{getDateFromUnix(student.timestart)}}</td>
                                  <td class="text-center">{{student.timelimit/60}}</td>
                                  <td class="text-center">{{student.gradeuser}}/{{student.grade}}</td>
                                  <td class="text-center">{{student.gradepass}}</td>
                                  <td class="text-center">
                                    <span class="badge badge-warning" *ngIf="student.completionstate === 3">Không
                                      đạt</span>
                                    <span class="badge badge-success" *ngIf="student.completionstate === 2">Đạt</span>
                                  </td>
                                  <td> <a class="btn btn-default btn-sm" (click)="openResultUser(student.id, student.fullname)"><i
                                        class="fas fa-eye"></i></a> </td>
                                </tr>
                              </tbody>
                              <tr class="text-center" *ngIf="students.length === 0"><td colspan="8">Không có học viên nào</td></tr>
                            </table>
                          </div>
                          <div class="card-footer text-center" *ngIf="students?.length > 0">
                            <div class="row">
                              <mat-paginator showFirstLastButtons
                                  [length]="totalStudents"
                                  [pageSize]="pageSize"
                                  [pageIndex]="pageIndex - 1"
                                  (page)="changePage($event)"
                                  [hidePageSize]="false"
                                  [pageSizeOptions]="pageSizeOptions">
                              </mat-paginator>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </mat-drawer-content>
              </mat-drawer-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
