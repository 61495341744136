import { Component, OnInit, OnDestroy } from '@angular/core';
import {PositionService} from '../../shared/services/position.service';
import {Position} from '../../shared/model/position.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmModalComponent} from '../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {PositionEditComponent} from './position-edit/position-edit.component';
import {SelectCourseComponent} from './position-course/select-course.component';
import {Course} from '../../shared/model/course.model';
import * as moment from 'moment';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {LocalStorageService} from 'ngx-webstorage';
import {USER_INFO, USER_ROLE} from '../../shared/constants/base.constant';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-program-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.css']
})
export class PositionComponent implements OnInit, OnDestroy {
  positions: Position[] = [];
  position: Position;
  currentSelectedPositionId: null;
  courses: Course[] = [];
  rootCourseOrdernumber = [];
  coursesExam: Course[] = [];
  rootExamOrdernumber = [];
  courseIds = new Array();
  checkAll: boolean;
  searchPositionForm = this.fb.group({
    keyword: ['']
  });

  constructor(
    private programPositionService: PositionService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private $localStorage: LocalStorageService,
    private spinner: NgxSpinnerService,
  ) {}

  add = false;
  titleForm = 'common.add';
  positionForm: FormGroup;

  ngOnInit(): void {
    this.listPosition();
    this.addPosition();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  listPosition() {
    this.spinner.show();
    return this.programPositionService.getPosition().subscribe((data) => {
      const selectedPosition = this.positions.map(pos => { // find all id of position, which is selected before
        if (pos.checked === true){
          return pos.id;
        }
      });
      this.positions = data.body.results;
      // re-select checkbox
      this.positions.forEach(pos => {
        if (selectedPosition.indexOf(pos.id) >= 0){
          pos.checked = true;
        }
      });

      // check if currentSelectedPosition is delete.
      if (!this.positions.find(pos => pos.id === this.currentSelectedPositionId)){
        this.currentSelectedPositionId = null;
      }

      // focus on current position
      this.displayDetail(this.currentSelectedPositionId);
      this.spinner.hide();
    },
      error => {
        this.toastrService.handlerError(error);
        this.spinner.hide();
      });
  }

  submitForm() {
    //  console.log(this.positionForm.value);
    this.programPositionService.createPosition(this.positionForm.value).subscribe(res => {
      //   console.log('Category added!');
      this.ngOnInit();
      this.toastrService.success(`common.noti.create_success`);
    });
  }

  addPosition() {
    this.positionForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
    });
  }

  onDeleteMultiplePosition()
  {
    // find all id is check to delete
    const positionIds = this.positions.map(pos => {
      if (pos.checked === true){
        return pos.id;
      }
    }).filter(notUndefine => notUndefine !== undefined);
    this.deletePosition(positionIds);
  }

  onDeleteSinglePosition(position: number)
  {
    this.deletePosition([position]);
  }

  deletePosition(position) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('position.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('position.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalDep.result.then((result) => {
      // console.log('result: ', result);
      this.programPositionService.deletePosition(position).subscribe(
        res => {
          this.listPosition();
          this.toastrService.success(`common.noti.delete_success`);
        },
        err => {
          this.toastrService.handlerError(err);
        }
      );
    });
    this.checkAll = false;
  }

  displayDetail(positionid) {
    this.positions.forEach(po => { // MLMS-995 high ligh selected position
      if (po.id === positionid){
        po.selected = true;
      } else {
        po.selected = false;
      }
    });
    this.position = this.positions.find(p => p.id === positionid);
    this.currentSelectedPositionId = positionid;
    if (this.position){
      const params = {
        positionid,
        coursetype: 1,
        exclude: 0,
        action: 'get',
        limit: 0,
        sortcolumn: 'ordernumber',
        sorttype: 'ASC'
      };
      this.programPositionService.getCourseByPositionId(params).subscribe((data) => {
        this.courses = data.body.results;
        this.rootCourseOrdernumber = this.courses.map(course => course.ordernumber);
        // console.log(this.courses);
        this.courses.forEach(element => {
          // console.log(element.badges);
          if (element.badges !== null && element.badges !== undefined) {
            if (element.badges.length !== 0) {
              element.certificate = 'yes';
            } else {
              element.certificate = 'no';
            }
          }
        });
      }, error => {
        this.toastrService.handlerError(error);
      });
      params.coursetype = 2;
      this.programPositionService.getCourseByPositionId(params).subscribe((data) => {
        this.coursesExam = data.body.results;
        this.rootExamOrdernumber = this.coursesExam.map(exam => exam.ordernumber);
        this.coursesExam.forEach(element => {
          if (element.badges !== null && element.badges !== undefined) {
            if (element.badges.length !== 0) {
              element.certificate = 'yes';
            } else {
              element.certificate = 'no';
            }
          }
        });
      }, error => {
        this.toastrService.handlerError(error);
      });
    }else {
      // Khi khong co Chuc danh nao duoc click -> show blank page
      // TH Xoa chuc danh dang duoc select hoac truong hop khoi tao man hinh
      this.position = null;
    }

  }

  openListCourse(open: string) {
    const modalDep = this.modalService.open(SelectCourseComponent, {
      size: 'xl' as any,
      centered: false,
      backdrop: 'static'
    });
    if (this.courses !== undefined) {
      modalDep.componentInstance.listCourses = this.courses;
    }
    if (this.coursesExam !== undefined) {
      modalDep.componentInstance.listExam = this.coursesExam;
    }
    modalDep.componentInstance.positionId = this.currentSelectedPositionId;
    modalDep.componentInstance.open = open;
    modalDep.componentInstance.loadAll.subscribe(($e) => {
      this.displayDetail(this.currentSelectedPositionId);
    });
  }

  onSearch()
  {
    const paramPosition = {
      keyword: this.searchPositionForm.value.keyword,
      limit: 0
    };
    this.programPositionService.searchPosition(paramPosition).subscribe(data => {
      this.positions = data.body.results;
    });
  }

  isSelectAll(){
    const positionsChecked = this.positions.filter(p => p.checked === true);
    if (this.positions.length === positionsChecked.length){
      this.checkAll = true;
    }else{
      this.checkAll = false;
    }
  }

  selectAll(checked: any) {
    this.positions.forEach(element => {
      if (checked) {
        element.checked = true;
      } else{
        element.checked = false;
      }
    });
  }

  deleteCourse(courseId: number, coursetype) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static'
    });
    if (coursetype === 1) {
      modalDep.componentInstance.title = this.translateService.instant('position.delete_course_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('position.delete_course_confirm_content');
    }
    if (coursetype === 2) {
      modalDep.componentInstance.title = this.translateService.instant('quiz.delete_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('quiz.delete_confirm_content');
    }
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    // this.courseIds.push(courseId);
    modalDep.result.then((result) => {
      //  console.log('result: ', result);
      this.spinner.show();
      this.programPositionService.unAssignCourse(this.position.id, [courseId]).subscribe(
        res => {
          this.spinner.hide();
          this.displayDetail(this.currentSelectedPositionId);
          // TODO: Remove object khoi list nua.
          this.toastrService.success(`common.noti.delete_success`);
        },
        err => {
          this.spinner.hide();
          this.toastrService.handlerError(err);
        }
      );
    });
  }

  onCreatedPosition() {
    const position = {
      name: null,
      description: '',
      ordernumber: null,
    };
    this.openEditPositionPopup(position);
  }


  openEditPositionPopup(item) {
    const modalDep = this.modalService.open(PositionEditComponent, {
      size: 'lg' as any,
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.positions = this.positions;
    // tslint:disable-next-line:triple-equals
    if (item.name == '') {
      modalDep.componentInstance.newPosition.subscribe(($e) => {
        this.setCurrentSelected($e);
        this.listPosition();
      });
    } else {
      modalDep.componentInstance.position = item;
      modalDep.componentInstance.newPosition.subscribe(($e) => {
        // Thuc hien insert data vao list hoac reload li
        this.setCurrentSelected($e);
        this.listPosition();
      });
      item.checked = false;
    }
  }

  manyDelete(checked: any, item) {
    if (checked) {
      item.checked = true;
    } else {
      item.checked = false;
    }
    this.isSelectAll();
  }

  checkAdd() {
    if (this.add === false) {
      this.add = true;
    } else if (this.add === true) {
      this.add = false;
    }
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  /**
   *
   * param event
   * param courses: courses or exams
   * param rootOrderNumber: root order of course or exam
   */
  drop(event: CdkDragDrop<Course[]>, courses, rootOrderNumber) {
    // Kiem tra xem vi tri thay doi co hop le khong
    if (courses[event.currentIndex].candragging === 0){
      this.toastrService.handlerError({error: {errorcode: 'cannotdraggingcourse'}});
      return;
    }

    this.swapItemInArray(courses, event.previousIndex, event.currentIndex);
    const params = courses.map((course, index) => {
      if (index === event.currentIndex || index === event.previousIndex){
        return {
          courseid: course.id,
          ordernumber: rootOrderNumber[index]
        };
      } else {
        return null;
      }
    }).filter(course => course !== null);
    this.programPositionService.orderPosition(this.position.id, params).subscribe(
      res => {
      },
      err => {
        // Co loi xay ra thi tra ve vi tri cu
        this.swapItemInArray(courses, event.currentIndex, event.previousIndex);
        this.toastrService.handlerError(err);
      }
    );
  }

  swapItemInArray(array: any, previousIndex, currentIndex){
    const oldTarget = array[previousIndex];
    array[previousIndex] = array[currentIndex];
    array[currentIndex] = oldTarget;
  }

  isAdministrator(){
    // MLMS-899: Tam thoi chi cho admin duoc phep xoa khoa hoc/ bai thi
    const roles = this.$localStorage.retrieve(USER_INFO.ROLES);
    if (roles) {
      for (const role of roles) {
        if (role.shortname === USER_ROLE.ADMIN) {
          return  true;
        }
      }
    }
    return false;
  }

  setCurrentSelected(id: any){
    this.currentSelectedPositionId = id;
  }
}
