import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import CommonUtil from '../utils/common-util';
import {MOODLE_INFO} from '../constants/base.constant';
import {map} from 'rxjs/operators';
import {Department} from '../model/department.model';

type EntityResponseType = HttpResponse<Department>;
type EntityArrayResponseType = HttpResponse<Department[]>;


@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(private abstractService: AbstractService) {

  }

  createDepartment(department: Department): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_department_create_department');
    delete department.id;
    CommonUtil.appendDataToFormData(data, 'department', department);
    return this.abstractService.post<Department>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updateDepartment(department: Department): Observable<EntityResponseType> {
    const updateDepartment = this.abstractService.getCommonInput('mobifone_department_update_department');
    CommonUtil.appendDataToFormData(updateDepartment, 'department', department);
    return this.abstractService.post<Department>(MOODLE_INFO.SERVICE_URL, updateDepartment)
      .pipe(map((res: EntityResponseType) => res));
  }

  getlist(): Observable<EntityArrayResponseType> {
    const dataDepartment = this.abstractService.getCommonInput('mobifone_department_list_department');
    return this.abstractService.post<Department[]>(MOODLE_INFO.SERVICE_URL, dataDepartment)
      .pipe(map((res: EntityArrayResponseType) => res));

  }

  listDepartmentTree(firstId: number): Observable<EntityArrayResponseType> {
    const dataDepartment = this.abstractService.getCommonInput('mobifone_department_list_department_tree');
    const param = {
      id: firstId
    };
    CommonUtil.appendDataToFormData(dataDepartment, 'params', param);
    return this.abstractService.post<Department[]>(MOODLE_INFO.SERVICE_URL, dataDepartment)
      .pipe(map((res: EntityArrayResponseType) => res));

  }

  getUserDepartmentTree(showall): Observable<EntityArrayResponseType> {
    const dataDepartment = this.abstractService.getCommonInput('mobifone_department_list_user_department_tree');
    dataDepartment.append('showall', showall);
    return this.abstractService.post<Department[]>(MOODLE_INFO.SERVICE_URL, dataDepartment)
      .pipe(map((res: EntityArrayResponseType) => res));

  }

  searchDepartment(params: any): Observable<any> {
    const searchData = this.abstractService.getCommonInput('mobifone_department_list_department');
    CommonUtil.appendDataToFormData(searchData, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, searchData).pipe(map((res: any) => res));
  }

  deleteDepartment(id: number[]): Observable<EntityArrayResponseType> {
    let strid = id.toString();
    const dataDeleteDepartment = this.abstractService.getCommonInput('mobifone_department_delete_department');
    CommonUtil.appendDataToFormData(dataDeleteDepartment, 'params[ids]', strid);
    return this.abstractService.post<Department[]>(MOODLE_INFO.SERVICE_URL, dataDeleteDepartment)
      .pipe(map((res: EntityArrayResponseType) => res));
  }

  listAllDepartmentPublic() {
    return this.abstractService.get<any>(MOODLE_INFO.PUBLIC_DEPARTMENT)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  getAllStatisticalDepartment(){
    return this.abstractService.get<any>(MOODLE_INFO.STATISTICAL_DEPARTMENT)
      .pipe(map((res: HttpResponse<any>) => res));
  }
}

