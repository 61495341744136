import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {COMPLETION_STATE, GRADE_METHOD} from '../../../shared/constants/base.constant';
import {LocalStorageService} from 'ngx-webstorage';
import * as moment from 'moment';
import {CourseService} from '../../../shared/services/course.service';

@Component({
  selector: 'app-eu-course-exam-content',
  templateUrl: './eu-course-exam-content.component.html',
  styleUrls: ['./eu-course-exam-content.component.css']
})
export class EuCourseExamContentComponent implements OnInit, OnChanges {

  @Input() courseId: number;
  @Input() course: any;

  currentDate: Date = new Date();


  listExam: any = [];
  numberOfExam = 0;
  numberOfExamPassed = 0;
  numberOfExamFailed = 0;
  numberOfExamNotTaken = 0;
  isTeacher = false;

  constructor(
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private courseService: CourseService,
    private $localStorage: LocalStorageService,
  ) {
  }

  ngOnInit(): void {

    this.getListExamEu();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.course) {
      this.isTeacher = this.course.roleincourse === 'teacher';
    }
  }

  getListExamEu() {
    return this.courseService.getListExam(this.courseId).subscribe((data) => {
      this.listExam = data.body;
      this.numberOfExam = this.listExam.length;
      this.listExam.find(e => e.completionstate > 1);
      this.listExam.find(e => e.completionstate === null);
      this.listExam.find(e => e.completionstate < 1);
      this.listExam.forEach(element => {
        element.sumgrades = element.sumgrades ? Math.round(element.sumgrades * 100) / 100 : 0;
        element.gradequiz = element.gradequiz ? Math.ceil(element.gradequiz) : 0;
        if (element.grademethod === GRADE_METHOD.QUIZ_GRADEHIGHEST) {
          element.grademethodName = GRADE_METHOD.QUIZ_GRADEHIGHEST_DESCRIPTION;
        } else if (element.grademethod === GRADE_METHOD.QUIZ_GRADEAVERAGE) {
          element.grademethodName = GRADE_METHOD.QUIZ_GRADEAVERAGE_DESCRIPTION;
        } else if (element.grademethod === GRADE_METHOD.QUIZ_ATTEMPTFIRST) {
          element.grademethodName = GRADE_METHOD.QUIZ_ATTEMPTFIRST_DESCRIPTION;
        } else if (element.grademethod === GRADE_METHOD.QUIZ_ATTEMPTLAST) {
          element.grademethodName = GRADE_METHOD.QUIZ_ATTEMPTLAST_DESCRIPTION;
        }
        if (element.completionstate === COMPLETION_STATE.YET_EXAM) {
          this.numberOfExamNotTaken++;
          element.completionStateName = COMPLETION_STATE.YET_EXAM_DESCRIPTION;
        } else if (element.completionstate === COMPLETION_STATE.PASS_EXAM) {
          this.numberOfExamPassed++;
          element.completionStateName = COMPLETION_STATE.PASS_EXAM_DESCRIPTION;
        } else if (element.completionstate === COMPLETION_STATE.FAIL_EXAM || element.completionstate === COMPLETION_STATE.USED_EXAM) {
          this.numberOfExamFailed++;
          element.completionStateName = COMPLETION_STATE.FAIL_EXAM_DESCRIPTION;
        }
      });
    });
  }

  localStore(name , overduehandling) {
    this.$localStorage.store('quizName', name);
    this.$localStorage.store('overduehandling', overduehandling);
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

}
