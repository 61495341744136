import {Component, OnDestroy, OnInit} from '@angular/core';
import {USER_INFO, USER_ROLE} from '../../shared/constants/base.constant';
import {LocalStorageService} from 'ngx-webstorage';
import {RoleSystem} from '../../shared/model/role-system';
import {NotificationService} from '../../shared/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit, OnDestroy{
  index = 0;
  isShowNotifyGeneral = false;

  constructor(
    private notifycationService: NotificationService,
    private $localStorage: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.notifycationService.currentSubject.subscribe(data => {
      this.index = data;
    });
    this.getRoles();
  }

  ngOnDestroy(): void {
  }

  private getRoles() {
    const roles: RoleSystem[] = this.$localStorage.retrieve(USER_INFO.ROLES);
    const currentUserRole = roles[0].shortname;
    this.isShowNotifyGeneral = !(currentUserRole === USER_ROLE.MANAGER || currentUserRole === USER_ROLE.TEACHER);
  }
}
