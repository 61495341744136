<!-- ======= Header ======= -->
<header id="header">
  <div class="container d-flex align-items-center">
    <div class="logo float-left mr-auto">
      <h1 class="text-light">
        <a href="home">
          <img src="assets/end-user/img/logo_mobifone.png" alt="" />
        </a>
      </h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
    </div>

    <nav class="nav-menu float-right d-none d-lg-block">
      <ul>
        <li routerLinkActive="active"><a routerLink="/home">Trang chủ</a></li>
        <li routerLinkActive="active"><a routerLink="/my-course">Khóa học của tôi</a></li>
        <li routerLinkActive="active"><a routerLink="/course-register">Khóa học tự đăng ký</a></li>
        <li *ngIf="isTeacher" routerLinkActive="active"><a routerLink="/teacher-course">Khóa học giảng dạy</a></li>
        <li routerLinkActive="active"><a routerLink="/course-route">Lộ trình</a></li>
        <li routerLinkActive="active"><a routerLink="/exam-list">Kỳ thi</a></li>
        <li routerLinkActive="active"><a routerLink="/certificate-user">Chứng chỉ</a></li>
        <li>
          <form role="form" [formGroup]="searchForm"
            class="form-inline d-flex justify-content-center md-form form-sm active-cyan-2 mt-2">
            <input style="
                font-size: 10pt;
                color: #777777;
                padding-left: 10px;
                border: 1px solid #dadada;
              " class="form-control form-control-sm mr-2 w-75" type="text" placeholder="Tìm kiếm"
              aria-label="Tìm kiếm"
              #keyword
              (keydown.enter)="onSearch($event.target.value)"/>
            <i class="icofont-search-1 text-primary" aria-hidden="true" (click)="onSearch(keyword.value)"></i>
          </form>
        </li>
      </ul>
    </nav>
    <!-- .nav-menu -->
  </div>
</header>
<!-- End Header -->
