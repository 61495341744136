
<div class="modal-header">
  <h5 class="modal-title">{{titleModal}}</h5>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
  (click)="activeModal.dismiss()"><i class="fas fa-times"></i>
</button>
</div>
<div class="modal-body">
       <div class="row">
        <form>
          <div class="form-row">
            <div class="col-auto">
              <input type="text" class="form-control" name="search" [(ngModel)]="search" (change)="onChangeKeyword()" placeholder="Tìm kiếm khóa học">
            </div>
          </div>
        </form>
            <div class="col-12 mt-3 table-responsive overflow-auto" style="height: 60vh;">
                  <table class="table table-hover">
                    <thead>
                    <tr *ngIf="coursetype==1">
                      <th>
                        <mat-icon class="mt-1">
                          <mat-checkbox *ngIf="!isReportSreen" class="example-margin" [(ngModel)]="completedAll" (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                        </mat-icon>
                      </th>
                      <th>STT</th>
                      <th>Tên đầy đủ</th>
                      <th>Từ ngày</th>
                      <th>Đến ngày</th>
                      <th>Đơn vị chủ trì</th>
                      <th class="d-none">Chức danh</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of courses; let i = index;">
                        <td style="width:25px;" >
                          <mat-icon class="mt-1">
                            <mat-checkbox *ngIf="!isReportSreen" [(ngModel)]="item.completed" (change)="oncheckboxItem(item.id, $event.checked)" class="example-margin"></mat-checkbox>
                            <mat-radio-button *ngIf="isReportSreen" (change)="onRadiocheckboxItem(item.id)"></mat-radio-button>
                          </mat-icon>
                        </td>
                        <td style="width:30px;" >{{(pageIndex - 1) * pageSize + i + 1}}</td>
                        <td>{{item.fullname.length <= 40 ? item.fullname : (item.fullname | slice:0:40) + '...' }}</td>
                        <td>{{getDateFromUnix(item.startdate) | date:'dd/MM/yyyy'}}</td>
                        <td>{{getDateFromUnix(item.enddate) | date:'dd/MM/yyyy'}}</td>
                        <td>{{item.departmentname}}</td>
                        <td class="d-none">{{item.positionname}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <p *ngIf="courses?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
                </div>
        </div>
    </div>
    <div class="card-footer text-center">
      <b *ngIf="!isReportSreen">Số khóa học đã chọn: {{courseIdsChecked?.length}}</b>
      <div class="row">
        <mat-paginator showFirstLastButtons
            [length]="totalRecord"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex - 1 "
            (page)="changePage($event)"
            [hidePageSize]="false"
            [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </div>
    </div>
<div class="modal-footer">
    <button type="button" (click)="activeModal.dismiss()" class="btn btn-default round-0 mr-2"><i class="fas fa-close"></i>Hủy</button>
    <button type="button" (click)="save()" class="btn btn-primary" ><i class="fas fa-save mr-1"></i>Lựa Chọn</button>
</div>


