import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Component, Input, OnInit} from '@angular/core';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {Survey} from '../../../shared/model/survey';
import {SurveyService} from '../../../shared/services/survey.service';
import {QUESTION_TYPE} from '../../../shared/constants/base.constant';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-eu-course-survey-content',
  templateUrl: './eu-course-survey-content.component.html',
  styleUrls: ['./eu-course-survey-content.component.css']
})
export class EuCourseSurveyContentComponent implements OnInit {

  @Input() courseId: number;

  @Input() course: any;

  currentDate: Date = new Date();

  survey: Survey = {
    name: '',
    sectionid: null,
    introeditor: '',
    ismaster: null,
    published: null,
    publishedat: null,
    startdate: null,
    enddate: '',
    qtype: null,
    id: null,
    questionnaireid: null,
    intro: '',
    cmid: null,
    listquestion: [],
    course: null,
    isactive: null,
    refdepartmentid: null,
    activatedat: null,
    departmentid: null,
    selected: null,
    isteacher: null,
    sid: null
  };

  textQuestionType = QUESTION_TYPE.TEXT;
  radioQuestionType = QUESTION_TYPE.RADIO;
  rateQuestionType = QUESTION_TYPE.RATE;
  isSendSurvey = false;
  noSurvey = true;
  hasResponse = false;

  questionNaireIdForCourse: number;

  isHasQuestionTeacher: any;
  isHasQuestionCourse: any;

  constructor(
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private surveyService: SurveyService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.surveyService.getEnduserSurveyOfCourse(this.courseId).subscribe(res => {
      const questionNaireIdForCourse = res.body.questionnaireid;
      this.hasResponse = res.body.hasresponse;
      if (questionNaireIdForCourse) {
        this.noSurvey = false;
        if (!this.hasResponse) {
        this.questionNaireIdForCourse = questionNaireIdForCourse;
        this.getInforSurvey(questionNaireIdForCourse);
        }
      } else {
        this.noSurvey = true;
      }
    }, error => {
      this.toastrService.handlerError(error);
      this.noSurvey = true;
    });
  }

  getInforSurvey(idSurvey: any) {
    this.surveyService.attemptSurvey(idSurvey).subscribe(response => {
      this.survey = response.body;
      this.survey.listquestion.forEach(
        (question) => {
          if (question.type === this.rateQuestionType){
            question.extradata = JSON.parse(question.extradata);
          }
        }
      );
      this.isHasQuestionCourse = this.survey.listquestion.filter(q => q.isteacher === '0').length > 0;
      this.isHasQuestionTeacher = this.survey.listquestion.filter(q => q.isteacher === '1').length > 0;
    });
  }

  sendAnswer() {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('survey.answer_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('survey.answer_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.answer');

    modalDep.result.then(() => {


      const answer = [];

      if (this.survey.listquestion) {

        for (const question of this.survey.listquestion) {

          if (question.type === this.textQuestionType) {
            if (question.answer) {
              answer.push('q' + question.id + ':' + question.answer);
            }
          }

          if (question.type === this.radioQuestionType) {
            if (question.answer) {
              answer.push('q' + question.id + ':' + question.answer);
            }
          }


          if (question.type === this.rateQuestionType) {
            for (const choice of question.choices) {
              if (choice.answer) {
                answer.push('q' + question.id + '_' + choice.id + ':' + choice.answer);
              }
            }
          }
        }
      }


      this.spinner.show();
      this.surveyService.answerQuestionSurvey(this.survey.cmid, this.questionNaireIdForCourse, answer).subscribe(() => {
        this.toastrService.success('survey.push_answer_success');
        this.isSendSurvey = true;
        this.noSurvey = false;
        this.hasResponse = false;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });

    });
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  arrayStartFrom(n: number, startFrom: number): number[] {
    return [...Array(n).keys()].map(i => i + startFrom);
  }

  checkRateQuestion(question, value){
    question.answer = value;
  }


}
