<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <mat-drawer-container autosize class="example-container">
          <mat-drawer #drawer class="example-sidenav" mode="side" opened="true" *ngIf="checkRoleFunction('ASSIGN_TEACHER_TO_COURSE',role) === true && !courseDetail.readonly">
            <div class="card card-default">
              <div class="card-body">
                <div class="row">
                    <div class="col-12">

                      <!-- /.card-header -->
                      <div>
                        <div class="row mb-2 desc">
                          <!--Search -->
                          <div class="col-7 mt-2 mb-3 pr-2">
                            <input type="search" class="form-control form-control-sm pr-2" (change)="onSearchTeacherOutCourse($event.target.value)" placeholder="Tìm kiếm giảng viên">
                          </div>
                          <div class="col-5 mt-2 mb-3" *ngIf="!courseDetail.readonly">
                            <button type="button" data-toggle="modal"class="btn btn-primary rounded-0 float-right"
                              (click)="assignTeacher()" [disabled]="teacherIdsAssign?.length === 0 || courseDetail.published === 1">
                              <i class="fas fa-plus mr-1"></i>Lựa chọn</button>
                          </div>
                          <!--Phần tree-->
                        </div>
                        <!-- <form [formGroup]="form"  novalidate> -->
                        <div class="overflow-hidden" *ngFor="let teacherAdd of listTeacherAdd">

                          <div class="row" #draggable>
                            <div class="col-1">
                              <mat-icon matListAvatar class="mt-1" *ngIf="!courseDetail.readonly">
                                <mat-checkbox class="example-margin"
                                [(ngModel)]="teacherAdd.checked" [disabled]="courseDetail.published === 1" (change)="addTeacherIds(teacherAdd.id, $event.checked)"></mat-checkbox>
                                  <!-- <input type="checkbox" [value]="teacherAdd.id" (change)="checkCheckBoxvalue($event,teacherAdd.id)" /> -->
                              </mat-icon>
                            </div>
                            <div class="col-11">
                              <div class="d-flex w-100 justify-content-between">
                                <h6 class="mb-1">{{teacherAdd.firstname +' '+teacherAdd.lastname}}</h6>
                              </div>
                              <!-- <p class="mb-0 text-primary">Giảng viên BA</p> -->
                              <p class="mb-0">{{teacherAdd.email}}</p>
                              <small>{{teacherAdd.phone1}}</small>
                            </div>
                          </div>
                          <hr>
                        </div>
                        <p *ngIf="listTeacherAdd?.length === 0">{{ 'common.no_search_result' | translate}}</p>
                        <!-- </form> -->
                        <!--end-->
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </mat-drawer>
          <div class="example-sidenav-content">
            <div class="col-12">
              <div class="card card-default">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8" *ngIf="!courseDetail.readonly">
                      <div class="btn-group">
                        <button type="button" *ngIf="checkRoleFunction('UNASSIGN_TEACHER_TO_COURSE',role) === true" matTooltip="Loại bỏ" matTooltipClass="tooltip-red" (click)="unAssignTeacher(teacherIds)" [disabled]="courseDetail.published === 1 || teacherIdsChecked.length === 0"
                          class="btn btn-default btn-md btn-flat"><i class="fas fa-trash"></i></button>
                          <span> Số giảng viên đã chọn: {{teacherIdsChecked?.length}}</span>
                      </div>
                    </div>
                    <!--Search -->
                    <div class="col-4 pr-4">
                      <input type="search" (change)="onSearchTeacherOnCourse($event.target.value)" class="form-control form-control-sm" placeholder="Tìm kiếm giảng viên">
                    </div>
                  </div>
                  <div class="another-test" #div></div>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th *ngIf="checkRoleFunction('UNASSIGN_TEACHER_TO_COURSE',role) === true && !courseDetail.readonly">
                          <mat-icon class="mt-1">
                            <mat-checkbox class="example-margin" [disabled]="courseDetail.published === 1" [(ngModel)]="completedAll" (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                          </mat-icon>
                        </th>
                        <th>STT</th>
                        <th>Họ tên</th>
                        <th>Thông tin giảng viên</th>
                        <th>Email</th>
                        <th>SDT</th>
                        <th *ngIf="checkRoleFunction('UNASSIGN_TEACHER_TO_COURSE',role) === true && !courseDetail.readonly">Thao tác</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let teacher of listTeacherOfCourse ;let i=index">
                      <tr>
                        <td *ngIf="checkRoleFunction('UNASSIGN_TEACHER_TO_COURSE',role) === true && !courseDetail.readonly">
                          <mat-icon class="mt-1">
                            <mat-checkbox [(ngModel)]="teacher.completed" [disabled]="courseDetail.published === 1" (change)="oncheckboxItem(teacher.id, $event.checked)" class="example-margin"></mat-checkbox>
                          </mat-icon>
                        </td>
                        <td width="2%">{{i + 1 + (searchTeacher.pageIndex - 1) * searchTeacher.pageSize}}</td>
                        <td width="22%">{{teacher.firstname +' '+teacher.lastname}}</td>
                        <td width="28%">{{teacher.description}}</td>
                        <td width="18%">{{teacher.email}}</td>
                        <td width="18%">{{teacher.phone1}}</td>
                        <td class="project-actions" width="10%" *ngIf="!courseDetail.readonly">
                          <button type="button" [disabled]="courseDetail.published === 1" *ngIf="checkRoleFunction('UNASSIGN_TEACHER_TO_COURSE',role) === true" matTooltip="Xóa" matTooltipClass="tooltip-red" (click)="unAssignTeacher([teacher.id])"  class="btn btn-default btn-sm mr-1"><i class="fas fa-trash"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <p *ngIf="listTeacherOfCourse?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
                </div>
                <div class="card-footer text-center">
                  <div class="row">
                    <mat-paginator showFirstLastButtons
                        [length]="totalRecord"
                        [pageSize]="searchTeacher.pageSize"
                        [pageIndex]="searchTeacher.pageIndex - 1 "
                        (page)="changePage($event)"
                        [hidePageSize]="false"
                        [pageSizeOptions]="pageSizeOptions">
                    </mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-drawer-container>
      </div>
    </div>
  </div>
</section>
