import {Component, OnInit} from '@angular/core';
import {Survey} from '../../shared/model/survey';
import {FormBuilder} from '@angular/forms';
import {LocalStorageService} from 'ngx-webstorage';
import {SurveyService} from '../../shared/services/survey.service';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {ConfirmModalComponent} from '../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {QUESTION_TYPE} from '../../shared/constants/base.constant';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.css']
})
export class SurveyQuestionComponent implements OnInit {
  textQuestionType = QUESTION_TYPE.TEXT;
  radioQuestionType = QUESTION_TYPE.RADIO;
  rateQuestionType = QUESTION_TYPE.RATE;
  isHasReponse :boolean = false;
  isHasNotStarted :boolean = false;
  isHasEnded :boolean = false;
  notPermission :boolean = false;
  survey: Survey = {
    name: '',
    sectionid: null,
    introeditor: '',
    ismaster: null,
    published: null,
    publishedat: null,
    startdate: null,
    enddate: '',
    qtype: null,
    id: null,
    questionnaireid: null,
    intro: '',
    cmid: null,
    listquestion: null,
    course: null,
    isactive: null,
    refdepartmentid: null,
    activatedat: null,
    departmentid: null,
    selected: null,
    isteacher: null,
    sid: null
  };

  constructor(
    private fb: FormBuilder,
    private $localStorage: LocalStorageService,
    private surveyService: SurveyService,
    private toastrService: ToastrCustomService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    if (+this.route.snapshot.paramMap.get('id') !== null && +this.route.snapshot.paramMap.get('id') !== undefined) {
      if (+this.route.snapshot.paramMap.get('id') > 0) {
        this.getInforSurvey(+this.route.snapshot.paramMap.get('id'));
      }
    }
  }

   // question naire id
  getInforSurvey(idQuestionnaire: any) {
    this.spinner.show();
    this.surveyService.attemptSurvey(idQuestionnaire).subscribe(response => {
        this.survey = response.body;
        this.survey.listquestion.forEach(
          (question) => {
            if (question.type === this.rateQuestionType){
              question.extradata = JSON.parse(question.extradata);
            }
          }
        );
        this.survey.sid = idQuestionnaire;
        this.spinner.hide();
    },
    err => {
      switch (err.error.errorcode) {
        case 'notpermission':
          this.notPermission = true;
          break;
        case 'surveyhasresponse':
          this.isHasReponse = true;
          break;
        case 'surveyhasnotstarted':
          this.isHasNotStarted = true;
          break;
        case 'surveyhasended':
          this.isHasEnded = true;
          break;
        default:
          break;
      }

      this.spinner.hide();
      // debugger
    });
  }


  sendAnswer(){
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('survey.answer_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('survey.answer_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.answer');

    modalDep.result.then((result) => {
      const answer = [];

      if (this.survey.listquestion) {

        for (const question of this.survey.listquestion) {

          if (question.type === this.textQuestionType) {
            if (question.answer) {
              answer.push('q' + question.id + ':' + question.answer);
            }
          }

          if (question.type === this.radioQuestionType) {
            if (question.answer) {
              answer.push('q' + question.id + ':' + question.answer);
            }
          }


          if (question.type === this.rateQuestionType) {
            for (const choice of question.choices) {
              if (choice.answer) {
                answer.push('q' + question.id + '_' + choice.id + ':' + choice.answer);
              }
            }
          }
        }
      }
      this.spinner.show();
      this.surveyService.answerQuestionSurvey( this.survey.cmid, this.survey.sid, answer).subscribe( res => {
        // console.log(res.body);
        this.toastrService.success('survey.push_answer_success');
        this.isHasReponse = true;
        this.spinner.hide();

      }, error => {
        this.toastrService.handlerError(error);
        this.spinner.hide();
      });
    });
  }

  arrayStartFrom(n: number, startFrom: number): number[] {
    return [...Array(n).keys()].map(i => i + startFrom);
  }

  checkRateQuestion(question, value){
    question.answer = value;
  }

}
