<div class="modal-header">
    <h5 class="modal-title">{{studentName}}</h5>
    <button type="button" class="btn btn-tool" data-card-widget="collapse" (click)="activeModal.dismiss()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-lg-12 col-md-auto pb-2">
            <div class="overflow mt-3">
                <ng-container *ngFor="let question of listQuestion;let i = index;">
                    <div class="card card-default rounded " [id]="question.slot">
                        <div class="card-body p-3">
                            <div class="row form-inline">
                                <div class="col-auto">
                                    <div class="dot">{{i + 1}}</div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-lg-12 col-md-6 label-text text-justify"><span [innerHTML]="question.questiontext"></span>
                                    <iframe *ngIf="question.linkVideo" width="420" height="315" [src]="domSanitizer2.bypassSecurityTrustResourceUrl(question.linkVideo)"></iframe>
                                </div>
                                <div class="col-lg-2 col-md-6"></div>
                            </div>
                            <div class="row pl-2 mt-3">
                                <div class="col-12">
                                    <div class="form-check bg-light" *ngFor="let answer of question.answers; let i = index;">
                                        <!-- câu hỏi nhiều lựa chọn -->
                                        <ng-container *ngIf="question.typeCheckBox=='checkbox'">
                                            <mat-icon aria-hidden="false" *ngIf="answer.rightanswer > 0" class="color_green" aria-label="Example home icon">done</mat-icon>
                                            <mat-icon aria-hidden="false" *ngIf="answer.rightanswer == 0"></mat-icon>
                                            <mat-checkbox [checked]="answer.checked" class="example-margin" name="check" [disabled]="true">
                                                <div class="row">
                                                    <div class="col-auto" *ngIf="question.answernumbertype">{{question.answernumbertype[i]}}.</div>
                                                    <div class="col-auto">
                                                        <span [innerHTML]="answer.text | translate"></span>
                                                    </div>
                                                </div>
                                            </mat-checkbox>
                                        </ng-container>

                                        <!-- câu hỏi một lựa chọn/ đúng sai -->
                                        <ng-container *ngIf="question.typeCheckBox=='radio'">
                                            <mat-icon aria-hidden="false" *ngIf="answer.rightanswer > 0" class="color_green" aria-label="Example home icon">done</mat-icon>
                                            <mat-icon aria-hidden="false" *ngIf="answer.rightanswer == 0"></mat-icon>
                                            <mat-radio-button [checked]="answer.checked" class="example-margin" [name]="question.slot" [disabled]="true">
                                                <div class="row">
                                                    <div class="col-auto" *ngIf="question.answernumbertype">{{question.answernumbertype[i]}}.</div>
                                                    <div class="col-auto">
                                                        <span [innerHTML]="answer.text | translate"></span>
                                                    </div>
                                                </div>
                                            </mat-radio-button>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" (click)="activeModal.dismiss()" class="btn btn-default round-0 mr-2"><i class="fas fa-close"></i>Đóng</button>
</div>
<ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
  <p class="loading">{{ 'common.loading' | translate }}</p>
</ngx-spinner>
