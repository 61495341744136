import {CourseService} from '../../../shared/services/course.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy } from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import {QuizContentService} from '../../../shared/services/quiz-content.service';
import {TopicModel} from '../../../shared/model/topic.model';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {ShowContentService} from '../../../shared/services/show-content.service';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {TopicService} from '../../../shared/services/topic.service';

@Component({
  selector: 'app-quiz-content',
  templateUrl: './quiz-content.component.html',
  styleUrls: ['./quiz-content.component.css']
})
export class QuizContentComponent implements OnInit, OnChanges, OnDestroy {

  // MLMS 668
  @Input() courseDetail;
  @Output() checkCreate = new EventEmitter();
  @Output() showCreateQuizz = new EventEmitter();
  @Output() quizEdit = new EventEmitter();
  public Editor = ClassicEditor;
  quizactivity = '';
  currentDate: Date = new Date();
  happening: string;
  date: any;
  topicContent: TopicModel;
  modules: ModuleInfo[];
  quiz: ModuleInfo;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  searchQuiz: string;

  constructor(
    public quizContentService: QuizContentService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private topicService: TopicService,
    private courseService: CourseService,
    private toastrService: ToastrCustomService,
    private showService: ShowContentService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {}


  ngOnInit(): void {
    this.getListQuiz();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  getListQuiz(keyword?: string) {
    this.spinner.show();
    this.quizContentService.listQuiz(this.courseDetail.id, 0, keyword).subscribe((data) => {
      this.topicContent = data.body;
      this.modules = this.topicContent.modules;
      if (this.modules) {
        this.totalRecord = this.modules.length;
      }
      this.modules?.forEach(element => {
        if (this.currentDate > moment.unix(element.timeclose).toDate()) {
          element.status = 'Đã kết thúc';
          element.happening = 'no';
        }
        if (this.currentDate < moment.unix(element.timeopen).toDate()) {
          element.status = 'Chưa bắt đầu';
          element.happening = 'no';
        }
        if (moment.unix(element.timeopen).toDate() <= this.currentDate && this.currentDate <= moment.unix(element.timeclose).toDate()) {
          element.status = 'Đang diễn ra';
          element.happening = 'yes';
        }
        if (element.timeclose === 0 && element.timeopen === 0) {
          element.happening = 'yes';
        }
        if (!this.courseDetail.published) {
          element.status = 'Chưa hiển thị';
          element.happening = 'no';
        }
      });
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  getModuleDetail(id) {
    this.spinner.show('quizcontent');
    this.showService.getFilebyId(id).subscribe((data) => {
      this.spinner.hide('quizcontent');
      this.quiz = data.body;
      this.quiz.grade = Math.ceil(this.quiz.grade);
      this.quizEdit.emit(this.quiz);
      this.showCreateQuizz.emit(true);
      this.checkCreate.emit(false);
    }, error => {
      this.spinner.hide('quizcontent');
      this.toastrService.handlerError(error);
    });
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  ngOnChanges(): void {
    this.getListQuiz();
  }

  delete(id) {
    const modalModule = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    const cmids: number [] = [];
    cmids.push(id);

    modalModule.componentInstance.title = this.translateService.instant('quiz.delete_confirm_title');
    modalModule.componentInstance.body = this.translateService.instant('quiz.delete_confirm_content');
    modalModule.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalModule.result.then((result) => {
      this.spinner.show();
      this.topicService.deleteModule(cmids).subscribe(
        (res) => {
          this.toastrService.success(`common.noti.delete_success`);
          this.getListQuiz();
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          this.toastrService.handlerError(err);
        });
    });
  }

  onCreateQuiz() {
    if (this.courseDetail.published === 1) {
      this.checkCoursePublished();
      return;
    }
    this.showCreateQuizz.emit(true);
    this.checkCreate.emit(true);
  }

  onSearchQuiz(search: string) {
    this.searchQuiz = search;
    this.getListQuiz(search);
  }

  checkCoursePublished() {
    if (this.courseDetail.published === 1) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalDep.componentInstance.title = this.translateService.instant('common.caution');
      modalDep.componentInstance.body = this.translateService.instant('common.noti.required_un_publish_course');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.hide');
      modalDep.result.then((result) => {
        if (result === 'confirm') {
          this.spinner.show();
          this.courseService.unPublishCourse(+this.route.snapshot.paramMap.get('id')).subscribe(
            res => {
              this.toastrService.success(`common.noti.un_publish_success`);
              this.courseDetail.published = 0;
              this.spinner.hide();
            },
            err => {
              this.spinner.hide();
              this.toastrService.handlerError(err);
            }
          );
        }
      });
    }
  }


}
