import {AbstractControl, FormGroup} from '@angular/forms';
import {MODULE_TYPE, ROLE_MENU, STATUS_BADGES} from '../constants/base.constant';
import {CourseCategory} from '../model/course-category.model';
import {MatPaginatorIntl} from '@angular/material/paginator';

/**
 * Common Util class
 */

export default class CommonUtil {

  // Chi can goi chung ham nay la duoc
  static appendDataToFormData(formdata: FormData, fieldName: string, data: any) {
    if (data !== null && data !== undefined) {
      if (Array.isArray(data)) {
        if (data.length > 0) {
          CommonUtil.appendArrayToFormData(formdata, fieldName, data);
        }
      } else if (typeof data === 'object') {
        CommonUtil.appendObjectToFormData(formdata, fieldName, data);
        // Chi lay cac field co gia tri
      } else if (typeof data === 'string') {
        if (data.trim().length > 0) {
          formdata.append(fieldName, data.trim());
        }
      } else {
        formdata.append(fieldName, data);
      }
    }
  }

  static appendObjectToFormData(formdata: FormData, fieldName: string, data: any) {
    Object.keys(data).map((key) => {
      CommonUtil.appendDataToFormData(formdata, fieldName + '[' + key + ']', data[key]);
    });
  }

  static appendArrayToFormData(formdata: FormData, fieldName: string, array: any) {
    array.forEach((value, i) => {
      if (value !== null && value !== undefined) {
        if (typeof value === 'object') {
          CommonUtil.appendSingleArrayObjectToFormData(formdata, fieldName, value, i);
          // Chi lay cac field co gia tri
        } else if (typeof value === 'string') {
          if (value.trim().length > 0) {
            formdata.append(fieldName + '[' + i + ']', value.trim());
          }
        } else {
          formdata.append(fieldName + '[' + i + ']', value);
        }
      }
    });
  }

  static appendSingleArrayObjectToFormData(formdata: FormData, fieldName: string, data: any, index) {
    Object.keys(data).map((key) => {
      const value = data[key];
      if (value !== null && value !== undefined) {
        if (typeof value === 'object') {
          CommonUtil.appendObjectToFormData(formdata, fieldName + '[' + index + '][' + key + ']', value);
          // Chi lay cac field co gia tri
        } else if (typeof value === 'string') {
          if (value.trim().length > 0) {
            formdata.append(fieldName + '[' + index + '][' + key + ']', value.trim());
          }
        } else {
          formdata.append(fieldName + '[' + index + '][' + key + ']', value);
        }
      }

    });
  }

  // pass raw data, khong trim() truoc khi truyen cho backend
  static appendRawDataToFormData(formdata: FormData, fieldName: string, data: any) {
    if (data !== null && data !== undefined) {
      if (Array.isArray(data)) {
        if (data.length > 0) {
          CommonUtil.appendRawArrayToFormData(formdata, fieldName, data);
        }
      } else if (typeof data === 'object') {
        CommonUtil.appendRawObjectToFormData(formdata, fieldName, data);
        // Chi lay cac field co gia tri
      } else if (typeof data === 'string') {
        if (data.trim().length > 0) {
          formdata.append(fieldName, data);
        }
      } else {
        formdata.append(fieldName, data);
      }
    }
  }

  static appendRawObjectToFormData(formdata: FormData, fieldName: string, data: any) {
    Object.keys(data).map((key) => {
      CommonUtil.appendRawDataToFormData(formdata, fieldName + '[' + key + ']', data[key]);
    });
  }

  static appendRawArrayToFormData(formdata: FormData, fieldName: string, array: any) {
    array.forEach((value, i) => {
      if (value !== null && value !== undefined) {
        if (typeof value === 'object') {
          CommonUtil.appendRawSingleArrayObjectToFormData(formdata, fieldName, value, i);
          // Chi lay cac field co gia tri
        } else if (typeof value === 'string') {
          if (value.trim().length > 0) {
            formdata.append(fieldName + '[' + i + ']', value);
          }
        } else {
          formdata.append(fieldName + '[' + i + ']', value);
        }
      }
    });
  }

  static appendRawSingleArrayObjectToFormData(formdata: FormData, fieldName: string, data: any, index) {
    Object.keys(data).map((key) => {
      const value = data[key];
      if (value !== null && value !== undefined) {
        if (typeof value === 'object') {
          CommonUtil.appendRawObjectToFormData(formdata, fieldName + '[' + index + '][' + key + ']', value);
          // Chi lay cac field co gia tri
        } else if (typeof value === 'string') {
          if (value.trim().length > 0) {
            formdata.append(fieldName + '[' + index + '][' + key + ']', value);
          }
        } else {
          formdata.append(fieldName + '[' + index + '][' + key + ']', value);
        }
      }
    });
  }

  static markFormGroupTouched(form: FormGroup) {
    Object.values(form.controls).forEach(control => {
      control.markAsTouched();

      if ((control as any).controls) {
        this.markFormGroupTouched(control as FormGroup);
      }
    });
  }

  static convertCategoryListToMap(categoryList: CourseCategory[], categoryMap) {
    categoryList.forEach(category => {
      if (category.parent !== null) {
        const parentName = categoryMap.get(category.parent);
        let name = '';
        if (parentName) {
          name = parentName + ' / ' + category.name;
        } else {
          name = category.name;
        }
        categoryMap.set(category.id, name);
      } else {
        categoryMap.set(category.id, category.name);
      }
    });
  }

  static convertTeacherName(teachers: any[]) {
    let teacherName = '';
    if (!teachers || teachers.length === 0) {
      return 'Đang cập nhật...';
    } else if (teachers.length === 1) {
      return teacherName = teachers[0].firstname + ' ' + teachers[0].lastname;
    } else {
      teachers.forEach(teacher => {
        teacherName += teacher.firstname + ' ' + teacher.lastname;
        if (teachers.indexOf(teacher) !== (teachers.length - 1)) {
          teacherName += ', ';
        }
      });
      return teacherName;
    }
  }

  static limitWord(stringRaw: string, numberLimit: number) {
    let stringLimit = '';
    if (stringRaw) {
      if (stringRaw.length <= numberLimit) {
        stringLimit = stringRaw;
      } else {
        stringLimit = stringRaw.slice(0, numberLimit) + '...';
      }
    }
    return stringLimit;
  }

  static convertModType(modType: string, detailsType?: string) {
    const mod = MODULE_TYPE.find(module => module.type === modType);
    if (mod.subType.length > 0) {
      const modName = mod.subType.find(sub => sub.mineType.includes(detailsType));
      if (modName !== undefined) {
        return modName.name;
      }
      return 'common.undefine';
    }
    return mod.name;
  }

  static getStatusBadge(status: number) {
    const statusBadge = STATUS_BADGES.find(s => s.statusId === status);
    return statusBadge;
  }

  static filterMenu(listmenu: any, role) {
    return listmenu.filter(m => ROLE_MENU[role].includes(m.code));
  }

  static linkVideoCkeditorToLinkEmbedYoutube(element: any) {
    if (element !== null && element !== undefined) {
      let videoLink = null;
      videoLink = element.slice(element.indexOf('<oembed url="') + 13,
        element.indexOf('"></oembed>')).replace('&amp;', '?').replace('&amp;', '?').replace('watch?v=', 'embed/');
      if (videoLink.indexOf('embed/') === -1) {
        videoLink = this.insertStr(videoLink, videoLink.indexOf('youtu.be/') + 'youtu.be/'.length, 'embed/').replace('//youtu.be/', '//www.youtube.com/');
      }
      return videoLink;
    }
  }

  static insertStr(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
  }


  static convertDateToTime(date: Date, hours: number, minutes: number) {
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.getTime() / 1000;
  }


  static overridePaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = 'Hiển thị:';
    return paginatorIntl;
  }

  static encodeURI(value) {
    let returnStr = encodeURI(value);
    returnStr = returnStr.replace('(', '%28');
    returnStr = returnStr.replace(')', '%29');

    return returnStr;
  }
}
