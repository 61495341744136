<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Danh sách người dùng</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý người dùng</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <!-- code develop-->
        <mat-drawer-container autosize class="example-container">
          <mat-drawer #drawer class="example-sidenav" mode="side" opened>
            <div class="card card-default">
              <div class="card-body">
                <h6>Đơn vị</h6>
                <hr>
                <ejs-treeview #departmentTree
                              [fields]="departmentTreeData"
                              (keyPress)='nodeCheck($event)'
                              (nodeClicked)='nodeCheck($event)'
                              (nodeSelected)="nodeDepartmentSelected($event)"
                              (nodeChecked)='nodeDepartmentChecked($event)'
                              [showCheckBox]='true'>
                </ejs-treeview>
              </div>
              <!-- code develop-->
            </div>
          </mat-drawer>
          <div class="example-sidenav-content">
            <div class="card card-default">
              <div class="card-body">
                <h6>Danh sách người dùng</h6>
                <hr>
                <div class="row mb-3">
                  <div class="col-3 mt-2">
                    <button type="button" class="btn btn-default btn-lg mr-1" matTooltip="Tạo mới"
                            matTooltipClass="tooltip-red" (click)="openCreateOrEditPopup(currentUser)"><i
                      class="fas fa-plus mr-1"></i></button>
                    <button type="button" data-toggle="modal" matTooltip="Nhập từ File Excel"
                            (click)="openImportFile()"
                            matTooltipClass="tooltip-red" class="btn btn-default btn-lg mr-1">
                      <i class="fas fa-file-excel mr-1"> </i>
                    </button>
                    <button type="button" data-toggle="modal" class="btn btn-default btn-lg mr-1"
                            [disabled]="userIdsChecked?.length === 0" (click)="onDeleteUser(userIdsChecked)"
                            matTooltip="Xóa" matTooltipClass="tooltip-red">
                      <i class="fas fa-trash mr-1"></i>
                    </button>
                    <button type="button" data-toggle="modal" class="btn btn-default btn-lg mr-1"
                            [disabled]="userIdsChecked?.length === 0" (click)="onLockMultiUser()"
                            matTooltip="Khóa" matTooltipClass="tooltip-red">
                      <i class="fa fa-lock mr-1"></i>
                    </button>
                    <button type="button" data-toggle="modal" class="btn btn-default btn-lg mr-1"
                            [disabled]="userIdsChecked?.length === 0" (click)="onUnlockMultiUser()"
                            matTooltip="Mở khóa" matTooltipClass="tooltip-red">
                      <i class="fa fa-unlock mr-1"></i>
                    </button>
                    <br>
                    <span>Số người dùng đã chọn: {{userIdsChecked?.length}}</span>
                  </div>
                  <!--Search -->
                  <div class="col-9 mt-2 ">
                    <div class="row">
                      <div class="col-4">
                        <ng-multiselect-dropdown
                          [placeholder]="'Lọc theo chức danh'"
                          [settings]="dropdownPositionSettings"
                          [data]="positionList"
                          [(ngModel)]="selectedPositions"
                          (onSelect)="onSelectPositionEvent()"
                          (onSelectAll)="onSelectPositionEvent()"
                          (onDeSelect)="onSelectPositionEvent()"
                          (onDeSelectAll)="onSelectPositionEvent()"
                          (onDropDownClose)="changePosition()">
                        </ng-multiselect-dropdown>
                      </div>
                      <div class="col-4">
                        <ng-multiselect-dropdown
                          [placeholder]="'Lọc theo vai trò'"
                          [settings]="dropdownRoleSettings"
                          [data]="showFullRoles ? roleList : roleListRemoveRoleManager"
                          [(ngModel)]="selectedRole"
                          (onSelect)="onSelectRoleEvent()"
                          (onSelectAll)="onSelectRoleEvent()"
                          (onDeSelect)="onSelectRoleEvent()"
                          (onDeSelectAll)="onSelectRoleEvent()"
                          (onDropDownClose)="changeRole()">
                        </ng-multiselect-dropdown>
                      </div>
                      <div class="col-4">
                        <input type="search" class="form-control form-control-sm ml-1"
                               (change)="searchUserUsingKeyUp($event.target.value)" placeholder="Mã HV, Tên đăng nhập, SĐT">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-default">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-hover" matSort (matSortChange)="sortData($event)">
                    <thead>
                    <tr>
                      <th>
                        <mat-icon class="custom-m-top">
                          <mat-checkbox class="example-margin"
                                        [(ngModel)]="checkBoxAllUserDelete"
                                        (change)="checkAllUser($event.checked)">
                          </mat-checkbox>
                        </mat-icon>
                      </th>
                      <th>{{'user.stt' | translate}}</th>
                      <th mat-sort-header="fullname">{{'user.full_name' | translate}} </th>
                      <th mat-sort-header="username">{{'user.user_name' | translate}} </th>
                      <th mat-sort-header="positionnames">{{'user.position' | translate}}</th>
                      <th mat-sort-header="departmentname">{{'user.department' | translate}}</th>
                      <th mat-sort-header="phone1">{{'user.phone_number' | translate}}</th>
                      <th mat-sort-header="roleshortname">{{'user.role' | translate}}</th>
                      <th mat-sort-header="suspended">{{'user.status' | translate}}</th>
                      <th>{{'common.action' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let user of userInfors; let i = index;">
                      <td>
                        <ng-template [ngIf]="user.id != 1">
                          <mat-icon class="mt-1">
                            <mat-checkbox class="example-margin"
                                          [(ngModel)]="user.selected"
                                          (change)="checkSelection(user.id, $event.checked)">
                            </mat-checkbox>
                          </mat-icon>
                        </ng-template>
                      </td>
                      <td>{{(pageIndex - 1) * pageSize + i + 1}}</td>
                      <td><span matTooltip="{{user.fullname}}"
                                matTooltipClass="tooltip-inner">{{user.fullname?.length <= 10 ? user.fullname : (user.fullname | slice:0:20) + '...' }}</span>
                      </td>
                      <td>{{user.username}}</td>
                      <td><span matTooltip="{{getPositionNames(user)}}"
                                matTooltipClass="tooltip-inner">{{getPositionNames(user)?.length <= 30 ? getPositionNames(user) : (getPositionNames(user) | slice:0:20) + '...' }}</span>
                      </td>
                      <td><span matTooltip="{{user.departmentname}}"
                                matTooltipClass="tooltip-inner">{{user.departmentname?.length <= 30 ? user.departmentname : (user.departmentname | slice:0:20) + '...' }}</span>
                      </td>
                      <td>{{user.phone1}}</td>
                      <td>{{getShortName(user.roleshortname)}}</td>
                      <td>
                        <mat-slide-toggle (click)="onLockSingleUser($event, user)" [checked]="!user.suspended"></mat-slide-toggle>
                      </td>
                      <td style="width:150px;">
                        <button [disabled]="user.auth === 'ldap'"
                          type="button" matTooltip="Đổi mật khẩu" matTooltipClass="tooltip-red"
                                (click)="openCreateOrEditPopup(user, true)" class="btn btn-default btn-sm mr-1"><i
                          class="fas fa-key"></i></button>
                        <button type="button" matTooltip="Chỉnh sửa" matTooltipClass="tooltip-red"
                                (click)="openCreateOrEditPopup(user, false)" class="btn btn-default btn-sm mr-1"><i
                          class="fas fa-edit"></i></button>
                        <button type="button" matTooltip="Xóa" matTooltipClass="tooltip-red"
                                (click)="onDeleteUser([user.id])" class="btn btn-default btn-sm mr-1"><i
                          class="fas fa-trash"></i></button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <br />
                  <p *ngIf="userInfors?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
                </div>
                <div class="card-footer text-center" *ngIf="totalRecord > 0">
                  <div class="row">
                    <mat-paginator showFirstLastButtons
                                   #paginator
                                   [length]="totalRecord"
                                   [pageIndex]="pageIndex-1"
                                   [pageSize]="pageSize"
                                   (page)="changePage($event)"
                                   [hidePageSize]="false"
                                   [pageSizeOptions]="pageSizeOptions">
                    </mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-drawer-container>
      </div>
    </div>
  </section>
</div>
