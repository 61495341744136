<div class="row d-block">
  <h5>{{courseModule.name}}</h5>
  <small class="text-dark">Giảng viên: {{teachers?.length > 100 ? (teachers | slice:0:100) : teachers}}</small>
</div>
<br>
<div [innerHTML]="courseModule.intro"></div>
<div *ngIf="courseModule.mimetype=='application/pdf'">
  <a style="padding-left: 2rem" [href]="urlSafe" target="_blank">
    {{courseModule.filename}}
  </a> <br/> <br/>

  <a style="padding-left: 2rem">
    <iframe [src]="urlSafe"
            width="900" height="600"></iframe>
  </a>
</div>
<div *ngIf="courseModule?.mimetype=='video/quicktime' || courseModule?.mimetype=='video/mp4'" class="col-12">
  <video *ngIf="urlSafe" #videoCourse width="100%" autoplay="autoplay" controls>
    <source [src]="urlSafe" type="video/mp4">
    Trình duyệt bạn không hỗ trợ Video - Vui lòng kiểm tra lại !
  </video>
</div>
<div *ngIf="courseModule?.mimetype!=='video/quicktime' && courseModule?.mimetype!=='video/mp4' && courseModule.mimetype!=='application/pdf'" class="col-12">
  <div class="row mt-2 p-4  bg-light rounded">
    <div class="col-6 form-inline"><p class="mt-3"><i class="fas fa-file-archive font-size-20 mr-2"></i>{{courseModule.filename}}</p></div>
    <div class="col-6 mt-3">
      <p class=" float-right ml-3"><a [href]="urlSafe"><i class="fas fa-file-download mr-2 font-size-20"></i>Tải xuống</a></p>
    </div>
  </div>
</div>
