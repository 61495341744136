import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { QuizContentService } from 'src/app/shared/services/quiz-content.service';
import { CourseService } from 'src/app/shared/services/course.service';
import { Course } from 'src/app/shared/model/course.model';
import { ModuleInfo } from 'src/app/shared/model/moduleinfo.model';
import { ReportService } from '../../../../shared/services/report.service';
import { ResultExamUserComponent } from '../result-exam-user/result-exam-user.component';

@Component({
  selector: 'app-result-detail-exam',
  templateUrl: './result-detail-exam.component.html',
  styleUrls: ['./result-detail-exam.component.css']
})
export class ResultDetailExamComponent implements OnInit, OnDestroy {
  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];
  courseId: number;
  modules: ModuleInfo[];
  quizId: number;
  students = [];
  course: Course;
  totalStudents: number;
  passedStudents: number;
  failedStudents: number;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex = 1;
  pageSize = 10;

  constructor(
    private route: ActivatedRoute,
    public quizContentService: QuizContentService,
    private modalService: NgbModal,
    private reportService: ReportService,
    private courseService: CourseService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.courseId = Number(this.route.snapshot.paramMap.get('id'));
    this.getDetailCourse();
    this.getListQuiz();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  getListQuiz() {
    this.quizContentService.listQuiz(this.courseId, 0).subscribe((data) => {
      this.modules = data.body.modules;
      if (this.modules.length > 0) {
        // tslint:disable-next-line: no-string-literal
        this.modules.forEach(m => m['selected'] = false);
        // tslint:disable-next-line: no-string-literal
        this.modules[0]['selected'] = true;
        this.quizId = this.modules[0].instance;
        this.getListStudentByQuiz();
      }
    });
  }

  openResultUser(quizAttemptid: number, userName: string) {
    const modalDep = this.modalService.open(ResultExamUserComponent, {
      size: 'xl' as any,
      centered: false,
      backdrop: 'static',
      scrollable: true
    });
    modalDep.componentInstance.quizAttemptid = quizAttemptid;
    modalDep.componentInstance.studentName = userName;
  }

  onSelectQuiz(quiz: any) {
    if (quiz.instance === this.quizId){
      return;
    }
    this.quizId = quiz.instance;
    this.pageIndex = 1;
    this.pageSize = 10;
    this.getListStudentByQuiz();
  }

  getListStudentByQuiz(){
    const params = {
      quizid: this.quizId,
      limit: this.pageSize,
      page: this.pageIndex
    };
    this.spinner.show();
    this.reportService.getListStudentByQuiz(params).subscribe(rs => {
      this.students = rs.body.results;
      this.totalStudents = rs.body.total;
      this.passedStudents = rs.body.studentpass;
      this.failedStudents = rs.body.studdentfaild;
      this.spinner.hide();
    }, () => {
        this.spinner.hide();
    });
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date).format('DD/MM/YYYY - HH:mm');
    }
    return null;
  }

  getDetailCourse(){
    this.courseService.getCoursesInfo(this.courseId).subscribe(rs => {
      this.course = rs.body;
    });
  }

  changePage(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getListStudentByQuiz();
  }
}
