<section class="inner_page_breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 text-center">
        <div class="breadcrumb_content mt-5">
          <h4 class="breadcrumb_title">Khóa học</h4>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#">Trang chủ</a>
            </li>
          </ol>
        </div>
      </div>

    </div>
  </div>
</section>

<main id="main">
  <!---Phan noi dung bai hoc-->
  <section id="tabs" class="p-0">
    <div class="container">
      <div class="row mt-5">
        <div class="col-lg-9 col-md-6 box-object">
          <div class="row mt-3">
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member box-sub-object">
                <div class="member-img mb-2">
                  <div class="hovereffect">
                    <img
                      class="img-responsive"
                      src="assets/end-user/img/crouse/6.jpg"
                      class="img-fluid"
                      alt=""
                    />
                    <div class="overlay">
                      <h2>01h30 phút</h2>
                      <p>
                        <a href="#">THAM GIA HỌC</a>
                      </p>
                    </div>
                  </div>
                  <img />
                </div>
                <div class="member-info p-2">
                  <h5>Khóa học lập trình PHP</h5>
                  <span style="font-size: 10pt; text-align: justify;"
                  ><b>Giảng viên </b> Phạm Quang Đức</span
                  >
                  <p class="mt-3" style="font-size: 11pt; text-align: justify;">
                    Neque non quidem sit sed pariatur quia modi ea occaecati.
                    Incidunt ea non est corporis in.
                  </p>
                </div>
                <div class="row p-2">
                  <div class="col-md-6">
                    <a href="#" class="linkedin"
                    ><i class="icofont-notebook"></i>
                      <span style="font-size: 10pt;">CNTT</span></a
                    >
                  </div>
                  <div class="col-md-6 text-right">
                    <a href="#" class="linkedin"
                    ><i class="icofont-ruler-alt-2"></i>
                      <span style="font-size: 10pt;">Kỹ thuật</span></a
                    >
                  </div>
                </div>
                <div class="row m-1">
                  <div class="progress mt-1 mb-2" style="width: 100%;">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 25%;"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      25%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member box-sub-object">
                <div class="member-img mb-2">
                  <div class="hovereffect">
                    <img
                      class="img-responsive"
                      src="assets/end-user/img/crouse/7.jpg"
                      class="img-fluid"
                      alt=""
                    />
                    <div class="overlay">
                      <h2>01h30 phút</h2>
                      <p><a href="#">THAM GIA HỌC</a></p>
                    </div>
                  </div>
                  <img />
                </div>
                <div class="member-info p-2">
                  <h5>Khóa học lập trình PHP</h5>
                  <span style="font-size: 10pt; text-align: justify;"
                  ><b>Giảng viên </b> Phạm Quang Đức</span
                  >
                  <p class="mt-3" style="font-size: 11pt; text-align: justify;">
                    Neque non quidem sit sed pariatur quia modi ea occaecati.
                    Incidunt ea non est corporis in.
                  </p>
                </div>
                <div class="row p-2">
                  <div class="col-md-6">
                    <a href="#" class="linkedin"
                    ><i class="icofont-notebook"></i>
                      <span style="font-size: 10pt;">CNTT</span></a
                    >
                  </div>
                  <div class="col-md-6 text-right">
                    <a href="#" class="linkedin"
                    ><i class="icofont-ruler-alt-2"></i>
                      <span style="font-size: 10pt;">Kỹ thuật</span></a
                    >
                  </div>
                </div>
                <div class="row m-1">
                  <div class="progress mt-1 mb-2" style="width: 100%;">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 25%;"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      25%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member box-sub-object">
                <div class="member-img mb-2">
                  <div class="hovereffect">
                    <img
                      class="img-responsive"
                      src="assets/end-user/img/crouse/6.jpg"
                      class="img-fluid"
                      alt=""
                    />
                    <div class="overlay">
                      <h2>01h30 phút</h2>
                      <p>
                        <a href="#">THAM GIA HỌC</a>
                      </p>
                    </div>
                  </div>
                  <img />
                </div>
                <div class="member-info p-2">
                  <h5>Khóa học lập trình PHP</h5>
                  <span style="font-size: 10pt; text-align: justify;"
                  ><b>Giảng viên </b> Phạm Quang Đức</span
                  >
                  <p class="mt-3" style="font-size: 11pt; text-align: justify;">
                    Neque non quidem sit sed pariatur quia modi ea occaecati.
                    Incidunt ea non est corporis in.
                  </p>
                </div>
                <div class="row p-2">
                  <div class="col-md-6">
                    <a href="#" class="linkedin"
                    ><i class="icofont-notebook"></i>
                      <span style="font-size: 10pt;">CNTT</span></a
                    >
                  </div>
                  <div class="col-md-6 text-right">
                    <a href="#" class="linkedin"
                    ><i class="icofont-ruler-alt-2"></i>
                      <span style="font-size: 10pt;">Kỹ thuật</span></a
                    >
                  </div>
                </div>
                <div class="row m-1">
                  <div class="progress mt-1 mb-2" style="width: 100%;">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 25%;"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      25%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member box-sub-object">
                <div class="member-img mb-2">
                  <div class="hovereffect">
                    <img
                      class="img-responsive"
                      src="assets/end-user/img/crouse/6.jpg"
                      class="img-fluid"
                      alt=""
                    />
                    <div class="overlay">
                      <h2>01h30 phút</h2>
                      <p>
                        <a href="#">THAM GIA HỌC</a>
                      </p>
                    </div>
                  </div>
                  <img />
                </div>
                <div class="member-info p-2">
                  <h5>Khóa học lập trình PHP</h5>
                  <span style="font-size: 10pt; text-align: justify;"
                  ><b>Giảng viên </b> Phạm Quang Đức</span
                  >
                  <p class="mt-3" style="font-size: 11pt; text-align: justify;">
                    Neque non quidem sit sed pariatur quia modi ea occaecati.
                    Incidunt ea non est corporis in.
                  </p>
                </div>
                <div class="row p-2">
                  <div class="col-md-6">
                    <a href="#" class="linkedin"
                    ><i class="icofont-notebook"></i>
                      <span style="font-size: 10pt;">CNTT</span></a
                    >
                  </div>
                  <div class="col-md-6 text-right">
                    <a href="#" class="linkedin"
                    ><i class="icofont-ruler-alt-2"></i>
                      <span style="font-size: 10pt;">Kỹ thuật</span></a
                    >
                  </div>
                </div>
                <div class="row m-1">
                  <div class="progress mt-1 mb-2" style="width: 100%;">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 25%;"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      25%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member box-sub-object">
                <div class="member-img mb-2">
                  <div class="hovereffect">
                    <img
                      class="img-responsive"
                      src="assets/end-user/img/crouse/7.jpg"
                      class="img-fluid"
                      alt=""
                    />
                    <div class="overlay">
                      <h2>01h30 phút</h2>
                      <p><a href="#">THAM GIA HỌC</a></p>
                    </div>
                  </div>
                  <img />
                </div>
                <div class="member-info p-2">
                  <h5>Khóa học lập trình PHP</h5>
                  <span style="font-size: 10pt; text-align: justify;"
                  ><b>Giảng viên </b> Phạm Quang Đức</span
                  >
                  <p class="mt-3" style="font-size: 11pt; text-align: justify;">
                    Neque non quidem sit sed pariatur quia modi ea occaecati.
                    Incidunt ea non est corporis in.
                  </p>
                </div>
                <div class="row p-2">
                  <div class="col-md-6">
                    <a href="#" class="linkedin"
                    ><i class="icofont-notebook"></i>
                      <span style="font-size: 10pt;">CNTT</span></a
                    >
                  </div>
                  <div class="col-md-6 text-right">
                    <a href="#" class="linkedin"
                    ><i class="icofont-ruler-alt-2"></i>
                      <span style="font-size: 10pt;">Kỹ thuật</span></a
                    >
                  </div>
                </div>
                <div class="row m-1">
                  <div class="progress mt-1 mb-2" style="width: 100%;">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 25%;"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      25%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member box-sub-object">
                <div class="member-img mb-2">
                  <div class="hovereffect">
                    <img
                      class="img-responsive"
                      src="assets/end-user/img/crouse/6.jpg"
                      class="img-fluid"
                      alt=""
                    />
                    <div class="overlay">
                      <h2>01h30 phút</h2>
                      <p>
                        <a href="#">THAM GIA HỌC</a>
                      </p>
                    </div>
                  </div>
                  <img />
                </div>
                <div class="member-info p-2">
                  <h5>Khóa học lập trình PHP</h5>
                  <span style="font-size: 10pt; text-align: justify;"
                  ><b>Giảng viên </b> Phạm Quang Đức</span
                  >
                  <p class="mt-3" style="font-size: 11pt; text-align: justify;">
                    Neque non quidem sit sed pariatur quia modi ea occaecati.
                    Incidunt ea non est corporis in.
                  </p>
                </div>
                <div class="row p-2">
                  <div class="col-md-6">
                    <a href="#" class="linkedin"
                    ><i class="icofont-notebook"></i>
                      <span style="font-size: 10pt;">CNTT</span></a
                    >
                  </div>
                  <div class="col-md-6 text-right">
                    <a href="#" class="linkedin"
                    ><i class="icofont-ruler-alt-2"></i>
                      <span style="font-size: 10pt;">Kỹ thuật</span></a
                    >
                  </div>
                </div>
                <div class="row m-1">
                  <div class="progress mt-1 mb-2" style="width: 100%;">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 25%;"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      25%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member box-sub-object">
                <div class="member-img mb-2">
                  <div class="hovereffect">
                    <img
                      class="img-responsive"
                      src="assets/end-user/img/crouse/6.jpg"
                      alt=""
                    />
                    <div class="overlay">
                      <h2>01h30 phút</h2>
                      <p>
                        <a href="#">THAM GIA HỌC</a>
                      </p>
                    </div>
                  </div>
                  <img />
                </div>
                <div class="member-info p-2">
                  <h5>Khóa học lập trình PHP</h5>
                  <span style="font-size: 10pt; text-align: justify;"
                  ><b>Giảng viên </b> Phạm Quang Đức</span
                  >
                  <p class="mt-3" style="font-size: 11pt; text-align: justify;">
                    Neque non quidem sit sed pariatur quia modi ea occaecati.
                    Incidunt ea non est corporis in.
                  </p>
                </div>
                <div class="row p-2">
                  <div class="col-md-6">
                    <a href="#" class="linkedin"
                    ><i class="icofont-notebook"></i>
                      <span style="font-size: 10pt;">CNTT</span></a
                    >
                  </div>
                  <div class="col-md-6 text-right">
                    <a href="#" class="linkedin"
                    ><i class="icofont-ruler-alt-2"></i>
                      <span style="font-size: 10pt;">Kỹ thuật</span></a
                    >
                  </div>
                </div>
                <div class="row m-1">
                  <div class="progress mt-1 mb-2" style="width: 100%;">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 25%;"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      25%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member box-sub-object">
                <div class="member-img mb-2">
                  <div class="hovereffect">
                    <img
                      class="img-responsive"
                      src="assets/end-user/img/crouse/7.jpg"
                      alt=""
                    />
                    <div class="overlay">
                      <h2>01h30 phút</h2>
                      <p><a href="#">THAM GIA HỌC</a></p>
                    </div>
                  </div>
                  <img />
                </div>
                <div class="member-info p-2">
                  <h5>Khóa học lập trình PHP</h5>
                  <span style="font-size: 10pt; text-align: justify;"
                  ><b>Giảng viên </b> Phạm Quang Đức</span
                  >
                  <p class="mt-3" style="font-size: 11pt; text-align: justify;">
                    Neque non quidem sit sed pariatur quia modi ea occaecati.
                    Incidunt ea non est corporis in.
                  </p>
                </div>
                <div class="row p-2">
                  <div class="col-md-6">
                    <a href="#" class="linkedin"
                    ><i class="icofont-notebook"></i>
                      <span style="font-size: 10pt;">CNTT</span></a
                    >
                  </div>
                  <div class="col-md-6 text-right">
                    <a href="#" class="linkedin"
                    ><i class="icofont-ruler-alt-2"></i>
                      <span style="font-size: 10pt;">Kỹ thuật</span></a
                    >
                  </div>
                </div>
                <div class="row m-1">
                  <div class="progress mt-1 mb-2" style="width: 100%;">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 25%;"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      25%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member box-sub-object">
                <div class="member-img mb-2">
                  <div class="hovereffect">
                    <img
                      class="img-responsive"
                      src="assets/end-user/img/crouse/6.jpg"
                      alt=""
                    />
                    <div class="overlay">
                      <h2>01h30 phút</h2>
                      <p>
                        <a href="#">THAM GIA HỌC</a>
                      </p>
                    </div>
                  </div>
                  <img />
                </div>
                <div class="member-info p-2">
                  <h5>Khóa học lập trình PHP</h5>
                  <span style="font-size: 10pt; text-align: justify;"
                  ><b>Giảng viên </b> Phạm Quang Đức</span
                  >
                  <p class="mt-3" style="font-size: 11pt; text-align: justify;">
                    Neque non quidem sit sed pariatur quia modi ea occaecati.
                    Incidunt ea non est corporis in.
                  </p>
                </div>
                <div class="row p-2">
                  <div class="col-md-6">
                    <a href="#" class="linkedin"
                    ><i class="icofont-notebook"></i>
                      <span style="font-size: 10pt;">CNTT</span></a
                    >
                  </div>
                  <div class="col-md-6 text-right">
                    <a href="#" class="linkedin"
                    ><i class="icofont-ruler-alt-2"></i>
                      <span style="font-size: 10pt;">Kỹ thuật</span></a
                    >
                  </div>
                </div>
                <div class="row m-1">
                  <div class="progress mt-1 mb-2" style="width: 100%;">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 25%;"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      25%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <nav id="sidebar">
            <ul class="list-unstyled">
              <li>
                <a
                  href="#homeSubmenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle collapsed"
                >Khóa học CNTT</a
                >
                <ul class="collapse list-unstyled" id="homeSubmenu">
                  <li>
                    <a href="#"
                    ><strong>MySQL</strong> <br />120 Bài<span
                      class="float-right"
                      style="color: #b3b3b3;"
                    >15 pts</span
                    ></a
                    >
                  </li>
                  <li>
                    <a href="#"
                    ><strong>Thuật toán cơ bản</strong> <br />25 Bài<span
                      class="float-right"
                      style="color: #b3b3b3;"
                    >15 pts</span
                    ></a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#pageSubmenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle collapsed"
                >
                  Khóa học bán hàng</a
                >
                <ul class="collapse list-unstyled" id="pageSubmenu">
                  <li>
                    <a href="#"
                    ><strong>Nghệ thuật bán hàng</strong><br />25 bài<span
                      class="float-right"
                      style="color: #b3b3b3;"
                    >15 pts</span
                    ></a
                    >
                  </li>
                  <li>
                    <a href="#"
                    ><strong>Marketing Online</strong><br />15 bài<span
                      class="float-right"
                      style="color: #b3b3b3;"
                    >15 pts</span
                    ></a
                    >
                  </li>
                  <li>
                    <a href="#"
                    ><strong>Tư vấn chiến lược</strong><br />10 bài<span
                      class="float-right"
                      style="color: #b3b3b3;"
                    >15 pts</span
                    ></a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a href="#">Khóa học mở rộng</a>
              </li>
              <li>
                <a href="#">Toeic</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </section>
</main>
<!-- End #main -->
