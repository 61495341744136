import {EndUserExamResultComponent} from './end-user-exam-result/end-user-exam-result.component';
import {Component, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrCustomService} from '../shared/services/toastr-custom.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomValidators} from '../shared/utils/custom-validators';
import CommonUtil from '../shared/utils/common-util';
import {DepartmentService} from '../shared/services/department.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {DOCUMENT} from '@angular/common';
import {LoginComponent} from '../login/login.component';
import {LoginService} from '../shared/services/login.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmModalComponent} from '../shared/modal/confirm-modal/confirm-modal.component';
import {LocalStorageService} from 'ngx-webstorage';
import {USER_INFO} from '../shared/constants/base.constant';
import {UserInfo} from '../shared/model/user-info.model';
import {StatisticalPopupComponent} from './statistical-popup/statistical-popup.component';
import {DocumentPopupComponent} from '../landing-page/document-popup/document-popup.component';
import {UserDetailComponent} from '../end-user/user-detail/user-detail.component';
import 'moment/locale/ja';
import 'moment/locale/fr';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';


@Component({
  selector: 'app-login',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'vi-VI'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class LandingPageComponent implements OnInit {
  isPasswordMatch = true;
  departmentStatistic = [];
  departmentsDisplayTable = [];
  isLogin = false;
  currentUser: UserInfo;
  isShowAccount = false;
  registerUserForm = this.fb.group({
    email: [{value: '', disabled: this.loginService.isLoggedIn()}, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')]],
    phone1: [{value: '', disabled: this.loginService.isLoggedIn()}, [Validators.required, Validators.pattern('(0|\\+84)(\\s|\\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\\d)(\\s|\\.)?(\\d{3})(\\s|\\.)?(\\d{3})$')]],
    firstname: [{value: '', disabled: this.loginService.isLoggedIn()}, [Validators.required, CustomValidators.notBlankValidator]],
    lastname: [{value: '', disabled: this.loginService.isLoggedIn()}, [Validators.required, CustomValidators.notBlankValidator]],
    gender: [{value: '', disabled: this.loginService.isLoggedIn()}, Validators.required],
    icq: [{value: '', disabled: this.loginService.isLoggedIn()}, Validators.required],
    dob: [{value: '', disabled: this.loginService.isLoggedIn()}, Validators.required],
    departmentid: [{value: '', disabled: this.loginService.isLoggedIn()}, Validators.required],
    subdepartment: [{value: '', disabled: this.loginService.isLoggedIn()}, [Validators.required, CustomValidators.notBlankValidator]],
    password: [{value: '', disabled: this.loginService.isLoggedIn()}, [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,36}$')]],
    repassword: [{value: '', disabled: this.loginService.isLoggedIn()}, [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,36}$')]]
  });

  minDob = new Date(1960, 0, 1);
  maxDob = new Date(2015, 0, 1);

  listDepartment = [];

  totalStudent = 0;
  totalAttempt = 0;

  constructor(private fb: FormBuilder,
              private toastrService: ToastrCustomService,
              private spinner: NgxSpinnerService,
              private modalService: NgbModal,
              private router: Router,
              private departmentService: DepartmentService,
              private pageScrollService: PageScrollService,
              public loginService: LoginService,
              private translateService: TranslateService,
              public localStorage: LocalStorageService,
              @Inject(DOCUMENT) private document: any) {
  }

  ngOnInit(): void {
    this.getDepartmentData();
    this.getStatistical();
    this.checkIsLogin();
    this.currentUser = this.localStorage.retrieve(USER_INFO.INFO);
  }

  checkIsLogin(){
    if (this.loginService.isLoggedIn()){
      this.isLogin = true;
    }
  }

  getDepartmentData() {
    this.departmentService.listAllDepartmentPublic().subscribe(res => {
        this.listDepartment = res.body;
      },
      err => {
        this.toastrService.handlerError(err);
      });
  }

  onChangePassword() {
    if (this.registerUserForm.get('password').value && this.registerUserForm.get('repassword').value) {
      if (this.registerUserForm.get('password').value !== this.registerUserForm.get('repassword').value) {
        this.isPasswordMatch = false;
      } else {
        this.isPasswordMatch = true;
      }
    }
  }

  onRegisterUser() {
    if (!this.registerUserForm.valid) {
      CommonUtil.markFormGroupTouched(this.registerUserForm);
      return;
    }

    if (!this.isPasswordMatch) {
      return;
    }

    this.spinner.show();
    this.loginService.signup(this.registerUserForm.value).subscribe(res => {
        this.toastrService.success('common.noti.update_success');
        this.registerUserForm.reset();
        this.showConfirmEmailPopup();
        this.spinner.hide();

      },
      err => {
        this.toastrService.handlerError(err);
        this.spinner.hide();
      });
  }

  onLogin(){
    if (this.loginService.isLoggedIn()) {
      this.router.navigate(['/exam-list']);
      return;
    }

    const modalRef = this.modalService.open(LoginComponent, {
      size: 'md',
      centered: false,
      backdrop: 'static',
    });

    modalRef.componentInstance.loginResult.subscribe((res) => {
      if (res === 'contact') {
        this.pageScrollService.scroll({
          document: this.document,
          scrollTarget: '.contact',
        });
      } else if (res === 'success') {
        this.isLogin = true;
        this.currentUser = this.localStorage.retrieve(USER_INFO.INFO);
      }
    });
  }

  showConfirmEmailPopup(){
    const modalEmailConfirm = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalEmailConfirm.componentInstance.title = this.translateService.instant('login.confirm_register_email.title');
    modalEmailConfirm.componentInstance.body = this.translateService.instant('login.confirm_register_email.content');
    modalEmailConfirm.componentInstance.confirmEmail = true;
  }

  getStatistical(){
    this.departmentService.getAllStatisticalDepartment().subscribe(res => {
      this.departmentStatistic = res.body;
      this.totalStudent = 0;
      this.totalAttempt = 0;
      if (this.departmentStatistic.length > 0) {
        this.departmentStatistic.forEach(department => {
          if (department.sothisinh) {
            this.totalStudent += +department.sothisinh;
          }
          if (department.slt) {
            this.totalAttempt += +department.slt;
          }
        });
      }
      this.departmentsDisplayTable = this.departmentStatistic.slice(0, 5);
    }, error => {
      this.toastrService.handlerError(error);
    });
  }

  showPopupExamResult() {
    const modal = this.modalService.open(EndUserExamResultComponent, {
      size: 'lg',
      centered: false,
      backdrop: 'static'
    });
  }

  logout() {
    this.localStorage.clear();
    window.location.href = '/';
  }

  openStatistical() {
    const statisticalPopup = this.modalService.open(StatisticalPopupComponent, {
      size: 'lg',
      centered: false,
      backdrop: 'static'
    });
    statisticalPopup.componentInstance.departments = this.departmentStatistic;
    statisticalPopup.componentInstance.totalAttempt = this.totalAttempt;
    statisticalPopup.componentInstance.totalStudent = this.totalStudent;
}

  openDocumentPopup() {
    const documentPopup = this.modalService.open(DocumentPopupComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static'
    });
  }
  oncChangeShowAccount() {
    this.isShowAccount = !this.isShowAccount;
  }

  openUpdateUserPopup() {
    const updateUserPopup = this.modalService.open(UserDetailComponent, {
      size: 'lg',
      centered: false,
      backdrop: 'static'
    });
    updateUserPopup.componentInstance.listDepartment = this.listDepartment;
    this.isShowAccount = false;
  }
}

