import {PageEvent} from '@angular/material/paginator';
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {QuestionBankService} from '../../../../shared/services/question-bank.service';
import {QuizContentService} from '../../../../shared/services/quiz-content.service';
import {ToastrCustomService} from '../../../../shared/services/toastr-custom.service';
import * as moment from 'moment';
import {CourseCategoryService} from '../../../../shared/services/course-category.service';
import {CourseCategory} from '../../../../shared/model/course-category.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import CommonUtil from '../../../../shared/utils/common-util';
import {NgxSpinnerService} from 'ngx-spinner';
import { TOPIC_CONST } from 'src/app/shared/constants/base.constant';

@Component({
  selector: 'app-chosen-lib',
  templateUrl: './chosen-lib.component.html',
  styleUrls: ['./chosen-lib.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChosenLibComponent implements OnInit {

  listQuestion: any[] = [];
  listQuestionChecked: any[] = [];
  moduleForm: FormGroup;
  questionIds = new Array();
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  completedAll = false;
  lastSort: any;
  @Output() loadAll = new EventEmitter();
  // MatPaginator Output
  pageEvent: PageEvent;
  keyWord: string;
  @Input() quizId: number;
  active0: string;
  active1: string;
  active2: string;
  active3: string;
  @Input() listQuestions: any[] = [];
  courseCategoryList: CourseCategory[] = [];
  categoryMap = new Map();
  categoryId: number;
  level: any;
  TOPIC_CONST = TOPIC_CONST;

  searchQuestion = {
    pageIndex: 1,
    pageSize: 10
  };

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public questionBankService: QuestionBankService,
    public quizContentService: QuizContentService,
    private toastrService: ToastrCustomService,
    private modalService: NgbModal,
    private courseCategoryService: CourseCategoryService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.active0 = 'active2';
    this.moduleForm = this.fb.group({
      keyWord: null
    });
    this.getListQuestion();
    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      this.courseCategoryList = response.body;
      CommonUtil.convertCategoryListToMap(this.courseCategoryList, this.categoryMap);
    });
  }

  search() {
    this.searchQuestion.pageIndex = 1;
    this.searchQuestion.pageSize = 10;
    this.getListQuestion();
  }

  getListQuestion() {
    const params = {
      limit: this.searchQuestion.pageSize,
      page: this.searchQuestion.pageIndex,
      keyword: this.moduleForm.value.keyWord,
      level: this.level,
      categoryids: this.categoryId,
      isloadrandomquestion: 0
    };
    this.spinner.show();
    return this.questionBankService.getListQuestion(params).subscribe((data) => {
      data.body.results.forEach(element => {
        element.questiontext = element.questiontext.split('<strong>').join('').split('</strong>').join('');
        element.questiontext = element.questiontext.split('<p>').join('').split('</p>').join('');
      });
      this.listQuestion = data.body.results;
      this.totalRecord = data.body.total;
      if (this.listQuestion !== undefined && this.listQuestionChecked !== undefined) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.listQuestionChecked.length; i++) {
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < this.listQuestion.length; j++) {
            if (this.listQuestion[j].id === this.listQuestionChecked[i].id) {
              this.listQuestion[j].checked = true;
            }
          }
        }
      }
      if (this.listQuestion.length > 0) {
        this.onCheckBoxCompleteAll(this.listQuestion, true);
      }
      this.spinner.hide();
    },
      error => {
        this.toastrService.handlerError(error);
        this.spinner.hide();
      });
  }

  searchLevel(index) {
    this.level = +index;
    this.getListQuestion();
  }

  save() {
    if (this.questionIds.length === 0) {
      return;
    }
    this.spinner.show('addquestion');
    this.quizContentService.addQuestion(this.quizId, this.questionIds).subscribe(
      res => {
        this.spinner.hide('addquestion');
        this.modalService.dismissAll();
        this.toastrService.success(`common.noti.add_question_success`);
        this.loadAll.emit('load all');
      },
      err => {
        this.spinner.hide('addquestion');
        this.toastrService.handlerError(err);
      }
    );

  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.listQuestion.forEach(c => {
        c.checked = true;
        if (!this.questionIds.includes(c.id)) {
          this.questionIds.push(c.id);
        }
      });
      this.listQuestionChecked.push(...this.listQuestion);
    } else {
      // this.questionIds = [];
      this.listQuestion.forEach(c => {
        c.checked = false;
        this.listQuestionChecked = this.listQuestionChecked.filter(q => q.id !== c.id);
      });
      this.onCheckBoxCompleteAll(this.listQuestion, false);
    }
  }

  oncheckboxItem(question: string, checked: any, questionObject) {
    this.completedAll = false;
    if (checked) {
      this.questionIds.push(question);
      this.listQuestionChecked.push(questionObject);
      questionObject.checked = true;
      this.onCheckBoxCompleteAll(this.listQuestion, true);
    } else {
      this.questionIds.splice(this.questionIds.indexOf(question), 1);
      this.listQuestionChecked = this.listQuestionChecked.filter(q => q.id !== questionObject.id);
      questionObject.checked = false;
    }
  }

  onCheckBoxCompleteAll(data?, isSelect?: boolean) {
    const checkedArray = this.questionIds;
    const dataArray = data.map(value => value.id);
    const map = {};

    // initial value default
    checkedArray.forEach(i => map[i] = false);
    dataArray.forEach(i => map[i] === false && (map[i] = true));

    // result after checked
    const resultArray = Object.keys(map).map(k => ({id: +k, matched: map[k]}));

    // result final
    // tslint:disable-next-line:no-shadowed-variable
    const dataChecked = resultArray.filter(value => value.matched === true).map(data => data.id);

    if (isSelect) {
      data.forEach(item => {
        dataChecked.forEach(id => {
          if (item.id === id) {
            item.checked = true;
            return;
          }
        });
      });

      this.completedAll = dataArray.length === dataChecked.length;
    } else {
      dataChecked.forEach(id => {
        this.questionIds.splice(this.questionIds.indexOf(id), 1);
      });
      this.completedAll = false;
    }

  }

  searchCategory(id) {
    this.categoryId = id;
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  changePage(event){
    this.searchQuestion.pageIndex = event.pageIndex + 1;
    this.searchQuestion.pageSize = event.pageSize;
    this.getListQuestion();
  }
}

