import { EXAM_ATTEMPS } from './../../../shared/constants/base.constant';
import {NgxSpinnerService} from 'ngx-spinner';
import {QuestionBankService} from '../../../shared/services/question-bank.service';
import CommonUtil from './../../../shared/utils/common-util';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {Component, EventEmitter, Input, OnInit, Output, OnDestroy, SimpleChanges, OnChanges} from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChosenLibComponent} from '../../course-content/quiz-content/chosen-lib/chosen-lib.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import {IntroeditorModel} from '../../../shared/model/introeditor.model';
import {QuizContentService} from '../../../shared/services/quiz-content.service';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {ActivatedRoute} from '@angular/router';
import {Question} from '../../../shared/model/question.model';
import * as moment from 'moment';
import {LIST_HOURS, LIST_MINUTES} from '../../../shared/constants/base.constant';
import { OVER_DUE_HANDLING_2, GRADE_METHOD_2} from '../../../shared/constants/base.constant';
import {DataService} from 'src/app/shared/services/data.service';
import {CustomValidators} from '../../../shared/utils/custom-validators';
import { v1 as uuidv1 } from 'uuid';
import {ChosenRanComponent} from '../../course-content/quiz-content/chosen-ran/chosen-ran.component';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';

@Component({
  selector: 'app-create-exam',
  templateUrl: './create-exam.component.html',
  styleUrls: ['./create-exam.component.css']
})
export class CreateExamComponent implements OnInit, OnDestroy, OnChanges {

  @Input() quizInput;
  @Input() checkCreate = false;
  @Input() examDetail;
  @Output() showCreateQuizz = new EventEmitter();

  OVER_DUE_HANDLING_2 = OVER_DUE_HANDLING_2;
  GRADE_METHOD_2 = GRADE_METHOD_2;
  formModule: FormGroup;
  timeForm: FormGroup;
  public Editor = ClassicEditor;
  editorConfig = CKEDITOR_CONFIG.NOT_UPLOAD;

  quizId: number;
  idModule: number;
  description: string;
  moduleUpdate: ModuleInfo;
  listQuestion: Question[] = [];
  module: ModuleInfo;
  introeditor: IntroeditorModel;
  endDateExamDate: any;
  startDateExamDate: any;
  courseId: any;
  titleNumber: any = 1;
  questionsTrueFalse: any = [];
  questionsMulti: any = [];
  questionsMultiset: any = [];
  questionTypeMulti = 'multichoice';
  questionTypeMultiset = 'multichoiceset';
  questionTypeTrueFalse = 'truefalse';
  accordions: any = [];
  idCheck: number;
  saveEnabled = false;
  defauttAttemp = '0';
  minCloseDate: any;
  hoursList = LIST_HOURS.filter(hour => hour.value !== '');
  minutesList = LIST_MINUTES.filter(minute => minute.value !== '');

  panelOpenState = true;
  EXAM_ATTEMPS = EXAM_ATTEMPS;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private quizContentService: QuizContentService,
    private route: ActivatedRoute,
    private toastrService: ToastrCustomService,
    private translateService: TranslateService,
    private questionBankService: QuestionBankService,
    public dataService: DataService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.setDefaultPage();
    this.setFormValue();
    if (!this.checkCreate) {
      this.listQuestions();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setDefaultPage();
    this.setFormValue();
  }

  setFormValue() {
    const data = this.getDefaultFormData();
    this.formModule = this.fb.group({
        name: [data.name, [Validators.required, CustomValidators.notBlankValidator, Validators.maxLength(200)]],
        description: [data.description, Validators.required],
        // start date
        openingtime: [data.openingtime],
        houropeningtime: [data.houropeningtime],
        minuteopeningtime: [data.minuteopeningtime],
        // end date
        closingtime: [data.closingtime],
        hourclosingtime: [data.hourclosingtime],
        minuteclosingtime: [data.minuteclosingtime],

        timelimit: [data.timelimit, [Validators.required, CustomValidators.isPositiveInteger, CustomValidators.checkLimit(1,6)]],
        overduehandling: [data.overduehandling, Validators.required],
        gradepass: [data.gradepass, [Validators.required, CustomValidators.isPositiveInteger, CustomValidators.checkLimit(1,6)]],

        grade: [data.grade, [Validators.required, CustomValidators.isPositiveInteger, CustomValidators.checkLimit(1,6)]],
        attempts: [data.attempts, Validators.required],
        grademethod: [data.grademethod, Validators.required]
      },
      {
        validators: CustomValidators.startTimeEndTimeValidator(this.examDetail?.startdate, this.examDetail?.enddate),
      });

      // nếu bài thi đã có học viên làm thì chỉ được sửa các thông tin: thời gian/tên/mô tả
      if (this.quizInput?.quizhasstudent) {
        const feilds = ['name', 'timelimit', 'description', 'openingtime', 'houropeningtime', 'minuteopeningtime', 'closingtime', 'hourclosingtime', 'minuteclosingtime'];
        const keyForm = Object.keys(this.formModule.value);
        for (const key of keyForm) {
          if (!feilds.includes(key)) {
            this.formModule.controls[key].disable();
          }
        }
      }
  }

  getDefaultFormData(): any{
    const openingtime = this.checkCreate ?
      this.getDateFromDatetime(this.examDetail?.startdate) : // create
      this.getDateFromDatetime(this.module.timeopen); // update

    const houropeningtime = this.checkCreate ?
      this.getHourFromDatetime(this.examDetail?.startdate) : // create
      this.getHourFromDatetime(this.module.timeopen); // update

    const minuteopeningtime = this.checkCreate ?
      this.getMinusFromDatetime(this.examDetail?.startdate) : // create
      this.getMinusFromDatetime(this.module.timeopen); // update

    const closingtime = this.checkCreate ?
      this.getDateFromDatetime(this.examDetail?.enddate) : // create
      this.getDateFromDatetime(this.module.timeclose); // update

    const hourclosingtime = this.checkCreate ?
      this.getHourFromDatetime(this.examDetail?.enddate) : // create
      this.getHourFromDatetime(this.module.timeclose); // update

    const minuteclosingtime = this.checkCreate ?
      this.getMinusFromDatetime(this.examDetail?.enddate) : // create
      this.getMinusFromDatetime(this.module.timeclose); // update

    const name = this.checkCreate ?
      null : // create
      this.module.name; // update

    const description = this.checkCreate ?
      null :
      this.module.intro;

    const timelimit = this.checkCreate ?
      null :
      this.module.timelimit / 60;

    const overduehandling = this.checkCreate ?
      this.OVER_DUE_HANDLING_2[0].key : // create
      this.module.overduehandling; // update

    const grade = this.checkCreate ?
      null : // create
      this.module.grade; // update

    const gradepass = this.checkCreate ?
      null : // create
      this.module.gradepass; // update

    const attempts = this.checkCreate ?
      this.defauttAttemp : // create
      this.module.attempts; // update

    const grademethod = this.checkCreate ?
      GRADE_METHOD_2[0].key : // create
      this.module.grademethod; // update

    const result = {
      name,
      description,
      openingtime,
      houropeningtime,
      minuteopeningtime,
      closingtime,
      hourclosingtime,
      minuteclosingtime,
      timelimit,
      overduehandling,
      gradepass,
      grade,
      attempts,
      grademethod
    };
    return result;
  }

  getDateFromDatetime(dateTime: any) {
    return moment.unix(dateTime).toDate();
  }

  getHourFromDatetime(dateTime: any) {
    return moment.unix(dateTime).toDate().getHours();
  }

  getMinusFromDatetime(dateTime: any) {
    return moment.unix(dateTime).toDate().getMinutes();
  }


  onChangGradepass(value: any) {
    const validate = Number.isInteger(+value);
    if (validate && this.formModule.controls['grade'].value) {
      if (value && value > this.formModule.controls['grade'].value) {
        this.formModule.get(['gradepass']).setErrors({'gradepassError': true});
      } else if (value && value <= this.formModule.controls['grade'].value) {
        this.formModule.get(['gradepass']).setErrors(null);
      }
    }
    return validate;
  }

  onChangGrade(value: any) {
    const validate = Number.isInteger(+value);
    if (validate && this.formModule.controls['gradepass'].value) {
      if (value && value < this.formModule.controls['gradepass'].value) {
        this.formModule.get(['gradepass']).setErrors({'gradepassError': true});
      } else if (value && value >= this.formModule.controls['gradepass'].value) {
        this.formModule.get(['gradepass']).setErrors(null);
      }
    }
    return validate;
  }


  numericOnly(event: any){
    const patt = /^([0-9])$/;
    return patt.test(event.key);
  }

  setDefaultPage(){
    // tslint:disable-next-line:radix
    this.courseId = parseInt(this.route.snapshot.paramMap.get('id'));
    if (!this.checkCreate){
      if (!this.module){
        this.module = this.quizInput;
      }
      if ((this.examDetail?.published === 1 || this.examDetail?.readonly) && this.quizInput) {
        this.idModule = this.quizInput.id;
      }
    }else {
      this.quizInput = undefined;
    }

    this.endDateExamDate = moment.unix(this.examDetail?.enddate).toDate();
    this.startDateExamDate = moment.unix(this.examDetail?.startdate).toDate();
    this.minCloseDate = this.startDateExamDate;
  }


  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }


  listQuestions() {
    this.accordions = [];
    this.listQuestion = [];
    this.titleNumber = 1;
    if (this.quizInput !== null && this.quizInput !== undefined) {
      this.quizId = this.quizInput.instance;
    } else {
      this.quizId = this.module.id;
    }
    // chưa thêm phân trang
    const params = {
      quizid: this.quizId,
      sortcolumn: 'slot',
      sorttype: 'asc',
      limit: 0
    };
    this.spinner.show();
    this.questionBankService.getListQuestion(params).subscribe((data) => {
      this.listQuestion = data.body.results;
      data.body.results?.forEach((item) => {
        this.addQues(item.qtype, item);
        this.dataService.changeMessage(this.titleNumber, item.qtype);
      });
      this.spinner.hide();
    },
      error => {
        this.toastrService.handlerError(error);
        this.spinner.hide();
      });
  }

  openLibaryCreatePopup() {
    const modalDep = this.modalService.open(ChosenLibComponent, {
      size: 'xl',
      centered: false,
      backdrop: 'static'
    });
    if (this.listQuestion !== undefined) {
      modalDep.componentInstance.listQuestions = this.listQuestion;
    }
    if (this.quizInput === undefined) {
      modalDep.componentInstance.quizId = this.module.id;
    } else {
      modalDep.componentInstance.quizId = this.quizInput.instance;
    }
    modalDep.componentInstance.loadAll.subscribe(($e) => {
      this.listQuestions();
    });
  }

  openRandomCreateQuestionPopup() {
    const modalDep = this.modalService.open(ChosenRanComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    if (this.quizInput === undefined) {
      modalDep.componentInstance.quizId = this.module.id;
    } else {
      modalDep.componentInstance.quizId = this.quizInput.instance;
    }
    modalDep.componentInstance.loadAll.subscribe(($e) => {
      this.listQuestions();
    });
  }

  back() {
    this.showCreateQuizz.emit(false);
  }

  edit() {
    this.idModule = null;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listQuestion, event.previousIndex, event.currentIndex);
  }

  addQuestion(typeValue: any, index?: number, ques?: any, isViewMode = false) {
    let question = null;
    if (typeValue === this.questionTypeMulti && this.questionsMulti.length > 0 && index && !ques) {
      // tslint:disable-next-line:no-shadowed-variable
      this.questionsMulti.forEach(ques => {
        if (ques.index === index) {
          question = {...ques};
          question.index = this.titleNumber;
          this.receiveMulti(question);
        }
      });
    } else if (typeValue === this.questionTypeTrueFalse && this.questionsTrueFalse.length > 0 && index && !ques) {
      // tslint:disable-next-line:no-shadowed-variable
      this.questionsTrueFalse.forEach(ques => {
        if (ques.index === index) {
          question = {...ques};
          question.index = this.titleNumber;
          this.receiveTrueFalse(question);
        }
      });
    }else if (typeValue === this.questionTypeMultiset && this.questionsMultiset.length > 0 && index && !ques) {
      // tslint:disable-next-line:no-shadowed-variable
      this.questionsMultiset.forEach(ques => {
        if (ques.index === index) {
          question = {...ques};
          question.index = this.titleNumber;
          this.receiveMultiset(question);
        }
      });
    } else if (ques) {
      question = {...ques};
      question.index = this.titleNumber;
      switch (typeValue){
        case this.questionTypeMulti:
          this.questionsMulti.push(question);
          break;
        case this.questionTypeMultiset:
          this.questionsMultiset.push(question);
          break;
        case this.questionTypeTrueFalse:
          this.questionsTrueFalse.push(question);
          break;
      }
      // typeValue === this.questionTypeMulti ? this.questionsMulti.push(question) : this.questionsTrueFalse.push(question);
    }
    if (question?.answer) {
      question.answer.forEach(a => {
        delete a.id;
      });
    }
    if (question?.id && isViewMode){
      this.prepareQuestiontext(typeValue, question);
    } else {
      delete question?.id;
      this.addQues(typeValue, question);
    }
  }

  addQues(typeValue: any, ques?: any) {
    const newAco = [
      {
        index: this.titleNumber,
        questionNumber: this.titleNumber,
        // title: this.titleNumber,
        description: typeValue === 'random' ? 'Câu hỏi ' + this.titleNumber + ' (Câu hỏi ngẫu nhiên)' : 'Câu hỏi ' + this.titleNumber,
        type: typeValue,
        defaultmark: 4,
        question: ques ? ques : null,
        enable: ques?.id ? false : true,
        btnSaveDisable: ques ? false : true
      }
    ];
    this.accordions.push(...newAco);
    this.titleNumber++;
  }

  removeQuestion(accordion: any) {
    if (this.questionsMulti.length > 0 || this.questionsTrueFalse.length > 0 || this.accordions) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalDep.componentInstance.title = this.translateService.instant('question.delete_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('question.delete_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
      modalDep.result.then((result) => {
        if (result === 'confirm') {
          if (accordion.question?.id) {
            const params = {
              questionids: accordion.question.id,
              quizid: this.quizId
            };
            this.spinner.show();
            this.questionBankService.deleteQuestionOnExam(params).subscribe(
              res => {
                this.toastrService.success(`common.noti.delete_success`);
                this.accordions.splice(this.accordions.indexOf(accordion), 1);
                this.spinner.hide();
                // this.listQuestions();
                // this.titleNumber -= 1;
              },
              err => {
                this.spinner.hide();
                this.toastrService.handlerError(err);
              });
          } else {
            this.accordions.splice(this.accordions.indexOf(accordion), 1);
          }
        }
      });
    }
  }

  saveQuestions(accordion?: any) {
    if (this.questionsTrueFalse.length <= 0 && this.questionsMulti.length <= 0 && this.questionsMultiset.length <= 0) {
      return;
    }
    if (accordion) {
      if (accordion.type === this.questionTypeMulti) {
        const ques = this.questionsMulti.filter(question => question.index === accordion.index);
        if (ques.lenght === 0) {
          return;
        }
        if (accordion.question?.id) {
          ques[0].id = accordion.question.id;
        }
        this.questionsMulti = this.questionsMulti.filter(question => question.index !== accordion.index);
        this.saveQuestion(ques, accordion);
      } else if (accordion.type === this.questionTypeTrueFalse) {
        const ques = this.questionsTrueFalse.filter(question => question.index === accordion.index);
        if (ques.lenght === 0) {
          return;
        }
        if (accordion.question?.id) {
          ques[0].id = accordion.question.id;
        }
        this.questionsTrueFalse = this.questionsTrueFalse.filter(question => question.index !== accordion.index);
        this.saveQuestion(ques, accordion);
      }else if (accordion.type === this.questionTypeMultiset) {
        const ques = this.questionsMultiset.filter(question => question.index === accordion.index);
        if (ques.lenght === 0) {
          return;
        }
        if (accordion.question?.id) {
          ques[0].id = accordion.question.id;
        }
        this.questionsMultiset = this.questionsMultiset.filter(question => question.index !== accordion.index);
        this.saveQuestion(ques, accordion);
      }
    } else {
      this.saveQuestion(this.questionsMulti);
      this.saveQuestion(this.questionsTrueFalse);
      this.saveQuestion(this.questionsMultiset);
      this.questionsMulti = [];
      this.questionsTrueFalse = [];
      this.questionsMultiset = [];
    }
  }

  private saveQuestion(questions: any[], accordion?: any) {
    questions.forEach((question: any) => {
      question.quizid = this.idModule ? this.idModule : this.quizInput.instance;
      question.categoryid = this.examDetail?.categoryid;
      const index = question.index;
      const single = question.single;
      const qtype = question.qtype;
      if (question.questiontext.search('<img') !== -1) {
        question.questiontext = question.questiontext.replaceAll('<img', '<img class="img-fluid"');
      }
      question.answer?.forEach(ans => {
        if (ans.text.search('<img') !== -1) {
          // nếu ảnh chưa có alt thì add thêm, nếu ảnh đã có alt thì replate để tránh gây lỗi khi 2 ảnh có alt bị trùng
          if (ans.text.search('alt="') === -1) {
            ans.text = ans.text.replaceAll('<img', '<img class="img-fluid" alt="' + uuidv1() + '"');
          } else {
            ans.text = ans.text.replaceAll('<img', '<img class="img-fluid"').replaceAll('></figure>', ' alt="' + uuidv1() + '"></figure>');
          }
        }
      });
      if (!question.id && !accordion?.question?.id) {
        this.spinner.show();
        this.questionBankService.createQuestion(question).subscribe(
          res => {
            this.toastrService.success(`common.noti.create_success`);
            if (accordion) {
              this.accordions.forEach(acc => {
                if (acc.index === accordion.index) {
                  acc.question = question;
                  acc.question.id = res.body.id;
                  acc.question.index = index;
                  acc.question.single = single;
                  acc.question.qtype = qtype;
                  acc.enable = false;
                  this.dataService.changeMessage(index, qtype, true);
                }
              });
            } else {
              this.listQuestions();
              this.dataService.changeMessage(index, qtype, true);
            }
            this.spinner.hide();
          },
          err => {
            this.spinner.hide();
            question.index = index;
            question.single = single;
            question.qtype = qtype;
            this.toastrService.handlerError(err);
            // tslint:disable-next-line:no-shadowed-variable
            this.accordions.forEach((accordion) => {
              if (accordion.index === question.index) {
                accordion.btnSaveDisable = true;
              }
            });
          });
      } else {
        this.spinner.show();
        this.questionBankService.updateQuestion(question).subscribe(
          res => {
            this.spinner.hide();
            this.toastrService.success(`common.noti.update_success`);
            if (accordion) {
              this.accordions.forEach(acc => {
                if (acc.index === accordion.index) {
                  acc.question = question;
                  acc.question.index = index;
                  acc.question.single = single;
                  acc.question.qtype = qtype;
                  acc.enable = false;
                  this.dataService.changeMessage(index, qtype);
                }
              });
            } else {
              this.dataService.changeMessage(index, qtype);
            }
          },
          err => {
            this.spinner.hide();
            question.index = index;
            question.single = single;
            question.qtype = qtype;
            this.toastrService.handlerError(err);
            // tslint:disable-next-line:no-shadowed-variable
            this.accordions.forEach((accordion) => {
              if (accordion.index === question.index) {
                accordion.btnSaveDisable = true;
              }
            });
          });
      }
    });
  }

  cancelCreateQues() {
    if (this.accordions.length > 0
      && (this.questionsTrueFalse.length > 0 || this.questionsMulti.length > 0 || this.questionsMultiset.length > 0)) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalDep.componentInstance.title = this.translateService.instant('question.cancel_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('question.cancel_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.continute');
      modalDep.result.then((result) => {
        if (result === 'confirm') {
          // const confirm = this.accordions;
          // this.accordions = [];
          // confirm.forEach((accordion) => {
          //   if(accordion.question && accordion.question.id) this.accordions.push(accordion);
          // });
          // this.questionsTrueFalse = [];
          // this.questionsMulti = [];
          // this.titleNumber = this.accordions.length === 0 ? 1 : this.accordions.length + 1;
          this.listQuestions();
        }
      });
    } else {
      // const confirm = this.accordions;
      // this.accordions = [];
      // confirm.forEach((accordion) => {
      //   if(accordion.question && accordion.question.id) this.accordions.push(accordion);
      // });
      // this.titleNumber = this.accordions.length === 0 ? 1 : this.accordions.length + 1;
      this.listQuestions();
    }
  }

  receiveTrueFalse($ques) {
    if (!$ques.questiontext) {
      this.accordions.forEach((accordion) => {
        if (accordion.index === $ques.index) {
          accordion.btnSaveDisable = true;
        }
      });
      this.saveEnabled = false;
      return;
    }
    this.saveEnabled = true;
    this.accordions.forEach((accordion) => {
      if (accordion.index === $ques.index) {
        accordion.btnSaveDisable = false;
      }
    });
    let recover = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.questionsTrueFalse?.length; i++) {
      if (this.questionsTrueFalse[i].index === $ques.index) {
        // this.questionsTrueFalse[i].name = $ques.name;
        this.questionsTrueFalse[i].questiontext = $ques.questiontext;
        this.questionsTrueFalse[i].level = $ques.level;
        this.questionsTrueFalse[i].defaultmark = $ques.defaultmark;
        this.questionsTrueFalse[i].answercorrect = $ques.answercorrect;
        recover = true;
      }
    }
    $ques.qtype = this.questionTypeTrueFalse;
    $ques.categoryid = this.examDetail?.categoryid;
    if (!recover) {
      this.questionsTrueFalse.push($ques);
    }
  }

  receiveMulti($ques) {
    if (!$ques.questiontext || !$ques.answer[0].text) {
      this.accordions.forEach((accordion) => {
        if (accordion.index === $ques.index) {
          accordion.btnSaveDisable = true;
        }
      });
      this.saveEnabled = false;
      return;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < $ques.answer.length; i++) {
      if (!$ques.answer[i].text || !$ques.answer[i].grade) {
        this.accordions.forEach((accordion) => {
          if (accordion.index === $ques.index) {
            accordion.btnSaveDisable = true;
          }
        });
        this.saveEnabled = false;
        return;
      }
    }
    this.saveEnabled = true;
    this.accordions.forEach((accordion) => {
      if (accordion.index === $ques.index) {
        accordion.btnSaveDisable = false;
      }
    });
    let recover = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.questionsMulti.length; i++) {
      if (this.questionsMulti[i].index === $ques.index) {
        // this.questionsMulti[i].name = $ques.name;
        this.questionsMulti[i].questiontext = $ques.questiontext;
        this.questionsMulti[i].defaultmark = $ques.defaultmark;
        this.questionsMulti[i].answernumbering = $ques.answernumbering;
        this.questionsMulti[i].answer = $ques.answer;
        this.questionsMulti[i].level = $ques.level;
        this.questionsMulti[i].single = $ques.single;
        recover = true;
      }
    }
    $ques.qtype = this.questionTypeMulti;
    $ques.categoryid = this.examDetail?.categoryid;
    if (!recover) {
      this.questionsMulti.push($ques);
    }
  }

  receiveMultiset($ques) {
    if (!$ques.questiontext || !$ques.answer[0].text) {
      this.accordions.forEach((accordion) => {
        if (accordion.index === $ques.index) {
          accordion.btnSaveDisable = true;
        }
      });
      this.saveEnabled = false;
      return;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < $ques.answer.length; i++) {
      if (!$ques.answer[i].text || !$ques.answer[i].grade) {
        this.accordions.forEach((accordion) => {
          if (accordion.index === $ques.index) {
            accordion.btnSaveDisable = true;
          }
        });
        this.saveEnabled = false;
        return;
      }
    }
    this.saveEnabled = true;
    this.accordions.forEach((accordion) => {
      if (accordion.index === $ques.index) {
        accordion.btnSaveDisable = false;
      }
    });
    let recover = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.questionsMultiset.length; i++) {
      if (this.questionsMultiset[i].index === $ques.index) {
        // this.questionsMulti[i].name = $ques.name;
        this.questionsMultiset[i].questiontext = $ques.questiontext;
        this.questionsMultiset[i].defaultmark = $ques.defaultmark;
        this.questionsMultiset[i].answernumbering = $ques.answernumbering;
        this.questionsMultiset[i].answer = $ques.answer;
        this.questionsMultiset[i].level = $ques.level;
        this.questionsMultiset[i].single = $ques.single;
        recover = true;
      }
    }
    $ques.qtype = this.questionTypeMultiset;
    $ques.categoryid = this.examDetail?.categoryid;
    if (!recover) {
      this.questionsMultiset.push($ques);
    }
  }

  editQuestion($index) {
    for (const item of this.accordions) {
      if (item.index === $index) {
        item.enable = true;
      }
    }
  }

  setMinEndDate(startDate: any) {
    this.minCloseDate = startDate;
  }

  submitForm(){
    const data = this.getDataForCreateOrUpdate();
    if (this.quizInput == null && this.idCheck === undefined) {
      this.createQuiz(data);
    }else {

      if (this.quizInput !== null && this.quizInput !== undefined) {
        data.coursemodule = this.quizInput.id;
      } else {
        data.coursemodule = this.module.coursemodule;
      }
      this.updateQuiz(data);

    }
  }

  getDataForCreateOrUpdate() {
    this.introeditor = {text: '', format: 1, itemid: 1598597784614};
    const createOrUpdate: ModuleInfo = {
      attempts: this.formModule.getRawValue().attempts,
      course: this.courseId,
      grade: this.formModule.getRawValue().grade,
      grademethod: this.formModule.getRawValue().grademethod,
      gradepass: this.formModule.getRawValue().gradepass,
      introeditor: null,
      modulename: 'quiz',
      name: this.formModule.getRawValue().name,
      overduehandling: this.formModule.getRawValue().overduehandling,
      section: 0,
      timeclose: this.getClosingtime(),
      timelimit: this.formModule.getRawValue().timelimit * 60,
      timeopen: this.getOpeningtime(),
    };
    this.introeditor.text = this.formModule.getRawValue().description;
    createOrUpdate.introeditor = this.introeditor;

    return createOrUpdate;
  }

  getOpeningtime() {
    return CommonUtil.convertDateToTime(
      this.formModule.value.openingtime,
      this.formModule.value.houropeningtime,
      this.formModule.value.minuteopeningtime,
    );
  }

  getClosingtime() {
    return CommonUtil.convertDateToTime(
      this.formModule.value.closingtime,
      this.formModule.value.hourclosingtime,
      this.formModule.value.minuteclosingtime,
    );
  }

  createQuiz(data){
    this.spinner.show();
    this.quizContentService.createQuizContent(data).subscribe(res => {
      this.toastrService.success(`common.noti.create_success`);
      this.setDataAfterUpdateOrCreate(res);
      this.spinner.hide();
    }, error => {
      this.toastrService.handlerError(error);
      this.spinner.hide();
    });
  }

  updateQuiz(data){
    this.spinner.show();
    this.quizContentService.updateQuizContent(data).subscribe(res => {
      this.toastrService.success(`common.noti.update_success`);
      this.setDataAfterUpdateOrCreate(res);
      this.spinner.hide();
    }, error => {
      this.toastrService.handlerError(error);
      this.spinner.hide();
    });
  }

  setDataAfterUpdateOrCreate(response: any){
    this.module = response.body;
    this.checkCreate = false;
    this.idCheck = this.module.id;
    this.module.grade = Math.ceil(this.module.grade);
    this.idModule = this.module.id;
    this.quizId = this.module.id;
    this.setDefaultPage();
    this.setFormValue();
  }

  getGradepassValueByKey(key){
    return this.GRADE_METHOD_2.find(obj => obj.key === key).value;
  }

  getOverDueHandlingValueByKey(key){
    return this.OVER_DUE_HANDLING_2.find(obj => obj.key === key).value;
  }

  setDefaultStartDate(){
    this.formModule.patchValue({
      // start date
      openingtime: this.getDateFromDatetime(this.examDetail?.startdate),
      houropeningtime: this.getHourFromDatetime(this.examDetail?.startdate),
      minuteopeningtime: this.getMinusFromDatetime(this.examDetail?.startdate),
    });
  }

  setDefaultEndDate(){
    this.formModule.patchValue({
      // end date
      closingtime: this.getDateFromDatetime(this.examDetail?.enddate),
      hourclosingtime: this.getHourFromDatetime(this.examDetail?.enddate),
      minuteclosingtime: this.getMinusFromDatetime(this.examDetail?.enddate),
    });
  }

  onRemoveQuestion(index: number) {
    this.accordions.splice(index, 1);
  }

  prepareQuestiontext(typeValue, question){
    this.spinner.show();
    this.questionBankService.prepareQuestiontextForUpdate(question.id).subscribe(
      res => {
        delete question?.id;
        question.questiontext = res.body.questiontext;
        this.addQues(typeValue, question);
        this.spinner.hide();
      }, error => {
        this.toastrService.handlerError(error);
        this.spinner.hide();
      }
    );
  }

}

