<!-- ======= Hero Section ======= -->
<section id="hero">
  <div class="hero-container">
    <div
      id="heroCarousel"
      class="carousel slide carousel-fade"
      data-ride="carousel"    >
      <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

      <div class="carousel-inner" role="listbox">
        <!-- Slide 1 -->
        <div
          class="carousel-item active"
          style=" background-image: url('assets/end-user/img/slide/slide-1.jpg');
          "
        >
          <div class="carousel-container">
            <div class="carousel-content container">
              <!-- <h2 class="animate__animated animate__fadeInDown ">
                Thư viện tri thức <span>Mobifone</span>
              </h2>
              <h5 class="animate__animated animate__fadeInUp text-white">
                Learning is the eye of the mind.
              </h5> -->
              <!-- <a
                href="#about"
                class="btn-get-started animate__animated animate__fadeInUp scrollto"
                >Xem tiếp</a
              > -->
            </div>
          </div>
        </div>

        <!-- Slide 2 -->
        <div
          class="carousel-item"
          style="
            background-image: url('assets/end-user/img/slide/slide-2.jpg');
          "
        >
          <div class="carousel-container">
            <div class="carousel-content container">
              <!-- <h2 class="animate__animated animate__fadeInDown">
                If you are not willing to learn...
              </h2>
              <h5 class="animate__animated animate__fadeInUp text-white">
                If you are not willing to learn, no one can help you. If you are determined to learn, no one can stop you.
              </h5> -->
              <!-- <a
                href="#about"
                class="btn-get-started animate__animated animate__fadeInUp scrollto"
                >Xem tiếp</a
              > -->
            </div>
          </div>
        </div>

        <!-- Slide 3 -->
        <div
          class="carousel-item"
          style="
            background-image: url('assets/end-user/img/slide/slide-3.jpg');
          "
        >
          <div class="carousel-container">
            <div class="carousel-content container">
              <!-- <h2 class="animate__animated animate__fadeInDown">
                Education is the most...
              </h2>
              <h5 class="animate__animated animate__fadeInUp text-white">
                Education is the most powerful weapon we use to change the world.
              </h5> -->
              <!-- <a
                href="#about"
                class="btn-get-started animate__animated animate__fadeInUp scrollto"
                >Xem tiếp</a
              > -->
            </div>
          </div>
        </div>
      </div>

      <a
        class="carousel-control-prev"
        href="#heroCarousel"
        role="button"
        data-slide="prev"
      >
        <span
          class="carousel-control-prev-icon icofont-rounded-left"
          aria-hidden="true"
        ></span>
        <span class="sr-only">Trước</span>
      </a>
      <a
        class="carousel-control-next"
        href="#heroCarousel"
        role="button"
        data-slide="next"
      >
        <span
          class="carousel-control-next-icon icofont-rounded-right"
          aria-hidden="true"
        ></span>
        <span class="sr-only">Sau</span>
      </a>
    </div>
  </div>
</section>
<!-- End Hero -->


<main id="main">

  <!-- ======= Services Section ======= -->
  <section class="services" *ngIf="isTeacher">
    <div class="container">
      <div class="section-title">
        <h2 class="mb-1">Khóa học đang giảng dạy</h2>
        <!-- <p style="font-size: 12pt; color: #777777">Hãy cố gắng hoàn thành khóa học mà bạn được giao!</p> -->
      </div>
      <section class="mt-0 p-0">
        <div class="container">
          <div class="row" *ngIf=" myCourseTeacher?.length > 0; else elseBlock1">
            <div class="col-12 text-right" *ngIf="myCourseTeacher.results?.length > 4">
              <a
                class="btn btn-outline-primary btn-sm mb-1 mr-2"
                style="line-height: 0.6;"
                href="#carouselExampleIndicators1"
                role="button"
                data-slide="prev"
              >
                <i class="icofont-simple-left"></i>
              </a>
              <a
                class="btn btn-outline-primary btn-sm mb-1"
                style="line-height: 0.6;"
                href="#carouselExampleIndicators1"
                role="button"
                data-slide="next"
              >
                <i class="icofont-simple-right"></i>
              </a>
            </div>
            <div class="col-12">
              <div
                id="carouselExampleIndicators1"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="row">
                      <div class="col-lg-3 col-md-6 d-flex align-items-stretch" *ngFor="let course of myCourseTeacher.slice(0,4)">
                        <app-course-card
                        [course] = "course" [isTeacher]="isTeacher"
                        ></app-course-card>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item" *ngIf="myCourseTeacher?.length > 4">
                    <div class="row">
                      <div class="col-lg-3 col-md-6 d-flex align-items-stretch" *ngFor="let course of myCourseTeacher.slice(4,8)">
                        <app-course-card
                              [course] = "course" [isTeacher]="isTeacher"
                        ></app-course-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-center">
                <button routerLink="/teacher-course" class="btn btn-outline-primary button-round">
                  <a>{{'common.see_more' | translate}}</a>
                </button>
              </div>
            </div>
          </div>
          <ng-template #elseBlock1>
            <div class="no-course text-center p-3">
              <div class="row">
                <div class="col-12 text-center border bg-light p-3">
                  <img src="/assets/img-course.svg" alt="">
                  <p>Bạn chưa có khóa học đang giảng dạy nào!</p>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </section>

    </div>
  </section>
  <!-- End Services Section -->

  <!-- ======= Services Section ======= -->
  <section class="services">
    <div class="container">
      <div class="section-title">
        <h2 class="mb-1">Khóa học của bạn</h2>
        <!-- <p style="font-size: 12pt; color: #777777">Hãy cố gắng hoàn thành khóa học mà bạn được giao!</p> -->
      </div>
      <section class="mt-0 p-0">
        <div class="container">
          <div class="row" *ngIf="myCourse.results?.length > 0;  else elseBlock">
            <div class="col-12 text-right" *ngIf="myCourse.results?.length > 4">
              <a  class="btn btn-outline-primary btn-sm mb-1 mr-2"  style="line-height: 0.6;"   href="#carouselExampleIndicators2"  role="button"  data-slide="prev" ><i class="icofont-simple-left"></i></a>
              <a  class="btn btn-outline-primary btn-sm mb-1"  style="line-height: 0.6;"  href="#carouselExampleIndicators2"  role="button"    data-slide="next" ><i class="icofont-simple-right"></i>              </a>
            </div>
            <div class="col-12" >
              <div id="carouselExampleIndicators2" class="carousel slide"   data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="row">
                      <div class="col-lg-3 col-md-6 d-flex align-items-stretch" *ngFor="let course of myCourse.results.slice(0,4)">
                        <app-course-card
                        [course] = "course"
                        ></app-course-card>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item" *ngIf="myCourse.results?.length > 4">
                    <div class="row">
                      <div class="col-lg-3 col-md-6 d-flex align-items-stretch" *ngFor="let course of myCourse.results.slice(4,8)">
                        <app-course-card
                              [course] = "course"
                        ></app-course-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-center">
                <button routerLink="/my-course" class="btn btn-outline-primary button-round">
                  <a >{{'common.see_more' | translate}}</a>
                </button>
              </div>
            </div>
          </div>
          <ng-template #elseBlock>
            <div class="no-course text-center p-3">
              <div class="row">
                <div class="col-12 text-center border bg-light p-3">
                  <img src="/assets/img-course.svg" alt="">
                  <p>Bạn chưa có khóa học nào mới Vui lòng quay lại sau bạn nhé !</p>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </section>
      <!--phan khoa hoc mo rong-->
      <div class="section-title mt-5">
        <h2 class="mb-1">Lộ trình đào tạo</h2>
        <!-- <p style="font-size: 12pt; color: #777777;">
           Hoàn thành lộ trình đào tạo để có sự nghiệp tốt hơn !
        </p> -->
      </div>
      <section class="mt-0 p-0">
        <div class="container">
          <div class="row" *ngIf="listPosition?.length > 0; else noCoursePosition">
            <div class="col-12 text-right" *ngIf="listPosition?.length > 4">
              <a  class="btn btn-outline-primary btn-sm mb-1 mr-2" style="line-height: 0.6;" href="#carouselExampleIndicators3" role="button" data-slide="prev"><i class="icofont-simple-left"></i></a>
              <a  class="btn btn-outline-primary btn-sm mb-1"    style="line-height: 0.6;"   href="#carouselExampleIndicators3"  role="button"  data-slide="next"><i class="icofont-simple-right"></i></a>
            </div>
            <div class="col-12">
              <div id="carouselExampleIndicators3" class="carousel slide"  data-ride="carousel"  >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="row">
                      <div class="col-md-12 p-3 d-flex align-items-stretch" *ngFor="let item of listPosition?.slice(0,1)">
                        <div class="row box-object-route shadow w-100">
                          <div class="col-lg-3">
                              <img [src]="convertLinkimg(item.filename,item.filearea,item.contextid)" class="clipmask img-course-route" alt="">
                          </div>
                          <div class="col-lg-6 mt-5">
                            <span class="mt-3">
                              <h6 class="mb-3">Chức danh</h6>
                              <h2 class="section-title-route">{{item.name}}</h2></span>
                              <blockquote class="blockstyle"><p class="mb-5">{{item.description ? item.description : 'Đang cập nhật'}}</p></blockquote>
                            <a [routerLink]="['/course-route/detail', item.id]" [state]="{ positionName: item.name }" class="btn btn-lg btn-primary rounded-pill">
                              <span class="text-white"> <i class="fas fa-anchor mr-2"></i>Khám phá </span>
                            </a>
                          </div>
                          <div class="col-lg-3 mt-2 border-left">
                            <div class="reptro-event-item ">
                            <div class="reptro-event-date mt-5">
                              <span class="reptro-event-day text-primary">{{item.totalcourse}}</span>
                              <span class="reptro-event-month mt-2">Khóa học</span>

                              </div>
                              <div class="reptro-event-date mt-2">
                                <span class="reptro-event-day text-danger">{{item.totalexam}}</span>
                                <span class="reptro-event-month mt-2">Kỳ thi</span>

                                </div>
                            </div>
                          </div>
                        </div>
                        <!-- <figure class="effect-oscar shadow" [routerLink]="['/course-route/detail', item.id]" [state]="{ positionName: item.name }">
                          <img src="{{convertLinkimg(item.filename,item.filearea,item.contextid)}}" class="rounded" alt="img10">
                          <figcaption>
                            <h5 class="mt-5">{{item.name}}</h5>
                            <p><span><i class="fas fa-book text-white mr-2"></i>Số khóa học: {{item.totalcourse}}</span></p>
                            <p><span><i class="fas fa-pencil-ruler text-white mr-2"></i>Số kỳ thi: {{item.totalexam}}</span></p>
                            <a [routerLink]="['/course-route/detail', item.id]" [state]="{ positionName: item.name }">View more</a>
                          </figcaption>
                        </figure> -->
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row">
                      <div class="col-md-12 p-3 d-flex align-items-stretch" *ngFor="let item of listPosition?.slice(1,2)">
                        <div class="row box-object-route shadow w-100">
                          <div class="col-lg-3">
                            <img src="{{convertLinkimg(item.filename,item.filearea,item.contextid)}}" class="clipmask img-course-route" alt="">
                          </div>
                          <div class="col-lg-6 mt-5">
                            <span class="mt-3">
                              <h6 class="mb-3">Chức danh</h6>
                              <h2 class="section-title-route">{{item.name}}</h2></span>
                              <blockquote class="blockstyle"><p class="mb-30">{{item.description ? item.description : 'Đang cập nhật'}}</p></blockquote>
                            <a [routerLink]="['/course-route/detail', item.id]" [state]="{ positionName: item.name }" class="btn btn-lg btn-primary rounded-pill">
                              <span class="text-white"> <i class="fas fa-anchor mr-2"></i>Khám phá </span>
                            </a>
                          </div>
                          <div class="col-lg-3 mt-2 border-left">
                            <div class="reptro-event-item ">
                            <div class="reptro-event-date mt-5">
                              <span class="reptro-event-day text-primary">{{item.totalcourse}}</span>
                              <span class="reptro-event-month mt-2">Khóa học</span>

                              </div>
                              <div class="reptro-event-date mt-2">
                                <span class="reptro-event-day text-danger">{{item.totalexam}}</span>
                                <span class="reptro-event-month mt-2">Kỳ thi</span>

                                </div>
                            </div>
                          </div>
                        </div>
                        <!-- <figure class="effect-oscar shadow" [routerLink]="['/course-route/detail', item.id]" [state]="{ positionName: item.name }">
                          <img src="{{convertLinkimg(item.filename,item.filearea,item.contextid)}}" class="rounded" alt="img10">
                          <figcaption>
                            <h5 class="mt-5">{{item.name}}</h5>
                            <p><span><i class="fas fa-book text-white mr-2"></i>Số khóa học: {{item.totalcourse}}</span></p>
                            <p><span><i class="fas fa-pencil-ruler text-white mr-2"></i>Số kỳ thi: {{item.totalexam}}</span></p>
                            <a [routerLink]="['/course-route/detail', item.id]" [state]="{ positionName: item.name }">View more</a>
                          </figcaption>
                        </figure> -->
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 text-center">
                <button routerLink="/course-route" class="btn btn-outline-primary button-round">
                  <a>{{'common.see_more' | translate}}</a>
                </button>
              </div>
            </div>
          </div>
          <ng-template #noCoursePosition>
            <div class="no-course text-center p-3">
               <div class="row">
                 <div class="col-12 text-center border bg-light p-3">
                   <img src="/assets/img-program.svg" alt="">
                   <p>Bạn chưa tham gia lộ trình đào tạo nào !
                    Hãy Khám phá và lựa chọn chương trình học trong khóa học của bạn ! </p>
                 </div>
               </div>
            </div>
          </ng-template>
        </div>
      </section>
    </div>
  </section>
  <!-- End Services Section -->

  <!-- ======= Counts Section ======= -->
  <section class="counts section-bg bg-blur mt-3">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 text-center" data-aos="fade-up">
          <div class="count-box rounded">
            <div class="icon icon-box-pink"><i class="icofont-certificate-alt-1 text-pink-icon "></i></div>
            <span data-toggle="counter-up">{{overviewStudent?.totalbadge ? overviewStudent?.totalbadge : 0}}</span>
            <p class="mb-3">Chứng chỉ</p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 text-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div class="count-box rounded">
            <div class="icon icon-box-cyan"><i class="icofont-document-folder text-cyan-icon"></i></div>
            <span data-toggle="counter-up">{{overviewStudent?.totalcourse ? overviewStudent?.totalcourse : 0}}</span>
            <p class="mb-3">Khóa học chuyên ngành</p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 text-center"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <div class="count-box rounded">
            <div class="icon icon-box-green"><i class="icofont-book text-green-icon "></i></div>
            <span data-toggle="counter-up">{{overviewStudent?.totalposition ? overviewStudent?.totalposition : 0}}</span>
            <p class="mb-3">Lộ trình đào tạo</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Counts Section -->

  <!-- =======Khoa hoc sap toi======= -->
  <section id="team" class="services">
    <div class="container">
      <div class="section-title">
        <h2 class="mb-1">Khóa học tự đăng ký</h2>
        <!-- <p>Đón chờ khóa học bổ ích trong thời gian tới bạn nhé.</p> -->
      </div>
      <div class="row" *ngIf="listCourseRegister?.length == 0">
        <div class="col-12 text-center">
        <img src="../../../assets/end-user/img/khoahoctudangky.png" class="img-fluid" alt="">
      </div>
      </div>
        <!-- <div class="col-xl-3 col-lg-3 col-md-6" data-aos="fade-up">
          <div class="member">
            <div class="pic">
              <img
                src="assets/end-user/img/extend/extend1.jpg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="member-info">
              <h4>Digital Marketing</h4>
              <span>25/07/2020</span>
              <button class="btn btn-success" (click)="onRegisterCourse(164)">Đăng ký khóa học</button>
            </div>
          </div>
        </div> -->

        <!-- <div
          class="col-xl-3 col-lg-3 col-md-6"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div class="member">
            <div class="pic">
              <img
                src="assets/end-user/img/extend/extend2.jpg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="member-info">
              <h4>Học cách lắng nghe</h4>
              <span>30/07/2020</span>
            </div>
          </div>
        </div> -->

        <!-- <div
          class="col-xl-3 col-lg-3 col-md-6"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div class="member">
            <div class="pic">
              <img
                src="assets/end-user/img/extend/extend3.jpg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="member-info">
              <h4>Quản lý Team</h4>
              <span>08/08/2020</span>
            </div>
          </div>
        </div> -->
        <!-- <div
          class="col-xl-3 col-lg-3 col-md-6"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div class="member">
            <div class="pic">
              <img src="assets/end-user/img/team/team-4.jpg" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Quản lý nhân viên</h4>
              <span>15/08/2020</span>
            </div>
          </div>
        </div> -->
        <section class="mt-0 p-0">
          <div class="container">
            <div class="row" *ngIf="listCourseRegister?.length > 0">
              <div class="col-12 text-right" *ngIf="listCourseRegister?.length > 4">
                <a  class="btn btn-outline-primary btn-sm mb-1 mr-2"  style="line-height: 0.6;"  href="#carouselIndicators2"  role="button"  data-slide="prev" ><i class="icofont-simple-left"></i></a>
                <a  class="btn btn-outline-primary btn-sm mb-1"  style="line-height: 0.6;"  href="#carouselIndicators2"  role="button"    data-slide="next" ><i class="icofont-simple-right"></i>              </a>
              </div>
              <div class="col-12" >
                <div id="carouselIndicators2" class="carousel slide" data-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div class="row">
                        <div class="col-lg-3 col-md-6 d-flex align-items-stretch" *ngFor="let course of listCourseRegister.slice(0,4)">
                          <app-course-card
                          [course] = "course" (registerSucc)="reloadListCourseStudent($event)"
                          ></app-course-card>
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item" *ngIf="listCourseRegister?.length > 4">
                      <div class="row">
                        <div class="col-lg-3 col-md-6 d-flex align-items-stretch" *ngFor="let course of listCourseRegister.slice(4,8)">
                          <app-course-card
                                [course] = "course" (registerSucc)="reloadListCourseStudent($event)"
                          ></app-course-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-center">
                  <button routerLink="/course-register" class="btn btn-outline-primary button-round">
                    <a>{{'common.see_more' | translate}}</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  </section>
  <!-- End Our Team Section -->
</main>

<!-- End #main -->
