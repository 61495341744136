import {Component, OnInit} from '@angular/core';
import {LoginService} from '../../../shared/services/login.service';

@Component({
  selector: 'app-appheader',
  templateUrl: './appheader.component.html',
  styleUrls: ['./appheader.component.css']
})
export class AppheaderComponent implements OnInit {


  constructor(private loginService: LoginService) {

  }

  ngOnInit(): void {
  }

  logout(){
    this.loginService.logout();
  }

}
