import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MOODLE_INFO} from '../constants/base.constant';
import {AbstractService} from './abstract.service';
import CommonUtil from '../utils/common-util';
import {map} from 'rxjs/operators';
import {Position} from '../model/position.model';
import {Department} from "../model/department.model";

type EntityArrayResponse = HttpResponse<any>;
type EntityResponseType = HttpResponse<Position>;
type EntityArrayResponseType = HttpResponse<Position[]>;

interface PositionPageResponse {
  results: Position[];
  total: number;
}

@Injectable({providedIn: 'root'})
export class PositionService {
  public resourceUrl = MOODLE_INFO.SERVICE_URL;

  constructor(private abstractService: AbstractService) {

  }

  getPosition(): Observable<HttpResponse<PositionPageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_position_list_position');
    const param = {
      limit: 0
    };
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<PositionPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<PositionPageResponse>) => res));
  }

  getCourseByPositionId(params: any): Observable<EntityArrayResponse> {
    const data = this.abstractService.getCommonInput('mobifone_course_list_course_by_position');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponse) => res));
  }

  searchPositionCouse(position: Position): Observable<any> {
    const searchData = this.abstractService.getCommonInput('mobifone_course_list_course_by_position');
    CommonUtil.appendDataToFormData(searchData, 'params', position);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, searchData).pipe(map((res: any) => res));
  }

  searchPosition(position: Position): Observable<any> {
    const searchData = this.abstractService.getCommonInput('mobifone_position_list_position');
    CommonUtil.appendDataToFormData(searchData, 'params', position);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, searchData).pipe(map((res: any) => res));
  }

  orderPosition(positionId: number, course: any): Observable<EntityArrayResponse> {
    const data = this.abstractService.getCommonInput('mobifone_position_change_order');
    const param = {
      positionid: positionId,
      courses: course
    };
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponse) => res));
  }

  createPosition(position: Position): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_position_create_position');
    delete position.id;
    CommonUtil.appendDataToFormData(data, 'position', position);
    return this.abstractService.post<Position>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updatePosition(position: Position): Observable<EntityResponseType> {
    const updatePosition = this.abstractService.getCommonInput('mobifone_position_update_position');
    CommonUtil.appendDataToFormData(updatePosition, 'position', position);
    return this.abstractService.post<Position>(MOODLE_INFO.SERVICE_URL, updatePosition)
      .pipe(map((res: EntityResponseType) => res));
  }

  deletePosition(ids): Observable<EntityArrayResponseType> {
    const dataDeletePosition = this.abstractService.getCommonInput('mobifone_position_delete_position');
    const params = {
      ids: ids.toString(),
    };
    CommonUtil.appendDataToFormData(dataDeletePosition, 'params', params);
    return this.abstractService.post<Position[]>(MOODLE_INFO.SERVICE_URL, dataDeletePosition)
      .pipe(map((res: EntityArrayResponseType) => res));
  }

  assignCourse(positionid: number, courseids): Observable<EntityArrayResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_position_assign_course');
    CommonUtil.appendDataToFormData(data, 'positionid', positionid);
    CommonUtil.appendDataToFormData(data, 'courseids', courseids);
    return this.abstractService.post(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponseType) => res));
  }

  unAssignCourse(positionid: number, courseids): Observable<EntityArrayResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_position_unassign_course');
    CommonUtil.appendDataToFormData(data, 'positionid', positionid);
    CommonUtil.appendDataToFormData(data, 'courseids', courseids);
    return this.abstractService.post(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponseType) => res));
  }

  getListRoadMap(): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_position_list_current_position');
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
