import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import CommonUtil from '../../../shared/utils/common-util';
import {PositionService} from '../../../shared/services/position.service';
import {Position} from '../../../shared/model/position.model';
import {CustomValidators} from '../../../shared/utils/custom-validators';

@Component({
  selector: 'app-course-category-edit',
  templateUrl: './position-edit.component.html',
  styleUrls: ['../position.component.css']
})
export class PositionEditComponent implements OnInit {

  @Input() positions: Position[];
  // @Input() courseCategoryList: CourseCategory[];
  @Input() position: Position;

  @Output() newPosition = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrCustomService,
    private programPositionService: PositionService
  ) {
  }

  validateCodePos = true;
  positionsName: any;
  editProgramForm = this.fb.group({
  name: ['', [Validators.required, CustomValidators.notBlankValidator, Validators.maxLength(100)]],
  description: [''],
  code: ['', [Validators.required, CustomValidators.notBlankValidator, Validators.maxLength(50)]]
  });
  positionClone: Position;

  titleForm = 'common.update';

  ngOnInit(): void {
    this.positionClone = Object.assign({}, this.position);

    this.fetch();
    if (this.position.id === undefined) {
      this.titleForm = 'position.create_title';
    }
  }

  mustTypeIdDepartment() {
    const code =  this.positionClone.code;
    if (code === '') {
      this.validateCodePos = true;
    } else if (!code.match(/^[A-Za-z0-9-_]+$/g)){
       this.validateCodePos = false;
     } else {
      this.validateCodePos = true;
    }
  }

  editProgram() {
    if (this.editProgramForm.invalid) {
      // Danh dau la da cham de hien thi message loi
      CommonUtil.markFormGroupTouched(this.editProgramForm);
      return;
    }
    if (this.validateCodePos === false){
      return;
    }
    this.editProgramForm.value.id = this.positionClone.id;
    if (this.positionClone.id !== undefined) {
      this.programPositionService.updatePosition(this.editProgramForm.value).subscribe(res => {
          this.toastrService.success(`common.noti.update_success`);
          this.newPosition.emit(this.positionClone.id);
          this.modalService.dismissAll();
        }, err => {
          this.toastrService.handlerError(err);
        }
      );
    } else {
      this.programPositionService.createPosition(this.editProgramForm.value).subscribe(res => {
          this.newPosition.emit(res.body.id);
          this.toastrService.success(`common.noti.create_success`);
          this.modalService.dismissAll();
        }, err => {
          this.toastrService.handlerError(err);
        }
      )
      ;
    }
  }

  trimSpace(){

  }

  fetch() {
    // return this.categoryService.getCourseCategory().subscribe((data) => {
    //   this.categories = data.body;
    // });
  }

}
