<div class="modal-header">
  <h4 class="modal-title">{{ 'quiz.chosen_ran_title' | translate }}</h4>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
          (click)="activeModal.close('Close click')"><i class="fas fa-minus"></i>
  </button>
</div>
<div class="modal-body">
  <form role="form" [formGroup]="addRandomQuestionsForm">

    <div class="form-group">
      <label>{{ 'course_category.categories' | translate }}</label>

      <select class="custom-select" formControlName="categoryid" >
        <option [value]="null" disabled selected >Chọn danh mục</option>
        <option *ngFor="let item of categoryMap | keyvalue; let i = index" [selected]="i == 0"
                [value]="item.key">{{item.value}}</option>
      </select>
      <div *ngIf="addRandomQuestionsForm.get('categoryid')!.invalid
      &&(addRandomQuestionsForm.get('categoryid')!.dirty || addRandomQuestionsForm.get('categoryid')!.touched)">
        <small class="text-danger" *ngIf="addRandomQuestionsForm.get('categoryid')?.errors?.required">
          {{ 'error.validate.input_required' | translate }}
        </small>
      </div>
    </div>

    <div class="form-group">
      <!-- Tim quiz.chosen_ran_number_of_easy_question_title o file vi.json va en.json (i18n) -->
      <b>Loại 1. </b><label>Tiếng anh thông dụng và nâng cao<span class="text-danger"> * </span></label>
      <input type="number" class="form-control" (keypress)="numericOnly($event)"
             placeholder="Nhập số lượng câu hỏi"
             formControlName="one" min="0">
      <small class="text-danger"
             *ngIf="(addRandomQuestionsForm?.get('one')!.dirty
             || addRandomQuestionsForm?.get('one')!.touched)
             && addRandomQuestionsForm?.get('one')?.hasError('required')">
        {{ 'error.validate.input_required' | translate }}
      </small>
    </div>

    <div class="form-group">
      <b>Loại 2. </b><label>Kiến thức cơ bản về chính trị, kinh tế - xã hội, lịch sử, địa lý, văn hoá Việt Nam và các nước trên Thế giới<span class="text-danger"> * </span></label>
      <input type="number" class="form-control" (keypress)="numericOnly($event)"
             placeholder="Nhập số lượng câu hỏi"
             formControlName="two" min="0">
      <small class="text-danger"
             *ngIf="(addRandomQuestionsForm?.get('two')!.dirty
             || addRandomQuestionsForm?.get('two')!.touched)
             && addRandomQuestionsForm?.get('two')?.hasError('required')">
        {{ 'error.validate.input_required' | translate }}
      </small>
    </div>

    <div class="form-group">
      <b>Loại 3. </b><label>Lịch sử hình thành và phát triển của Đảng Cộng Sản Việt Nam<span class="text-danger"> * </span></label>
      <input type="number" class="form-control" (keypress)="numericOnly($event)"
             placeholder="Nhập số lượng câu hỏi"
             formControlName="three" min="0">
      <small class="text-danger"
             *ngIf="(addRandomQuestionsForm?.get('three')!.dirty
             || addRandomQuestionsForm?.get('three')!.touched)
             && addRandomQuestionsForm?.get('three')?.hasError('required')">
        {{ 'error.validate.input_required' | translate }}
      </small>
    </div>

    <div class="form-group">
      <b>Loại 4. </b><label>Điều lệ Đoàn TNCS Hồ Chí Minh, kiến thức về công tác Đoàn và phong trào thanh niên, <br> lịch sử 90 năm thành lập Đoàn TNCS Hồ Chí Minh</label><span class="text-danger"> * </span>
      <input type="number" class="form-control" (keypress)="numericOnly($event)"
             placeholder="Nhập số lượng câu hỏi"
             formControlName="four" min="0">
      <small class="text-danger"
             *ngIf="(addRandomQuestionsForm?.get('four')!.dirty
             || addRandomQuestionsForm?.get('four')!.touched)
             && addRandomQuestionsForm?.get('four')?.hasError('required')">
        {{ 'error.validate.input_required' | translate }}
      </small>
    </div>
    <div class="form-group">
      <b>Loại 5. </b><label>Tiếng Anh chuyên ngành: Kiến thức cơ bản về Kinh tế, Quản trị Doanh nghiệp, Hội nhập Quốc tế<span class="text-danger"> * </span></label>
      <input type="number" class="form-control" (keypress)="numericOnly($event)"
             placeholder="Nhập số lượng câu hỏi"
             formControlName="five" min="0">
      <small class="text-danger"
             *ngIf="(addRandomQuestionsForm?.get('five')!.dirty
             || addRandomQuestionsForm?.get('five')!.touched)
             && addRandomQuestionsForm?.get('five')?.hasError('required')">
        {{ 'error.validate.input_required' | translate }}
      </small>
      <small class="text-danger"
             *ngIf="addRandomQuestionsForm.hasError('invalidChosenQuestion')">
        {{ 'error.validate.quiz.invalid_chosen_question' | translate }}
      </small>
    </div>

  </form>

</div>
<div class="modal-footer text-center">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-dismiss="modal">
    {{ 'common.cancel' | translate }}
  </button>
  <button type="button" (click)="addQuestions()" class="btn btn-primary">
    {{ 'common.save' | translate }}
  </button>
</div>

<ngx-spinner [fullScreen]="true" [name]="'chosenran'" type="ball-clip-rotate-multiple" size="medium">
  <p class="loading">{{ 'common.loading' | translate }}</p>
</ngx-spinner>

