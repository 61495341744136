  <main style="height: 100vh;" class="theduthi">
    <section class="pt0">
        <div class="container" style="position: relative;">
          <div class="row bglinear">
            <div class="col-12 bgkythi">
               <div class="row" >
                 <div class="col-lg-6 col-md-12  order-2 order-lg-1 tkdiemthi">
                      <div clas="w-100" style="margin-bottom:30px;" >
                         <!-- <h2 >{{listQA[0]?.name}}</h2>
                         -->
                         <p class="text-white sfbold text-uppercase mobiletbt" style="line-height: 25pt;">Tìm hiểu nghị quyết đại hội đại biểu Đoàn TNCS Hồ Chí Minh
                          Khối doanh nghiệp Trung Ương lần thứ IV, nhiệm kỳ 2022-2027</p>
                     </div>
                      <div clas="w-100 mt-5" >
                        <p class="text-white" style="opacity: 0.8;">
                          Thí sinh
                        </p>
                         <h3 class="text-white sfbold text-uppercase">{{userInfo.firstname + " " + userInfo.lastname}}</h3>
                     </div>
                      <div clas="w-100 mt-3" style="margin-top:30px;" *ngIf="!isOverExam" >
                        <p class="text-white" style="opacity: 0.8;">
                          Số lượt thi còn lại
                        </p>
                         <h1 class="display-2 text-blue">{{listQA[0]?.attempts ? result : "02"}}</h1>
                     </div>
                     <div clas="w-100" style="margin-top:30px;" *ngIf="listQA?.length > 0">
                        <p class="text-white" style="opacity: 0.8;">
                          Kết quả thi tốt nhất
                        </p>
                         <h1 class="display-2 text-yellow">{{listQA[0]?.gradeuser+ "/" + listQA[0]?.grade}}</h1>

                     </div>
                 </div>
                 <div class="col-lg-6 col-md-12 order-1 order-lg-2 hero-img aos-init aos-animate text-center tkdiemthi" style="position: relative;">
                      <img class="img-fluid logokythi" src="../../../../assets/ladipage/img/olympic1.png" alt="First slide">
                      <div class="navtk d-flex form-inline">
                        <a href="" class="text-white"><i class="fas fa-home"></i> Trang chủ</a>
                        <a (click)="logout()" class="text-yellow ml-3" style="cursor: pointer;"><i class="fas fa-sign-out-alt"></i> Đăng xuất</a>
                      </div>
                    </div>
               </div>
               <div clas="col-12 mt-3 mb-3 text-center" *ngIf="isOverExam" >
                <h6 class="text-center text-white">
                  Xin chào. Bạn đã hoàn thành bài thi của mình, cám ơn bạn đã tham gia kỳ thi.
                </h6>
              </div>
               <div class="col-12 text-center mb-2">
                <span class="text-yellow" *ngIf="!quizIdActive">Kỳ thi chưa diễn ra, vui lòng quay lại sau</span>
               </div>
               <div class="col-12 text-center">
                <button class="btn btn-danger btn-lg shadow-lg pl-5 pr-5" [disabled]="isOverExam" *ngIf="quizIdActive" (click)="startAttempt()">Bắt đầu thi</button>
                <!-- <span class="btn btn-danger btn-lg shadow-lg pl-5 pr-5" *ngIf="!quizIdActive">Kỳ thi chưa diễn ra, vui lòng quay lại sau</span> -->
<!--                <button class="btn btn-warning btn-lg shadow-lg pl-4 pr-4 ml-3 mb-2">Kết quả thi</button>-->
             </div>
             <div class="col-12 text-center mt-3">
              <p class="text-center text-white">Mỗi lượt thi có <span class="text-yellow sfbold" style="font-size: 20pt;">15 phút</span>. Hãy tập trung để giành giải thưởng cho đơn vị và cá nhân bạn ! Chúc bạn đạt kết quả cao trong kỳ thi</p>
           </div>

            </div>
          </div>
        </div>

    </section>
  </main>
