import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {MOODLE_INFO} from '../constants/base.constant';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ModuleInfo} from '../model/moduleinfo.model';
import CommonUtil from '../utils/common-util';


type EntityResponseType = HttpResponse<ModuleInfo>;
type EntityAnyResponse = HttpResponse<any>;

@Injectable({providedIn: 'root'})
export class TopicService {
  constructor(private abstractService: AbstractService,
  ) {
  }

  getCourseTopics(courseId: any, keyword?: string): Observable<EntityAnyResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_list_topic');
    data.append('courseid', courseId);
    if (keyword) {
      data.append('keyword', keyword);
    }
    const returndata = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityAnyResponse) => res));
    return returndata;
  }

  getCourseTopicsEnduser(courseId: any, keyword?: string): Observable<EntityAnyResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_enduser_list_topic');
    data.append('courseid', courseId);
    if (keyword) {
      data.append('keyword', keyword);
    }
    const returndata = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityAnyResponse) => res));
    return returndata;
  }

  createTopic(topic): Observable<EntityAnyResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_create_topic');
    data.append('courseid', topic.courseid);
    data.append('name', topic.name);
    const value = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityAnyResponse) => res));
    return value;
  }

  editTopic(topic): Observable<EntityAnyResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_update_topic');
    data.append('topicid', topic.topicId);
    data.append('name', topic.name);
    const returnvalue = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe((map((res: EntityAnyResponse) => res)));
    // console.log(returnvalue);
    return returnvalue;
  }

  deleteTopic(topic): Observable<EntityAnyResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_delete_topic');
    data.append('courseid', topic.courseid);
    CommonUtil.appendDataToFormData(data, 'topicids', topic.topicId);
    // console.log(topic.topicId);
    const returnvalue = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityAnyResponse) => res));
    return returnvalue;
  }

  deleteModule(id): Observable<EntityAnyResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_delete_module');
    CommonUtil.appendDataToFormData(data, 'cmids', id);
    // console.log(data);
    const returnval = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityAnyResponse) => res));
    return returnval;
  }


  createPageContent(moduleinfo: ModuleInfo): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_create_page');
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updatePageContent(moduleinfo): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_update_page');
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  createResourceContent(moduleinfo: ModuleInfo, file: File): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_create_resource');
    delete moduleinfo.id;
    data.append('repo_upload_file', file, file.name);
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updateResourceContent(moduleinfo, file: File): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_update_resource');
    if (file !== null && file !== undefined) {
      data.append('repo_upload_file', file, file.name);
    }
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }


  createScormContent(moduleinfo: ModuleInfo, file: File): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_create_scorm');
    data.append('repo_upload_file', file, file.name);
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updateScormContent(moduleinfo: ModuleInfo, file: File): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_update_scorm');
    if (file !== null && file !== undefined) {
      data.append('repo_upload_file', file, file.name);
    }
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  getScormSco(moduleid): Observable<EntityAnyResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_list_scorm_sco');
    data.append('coursemoduleid', moduleid);
    const returnvalue = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityAnyResponse) => res));
    return returnvalue;
  }


  createModuleContent(moduleinfo: ModuleInfo): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_create_module');
    delete moduleinfo.id;
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    // console.log(data);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updateModuleContent(moduleinfo): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_update_module');
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }


  getModuleDetail(moduleid): Observable<EntityAnyResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_module_detail');
    data.append('coursemoduleid', moduleid);
    const returnvalue = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityAnyResponse) => res));
    return returnvalue;
  }


  createBBBContent(moduleinfo: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_create_bbb');
    delete moduleinfo.intro;
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    // console.log(data);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updateBBBContent(moduleinfo): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_update_bbb');
    delete moduleinfo.instance;
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  joinMeeting(coursemoduleid: any) {
    const data = this.abstractService.getCommonInput('mobifone_topic_get_meeting_url');
    data.append('coursemoduleid', coursemoduleid);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  studentJoinMeeting(coursemoduleid: any) {
    const data = this.abstractService.getCommonInput('mobifone_topic_student_get_meeting_url');
    data.append('coursemoduleid', coursemoduleid);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }


  endMeeting(courseModuleId: any) {
    const data = this.abstractService.getCommonInput('mobifone_topic_end_meeting');
    data.append('coursemoduleid', courseModuleId);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  listBBBRecord(params): Observable<EntityAnyResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_admin_list_bbb_record');
    CommonUtil.appendDataToFormData(data, 'params', params);
    const returnvalue = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityAnyResponse) => res));
    return returnvalue;
  }

  deleteBBBRecord(params): Observable<EntityAnyResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_admin_delete_bbb_record');
    CommonUtil.appendDataToFormData(data, 'params', params);
    const returnvalue = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityAnyResponse) => res));
    return returnvalue;
  }

  prepareDataForUpdate(moduleid): Observable<EntityAnyResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_admin_prepare_data_for_update_module');
    data.append('coursemoduleid', moduleid);
    const returnvalue = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityAnyResponse) => res));
    return returnvalue;
  }

}
