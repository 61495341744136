import {Component, Input, OnInit} from '@angular/core';
import {UserInfo} from '../../shared/model/user-info.model';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserService} from '../../shared/services/user.service';
import {LocalStorageService} from 'ngx-webstorage';
import {USER_INFO} from '../../shared/constants/base.constant';
import {FileService} from '../../shared/services/file.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {CustomValidators} from '../../shared/utils/custom-validators';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {

  user: UserInfo;
  isChangeAvatar = false;
  isValidAvatarFormat = true;
  avatar: File;

  @Input() listDepartment = [];

  updateUserInfoForm = this.fb.group({
    departmentid: [, Validators.required],
    subdepartment: [,  [Validators.required, CustomValidators.notBlankValidator]],
    fullname: [{value: '', disabled: true}, [Validators.required, CustomValidators.notBlankValidator]],
    username: [{value: '', disabled: true}, [Validators.required, CustomValidators.notBlankValidator]],
    email: [{value: '', disabled: true}, [Validators.required, CustomValidators.notBlankValidator]],
    gender: [{value: '', disabled: true}, [Validators.required, CustomValidators.notBlankValidator]],
    dob: [{value: '', disabled: true}, [Validators.required, CustomValidators.notBlankValidator]],
    icq: [{value: '', disabled: true}, [Validators.required, CustomValidators.notBlankValidator]],
    phone1: [{value: '', disabled: true}, [Validators.required, CustomValidators.notBlankValidator]]
  });

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    public userService: UserService,
    private $localStorage: LocalStorageService,
    private fileServide: FileService,
    private activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    this.user = this.$localStorage.retrieve(USER_INFO.INFO);
    this.patchValueToForm();
  }

  patchValueToForm(){
    this.updateUserInfoForm.controls.phone1.setValue(this.user.phone1);
    this.updateUserInfoForm.controls.fullname.setValue(this.user.firstname + ' ' + this.user.lastname);
    this.updateUserInfoForm.controls.icq.setValue(this.user.icq);
    this.updateUserInfoForm.controls.username.setValue(this.user.username);
    this.updateUserInfoForm.controls.email.setValue(this.user.email);
    this.updateUserInfoForm.controls.departmentid.setValue(this.user.departmentid + '');
    this.updateUserInfoForm.controls.subdepartment.setValue(this.user.department);

    // @ts-ignore
    this.updateUserInfoForm.controls.dob.setValue(moment(new Date(+this.user.dob * 1000)).format('DD/MM/yyyy'));
    this.updateUserInfoForm.controls.gender.setValue(this.user.gender);

  }

  changeAvatar(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      this.isChangeAvatar = true;
      if (files[0].type === 'image/jpeg'
        || files[0].type === 'image/jpg'
        || files[0].type === 'image/png'
        || files[0].type === 'image/bmp'
        || files[0].type === 'image/gif') {

        this.avatar = files[0];
        this.isValidAvatarFormat = true;
        // preview avatar
        this.previewAvatarBeforeUpdate(event);

      } else {
        this.isValidAvatarFormat = false;
      }
    }
  }

  previewAvatarBeforeUpdate(event: any) {

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    // tslint:disable-next-line:no-shadowed-variable
    reader.onload = (event: any) => {
      this.user.userpictureurl = event.target.result;
    };

  }

updateUserInfo() {

    const param = {
      id: this.user.id,
      department: this.updateUserInfoForm.get('subdepartment').value,
      departmentid: this.updateUserInfoForm.get('departmentid').value
    };
    this.spinner.show();
    this.userService.updateOwnDetail(this.avatar, param).subscribe(res => {
        if (res) {
          this.user.departmentid = +this.updateUserInfoForm.get('departmentid').value;
          this.user.department = this.updateUserInfoForm.get('subdepartment').value;
          this.$localStorage.store(USER_INFO.INFO, this.user);
          this.toastrService.success('common.noti.update_success');
          this.spinner.hide();
          this.close();
        }
    },
      error => {
        this.toastrService.handlerError(error);
        this.spinner.hide();
      });
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  close() {
    this.activeModal.dismiss('close');
  }
}
