<div class="modal-header">
  <h4 class="modal-title">{{ title | translate }}</h4>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
          (click)="activeModal.dismiss('close')"><i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form role="form" [formGroup]="createTopicForm">
    <div class="row">
      <div class="col-md-12">

        <div class="form-group">
          <label>{{'topic.input_title' | translate}}</label><span class="text-danger"> *</span>
          <div>
            <input type="text" class="form-control" placeholder="{{'topic.name' | translate}}" formControlName="name" [(ngModel)]="topic.name">
          </div>
          <div *ngIf="createTopicForm.get('name')!.invalid && (createTopicForm.get('name')!.dirty || createTopicForm.get('name')!.touched)">
            <small class="text-danger" *ngIf="!createTopicForm.get('name').value || createTopicForm.get('name')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
            <small class="text-danger" *ngIf="createTopicForm.get('name')?.errors && !createTopicForm.get('name')?.errors?.required">
              {{ 'error.validate.course.nameMaxlength' | translate }}
            </small>
            <small class="text-danger" *ngIf="createTopicForm.get('name')?.errors?.isBlank">
              {{ 'error.validate.input_required' | translate }}
            </small>
          </div>
        </div>
         </div>
    </div>
  </form>
</div>
<div class="modal-footer text-center">
  <div>
    <button type="button" class="btn ml-1 btn-secondary rounded-0 mr-1"
            (click)="activeModal.dismiss('close')">{{ 'common.cancel' | translate }}</button>
    <button type="submit" [disabled]="!createTopicForm.value.name" class="btn ml-1 btn-primary rounded-0" (click)="createOrUpdateTopic()">
      {{  button | translate }}
    </button>
  </div>
</div>

