<div class="row">
  <div class="col-8">
    <div class="form-inline"><p class="teacher mb-0"><strong>Loại: </strong>Bigbluebutton</p></div>
    <div *ngIf="courseId===undefined && moduleInput===undefined" style="color: red">Bạn cần chọn chủ đề để tạo bài giảng
      trước bằng cách nhấn vào chủ đề đó!
    </div>
  </div>
  <div class="col-4" *ngIf="!courseReadOnly && !courseFinished">
    <div class="float-right">
      <button *ngIf="!isNew" type="button" matTooltip="Chỉnh sửa" matTooltipClass="tooltip-red"
              (click)="showEdit()" class="btn btn-default btn-lg mr-4"><i class="fas fa-edit"></i></button>
    </div>
  </div>
</div>

<div class="row mt-3" *ngIf="!isCreateOrEdit">
  <div class="col-11">
    <h5>{{courseModule?.name}}</h5>

  </div>
  <div class="col-11 mt-3">
    <small class="text-dark">Giảng viên:
      <span matTooltip="{{teachers}}" matTooltipClass="tooltip-inner">{{teachers?.length > 100 ? (teachers | slice:0:100) : teachers}}</span>
    </small>
  </div>
  <div class="col-11 mt-3">
    <small class="text-dark">Thời lượng (phút): {{courseModule?.timelimit / 60}}</small>
  </div>
  <br>

  <div class="col-11 mt-3" >Miêu tả: </div>
  <div class="col-11 mt-3">
    <div [innerHTML]="courseModule?.intro"></div>
  </div>
  <div class="col-11 mt-3">Nội dung: </div>
  <div class="col-11 mt-3">
    <p *ngIf="openingtime">Thời gian bắt đầu: {{openingtime| date:'HH:mm dd/MM/yyyy'}}</p>
    <p *ngIf="closingtime">Thời gian kết thúc: {{closingtime| date:'HH:mm dd/MM/yyyy'}}</p>
    <button type="button" data-toggle="modal" *ngIf="!courseReadOnly"
            class="btn btn-primary "
            (click)="joinMeeting()">Tham gia bài giảng</button>
  </div>
  <!-- bbb recording bbbRecordings.length default = 0 -->
  <div class="col-11 mt-3">Bài giảng đã ghi: {{bbbRecordings.length}} </div>
  <div class="col-12 mt-3">

    <table class="table table-hover">
      <thead>
      <tr>
        <th>{{'bbbrecording.title_header_no' | translate}}</th>
        <th>{{'bbbrecording.title_header_playback' | translate}} </th>
        <th>{{'bbbrecording.title_header_name' | translate}} </th>
        <th>{{'bbbrecording.title_header_description' | translate}}</th>
        <th>{{'bbbrecording.title_header_preview' | translate}}</th>
        <th>{{'bbbrecording.title_header_date' | translate}}</th>
        <th>{{'bbbrecording.title_header_duraion' | translate}}</th>
        <th>{{'common.action' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let record of bbbRecordings; let i = index;">
        <td>{{i + 1}}</td>
        <td>
          <span matTooltip="" matTooltipClass="tooltip-inner">
            <a [href]="record.playbackurl" target="_blank">{{'bbbrecording.button_presentation' | translate}}</a>
          </span>
        </td>
        <td>
          <span matTooltip="{{record.name}}" matTooltipClass="tooltip-inner">
            {{record.name}}
          </span>
        </td>
        <td>
          <span matTooltip="{{record.description}}" matTooltipClass="tooltip-inner">
            {{record.description}}
          </span>
        </td>
        <td>
            <img *ngFor="let previewimg of record.preview"
                 width="{{previewimg.width/1.5}}" height="{{previewimg.height/1.5}}"
                 class="zoom"
                 src="{{previewimg?.url}}" alt="{{previewimg?.alt}}">
        </td>
        <td>
          <span matTooltip="{{record.date | date:'HH:mm dd/MM/yyyy'}}" matTooltipClass="tooltip-inner">
            {{record.date | date:'HH:mm dd/MM/yyyy'}}
          </span>
        </td>
        <td>
          <span matTooltip="{{record.duration}}" matTooltipClass="tooltip-inner">
            {{record.duration}}
          </span>
        </td>
        <td>
          <button type="button" matTooltip="{{'common.delete' | translate}}"
                  (click)="onDeleteRecord(record.recordid)"
                  matTooltipClass="tooltip-red"
                  class="btn btn-default btn-sm mr-1">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>



  </div>
</div>


<form role="form" [formGroup]="formModule" (ngSubmit)="submitForm()" *ngIf="isCreateOrEdit">
  <div class="row mt-3">
    <div class="col-3 text-right mb-1"><p>Tên bài giảng <span class="text-danger">*</span></p></div>
    <div class="col-8">
      <input
             type="text"
             class="form-control"
             formControlName="name"
             (change)="onDataChange()"
             placeholder="Nhập tên bài giảng..."/>
      <div *ngIf="formModule.get('name')!.invalid &&(formModule.get('name')!.dirty || formModule.get('name')!.touched)">
        <small class="text-danger" *ngIf="formModule.get('name')?.errors?.required">
          {{ 'error.validate.input_required' | translate }}
        </small>
        <small class="text-danger" *ngIf="formModule.get('name')?.errors && !formModule.get('name')?.errors?.required">
          {{ 'error.validate.course.nameMaxlength' | translate }}
        </small>
      </div>
    </div>
    <div class="col-3 text-right mb-1"><p>Thời lượng (phút)<span class="text-danger">*</span></p></div>
    <div class="col-8">
      <input type="text" (keypress)="numericOnly($event)" [maxLength]="4"
             class="form-control" formControlName="timelimit" (change)="onDataChange()"
             placeholder="Nhập thời lượng bài giảng..."/>
      <div
        *ngIf="formModule?.get('timelimit')!.invalid
        &&(formModule?.get('timelimit')!.dirty
        || formModule?.get('timelimit')!.touched)">
        <small class="text-danger" *ngIf="formModule.get('timelimit')?.errors?.required">
          {{ 'error.validate.input_required' | translate }}
        </small>
      </div>
    </div>
    <div class="col-3 mt-3 text-right mb-1">Nhập miêu tả <span class="text-danger">*</span></div>
    <div class="col-8 mt-3">
      <ckeditor [editor]="Editor" formControlName="description" (change)="onDataChange()" [config]="editorConfig"></ckeditor>
      <small class="text-danger"
             *ngIf="formModule?.get('description')!.invalid
             &&(formModule?.get('description')!.dirty
             || formModule?.get('description')!.touched)">
        {{ 'error.validate.input_required' | translate }}
      </small>
    </div>

    <!-- -------------------------------------------------------- -->

    <!-- start container open date -->
    <div class="col-3 mt-1 text-right mb-1">Thời gian bắt đầu</div>
    <div class="col-8 mt-1">
      <div class="form-inline">
        <div class="form-group mr-2">
          <select class="form-control" formControlName="houropeningtime" >
            <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
          </select>
        </div>
        <div class="form-group mr-2">
          <select class="form-control" formControlName="minuteopeningtime" >
            <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
          </select>
        </div>
        <div class="form-group mr-2">
          <mat-form-field>
            <mat-label>Chọn ngày</mat-label>
            <input matInput readonly formControlName="openingtime"
                   (ngModelChange)="setMinEndDate($event)"
                   [matDatepicker]="openingtime" [min]="dtpCourseStartDate" [max]="dtpCourseEndDate">
            <mat-datepicker-toggle matSuffix [for]="openingtime">
            </mat-datepicker-toggle>
            <mat-datepicker #openingtime></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-group mr-2">
          <input type="button" value="Mặc định" class="btn btn-default" (click)="setDefaultStartDate()">
        </div>
      </div>
      <!-- error message -->

      <div class="col-8 mt-1" *ngIf="formModule.hasError('startDateLessThanCourseStartDate')">
        <small class="text-danger">
          {{ 'error.timeCourseStart' | translate }}
        </small>
      </div>

      <div class="col-8 mt-1" *ngIf="formModule.hasError('startDateGreaterThanCourseEndDate')">
        <small class="text-danger">
          {{ 'error.timeCourseStart2' | translate }}
        </small>
      </div>
    </div>

    <!-- end container open date -->

    <!-- start container close date -->
    <div class="col-3 mt-1 text-right mb-1">Thời gian kết thúc</div>
    <div class="col-8 mt-1">
      <div class="form-inline">
        <div class="form-group mr-2">
          <select class="form-control" formControlName="hourclosingtime">
            <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
          </select>
        </div>
        <div class="form-group mr-2">
          <select class="form-control" formControlName="minuteclosingtime">
            <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
          </select>
        </div>
        <div class="form-group mr-2">
          <mat-form-field>
            <mat-label>Chọn ngày</mat-label>
            <input matInput readonly formControlName="closingtime"
                   [matDatepicker]="closingtime"
                   [min]="minCloseDate" [max]="dtpCourseEndDate">
            <mat-datepicker-toggle matSuffix [for]="closingtime">
            </mat-datepicker-toggle>
            <mat-datepicker #closingtime ></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-group mr-2">
          <input type="button" value="Mặc định" class="btn btn-default" (click)="setDefaultEndDate()">
        </div>
      </div>

      <!-- error message -->

      <div class="col-8 mt-1" *ngIf="formModule.hasError('endDateLessThanCourseStartDate')">
        <small class="text-danger">
          {{ 'error.timeCourseEnd2' | translate }}
        </small>
      </div>

      <div class="col-8 mt-1" *ngIf="formModule.hasError('endDateGreaterThanCourseEndDate')">
        <small class="text-danger">
          {{ 'error.timeCourseEnd' | translate }}
        </small>
      </div>

      <div class="col-8 mt-1" *ngIf="formModule.hasError('endDateLessThanStartDate')">
        <small class="text-danger">
          {{ 'error.timeCourseEnd3' | translate }}
        </small>
      </div>

    </div>

    <!-- end container close date -->

    <!-- -------------------------------------------------------  -->
    <div class="col-12 mt-3 text-center">
      <button type="button" *ngIf="!isNew" (click)="cancel()" class="btn btn-default round-0 mr-2">
        <i class="fas fa-close"></i>{{ 'common.back' | translate}}
      </button>
      <button type="submit" class="btn btn-primary "><i
        class="fas fa-save mr-1"></i>Lưu lại
      </button>
    </div>
  </div>

</form>
