import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {TopicService} from '../../../shared/services/topic.service';
import {CustomValidators} from '../../../shared/utils/custom-validators';


@Component({
  selector: 'app-topic-detail',
  templateUrl: './topic-detail.component.html',
  styleUrls: ['./topic-detail.component.css']
})
export class TopicDetailComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private topicService: TopicService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private toarstService: ToastrService,
    private toarstCustomService: ToastrCustomService,
  ) { }

  @Input() topic: any;
  @Input() title: string;
  @Input() button;

  @Output() newTopic = new EventEmitter();

  createTopicForm = this.fb.group({
    name: ['', [Validators.required, CustomValidators.notBlankValidator, Validators.maxLength(200)]]
  });

  ngOnInit(): void {
  }

  createOrUpdateTopic(){
    if (this.createTopicForm.value.name) {
      this.spinner.show();
      if (this.topic.topicId){
        this.topicService.editTopic(this.topic).subscribe(res => {
          const topic = res.body;
          this.newTopic.emit(topic);
          this.toarstService.success(this.translateService.instant('common.noti.update_success'));
        },
        err => {
          this.toarstCustomService.handlerError(err);
        });
        this.close();
      } else {
        this.topicService.createTopic(this.topic).subscribe(res => {
          const topic = res.body;
          this.newTopic.emit(topic);
          this.toarstService.success(this.translateService.instant('common.noti.create_success'));
        },
        err => {
          this.toarstCustomService.handlerError(err);
        });
        this.close();
      }
    } else {

    }
  }

  close(){
    this.spinner.hide();
    this.activeModal.dismiss('close');
  }

}
