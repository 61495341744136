<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center">
  <div class="col-row">
    <div class="col-12 text-center">
      <img src="../../../../assets/end-user/img/dk_tc.png" style="width:50%;"  alt="">
      <p>Bạn đăng ký khóa học thành công. Khóa học đã được thêm vào <strong>KHÓA HỌC CỦA TÔI</strong>. <br> Bạn có muốn tham gia vào khóa học này ngay bây giờ?</p>
    </div>
  </div>
 
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary"
          (click)="activeModal.dismiss('close')">{{ 'common.cancel' | translate }}</button>
  <button type="button" class="btn btn-primary"
          (click)="activeModal.close('confirm')">{{confirmButton}}</button>
</div>
