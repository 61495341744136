<section class="content-header">
  <div class="container-fluid">
  </div><!-- /.container-fluid -->
  <div *ngIf="idModule==null||idModule==undefined" class="card">
    <div class="card-header">
      <h3 class="card-title">
        Tạo thông tin bài thi
      </h3>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <form role="form" [formGroup]="formModule" (ngSubmit)="submitForm()">
        <div class="row pt-3 bg-white pb-3">

          <div class="col-3 text-right mb-1">Tên bài thi <span class="text-danger">*</span></div>
          <div class="col-8">
            <input type="text" formControlName="name" class="form-control" placeholder="Nhập tên bài thi..." required/>
            <div *ngIf="formModule.get('name')!.invalid &&(formModule.get('name')!.dirty || formModule.get('name')!.touched)">
              <small class="text-danger" *ngIf="formModule.get('name')?.errors?.required">
                {{ 'error.validate.input_required' | translate }}
              </small>
              <small class="text-danger" *ngIf="formModule.get('name')?.errors && !formModule.get('name')?.errors?.required">
                {{ 'error.validate.course.nameMaxlength' | translate }}
              </small>
              <small class="text-danger" *ngIf="formModule.get('name')?.errors?.isBlank">
                {{ 'error.validate.input_required' | translate }}
              </small>
            </div>
          </div>

          <div class="col-3 mt-3 text-right mb-1">Nhập miêu tả <span class="text-danger">*</span></div>
          <div class="col-8 mt-3">
            <ckeditor [editor]="Editor" formControlName="description" [config]="editorConfig"></ckeditor>
            <div *ngIf="formModule.get('description')!.invalid
                &&(formModule.get('description')!.dirty || formModule.get('description')!.touched)">
              <small class="text-danger" *ngIf="formModule.get('description')?.errors?.required">
                {{ 'error.validate.input_required' | translate }}
              </small>
            </div>
          </div>

          <div class="col-3 mt-1 text-right mb-1">Thời gian bắt đầu</div>
          <div class="col-8 mt-1">
            <div class="form-inline">
              <div class="form-group mr-2">
                <select  class="custom-select disabled" formControlName="houropeningtime">
                  <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
                </select>
              </div>
              <div class="form-group mr-2">
                <select class="custom-select disabled" formControlName="minuteopeningtime">
                  <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
                </select>
              </div>
              <div class="form-group mr-2">
                <mat-form-field>
                  <input matInput readonly
                         [min]="startDateExamDate" [max]="endDateExamDate" (ngModelChange)="setMinEndDate($event)"
                         [matDatepicker]="picker1" formControlName="openingtime">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group mr-2">
                <input type="button" value="Mặc định" class="btn btn-default" (click)="setDefaultStartDate()">
              </div>
            </div>

            <div class="col-8 mt-1" *ngIf="formModule.hasError('startDateLessThanCourseStartDate')">
              <small class="text-danger">
                {{ 'error.timeExamStart' | translate }}
              </small>
            </div>

            <div class="col-8 mt-1" *ngIf="formModule.hasError('startDateGreaterThanCourseEndDate')">
              <small class="text-danger">
                {{ 'error.timeExamStart2' | translate }}
              </small>
            </div>

          </div>

          <div class="col-3 mt-1 text-right mb-1">Thời gian kết thúc</div>
          <div class="col-8 mt-1">
            <div class="form-inline">
              <div class="form-group mr-2">
                <select class="custom-select" formControlName="hourclosingtime">
                  <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
                </select>
              </div>
              <div class="form-group mr-2">
                <select class="custom-select" formControlName="minuteclosingtime">
                  <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
                </select>
              </div>
              <div class="form-group mr-2">
                <mat-form-field>
                  <input  matInput readonly
                          [max]="endDateExamDate" [min]="minCloseDate"
                          [matDatepicker]="picker2" formControlName="closingtime">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group mr-2">
                <input type="button" value="Mặc định" class="btn btn-default" (click)="setDefaultEndDate()">
              </div>
            </div>

            <div class="col-8 mt-1" *ngIf="formModule.hasError('endDateLessThanCourseStartDate')">
              <small class="text-danger">
                {{ 'error.timeExamEnd2' | translate }}
              </small>
            </div>

            <div class="col-8 mt-1" *ngIf="formModule.hasError('endDateGreaterThanCourseEndDate')">
              <small class="text-danger">
                {{ 'error.timeExamEnd' | translate }}
              </small>
            </div>

            <div class="col-8 mt-1" *ngIf="formModule.hasError('endDateLessThanStartDate')">
              <small class="text-danger">
                {{ 'error.timeExamEnd3' | translate }}
              </small>
            </div>

          </div>

          <div class="col-3 mt-3 text-right mb-1">Thời gian làm bài (Phút) <span class="text-danger">*</span></div>
          <div class="col-9 mt-3">
            <div class="row">
              <div class="col-md-1">
                <input type="number" formControlName="timelimit" class="form-control"
                       min="0" (keypress)="numericOnly($event)">
              </div>
              <div class="col-md-11">
                <div *ngIf="formModule.get('timelimit')!.invalid
                            && (formModule.get('timelimit')!.dirty || formModule.get('timelimit')!.touched)">
                  <small class="text-danger" *ngIf="formModule.get('timelimit')?.errors?.required">
                    {{ 'error.validate.input_required' | translate }}
                  </small>
                  <small class="text-danger" *ngIf="formModule.get('timelimit')?.errors?.invalidNumber">
                    {{ 'error.validate.number' | translate }}
                  </small>
                  <small class="text-danger" *ngIf="formModule.get('timelimit')?.errors?.rangeInvalid">
                    {{ 'error.validate.quiz.maxlengthGrade' | translate }}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="col-3 mt-3 text-right mb-1">Kết thúc bài thi <span class="text-danger">*</span></div>
          <div class="col-7 mt-3">
            <select formControlName="overduehandling" class="custom-select">
              <option value="{{OVER_DUE_HANDLING_2[0].key | translate}}">{{OVER_DUE_HANDLING_2[0].value | translate}}</option>
            </select>
            <small class="text-danger" *ngIf="formModule.get('overduehandling')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
          </div>

          <div class="col-3 mt-3 text-right mb-1">Điểm chuẩn bài thi <span class="text-danger">*</span></div>
          <div class="col-7 mt-3">
            <input type="number" formControlName="gradepass" class="form-control" (keypress)="numericOnly($event)" min="0" (ngModelChange)="onChangGradepass($event)">
            <small class="text-danger" *ngIf="formModule.get('gradepass')?.errors?.gradepassError">
              {{ 'error.validate.quiz.gradepassError' | translate }}
            </small>
            <div *ngIf="formModule.get('gradepass')!.invalid &&(formModule.get('gradepass')!.dirty || formModule.get('gradepass')!.touched)">
              <small class="text-danger" *ngIf="formModule.get('gradepass')?.errors?.required">
                {{ 'error.validate.input_required' | translate }}
              </small>
              <small class="text-danger" *ngIf="formModule.get('gradepass')?.errors?.invalidNumber">
                {{ 'error.validate.number' | translate }}
              </small>
            </div>
          </div>

          <div class="col-3 mt-3 text-right mb-1">Thang điểm bài thi<span class="text-danger">*</span> </div>
          <div class="col-7 mt-3">
            <input formControlName="grade" type="number" class="form-control " name="grade" (keypress)="numericOnly($event)"
                   min="0" (ngModelChange)="onChangGrade($event)">
            <div *ngIf="formModule.get('grade')!.invalid &&(formModule.get('grade')!.dirty || formModule.get('grade')!.touched)">
              <small class="text-danger" *ngIf="formModule.get('grade')?.errors?.required">
                {{ 'error.validate.input_required' | translate }}
              </small>
              <small class="text-danger" *ngIf="formModule.get('grade')?.errors?.invalidNumber">
                {{ 'error.validate.positive_integer' | translate }}
              </small>
              <small class="text-danger" *ngIf="formModule.get('grade')?.errors?.rangeInvalid">
                {{ 'error.validate.quiz.maxlengthGrade' | translate }}
              </small>
            </div>
          </div>

          <div class="col-3 mt-3 text-right mb-1">Số lần tham gia <span class="text-danger">*</span></div>
          <div class="col-7 mt-3">
            <select formControlName="attempts" class="custom-select" name="attempts">
              <option [value]="attemp.value" *ngFor="let attemp of EXAM_ATTEMPS; let i = index;">{{attemp.lable}}</option>
            </select>
            <div *ngIf="formModule.get('attempts')?.errors?.required">
              <small class="text-danger" *ngIf="formModule.get('attempts')?.errors?.required">
                {{ 'error.validate.input_required' | translate }}
              </small>
            </div>
          </div>

          <div class="col-3 mt-3 text-right mb-1">Phương pháp chấm điểm <span class="text-danger">*</span></div>
          <div class="col-7 mt-3">
            <select class="custom-select" formControlName="grademethod">
              <option *ngFor="let item of GRADE_METHOD_2"
                      value="{{item.key | translate}}">
                {{item.value | translate}}
              </option>
            </select>
            <div *ngIf="formModule.get('grademethod')?.errors?.required">
              <small class="text-danger" *ngIf="formModule.get('grademethod')?.errors?.required">
                {{ 'error.validate.input_required' | translate }}
              </small>
            </div>
          </div>

          <div class="col-12 mt-3 text-center">
            <button type="button" class="btn btn-default round-0 mr-2" (click)="back()"><i class="fas fa-close"></i>Quay lại
            </button>
            <button type="submit"
                    [disabled]="formModule.invalid" class="btn btn-primary">
              <i class="fas fa-save mr-1"></i>Lưu lại</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="idModule!==null&&idModule!==undefined" class="card">
    <div class="card-header">
      <h3 class="card-title">
        Thông tin bài thi
      </h3>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row pt-3 bg-white pb-3">
        <div class="col-3 text-right mb-1">Loại bài thi</div>
        <div class="col-8">
          Bài thi
        </div>
        <div class="col-3 text-right mb-1">Tên bài thi</div>
        <div class="col-8">
          {{module.name}}
        </div>
        <div class="col-3 mt-3 text-right mb-1">Nhập miêu tả</div>
        <div class="col-8 mt-3">
          <p [innerHTML]="module.intro"></p>
        </div>
        <div class="col-3 mt-1 text-right mb-1">Thời gian bắt đầu</div>
        <div class="col-8 mt-1">
          <div *ngIf="module.timeopen!==0" class="form-group mr-2">
            {{getDateFromDatetime(module.timeopen) | date:'HH:mm dd/MM/yyyy'}}
          </div>
          <div *ngIf="module.timeopen==0" class="form-group mr-2">
            Không giới hạn
          </div>
        </div>
        <div class="col-3 mt-1 text-right mb-1">Thời gian kết thúc</div>
        <div class="col-8 mt-1">
          <div *ngIf="module.timeclose!==0" class="form-group mr-2">
            {{getDateFromDatetime(module.timeclose) | date:'HH:mm dd/MM/yyyy'}}
          </div>
          <div *ngIf="module.timeclose==0" class="form-group mr-2">
            Không giới hạn
          </div>
        </div>
        <div class="col-3 mt-3 text-right mb-1">Thời gian làm bài</div>
        <div class="col-9 mt-3">
          <div class="row">
            <div class="col-md-1">
              {{module.timelimit / 60}} Phút
            </div>
            <div class="col-md-2">
            </div>
          </div>
        </div>
        <div class="col-3 mt-3 text-right mb-1">Kết thúc bài thi</div>
        <div class="col-7 mt-3">
          {{getOverDueHandlingValueByKey(module.overduehandling) | translate}}
        </div>
        <div class="col-3 mt-3 text-right mb-1">Điểm chuẩn bài thi</div>
        <div class="col-7 mt-3">
          {{module.gradepass}}
        </div>
        <div class="col-3 mt-3 text-right mb-1">Thang điểm bài thi</div>
        <div class="col-7 mt-3">
          {{module.grade}}
        </div>
        <div class="col-3 mt-3 text-right mb-1">Số lần tham gia</div>
        <div class="col-7 mt-3">
          {{module.attempts === 0 ? 'Không giới hạn' : module.attempts}}
        </div>
        <div class="col-3 mt-3 text-right mb-1">Phương pháp chấm điểm</div>
        <div class="col-7 mt-3">
          {{getGradepassValueByKey(module.grademethod) | translate}}
        </div>
        <div class="col-12 mt-3 text-center">
          <button type="button" class="btn btn-default round-0 mr-2" (click)="back()"><i class="fas fa-close"></i>Quay
            lại
          </button>
          <button *ngIf="examDetail?.published !== 1 && !examDetail?.readonly" type="submit" class="btn btn-primary" (click)="edit()"><i
            class="fas fa-save mr-1"></i>Chỉnh sửa
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-default mb-5" *ngIf="quizInput?.id || idModule">
    <div class="card-header">
      <h3 class="card-title">
        Tạo nội dung bài thi
      </h3>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
    <div class="card-body ">
      <div class="row">
        <div class="container">
          <div class="btn-sticky shadow" *ngIf="!examDetail?.readonly && !quizInput?.quizhasstudent">
            <div aria-label="Vertical button group" role="group" class="btn-group-vertical">
              <button [disabled]="examDetail?.published === 1" type="button" matTooltip="Chọn từ thư viện"
                      matTooltipClass="tooltip-red"
                      (click)="openLibaryCreatePopup()" class="btn btn-default btn-lg mb-2"><i
                class="fas fa-plus"></i></button>
              <button [disabled]="examDetail?.published === 1" type="button" matTooltip="Chọn câu hỏi ngẫu nhiên"
                      (click)="openRandomCreateQuestionPopup()"
                      matTooltipClass="tooltip-red"
                      class="btn btn-default btn-lg"><i class="fas fa-random"></i></button>
              <button [disabled]="examDetail?.published === 1" type="button" matTooltip="Một lựa chọn"
                      (click)="addQuestion('multichoice')" matTooltipClass="tooltip-red"
                      class="btn btn-default btn-lg"><i class="fas fa-check"></i></button>
              <button [disabled]="examDetail?.published === 1" type="button" matTooltip="Nhiều lựa chọn"
                      (click)="addQuestion('multichoiceset')" matTooltipClass="tooltip-red"
                      class="btn btn-default btn-lg"><i class="fas fa-check-double"></i></button>
              <button [disabled]="examDetail?.published === 1" type="button" matTooltip="Đúng sai"
                      (click)="addQuestion('truefalse')" matTooltipClass="tooltip-red"
                      class="btn btn-default btn-lg"><i class="fa fa-cubes"></i></button>
            </div>

          </div>
          <div class="row p-4 d-flex">
            <div class="col-12 align-self-center p-2">
              <br>
              <br>
              <br>
              <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
                <mat-expansion-panel *ngFor="let panel of accordions; let i = index"
                                     (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="right-aligned-header">{{ panel.title }}</mat-panel-title>
                    <mat-icon class="mr-1 icon_drag">swap_vert</mat-icon>
                    <mat-panel-description>{{ panel.description }}</mat-panel-description>
                  </mat-expansion-panel-header>


                  <ng-container *ngIf="panel.type == 'multichoice'">
                    <app-multichoice
                      [data]="{
                        question: panel.question,
                        canEdit: (!examDetail?.readonly && !quizInput?.quizhasstudent),
                        isPublished: (examDetail?.published === 1),
                        moduleId: quizId,
                        categoryId: (this.examDetail?.categoryid),
                        index: i
                       }"
                      (deleteQuestionEvent)="onRemoveQuestion($event)"
                      (cloneQuestion)="addQuestion(panel.type, panel.index, $event.value, $event.isViewMode)"
                      [index]="panel.index" [questionInput]="panel.question">

                    </app-multichoice>
                  </ng-container>
                  <ng-container *ngIf="panel.type == 'multichoiceset'">
                    <app-multichoiceset
                      [data]="{
                        question: panel.question,
                        canEdit: (!examDetail?.readonly && !quizInput?.quizhasstudent),
                        isPublished: (examDetail?.published === 1),
                        moduleId: quizId,
                        categoryId: (this.examDetail?.categoryid),
                        index: i
                       }"
                      (deleteQuestionEvent)="onRemoveQuestion($event)"
                      (cloneQuestion)="addQuestion(panel.type, panel.index, $event.value, $event.isViewMode)"
                      [index]="panel.index" [questionInput]="panel.question">

                    </app-multichoiceset>
                  </ng-container>

                  <ng-container *ngIf="panel.type == 'truefalse'">
                    <app-truefalse
                      input="panel"
                      [data]="{
                        question: panel.question,
                        canEdit: (!examDetail?.readonly && !quizInput?.quizhasstudent),
                        isPublished: (examDetail?.published === 1),
                        moduleId: quizId,
                        categoryId: (this.examDetail?.categoryid),
                        index: i
                       }"
                      (deleteQuestionEvent)="onRemoveQuestion($event)"
                      (cloneQuestion)="addQuestion(panel.type, panel.index, $event.value, $event.isViewMode)"
                      [index]="panel.index" [panel]="panel">

                    </app-truefalse>
                  </ng-container>

                  <ng-container *ngIf="panel.type == 'random'">
                    <app-random-question
                      input="panel"
                      [data]="{
                        question: panel.question,
                        canEdit: (!examDetail?.readonly && !quizInput?.quizhasstudent),
                        isPublished: (examDetail?.published === 1),
                        moduleId: quizId,
                        categoryId: (this.examDetail?.categoryid),
                        index: i
                       }"
                      (deleteQuestionEvent)="onRemoveQuestion($event)"
                      (cloneQuestion)="addQuestion(panel.type, panel.index, $event.value, $event.isViewMode)"
                      [index]="panel.index" [panel]="panel">

                    </app-random-question>
                  </ng-container>


<!--                  <ng-container *ngIf="panel.type == 'multichoice'">-->
<!--                    <app-multichoice [index]="panel.index" [questionInput]="panel.question"-->
<!--                                     (editQuestionIndex)="editQuestion($event)"-->
<!--                                     (messageEvent)="receiveMulti($event)"></app-multichoice>-->
<!--                  </ng-container>-->
<!--                  <ng-container *ngIf="panel.type == 'multichoiceset'">-->
<!--                    <app-multichoiceset [index]="panel.index" [questionInput]="panel.question"-->
<!--                                     (editQuestionIndex)="editQuestion($event)"-->
<!--                                     (messageEvent)="receiveMultiset($event)"></app-multichoiceset>-->
<!--                  </ng-container>-->
<!--                  <ng-container *ngIf="panel.type == 'truefalse'">-->
<!--                    <app-truefalse input="panel" [index]="panel.index" [panel]="panel"-->
<!--                                   (editQuestionIndex)="editQuestion($event)" [questionInput]="panel.question"-->
<!--                                   (messageEvent)="receiveTrueFalse($event)"></app-truefalse>-->
<!--                  </ng-container>-->
<!--                  <mat-action-row *ngIf="!examDetail?.readonly && !quizInput?.quizhasstudent">-->
<!--                    <button [disabled]="examDetail?.published === 1" type="button" matTooltip="Sao chép"-->
<!--                            (click)="addQuestion(panel.type, panel.index, panel.question)"-->
<!--                            matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i class="fas fa-copy"></i>-->
<!--                    </button>-->
<!--                    <button [disabled]="examDetail?.published === 1" type="button" matTooltip="Chỉnh sửa" *ngIf="!panel?.enable"-->
<!--                            (click)="dataService.changeMessage(panel.index, panel.type, null)"-->
<!--                            matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i class="fas fa-edit"></i>-->
<!--                    </button>-->
<!--                    <button type="button" matTooltip="Lưu" *ngIf="panel?.enable" (click)="saveQuestions(panel)"-->
<!--                            [disabled]="panel.btnSaveDisable || examDetail?.published === 1"-->
<!--                            matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i-->
<!--                      class="fas fa-save mr-1"></i></button>-->
<!--                    <button [disabled]="examDetail?.published === 1" type="button" matTooltip="Xóa" (click)="removeQuestion(panel)"-->
<!--                            matTooltipClass="tooltip-red"-->
<!--                            class="btn btn-default btn-lg"><i class="fas fa-trash"></i></button>-->
<!--                  </mat-action-row>-->
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
<!--          <div class="row">-->
<!--            <div class="col-12 text-center">-->
<!--              <button type="button" *ngIf="accordions.length > 0" (click)="cancelCreateQues()"-->
<!--                      [disabled]="questionsTrueFalse.length === 0 && questionsMulti.length === 0"-->
<!--                      class="btn btn-default btn-md mr-2"><i class="nav-icon fas fa-close mr-1"></i>Hủy-->
<!--              </button>-->
<!--              <button type="button" *ngIf="accordions.length > 0" (click)="saveQuestions()"-->
<!--                      [disabled]="!saveEnabled || (questionsTrueFalse.length === 0 && questionsMulti.length === 0)"-->
<!--                      class="btn btn-primary btn-md"><i class="nav-icon fas fa-save mr-1"></i>Lưu lại-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</section>

