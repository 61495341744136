import { DepartmentService } from '../../../shared/services/department.service';
import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PositionService } from '../../../shared/services/position.service';

@Component({
  selector: 'app-exam-search',
  templateUrl: './exam-search.component.html',
  styleUrls: ['./exam-search.component.css']
})
export class ExamSearchComponent implements OnInit {

  @Input() searchExam;
  status: any;
  startDate: any;
  endDate: any;
  departmentMap = new Map();
  positionList: any[] = [];
  positionData: number [] = [];
  departmentId: any;
  statusSearchData: any;

  dropdownPositionSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Chọn hết',
    unSelectAllText: 'Bỏ chọn hết',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    searchPlaceholderText: 'Tìm kiếm'
  };

  constructor(
    public activeModal: NgbActiveModal,
    private positionService: PositionService,
    private departmentService: DepartmentService
  ) {}

  ngOnInit(): void {
    this.statusSearchData = [
      {name: 'Chưa hiển thị', id: 1},
      {name: 'Chưa diễn ra', id: 2},
      {name: 'Đang diễn ra', id: 3},
      {name: 'Đã kết thúc', id: 4}
    ];

    if(this.searchExam.status){
      this.status = this.searchExam.status;
    }else{
      this.status = null;
    }

    if(this.searchExam.departmentId){
      this.departmentId = this.searchExam.departmentId;
    }else{
      this.departmentId = null;
    }

    if(this.searchExam.positionData){
      this.positionData = this.searchExam.positionData;
    }else{
      this.positionData = null;
    }

    if (this.searchExam.startDate) {
      this.startDate = moment.unix(this.searchExam.startDate).toDate();
    }
    if (this.searchExam.endDate) {
      this.endDate = moment.unix(this.searchExam.endDate).toDate();
    }

    this.getListPosition();
    this.listAllTree();
  }

  getListPosition() {
    return this.positionService.getPosition().subscribe(positionList => {
      this.positionList = positionList.body.results;
    });
  }

  onSearchExam() {
    if(this.endDate){
      //lay cuoi ngay
      this.endDate.setHours(23);
      this.endDate.setMinutes(59);
      this.endDate.setSeconds(59);
    }
    const searchParams = {
      startDate: this.startDate,
      endDate: this.endDate,
      departmentId: (this.departmentId==="null") ? null :this.departmentId,
      status: (this.status==="null") ? null : this.status,
      positionData: this.positionData,
    }
    this.activeModal.close(searchParams);
  }

  resetSearch() {
    this.startDate = null;
    this.endDate = null;
    this.status = null;
    this.positionData = [];
    this.departmentId = null;
  }

  onDeSelectPosition($event) {
    this.positionData = [...this.positionData]?.filter(id => id !== $event.id);
  }

  onDeAllSelectPosition() {
    this.positionData = [];
  }

  listAllTree() {
    this.departmentService.getUserDepartmentTree(1).subscribe((data) => {
      data.body.forEach(department => {
        if (department.parentid !== null) {
          const parentName = this.departmentMap.get(department.parentid);
          let name = '';
          if (parentName) {
            name = parentName + ' / ' + department.name;
          } else {
            name = department.name;
          }
          this.departmentMap.set(department.id, name);
        } else {
          this.departmentMap.set(department.id, department.name);
        }
      });
    });
  }

}
