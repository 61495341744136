import CommonUtil from 'src/app/shared/utils/common-util';
import {MOODLE_INFO} from '../constants/base.constant';
import {AbstractService} from 'src/app/shared/services/abstract.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private abstractService: AbstractService) {
  }

  reportCourseInSystem(courseType:any,categoryid?: number): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_report_course_in_system');
    CommonUtil.appendDataToFormData(data, 'params', {categoryid: categoryid,coursetype:courseType});
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  reportExportCourseInSystem(courseType:any,categoryid?: number): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_report_export_course_in_system');
    CommonUtil.appendDataToFormData(data, 'params', {categoryid: categoryid,coursetype:courseType});
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  reportCourseOrgan(params: any): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_report_course_organization');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  reportExportCourseOrgan(courseid: number): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_report_export_course_organization');
    CommonUtil.appendDataToFormData(data, 'params', {courseid: courseid});
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  reportStudentCourse(userid: number,coursetype:any): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_report_course_student');
    CommonUtil.appendDataToFormData(data, 'params', {userid: userid,coursetype:coursetype});
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  getListStudent(param:any): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_report_learning_progress_students');
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  getListStudentByQuiz(params: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_report_quiz_list_user_result');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: HttpResponse<any>) => res));
  }

  getListExamReport(params: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_report_list_exam_result');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: HttpResponse<any>) => res));
  }

  getReportExamByUser(params: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_report_quiz_result_detail');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: HttpResponse<any>) => res));
  }

  exportListExamReport(params: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_report_export_exam_result');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: HttpResponse<any>) => res));
  }

  exportExamReportDetail(params: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_report_export_quiz_result_detail');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: HttpResponse<any>) => res));
  }

  exportLearningProgressStudents(params: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_report_export_learning_progress_students');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: HttpResponse<any>) => res));
  }

  exportLearningProgressDetail(params: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_report_export_learning_progress_student_detail');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: HttpResponse<any>) => res));
  }

  getHistoryLerning(param: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_report_learning_progress_student_detail');
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  overviewSystemData(): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_report_overview_of_system_data');
    // CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }


}
