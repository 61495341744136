<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <title>Evotek| Reset Password</title>
  <!-- Tell the browser to be responsive to screen width -->
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <!-- Ionicons -->
  <link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">
  <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700" rel="stylesheet">

</head>
<body class="hold-transition sidebar-mini layout-fixed login-layout">
<section class="login-block">
  <div class="container mt-5">
    <div class="row">
      <div class="iteamdoan col-md-12 login-sec text-center">
        <img src="../../assets/ladipage/img/doan.png" alt="">
        <h2 class="text-center">Quên mật khẩu ?</h2>
        <span class="fontSize__14">
          <span class="text-danger">*</span>
          Vui lòng nhập Email mà bạn đã đăng ký tham gia cuộc thi. Sau khi nhập Email hãy nhấn Xác nhận. Hệ thống sẽ gửi Email thay đổi mật khẩu tới Email của bạn.
        </span>
        <!-- <div class="row">
          <div class="col-4 text-right mb-1">Nhập</div>
          <div class="col-8">
            <mat-radio-group>
              <mat-radio-button class="example-margin mr-1" [value]="true" (change)="isShowEmailForm($event)" [checked]="true">
                Email
              </mat-radio-button>
              <br/>
              <mat-radio-button class="example-margin" [value]="false" (change)="isShowEmailForm($event)">
                Tên đăng nhập
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div> -->
        <form [formGroup]="emailForm" (ngSubmit)="onSubmitEmailForm()" role="form" *ngIf="showEmailForm">
          <div class="row mt-3">
            <!-- <div class="form-group row">
              <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
              <div class="col-sm-10">
                <input type="password" class="form-control" id="inputPassword" placeholder="Password">
              </div>
            </div> -->
            <!-- <div class="col-4 mt-3 text-right mb-1" style="line-height: 22pt;">
              <p>Email <span class="text-danger">*</span></p>
            </div> -->
            <div class="col-12 mt-3 form-row">
              <label for="inputPassword" class="col-sm-2 col-form-label">Email  <span class="text-danger">*</span></label>
              <div class="col-sm-10">
                <input type="text" class="form-control" formControlName="email"
                [placeholder]="'Nhập email'">
                <div
                  *ngIf="emailForm.get('email')!.invalid && (emailForm.get('email')!.dirty || emailForm.get('email')!.touched)">
                  <small class="text-danger"
                          *ngIf="emailForm.get('email')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                  <small class="text-danger"
                          *ngIf="emailForm.get('email').errors?.pattern">{{ 'error.validate.email.pattern' | translate }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 text-center mt-3">
              <button class="btn btn-sm btn-primary  round-0 mr-2">
                Tiếp tục
              </button>
              <input type="button"
                     class="btn btn-sm btn-default round-0 mr-2" (click)="backToLogin()"
                     value="Quay lại">
            </div>
          </div>
        </form>

        <form [formGroup]="usernameForm" (ngSubmit)="onSubmitUsernameForm()" role="form" *ngIf="!showEmailForm">
          <div class="row mt-3">
            <!-- <div class="col-4 mt-3 text-right mb-1">
              <p>Tên đăng nhập <span class="text-danger">*</span></p>
            </div>
            <div class="col-8 mt-3">
              <input type="text" class="form-control" formControlName="username"
                     [placeholder]="'Nhập tên đăng nhập'">
              <div
                *ngIf="usernameForm.get('username')!.invalid && (usernameForm.get('username')!.dirty || usernameForm.get('username')!.touched)">
                <small class="text-danger"
                       *ngIf="usernameForm.get('username')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
              </div>
            </div> -->
            <div class="col-12 mt-3">
              <button class="btn  btn-primary  round-0 mr-2">
                Tiếp tục
              </button>

              <button type="button"
              class="btn  btn-default  round-0 mr-2" (click)="backToLogin()"
              >Quay lại
            </button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
</body>
</html>
