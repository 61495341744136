import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnRegisterCourseConfirmModalComponent } from './../course-card/un-register-course-confirm-modal/un-register-course-confirm-modal.component';
import {PageEvent} from '@angular/material/paginator';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {FileService} from '../../shared/services/file.service';
import * as moment from 'moment';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {CourseService} from '../../shared/services/course.service';
import {DEFAULT_COURSE_IMAGE} from '../../shared/constants/base.constant';

@Component({
  selector: 'app-eu-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css']
})
export class CourseDetailComponent implements OnInit, OnDestroy {

  currentDate: Date = new Date();
  course: any;
  progress: any = 0;
  listTeacher: any = [];
  listOtherStudent: any = [];
  openTab: number;
  sub: any;
  isTeacher = false;
  pageIndex = 1;
  pageSize = 6;
  pageSizeOptions: number[] = [6, 12, 24, 48];
  totalStudent: number;
  permission = true;
  // MatPaginator Output
  pageEvent: PageEvent;
  courseId: number;
  courseImageUrl;


  constructor(
    private activatedRoute: ActivatedRoute,
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrCustomService,
    private courseService: CourseService,
    private fileServide: FileService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.openTab = 1;
    this.sub = this.activatedRoute.paramMap.subscribe(params => {
      this.courseId = +params.get('id');
      this.getCourseDetail();
      this.getListTeacher();
      this.getListStudent();
      if (!this.isTeacher) {
        this.getProgressOfCourse();
      }
    });
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  getCourseDetail() {
    return this.courseService.getCoursesInfoEnduser(this.courseId).subscribe((data) => {
        this.course = data.body;

        this.isTeacher = this.course.roleincourse === 'teacher';

        if (this.course.filename) {
          const urlFile = this.fileService.getFileUrl(this.course.contextid, this.course.filename, '/course/overviewfiles/');
          this.courseImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
        } else {
          this.courseImageUrl = DEFAULT_COURSE_IMAGE;
        }
      },
      (err) => {
        this.permission = false;
        // this.toastrService.handlerError(err);
      });
  }

  getListTeacher() {
    const param = {
      courseid: this.courseId,
      useris: 'teacher',
      sortcolumn: 'roleshortnameincourse',
      sorttype: 'desc',
      page: this.pageIndex,
      limit: this.pageSize
    };

    return this.courseService.getListUser(param).subscribe((data) => {
      this.listTeacher = data.body;
      this.listTeacher.results.forEach(teacher => {
        if (teacher.pictureid){
          teacher.userpictureurl = this.fileServide.getFileUrlWithRevision(
            teacher.contextid, teacher.picturename, '/user/icon/boost/', teacher.pictureid
          );
        }
      });
    }, err => {
      // this.toastrService.handlerError(err);
    });
  }

  changePage($event) {
    this.pageIndex = $event.pageIndex + 1;
    this.pageSize = $event.pageSize;
    this.getListStudent();
  }

  getListStudent() {
    const param = {
      courseid: this.courseId,
      useris: 'student',
      sortcolumn: 'roleshortnameincourse',
      sorttype: 'desc',
      page: this.pageIndex,
      limit: this.pageSize
    };

    return this.courseService.getListUser(param).subscribe((data) => {
      this.listOtherStudent = data.body.results;
      this.listOtherStudent.forEach(student => {
        if (student.pictureid){
          student.userpictureurl = this.fileServide.getFileUrlWithRevision(
            student.contextid, student.picturename, '/user/icon/boost/', student.pictureid
          );
        }
      });
      this.totalStudent = data.body.total;
    }, err => {
      // this.toastrService.handlerError(err);
    });
  }

  getProgressOfCourse() {
    return this.courseService.getProgress(this.courseId).subscribe((data) => {
      this.progress = data.body.percentcourse;
    });
  }


  onUnRegisterCourse() {
    const modalDep = this.modalService.open(UnRegisterCourseConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.spinner.show();
        this.courseService.userUnRegisterCourse(this.course?.id).subscribe(
          res => {
            this.spinner.hide();
            this.toastrService.success(`common.noti.unresgister_course_success`);
            this.router.navigate(['/my-course']);
          }, err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          }
        )
      }
    });
  }


}
