<div class="modal-header">
  <h5 class="modal-title">Thông tin khóa học</h5>
  <button type="button" class="btn" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('close')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">

  <div class="container">
    <div class="row">
      <div class="col-4">
        <img [src]="courseInfo.img" class="img-fluid w-100 rounded">
      </div>
      <div class="col-8">
        <p><strong class="mr-1">Khóa học:</strong> {{courseInfo.fullname}}</p>
        <p><strong class="mr-1">Chủ đề khóa học:</strong> {{courseInfo.categoryname}}</p>
        <p><strong class="mr-1">Thời gian:</strong> {{courseInfo._startdate | date:'dd/MM/yyyy'}} - {{courseInfo._enddate | date:'dd/MM/yyyy'}}</p>
        <p><strong class="mr-1">Giảng viên:</strong> {{convertTeacherName(courseInfo.teachers)?.length <= 30 ? convertTeacherName(courseInfo.teachers) : (convertTeacherName(courseInfo.teachers)| slice:0:30) + '...' }}</p>
        <p><strong class="mr-1">Mô tả:</strong> {{courseInfo.summary}} </p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary"
          (click)="activeModal.dismiss('close')">{{ 'common.cancel' | translate }}</button>
  <button type="button" class="btn btn-primary"
          (click)="activeModal.close('confirm')">Đăng ký học</button>
</div>
