import {NgxSpinnerService} from 'ngx-spinner';
import {Component, Input, OnInit} from '@angular/core';
import {TopicService} from '../../../shared/services/topic.service';
import {TopicCourseModel} from '../../../shared/model/topic.course.model';
import * as moment from 'moment';
import {CourseService} from '../../../shared/services/course.service';

@Component({
  selector: 'app-eu-course-content',
  templateUrl: './eu-course-content.component.html',
  styleUrls: ['./eu-course-content.component.css']
})
export class EuCourseContentComponent implements OnInit{

  @Input() courseId: number;
  @Input() course: any;

  currentDate: Date = new Date();

  topicContent: TopicCourseModel [] = [];
  listTopicId = [];
  currentTopic: any;

  selectedInfo = {
    currentModuleOrder: 0,
    currentTopicIndex: 0
  };

  section: number;
  tabname = '';
  nameSection: string;
  topicId: number;
  createduser: string;
  createdat: number;
  currentModule: any;

  moduleSelected = 0;
  sectionPanelSelected = 0;
  nextButtonHide = true;

  constructor(
    private spinner: NgxSpinnerService,
    private topicService: TopicService,
    private courseService: CourseService,
  ) {}

  ngOnInit(): void {
    this.getCourseTopic();
  }

  getCourseTopic(search?: string): void {
    this.spinner.show();
    this.topicService.getCourseTopicsEnduser(this.courseId, search).subscribe((data) => {
      this.topicContent = data.body;
      this.topicContent.forEach(element => {
        if (element.modules !== undefined && element.modules !== null) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < element.modules.length; i++) {
            if (element.modules[i]?.modname === 'resource') {
              element.modules[i].mimetype = 'Tài liệu/Video';
            } else if (element.modules[i]?.modname === 'page') {
              element.modules[i].mimetype = 'Nội dung';
            } else if (element.modules[i]?.modname === 'bigbluebuttonbn') {
              element.modules[i].mimetype = 'Bài giảng trực tuyến';
            } else if (element.modules[i]?.modname === 'scorm') {
              element.modules[i].mimetype = 'Scorm';
            }
          }
        }
        this.listTopicId.push(element.id.toString());
      });

      // ẩn nút next bài giảng đi nếu khóa học không có bài giảng nào
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.topicContent.length; i++) {
        if (this.topicContent[i].modules?.length > 0) {
          this.nextButtonHide = false;
          break;
        }
      }

      // nếu khóa học có bài giảng thì auto show bài giảng đầu tiên
      if (!this.nextButtonHide) {
        this.nextModuleSelected();
      }

    });
    this.spinner.hide();
  }

  showTab(module) {
    this.currentModule = module;
    this.tabname = module.modname;
    this.topicContent.forEach((top, currentTopicIndex) => {
      top.modules?.forEach(mod => {
        if (mod.id === module.id) {
          mod.selected = true;
          // Bai giang nao duoc select thi title cua topic cung duoc hien thi
          this.nameSection = top.name;
          // Luu trang thai cua bai giang dang duoc select
          this.selectedInfo.currentModuleOrder = mod.ordernumber;
          this.selectedInfo.currentTopicIndex = currentTopicIndex;
          // Khi click vao 1 bai giang thi topic cung dc select theo
          this.topicId = top.id;
          this.section = top.section;
        } else {
          mod.selected = false;
        }
      });
    });
    if (moment.unix(this.course?.startdate).toDate() < this.currentDate
      && this.currentDate < moment.unix(this.course?.enddate).toDate()
      && module.modname !== 'bigbluebuttonbn' // BBB phai join bai giang moi duoc tinh
    ) {
      this.courseService.setProgress(this.courseId, this.currentModule.id).subscribe(res => {});
    }
  }

  nextModuleSelected() {
    const nextModule = this.findNextModule();
    if (nextModule) {
      this.showTab(nextModule);
    }
  }

  previousModuleSelected() {
    const previousModule = this.findPreviousModule();
    if (previousModule) {
      this.showTab(previousModule);
    }
  }

  private findNextModule(): any {
    if (this.selectedInfo.currentTopicIndex === (this.topicContent.length - 1) &&
      this.selectedInfo.currentModuleOrder === this.topicContent[this.topicContent.length - 1].modules.length) {
      return null;
    }
    let nextModule;
    for (let topicIndex = this.selectedInfo.currentTopicIndex; topicIndex < this.topicContent.length; topicIndex++) {
      // tslint:disable-next-line:prefer-for-of
      for (let moduleIndex = 0; moduleIndex < this.topicContent[topicIndex].modules.length; moduleIndex++) {
        const mod = this.topicContent[topicIndex].modules[moduleIndex];
        // tim bai giang tiep theo trong cung topic
        if (topicIndex === this.selectedInfo.currentTopicIndex) {
          // chua tim thay bai giang nao co ordernumber lon hon ordernumber cua bai giang hien tai
          if (nextModule === undefined) {
            // tim thay bai giang co ordernumber lon hon thi tam thoi gan nextModule = mod
            if (mod.ordernumber > this.selectedInfo.currentModuleOrder) {
              nextModule = mod;
            }
          } else {
            /*
              Tiep tuc tim kiem bai giang co ordernumber lon hon gan nhat voi bai giang hien tai
            */
            if (mod.ordernumber > this.selectedInfo.currentModuleOrder
              && mod.ordernumber < nextModule.ordernumber) {
              nextModule = mod;
            }
          }
        } else {
          /*
            neu chua tim duoc bai giang tiep theo trong cung topic voi bai giang hien tai
            => bai giang hien tai la bai giang cuoi cung cua topic hien tai
            => tim kiem bai giang co ordernumber nho nhat cua topic tiep theo
          */
          if (nextModule === undefined) {
            // Gan bai giang tiep theo la bai giang tim thay dau tien cua topic tiep theo
            nextModule = mod;
          } else {
            // tim kiem bai giang co ordernumber nho nhat cua topic tiep theo
            if (nextModule.ordernumber > mod.ordernumber) {
              nextModule = mod;
            }
          }
        }
      }

      /* Sau moi lan tim kiem o topic => kiem tra xem da tim thay bai giang tiep theo chua
       neu da tim thay thi dung vong lap */
      if (nextModule) {
        break;
      }
    }

    /* Tim kiem thanh cong => tra ve bai giang tiep theo
     Khong thanh cong tra ve undefined */
    return nextModule;
  }


  private findPreviousModule(): any {
    let nextModule;
    for (let topicIndex = this.selectedInfo.currentTopicIndex; topicIndex >= 0; topicIndex--) {
      // tslint:disable-next-line:prefer-for-of
      for (let moduleIndex = 0; moduleIndex < this.topicContent[topicIndex].modules.length; moduleIndex++) {
        const mod = this.topicContent[topicIndex].modules[moduleIndex];
        // tim bai giang truoc do trong cung topic
        if (topicIndex === this.selectedInfo.currentTopicIndex) {
          // chua tim thay bai giang nao co ordernumber nho hon ordernumber cua bai giang hien tai
          if (nextModule === undefined) {
            // tim thay bai giang co ordernumber nho hon thi tam thoi gan nextModule = mod
            if (mod.ordernumber < this.selectedInfo.currentModuleOrder) {
              nextModule = mod;
            }
          } else {
            /*
              Tiep tuc tim kiem bai giang co ordernumber nho hon gan nhat voi bai giang hien tai
            */
            if (mod.ordernumber < this.selectedInfo.currentModuleOrder
              && mod.ordernumber > nextModule.ordernumber) {
              nextModule = mod;
            }
          }
        } else {
          /*
            neu chua tim duoc bai giang truoc do trong cung topic voi bai giang hien tai
            => bai giang hien tai la bai giang dau tien cua topic hien tai
            => tim kiem bai giang co ordernumber lon nha cua topic truoc do
          */
          if (nextModule === undefined) {
            // Gan bai giang tiep theo la bai giang tim thay dau tien cua topic tiep theo
            nextModule = mod;
          } else {
            // tim kiem bai giang co ordernumber lon nhat cua topic tiep theo
            if (nextModule.ordernumber < mod.ordernumber) {
              nextModule = mod;
            }
          }
        }
      }
      /* Sau moi lan tim kiem o topic => kiem tra xem da tim thay bai giang tiep theo chua
       neu da tim thay thi dung vong lap */
      if (nextModule) {
        break;
      }
    }

    /* Tim kiem thanh cong => tra ve bai giang tiep theo
     Khong thanh cong tra ve undefined */
    return nextModule;
  }

}
