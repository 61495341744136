import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrCustomService } from 'src/app/shared/services/toastr-custom.service';
import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import {UserService} from '../../../../shared/services/user.service';
import {Position} from '../../../../shared/model/position.model';
import {PositionService} from '../../../../shared/services/position.service';

@Component({
  selector: 'app-add-single-member',
  templateUrl: './add-single-member.component.html',
})
export class AddSingleMemberComponent implements OnInit {
  @Output() useridsEmit = new EventEmitter();
  totalRecord: any;
  users= new Array();
  positionList: Position[] = [];
  userIds: number[] = [];
  userIdsChecked: number[] = [];
  isCheckAll= false;
  pageSizeOptions = [10,25,50,100];

  searchUser = {
    pageSize: 10,
    pageIndex: 1,
    keyword: ''
  }

  constructor(
    private userService: UserService,
    private positionService: PositionService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getListUser();
    this.getListPosition();
  }

  getListUser() {
    this.spinner.show();
    const params = {
      limit: this.searchUser.pageSize,
      page: this.searchUser.pageIndex,
      keyword: this.searchUser.keyword,
      positionids: [].toString(),
      departmentids: [].toString(),
      acceptsuspendeduser: 0,
    };
    this.userIds = [];
    this.userService.searchUser(params).subscribe((data) => {
      data.body?.results?.forEach(c => {
        if (this.userIdsChecked?.includes(c.id)) {
          c.checked = true;
          this.userIds?.push(c.id);
        }
      });
      if (this.userIds?.length > 0 && (this.userIds?.length === data.body?.results?.length)) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
      this.users = data.body.results;
      this.totalRecord = data.body.total;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastrService.handlerError(err);
    })
  }

  getListPosition() {
    return this.positionService.getPosition().subscribe(positionList => {
      this.positionList = positionList.body.results;
    });
  }

  getPositionNames(user) {
    let positionName = '';
    if (user.positionids) {
      const positionIdArray = user.positionids.split(';');
      for (const positionId of positionIdArray) {
        const foundPosition = this.positionList.find(value => value.id === +positionId);
        if (positionName.length > 0) {
          positionName += ',';
        }
        if (foundPosition && foundPosition.name !== undefined) {
          positionName += foundPosition.name;
        }
      }
      return positionName;
    }
    return '';
  }

  oncheckboxItem(courseId: number, checked: any) {
    if (checked) {
      this.users?.forEach(c => {
        if (c.id === courseId) {
          c.checked = true;
          this.userIds?.push(courseId);
          this.userIdsChecked?.push(courseId);
          return;
        }
      });
      if (this.userIds?.length > 0 && this.userIds?.length === this.users?.length && !this.isCheckAll) {
        this.isCheckAll = true;
      }
    } else {
      this.isCheckAll = false;
      this.users?.forEach(c => {
        if (c.id === courseId) {
          c.checked = false;
          this.userIds?.splice(this.userIds?.indexOf(courseId), 1);
          this.userIdsChecked?.splice(this.userIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
    this.useridsEmit.emit(this.userIdsChecked);
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.users.forEach(c => {
        c.checked = checked;
        if (!this.userIds.includes(c.id)) {
          this.userIds.push(c.id);
        }
        if (!this.userIdsChecked?.includes(c.id)) {
          this.userIdsChecked?.push(c.id);
        }
      });
      this.isCheckAll = true;
    } else {
      this.userIds?.forEach(id => {
        this.userIdsChecked?.splice(this.userIdsChecked?.indexOf(id), 1);
      });
      this.userIds = [];
      this.users?.forEach(c => {
        c.checked = false;
      });
      this.isCheckAll = false;
    }
    this.useridsEmit.emit(this.userIdsChecked);
  }

  onSearchUser(value){
    this.searchUser.keyword = value;
    this.searchUser.pageIndex = 1;
    this.userIdsChecked = [];
    this.getListUser();
  }

  changePage(event){
    this.searchUser.pageIndex = event.pageIndex + 1;
    this.searchUser.pageSize = event.pageSize;
    this.getListUser();
  }

}
