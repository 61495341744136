<div class="container-fluid">
  <div class="card card-default">
    <div class="card-body">
      <div class="col-12 table-responsive">
        <div class="row">
          <form class="form-inline float-right" role="form">
            <div class="form-group m-2">
              <label class="mr-2">Tìm kiếm</label>
              <input type="search" class="form-control mr-2" placeholder="Tên học viên..." value="" (change)="onChangeSearch($event)">
            </div>
            <!-- <button type="button" class="btn btn-default btn-lg mr-1" matTooltip="Xuất File Excel"
                    matTooltipClass="tooltip-red"><i class="fas fa-file-excel  mr-1"></i></button> -->
          </form>
        </div>
        <table class="table table-hover">
          <thead>
          <tr>
            <th>STT</th>
            <th>Tên học viên</th>
            <th>Khóa học/kỳ thi</th>
            <th>Ngày nhận</th>
            <th>Ngày hết hạn</th>
            <!-- <th *ngIf="!readonly">Thao tác</th> -->
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of studentonBadge; let i = index;">
            <td>{{(searchBadgeStudent.page - 1) * searchBadgeStudent.limit + i + 1}}</td>
            <td>{{item.studentname}}</td>
            <td>{{item.coursename}}</td>
            <td>{{getDateFromUnix(item.dateissued) | date:'dd/MM/yyyy'}}</td>
            <td>{{item.dateexpire ? (getDateFromUnix(item.dateexpire) | date:'dd/MM/yyyy') : 'Vô thời hạn'}}</td>
            <!-- <td *ngIf="!readonly">
              <button type="button" (click)="revokeBadgeOfStudent(item.studentid)" class="btn btn-default btn-sm mr-1" matTooltip="Xóa" matTooltipClass="tooltip-red">
                <i class="fas fa-trash mr-1"></i></button>
            </td> -->
          </tr>
          </tbody>
        </table>
        <br />
        <p *ngIf="studentonBadge?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
      </div>
    </div>
    <div class="card-footer text-center">
      <div class="row">
        <mat-paginator showFirstLastButtons
          [length]="totalRecord"
          [pageSize]="searchBadgeStudent.limit"
          [pageIndex]="searchBadgeStudent.page - 1"
          (page)="changePage($event)"
          [hidePageSize]="false"
          [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
