import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, Form, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ToastrCustomService} from '../../../../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {CourseCategoryService} from '../../../../shared/services/course-category.service';
import CommonUtil from '../../../../shared/utils/common-util';
import {CourseCategory} from '../../../../shared/model/course-category.model';
import {QuizContentService} from '../../../../shared/services/quiz-content.service';
import {CustomValidators} from "../../../../shared/utils/custom-validators";

@Component({
  selector: 'app-chosen-ran',
  templateUrl: './chosen-ran.component.html',
  styleUrls: ['./chosen-ran.component.css']
})
export class ChosenRanComponent implements OnInit {

  @Output() loadAll = new EventEmitter();
  @Input() quizId: number;
  addRandomQuestionsForm: FormGroup;

  courseCategoryList: CourseCategory[] = [];
  categoryMap = new Map();


  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private courseCategoryService: CourseCategoryService,
    public quizContentService: QuizContentService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      this.setRootTree(response.body);
      this.courseCategoryList = response.body;
      CommonUtil.convertCategoryListToMap(this.courseCategoryList, this.categoryMap);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
    this.addRandomQuestionsForm = this.fb.group({
      categoryid: [null, Validators.required],
      one: ['', Validators.required],
      two: ['', Validators.required],
      three: ['', Validators.required],
      four: ['', Validators.required],
      five: ['', Validators.required],
    }, {
      validators: CustomValidators.chosenRandomQuestionValidator(),
    });
  }

  addQuestions(){
    if (this.addRandomQuestionsForm.invalid) {
      CommonUtil.markFormGroupTouched(this.addRandomQuestionsForm);
      return;
    }
    const params = {
      quizid: this.quizId,
      categoryid: this.addRandomQuestionsForm.value.categoryid,
      one: this.addRandomQuestionsForm.value.one,
      two: this.addRandomQuestionsForm.value.two,
      three: this.addRandomQuestionsForm.value.three,
      four: this.addRandomQuestionsForm.value.four,
      five: this.addRandomQuestionsForm.value.five,
    };
    this.spinner.show('chosenran');
    this.quizContentService.addRandomQuestion(params).subscribe(
      res => {
        this.spinner.hide('chosenran');
        this.toastrService.success(`common.noti.add_question_success`);
        this.loadAll.emit('load all');
        this.modalService.dismissAll();
      },
      err => { // co loi xay ra thi khong dong popup nua
        this.spinner.hide('chosenran');
        this.toastrService.handlerError(err);
      }
    );

  }


  /**
   * chi cho phep nhap so nguyen duong
   * param event
   */
  numericOnly(event): boolean {
    const patt = /^([0-9])$/;
    return patt.test(event.key);
  }

  setRootTree(tree: any){
    tree.forEach(value => {
      if (value.parent === 0) // = 0 la root tree
      {
        value.parent = null; // set = null nham muc dich hien thi
        value.expanded = true; // muc dich expand root luon khi khoi tao tree
      } else {
        value.expanded = false;
      }
      value.isChecked = true;
    });
  }

}
