<form role="form" [formGroup]="questionForm">
  <select name="level" formControlName="level" class="form-control">
    <option value>Chọn chủ đề câu hỏi</option>
    <option [value]="topic.value" *ngFor="let topic of TOPIC_CONST">
      {{topic.lable}}
    </option>
  </select>
  <div
    *ngIf="questionForm.get('level')!.invalid && (questionForm.get('level')!.dirty || questionForm.get('level')!.touched)">
    <small class="text-danger" *ngIf="questionForm.get('level')?.errors?.required">
      {{ 'error.validate.input_required' | translate }}
    </small>
  </div>
  <div class="col-12 mt-3 border">
    <!-- /.card-header -->
    <h6 class="mt-3">Nhập nội dung câu hỏi</h6>
    <div class="card-body" style="display: block;">
      <div class="row">
        <!-- <div class="col-md-3 text-right"> -->
        <!-- <p>Câu hỏi<span class="text-danger"> *</span></p> -->
        <!-- </div> -->
        <!-- <div class="col-md-9"> <input type="text" class="form-control" name="name" placeholder="Tên câu hỏi" formControlName="name"> -->
        <!-- </div> -->
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>
            Nhập nội dung
            <span class="text-danger">*</span>
          </p>
        </div>
        <div class="col-md-9">
          <ckeditor [editor]="Editor" formControlName="questiontext" data [config]="questionEditorConfig"></ckeditor>
          <div
            *ngIf="questionForm.get('questiontext')!.invalid && (questionForm.get('questiontext')!.dirty || questionForm.get('questiontext')!.touched)">
            <small class="text-danger" *ngIf="questionForm.get('questiontext')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
            <small class="text-danger" *ngIf="questionForm.get('questiontext')?.errors?.isBlank">
              {{ 'error.validate.input_required' | translate }}
            </small>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Đánh dấu câu hỏi</p>
        </div>
        <div class="col-md-1">
          <input type="text" class="form-control" name="defaultmark" value="1" formControlName="defaultmark" size="7">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>
            Lựa chọn kiểu đáp án
            <span class="text-danger">*</span>
          </p>
        </div>
        <div class="col-md-2">
          <select class="custom-select" name="answercorrect" formControlName="answercorrect">
            <option value="1">Đúng</option>
            <option value="0">Sai</option>
          </select>
        </div>
      </div>
      <mat-action-row *ngIf="canEdit">
        <button type="button" matTooltip="Sao chép" [disabled]="isPublished" (click)="onCloneQuestion()"
          matTooltipClass="tooltip-red" class="btn btn-default btn-lg">
          <i class="fas fa-copy"></i>
        </button>
        <button type="button" matTooltip="Chỉnh sửa" (click)="onTurnOnEditmode()" [disabled]="isPublished"
          *ngIf="isViewMode" matTooltipClass="tooltip-red" class="btn btn-default btn-lg">
          <i class="fas fa-edit"></i>
        </button>
        <button type="button" matTooltip="Lưu" (click)="onSaveQuestion(questionForm.value)"
          [disabled]="isPublished || questionForm.invalid" *ngIf="!isViewMode" matTooltipClass="tooltip-red"
          class="btn btn-default btn-lg">
          <i class="fas fa-save mr-1"></i>
        </button>
        <button type="button" matTooltip="Xóa" [disabled]="isPublished" (click)="onRemoveQuestion()"
          matTooltipClass="tooltip-red" class="btn btn-default btn-lg">
          <i class="fas fa-trash"></i>
        </button>
      </mat-action-row>
    </div>
  </div>
</form>
