<div class="modal-header">
  <h5 class="modal-title">Chọn câu hỏi từ thư viện</h5>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
          (click)="activeModal.dismiss()"><i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row ">
    <div class="col-12">
      <form [formGroup]="moduleForm" (ngSubmit)="search()">
        <div class="form-row">
          <div class="col-4">
            <input type="text" formControlName="keyWord" class="form-control" placeholder="Tìm kiếm câu hỏi">
          </div>
          <div class="col-auto" (change)="searchCategory($event.target.value)">
            <select class="custom-select">
              <option value="0">Tất cả</option>
              <option *ngFor="let item of categoryMap | keyvalue"
                      [value]="item.key">{{item.value}}</option>
            </select>
          </div>
          <div class="col-auto">
            <select name="level" class="form-control" (change)="searchLevel($event.target.value)">
              <option value>Chọn chủ đề câu hỏi</option>
              <option [value]="topic.value" *ngFor="let topic of TOPIC_CONST">
                {{topic.lable}}
              </option>
            </select>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary"><i class="fas fa-filter mr-2"></i> Lọc</button>
            </div>
          </div>
        </div>
      </form>
      <div class="col-12 mt-3 table-responsive overflow-auto" style="height: 50vh;">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>
              <mat-icon class="mt-1">
                <mat-checkbox class="example-margin" [(ngModel)]="completedAll"
                              (change)="oncheckboxAll($event.checked)"></mat-checkbox>
              </mat-icon>
            </th>
            <th>STT</th>
            <th>Nội dung câu hỏi</th>
            <th>Loại câu hỏi</th>
            <th>Số đáp án</th>
            <th>Ngày tạo</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let question of listQuestion; let i = index">
            <td>
              <mat-icon class="mt-1">
                <mat-checkbox [(ngModel)]="question.checked" [checked]="question.checked" (change)="oncheckboxItem(question.id, $event.checked, question)"
                              class="example-margin"></mat-checkbox>
              </mat-icon>
            </td>
            <td style="width:20px">{{i + 1 + (searchQuestion.pageIndex - 1) * searchQuestion.pageSize}}</td>
            <td style="width:700px;"><p [innerHTML]="question.questiontext?.length <= 90 ? question.questiontext: (question.questiontext | slice:0:90) + '...' "></p></td>
            <td *ngIf="question.qtype==='multichoice'">{{'qtype.multichoice' | translate}}</td>
            <td *ngIf="question.qtype==='multichoiceset'">{{'qtype.multichoiceset' | translate}}</td>
            <td *ngIf="question.qtype==='truefalse'">{{'qtype.truefalse' | translate}}</td>
            <td>{{ question.answer ? question.answer.length : 2 }}</td>
            <td>{{getDateFromUnix(question.timecreated) | date:'dd/MM/yyyy'}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer text-center" style="z-index: 100000;">
        <div class="row">
          <mat-paginator showFirstLastButtons
                         [length]="totalRecord"
                         [pageSize]="searchQuestion.pageSize"
                         [pageIndex]="searchQuestion.pageIndex - 1 "
                         (page)="changePage($event)"
                         [hidePageSize]="false"
                         [pageSizeOptions]="pageSizeOptions">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <p class="mr-auto">Số câu đã chọn: <span class="font-weight-bold">{{questionIds?.length}}</span></p>
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-dismiss="modal">Đóng</button>
  <button type="button" (click)="save()" [disabled]="questionIds.length === 0" class="btn btn-primary">Lưu lại</button>
</div>

<ngx-spinner [fullScreen]="true" [name]="'addquestion'" type="ball-clip-rotate-multiple" size="medium">
  <p class="loading">{{ 'common.loading' | translate }}</p>
</ngx-spinner>
