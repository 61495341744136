import { LOCAL_STORAGE_KEY } from './../../shared/constants/base.constant';
import {Component, OnInit} from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  searchSurvey = {
    currentTabIndex: 0
  };
  isTemplate: boolean;
  constructor( private $localStorage: LocalStorageService
  ) { }
  //
  ngOnInit(): void {
    this.isTemplate = true;
    const currentSearch = this.$localStorage.retrieve(LOCAL_STORAGE_KEY.SEARCH.SURVEY_SEARCH);
    if (currentSearch) {
      // Kiem tra xem truoc do co luu du lieu tim kiem khong
      this.searchSurvey.currentTabIndex = currentSearch.currentTabIndex;
      // Xoa di sau khi su dung
      this.$localStorage.clear(LOCAL_STORAGE_KEY.SEARCH.SURVEY_SEARCH);
    }
  }

  onTabChange(event){
    this.searchSurvey.currentTabIndex = event.index;
    this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.SURVEY_SEARCH, this.searchSurvey);
  }
}
