<div class="container" *ngIf="survey && !notPermission && !isHasReponse && !isHasNotStarted && !isHasEnded">
  <div class="col-12 mt-3">
    <h5 class="mt-3">{{survey.name}}</h5>
    <div *ngFor="let question of survey.listquestion">
      <div class="card card-default  border rounded" *ngIf="question.type === textQuestionType">
        <div class="card-body p-3">
          <div class="row">
            <!-- <p class="font-weight-bold">{{question.name}}: </p> -->
            <span class="ml-2" [innerHTML]=question.content></span>
          </div>
          <div class="form-group w-100">
            <textarea type="text" class="width:50%;" [(ngModel)]="question.answer" name="{{question.id}}" class="form-control"
              required></textarea>
          </div>
        </div>
      </div>
      <div class="card card-default  border rounded" *ngIf="question.type === radioQuestionType">

        <div class="card-body p-3">
          <div class="row ">
            <span class="ml-2" [innerHTML]=question.content></span>
          </div>
          <!-- <div class="form-check">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" name="{{question.id}}"
                     (change)="handleChange(choice.id)">{{choice.content}}
            </label>
          </div> -->
          <mat-radio-group aria-label="Select an option" name="{{question.id}}" [(ngModel)]="question.answer">
            <mat-radio-button  class="w-100 mr-4" *ngFor="let choice of question.choices" value="{{choice.id}}" >{{choice.content}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="card card-default  border rounded" *ngIf="question.type === rateQuestionType">
        <div class="card-body p-3" *ngFor="let choice of question.choices">
          <div class="row">
            <!-- <p class="font-weight-bold">{{question.name}}: </p> -->
            <span class="ml-2" [innerHTML]=question.content></span>
          </div>
          <table style="width: 100%">
            <tr>
              <td style="text-align: center; word-break: break-word;" class="rate"
                  [style.width.%]="100 / question.length"
                  *ngFor="let i of arrayStartFrom(question.length, 1)">
                {{question.extradata[i] ? question.extradata[i] : ""}} <br/>
                {{i}}
              </td>
            </tr>
            <tr *ngFor="let choice of question.choices">
              <td style="text-align: center" class="rate"
                  *ngFor="let i of arrayStartFrom(question.length, 1)" >
                <mat-radio-button (change)="checkRateQuestion(choice, i)"
                  class="w-10" value="{{i}}" name="{{question.id + '_' + choice.id}}" >
                </mat-radio-button>
              </td>
            </tr>
          </table>
<!--          <mat-radio-group  name="{{question.id + '_' + choice.id}}" [(ngModel)]="choice.answer">-->
<!--            <mat-radio-button class="w-100 mr-4" *ngFor="let i of arrayStartFrom(question.length, 1)" value="{{i}}" >{{i}}</mat-radio-button>-->
<!--          </mat-radio-group>-->
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer text-center">
    <button type="button" class="btn btn-primary" (click)="sendAnswer()">Gửi khảo sát</button>
  </div>
</div>
<div *ngIf="notPermission" class="container text-center mt-2">
  <img src="/assets/survey.svg" style="height: 25vh;" alt="">
  <h6 class = "text-center mt-1">Rất tiếc, khảo sát không tồn tại !</h6>
</div>
<div *ngIf="isHasReponse" class="container text-center mt-2">
  <img src="/assets/survey.svg" style="height: 25vh;" alt="">
  <h6 class = "text-center mt-1">Bạn đã hoàn thành khảo sát</h6>
</div>
<div *ngIf="isHasNotStarted" class="container text-center mt-2">
  <img src="/assets/survey.svg" style="height: 25vh;" alt="">
  <h6 class = "text-center mt-1">Khảo sát chưa diễn ra! Vui Lòng quay lại sau!</h6>
</div>
<div *ngIf="isHasEnded" class="container text-center mt-2">
  <img src="/assets/survey.svg" style="height: 25vh;" alt="">
  <h6 class = "text-center mt-1">Khảo sát đã kết thúc !</h6>
</div>
