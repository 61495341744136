import { CourseCategory } from './../../../shared/model/course-category.model';
import { CourseCategoryService } from '../../../shared/services/course-category.service';
import { ActivatedRoute } from '@angular/router';
import { Course } from 'src/app/shared/model/course.model';
import { CourseService } from '../../../shared/services/course.service';
import { ToastrCustomService } from 'src/app/shared/services/toastr-custom.service';
import { Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import CommonUtil from 'src/app/shared/utils/common-util';
import {CustomValidators} from '../../../shared/utils/custom-validators';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit {

  examForm = this.fb.group({
    id: [],
    fullname: ['', [Validators.required, CustomValidators.notBlankValidator, Validators.maxLength(200)]],
    startdate: [, Validators.required],
    enddate: [, Validators.required],
    categoryid: []
  });

  examDetail: Course = {
    id: null,
    fullname: '',
    startdate: null,
    enddate: null,
    published: null,
    categoryid: null
  };

  startDate: any;
  endDate: any;
  examId: number;
  formEnable = false;
  courseCategoryList: CourseCategory[] = [];
  categoryMap = new Map();

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrCustomService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private courseCategoryService: CourseCategoryService,
  ) { }

  ngOnInit(): void {
    this.examId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.getInfoExam(this.examId);
    this.examForm.disable();
    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      this.courseCategoryList = response.body;
      CommonUtil.convertCategoryListToMap(this.courseCategoryList, this.categoryMap);
    });
  }

  updateExam() {
    if (this.examForm.invalid) {
      if (this.examForm.invalid) {
        CommonUtil.markFormGroupTouched(this.examForm);
      }
      return;
    }
    this.courseService.updateCourse(this.examForm.value, null, true).subscribe(
      res => {
        this.toastrService.success('common.noti.update_success');
        this.getInfoExam(this.examId);
        this.examForm.disable();
        this.formEnable = false;
      },
      err => {
        this.toastrService.handlerError('common.noti.update_error');
      }
    );
  }

  onUpdateExam() {
    this.examForm.enable();
    this.formEnable = true;
  }

  cancelUpdateExam() {
    this.examForm.disable();
    this.formEnable = false;
    this.initFormExamDetail(this.examDetail);
  }

  initFormExamDetail(course: Course) {
    const keyForm = Object.keys(this.examForm.value);
    for (const key of keyForm) {
      if (key === 'startdate' || key === 'enddate') {
        this.examForm.controls[key].setValue(moment.unix(course[key]).toDate());
      } else {
        this.examForm.controls[key].setValue(course[key]);
      }
    }
  }

  getInfoExam(courseId: any) {
    this.courseService.getCoursesInfo(courseId).subscribe(response => {
      this.examDetail = response.body;
      this.initFormExamDetail(response.body);
      if (this.examDetail.startdate) {
        this.startDate = moment.unix(this.examDetail.startdate).toDate();
      }
      if (this.examDetail.enddate) {
        this.endDate = moment.unix(this.examDetail.enddate).toDate();
      }
    });
  }

  onPublishExam() {
    this.courseService.publishCourse(this.examId).subscribe(
      res => {
        this.toastrService.success(`common.noti.publish_success`);
        this.examDetail.published = true;
      },
      err => {
        this.toastrService.handlerError(err);
      }
    );
  }

  onUnPublishExam() {
    this.courseService.unPublishCourse(this.examId).subscribe(
      res => {
        this.toastrService.success(`common.noti.un_publish_success`);
        this.examDetail.published = false;
      },
      err => {
        this.toastrService.handlerError(err);
      }
    );
  }
}
