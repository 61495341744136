<div class="container">
    <div class="row mt-3">
        <div class="col-10 justify-content-center">
          <div class="certificate">
              <div class="selectcertificate">
                <mat-radio-button value="1">Lựa chọn mẫu này</mat-radio-button>
              </div>
              <div class="namecourse text-uppercase ">
                  <p>Tên khóa học</p>
              </div>
              <div class="usercertificate text-uppercase font-weight-bold">
                <p>Tên học viên</p>
              </div>
              <div class="department text-uppercase">
                <p>Đơn vị</p>
              </div>
              <div class="datecertificate text-uppercase">
                <p>Ngày cấp</p>
              </div>
          </div>
        </div>      
      </div>
</div>
