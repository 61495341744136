<section class="inner_page_breadcrumb">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 text-center">
          <div class="breadcrumb_content mt-2">
            <h4 class="breadcrumb_title">CHỨNG CHỈ CỦA BẠN</h4>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a routerLink="/home">Trang chủ</a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
</section>
<main>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 col-md-12 table-responsive ">
                  <table class="table mt-3 table-hover">
                      <thead>
                        <tr class="bg-light">
                          <th >STT</th>
                          <th >Tên chứng chỉ</th>
                          <th >Số hiệu</th>
                          <th >Ngày cấp</th>
                          <th >Ngày hết hạn</th>
                          <th >Thao tác</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let item of certificate; let i = index">
                        <td>{{(pageIndex - 1) * pageSize + i + 1}}</td>
                        <td style="width:450px;">{{item.name}}</td>
                        <td>MOBIFONE-{{item.issuedid}}</td>
                        <td>{{getDateFromUnix(item.dateissued) | date:'dd/MM/yyyy'}}</td>
                        <td>{{item.dateexpire ? (getDateFromUnix(item.dateexpire) | date:'dd/MM/yyyy') : 'Vô thời hạn'}}</td>
                        <td><button matTooltip="Xem" (click)="goToDetail(item?.id)" class="btn btn-default"><i class="fas fa-eye"></i></button></td>
                      </tr>
                      </tbody>
                      <tr class="text-center" *ngIf="certificate?.length === 0"><td colspan="8">Bạn chưa có chứng chỉ nào</td></tr>
                  </table>
              </div>
            </div>
          </div>
          <div class="card-footer text-center" *ngIf="certificate?.length > 0">
            <div class="row">
              <mat-paginator showFirstLastButtons
                  [length]="totalRecord"
                  [pageSize]="pageSize"
                  [pageIndex]="pageIndex - 1"
                  (page)="changePage($event)"
                  [hidePageSize]="false"
                  [pageSizeOptions]="pageSizeOptions">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
