import { LOCAL_STORAGE_KEY } from './../../../shared/constants/base.constant';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleSystem } from '../../../shared/model/role-system';
import {LIST_HOURS, LIST_MINUTES, QUESTION_TYPE, USER_INFO, USER_ROLE} from '../../../shared/constants/base.constant';
import { LocalStorageService } from 'ngx-webstorage';
import { Survey } from '../../../shared/model/survey';
import { SurveyService } from '../../../shared/services/survey.service';
import { ToastrCustomService } from '../../../shared/services/toastr-custom.service';
import { TranslateService } from '@ngx-translate/core';
import CommonUtil from '../../../shared/utils/common-util';
import { ConfirmModalComponent } from '../../../shared/modal/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditQuestionComponent } from '../edit-question/edit-question.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventManagerService } from 'src/app/shared/services/event-manager.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-survey',
  templateUrl: './create-survey.component.html',
  styleUrls: ['./create-survey.component.css']
})
export class CreateSurveyComponent implements OnInit, OnDestroy {

  currentDate: Date = new Date();
  currentUserRole: any;
  @Input() checkChooseTemplate: boolean;
  buttonText: string;

  textType: string;
  radioType: string;
  rateType: string;


  questionTypeText = 'textquestion';
  questionTypeSelect = 'selectquesiton';
  questionTypeRate = 'ratequestion';

  @Output() showCreateSurvey = new EventEmitter();
  @Input() idSurveyUpdate;

  hoursList = LIST_HOURS;
  minutesList = LIST_MINUTES;

  survey: Survey = {
    name: '',
    sectionid: null,
    introeditor: '',
    ismaster: null,
    published: null,
    publishedat: null,
    startdate: null,
    enddate: '',
    qtype: 1,
    id: null,
    questionnaireid: null,
    intro: '',
    cmid: null,
    listquestion: null,
    course: null,
    isactive: false,
    refdepartmentid: null,
    activatedat: null,
    departmentid: null,
    selected: null,
    isteacher: null,
    sid: null
  };

  createSurveyForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(200)]],
    // introeditor: ['', [Validators.required, Validators.maxLength(255)]],
    enddate: [],
    hoursEndDate: [''],
    minuteEndDate: [''],
    qtype: [1],
    startdate: [],
    hourStartDate: [''],
    minuteStartDate: [''],
  });

  accordions: any = [];

  public Editor = ClassicEditor;
  currentQuestionForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private $localStorage: LocalStorageService,
    private surveyService: SurveyService,
    private toastrService: ToastrCustomService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private eventManager: EventManagerService,
    private cdRef: ChangeDetectorRef,
    private location: Location
  ) {}


  ngOnInit(): void {
    this.registerUpdateQuestion();
    this.textType = QUESTION_TYPE.TEXT;
    this.radioType = QUESTION_TYPE.RADIO;
    this.rateType = QUESTION_TYPE.RATE;

    const roles: RoleSystem[] = this.$localStorage.retrieve(USER_INFO.ROLES);
    if (roles.length > 0) {
      this.currentUserRole = roles[0].shortname;
    }
    if (this.currentUserRole === USER_ROLE.ADMIN) {
      this.survey.ismaster = 1;
    } else {
      this.survey.ismaster = 0;
    }

    if (this.idSurveyUpdate !== undefined && this.idSurveyUpdate !== null && this.idSurveyUpdate > 0) {
      this.getInforSurvey(this.idSurveyUpdate);
      this.buttonText = this.translateService.instant('common.update');
    } else {
      this.buttonText = this.translateService.instant('common.add');
    }

    if (!this.checkChooseTemplate){
      this.createSurveyForm.controls.enddate.setValidators(Validators.required);
      this.createSurveyForm.controls.startdate.setValidators(Validators.required);
      this.createSurveyForm.controls.hoursEndDate.setValidators(Validators.required);
      this.createSurveyForm.controls.minuteEndDate.setValidators(Validators.required);
      this.createSurveyForm.controls.hourStartDate.setValidators(Validators.required);
      this.createSurveyForm.controls.minuteStartDate.setValidators(Validators.required);
    }else{
      this.createSurveyForm.controls.enddate.clearValidators();
      this.createSurveyForm.controls.startdate.clearValidators();
      this.createSurveyForm.controls.hoursEndDate.clearValidators();
      this.createSurveyForm.controls.minuteEndDate.clearValidators();
      this.createSurveyForm.controls.hourStartDate.clearValidators();
      this.createSurveyForm.controls.minuteStartDate.clearValidators();
    }
    this.createSurveyForm.get('enddate').updateValueAndValidity();
    this.createSurveyForm.get('startdate').updateValueAndValidity();
    this.createSurveyForm.get('hoursEndDate').updateValueAndValidity();
    this.createSurveyForm.get('minuteEndDate').updateValueAndValidity();
    this.createSurveyForm.get('hourStartDate').updateValueAndValidity();
    this.createSurveyForm.get('minuteStartDate').updateValueAndValidity();
    ////
    /* if (this.checkChooseTemplate) {
      this.checkChooseTemplate = false;
      // update single

      // create single
      if (this.idSurveyUpdate === undefined || this.idSurveyUpdate === null || this.idSurveyUpdate === 0) {
        this.buttonText = this.translateService.instant('common.add');
       // this.buttonPublish = this.translateService.instant('common.start_publish');
      //  this.checkValue2(false);
      }
    } else {
      this.checkChooseTemplate = true;
      // update template
      if (this.idSurveyUpdate !== undefined && this.idSurveyUpdate !== null && this.idSurveyUpdate > 0) {
        this.getInforSurvey(this.idSurveyUpdate);
        this.buttonText = this.translateService.instant('common.update');
      }

      // create template
      if (this.idSurveyUpdate === undefined || this.idSurveyUpdate === null || this.idSurveyUpdate === 0) {
        this.buttonText = this.translateService.instant('common.add');
      }
    } */
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  onClosePanel(index: any) {
    this.accordions[index].isOpen = false;
  }

  onOpenPanel(index: any) {
    this.accordions[index].isOpen = true;
    this.currentQuestionForm = this.accordions[index].form;
  }

  getInforSurvey(idSurvey: any) {
    if (idSurvey != null){
      this.spinner.show();
      this.surveyService.getDetailSurvey(idSurvey).subscribe(response => {
        this.survey = response.body;
        this.survey.enddate = new Date(this.survey.enddate * 1000);
        this.survey.startdate = new Date(this.survey.startdate * 1000);

        this.updateFormValue();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
    }
  }

  updateFormValue() {
    this.createSurveyForm.patchValue({
      parentid: this.survey.departmentid,
      name: this.survey.name,
      introeditor: this.survey.intro,
      hoursEndDate: this.survey.enddate > 0 ? this.survey.enddate.getHours() : '0',
      minuteEndDate: this.survey.enddate > 0 ? this.survey.enddate.getMinutes() : '0',
      enddate: this.survey.enddate > 0 ? this.survey.enddate : '',
      qtype: this.survey.qtype,
      startdate: this.survey.startdate > 0 ? this.survey.startdate : '',
      hourStartDate: this.survey.startdate > 0 ? this.survey.startdate.getHours() : '0',
      minuteStartDate: this.survey.startdate > 0 ? this.survey.startdate.getMinutes() : '0'
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.accordions, event.previousIndex, event.currentIndex);
  }

  createNewSurvey() {
    if (this.createSurveyForm.invalid) {
      if (this.createSurveyForm.invalid) {
        CommonUtil.markFormGroupTouched(this.createSurveyForm);
      }
      return;
    }

    this.spinner.show();
    this.survey.introeditor = this.createSurveyForm.value.introeditor;
    this.survey.name = this.createSurveyForm.value.name;
    this.survey.qtype = this.createSurveyForm.value.qtype;
    this.survey.cmid = null;
    this.survey.qtype = 1;

    if (this.checkChooseTemplate) {
      this.survey.publishedat = null;
      this.survey.enddate = '0';
    }

    // update survey
    if (this.survey.id) {
      this.survey.enddate = this.convertDateToTime(
      this.createSurveyForm.value.enddate,
      this.createSurveyForm.value.hoursEndDate,
      this.createSurveyForm.value.minuteEndDate);

      this.survey.startdate = this.convertDateToTime(
      this.createSurveyForm.value.startdate,
      this.createSurveyForm.value.hourStartDate,
      this.createSurveyForm.value.minuteStartDate);

      if (this.survey.enddate <= this.survey.startdate && !this.checkChooseTemplate) {
        this.spinner.hide();
        this.toastrService.error('error.enddatebeforestartdate');
        return;
      }
      const currentTime = (new Date()).getTime() / 1000;
      if (this.survey.startdate < currentTime && !this.checkChooseTemplate) {
        this.spinner.hide();
        this.toastrService.error('error.startdatebeforecurrentdate');
        return;
      }


      this.surveyService.updateSurvey(this.survey).subscribe(() => {
        this.getInforSurvey(this.survey.questionnaireid);
        this.spinner.hide();
        this.toastrService.success('common.noti.update_success');
      }, err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      });
    }

    // create survey
    else {
      this.survey.cmid = null;
      this.survey.published = null;
      // this.survey.intro = '';

      // tao template
      if (this.checkChooseTemplate) {
        this.survey.publishedat = null;
        this.survey.enddate = null;

        this.surveyService.createTemplateSurvey(this.survey).subscribe(res => {
          const survey: any = res.body;
          this.survey.questionnaireid = survey.id;
          this.survey.cmid = survey.coursemodule;
          this.survey.intro = survey.intro;

          this.getInforSurvey(survey.id);
          this.spinner.hide();
          this.toastrService.success('common.noti.create_success');
          this.buttonText = this.translateService.instant('common.update');

        },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);

          }
        );
      }
      // tao doc lap
      /* if (!this.checkChooseTemplate) {
        this.surveyService.createSingleSurvey(this.survey).subscribe(res => {
          const survey: any = res.body;
          this.survey.questionnaireid = survey.id;
          this.survey.cmid = survey.coursemodule;

          this.getInforSurvey(survey.id);
          this.spinner.hide();
          this.toastrService.success('common.noti.create_success');
          this.buttonText = this.translateService.instant('common.update');

        }, err => {
          this.spinner.hide();
          this.toastrService.handlerError(err);
        }
        );
      } */
    }
  }


  addQuestion(typeValue: any, index?: number) {
    const accordion = {
      type: typeValue,
      question: null,
      form: null,
      isOpen: false,
      valid: false
    };

    this.accordions.push(accordion);
    if (index >= 0) {
      this.accordions[this.accordions.length - 1].question = this.accordions[index].question;
      this.accordions[this.accordions.length - 1].valid = this.accordions[index].valid;
    }
    this.cdRef.detectChanges();
  }

  /** Listener event on changes form text question */
  receiveText(data) {
    this.currentQuestionForm = data.form;
    this.accordions[data.index].question = this.getValueTextQuestionForm();
    this.accordions[data.index].valid = data.form.valid;
    this.accordions[data.index].form = data.form;
  }

  /** Listener event on changes form select question */
  receiveSelect(data) {
    this.currentQuestionForm = data.form;
    this.accordions[data.index].question = this.getValueSelectQuestionForm();
    this.accordions[data.index].valid = data.form.valid;
    this.accordions[data.index].form = data.form;
  }

  /** Listener event on changes form rate question */
  receiveRate(data) {
    this.currentQuestionForm = data.form;
    this.accordions[data.index].question = this.getValueRateQuestionForm();
    this.accordions[data.index].valid = data.form.valid;
    this.accordions[data.index].form = data.form;
  }

  getValueTextQuestionForm() {
    const question = {
      name: this.currentQuestionForm.value.name,
      description: this.currentQuestionForm.value.description,
      isteacher: this.currentQuestionForm.value.isteacher,
      cmid: this.survey.cmid,
      sid: this.survey.questionnaireid
    };
    return question;
  }

  getValueSelectQuestionForm() {
    const question = {
      name: this.currentQuestionForm.value.name,
      description: this.currentQuestionForm.value.description,
      cmid: this.survey.cmid,
      sid: this.survey.questionnaireid,
      allchoices: this.currentQuestionForm.value.allchoices,
      isteacher: this.currentQuestionForm.value.isteacher,
    };
    return question;
  }

  getValueRateQuestionForm() {
    const question = {
      name: this.currentQuestionForm.value.name,
      description: this.currentQuestionForm.value.description,
      cmid: this.survey.cmid,
      sid: this.survey.questionnaireid,
      allchoices: 'bình chọn',
      isteacher: this.currentQuestionForm.value.isteacher,
      length: this.currentQuestionForm.value.length,
      allnameddegrees: this.currentQuestionForm.value.allnameddegrees
    };
    return question;
  }

  async onSaveQuestion(accordion: any, isSaveAll?: boolean) {
    if (this.currentQuestionForm.invalid) {
      CommonUtil.markFormGroupTouched(this.currentQuestionForm);
      return;
    }

    let rs;
    try {
      if (accordion.type === this.questionTypeText) {
        rs = await this.surveyService
          .createQuestionTextInput(accordion.question).toPromise();
      }
      if (accordion.type === this.questionTypeSelect) {
        const arrchoices = [];
        accordion.question.allchoices.forEach(element => {
          arrchoices.push(element.text);
        });
        accordion.question.allchoices = arrchoices;
        rs = await this.surveyService
          .createQuestionSelect(accordion.question).toPromise();
      }
      if (accordion.type === this.questionTypeRate) {
        rs = await this.surveyService
          .createQuestionRate(accordion.question).toPromise();
      }

      if (!isSaveAll && rs.status === 200) {
        this.toastrService.success('common.noti.add_question_success');
        this.getInforSurvey(this.survey.questionnaireid);
        this.accordions.splice(this.accordions.indexOf(accordion), 1);
      }
    } catch (error) {
      this.toastrService.handlerError(error);
    }
  }

  removeQuestion(accordion: any) {

    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('question.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('question.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.accordions.splice(this.accordions.indexOf(accordion), 1);
      }
    });
  }

  /* async createQuestions() {
    const invalidAccordions = this.accordions.filter(a => !a.valid);
    if (invalidAccordions.length > 0) {
      this.toastrService.error('survey.alert_list_question');
      return;
    }
    this.spinner.show();
    await this.accordions.reduce(async (promise, acc) => {
      await promise;
      await this.onSaveQuestion(acc, true);
    }, Promise.resolve());

    this.getInforSurvey(this.survey.questionnaireid);
    this.accordions = [];
    this.toastrService.success('common.noti.add_question_success');
    this.spinner.hide();
  } */

  back() {
    this.showCreateSurvey.emit(false);
    if (!this.checkChooseTemplate) {
      const searchSurvey = {
        currentTabIndex: 1
      };
      this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.SURVEY_SEARCH, searchSurvey);
      this.location.back();

    }
  }

  openEditQuestion(question: any) {
    const modalDep = this.modalService.open(EditQuestionComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static'
    });
    const questionUpdate: any = question;
    modalDep.componentInstance.question = questionUpdate;
    modalDep.componentInstance.checkChooseTemplate = this.checkChooseTemplate;
    modalDep.componentInstance.questionNaireId = this.survey.questionnaireid;

    modalDep.componentInstance.quesUpdate.subscribe(() => {
      this.getInforSurvey(this.survey.questionnaireid);
    });
  }

  onDeleteQuestion(idQuestion: any) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('question.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('question.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalDep.result.then(() => {
      this.surveyService.deleteQuestion(this.survey.cmid, this.survey.questionnaireid, idQuestion).subscribe(() => {
        this.toastrService.success('common.noti.delete_success');
        if (this.idSurveyUpdate && this.idSurveyUpdate > 0) {
          this.getInforSurvey(this.idSurveyUpdate);
        } else {
          this.getInforSurvey(this.survey.questionnaireid);
        }
      }, error => {
        this.toastrService.handlerError(error);
      });
    }).catch(() => { });
  }

  registerUpdateQuestion() {
    this.eventManager.subscribe('updateQuestionSuccess', () => this.getInforSurvey(this.idSurveyUpdate));
  }

  convertDateToTime(date: Date, hours: number, minutes: number) {
    if (!this.checkChooseTemplate){
      date.setHours(hours);
      date.setMinutes(minutes);
      return date.getTime() / 1000;
    }
    return 0;
  }
}
