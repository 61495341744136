import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../shared/services/user.service';
import {URL_IMPORT_CSV_USER_TEMPLATE, USER_INFO} from '../../../shared/constants/base.constant';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {NgxSpinnerService} from 'ngx-spinner';
import {FileService} from '../../../shared/services/file.service';


@Component({
  selector: 'app-import-user',
  templateUrl: './import-user.component.html',
  styleUrls: ['./import-user.component.css']
})
export class ImportUserComponent implements OnInit, AfterViewInit {

  @Output() newUser = new EventEmitter<boolean>();

  urlCSVTemplte: string;
  typeFileValid = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
  fileToUpload: any = null;
  isDisable = true;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrCustomService,
    private userService: UserService,
    public activeModal: NgbActiveModal,
    private fileService: FileService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.urlCSVTemplte = URL_IMPORT_CSV_USER_TEMPLATE.URL;
  }

  ngAfterViewInit() {
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (this.fileToUpload == null) {
      this.isDisable = true;
      this.toastrService.error('Vui lòng tải lên một tệp excel - dạng tệp .xlsx');
    } else {
      this.isDisable = false;
      if (this.fileToUpload.type === this.typeFileValid[0] || this.fileToUpload.type === this.typeFileValid[1]) {
        this.isDisable = false;
      } else {
        this.isDisable = true;
        this.toastrService.error('Vui lòng tải lên một tệp excel - dạng tệp .xlsx');
      }
    }
  }

  importFileUser() {
    this.spinner.show();
    this.userService.importUserFormFile(this.fileToUpload).subscribe(res => {
      const file: any = res.body;
      this.spinner.hide();
      if (file.success) {
        this.toastrService.success('common.noti.file_create_success');
        window.open(this.fileService.getFileFromPathUrl(file.result));
        this.newUser.emit(true);
        this.activeModal.dismiss('close');

      } else {
        this.toastrService.error('common.noti.file_create_error');
      }
      this.fileToUpload = null;
      this.isDisable = true;
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

}
