<section class="content">
  <div class="container-fluid">
    <!--phan danh sach khoa hoc-->
    <div class="row">
      <mat-drawer-container autosize class="example-container" >
        <mat-drawer #drawer [ngClass]="isNotify ? 'example-sidenav-notify' : 'example-sidenav'" mode="side" opened="true" *ngIf="checkRoleFunction('ASSIGN_STUDENT_TO_COURSE',role) === true && !courseDetail?.readonly">
          <div class="card card-default">
            <div class="card-body">
              <div class="row mb-2">
                <!--Search -->
                <!-- <div class="col-7">
                  <input [hidden]="isNotify" type="search" class="form-control form-control-sm mr-2"
                         placeholder="Tìm học viên">
                </div> -->
                <div class="col-12" *ngIf="!courseDetail?.readonly">
                  <button *ngIf="!isNotify" type="button" data-toggle="modal" data-target="#createTopic"
                          [disabled]="((positionIds.length === 0 && !selectedDepartment) || courseDetail?.published === 1) && courseDetail?.isopen !== 1"
                          (click)="isNotify ? assignDepartmentPositionToNotify() : assignDepartmentPositionTocourse()"
                          class="btn btn-primary rounded-0 float-right"
                          style="margin-left: 5px;">
                    <i class="fas fa-plus mr-2"></i>Lựa chọn
                  </button>
                </div>
                <!--end-->
              </div>
              <div class="panel-group" role="tablist" aria-multiselectable="true">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header [collapsedHeight]="'50px'">
                      <mat-panel-title>
                        <div class="form-inline">
                          <div class="ml-2 mt-1">
                            <span class="topic">Lựa chọn theo đơn vị</span>
                          </div>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <form>
                      <ejs-treeview #departmentTree [fields]="departmentTreeData"
                                    (nodeChecked)='nodeSelected($event)' [showCheckBox]="!courseDetail?.readonly"></ejs-treeview>
                    </form>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header [collapsedHeight]="'50px'">
                      <mat-panel-title>
                        <div class="form-inline">
                          <div class="ml-2 mt-1">
                            <span class="topic">Lựa chọn theo chức danh</span>
                          </div>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list *ngFor="let position of positions;" class="mt-0">
                      <mat-list-item class="mat-list-button">
                        <h3 mat-line>{{position.name}}</h3>
                        <mat-icon matListAvatar class="mt-1" *ngIf="!courseDetail?.readonly">
                          <mat-checkbox [disabled]="courseDetail?.published === 1" [(ngModel)]="position.checked"
                                        (change)="addPositionIds(position.id, $event.checked)"
                                        class="example-margin"></mat-checkbox>
                        </mat-icon>
                      </mat-list-item>
                      <mat-divider></mat-divider>
                    </mat-list>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </mat-drawer>
        <div *ngIf="!isNotify" class="example-sidenav-content">
          <div class="card card-default">
            <div class="card-body content-of-topic">
              <div class="row">
                <div class="col-8">
                  <h6 class="topic mb-2">Danh sách học viên</h6>
                  <div class="form-inline">
                    <button *ngIf="checkRoleFunction('ASSIGN_STUDENT_TO_COURSE',role) === true" class="btn btn-default btn-md btn-flat mr-2" type="button" mat-button
                            (click)="drawer.toggle()"><i class="fas fa-bars"></i>
                    </button>
                    <p class="teacher mb-0"><strong>Số học viên</strong> {{totalRecord}}</p>
                    <p class="teacher ml-5 mb-0">
                      <!-- <strong>Chức danh</strong> Nhân viên CNTT -->
                    </p></div>
                </div>
                <div class="col-4">
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-8" *ngIf="checkRoleFunction('UNASSIGN_STUDENT_TO_COURSE',role) === true && !courseDetail?.readonly">
                  <div class="btn-group ">
                    <button type="button" matTooltip="Thêm học viên"
                            (click)="openAddSingleStudentPopup()" matTooltipClass="tooltip-red"
                            class="btn btn-default btn-md btn-flat mr-2"><i class="fas fa-user-alt"></i></button>
                    <button type="button" [disabled]="courseDetail?.published === 1 || studentIdsChecked?.length === 0" matTooltip="Loại bỏ"
                            matTooltipClass="tooltip-red" (click)="onUnassignStudentsOnCourse()"
                            class="btn btn-default btn-md btn-flat"><i class="fas fa-trash"></i></button>
                    <span class="pl-1">Số học viên đã chọn: {{studentIdsChecked?.length}}</span>
                  </div>
                </div>
                <!--Search -->
                <div class="col-4 pr-2">
                  <input type="search" class="form-control pr-2" (change)="onSearchStudent($event.target.value)" placeholder="Tìm kiếm học viên">
                </div>
                <div class="table-responsive ">
                  <table class="table table-hover mt-3">
                    <thead>
                    <tr>
                      <th *ngIf="checkRoleFunction('UNASSIGN_STUDENT_TO_COURSE',role) === true && !courseDetail?.readonly">
                        <mat-icon class="mt-1">
                          <mat-checkbox class="example-margin" [disabled]="courseDetail?.published === 1" [(ngModel)]="completedAll"
                                        (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                        </mat-icon>
                      </th>
                      <th>STT</th>
                      <th>Họ tên</th>
                      <th>Chức danh</th>
                      <th>Đơn vị</th>
                      <th *ngIf="checkRoleFunction('UNASSIGN_STUDENT_TO_COURSE',role) === true && !courseDetail?.readonly">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of students; let i = index;">
                      <td *ngIf="checkRoleFunction('UNASSIGN_STUDENT_TO_COURSE',role) === true && !courseDetail?.readonly">
                        <mat-icon class="mt-1">
                          <mat-checkbox [(ngModel)]="item.completed" [disabled]="courseDetail?.published === 1"
                                        (change)="oncheckboxItem(item.id, $event.checked)"
                                        class="example-margin"></mat-checkbox>
                        </mat-icon>
                      </td>
                      <td>{{(searchUser.pageIndex - 1) * searchUser.pageSize + i + 1}}</td>
                      <td><a matTooltip="{{item.firstname + ' ' + item.lastname}}"
                             matTooltipClass="tooltip-inner">{{(item.firstname?.length + item.lastname?.length) <= 30 ? item.firstname + ' ' + item.lastname : (item.firstname + ' ' + item.lastname | slice:0:30) + '...' }}</a>
                      </td>
                      <td matTooltip="{{item.positionname}}"
                          matTooltipClass="tooltip-inner">{{item.positionname?.length <= 30 ? item.positionname : (item.positionname | slice:0:30) + '...' }}</td>
                      <td matTooltip="{{item.departmentname}}"
                          matTooltipClass="tooltip-inner">{{item.departmentname?.length <= 30 ? item.departmentname : (item.departmentname | slice:0:30) + '...' }}</td>
                      <td *ngIf="checkRoleFunction('UNASSIGN_STUDENT_TO_COURSE',role) === true && !courseDetail?.readonly">
                        <button [disabled]="courseDetail?.published === 1" type="button"
                                class="btn btn-default btn-sm mr-1 {{item.id}}" matTooltip="Xóa"
                                (click)="onUnassignStudentOnCourse(item.id)" matTooltipClass="tooltip-red"><i
                          class="fas fa-trash mr-1"></i></button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <br />
                  <p *ngIf="students?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="card-footer text-center">
              <div class="row">
                <mat-paginator showFirstLastButtons
                               [length]="totalRecord"
                               [pageSize]="searchUser.pageSize"
                               [pageIndex]="searchUser.pageIndex - 1 "
                               (page)="changePage($event)"
                               [hidePageSize]="false"
                               [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isNotify" class="example-sidenav-content">
          <div class="card card-default">
            <div class="card-body content-of-topic">
              <div class="row">
                <div class="col-12">
                  <div class="form-inline">
                    <button [hidden]="true" class="btn btn-default btn-md btn-flat mr-2" type="button" mat-button
                            (click)="drawer.toggle()"><i class="fas fa-bars"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-drawer-container>
    </div>
  </div>
</section>

