import {Component, isDevMode, OnInit} from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {NotifycationModel} from '../../../shared/model/notifycation.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../../shared/services/data.service';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalStorageService} from 'ngx-webstorage';
import {USER_ROLE} from '../../../shared/constants/base.constant';
import {NotificationService} from '../../../shared/services/notification.service';

@Component({
  selector: 'app-notify-details',
  templateUrl: './create-notification.component.html',
  styleUrls: ['./create-notification.component.css']
})
export class CreateNotificationComponent implements OnInit {
  public Editor = ClassicEditor;
  inforNotifi: NotifycationModel = {
    title: null,
    content: null,
    userids: null,
    id: null,
    useridfrom: null,
    useridto: null,
    subject: null,
    smallmessage: null,
    timecreated: null,
    timeread: null,
    fullmessage: null,
    fullmessagehtml: null,
    customdata: null,
    createdat: null,
    recipient: null,
    selected: null,
  };
  notify: NotifycationModel = {
    title: null,
    content: null,
    userids: null,
    id: null,
    useridfrom: null,
    useridto: null,
    subject: null,
    smallmessage: null,
    timecreated: null,
    timeread: null,
    fullmessage: null,
    fullmessagehtml: null,
    customdata: null,
    createdat: null,
    recipient: null,
    selected: null,
  };

  listNotifyTemplate: any[] = [];
  readyLoad: boolean;
  isUpdate: boolean;
  courseIdsString: string;
  examIdsString: string;
  nameNoti: string;
  contentNoti: string;
  departmentids: any;
  positionids: any;
  userids: number[] = [];
  sortcolumn = 'id';
  sorttype = 'asc';

  showFullRoles = false;

  constructor(
    private route: ActivatedRoute,
    private notifycationService: NotificationService,
    private modalService: NgbModal,
    private dataService: DataService,
    private toastrService: ToastrCustomService,
    private translateService: TranslateService,
    private $localStorage: LocalStorageService,
    private router: Router,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.readyLoad = false;
    const roleUser = this.$localStorage.retrieve('roles')[0].shortname;
    this.showFullRoles = roleUser !== USER_ROLE.TEACHER;
    if (this.route.snapshot.paramMap.get('id') !== undefined && this.route.snapshot.paramMap.get('id') !== null
      && this.route.snapshot.paramMap.get('id') !== '') {
      this.isUpdate = true;
      this.notifycationService.searchNotifyGeneral(0, 0, this.sortcolumn, this.sorttype, null, +this.route.snapshot.paramMap.get('id'))
        .subscribe(res => {
          const result: any = res.body;
          this.listNotifyTemplate = result.results;
          this.inforNotifi = this.listNotifyTemplate[0];
          this.readyLoad = true;
        }, error => {
        });
    } else {
      this.readyLoad = true;
    }

    this.dataService.onDataChange((value) => {
      this.courseIdsString = value;
    });

    this.dataService.onDataChangeExam((value) => {
      this.examIdsString = value;
    });

    this.dataService.nameChange((value) => {
      this.nameNoti = value;
    });

    this.dataService.onContentChange((value) => {
      this.contentNoti = value;
    });
  }

  createNotity() {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('notification.send_notify_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('notification.send_notify_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.send_notify');

    modalDep.result.then((result) => {
      this.spinner.show();
      if (this.examIdsString === undefined || this.examIdsString === null) {
        this.examIdsString = '';
      }
      if (this.courseIdsString === undefined || this.courseIdsString === null) {
        this.courseIdsString = '';
      }
      if (this.departmentids === undefined) {
        this.departmentids = '';
      }
      if (this.positionids === undefined) {
        this.positionids = '';
      }
      if (this.userids === undefined) {
        this.userids = [];
      }
      this.notify.title = this.nameNoti;
      this.notify.content = this.contentNoti;
      if (this.notify.title === undefined || this.notify.title === null) {
        this.spinner.hide();
        this.toastrService.error('notification.need_input_title_name');
        return;
      } else {
        if (this.notify.title === '') {
          this.spinner.hide();
          this.toastrService.error('notification.need_input_title_name');
          return;
        }
        if (this.notify.title.trim().length === 0) {
          this.spinner.hide();
          this.toastrService.error('notification.need_input_title_name_invalid');
          return;
        }
        if (this.notify.title.trim().length > 200) {
          this.spinner.hide();
          this.toastrService.error('notification.need_input_title_name_invalid_max_length');
          return;
        }
      }

      if (this.notify.content === undefined || this.notify.content === null) {
        this.spinner.hide();
        this.toastrService.error('notification.need_input_content_name');
        return;
      } else {
        if (this.notify.content === '') {
          this.spinner.hide();
          this.toastrService.error('notification.need_input_content_name');
          return;
        }
        if (this.notify.content.trim().length === 0) {
          this.spinner.hide();
          this.toastrService.error('notification.need_input_title_content_invalid');
          return;
        }
      }

      if (this.courseIdsString === '' && this.examIdsString === ''
        && this.departmentids === '' && this.positionids === '' && this.userids.length === 0) {
        this.spinner.hide();
        this.toastrService.error('notification.need_select_receiver');
        return;
      }

      if (!this.showFullRoles && (this.courseIdsString || this.examIdsString)) {
        let numberCSIDs = 0;
        let numberEXIDs = 0;
        if (this.courseIdsString !== ''){
          numberCSIDs = this.courseIdsString.split(',').length;
        }
        if (this.examIdsString !== ''){
          numberEXIDs = this.examIdsString.split(',').length;
        }
        if ((numberCSIDs + numberEXIDs) > 1){
          this.spinner.hide();
          this.toastrService.error('error.notificationchoseonlyone');
          return;
        }
      }

      this.notifycationService
        .createNotifySingle(this.courseIdsString, this.notify.title, this.notify.content, this.examIdsString.toString(),
          this.positionids.toString(), this.departmentids.toString(), this.userids.toString())
        .subscribe(res => {
          this.spinner.hide();
          const notify: any = res.body;
          if (isDevMode()) {
            console.log('notify: ', notify);
          }
          this.notifycationService.changeMessage(1);
          this.router.navigate(['/admin/notification']);
          this.toastrService.success('common.noti.send_message_success');
        }, error => {
          this.spinner.hide();
          if (isDevMode()) {
            console.log(error);
          }
          this.toastrService.handlerError(error);
        });
    });
  }

  getPositionIds($event: any) {
    this.positionids = $event;
  }

  getDepartmentIds($event: any) {
    this.departmentids = $event;
  }

  getUserIds($event){
    this.userids = $event;
  }
}
