import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrCustomService} from '../shared/services/toastr-custom.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalComponent} from '../shared/modal/confirm-modal/confirm-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from '../shared/services/login.service';
import {CustomValidators} from '../shared/utils/custom-validators';
import CommonUtil from '../shared/utils/common-util';
import {InfoModalComponent} from '../shared/modal/info-modal/info-modal.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm = this.fb.group({
    newpassword: ['',
      [
        Validators.required,
        Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,36}$')
      ]
    ],
    confirmpassword: ['', Validators.required],
  }, {
    validators: CustomValidators.matchPassword()
  });

  token: string;

  username: any = 'username';

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private router: Router,
    private translateService: TranslateService,
    private loginService: LoginService
  ) {
  }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParams.token;
    const params = {
      token: this.token
    };
    this.spinner.show();
    this.loginService.checkForgotPasswordToke(params).subscribe(
      res => {
        // case normal
        this.spinner.hide();
      },
      error => {
        // case invalid token
        this.spinner.hide();
        const modalDep = this.modalService.open(InfoModalComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static'
        });
        modalDep.componentInstance.title = this.translateService.instant('warning');
        // tslint:disable-next-line:max-line-length
        modalDep.componentInstance.body = this.translateService.instant('error.forgot_password.tokeninvalid');
        modalDep.componentInstance.confirmButton = this.translateService.instant('common.confirm');

        modalDep.result.then((result) => {
          this.backToLogin();
        });

      }
    );
  }

  backToLogin() {
    this.router.navigate(['/home']);
  }

  showConfirmPopup() {
    if (this.resetPasswordForm.invalid) { // show error
      CommonUtil.markFormGroupTouched(this.resetPasswordForm);
      return;
    }

    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('login.reset_password_confirm_popup.title');
    // tslint:disable-next-line:max-line-length
    modalDep.componentInstance.body = this.translateService.instant('login.reset_password_confirm_popup.content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.confirm');

    modalDep.result.then((result) => {
      this.resetPassword();
      this.router.navigate(['/home']);
    });
  }


  resetPassword() {
    const params = {
      password: this.resetPasswordForm.value.newpassword,
      token: this.token
    };
    this.spinner.show();
    this.loginService.resetPassword(params).subscribe(
      res => {
        this.spinner.hide();
        this.toastrService.success(`login.reset_password_success`);
        this.backToLogin();
      },
      error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      }
    );
  }
}
