import {HttpClient, HttpResponse} from '@angular/common/http';
import {UserInfo} from '../model/user-info.model';
import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {MOODLE_INFO} from '../constants/base.constant';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SessionStorageService} from 'ngx-webstorage';
import {RoleSystem} from '../model/role-system';
import {Course} from '../model/course.model';
import {Menu} from '../model/menu';


type EntityResponseType = HttpResponse<UserInfo>;

interface RoleMenuResponse {
  roles: RoleSystem[];
  menus: Menu[];
}

@Injectable({providedIn: 'root'})
export class AccountService {
  constructor(private abstractService: AbstractService,
  ) {
  }

  getAccount(): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('core_webservice_get_site_info');
    return this.abstractService.post<UserInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  getAccountRole(): Observable<HttpResponse<RoleMenuResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_role_get_account_role');
    return this.abstractService.post<RoleMenuResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<RoleMenuResponse>) => res));
  }
}
