import {FormBuilder, Validators, AbstractControl, FormGroup, FormArray} from '@angular/forms';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import {Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges} from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {DataService} from 'src/app/shared/services/data.service';
import {CustomValidators} from '../../../../shared/utils/custom-validators';
import {CKEDITOR_CONFIG} from '../../../../shared/constants/ckeditor.constant';
import {ToastrCustomService} from '../../../../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {QuestionBankService} from '../../../../shared/services/question-bank.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmModalComponent} from '../../../../shared/modal/confirm-modal/confirm-modal.component';
import { v1 as uuidv1 } from 'uuid';
import { TOPIC_CONST } from 'src/app/shared/constants/base.constant';


@Component({
  selector: 'app-multichoiceset',
  templateUrl: './multichoiceset.component.html',
  styleUrls: ['./multichoiceset.component.css']
})
export class MultichoiceSetComponent implements OnInit, OnChanges {


  @Input() panel: any;
  @Input() index: any;
  @Input() questionInput: any;
  @Output() messageEvent = new EventEmitter<any>();
  @Output() editQuestionIndex = new EventEmitter<number>();

  @Input() data: any;
  @Output() deleteQuestionEvent = new EventEmitter<any>();
  @Output() createQuestionEvent = new EventEmitter<any>(); // logic for question bank only
  @Output() cloneQuestion = new EventEmitter<any>();

  question: any;
  canEdit = false;
  isPublished = true;
  moduleId: number;
  categoryId: number;
  isViewMode = false;
  qindex = -1;

  public Editor = ClassicEditor;
  editorData = '';
  formEnable = true;
  anwserNumber = 0;
  questionForm: FormGroup;
  isLevel1 = true;
  isLevel2 = false;
  isLevel3 = false;

  questionEditorConfig = CKEDITOR_CONFIG.DEFAULT;
  answerEditorConfig = CKEDITOR_CONFIG.NOT_UPLOAD;
  TOPIC_CONST = TOPIC_CONST;

  constructor(
    private fb: FormBuilder,
    public dataService: DataService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private questionBankService: QuestionBankService,
    private modalService: NgbModal,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setData();
    this.initForm(this.question);
    if (this.question?.id) {
      this.isViewMode = true;
      this.onUnEditForm();
    }

    // this.questionForm.valueChanges.subscribe((value) => {
    //   if (this.questionForm.status === 'DISABLED') {
    //     return;
    //   }
    //   value.index = this.index;
    //   this.messageEvent.emit(value);
    // });
    //
    // this.dataService.currentMessMulti?.subscribe(value => {
    //   if (value && value[0] === this.index && (this.questionInput || value[1])) {
    //     if (this.questionForm.status === 'DISABLED') {
    //       this.questionForm.enable();
    //       this.formEnable = true;
    //       this.editQuestionIndex.emit(this.index);
    //     } else if (this.questionForm.status !== 'DISABLED' && (value[1] || this.questionInput?.id)) {
    //       this.questionForm.disable();
    //       this.formEnable = false;
    //     }
    //   }
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.qindex = this.data.index;
    this.categoryId = this.data.categoryId;
  }

  setData(){
    this.question = this.data.question;
    this.canEdit = this.data.canEdit;
    this.isPublished = this.data.isPublished;
    this.moduleId = this.data.moduleId;
    this.categoryId = this.data.categoryId;
    this.qindex = this.data.index;
  }

  initForm(question: any) {
    this.questionForm = this.fb.group({
      id: [],
      // name: ['', [Validators.required, CustomValidators.notBlankValidator, Validators.maxLength(255)]],
      questiontext: ['', [Validators.required, CustomValidators.notBlankValidator]],
      defaultmark: [1],
      answernumbering: ['ABCD'],
      index: [],
      level: ['', Validators.required],
      single: [0],
      answer: this.fb.array([])
    });
    if (question) {
      const keyForm = Object.keys(this.questionForm.value);
      for (const key of keyForm) {
        if (key === 'answer') {
          question[key].forEach((answer) => {
            this.addAnswer(answer);
          });
        } else {
          this.questionForm.controls[key].setValue(question[key]);
        }
      }
    } else {
      this.addAnswer();
    }
  }

  get answer(): FormArray {
    return this.questionForm.get('answer') as FormArray;
  }

  newAnwser(answer?: any): FormGroup {
    const form = this.fb.group({
      text: ['', [Validators.required, CustomValidators.notBlankValidator]],
      grade: ['0.0']
    });
    if (answer) {
      form.controls.text.setValue(answer.text);
      if (answer.grade.toString().indexOf('.') === -1) {
        form.controls.grade.setValue(answer.grade.toFixed(1));
      } else {
        form.controls.grade.setValue(answer.grade);
      }
    }
    return form;
  }

  addAnswer(answer?: any) {
    this.anwserNumber += 1;
    this.answer.push(this.newAnwser(answer));
  }

  removeAnswer(i: number) {
    this.answer.removeAt(i);
  }

  onChangeCkeditorAnswer({editor}: ChangeEvent, indexAnswer?: number) {
    if (!editor) {
      return;
    }
    const data = editor.getData();
    if (data) {
      this.questionForm.value.answer[indexAnswer].text = data;
    }
  }

  changeLevel(levelid) {
    if (levelid === 1) {
      this.isLevel1 = true;
      this.isLevel2 = false;
      this.isLevel3 = false;
    } else if (levelid === 2) {
      this.isLevel1 = false;
      this.isLevel2 = true;
      this.isLevel3 = false;
    } else {
      this.isLevel1 = false;
      this.isLevel2 = false;
      this.isLevel3 = true;
    }
    this.questionForm.controls.level.setValue(levelid);
  }

  onEditForm() {
    this.formEnable = true;
    this.questionForm.enable();
  }

  onUnEditForm() {
    this.formEnable = false;
    this.questionForm.disable();
  }


  onSaveQuestion(question: any){
    question.quizid = this.moduleId;
    question.categoryid = this.categoryId;
    question.qtype = 'multichoiceset';
    if (question.questiontext.search('<img') !== -1) {
      question.questiontext = question.questiontext.replaceAll('<img', '<img class="img-fluid"');
    }
    question.answer?.forEach(ans => {
      if (ans.text.search('<img') !== -1) {
        // nếu ảnh chưa có alt thì add thêm, nếu ảnh đã có alt thì replate để tránh gây lỗi khi làm bài thi có 2 ảnh có alt bị trùng
        if (ans.text.search('alt="') === -1) {
          ans.text = ans.text.replaceAll('<img', '<img class="img-fluid" alt="' + uuidv1() + '"');
        } else {
          ans.text = ans.text.replaceAll('<img', '<img class="img-fluid"').replaceAll('></figure>', ' alt="' + uuidv1() + '"></figure>');
        }
      }
    });

    if (!this.question?.id){
      this.onCreateQuestion(question);
    } else {
      this.onUpdateQuestion(question);
    }
  }

  onCreateQuestion(question){
    this.spinner.show();
    this.questionBankService.createQuestion(question).subscribe(
      res => {
        this.question = res.body;
        this.onUnEditForm();
        this.initForm(this.question);
        this.questionForm.disable();
        this.isViewMode = true;
        this.createQuestionEvent.emit(this.qindex);
        this.toastrService.success(`common.noti.create_success`);
        this.spinner.hide();
      },
      err => {
        this.toastrService.handlerError(err);
        this.spinner.hide();
      });
  }

  onUpdateQuestion(question){
    this.spinner.show();
    this.questionBankService.updateQuestion(question).subscribe(
      res => {
        this.question = question;
        this.question.questiontext = res.body.questiontext;
        this.onUnEditForm();
        this.initForm(this.question);
        this.questionForm.disable();
        this.isViewMode = true;
        this.toastrService.success(`common.noti.update_success`);
        this.spinner.hide();
      },
      err => {
        this.toastrService.handlerError(err);
        this.spinner.hide();
      });
  }

  onRemoveQuestion(){
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('question.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('question.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        if (this.question?.id) {
          const params = {
            questionids: this.question.id,
            quizid: this.moduleId
          };
          this.spinner.show();
          this.questionBankService.deleteQuestionOnExam(params).subscribe(
            res => {
              this.deleteQuestionEvent.emit(this.qindex);
              this.toastrService.success(`common.noti.delete_success`);
              this.spinner.hide();
            },
            err => {
              this.spinner.hide();
              this.toastrService.handlerError(err);
            });
        } else {
          this.deleteQuestionEvent.emit(this.qindex);
        }

      }
    });
  }

  onTurnOnEditmode(){
    this.spinner.show();
    this.questionBankService.prepareQuestiontextForUpdate(this.question.id).subscribe(
      res => {
        this.isViewMode = false;
        this.onEditForm();
        this.questionForm.patchValue({
          questiontext: res.body.questiontext
        });
        this.spinner.hide();
      }, error => {
        this.toastrService.handlerError(error);
        this.spinner.hide();
      }
    );
  }

  onCloneQuestion(){
    this.cloneQuestion.emit({value: this.questionForm.value, isViewMode: this.isViewMode});
  }

}
