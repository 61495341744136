import { USER_INFO, USER_FUNCTIONS, USER_ROLE, LOCAL_STORAGE_KEY } from './../../shared/constants/base.constant';
import CommonUtil from 'src/app/shared/utils/common-util';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrCustomService} from './../../shared/services/toastr-custom.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmModalComponent} from '../../shared/modal/confirm-modal/confirm-modal.component';
import {CourseCreateComponent} from './course-create/course-create.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {CourseService} from '../../shared/services/course.service';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import * as moment from 'moment';
import {CourseSearchComponent} from './course-search/course-search.component';
import {LocalStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';


@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit, OnDestroy {

  courses = new Array();
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  completedAll: boolean = false;
  isManage: boolean = false;
  role: string;
  managedDepartmentId: number;
  // list id course checked trong cả màn
  courseIdsChecked: number[] = [];
  // list id course checked trong current page
  courseIds: number[] = [];
  courseCanChecked: number = 0;

  // MatPaginator Output
  pageEvent: PageEvent;

  currentDate: Date = new Date();

  searchCourse = {
    search: '',
    startDate: null,
    endDate: null,
    categoryid: null,
    pageIndex: 1,
    pageSize: 10,
    sortColumn: 'timecreated',
    sortType: 'desc',
    coursetype: 1,
    departmentId: null,
    positionIds: [],
    positionData: [],
    status: null
  };

  constructor(
    private courseService: CourseService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private $localStorage: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.role = this.$localStorage.retrieve(USER_INFO.ROLES)[0].shortname;
    const roles = this.$localStorage.retrieve(USER_INFO.ROLES);
    if (roles) {
      for (const role of roles) {
        if (role.shortname === USER_ROLE.MANAGER) {
          this.isManage = true;
        }
      }
    }

    const currentSearch = this.$localStorage.retrieve(LOCAL_STORAGE_KEY.SEARCH.COURSE_SEARCH);
    if (currentSearch) {
      // Kiem tra xem truoc do co luu du lieu tim kiem khong
      this.searchCourse.search = currentSearch.search;
      this.searchCourse.startDate = currentSearch.startDate;
      this.searchCourse.endDate = currentSearch.endDate;
      this.searchCourse.categoryid = currentSearch.categoryid;
      this.searchCourse.pageIndex = currentSearch.pageIndex;
      this.searchCourse.pageSize = currentSearch.pageSize;
      this.searchCourse.sortColumn = currentSearch.sortColumn;
      this.searchCourse.sortType = currentSearch.sortType;
      this.searchCourse.positionIds = currentSearch.positionIds;
      this.searchCourse.departmentId = currentSearch.departmentId;
      this.searchCourse.status = currentSearch.status;
      this.searchCourse.positionData = currentSearch.positionData;
      // Xoa di sau khi su dung
      this.$localStorage.clear(LOCAL_STORAGE_KEY.SEARCH.COURSE_SEARCH);
    }
    this.getListCourse();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  openCourseCreatePopup() {
    const modalDep = this.modalService.open(CourseCreateComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
  }

  openCourseSearchPopup() {
      const modalDep = this.modalService.open(CourseSearchComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalDep.componentInstance.searchCourse = this.searchCourse;

      modalDep.result.then((result) => {
        if (result) {
          // const teacherIds = [];
          const positionIds = [];
          if (result.startDate) {
            this.searchCourse.startDate = result.startDate.getTime() / 1000;
          } else {
            this.searchCourse.startDate = null;
          }
          if (result.endDate) {
            this.searchCourse.endDate = result.endDate.getTime() / 1000;
          } else {
            this.searchCourse.endDate = null;
          }
          if (result.departmentId) {
            this.searchCourse.departmentId = result.departmentId;
          } else {
            this.searchCourse.departmentId = null;
          }

          if (result.positionData) {
            this.searchCourse.positionData = result.positionData;
          } else {
            this.searchCourse.positionData = null;
          }

          if (result.status) {
            this.searchCourse.status = parseInt([result.status].toString());
          } else {
            this.searchCourse.status = null;
          }

          result.positionData?.forEach((position) => {
            positionIds.push(position.id);
          });

          if (positionIds.length > 0) {
            this.searchCourse.positionIds = positionIds;
          } else {
            this.searchCourse.positionIds = null;
          }
          this.searchCourse.pageIndex = 1;
          this.courseIdsChecked = [];
          this.getListCourse();
        }
      });
  }

  onChangeKeyword() {
    this.searchCourse.pageIndex = 1;
    this.courseIdsChecked = [];
    this.getListCourse();
  }

  getListCourse() {
    // search string  Tùy ý //search by name
    // categoryid int  Tùy ý //category id
    // startdate int  Tùy ý //time start
    // enddate int  Tùy ý //time end
    // coursetype int  Mặc định thành "1" //coursetype  1: course | 2: exam | 3: survey template | 4: survey
    // departmentids list of int // department ID
    // teacherids list of int // teacher ID
    // positionids list of int  // position ID
    const params = {
      limit: this.searchCourse.pageSize,
      page: this.searchCourse.pageIndex,
      sortcolumn: this.searchCourse.sortColumn,
      sorttype: this.searchCourse.sortType,
      startdate: this.searchCourse.startDate,
      enddate: this.searchCourse.endDate,
      // teacherids: teacherIds,
      positionids: this.searchCourse.positionIds,
      search: this.searchCourse.search,
      coursetype: this.searchCourse.coursetype,
      departmentids: this.searchCourse.departmentId,
      status: this.searchCourse.status
    };
    this.spinner.show();
    this.courseCanChecked = 0;
    this.courseIds = [];
    this.courseService.searchCourses(params).subscribe(data => {
      this.managedDepartmentId = data.body.manageddepartmentid;
      data.body?.results?.forEach(course => {
          course.teacherName = CommonUtil.convertTeacherName(course.teachers);
          if (this.currentDate > moment.unix(course.enddate).toDate()) {
            course.status = 'Đã kết thúc';
            course.style = 'success';
          }
          if (this.currentDate < moment.unix(course.startdate).toDate()) {
            course.status = 'Chưa bắt đầu';
            course.style = 'secondary';
          }
          if (moment.unix(course.startdate).toDate() < this.currentDate && this.currentDate < moment.unix(course.enddate).toDate()) {
            course.status = 'Đang diễn ra';
            course.style = 'info';
          }

          // khi next trang, nếu đã có student được check từ trước thì set checked cho student đó
          if (this.courseIdsChecked?.includes(course.id)) {
            course.completed = true;
            this.courseIds?.push(course.id);
          }
          if (!this.checkDisable(course) && ((this.isManage && this.managedDepartmentId == course.refdepartmentid) || !this.isManage)) {
            this.courseCanChecked++;
          }
      });
      // nếu số khóa học được checked = số khóa học có thể checked thì set completedAll = true
      if (this.courseIds?.length > 0 && (this.courseIds?.length === this.courseCanChecked)) {
        this.completedAll = true;
      } else {
        this.completedAll = false;
      }
      this.courses = data.body.results;
      this.totalRecord = data.body.total;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastrService.handlerError(err);
    });
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.courses.forEach(c => {
        // nếu user là QLDV thì chỉ checked vào khóa học do đơn vị nó quản lý
        if ((this.isManage && this.managedDepartmentId === c.refdepartmentid) || !this.isManage) {
          if (this.checkDisable(c)) {
            c.completed = !checked;
          } else {
            c.completed = checked;
            if (!this.courseIds.includes(c.id)) {
              this.courseIds.push(c.id);
            }
            if (!this.courseIdsChecked?.includes(c.id)) {
              this.courseIdsChecked?.push(c.id);
            }
          }
        }
      });
      this.completedAll = true;
    } else {
      this.courseIds?.forEach(id => {
        this.courseIdsChecked?.splice(this.courseIdsChecked?.indexOf(id), 1);
      });
      this.courseIds = [];
      this.courses?.forEach(c => {
        c.completed = false;
      });
      this.completedAll = false;
    }
  }

  oncheckboxItem(courseId: number, checked: any) {
    if (checked) {
      this.courses?.forEach(c => {
        if (c.id === courseId) {
          c.completed = true;
          this.courseIds?.push(courseId);
          this.courseIdsChecked?.push(courseId);
          return;
        }
      });
      // nếu số khóa checked bằng số khóa học có thể checked trong page thì set checkboxall = true
      if (this.courseIds?.length > 0 && this.courseIds?.length === this.courseCanChecked && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.completedAll = false;
      this.courses?.forEach(c => {
        if (c.id === courseId) {
          c.completed = false;
          this.courseIds?.splice(this.courseIds?.indexOf(courseId), 1);
          this.courseIdsChecked?.splice(this.courseIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
  }

  onDeleteSingleCourse(courseId: number) {
    this._onDeleteCourses([courseId]);
  }

  onDeleteMultipleCourse() {
    this._onDeleteCourses(this.courseIdsChecked);
  }

  _onDeleteCourses(courseIds?: number[]) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('course.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('course.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalDep.result.then((result) => {
      if (result === 'confirm' && courseIds?.length > 0) {
        this.spinner.show();
        this.courseService.deleteCourse(courseIds).subscribe(
          res => {
            this.toastrService.success(`common.noti.delete_success`);
            [...courseIds]?.forEach(courseId => {
              this.courseIds?.splice(this.courseIds?.indexOf(courseId), 1);
              this.courseIdsChecked?.splice(this.courseIdsChecked?.indexOf(courseId), 1);
            });
            this.getListCourse();
            this.spinner.hide();
          },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          });
      }
    });
  }

  sortData(sort: Sort) {
    this.searchCourse.pageIndex = 1;
    this.searchCourse.sortColumn = sort.active;
    this.searchCourse.sortType = sort.direction;
    this.getListCourse();
  }

  changePage(event) {
    this.searchCourse.pageIndex = event.pageIndex + 1;
    this.searchCourse.pageSize = event.pageSize;
    this.getListCourse();
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  goToCourseDetail(courseid) {
    this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.COURSE_SEARCH, this.searchCourse);
    this.router.navigate(['/admin/course/detail', courseid]);
  }

  checkDisable(c) {
    return (moment.unix(c.startdate).toDate() < this.currentDate && this.currentDate < moment.unix(c.enddate).toDate() && c.published === 1) || c.published === 1;
  }

  checkRoleFunction(userFunction:any,role:any):boolean{
    return USER_FUNCTIONS[userFunction].includes(role)
  }

}
