import {Injectable} from '@angular/core';
import {IndividualConfig, ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ToastrCustomService {
  constructor(protected toastr: ToastrService, public translate: TranslateService) {
  }

  show(message?: string, title?: string, override?: Partial<IndividualConfig>, type?: string) {
    this.translate.get(message).subscribe(result => {
      this.toastr.show(result, title, override, type);
    }, () => {
      this.toastr.show(message, title, override, type);
    });
  }

  success(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    this.translate.get(message).subscribe(result => {
      this.toastr.success(result, title, override);
    }, () => {
      this.toastr.success(message, title, override);
    });
  }

  successCate(message?: string,variable?:any, title?: string, override?: Partial<IndividualConfig>) {
    this.translate.get(message).subscribe(result => {
      this.toastr.error(result + variable, title, override);
    }, () => {
      this.toastr.error(message + variable, title, override);
    });
  }

  error(message?: string, title?: string) {
    this.translate.get(message).subscribe(result => {
      this.toastr.error(result, title);
    }, () => {
      this.toastr.error(message, title);
    });
  }

  info(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    this.translate.get(message).subscribe(result => {
      this.toastr.info(result, title, override);
    }, () => {
      this.toastr.info(message, title, override);
    });
  }

  warning(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    this.translate.get(message).subscribe(result => {
      this.toastr.warning(result, title, override);
    }, () => {
      this.toastr.warning(message, title, override);
    });
  }

  // uploadFileError(message?: string, param?: string) {
  //   this.translate.get(message, param).subscribe(result => {
  //     this.toastr.error(result);
  //   }, () => {
  //     this.toastr.error(message);
  //   });
  // }

  handlerError(err){
    if (err && err.error && err.error.errorcode) {
      this.error(`error.${err.error.errorcode}`);
    } else {
      this.error('error.common');
    }
  }
}
