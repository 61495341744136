<div class="row d-block">
  <h5>{{courseModule.name}}</h5>
  <small class="text-dark">Giảng viên: {{teachers?.length > 100 ? (teachers | slice:0:100) : teachers}}</small>
</div>
<br>
<div [innerHTML]="courseModule.intro"></div>
<div class="col-12">
  <p *ngIf="openingtime"> Thời gian bắt đầu: {{openingtime| date:'HH:mm dd/MM/yyyy'}}</p>
  <p *ngIf="closingtime"> Thời gian kết thúc: {{closingtime| date:'HH:mm dd/MM/yyyy'}}</p>
  <button type="button" data-toggle="modal"
          class="btn btn-primary "
          *ngIf="currentDate >= canJoinMeetingTime && currentDate <= closingtime"
          (click)="joinMeeting()">Tham gia bài giảng</button>
</div>
<div class="col-12">Bài giảng đã ghi: {{bbbRecordings.length}} </div>
<div class="col-12">
  <table class="table table-hover">
    <thead>
    <tr>
      <th>{{'bbbrecording.title_header_no' | translate}}</th>
      <th>{{'bbbrecording.title_header_playback' | translate}} </th>
      <th>{{'bbbrecording.title_header_name' | translate}} </th>
      <th>{{'bbbrecording.title_header_description' | translate}}</th>
      <th>{{'bbbrecording.title_header_preview' | translate}}</th>
      <th>{{'bbbrecording.title_header_date' | translate}}</th>
      <th>{{'bbbrecording.title_header_duraion' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let record of bbbRecordings; let i = index;">
      <td>{{i + 1}}</td>
      <td>
          <span matTooltip="" matTooltipClass="tooltip-inner">
            <a [href]="record.playbackurl" target="_blank">{{'bbbrecording.button_presentation' | translate}}</a>
          </span>
      </td>
      <td>
          <span matTooltip="{{record.name}}" matTooltipClass="tooltip-inner">
            {{record.name}}
          </span>
      </td>
      <td>
          <span matTooltip="{{record.description}}" matTooltipClass="tooltip-inner">
            {{record.description}}
          </span>
      </td>
      <td>
        <img *ngFor="let previewimg of record.preview"
             width="{{previewimg.width/1.5}}" height="{{previewimg.height/1.5}}"
             class="zoom"
             src="{{previewimg?.url}}" alt="{{previewimg?.alt}}">
      </td>
      <td>
          <span matTooltip="{{record.date | date:'HH:mm dd/MM/yyyy'}}" matTooltipClass="tooltip-inner">
            {{record.date | date:'HH:mm dd/MM/yyyy'}}
          </span>
      </td>
      <td>
          <span matTooltip="{{record.duration}}" matTooltipClass="tooltip-inner">
            {{record.duration}}
          </span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
