<div class="container-fluid">
  <div class="card card-default">
    <div class="card-body">


      <form [formGroup]="createFormNotify">
        <div class="row pl-5 pr-5">
          <div class="col-12">
            <div class="form-group">
              <label>Tiêu đề thông báo</label><span class="text-danger"> *</span>
              <div>
                <input type="text" class="form-control mb-2"
                       placeholder="Tiêu đề thông báo" formControlName="titleNotify" (change)="getName()"/>
              </div>
              <div>
                <small class="text-danger">
                </small>
                <small class="text-danger"></small>
              </div>
            </div>
          </div>
          <div class="col-12 mt-2">
            <div class="form-group">
              <label>Nội dung</label><span class="text-danger"> *</span>
              <ckeditor [editor]="Editor" data="<p>Hello, world!</p>" formControlName="contentNoti" (change)="getContent()" [config]="editorConfig"></ckeditor>
            </div>
          </div>
          <div class="col-12 mt-2" *ngIf="isUpdate">
            <div class="form-group">
              <label>Mô tả</label>
              <ckeditor [editor]="Editor" data="{{newInforNotifi.description}}" [config]="editorConfig" [disabled]="true">
              </ckeditor>
            </div>
          </div>
          <div class="col-6">
          </div>
        </div>
      </form>

    </div>
    <div class="card-footer text-center">
      <button type="button" class="btn btn-light btn-lg mr-2" (click)="navigationPage()">
        <i class="fas fa-cancel"></i>Hủy
      </button>
<!--      <button type="button" class="btn btn-primary btn-lg" (click)="createNotity()" *ngIf="!isUpdate" value="{{idsCourseAndIdsExam}}">-->
<!--        <i class="fas fa-paper-plane mr-2"></i>Gửi Thông Báo-->
<!--      </button>-->
      <button type="button" class="btn btn-success btn-lg" (click)="updateNotity()" *ngIf="isUpdate"><i
        class="fas fa-save mr-2"></i>Cập Nhật
      </button>
      <button type="button" class="btn btn-primary btn-lg" (click)="activeOrInactiveNotiTemplate()" *ngIf="isUpdate" style="margin-left: 1%;"><i class="fab fa-creative-commons-pd-alt mr-2"></i>{{newInforNotifi.isactive == 1 ? 'Ẩn thông báo': 'Kích hoạt'}}
      </button>
    </div>
  </div>

</div>
