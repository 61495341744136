import {HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CourseCategory} from '../model/course-category.model';
import {MOODLE_INFO} from '../constants/base.constant';
import CommonUtil from '../utils/common-util';
import {map} from 'rxjs/operators';
import {AbstractService} from './abstract.service';
import {Injectable} from '@angular/core';

type EntityResponseType = HttpResponse<CourseCategory>;
type EntityArrayResponseType = HttpResponse<CourseCategory[]>;

@Injectable({providedIn: 'root'})
export class CourseCategoryService {

  constructor(private abstractService: AbstractService) {

  }

  createCourseCategory(courseCategory: CourseCategory): Observable<EntityResponseType> {

    const data = this.abstractService.getCommonInput('mobifone_course_create_categories');
    const categoryArray: CourseCategory[] = [];

    categoryArray.push(courseCategory);
    CommonUtil.appendDataToFormData(data, 'categories', categoryArray);
    return this.abstractService.post<CourseCategory>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updateCourseCategory(courseCategory: CourseCategory): Observable<HttpResponse<null>> {

    const data = this.abstractService.getCommonInput('mobifone_course_update_categories');
    CommonUtil.appendDataToFormData(data, 'categories[0]', courseCategory);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<null>) => res));
  }

  deleteCourseCate(courseIds: any): Observable<any> {
    let strid = String(courseIds);
    let deleteIds = {
      ids: strid
    };
    const dataCourse = this.abstractService.getCommonInput('mobifone_course_delete_categories');
    CommonUtil.appendDataToFormData(dataCourse, 'params', deleteIds);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: any) => res));
  }

  searchCourseCategory(category: any): Observable<any> {
    const searchData = this.abstractService.getCommonInput('mobifone_course_get_categories');
    CommonUtil.appendDataToFormData(searchData, 'params', category);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, searchData).pipe(map((res: any) => res));
  }

  getCourseCategoryTree(params): Observable<EntityArrayResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_course_get_all_categories_tree');
    CommonUtil.appendDataToFormData(data, 'id', params);
    return this.abstractService.post<CourseCategory[]>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponseType) => res));
  }
}
