import { LOCAL_STORAGE_KEY } from './../../../shared/constants/base.constant';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import {Survey} from '../../../shared/model/survey';
import {SurveyService} from '../../../shared/services/survey.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {PageEvent} from '@angular/material/paginator';
import {NgxSpinnerService} from 'ngx-spinner';
import {SurveySingleCreateComponent} from '../survey-single-create/survey-single-create.component';
import {Router} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import {Sort} from '@angular/material/sort';
import {SingleSurveySearchComponent} from '../single-survey-search/single-survey-search.component';
import {FileService} from '../../../shared/services/file.service';

@Component({
  selector: 'app-survey-general',
  templateUrl: './survey-general.component.html',
  styleUrls: ['./survey-general.component.css']
})
export class SurveyGeneralComponent implements OnInit, OnDestroy {

  showCreateSingleSurvey: boolean;
  idSurveyUpdate: any;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  completedAll = false;
  lastSort: any;
  pageEvent: PageEvent;
  surveySingleList: Survey[] = [];
  date: any;
  surveyIdsChecked: number[] = [];
  surveyIds: number[] = [];
  surveyIdsCanCheck: number = 0;
  searchSurvey = {
    search: '',
    pageIndex: 1,
    pageSize: 10,
    sortColumn: 'id',
    sortType: 'desc',
    refdepartmentid: null,
    startDate: null,
    endDate: null,
    positionIds: [],
    positionData: [],
    status: null,
  };

  constructor(
    private surveyService: SurveyService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private $localStorage: LocalStorageService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    const currentSearch = this.$localStorage.retrieve(LOCAL_STORAGE_KEY.SEARCH.SURVEY_SINGLE_SEARCH);
    if (currentSearch) {
      // Kiem tra xem truoc do co luu du lieu tim kiem khong
      this.searchSurvey.search = currentSearch.search;
      this.searchSurvey.pageIndex = currentSearch.pageIndex;
      this.searchSurvey.pageSize = currentSearch.pageSize;
      this.searchSurvey.sortColumn = currentSearch.sortColumn;
      this.searchSurvey.sortType = currentSearch.sortType;
      this.searchSurvey.refdepartmentid = currentSearch.refdepartmentid;

      this.searchSurvey.status = currentSearch.status;
      this.searchSurvey.positionIds = currentSearch.positionIds;
      this.searchSurvey.endDate = currentSearch.endDate;
      this.searchSurvey.startDate = currentSearch.startDate;
      this.searchSurvey.positionData = currentSearch.positionData;
      // Xoa di sau khi su dung
      this.$localStorage.clear(LOCAL_STORAGE_KEY.SEARCH.SURVEY_SINGLE_SEARCH);
    }
    this.searchSurveySingle();
    this.showCreateSingleSurvey = false;
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    window.location.href = '/admin/survey';
  }

  showCreateForm() {
    /* this.idSurveyUpdate = null;
    this.showCreateSingleSurvey = true; */
    this.modalService.open(SurveySingleCreateComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
  }

  hideCreateFormSurvey() {
    this.ngOnInit();
  }

  editSurvey(surveySingle: any) {
    if (surveySingle.published === 1) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalDep.componentInstance.title = this.translateService.instant('common.caution');
      modalDep.componentInstance.body = this.translateService.instant('common.noti.required_un_publish_survey');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.stop_publish');
      modalDep.result.then((result) => {
        if (result === 'confirm') {
          if (surveySingle.startdate && surveySingle.enddate) {
            const currentDate = new Date();
            if (surveySingle.startdate.getTime() <= currentDate.getTime() && surveySingle.enddate.getTime() >= currentDate.getTime()) {
              this.toastrService.error('error.surveyisrunning');
              return;
            }

            if (surveySingle.enddate.getTime() < currentDate.getTime()) {
              this.toastrService.error('error.surveyended');
              return;
            }
          }
          this.date = Math.round((new Date().getTime() / 1000));
          this.spinner.show();
          this.surveyService.publishOrStopSingleSurvey(surveySingle.questionnaireid, 0).subscribe(() => {
            this.spinner.hide();
            this.toastrService.success('common.noti.stop_publish_survey');
            this.getSurveySingleList();
          },
            err => {
              this.spinner.hide();
              this.toastrService.handlerError(err);
            });
        }
      });
    }else{
      this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.SURVEY_SINGLE_SEARCH, this.searchSurvey);
      this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.SURVEY_SEARCH, { currentTabIndex: 1 });
      this.router.navigate([`/admin/survey/single-detail/${surveySingle.questionnaireid}`]);
    }
  }


  getSurveySingleList() {
    this.spinner.show();
    const params = {
      limit: this.searchSurvey.pageSize,
      page: this.searchSurvey.pageIndex,
      search: this.searchSurvey.search,
      sortcolumn: this.searchSurvey.sortColumn,
      sorttype: this.searchSurvey.sortType,
      refdepartmentid: this.searchSurvey.refdepartmentid,
      status : this.searchSurvey.status ,
      positionids: this.searchSurvey.positionIds,
      enddate: this.searchSurvey.endDate,
      startdate: this.searchSurvey.startDate,
    };
    this.surveyIds = [];
    this.surveyIdsCanCheck = 0;
    return this.surveyService.searchSingleSurvey(params).subscribe(surveyList => {
      this.surveySingleList = surveyList.body.results || [];
      this.totalRecord = surveyList.body.total;
      for (let i = 0; i < this.surveySingleList.length; i++) {

        if (this.surveySingleList[i].activatedat !== null && this.surveySingleList[i].activatedat !== undefined) {
          this.surveySingleList[i].activatedat = new Date(this.surveySingleList[i].activatedat * 1000);
        } else {
          this.surveySingleList[i].activatedat = 0;
        }

        // tslint:disable-next-line: max-line-length
        if (this.surveySingleList[i].enddate !== undefined && this.surveySingleList[i].enddate !== null && this.surveySingleList[i].enddate !== 0) {
          this.surveySingleList[i].enddate = new Date(this.surveySingleList[i].enddate * 1000);
        }
        if (this.surveySingleList[i].enddate === 0) {
          this.surveySingleList[i].enddate = undefined;
        }

        if (this.surveySingleList[i].startdate !== undefined && this.surveySingleList[i].startdate !== null && this.surveySingleList[i].startdate !== 0) {
          this.surveySingleList[i].startdate = new Date(this.surveySingleList[i].startdate * 1000);
        }
        if (this.surveySingleList[i].startdate === 0) {
          this.surveySingleList[i].startdate = undefined;
        }

        // khi next trang, nếu đã có khảo sát được check từ trước thì set checked cho khảo sát đó
        if (this.surveyIdsChecked?.includes(this.surveySingleList[i]?.questionnaireid)) {
          this.surveySingleList[i].selected = true;
          this.surveyIds?.push(this.surveySingleList[i].questionnaireid);
        }
        if (this.surveySingleList[i]?.published === 0) {
          this.surveyIdsCanCheck++;
        }

      }
      // nếu số khảo sát được checked = số khảo sát có thể checked thì set completedAll = true
      if (this.surveyIds?.length > 0 && (this.surveyIds?.length === this.surveyIdsCanCheck)) {
        this.completedAll = true;
      } else {
        this.completedAll = false;
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastrService.handlerError(err);
    });
  }


  onDeleteSurvey(surveySingle) {
    const currentDate = new Date();
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    if (surveySingle.published === 1 && surveySingle.startdate.getTime() > currentDate.getTime()) {
      modalDep.componentInstance.title = this.translateService.instant('common.caution');
      modalDep.componentInstance.body = this.translateService.instant('common.noti.required_un_publish_survey');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.stop_publish');
      modalDep.result.then((result) => {
        if (result === 'confirm') {
          this.date = Math.round((new Date().getTime() / 1000));
          this.spinner.show();
          this.surveyService.publishOrStopSingleSurvey(surveySingle.questionnaireid, 0).subscribe(() => {
            this.spinner.hide();
            this.toastrService.success('common.noti.stop_publish_survey');
            this.getSurveySingleList();
          },
            err => {
              this.spinner.hide();
              this.toastrService.handlerError(err);
            });
        }
      });
    }else{
      modalDep.componentInstance.title = this.translateService.instant('survey.delete_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('survey.delete_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');

      modalDep.result.then(() => {
        if (surveySingle.published === 1 &&  surveySingle.enddate.getTime() >= currentDate.getTime() && surveySingle.startdate.getTime() <= currentDate.getTime()) {
          this.toastrService.error('error.surveyisrunning');
          return;
        }
        this.surveyService.deleteSurvey(surveySingle.questionnaireid).subscribe(() => {
          this.toastrService.success('common.noti.delete_success');
          this.surveyIds.splice(this.surveyIds.indexOf(surveySingle.questionnaireid), 1);
          this.surveyIdsChecked.splice(this.surveyIdsChecked.indexOf(surveySingle.questionnaireid), 1);
          this.getSurveySingleList();
        }, error => {
          this.toastrService.handlerError(error);
        });
      }).catch(() => {
      });
    }

  }

  publishSurvey(questionnaireId, isPublish, enddate, startdate) {
    this.spinner.show();
    let lengthListQuestion = 0;
    this.surveyService.getDetailSurvey(questionnaireId).subscribe(res => {
      const survey = res.body;
      lengthListQuestion = survey.listquestion.length;
      if (lengthListQuestion < 1) {
        this.spinner.hide();
        this.toastrService.error('survey.alert_length_list_question');
        return;
      } else {
        this.spinner.hide();
        this.publishAfterCheckSurveyHasQuestion(questionnaireId, isPublish, enddate, startdate);
      }
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
      return;
    });
  }

  publishAfterCheckSurveyHasQuestion(questionnaireId, isPublish, enddate, startdate) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    if (+isPublish === 0 || isPublish === undefined || isPublish === null) {

      modalDep.componentInstance.title = this.translateService.instant('survey.publish_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('survey.publish_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.publish');

      modalDep.result.then(() => {

        if (enddate) {
          const currentDate = new Date();
          if (enddate.getTime() < currentDate.getTime()) {
            this.toastrService.error('error.surveyisexpired');
            return;
          }
        }

        if (startdate) {
          const currentDate = new Date();
          if (startdate.getTime() < currentDate.getTime()) {
            this.toastrService.error('error.surveyinvalidstartdate');
            return;
          }
        }

        this.spinner.show();
        this.date = Math.round((new Date().getTime() / 1000));
        this.surveyService.publishOrStopSingleSurvey(questionnaireId, 1).subscribe(() => {
          this.spinner.hide();
          this.toastrService.success('common.noti.publish_survey');
          this.getSurveySingleList();
        },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          });
      });
    }
    if (+isPublish === 1) {
      modalDep.componentInstance.title = this.translateService.instant('survey.stop_publish_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('survey.stop_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.stop_publish');

      modalDep.result.then(() => {
        if (startdate && enddate) {
          const currentDate = new Date();
          if (startdate.getTime() <= currentDate.getTime() && enddate.getTime() >= currentDate.getTime()) {
            this.toastrService.error('error.surveyisrunning');
            return;
          }

          if (enddate.getTime() < currentDate.getTime()) {
            this.toastrService.error('error.surveyended');
            return;
          }
        }
        this.spinner.show();
        this.date = Math.round((new Date().getTime() / 1000));
        this.surveyService.publishOrStopSingleSurvey(questionnaireId, 0).subscribe(() => {
          this.spinner.hide();
          this.toastrService.success('common.noti.stop_publish_survey');
          this.getSurveySingleList();
        },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          });
      });
    }
  }

  searchSurveySingle(event?: any) {
    if (event) {
      this.searchSurvey.pageIndex = 1;
    }
    this.surveyIdsChecked = [];
    this.getSurveySingleList();
  }

  sortData(sort: Sort) {
    this.searchSurvey.sortColumn = sort.active;
    this.searchSurvey.sortType = sort.direction;
    this.getSurveySingleList();
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.surveySingleList.forEach(c => {
        if (c.published === 0) {
          c.selected = checked;
          if (!this.surveyIds.includes(c.questionnaireid)) {
            this.surveyIds.push(c.questionnaireid);
          }
          if (!this.surveyIdsChecked?.includes(c.questionnaireid)) {
            this.surveyIdsChecked?.push(c.questionnaireid);
          }
        } else {
          c.selected = false;
        }
      });
      this.completedAll = true;
    } else {
      this.surveyIds?.forEach(id => {
        this.surveyIdsChecked?.splice(this.surveyIdsChecked?.indexOf(id), 1);
      });
      this.surveyIds = [];
      this.surveySingleList?.forEach(c => {
        c.selected = false;
      });
      this.completedAll = false;
    }
  }

  oncheckboxItem(courseId: number, checked: any) {
    if (checked) {
      this.surveySingleList?.forEach(c => {
        if (c.questionnaireid === courseId) {
          c.selected = true;
          this.surveyIds?.push(courseId);
          this.surveyIdsChecked?.push(courseId);
          return;
        }
      });
      // nếu số ... checked bằng số ... có thể checked trong page thì set checkboxall = true
      if (this.surveyIds?.length > 0 && this.surveyIds?.length === this.surveyIdsCanCheck && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.completedAll = false;
      this.surveySingleList?.forEach(c => {
        if (c.questionnaireid === courseId) {
          c.selected = false;
          this.surveyIds?.splice(this.surveyIds?.indexOf(courseId), 1);
          this.surveyIdsChecked?.splice(this.surveyIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
  }

  onDeleteMultipleSurvey() {
    this._onDeleteSurveys(this.surveyIdsChecked);
  }

  _onDeleteSurveys(surveyIdsChecked: number[]) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('survey.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('survey.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalDep.result.then((result) => {
      if (result === 'confirm' && surveyIdsChecked?.length > 0) {
        this.spinner.show();
        this.surveyService.deleteMultiSurvey({ids: this.surveyIdsChecked?.toString()}).subscribe(rs => {
          if (rs.body.status === 1) {
            this.toastrService.success('common.noti.delete_success');
            [...surveyIdsChecked]?.forEach(courseId => {
              this.surveyIds?.splice(this.surveyIds?.indexOf(courseId), 1);
              this.surveyIdsChecked?.splice(this.surveyIdsChecked?.indexOf(courseId), 1);
            });
            this.getSurveySingleList();
          }
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          this.toastrService.handlerError(error);
        });
      }
    }).catch(() => {});
  }

  onChangePage(event) {
    this.searchSurvey.pageIndex = event.pageIndex + 1;
    this.searchSurvey.pageSize = event.pageSize;
    this.getSurveySingleList();
  }

  goToDetail() {
    this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.SURVEY_SEARCH, { currentTabIndex: 1 });
    this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.SURVEY_SINGLE_SEARCH, this.searchSurvey);
  }

  openSingleSurveySearchPopup() {
    const modalRef = this.modalService.open(SingleSurveySearchComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.searchSurvey = this.searchSurvey;
    modalRef.componentInstance.departmentid = this.searchSurvey.refdepartmentid;
    modalRef.componentInstance.event.subscribe(res => {
      const positionIds = [];
      if (res.startDate) {
        this.searchSurvey.startDate = res.startDate.getTime() / 1000;
      } else {
          this.searchSurvey.startDate = null;
      }
      if (res.endDate) {
          this.searchSurvey.endDate = res.endDate.getTime() / 1000;
      } else {
          this.searchSurvey.endDate = null;
      }
      if (res.departmentid) {
        this.searchSurvey.refdepartmentid = res.departmentid;
      } else {
        this.searchSurvey.refdepartmentid = null;
      }

      if (res.positionData) {
        this.searchSurvey.positionData = res.positionData;
      } else {
        this.searchSurvey.positionData = null;
      }

      if (res.status) {
          this.searchSurvey.status = parseInt([res.status].toString());
      } else {
          this.searchSurvey.status = null;
      }

      // TODO: Add more search
      res.positionData?.forEach((position) => {
          positionIds.push(position.id);
      });
      if (positionIds.length > 0) {
          this.searchSurvey.positionIds = positionIds;
      } else {
        this.searchSurvey.positionIds = null;
      }

      this.searchSurvey.pageIndex = 1;
      this.surveyIdsChecked = [];
      this.getSurveySingleList();
    });
  }

onExport(questionnaireid: any){
  const params = {
    questionnaireid
  };
  this.spinner.show();
  this.surveyService.exportSingleSurvey(params).subscribe(res => {
  window.open(this.fileService.getFileFromPathUrl(res.body.path));
  this.spinner.hide();
  }, err => {
    this.toastrService.handlerError(err);
    this.spinner.hide();
  });
}
}
