import { ToastrCustomService } from 'src/app/shared/services/toastr-custom.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PageEvent } from '@angular/material/paginator';
import { CourseService } from '../../../../shared/services/course.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-add-single-user',
  templateUrl: './add-single-user.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./add-single-user.component.css']
})
export class AddSingleUserComponent implements OnInit {

  studentsOutCourse: any[] = [];
  // list student id checked trong 1 page
  studentIds: number[] = [];
  // list student id checked trong cả bảng
  studentIdsChecked: number[] = [];
  courseId: number;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  completedAll = false;

  // MatPaginator Output
  pageEvent: PageEvent;

  searchStudent = {
    limit: 10,
    page: 1,
    search: ''
  }

  constructor(
    public activeModal: NgbActiveModal,
    private courseService: CourseService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrCustomService,
  ) {}

  ngOnInit(): void {
    this.getListStudentsOutCourse();
  }

  getListStudentsOutCourse() {
    this.spinner.show();
    const params = {
      limit: this.searchStudent.limit,
      page: this.searchStudent.page,
      search: this.searchStudent.search,
      courseid: this.courseId
    };
    this.studentIds = [];
    this.courseService.listStudentsOutCourse(params).subscribe(
      (data) => {
        data.body.results?.forEach((item) => {
          item.fullname = item.firstname + ' ' + item.lastname;
          // khi next trang, nếu đã có student được check từ trước thì set checked cho student đó
          if (this.studentIdsChecked?.includes(item.id)) {
            item.checked = true;
            this.studentIds?.push(item.id);
          }
        });
        if (this.studentIds?.length > 0 && (this.studentIds?.length === data.body?.results?.length)) {
          this.completedAll = true;
        } else {
          this.completedAll = false;
        }
        this.studentsOutCourse = data.body.results;
        this.totalRecord = data.body.total;
        this.spinner.hide();
      }, (err) => {
        this.toastrService.handlerError(err);
        this.spinner.hide();
      }
    );
  }

  onSearchStudent(search: string) {
    this.searchStudent.page = 1;
    this.searchStudent.search = search;
    this.studentIdsChecked = [];
    this.getListStudentsOutCourse();
  }

  onCheckBoxItem(studentId: number, checked: boolean) {
    if (checked) {
      this.studentsOutCourse?.forEach(student => {
        if (student.id === studentId) {
          student.checked = true;
          this.studentIds?.push(studentId);
          this.studentIdsChecked?.push(studentId);
          return;
        }
      });
      // nếu số student checked bằng số student trong page thì set checkboxall = true
      if (this.studentIds?.length > 0 && this.studentIds?.length === this.studentsOutCourse?.length && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.studentsOutCourse?.forEach(student => {
        if (student.id === studentId) {
          student.checked = false;
          this.studentIds?.splice(this.studentIds?.indexOf(studentId), 1);
          this.studentIdsChecked?.splice(this.studentIdsChecked?.indexOf(studentId), 1);
          return;
        }
      });
      this.completedAll = false;
    }
  }

  oncheckboxAll(checked: boolean) {
    if (checked) {
      this.studentsOutCourse?.forEach(student => {
        student.checked = true;
        if (!this.studentIds?.includes(student.id)) {
          this.studentIds?.push(student.id);
        }
        if (!this.studentIdsChecked?.includes(student.id)) {
          this.studentIdsChecked?.push(student.id);
        }
      });
      this.completedAll = true;
    } else {
      this.studentIds?.forEach(id => {
        this.studentIdsChecked?.splice(this.studentIdsChecked?.indexOf(id), 1);
      });
      this.studentIds = [];
      this.studentsOutCourse?.forEach(student => {
        student.checked = false;
      });
      this.completedAll = false;
    }
  }

  changePage(event) {
    this.studentIds = [];
    this.searchStudent.page = event.pageIndex + 1;
    this.searchStudent.limit = event.pageSize;
    this.getListStudentsOutCourse();
  }

}
