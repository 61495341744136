import { NgxSpinnerService } from 'ngx-spinner';
import { PageEvent } from '@angular/material/paginator';
import { ToastrCustomService } from './../../../shared/services/toastr-custom.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { SelectCourseComponent } from '../../position/position-course/select-course.component';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ReportService } from '../../../shared/services/report.service';
import { CourseSelectComponent } from '../../course/course-select/course-select.component';
import {FileService} from '../../../shared/services/file.service';

@Component({
  selector: 'app-report-course',
  templateUrl: './report-course.component.html',
  styleUrls: ['./report-course.component.css']
})
export class ReportCourseComponent implements OnInit, OnDestroy {

  @Input() courseType:any;
  @Input() choosesCourseBtn:any;
  courses: any[] = [];
  courseIds: number[] = [];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  pageIndex: number = 1;
  pageSize: number = 10;

  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(
    private modalService: NgbModal,
    private reportService: ReportService,
    private fileService: FileService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  openListCourseReport() {
    const modalRef = this.modalService.open(CourseSelectComponent, {
      size: 'xl' as any,
      centered: false,
      backdrop: 'static'
    });

    modalRef.componentInstance.open = this.courseType;
    modalRef.componentInstance.usedToReport = 1;
    if(this.courseType === 'course'){
      modalRef.componentInstance.titleModal = 'Chọn khóa học cần báo cáo'
    }
    else{
      modalRef.componentInstance.titleModal = 'Chọn kỳ thi cần báo cáo'
    }
    modalRef.componentInstance.isReportSreen = true;
    modalRef.result.then((courseIds) => {
      if (courseIds) {
        this.courseIds = courseIds;
        this.courses = [];
        this.getReportCourseOrgan();
      }
    });
  }

  getReportCourseOrgan() {
    this.spinner.show();
    this.courseIds.forEach((courseId) => {
      const params = {
        courseid: courseId,
        limit: this.pageSize,
        page: this.pageIndex
      }
      this.reportService.reportCourseOrgan(params).subscribe(
        result => {
          this.spinner.hide();
          this.courses = result.body.results;
          this.totalRecord = result.body.total;
        }, err => {
          this.spinner.hide();
          this.toastrService.handlerError(err);
        }
      );
    });
  }

  reportExportCourseOrgan() {
    this.courseIds.forEach(id => {
      this.reportService.reportExportCourseOrgan(id).subscribe(
        res => {
          window.open(this.fileService.getFileFromPathUrl(res.body.path));
        },
        err => {
          this.toastrService.handlerError(err);
        }
      );
    });
  }

  changePage(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getReportCourseOrgan();
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

}
