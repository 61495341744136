<div class="modal-header">
  <h5 *ngIf="title!=='warning'" class="modal-title">{{title}}</h5>
  <h5 *ngIf="title=='warning'" class="modal-title">Cảnh báo</h5>
  <button *ngIf="title!=='Cảnh báo'&& title!=='warning' " type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="confirmEmail">
  <div class="text-center sfbold mt-3">
    Bạn đã đăng ký tài khoản thành công !
  </div>
  <div *ngIf="title!=='warning'" class="modal-body text-center">
    {{body}}
  </div>
</div>
<div *ngIf="title!=='warning' && !confirmEmail" class="modal-body">
  {{body}}
</div>
<div *ngIf="title=='warning'" class="modal-body">
  Bạn không thể sửa khi kì thi đang diễn ra
</div>
<div class="modal-footer" *ngIf="confirmEmail">
  <button type="button" class="btn btn-danger"
          (click)="activeModal.close('confirm')">Đóng</button>
</div>
<div class="modal-footer" *ngIf="!confirmEmail">
  <button *ngIf="title!=='Cảnh báo' && title!=='warning'" type="button" class="btn btn-secondary"
          (click)="activeModal.dismiss('close')">{{ 'common.cancel' | translate }}</button>
  <button type="button" class="btn btn-danger"
          (click)="activeModal.close('confirm')">{{confirmButton}}</button>
</div>
