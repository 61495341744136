
<div class="modal-header">
    <h4 class="modal-title">Tạo mới vai trò</h4>
    <button type="button" class="btn btn-tool"
    (click)="activeModal.dismiss()" ><i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
      <div class="row">
        <div class="col-12">
  
          <div class="form-group">
            <label>Tên vai trò</label>
            <div>
              <input type="text" class="form-control" placeholder="Nhập tên"  >
            </div>
            <div>
              <small class="text-danger">               
              </small>
              <small class="text-danger"></small>
            </div>
          </div>
        </div>
        <div class="col-12">
  
          <div class="form-group">
            <label>Miêu tả</label>          
              <textarea type="text" class="form-control" placeholder="Miêu tả ngắn"  ></textarea>        
            <div>
              <small class="text-danger">               
              </small>
              <small class="text-danger"></small>
            </div>
          </div>
        </div>     
   
      </div>
  </div>
  <div class="modal-footer text-center">
    <div>
      <button type="button"  (click)="activeModal.dismiss()" class="btn ml-1 btn-secondary rounded-0 mr-1">Hủy</button>
      <button type="submit"  class="btn ml-1 btn-primary rounded-0">Tạo mới</button>
    <div>
  </div>
    
  
  
  
  
  