
<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Quản lý thông báo</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý thông báo</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <section class="content">
    <div class="container-fluid">
      <!--phan danh sach khoa hoc-->
      <mat-tab-group [selectedIndex]="index">
        <mat-tab *ngIf="isShowNotifyGeneral">
          <ng-template mat-tab-label>
           Mẫu thông báo
          </ng-template>
        <app-notify-general></app-notify-general>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
         Lịch sử thông báo
          </ng-template>
        <app-notify-single></app-notify-single>
        </mat-tab>
      </mat-tab-group>

    </div><!-- /.container-fluid -->
  </section>
</div>


