<section class="inner_page_breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 text-center">
        <div class="breadcrumb_content mt-2">
          <h4 class="breadcrumb_title"> {{isSearchScreen ? 'Kết quả tìm kiếm cho từ khóa: ' : isTeacher ? 'Khóa học giảng dạy' : isRegisterScreen ? 'Khóa học tự đăng ký' : 'Khóa học của tôi'}} {{isSearchScreen ? search : ''}}</h4>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="/home">Trang chủ</a>
            </li>
          </ol>
        </div>
      </div>

    </div>
  </div>
</section>

<main id="main">
  <!---Phan noi dung khóa hoc-->
  <section id="tabs" class="p-0">
    <div class="container">
      <h1 *ngIf="isSearchScreen">Khóa học</h1>
      <div class="row mt-5">
        <div class="col-lg-3 col-md-6" *ngIf="!isSearchScreen && !isTeacher">
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Chủ đề khóa học
                </mat-panel-title>
              </mat-expansion-panel-header>
              <form>
                <ejs-treeview #categoryTree [fields]="categoryTreeData"
                             (nodeSelecting)='nodeSelected($event)'>
                </ejs-treeview>
              </form>

            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="col-lg-{{isSearchScreen || isTeacher ? '12' : '9'}} col-md-{{isSearchScreen || isTeacher ? '12' : '6'}} box-object">
          <div class="row mb-5">
            <div class="col-6">
              <h6 class="mb-0 text-dark">{{totalCourse}} khóa học</h6>
            </div>
            <div class="col-6" *ngIf="!isTeacher && url !== 'course-register'">
              <div class="d-flex align-items-center float-right">
                <div class="col-auto">
                  <select class="form-control" (change)="onFilterCourse($event.target.value)">
                    <option value="0"> Toàn bộ </option>
                    <option value="1"> Hoàn thành </option>
                    <option value="2"> Chưa hoàn thành </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2" *ngIf="myCourses?.length > 0;  else elseBlock">
            <div class="col-lg-{{isSearchScreen || isTeacher ? '3' : '4'}} col-md-6 d-flex align-items-stretch"  *ngFor="let course of myCourses">
              <app-course-card
                [course] = "course" [isTeacher]="isTeacher" (registerSucc)="registerSucc($event)"
              ></app-course-card>
            </div>
          </div>
          <div class="card-footer text-center" *ngIf="totalCourse > 0">
            <div class="row">
              <div class="col-12 text-center">
              <mat-paginator showFirstLastButtons
                  [length]="totalCourse"
                  [pageSize]="pageSize"
                  [pageIndex]="pageIndex - 1"
                  (page)="changePage($event)"
                  [hidePageSize]="false"
                  [pageSizeOptions]="pageSizeCourseOptions">
              </mat-paginator>
            </div>
            </div>
          </div>
          <ng-template #elseBlock>
            <div class="no-course text-center p-3">{{'common.no_user_course' | translate}}</div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>

  <!---Phan noi dung ky thi-->
  <section id="tabs2" class="p-0" *ngIf="isSearchScreen && !isTeacher">
    <div class="container">
      <h1 *ngIf="isSearchScreen">Kỳ thi</h1>
      <div class="row mt-5 mb-5">
        <div class="col-lg-12 col-md-12 box-object">
          <div class="row">
            <div class="col-6">
              <h6 class="mb-0 text-dark">{{totalExam}} Kỳ thi</h6>
            </div>
            <div class="col-6" *ngIf="!isTeacher">
              <div class="d-flex align-items-center float-right">
                <div class="col-auto">
                  <select class="form-control" (change)="onFilterExam($event.target.value)">
                    <option value="0"> Toàn bộ </option>
                    <option value="1"> Hoàn thành </option>
                    <option value="2"> Chưa hoàn thành </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2" *ngIf="myExams.length > 0;  else elseBlockExam">
            <div class="col-4 d-flex align-items-stretch"  *ngFor="let item of myExams">
              <div class="card-box mb-2 w-100">
                <div class="row align-items-center">
                  <div class="col-9">
                    <div class="media">
                      <div (click)="localStore(item.fullname, item.finished)" [routerLink]="['/exam-lesson',item.id]" class="person-circle chr-{{item.fullname | uppercase | slice:0:1}}" style="cursor: pointer;">{{item.fullname | uppercase | slice:0:1 }}</div>
                      <div class="media-body">
                        <h5 class="mt-0 mb-2">{{item.fullname}}</h5>
                        <p class="mb-1"> <i class="icofont-calendar mr-2"></i>
                          <span style="font-size: 10pt;">{{getDateFromUnix(item.startdate) | date:'dd/MM/yyyy'}} - {{getDateFromUnix(item.enddate)| date:'dd/MM/yyyy'}} </span></p>
                        <p class="mb-0" *ngIf="item.completion==100"><i class="icofont-tasks mr-2"></i> Đã hoàn thành</p>
                        <p class="mb-0" *ngIf="item.completion<100"><i class="icofont-tasks mr-2"></i> Chưa hoàn thành</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="text-center button-list">
                      <button  *ngIf="!item.finished" type="button" (click)="localStore(item.fullname, item.finished)" [routerLink]="['/exam-lesson',item.id]" class="btn btn-sm btn-primary">Tham gia</button>
                      <button *ngIf="item.finished" type="button" (click)="localStore(item.fullname, item.finished)" [routerLink]="['/exam-lesson',item.id]" class="btn btn-sm btn-primary">Xem lại</button>
                    </div>
                  </div>
                </div> <!-- end row -->
              </div>
            </div>
          </div>
          <div class="card-footer text-center" *ngIf="totalExam > 0">
            <div class="row">
              <div class="col-12 text-center">
              <mat-paginator showFirstLastButtons
                  [length]="totalExam"
                  [pageSize]="pageSizeExam"
                  [pageIndex]="pageIndexExam - 1"
                  (page)="changePageExam($event)"
                  [hidePageSize]="false"
                  [pageSizeOptions]="pageSizeExamOptions">
              </mat-paginator>
            </div>
            </div>
          </div>
          <ng-template #elseBlockExam>
            <div class="no-course text-center p-3" *ngIf="isSearchScreen">{{ 'common.no_search_result' | translate}} {{search}}</div>
            <div class="no-course text-center p-3" *ngIf="!isSearchScreen">{{ 'common.no_user_exam' | translate}}</div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</main>

<!-- End #main -->
