import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import CommonUtil from './common-util';


export class CustomValidators {

  static notBlankValidator(control: AbstractControl): { [key: string]: any } | null {
    // Luu y chi dung cho input string
    if (control.value) {
      if (control.value.trim() === '') {
        return {isBlank: true};
      }
    }
    return null;
  }

  static isNumberic(control: AbstractControl) {
    const val = control.value;
    // input type : number
    if (val !== null && val <= 0) {
      return {invalidNumber: true};
    }
    return null;
  }

  static isInteger(control: AbstractControl) {
    const val = control.value;
    // input type : number
    if (val !== null && (val < 0 || !Number.isInteger(val)) ) {
      return {invalidNumber: true};
    }
    return null;
  }

  static isPositiveInteger(control: AbstractControl) {
    const val = control.value;
    // input type : number
    if (val !== null && (val <= 0 || !Number.isInteger(val))) {
      return {invalidNumber: true};
    }
    return null;
  }

  static removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

  static startTimeEndTimeValidator(courseStartDate: any, courseEndDate: any): ValidatorFn | null{
    return (control: FormGroup): ValidationErrors | null => {
      const startDate = CommonUtil.convertDateToTime(
        control.getRawValue().openingtime,
        control.getRawValue().houropeningtime,
        control.getRawValue().minuteopeningtime,
      );
      const endDate = CommonUtil.convertDateToTime(
        control.getRawValue().closingtime,
        control.getRawValue().hourclosingtime,
        control.getRawValue().minuteclosingtime,
      );

      if (startDate < courseStartDate) {
        return {startDateLessThanCourseStartDate: true};
      }

      if (startDate > courseEndDate) {
        return {startDateGreaterThanCourseEndDate: true};
      }

      if (endDate < courseStartDate) {
        return {endDateLessThanCourseStartDate: true};
      }

      if (endDate > courseEndDate) {
        return {endDateGreaterThanCourseEndDate: true};
      }

      if (startDate > endDate) {
        return {endDateLessThanStartDate: true};
      }

      return null;
    };
  }

  static chosenRandomQuestionValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const easy = control.value.easy;
      const medium = control.value.medium;
      const difficult = control.value.difficult;

      // It nhat phai co 1 loai cau hoi
      if (easy === 0 && medium === 0 && difficult === 0){
        return {invalidChosenQuestion: true};
      }

      return null;
    };
  }

  static matchPassword(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.get('confirmpassword').hasError('required')){ // Chi show 1 message trong 1 thoi diem
        return null;
      }
      const newPassword = control.value.newpassword;
      const confirmPassword = control.value.confirmpassword;
      if (newPassword !== confirmPassword){
        return {confirmpassword: true};
      }

      return null;
    };
  }

  static checkLimit(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && (isNaN(c.value) || c.value.toString().length < min || c.value.toString().length > max)) {
          return {rangeInvalid: true};
      }
      return null;
    };
  }

  static checkRangeValue(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && (isNaN(c.value) || c.value.toString() < min || c.value.toString() > max)) {
        return {rangeValue: true};
      }
      return null;
    };
  }

}
