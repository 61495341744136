<section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card card-default">
        <div class="card-header">
              <!-- <h3 class="card-title">Danh sách bài thi</h3> -->
              <div class="row">
                <div class="col-6">
                  <div class="form-inline">
                    <button *ngIf="!examReadOnly" [disabled]="published === 1 || examhasstudent" type="button" (click)="onCreateQuiz()" class="btn btn-primary btn-flat" >
                      <i class="fas fa-plus"></i><a class="text-white" >Tạo bài thi</a>
                  </button>
                  </div>
                </div>
                <div class="col-6">
                  <form class="form-inline float-right">
                    <div class="form-group m-2">
                          <label class="mr-2">Tìm kiếm</label>
                          <input type="search" class="form-control mr-2" (change)="onSearchQuiz($event.target.value)" placeholder="Từ khóa...">
                    </div>
                  </form>
                </div>
              </div>

            </div>
            <div class="card-body" style="display: block;">
            <div class="col-12 table-responsive">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên bài thi</th>
                  <th>Ngày bắt đầu</th>
                  <th>Ngày kết thúc</th>
                  <th>Thời gian(phút)</th>
                  <th>Trạng thái</th>
                  <th>Thao tác</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of modules; let i = index;">
                  <td>{{i+1}}</td>
                  <td  style="width:450px;">{{item.name}}</td>
                  <td *ngIf="item.timeopen!==0">{{getDateFromUnix(item.timeopen)| date:'dd/MM/yyyy'}}</td>
                  <td *ngIf="item.timeclose!==0">{{getDateFromUnix(item.timeclose)| date:'dd/MM/yyyy'}}</td>
                  <td *ngIf="item.timeopen==0">Không giới hạn</td>
                  <td *ngIf="item.timeclose==0">Không giới hạn</td>
                  <td>{{item.timelimit/60}}</td>
                  <td *ngIf="item.timeclose!==0 && published !==0">{{item.status}}</td>
                  <td *ngIf="item.timeclose!==0 && published ===0">Chưa hiển thị</td>
                  <td *ngIf="item.timeclose==0 && published !==0">Đang diễn ra</td>
                  <td *ngIf="item.timeclose==0 && published ===0">Chưa hiển thị</td>
                  <td *ngIf="published === 0 && !examReadOnly">
                  <button type="button" class="btn btn-default btn-sm mr-1 {{item.id}}" (click)="edit(item.id)" [disabled]="published!==0" matTooltip="Sửa" matTooltipClass="tooltip-red"><i class="fas fa-edit"></i></button>
                  <button type="button" class="btn btn-default btn-sm mr-1 {{item.id}}" (click)="delete(item.id)" [disabled]="published!==0" matTooltip="Xóa" matTooltipClass="tooltip-red"><i class="fas fa-trash mr-1"></i></button>
                </td>
                  <td *ngIf="published === 1 || examReadOnly">
                    <button type="button" class="btn btn-default btn-sm mr-1 {{item.id}}" (click)="edit(item.id)"  matTooltip="Xem" matTooltipClass="tooltip-red"><i class="fas fa-eye"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
              <br />
              <p *ngIf="modules?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
               </div>
            <div class="text-center">
              </div>
            </div>
            </div>
        </div>
      </div><!-- /.container-fluid -->
  </section>

<ngx-spinner [fullScreen]="true" [name]="'listexam'" type="ball-clip-rotate-multiple" size="medium">
  <p class="loading">{{ 'common.loading' | translate }}</p>
</ngx-spinner>
