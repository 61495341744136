import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrCustomService } from './../../shared/services/toastr-custom.service';
import { ExamLessonService } from './../../shared/services/exam-lesson.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-end-user-exam-result',
  templateUrl: './end-user-exam-result.component.html',
  styleUrls: ['./end-user-exam-result.component.css'],
})
export class EndUserExamResultComponent implements OnInit {
  examResult = [];

  constructor(
    public activeModal: NgbActiveModal,
    private examLessonService: ExamLessonService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.examLessonService.getCurrentQuizActive(1).subscribe(
      (data) => {
        this.examResult = data.body.result;
        this.examResult?.map(item => {
          item.timeResult = moment((item.timefinish - item.timestart * 1000) - 900).utcOffset(0).format('HH:mm:ss:SS');
        })
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      }
    );
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date).format('DD/MM/YYYY - HH:mm');
    }
    return null;
  }
}
