<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>CHI TIẾT TIẾN ĐỘ HỌC VIÊN</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']">CMS</a></li>
              <li class="breadcrumb-item"><a [routerLink]="['/admin/report-student']">Báo cáo tiến độ học viên</a></li>
              <li class="breadcrumb-item active">Chi tiết tiến độ học viên</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="card">
                    <div class="card-body">
                         <div class="row flex-column p-4">
                            <h3 class="card-title">{{userFullname}}</h3>
                            <p>{{departmentName}}</p>
                         </div>
                       <div class="row pl-4 pr-4">
                        <div class="col-12 mb-2">
                            <a class="btn btn-sm btn-success text-white" (click)="onExportLearningDetail()"><i class="fas fa-file-excel mr-2"></i>Xuất File Excel </a>
                          </div>
                           <table class="table table-striped table-hover">
                               <thead>
                                   <tr>
                                       <th>STT</th>
                                       <th>Tên khóa học</th>
                                       <th class="text-center">Số bài học hoàn thành</th>
                                       <th class="text-center">Số bài học chưa hoàn thành</th>
                                       <th class="text-center">Thời gian bắt đầu</th>
                                       <th>Trạng thái</th>
                                       <th class="text-center">Thao tác</th>
                                   </tr>
                               </thead>
                               <tbody>
                                   <tr *ngFor="let item of listCourse; let i=index">
                                       <td>{{i+1}}</td>
                                       <td>{{item.fullname}}</td>
                                       <td class="text-center">{{item.completeditems}}</td>
                                       <td class="text-center">{{item.uncompleteditems}}</td>
                                       <td class="text-center">{{getDateFromUnix(item.timestart) | date:'dd/MM/yyyy' }}</td>
                                       <td><span class="badge badge-{{item.completion === 100 ? 'success' : (item.completion < 100 && item.completion > 50) ? 'warning': 'danger'}}">{{item.completion}}%</span></td>
                                       <td class="text-center"><a class="btn btn-default btn-sm" (click)="openLearningHistory(item.id,item.fullname)"> <i class="fas fa-eye"></i></a></td>
                                   </tr>
                               </tbody>
                           </table>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

