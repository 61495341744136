import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import {QuizContentService} from '../../../shared/services/quiz-content.service';
import {TopicModel} from '../../../shared/model/topic.model';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {ShowContentService} from '../../../shared/services/show-content.service';
import * as moment from 'moment';
import {CourseService} from '../../../shared/services/course.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TopicService} from '../../../shared/services/topic.service';

@Component({
  selector: 'app-list-exam',
  templateUrl: './list-exam.component.html',
  styleUrls: ['./list-exam.component.css']
})
export class ListExamComponent implements OnInit {

  topicContent: TopicModel;
  modules: ModuleInfo[];
  quiz: ModuleInfo;
  @Input() published;
  @Input() examhasstudent;
  @Input() examReadOnly;
  @Output() quizEdit = new EventEmitter();
  @Output() showCreateQuizz = new EventEmitter();
  @Output() checkCreate = new EventEmitter();
  @Input() courseId;
  @Input() happening;
  currentDate: Date = new Date();
  search: string;

  constructor(
    public quizContentService: QuizContentService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private topicService: TopicService,
    private toastrService: ToastrCustomService,
    private showService: ShowContentService,
    private courseService: CourseService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.getListQuiz();
  }

  onCreateQuiz() {
    this.showCreateQuizz.emit(true);
    this.checkCreate.emit(true);
  }

  onSearchQuiz(search: string) {
    this.search = search;
    this.getListQuiz(search);
  }

  getListQuiz(search?:string) {
    this.quizContentService.listQuiz(this.courseId, 0, search).subscribe((data) => {
      this.topicContent = data.body;
      this.modules = this.topicContent.modules;
      // console.log(this.modules);
      this.modules.forEach(element => {
        if (this.currentDate > moment.unix(element.timeclose).toDate()) {
          element.status = 'Đã kết thúc';
        }
        if (this.currentDate < moment.unix(element.timeopen).toDate()) {
          element.status = 'Chưa bắt đầu';
        }
        if (moment.unix(element.timeopen).toDate() <= this.currentDate && this.currentDate <= moment.unix(element.timeclose).toDate()) {
          element.status = 'Đang diễn ra';
        }
      });
    });
  }

  edit(id) {
    this.getModuleDetail(id);
  }

  getModuleDetail(id) {
    this.spinner.show('listexam');
    this.showService.getFilebyId(id).subscribe((data) => {
      this.spinner.hide('listexam');
      this.quiz = data.body;
      this.quiz.grade = Math.ceil(this.quiz.grade);
      this.quizEdit.emit(this.quiz);
      this.showCreateQuizz.emit(true);
      this.checkCreate.emit(false);
    }, error => {
      this.spinner.hide('listexam');
      this.toastrService.handlerError(error);
    });
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  delete(id) {
    const modalModule = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    let cmids: number [] = [];
    cmids.push(id);

    modalModule.componentInstance.title = this.translateService.instant('quiz.delete_confirm_title');
    modalModule.componentInstance.body = this.translateService.instant('quiz.delete_confirm_content');
    modalModule.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalModule.result.then((result) => {
      this.spinner.show();
      this.topicService.deleteModule(cmids).subscribe(
        (res) => {
          this.toastrService.success(`common.noti.delete_success`);
          this.getListQuiz();
          this.spinner.hide();
        },
        err => {
          this.toastrService.handlerError(err);
          this.spinner.hide();
        });
    });
  }
}
