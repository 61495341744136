import { FILE_UPLOAD } from './../../../shared/constants/base.constant';
import {NgxSpinnerService} from 'ngx-spinner';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {ModuleModel} from '../../../shared/model/module.model';
import CommonUtil from '../../../shared/utils/common-util';
import {TopicService} from '../../../shared/services/topic.service';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FileService} from '../../../shared/services/file.service';

@Component({
  selector: 'app-eu-resource-content',
  templateUrl: './eu-resource-content.component.html',
  styleUrls: ['./eu-resource-content.component.css']
})
export class EuResourceContentComponent implements OnInit, OnChanges {

  @ViewChild('videoCourse') videoCourse: ElementRef;

  @Input() module: ModuleModel;
  @Input() courseId: number;
  @Input() section: number;

  courseModule: ModuleInfo = {
    name: '',
    course: this.courseId,
    section: this.section,
    introeditor: null,
    modulename: 'resource'
  };

  urlSafe: SafeResourceUrl;
  teachers = '';
  isVideoResourse: boolean;

  constructor(
    private topicService: TopicService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    private  sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.module) {
      // Cap nhat du lieu
      this.spinner.show();
      this.topicService.getModuleDetail(this.module.id).subscribe(res => {
        this.courseModule = res.body;
        this.teachers = res.body.teachers.length > 0 ? CommonUtil.convertTeacherName(res.body.teachers) : 'Chưa có giảng viên được gán';
        const urlFile = this.fileService.getFileUrl(this.courseModule.contextid, this.courseModule.filename, '/mod_resource/content/0/');
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
        if (FILE_UPLOAD.MIME_TYPE_RESOURCE.includes(this.courseModule.mimetype)) {
          this.videoCourse?.nativeElement.load();
          this.isVideoResourse = true;
        } else {
          this.isVideoResourse = false;
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        // this.toastrService.handlerError(err);
      });
    }
  }
}
