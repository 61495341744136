import {FILE_UPLOAD} from './../../../shared/constants/base.constant';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {PageModel} from '../../../shared/model/page.model';
import {IntroeditorModel} from '../../../shared/model/introeditor.model';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import {ModuleModel} from '../../../shared/model/module.model';
import CommonUtil from '../../../shared/utils/common-util';
import {TopicService} from '../../../shared/services/topic.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FileService} from '../../../shared/services/file.service';
import {TreeViewComponent} from '@syncfusion/ej2-angular-navigations';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';

@Component({
  selector: 'app-scorm-content',
  templateUrl: './scorm-content.component.html',
  styleUrls: ['./scorm-content.component.css']
})
export class ScormContentComponent implements OnInit, OnChanges {
  @Input() module: ModuleModel;
  @Input() courseId: number;
  @Input() section: number;

  @Output() hasChange = new EventEmitter<boolean>();
  @Output() reLoadTopics = new EventEmitter<number>();

  Editor = ClassicEditor;

  editorConfig = CKEDITOR_CONFIG.DEFAULT;

  @ViewChild('scormScoTree')
  scormScoTree: TreeViewComponent;
  scormScoTreeData;

  fileInvalid = false;

  courseModule: ModuleInfo = {
    name: '',
    course: this.courseId,
    section: this.section,
    introeditor: null,
    modulename: 'scorm'
  };
  fileToUpload: File = null;
  page: PageModel;
  file: File;
  introeditor: IntroeditorModel;
  editorData = '';

  validateDes = true;
  verifyFile = true;
  moduleForm = this.fb.group({
    name: ['', Validators.required],
    timelimit: ['', Validators.required]
  });

  urlSafe: SafeResourceUrl;

  currentScormSco = null;

  scormScoTreeList;
  scormContentUrl = null;

  isNew = false;
  isCreateOrEdit = false;

  teachers = '';

  constructor(
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private topicService: TopicService,
    private fileService: FileService,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.module) {
      // Cap nhat du lieu
      this.spinner.show();
      this.scormContentUrl = null;
      this.isNew = false;
      this.isCreateOrEdit = false;
      this.topicService.getModuleDetail(this.module.id).subscribe(res => {
          this.courseModule = res.body;
          this.teachers = res.body.teachers.length > 0 ? CommonUtil.convertTeacherName(res.body.teachers) : 'Chưa có giảng viên được gán';
          this.moduleForm.patchValue({
            name: this.courseModule.name,
            timelimit: this.courseModule.timelimit / 60
          });
          this.editorData = this.courseModule.intro;
          const urlFile = this.fileService.getFileUrl(this.courseModule.contextid, this.courseModule.filename, '/mod_scorm/package/0/');
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);

          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          this.toastrService.handlerError(err);
        });
      this.topicService.getScormSco(this.module.id).subscribe(res => {
        this.scormScoTreeList = res.body;
        if (this.scormScoTreeList.length > 0) {
          // Do Item dau tien co cha la / nen set bang null de show len tree
          this.scormScoTreeList[0].parent = null;
        }
        this.scormScoTreeData = {
          dataSource: this.scormScoTreeList,
          id: 'identifier',
          parentID: 'parent',
          text: 'title',
          hasChildren: 'haschild'
        };
      });
    } else {
      // Tao bai giang moi
      this.courseModule.id = null;

      this.moduleForm.patchValue({
        name: '',
        timelimit: null
      });
      this.editorData = '';
      this.courseModule.filename = '';
      this.fileToUpload = null;
      this.isNew = true;
      this.isCreateOrEdit = true;
      this.moduleForm.reset();
    }
  }

  // type document file valid: zip xml SCORM 1.2 and below
  handleFileInput(files: FileList) {
    this.verifyFile = true;
    // file rar type isEmpty
    for (const type of FILE_UPLOAD.SCORM) {
      if (files?.[0].type && files?.[0].type.includes(type)) {
        this.fileToUpload = files.item(0);
        this.fileInvalid = false;
        this.hasChange.emit(true);
        return;
      }
    }
    const param = {file_name: files[0].name};
    this.translateService.get(`common.noti.img_invalid`, param).subscribe(message => {
      this.toastrService.error(message);
    });
    this.fileToUpload = null;
    this.fileInvalid = true;
  }

  submitForm() {
    if (this.moduleForm.invalid || this.editorData === '' || (this.fileToUpload === null && this.isNew)) {
      if (this.moduleForm.invalid) {
        CommonUtil.markFormGroupTouched(this.moduleForm);
      }
      if (this.editorData === '') {
        this.validateDes = false;
      } else {
        this.validateDes = true;
      }
      if (this.fileToUpload === null && this.isNew) {
        this.verifyFile = false;
      } else {
        this.verifyFile = true;
      }
      return;
    }

    this.introeditor = {text: '', format: 1, itemid: 0};

    const createOrUpdate: ModuleInfo = {
      name: '',
      course: this.courseId,
      section: this.section,
      introeditor: null,
      modulename: 'scorm'
    };

    createOrUpdate.name = this.moduleForm.value.name;
    createOrUpdate.timelimit = this.moduleForm.value.timelimit * 60;
    this.introeditor.text = this.editorData;
    createOrUpdate.introeditor = this.introeditor;
    this.spinner.show();

    if (!this.courseModule.id) {
      // Tao bai giang moi
      this.topicService.createScormContent(createOrUpdate, this.fileToUpload).subscribe(res => {
        this.courseModule.id = res.body.coursemodule;
        this.courseModule.name = this.moduleForm.value.name;
        this.courseModule.timelimit = this.moduleForm.value.timelimit * 60;
        this.courseModule.intro = this.editorData;
        this.courseModule.filename = this.fileToUpload.name;
        this.courseModule.mimetype = this.fileToUpload.type;
        this.courseModule.contextid = res.body.contextid;
        this.fileToUpload = null;
        const urlFile = this.fileService.getFileUrl(this.courseModule.contextid, this.courseModule.filename, '/mod_scorm/package/0/');
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
        this.spinner.hide();
        this.toastrService.success(`common.noti.create_success`);
        this.isCreateOrEdit = false;
        this.isNew = false;
        this.hasChange.emit(false);
        this.reLoadTopics.emit(res.body?.coursemodule);
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
    } else {
      // Cap nhat bai giang cu
      createOrUpdate.coursemodule = this.courseModule.id;
      this.topicService.updateScormContent(createOrUpdate, this.fileToUpload).subscribe(res => {
        this.courseModule.name = this.moduleForm.value.name;
        this.courseModule.timelimit = this.moduleForm.value.timelimit * 60;
        this.courseModule.intro = this.editorData;
        if (this.fileToUpload) {
          this.courseModule.filename = this.fileToUpload.name;
          this.courseModule.mimetype = this.fileToUpload.type;
          this.courseModule.contextid = res.body.contextid;
        }
        this.spinner.hide();
        const urlFile = this.fileService.getFileUrl(this.courseModule.contextid, this.courseModule.filename, '/mod_scorm/package/0/');
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
        this.toastrService.success(`common.noti.update_success`);
        this.isCreateOrEdit = false;
        this.isNew = false;
        this.hasChange.emit(false);
        this.reLoadTopics.emit(res.body?.coursemodule);
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
    }
  }


  cancel() {
    this.isCreateOrEdit = false;
  }

  public onChangeIntro({editor}: ChangeEvent) {
    if (!editor) {
      return;
    }
    const data = editor.getData();
    this.editorData = data;
    this.validateDes = true;
    this.hasChange.emit(true);
  }

  numericOnly(event): boolean {
    const patt = /^([0-9])$/;
    return patt.test(event.key);
  }

  showEdit() {
    this.spinner.show();
    this.topicService.prepareDataForUpdate(this.courseModule.id).subscribe(
      res => {
        this.editorData = res.body.intro;
        this.spinner.hide();
        this.isCreateOrEdit = true;
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      }
    );
  }

  onDataChange() {
    this.hasChange.emit(true);
  }

  nodeSelected($event) {
    const currentSelectedId = this.scormScoTree.selectedNodes[0];

    for (const scormSco of this.scormScoTreeList) {
      if (currentSelectedId === scormSco.identifier && !scormSco.haschild) {
        this.currentScormSco = scormSco;
      }
    }


    if (this.currentScormSco) {
      this.scormContentUrl = this.fileService.getScormUrl(this.courseModule.contextid,
        this.currentScormSco.launch, '/mod_scorm/content/0/');
    }

  }

  getCurrentLeafNode() {
    const currentSelectedId = this.scormScoTreeData.selectedNodes[0];
    let hasChild = false;
    for (const scormSco of this.scormScoTreeList) {

      if (hasChild === false) {
        if (currentSelectedId === scormSco.identifier) {
          if (scormSco.haschild) {
            hasChild = true;
          } else {
            this.currentScormSco = scormSco;
          }
        }
      } else {

      }
    }
  }

}
