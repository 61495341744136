import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {MOODLE_INFO, USER_INFO} from '../constants/base.constant';
import {LocalStorageService} from 'ngx-webstorage';
import CommonUtil from '../utils/common-util';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class FileService {
  constructor(
    private abstractService: AbstractService,
    private $localStorage: LocalStorageService,
  ) {
  }

  getFileUrl(contextid, filename, modulename) {
    const token = this.$localStorage.retrieve(USER_INFO.TOKEN);
    return MOODLE_INFO.FILE_URL + contextid + modulename + CommonUtil.encodeURI(filename) + '?token=' + token;
  }

  // Luu y: filepath do backend tra ve phai khong co environment.apiUrl
  getFileFromPathUrl(filepath) {
    const token = this.$localStorage.retrieve(USER_INFO.TOKEN);
    return environment.apiUrl + filepath + '?token=' + token;
  }

  getScormUrl(fileid, filename, modulename) {
    return MOODLE_INFO.SCORM_FILE_URL + fileid + modulename + CommonUtil.encodeURI(filename);
  }

  getFileUrlWithRevision(contextid, filename, modulename, revisionid){
    const token = this.$localStorage.retrieve(USER_INFO.TOKEN);
    return MOODLE_INFO.FILE_URL + contextid + modulename + CommonUtil.encodeURI(filename) + '?rev=' + revisionid + '&token=' + token;
  }
}
