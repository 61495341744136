import { Component, isDevMode, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserEditComponent} from './user-edit/user-edit.component';
import {UserService} from '../../shared/services/user.service';
import {ConfirmModalComponent} from '../../shared/modal/confirm-modal/confirm-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {TreeViewComponent} from '@syncfusion/ej2-angular-navigations';
import {DepartmentService} from '../../shared/services/department.service';
import {Department} from '../../shared/model/department.model';
import {UserInfo} from '../../shared/model/user-info.model';
import {Position} from '../../shared/model/position.model';
import {PositionService} from '../../shared/services/position.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ImportUserComponent} from './import-user/import-user.component';
import {Sort} from '@angular/material/sort';
import {BehaviorSubject} from 'rxjs';
import {RoleSystem} from '../../shared/model/role-system';
import {RoleService} from '../../shared/services/role.service';
import {USER_ROLE} from '../../shared/constants/base.constant';
import {LocalStorageService} from 'ngx-webstorage';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {

  @ViewChild('departmentTree')
  public departmentTree: TreeViewComponent;
  public departmentTreeData;

  /// page
  pageIndex = 1;
  pageSize = 10;

  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;

  departmentList: Department[] = [];
  positionList: Position[] = [];
  roleList: RoleSystem[] = [];
  roleListRemoveRoleManager: RoleSystem[] = [];

  checkedDepartmentIds = [];
  userInfors: UserInfo[] = [];

  currentUser = {};
  currentSelectedDepartmentId: number;
  selectedPositions = [];
  selectedPositionIds = [];

  selectedRole = [];
  selectedRoles = [];

  keyWord: string;

  dropdownPositionSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Chọn tất cả',
    unSelectAllText: 'Bỏ chọn tất cả',
    searchPlaceholderText: 'Tìm kiếm',
    itemsShowLimit: 5,
    allowSearchFilter: true
  };

  dropdownRoleSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'shortnameTranslate',
    selectAllText: 'Chọn tất cả',
    unSelectAllText: 'Bỏ chọn tất cả',
    searchPlaceholderText: 'Tìm kiếm',
    itemsShowLimit: 5,
    allowSearchFilter: true
  };

  sortcolumn = 'username';
  sorttype = 'asc';

  selectedItemsList = [];
  userIds = [];
  userIdsChecked = [];
  checkBoxAllUserDelete = false;
  fileToUpload: File = null;

  selectPositionEvent = false;
  selectRoleEvent = false;

  showFullRoles = false;

  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private $localStorage: LocalStorageService,
    private modalService: NgbModal,
    private userService: UserService,
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private positionService: PositionService,
    private departmentService: DepartmentService,
    private spinner: NgxSpinnerService,
    private roleService: RoleService,
  ) {}

  ngOnInit(): void {
    this.getListPosition();
    this.getListRoleSystem();
    this.listAllTree();
    this.searchUser();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  getListRoleSystem() {
    return this.roleService.getListRoleSystem().subscribe(roleList => {
      this.roleList = roleList.body.results;
      this.roleList.forEach(role => {
        role.shortnameTranslate = this.getShortName(role.shortname);
      });
      this.roleListRemoveRoleManager = this.roleList.filter(role => {
        return role.shortname !== USER_ROLE.ADMIN;
      });
      const roleUser = this.$localStorage.retrieve('roles')[0].shortname;
      if (roleUser === USER_ROLE.MANAGER) {
        this.showFullRoles = false;
      } else {
        this.showFullRoles = true;
      }
    });
  }

  getListPosition() {
    return this.positionService.getPosition().subscribe(positionList => {
      this.positionList = positionList.body.results;
    });
  }

  listAllTree() {
    this.departmentService.getUserDepartmentTree(0).subscribe((data) => {
      this.departmentList = data.body;
      // set thang cha = null de no hien thi len duoc cay
      this.departmentList[0].parentid = null;
      this.departmentList.forEach(value => {
        // value.expanded = value.haschild !== null;
        this.departmentList.filter(department => department.id === value.parentid);
        value.expanded = value.parentid == null;
      });
      this.departmentTreeData = {
        dataSource: this.departmentList,
        id: 'id',
        parentID: 'parentid',
        text: 'name',
        hasChildren: 'haschild'
      };
      // this.loading.next(true);
    });
  }

  searchUser() {
    // if (this.checkedDepartmentIds.length === 0) {
    //   this.userInfors = null;
    //   this.totalRecord = null;
    //   return;
    // }
    // Chi tim khi da chon don vi
    const param = {
      limit: this.pageSize,
      page: this.pageIndex,
      keyword: this.keyWord,
      positionids: this.selectedPositionIds.toString(),
      departmentids: this.checkedDepartmentIds.toString(),
      roleids: this.selectedRoles.toString(),
      sortcolumn: this.sortcolumn,
      sorttype: this.sorttype
    };
    this.spinner.show();
    this.userIds = [];
    this.userService.searchUser(param)
      .subscribe((userInfors) => {
          userInfors.body?.results?.forEach(user => {
            if (this.userIdsChecked.includes(user.id)) {
              user.selected = true;
              this.userIds?.push(user.id);
            }
          });
          if (this.userIds?.length > 0 && (this.userIds?.length === userInfors.body?.results?.length)) {
            this.checkBoxAllUserDelete = true;
          } else {
            this.checkBoxAllUserDelete = false;
          }
          this.userInfors = userInfors.body.results;
          this.totalRecord = userInfors.body.total;
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          this.toastrService.handlerError(err);
        });
  }

  changePosition() {
    if (this.selectPositionEvent) {
      // Vi su kien onDropDownClose luon ban ra, vi vay co them co de kiem tra truoc do co hanh vi thay doi chuc danh khong
      this.selectPositionEvent = false;
      this.selectedPositionIds = [];

      for (const position of this.selectedPositions) {
        this.selectedPositionIds.push(position.id);
      }
      this.pageIndex = 1;
      this.userIds = [];
      this.userIdsChecked = [];
      this.searchUser();
    }
  }

  changeRole() {
    if (this.selectRoleEvent) {
      // Vi su kien onDropDownClose luon ban ra, vi vay co them co de kiem tra truoc do co hanh vi thay doi chuc danh khong
      this.selectRoleEvent = false;
      this.selectedRoles = [];
      for (const role of this.selectedRole) {
        this.selectedRoles.push(this.roleList.filter(data => data.id === role.id).map(value => value.id).toString());
      }
      this.pageIndex = 1;
      this.userIds = [];
      this.userIdsChecked = [];
      this.searchUser();
    }
  }

  // chọn 1 đơn vị
  nodeDepartmentSelected(e: any) {
    this.userIds = [];
    this.userIdsChecked = [];
    this.currentSelectedDepartmentId = +this.departmentTree.selectedNodes;
    // this.searchUser();
  }

  public nodeCheck(args: any): void {
    const checkedNode: any = [args.node];
    if (args.event.target.classList.contains('e-fullrow') || args.event.key === 'Enter') {
      // @ts-ignore
      const getNodeDetails: any = this.departmentTree.getNodeData(args.node);
      if (getNodeDetails.isChecked === 'true') {
        this.departmentTree.uncheckAll(checkedNode);
      } else {
        this.departmentTree.checkAll(checkedNode);
      }
    }
  }

  // chọn nhiều đơn vị
  nodeDepartmentChecked(e) {
    this.userIds = [];
    this.userIdsChecked = [];
    this.checkedDepartmentIds = [];
    this.departmentTree.checkedNodes.forEach((node) => {
      this.checkedDepartmentIds.push(parseInt(node, 10));
    });
    this.pageIndex = 1;
    this.searchUser();
  }

  searchUserUsingKeyUp(search: string) {
    this.keyWord = search;
    this.pageIndex = 1;
    this.userIdsChecked = [];
    this.searchUser();
  }

  checkAllUser(checked: any) {
    if (checked) {
      this.userInfors.forEach(c => {
        c.selected = checked;
        if (!this.userIds.includes(c.id)) {
          this.userIds.push(c.id);
        }
        if (!this.userIdsChecked?.includes(c.id)) {
          this.userIdsChecked?.push(c.id);
        }
      });
      this.checkBoxAllUserDelete = true;
    } else {
      this.userIds?.forEach(id => {
        this.userIdsChecked?.splice(this.userIdsChecked?.indexOf(id), 1);
      });
      this.userIds = [];
      this.userInfors?.forEach(c => {
        c.selected = false;
      });
      this.checkBoxAllUserDelete = false;
    }
  }

  checkSelection(courseId: number, checked: any) {
    if (checked) {
      this.userInfors?.forEach(c => {
        if (c.id === courseId) {
          c.selected = true;
          this.userIds?.push(courseId);
          this.userIdsChecked?.push(courseId);
          return;
        }
      });
      if (this.userIds?.length > 0 && this.userIds?.length === this.userInfors?.length && !this.checkBoxAllUserDelete) {
        this.checkBoxAllUserDelete = true;
      }
    } else {
      this.checkBoxAllUserDelete = false;
      this.userInfors?.forEach(c => {
        if (c.id === courseId) {
          c.selected = false;
          this.userIds?.splice(this.userIds?.indexOf(courseId), 1);
          this.userIdsChecked?.splice(this.userIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
  }

  openImportFile() {
    const modalRef = this.modalService.open(ImportUserComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalRef.componentInstance.newUser.subscribe(($e) => {
      this.searchUser();
    });
  }

  openCreateOrEditPopup(user, isChangePassword?: boolean) {
    const modalRef = this.modalService.open(UserEditComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    if (user.id == null) {
      user.roleid = 0;
      user.departmentid = this.currentSelectedDepartmentId;
    }
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.isChangePassword = isChangePassword;
    modalRef.componentInstance.departmentList = this.departmentList;
    modalRef.componentInstance.positionList = this.positionList;
    modalRef.componentInstance.newUser.subscribe(($e) => {
      this.searchUser();
    });
  }

  onDeleteUser(userIds: number[]) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('user.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('user.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalDep.result.then((result) => {
      if (result === 'confirm' && userIds.length > 0) {
        this.spinner.show();
        this.userService.deleteUser(userIds).subscribe(
          (res) => {
            [...userIds]?.forEach(id => {
              this.userIds.splice(this.userIds.indexOf(id), 1);
              this.userIdsChecked.splice(this.userIdsChecked.indexOf(id), 1);
            });
            this.spinner.hide();
            this.toastrService.success('common.noti.delete_success');
            this.searchUser();
          }, (err) => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          }
        );
      }
    }).catch(err => {
      if (isDevMode()) {
        console.log('Delete a User', err);
      }
    });
  }

  getPositionNames(user) {
    let positionName = '';
    if (user.positionids) {
      const positionIdArray = user.positionids.split(';');
      for (const positionId of positionIdArray) {
        const foundPosition = this.positionList.find(value => value.id === +positionId);
        if (positionName.length > 0) {
          positionName += ',';
        }
        if (foundPosition && foundPosition.name !== undefined) {
          positionName += foundPosition.name;
        }
      }
      return positionName;
    }
    return '';
  }

  sortData(sort: Sort) {
    this.sortcolumn = sort.active;
    this.sorttype = sort.direction;
    this.pageIndex = 1;
    this.searchUser();
  }

  getShortName(shortname) {
    if (shortname) {
      return this.translateService.instant('user.' + shortname);
    }
    return '';
  }

  changePage(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.searchUser();
  }

  getIndex(i) {
    return (this.pageIndex - 1) * this.pageSize + i + 1;
  }

  onSelectPositionEvent() {
    this.userIds = [];
    this.userIdsChecked = [];
    this.selectPositionEvent = true;
  }

  onSelectRoleEvent() {
    this.userIds = [];
    this.userIdsChecked = [];
    this.selectRoleEvent = true;
  }

  onUnlockUser(userIds: number []) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('user.unlock_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('user.unlock_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.update');
    modalDep.result.then((result) => {
      if (result) {
        this.spinner.show();
        this.userService.unlockUser(userIds).subscribe(
          res => {
            [...userIds]?.forEach(id => {
              this.userIds.splice(this.userIds.indexOf(id), 1);
              this.userIdsChecked.splice(this.userIdsChecked.indexOf(id), 1);
            });
            this.spinner.hide();
            this.toastrService.success('common.noti.update_success');
            this.searchUser();
          },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          }
        );
      }
    }).catch(err => {
      if (isDevMode()) {
        console.log('Close modal');
      }
    });
  }

  onUnlockMultiUser() {
    this.onUnlockUser(this.userIdsChecked);
  }

  onLockMultiUser() {
    this.onLockUser(this.userIdsChecked);
  }

  onLockSingleUser($event, user) {
    $event.preventDefault();
    if (user?.suspended === 0) {
      this.onLockUser([user?.id]);
    } else {
      this.onUnlockUser([user?.id]);
    }
  }

  onLockUser(userIds: number[]) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('user.lock_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('user.lock_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.update');
    modalDep.result.then((result) => {
      if (result) {
        this.spinner.show();
        this.userService.lockUser(userIds).subscribe(
          res => {
            [...userIds]?.forEach(id => {
              this.userIds.splice(this.userIds.indexOf(id), 1);
              this.userIdsChecked.splice(this.userIdsChecked.indexOf(id), 1);
            });
            this.spinner.hide();
            this.toastrService.success('common.noti.update_success');
            this.searchUser();
          },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          }
        );
      }
    }).catch(err => {
      if (isDevMode()) {
        console.log('Close modal');
      }
    });
  }

}
