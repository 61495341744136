import {HttpResponse} from '@angular/common/http';
import {UserInfo} from '../model/user-info.model';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import CommonUtil from '../utils/common-util';
import {MOODLE_INFO} from '../constants/base.constant';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ModuleInfo} from '../model/moduleinfo.model';
import {User} from "../model/user.model";

type EntityResponseType = HttpResponse<UserInfo>;
type EntityArrayResponseType = HttpResponse<UserInfo[]>;

interface UserPageResponse {
  results: UserInfo[];
  total: number;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private abstractService: AbstractService) {

  }

  searchUser(param):
    Observable<HttpResponse<UserPageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_user_list_user');
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<UserPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<UserPageResponse>) => res));
  }

  deleteUser(idUser: number[]): Observable<EntityArrayResponseType> {
    const data = this.abstractService.getCommonInput('core_user_delete_users');
    CommonUtil.appendDataToFormData(data, 'userids', idUser);
    return this.abstractService.post<UserInfo[]>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponseType) => res));
  }

  createUser(newUser: UserInfo): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_user_create_user');
    CommonUtil.appendDataToFormData(data, 'user', newUser);
    return this.abstractService.post<UserInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updateUser(userUpdate: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_user_update_user');
    CommonUtil.appendDataToFormData(data, 'user', userUpdate);
    return this.abstractService.post<UserInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  importUserFormFile(file: File): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_user_import_user');
    data.append('file', file, file.name);
    CommonUtil.appendDataToFormData(data, 'file', file);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  findUserByUsername(username: string): Observable<HttpResponse<boolean>> {
    const data = this.abstractService.getCommonInput('core_auth_request_password_reset');
    CommonUtil.appendDataToFormData(data, 'username', username);
    return this.abstractService.post<boolean>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<boolean>) => res));
  }

  findUserByEmail(email: string): Observable<HttpResponse<boolean>> {
    const data = this.abstractService.getCommonInput('core_auth_request_password_reset');
    CommonUtil.appendDataToFormData(data, 'email', email);
    return this.abstractService.post<boolean>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<boolean>) => res));
  }

  changeOwnPassword(params: any): Observable<any>{
    const data = this.abstractService.getCommonInput('mobifone_user_change_password');
    CommonUtil.appendRawDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: any) => res));
  }

  updateOwnDetail(file: File, user: UserInfo): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_user_update_own_detail');
    if (file != null) {
      data.append('repo_upload_file', file, file.name);
    }
    CommonUtil.appendRawDataToFormData(data, 'params', user);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: any) => res));
  }

  getUserInfo(): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_user_get_own_detail');
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: any) => res));
  }

  lockUser(idUser: number[]): Observable<EntityArrayResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_user_lock_user');
    CommonUtil.appendDataToFormData(data, 'userids', idUser);
    return this.abstractService.post<UserInfo[]>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponseType) => res));
  }

  unlockUser(idUser: number[]): Observable<EntityArrayResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_user_unlock_user');
    CommonUtil.appendDataToFormData(data, 'userids', idUser);
    return this.abstractService.post<UserInfo[]>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponseType) => res));
  }
}
