<div class="col-12 mt-3 border"> 
    <!-- /.card-header -->
     <h6 class="mt-3">Nhập nội dung câu hỏi</h6>
    <div class="card-body" style="display: block;">
      <div class="row">
        <div class="col-md-3 text-right">
       <p>Câu hỏi</p>
        </div>
        <div class="col-md-9"> <input type="text" class="form-control" size="55" placeholder="Tên đầy đủ">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Nhập nội dung</p>
        </div>
        <div class="col-md-9">
          <ckeditor [editor]="Editor" data="" [config]="editorConfig"></ckeditor>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Đánh dấu câu hỏi</p>
        </div>
        <div class="col-md-1">
          <input type="text" class="form-control " name="defaultmark" value="1" size="7">
        </div>
      </div>     
</div>
<hr>
<div class="col-12 mt-3 "> 

   <h6 class="mt-3">Nội dung đáp án</h6>
    <div class="card-body" style="display: block;">
        <div class="row mt-2">
      <div class="col-md-3 text-right">
        <p>Câu hỏi 01</p>
      </div>
      <div class="col-md-9">
        <ckeditor [editor]="Editor" data="" [config]="editorConfig"></ckeditor>
      </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Lỗi</p>
        </div>
        <div class="col-md-9">
            <input type="text" class="form-control " name="tolerance[1]" value="0" size="15">
        </div>
      </div>
    <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Điểm số</p>
        </div>
        <div class="col-md-9">
            <select class="custom-select" name="fraction[0]">
                <option value="0.0" selected="">None</option>
                <option value="1.0">100%</option>
                <option value="0.9">90%</option>
                <option value="0.8333333">83.33333%</option>
                <option value="0.8">80%</option>
                <option value="0.75">75%</option>
                <option value="0.7">70%</option>
                <option value="0.6666667">66.66667%</option>
                <option value="0.6">60%</option>
                <option value="0.5">50%</option>
                <option value="0.4">40%</option>
                <option value="0.3333333">33.33333%</option>
                <option value="0.3">30%</option>
                <option value="0.25">25%</option>
                <option value="0.2">20%</option>
                <option value="0.1666667">16.66667%</option>
                <option value="0.1428571">14.28571%</option>
                <option value="0.125">12.5%</option>
                <option value="0.1111111">11.11111%</option>
                <option value="0.1">10%</option>
                <option value="0.05">5%</option>
            </select>
        </div>
      </div>
    <hr>
    <div class="card-body" style="display: block;">
        <div class="row mt-2">
      <div class="col-md-3 text-right">
        <p>Câu hỏi 02</p>
      </div>
      <div class="col-md-9">
        <ckeditor [editor]="Editor" data="" [config]="editorConfig"></ckeditor>
      </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Lỗi</p>
        </div>
        <div class="col-md-9">
            <input type="text" class="form-control " name="tolerance[1]" value="0" size="15">
        </div>
      </div>
    <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Điểm số</p>
        </div>
        <div class="col-md-9">
            <select class="custom-select" name="fraction[0]">
                <option value="0.0" selected="">None</option>
                <option value="1.0">100%</option>
                <option value="0.9">90%</option>
                <option value="0.8333333">83.33333%</option>
                <option value="0.8">80%</option>
                <option value="0.75">75%</option>
                <option value="0.7">70%</option>
                <option value="0.6666667">66.66667%</option>
                <option value="0.6">60%</option>
                <option value="0.5">50%</option>
                <option value="0.4">40%</option>
                <option value="0.3333333">33.33333%</option>
                <option value="0.3">30%</option>
                <option value="0.25">25%</option>
                <option value="0.2">20%</option>
                <option value="0.1666667">16.66667%</option>
                <option value="0.1428571">14.28571%</option>
                <option value="0.125">12.5%</option>
                <option value="0.1111111">11.11111%</option>
                <option value="0.1">10%</option>
                <option value="0.05">5%</option>
            </select>
        </div>
      </div>
      <hr>
      <div class="card-body" style="display: block;">
          <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Câu hỏi 03</p>
        </div>
        <div class="col-md-9">
          <ckeditor [editor]="Editor" data="" [config]="editorConfig"></ckeditor>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Lỗi</p>
        </div>
        <div class="col-md-9">
            <input type="text" class="form-control " name="tolerance[1]" value="0" size="15">
        </div>
      </div>
      <div class="row mt-2">
          <div class="col-md-3 text-right">
            <p>Điểm số</p>
          </div>
          <div class="col-md-9">
            <select class="custom-select" name="fraction[0]">
                <option value="0.0" selected="">None</option>
                <option value="1.0">100%</option>
                <option value="0.9">90%</option>
                <option value="0.8333333">83.33333%</option>
                <option value="0.8">80%</option>
                <option value="0.75">75%</option>
                <option value="0.7">70%</option>
                <option value="0.6666667">66.66667%</option>
                <option value="0.6">60%</option>
                <option value="0.5">50%</option>
                <option value="0.4">40%</option>
                <option value="0.3333333">33.33333%</option>
                <option value="0.3">30%</option>
                <option value="0.25">25%</option>
                <option value="0.2">20%</option>
                <option value="0.1666667">16.66667%</option>
                <option value="0.1428571">14.28571%</option>
                <option value="0.125">12.5%</option>
                <option value="0.1111111">11.11111%</option>
                <option value="0.1">10%</option>
                <option value="0.05">5%</option>
            </select>
          </div>
        </div>
</div>
<div class="row mt-2">
    <div class="col-md-3 text-center">
       
    </div>
    <div class="col-md-6 text-left">
        <button type="submit" class="btn btn-secondary btn-sm"><i class="nav-icon fas fa-plus mr-1"></i>Thêm đáp án</button>  
    </div>
</div>
<div class="row">
 <div class="col-12 text-center mb-3">
  <button type="submit" class="btn btn-default btn-sm mr-2"><i class="nav-icon fas fa-close mr-1"></i>Hủy </button> 
  <button type="submit" class="btn btn-primary btn-sm"><i class="nav-icon fas fa-save mr-1"></i>Lưu lại </button>
 </div>
</div>

