import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrCustomService } from './../../../shared/services/toastr-custom.service';
import { BadgeService } from './../../../shared/services/badge.service';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { USER_INFO } from './../../../shared/constants/base.constant';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-certificate-user-detail',
  templateUrl: './certificate-user-detail.component.html',
  styleUrls: ['./certificate-user-detail.component.css']
})
export class CertificateUserDetailComponent implements OnInit {

  certificate: any;
  userInfo: any;

  constructor(
    private $localStorage: LocalStorageService,
    private router: ActivatedRoute,
    private dadgeService: BadgeService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.userInfo = this.$localStorage.retrieve(USER_INFO.INFO);
    this.dadgeService.getDetailBadge(+this.router.snapshot.paramMap.get('id')).subscribe(
      res => {
        this.certificate = res.body;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      }
    );
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

}
