import { LOCAL_STORAGE_KEY } from './../../../shared/constants/base.constant';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrCustomService } from 'src/app/shared/services/toastr-custom.service';
import { ReportService } from '../../../shared/services/report.service';
import {FileService} from '../../../shared/services/file.service';

@Component({
  selector: 'app-result-exam',
  templateUrl: './result-exam.component.html',
  styleUrls: ['./result-exam.component.css']
})
export class ResultExamComponent implements OnInit {

  @ViewChild('keyword') keyword: ElementRef;
  searchExam = {
    search: '',
  //  startDate: null,
  //  endDate: null,
  //  categoryid: null,
    pageIndex: 1,
    pageSize: 10,
  //  sortColumn: 'timecreated',
  //  sortType: 'desc',
    coursetype: '',
  };
  courses = [];
  totalRecord: number;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex = 1;
  pageSize = 10;
  constructor(private toastrService: ToastrCustomService,
              private spinner: NgxSpinnerService,
              private $localStorage: LocalStorageService,
              private reportService: ReportService,
              private fileService: FileService
              ) { }

  ngOnInit(): void {
    const currentSearch = this.$localStorage.retrieve(LOCAL_STORAGE_KEY.SEARCH.REPORT_EXAM_SEARCH);
    if (currentSearch) {
      // Kiem tra xem truoc do co luu du lieu tim kiem khong
      this.searchExam.search = currentSearch.search;
      this.searchExam.pageIndex = currentSearch.pageIndex;
      this.searchExam.pageSize = currentSearch.pageSize;
    //  this.searchExam.sortColumn = currentSearch.sortColumn;
    //  this.searchExam.sortType = currentSearch.sortType;
      // Xoa di sau khi su dung
      this.$localStorage.clear(LOCAL_STORAGE_KEY.SEARCH.REPORT_EXAM_SEARCH);
    }
    this.getListExam();
  }

  ngAfterViewInit() {
    this.keyword.nativeElement.value = this.searchExam?.search;
  }

  getListExam() {
    const params = {
      limit: this.searchExam.pageSize,
      page: this.searchExam.pageIndex,
      search: this.searchExam.search,
      coursetype: this.searchExam.coursetype
    };

    this.spinner.show();
    this.reportService.getListExamReport(params).subscribe(data => {
      this.spinner.hide();
      this.courses = data.body.results;
      this.totalRecord = data.body.total;
      this.pageIndex = this.searchExam.pageIndex;
      this.pageSize = this.searchExam.pageSize;
    },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      });
  }

  changePage(event) {
    this.searchExam.pageIndex = event.pageIndex + 1;
    this.searchExam.pageSize = event.pageSize;
    this.getListExam();
  }

  onChangeType(event){
    this.searchExam.pageIndex = 1;
    this.searchExam.coursetype = event.target.value;
    this.getListExam();
  }

  goToDetail(){
    this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.REPORT_EXAM_SEARCH, this.searchExam);
  }

  onSearch(event){
    this.searchExam.pageIndex = 1;
    this.searchExam.search = event.target.value;
    this.getListExam();
  }

  onExportListExamReport(){
    const params = {
      limit: this.searchExam.pageSize,
      page: this.searchExam.pageIndex,
      search: this.searchExam.search,
      coursetype: this.searchExam.coursetype
    };

    this.reportService.exportListExamReport(params).subscribe(res => {
      window.open(this.fileService.getFileFromPathUrl(res.body.path));
    }, err => {
      this.toastrService.handlerError(err);
    });
  }

  onExport(id: any){
    const params = {
      courseid: id
    };
    this.reportService.exportExamReportDetail(params).subscribe(res => {
    window.open(this.fileService.getFileFromPathUrl(res.body.path));
    }, err => {
      this.toastrService.handlerError(err);
    });
  }
}
