<div class="container-fluid">
  <div class="card card-default">
    <div class="card-body">
      <div class="row">
        <div class="col-12 mt-3 mb-3">
          <button class="btn btn-secondary btn-sm" type="submit" *ngIf="!isNotify && !readonly"
                  (click)="openListCourse('course')" value="{{courseIdsString}}">
            <i class="nav-icon fas fa-plus mr-1"></i>Chọn khóa học
          </button>
          <button class="btn btn-secondary btn-sm" type="submit" *ngIf="isNotify"
                  (click)="openListCourseNotify('course')" value="{{courseIdsString}}">
            <i class="nav-icon fas fa-plus mr-1"></i>Chọn khóa học cho thông báo
          </button>
        </div>
      </div>
      <div class="col-12 table-responsive">
        <div class="row">
          <form class="form-inline float-right" role="form">
            <div class="form-group m-2" *ngIf="!isNotify">
              <label class="mr-2">Tìm kiếm</label>
              <input type="search" class="form-control mr-2" placeholder="Từ khóa..." (change)="onChange($event)">
            </div>
            <div class="form-group m-2" *ngIf="isNotify">
              <label class="mr-2">Tìm kiếm</label>
              <input type="search" class="form-control mr-2" placeholder="Từ khóa..." (input)="searchByNotify($event?.target?.value)">
            </div>
          </form>
        </div>
        <table class="table table-hover" matSort (matSortChange)="sortData($event)">
          <thead>
          <tr *ngIf="!isNotify">
            <th mat-sort-header="id">STT</th>
            <th mat-sort-header="fullname">Tên khóa học</th>
            <th mat-sort-header="startdate">Ngày bắt đầu</th>
            <th mat-sort-header="enddate">Ngày kết thúc</th>
            <th *ngIf="!readonly">Thao tác</th>
          </tr>
          <tr *ngIf="isNotify">
            <th>STT</th>
            <th>Tên khóa học</th>
            <th>Ngày bắt đầu</th>
            <th>Ngày kết thúc</th>
            <th>Thao tác</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of courseInBadge; let i = index;">
            <td [ngStyle]="{'width': '20px'}">{{i + 1 + (searchBadgeCourse.pageIndex - 1) * searchBadgeCourse.pageSize}}</td>
            <td [ngStyle]="{'width': '70%'}">{{item.fullname}}</td>
            <td>{{getDateFromUnix(item.startdate)  | date:'dd/MM/yyyy'}}</td>
            <td>{{getDateFromUnix(item.enddate)| date:'dd/MM/yyyy'}}</td>
            <td *ngIf="!readonly">
              <button type="button" class="btn btn-default btn-sm mr-1" matTooltip="Xóa" matTooltipClass="tooltip-red"
                      (click)="unAssignCourse(item.id)"><i class="fas fa-trash mr-1"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
        <br />
        <p *ngIf="courseInBadge?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
      </div>
      <div class="card-footer text-center">
        <div *ngIf="!isNotify" class="row">
          <mat-paginator showFirstLastButtons
              [length]="totalRecord"
              [pageSize]="searchBadgeCourse.pageSize"
              [pageIndex]="searchBadgeCourse.pageIndex - 1 "
              (page)="changePage($event)"
              [hidePageSize]="false"
              [pageSizeOptions]="pageSizeOptions">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
