import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmModalComponent} from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import {ToastrCustomService} from 'src/app/shared/services/toastr-custom.service';
import {BadgeService} from '../../../shared/services/badge.service';
import {SelectItemComponent} from '../select-item.component';
import {NotifycationModel} from '../../../shared/model/notifycation.model';
import {SelectCourseComponent} from '../../position/position-course/select-course.component';
import {DataService} from '../../../shared/services/data.service';
import {CourseService} from '../../../shared/services/course.service';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-badge-course',
  templateUrl: './badge-course.component.html',
  styleUrls: ['./badge-course.component.css']
})
export class BadgeCourseComponent implements OnInit, OnDestroy {
  @Input() badgeId: any;
  @Input() newInforNotifi: NotifycationModel;
  @Input() isNotify: boolean;
  @Input() readonly: boolean;
  courseIdsString: string;
  idCourseArray: any[] = [];
  @Output() sendIdCourses = new EventEmitter<string>();

  courseInBadge: any[] = [];
  tempCourseInBadge: any[] = [];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  pageEvent: PageEvent;



  searchBadgeCourse = {
    search : '',
    pageIndex: 1,
    pageSize: 10,
    sortBy :  'id',
    sortType: 'desc'
  }

  constructor(
    private badgeService: BadgeService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private dataService: DataService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private courseService: CourseService,
  ) {}

  ngOnInit(): void {
    if (this.isNotify === undefined || this.isNotify === null) {
      this.isNotify = false;
    }

    // ko phai thong bao
    if (!this.isNotify) {
      this.getCourseInBadge();
    }
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  // inBadge
  openListCourse(open: string) {
    const modalDep = this.modalService.open(SelectItemComponent, {
      size: 'xl' as any,
      centered: false,
      backdrop: 'static'
    });
    modalDep.componentInstance.badgeId = this.badgeId;
    modalDep.componentInstance.open = open;
    modalDep.componentInstance.loadAll.subscribe(($e) => {
      this.getCourseInBadge();
    });
  }

  getCourseInBadge() {
    if (!this.isNotify) {
      let params = {
        keyword:this.searchBadgeCourse.search,
        limit:this.searchBadgeCourse.pageSize,
        page:this.searchBadgeCourse.pageIndex,
        sortcolumn:this.searchBadgeCourse.sortBy,
        sorttype:this.searchBadgeCourse.sortType,
        coursetype: 1,
        inbadgeids: this.badgeId
      };

      this.badgeService.getistCourseCriteria(params).subscribe(data => {
        // console.log(data.body.results);
        this.courseInBadge = data.body.results;
        this.totalRecord = data.body.total;
      });

    }
  }

  // Notification
  openListCourseNotify(open: string) {
    const modalDep = this.modalService.open(SelectCourseComponent, {
      size: 'xl' as any,
      centered: false,
      backdrop: 'static'
    });

    modalDep.componentInstance.open = open;
    modalDep.componentInstance.arrayidCourses = this.idCourseArray;
    modalDep.componentInstance.isNotify = true;
    modalDep.componentInstance.transferCourseIds.subscribe((courseIds) => {
      this.courseIdsString = courseIds;
      // console.log('1');
      // hiển thị ds course đã chọn
      if (this.courseIdsString !== undefined && this.courseIdsString !== null && this.courseIdsString !== '') {
        this.courseIdsString = this.courseIdsString.trim();
        let idsCourse: any[] = [];
        // nhieu course
        if (this.courseIdsString.indexOf(',') !== -1) {
          idsCourse = this.courseIdsString.split(',');
          let count: any = 0;
          for (let i = 0; i < idsCourse.length; i++) {
            this.courseService.getCoursesInfo(+idsCourse[i]).subscribe(res => {

              const course = res.body;
              this.courseInBadge.push(course);
              this.tempCourseInBadge.push(course);
              this.idCourseArray.push(+idsCourse[i]);
              count++;

              // ban id course sang notifi
              this.courseIdsString = this.idCourseArray.toString();
              this.dataService.setData = this.courseIdsString;


            }, error => {
              this.toastrService.handlerError(error);
            });
          }
          if ( count == this.idCourseArray.length){
            this.toastrService.success('common.noti.assign_course_success');
          }
        }

        // 1 course
        else {
          this.idCourseArray.push(+this.courseIdsString);
          this.courseService.getCoursesInfo(+this.courseIdsString).subscribe(res => {
            const course = res.body;
            this.courseInBadge.push(course);
            this.tempCourseInBadge.push(course);
            this.toastrService.success('common.noti.assign_course_success');
            // ban id course sang notifi
            this.courseIdsString = this.idCourseArray.toString();
            this.dataService.setData = this.courseIdsString;
          }, error => {
            this.toastrService.handlerError(error);
          });
        }

        ////
        ////
      }
    });
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  unAssignCourse(courseId) {
    // unAssign course from badge
    if (!this.isNotify) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });

      modalDep.componentInstance.title = this.translateService.instant('badge.unassign_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('badge.unassign_course_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.unassign');

      modalDep.result.then((result) => {
        // console.log('result: ', result);
        this.spinner.show();
        this.badgeService.updateCourseToBadge(this.badgeId, String(courseId), 'delete').subscribe(
          res => {
            this.spinner.hide();
            this.toastrService.success('badge.unassign_course_success');
            this.getCourseInBadge();
          },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          }
        );
      });
    }
   // unAssign course from notification
    else {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });

      modalDep.componentInstance.title = this.translateService.instant('badge.unassign_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('badge.unassign_course_confirm_content_for_notify');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.unassign');

      modalDep.result.then((result) => {
        let index: any;
        let indexId: any;
        for (let i = 0; i < this.courseInBadge.length; i++) {
          if (this.courseInBadge[i].id == courseId) {
            index = i;
            break;
          }
        }
        for (let i = 0; this.idCourseArray.length; i++){
          if (this.idCourseArray[i] == courseId){
            indexId = i;
            break;
          }
        }
        this.idCourseArray.splice(indexId, 1);
        this.courseInBadge.splice(index, 1);
        this.toastrService.success('badge.unassign_course_success');
        ////
        // ban id course sang notifi
        this.courseIdsString = this.idCourseArray.toString();
        this.dataService.setData = this.courseIdsString;
        ////
      });
    }
  }

  onChange(event){
    this.searchBadgeCourse.pageIndex = 1;
    this.searchBadgeCourse.search= event.target.value;
    this.getCourseInBadge();
  }

  sortData(sort: Sort){
    if (!sort.active || sort.direction === '') {
      this.searchBadgeCourse.sortBy = 'id';
      this.searchBadgeCourse.sortType = 'desc';
      this.getCourseInBadge();
      return;
   }
   this.searchBadgeCourse.sortBy = sort.active;
   this.searchBadgeCourse.sortType = sort.direction;

   this.getCourseInBadge();
  }


  searchByNotify(value) {
    const filter = value.toString().toLowerCase().trim();
    if (filter.length > 0) {
      this.courseInBadge = this.tempCourseInBadge.filter(data => data.fullname.toLowerCase().includes(filter));
    } else {
      this.courseInBadge = [];
      this.courseInBadge = [...this.tempCourseInBadge];
    }
  }

  changePage(event){
    this.searchBadgeCourse.pageIndex = event.pageIndex + 1;
    this.searchBadgeCourse.pageSize = event.pageSize;
    this.getCourseInBadge();
  }
}
