<form role="form" [formGroup]="questionForm">
  <select name="level" formControlName="level" class="form-control">
    <option value="">Chọn chủ đề câu hỏi</option>
    <option [value]="topic.value" *ngFor="let topic of TOPIC_CONST">{{topic.lable}}</option>
  </select>
  <div
    *ngIf="questionForm.get('level')!.invalid && (questionForm.get('level')!.dirty || questionForm.get('level')!.touched)">
    <small class="text-danger" *ngIf="questionForm.get('level')?.errors?.required">
      {{ 'error.validate.input_required' | translate }}
    </small>
  </div>
  <div class="col-12 mt-3 border">
    <!-- /.card-header -->
    <h6 class="mt-3">Nhập nội dung câu hỏi</h6>
    <div class="card-body" style="display: block;">
      <div class="row">
        <input type="text" formControlName="id" hidden>
        <!--          <div class="col-md-3 text-right">-->
        <!--         <p>Câu hỏi</p>-->
        <!--          </div>-->
        <!--          <div class="col-md-9"> <input type="text" formControlName="name" class="form-control" size="55" placeholder="Tên đầy đủ">-->
        <!--          </div>-->
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Nhập nội dung<span class="text-danger"> *</span></p>
        </div>
        <div class="col-md-9">
          <ckeditor [editor]="Editor" formControlName="questiontext" data="" [config]="questionEditorConfig"></ckeditor>
          <div
            *ngIf="questionForm.get('questiontext')!.invalid && (questionForm.get('questiontext')!.dirty || questionForm.get('questiontext')!.touched)">
            <small class="text-danger" *ngIf="questionForm.get('questiontext')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
            <small class="text-danger" *ngIf="questionForm.get('questiontext')?.errors?.isBlank">
              {{ 'error.validate.input_required' | translate }}
            </small>
          </div>

        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Đánh dấu câu hỏi</p>
        </div>
        <div class="col-md-1">
          <input type="text" class="form-control " name="defaultmark" formControlName="defaultmark" value="1" size="7">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Lựa chọn kiểu đáp án<span class="text-danger"> *</span></p>
        </div>
        <div class="col-md-2">
          <select class="custom-select" formControlName="single" name="single">
            <!-- <option value="0">Nhiều đáp án</option> -->
            <option value="1" selected="">01 đáp án</option>
          </select>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Định dạng đáp án<span class="text-danger"> *</span></p>
        </div>
        <div class="col-md-2">
          <select class="custom-select" formControlName="answernumbering" name="answernumbering">
            <option value="ABCD" selected="">A., B., C., ...</option>
            <option value="abc">a., b., c., ...</option>
            <option value="123">1., 2., 3., ...</option>
            <option value="iii">i., ii., iii., ...</option>
            <option value="IIII">I., II., III., ...</option>
            <option value="none">-Không chọn -</option>
          </select>
        </div>
      </div>
    </div>
    <hr>
    <div formArrayName="answer">
      <div *ngFor="let item of answer.controls; let i=index">
        <div class="col-12 mt-3" [formGroupName]="i">
          <!-- /.card-header -->
          <h6 class="mt-3">Đáp án {{i + 1}}</h6>
          <div class="card-body" style="display: block;">
            <div class="row mt-2">
              <div class="col-md-3 text-right">
                <p>Nhập nội dung<span class="text-danger"> *</span></p>
              </div>
              <div class="col-md-9">
                <ckeditor [editor]="Editor" formControlName="text" (ngModelChange)="onChangeCkeditorAnswer($event, i)"
                          data="" [config]="answerEditorConfig"></ckeditor>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3 text-right">
                <p>Điểm số<span class="text-danger"> *</span></p>
              </div>
              <div class="col-md-2">
                <select class="custom-select" formControlName="grade" name="grade">
                  <option value="0.0" selected="">None</option>
                  <option value="1.0">100%</option>
                  <!-- <option value="0.9">90%</option>
                  <option value="0.8333333">83.33333%</option>
                  <option value="0.8">80%</option>
                  <option value="0.75">75%</option>
                  <option value="0.7">70%</option>
                  <option value="0.6666667">66.66667%</option>
                  <option value="0.6">60%</option>
                  <option value="0.5">50%</option>
                  <option value="0.4">40%</option>
                  <option value="0.3333333">33.33333%</option>
                  <option value="0.3333334">33.33334%</option>
                  <option value="0.3">30%</option>
                  <option value="0.25">25%</option>
                  <option value="0.2">20%</option>
                  <option value="0.1666667">16.66667%</option>
                  <option value="0.1428571">14.28571%</option>
                  <option value="0.125">12.5%</option>
                  <option value="0.1111111">11.11111%</option>
                  <option value="0.1">10%</option>
                  <option value="0.05">5%</option>
                  <option value="-0.05">-5%</option>
                  <option value="-0.1">-10%</option>
                  <option value="-0.1111111">-11.11111%</option>
                  <option value="-0.125">-12.5%</option>
                  <option value="-0.1428571">-14.28571%</option>
                  <option value="-0.1666667">-16.66667%</option>
                  <option value="-0.2">-20%</option>
                  <option value="-0.25">-25%</option>
                  <option value="-0.3">-30%</option>
                  <option value="-0.3333333">-33.33333%</option>
                  <option value="-0.4">-40%</option>
                  <option value="-0.5">-50%</option>
                  <option value="-0.6">-60%</option>
                  <option value="-0.6666667">-66.66667%</option>
                  <option value="-0.7">-70%</option>
                  <option value="-0.75">-75%</option>
                  <option value="-0.8">-80%</option>
                  <option value="-0.8333333">-83.33333%</option>
                  <option value="-0.9">-90%</option>
                  <option value="-1.0">-100%</option> -->
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3 text-center">
            </div>
            <div class="col-md-6 text-left">
              <button type="submit" *ngIf="i > 0 && formEnable" (click)="removeAnswer(i)" class="btn btn-danger btn-sm">
                <i class="nav-icon fas fa-plus mr-1"></i>Xóa
              </button>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-3 text-center">
      </div>
      <div class="col-md-6 text-left">
        <button type="submit" (click)="addAnswer()" *ngIf="formEnable" class="btn btn-secondary btn-sm"><i
          class="nav-icon fas fa-plus mr-1"></i>Thêm đáp án
        </button>
      </div>
    </div>
  </div>
  <mat-action-row *ngIf="canEdit">
    <button type="button" matTooltip="Sao chép"
            [disabled]="isPublished" (click)="onCloneQuestion()"
            matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i class="fas fa-copy"></i>
    </button>
    <button type="button" matTooltip="Chỉnh sửa" (click)="onTurnOnEditmode()"
            [disabled]="isPublished" *ngIf="isViewMode"
            matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i class="fas fa-edit"></i>
    </button>
    <button type="button" matTooltip="Lưu" (click)="onSaveQuestion(questionForm.value)"
            [disabled]="isPublished || questionForm.invalid" *ngIf="!isViewMode"
            matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i
      class="fas fa-save mr-1"></i></button>
    <button type="button" matTooltip="Xóa"
            [disabled]="isPublished" (click)="onRemoveQuestion()"
            matTooltipClass="tooltip-red"
            class="btn btn-default btn-lg"><i class="fas fa-trash"></i></button>
  </mat-action-row>
</form>


