import { LOCAL_STORAGE_KEY } from './../../../shared/constants/base.constant';
import { LocalStorageService } from 'ngx-webstorage';
import {Component, OnInit} from '@angular/core';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {NotificationService} from '../../../shared/services/notification.service';
import {CourseService} from '../../../shared/services/course.service';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../../shared/services/data.service';
import {SurveyService} from '../../../shared/services/survey.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-notify-general',
  templateUrl: './notification-template.component.html',
  styleUrls: ['./notification-template.component.css']
})
export class NotificationTemplateComponent implements OnInit {

  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;

  pageEvent: PageEvent;
  completedAll = false;
  showCreateNotiForm: boolean;
  listNotify: any[] = [];

  searchNoti = {
    search: '',
    pageIndex: 1,
    pageSize: 10,
    sortcolumn: 'updatedat',
    sorttype: 'asc'
  };

  constructor(
    private notifycationService: NotificationService,
    private toastrService: ToastrCustomService,
    private $localStorage: LocalStorageService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.showCreateNotiForm = false;
    const currentSearch = this.$localStorage.retrieve(LOCAL_STORAGE_KEY.SEARCH.NOTIFICATION_TEMPLATE_SEARCH);
    if (currentSearch) {
      // Kiem tra xem truoc do co luu du lieu tim kiem khong
      this.searchNoti.search = currentSearch.search;
      this.searchNoti.pageIndex = currentSearch.pageIndex;
      this.searchNoti.pageSize = currentSearch.pageSize;
      this.searchNoti.sortcolumn = currentSearch.sortcolumn;
      this.searchNoti.sorttype = currentSearch.sorttype;
      // Xoa di sau khi su dung
      this.$localStorage.clear(LOCAL_STORAGE_KEY.SEARCH.NOTIFICATION_TEMPLATE_SEARCH);
    }
    this.getListNotifyGeneral();
  }

  getListNotifyGeneral() {
    this.spinner.show();
    this.notifycationService.searchNotifyGeneral(this.searchNoti.pageSize, this.searchNoti.pageIndex,
      this.searchNoti.sortcolumn, this.searchNoti.sorttype, this.searchNoti.search, null).subscribe(res => {
      const result: any = res.body;
      this.listNotify = result.results;
      this.totalRecord = result.total;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.listNotify.length; i++) {
        if (this.listNotify[i].updatedat !== null && this.listNotify[i].updatedat !== undefined) {
          this.listNotify[i].updatedat = new Date(this.listNotify[i].updatedat * 1000);
        }
      }
      this.spinner.hide();
    }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
    });
  }

  searchNotify() {
    this.searchNoti.pageIndex = 1;
    this.getListNotifyGeneral();
  }

  changePage(event) {
    this.searchNoti.pageIndex = event.pageIndex + 1;
    this.searchNoti.pageSize = event.pageSize;
    this.getListNotifyGeneral();
  }

  sortData(sort: Sort) {
    this.searchNoti.sortcolumn = sort.active;
    this.searchNoti.sorttype = sort.direction;
    this.searchNoti.pageIndex = 1;
    this.getListNotifyGeneral();
  }

  goToNotiDetail() {
    this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.NOTIFICATION_TEMPLATE_SEARCH, this.searchNoti);
  }

}
