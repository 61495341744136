<div class="modal-header">
  <h4 class="modal-title">{{ title | translate }}</h4>
  <button type="button" class="btn btn-default" data-card-widget="collapse"
          (click)="activeModal.dismiss('close')"><i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">

  <form role="form" [formGroup]="createDepartmentForm">
    <div class="row">
      <div class="col-12">

        <div class="form-group">
          <label>Tên đơn vị <span class="text-danger">*</span></label>
          <div>
            <input type="text" class="form-control" placeholder="Tên đơn vị" formControlName="name"
                   [(ngModel)]="department.name"
                   >
          </div>
          <div *ngIf="createDepartmentForm.get('name')!.invalid && (createDepartmentForm.get('name')!.dirty || createDepartmentForm.get('name')!.touched)">
            <small class="text-danger" *ngIf="createDepartmentForm.get('name')?.errors?.isBlank">
              {{ 'error.validate.input_required' | translate }}
            </small>
            <small class="text-danger" *ngIf="createDepartmentForm.get('name')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
            <small class="text-danger" *ngIf="createDepartmentForm.get('name')?.errors?.maxlength">
              {{ 'error.validate.course.nameMaxlength' | translate }}
            </small>
          </div>
        </div>
      </div>
      <!-- <ng-template [ngIf]="this.departmentList.length > 0">
        <div class="col-12">
          <div class="form-group">
            <label>Đơn vị cha <span class="text-danger">*</span></label>
            <ng-select class="form-control select2 custom-select-box" formControlName="parentid"
            [items]="departmentMap | keyvalue"  bindLabel="value" bindValue="key" [editableSearchTerm]="true">
              <ng-option *ngFor="let item of departmentMap| keyvalue" [value]="item.key" >{{item.value}}</ng-option>
            </ng-select>
            <div *ngIf="createDepartmentForm.get('parentid')!.invalid && (createDepartmentForm.get('parentid')!.dirty || createDepartmentForm.get('parentid')!.touched)">
              <small class="text-danger" *ngIf="createDepartmentForm.get('parentid')?.errors?.required">
                {{ 'error.validate.input_required' | translate }}
              </small>
            </div>
          </div>
        </div>
      </ng-template> -->

    </div>
      <div class="col-12">
        <div class="form-group">
          <label>Mã Đơn vị <span class="text-danger">*</span></label>
          <div>
            <input type="text" class="form-control" placeholder="Mã Đơn vị" formControlName="code" [(ngModel)]="department.code" (change)="mustTypeIdDepartment()">
          </div>
          <div *ngIf="createDepartmentForm.get('code')!.invalid && (createDepartmentForm.get('code')!.dirty || createDepartmentForm.get('code')!.touched);else other_content">
            <small class="text-danger" *ngIf="createDepartmentForm.get('code')?.errors?.isBlank">
              {{ 'error.validate.input_required' | translate }}
            </small>
            <small class="text-danger" *ngIf="createDepartmentForm.get('code')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
            <small class="text-danger" *ngIf="createDepartmentForm.get('code')?.errors?.maxlength">
              {{ 'error.validate.course.nameMaxlength' | translate }}
            </small>
          </div>
          <ng-template #other_content>
            <div *ngIf="!validateCodeDep">
              <small class="text-danger">
                {{ 'department.code_error' | translate }}
              </small>
            </div>
          </ng-template>
        </div>
      </div>
  </form>
</div>
<div class="modal-footer text-center">
  <div>
    <button type="button" class="btn ml-1 btn-secondary rounded-0 mr-1"
            (click)="activeModal.dismiss('close')">{{ 'common.cancel' | translate }}</button>
    <button type="submit" class="btn ml-1 btn-primary rounded-0" (click)="createOrUpdateDepartment()">
      {{ button | translate }}
    </button>
  </div>
</div>
