import CommonUtil from 'src/app/shared/utils/common-util';
import {Component, HostListener, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {QuizContentService} from '../../shared/services/quiz-content.service';
import {Question} from '../../shared/model/question.model';
import { ActivatedRoute, Router } from '@angular/router';
import {ExamLessonService} from '../../shared/services/exam-lesson.service';
import {Location} from '@angular/common';
import {ExamResultComponent} from '../exam-result/exam-result.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmModalComponent} from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import {CountdownComponent} from 'ngx-countdown';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {USER_INFO, ANSWER_NUMBERING} from '../../shared/constants/base.constant';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import * as moment from 'moment';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})
export class ExamComponent implements OnInit, OnDestroy {

  quizid: any;
  attemptid: number;
  uniqueid: number;
  single: number;
  answercorrect: any;
  answercorrectMuti: any[][];
  finish: any;
  timeLimit: number;
  condition: boolean;
  checkattemptislimited: boolean;
  checkattemptisopen: boolean;
  checkattemptisfinish: boolean;
  checknoquestion: boolean;
  memoryStatus: number[];
  checkUpdate: [boolean];
  userInfo: any;
  checkUpdateMuti: boolean[][];
  quizName: string;
  overduehandling: string;
  elmnt: any;
  count: number[];
  checkgraceperiod: boolean;
  checkautoabandon: boolean;
  checktape: boolean[];
  open: boolean;
  countLeave: number;
  statusQuiz: string;
  finished: boolean;
  isShowExamAlert = false;
  isTeacher = false;
  isRandomQuestionNotEnough = false;
  isShowQuiz = false;
  questCheckedNumber = 0;

  numberAnswerAfterReload = 0;
  sumQuestionMustGet = 0;
  sumQuestionAnswered: number[] = new Array(31).fill(0);
  totalQuestionAnswered = 0;

  @ViewChild('cd', {static: false}) private countdown: CountdownComponent;
  countConfig: any;
  preventClickAnswer = false;
  modalDep;
  listQuestion: Question[] = [];
  ANSWER_NUMBERING = ANSWER_NUMBERING;
  TYPE_BUTTON = {
    truefalse: 'radio',
    multichoice: 'radio',
    multichoiceset: 'checkbox'
  };

  constructor(
    private quizContentService: QuizContentService,
    private route: ActivatedRoute,
    private examLessonService: ExamLessonService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private location: Location,
    private spinner: NgxSpinnerService,
    private $localStorage: LocalStorageService,
    public domSanitizer2: DomSanitizer,
    private sessionStorage: SessionStorageService,
    private toastrService: ToastrCustomService,
    private router: Router,
  ) {}


  ngOnInit(): void {
    this.countLeave = 0;
    this.open = true;
    this.memoryStatus = [0];
    this.count = [null];
    this.userInfo = this.$localStorage.retrieve(USER_INFO.INFO);
    this.quizName = this.$localStorage.retrieve('quizName');
    this.finished = this.$localStorage.retrieve('finished');
    this.isTeacher = this.$localStorage.retrieve('isTeacher');
    if (this.finished) {
      this.checkattemptisfinish = true;
    }
    this.overduehandling = this.$localStorage.retrieve('overduehandling');
    // tslint:disable-next-line:radix
    this.quizid = parseInt(this.route.snapshot.paramMap.get('quizId'));
    this.condition = false;
    this.startAttempt(this.quizid);
  }

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    if (this.preventClickAnswer) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  setConfigNotify() {
    const rs = [1];
    for (let i = 1; i < this.timeLimit; i++) {
      if (i % 10 === 0) {
        rs.push(i);
      }
    }
    return rs;
  }

  // @HostListener("mouseup", ["$event"])
  //   public onMouseDown(event: any): void {
  //     if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
  //       alert("Bạn không rời con trỏ chuột khỏi màn hình thi !");
  //     }
  //   }

  ngOnDestroy(): void {
    this.$localStorage.clear('quizName');
    this.$localStorage.clear('finished');
    this.modalService.dismissAll();
  }

  async canDeactivate(): Promise<Observable<boolean> | boolean> {
    // this.$localStorage.store('openTab', 2);
    if (this.checkattemptisfinish || this.checkattemptislimited || this.checkattemptisopen || this.checknoquestion || this.isTeacher) {
      return true;
    }
    if (!this.statusQuiz) {
      const rs = confirm(this.translateService.instant('common.noti.confirm_dialog_back_button_exam'));
      if (rs) {
        try {
          const quizResult = await this.examLessonService.finishAttempt(this.attemptid).toPromise();
          return true;
        } catch (error) {
          return true;
        }
      } else {
        history.pushState(null, null, location.href);
      }
      return rs;
    }
    return true;
  }

  leave() {
    if (this.isTeacher) {
      return;
    }
    if (this.countLeave === 3) {
      this.finishAttempt();
      this.open = false;
    }
    if (this.open) {
      this.countLeave++;
      const modalModule = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalModule.componentInstance.title = this.translateService.instant('warning');
      modalModule.componentInstance.body = this.translateService.instant('mouse');
      modalModule.componentInstance.confirmButton = this.translateService.instant('ok');
    }
  }

  timesUp(event) {
    this.showExamNotify(event);
    if (event.action === 'done') {
      // ket thuc bai thi thi show message khac, khong show alert nua
      this.isShowExamAlert = false;
      this.finishAttempt();
      // if (this.overduehandling === 'autosubmit') {
      //   this.finishAttempt();
      // }
      // if (this.overduehandling === 'graceperiod') {
      //   this.checkgraceperiod = true;
      // }
      // if (this.overduehandling === 'autoabandon') {
      //   this.checkautoabandon = true;
      // }
      return;
    }
  }

  showExamNotify(event) {
    if (event.action === 'notify' || event.action === 'start') {
      this.sessionStorage.store('timelimit', event.left / 1000);
      if (event.left <= 0) {
        this.preventClickAnswer = true;
        this.spinner.show();
        this.finishAttempt();
        this.spinner.hide();
        return;
      }
      if (event.left <= (2 * 60 * 1000)) {
        this.isShowExamAlert = true;
      }
    }
  }

  back() {
    // this.$localStorage.store('openTab', 2);
    window.history.back();
  }

  startAttempt(quizId) {
    this.spinner.show();
    this.examLessonService.startAttempt(quizId).subscribe((data) => {
      this.attemptid = data.body.id;
      this.timeLimit = data.body.timelimit;
      this.countConfig = {leftTime: data.body.timelimit, demand: true, notify: this.setConfigNotify(), format: 'HH:mm:ss:SS'};
      this.listQuestions(this.attemptid);
      this.uniqueid = data.body.uniqueid;
      if (!this.isTeacher) {
        this.sessionStorage.store('attemptid', this.attemptid);
      }
    }, err => {
      this.spinner.hide();
      if (err.error.errorcode === 'attemptislimited') {
        this.checkattemptislimited = true;
      } else if (err.error.errorcode === 'quizisnotopen') {
        this.checkattemptisopen = true;
      } else if (err.error.errorcode === 'quizisclosed') {
        this.checkattemptisfinish = true;
      } else if (err.error.errorcode === 'noquestionsfound') {
        this.checknoquestion = true;
      } else if (err.error.errorcode === 'notenoughrandomquestions'){
        this.isRandomQuestionNotEnough = true;
        this.checknoquestion = true;
      }else {
        this.toastrService.handlerError(err);
      }
      this.$localStorage.clear('attemptid');
    });

  }

  nextToElement(slot) {
    this.elmnt = document.getElementById(slot);
    this.elmnt.scrollIntoView(false);
  }

  finishAttempt() {
    this.spinner.show();
    if (this.isTeacher) {
      return;
    }
    if (this.countdown !== null && this.countdown !== undefined) {
      this.countdown.stop();
    }
    if (this.modalDep) {
      this.modalDep.dismiss();
    }
    this.open = false;
    const modalDep = this.modalService.open(ExamResultComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    // modalDep.componentInstance.title = this.translateService.instant('exam_result.title');
    // modalDep.componentInstance.body = this.translateService.instant('course.delete_confirm_content');
    // modalDep.componentInstance.confirmButton = this.translateService.instant('exam_result.back');
    this.examLessonService.finishAttempt(this.attemptid).subscribe((data) => {
      modalDep.componentInstance.correctquestion = data.body?.correctquestion;
      modalDep.componentInstance.totalquestion = data.body?.totalquestion;
      modalDep.componentInstance.grade = Math.ceil(data.body?.grade);
      modalDep.componentInstance.gradequiz = Math.ceil(data.body?.gradequiz * 100) / 100;
      modalDep.componentInstance.sumgrades = data.body?.sumgrades;
      modalDep.componentInstance.turnOfExam = data.body?.attempt && data.body?.attempt > 9 ? data.body?.attempt : '0' + data.body?.attempt;
      modalDep.componentInstance.timeResult = moment((data.body?.timefinish - data.body?.timestart * 1000) - 900).utcOffset(0).format('HH:mm:ss:SS');
      if (data.body.grade > data.body.sumgrades) {
        this.statusQuiz = 'Chưa qua';
      } else {
        this.statusQuiz = 'Đã qua';
      }
      modalDep.componentInstance.statusQuiz = this.statusQuiz;
      if (this.countdown !== null && this.countdown !== undefined) {
        this.countdown.stop();
      }
      this.sessionStorage.clear('attemptid');
      this.spinner.hide();
    }, error => {
      this.sessionStorage.clear('attemptid');
      this.spinner.hide();
    });
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.router.navigate(['/home']);
      }
    }).catch(() => {
    });

  }

  answerSingle(question, indexOfAnswer) {
    // tslint:disable-next-line:no-shadowed-variable
    question.answers.forEach((ans, index) => {
      if (index === indexOfAnswer){ // check vao dap an vua chon
        question.answeruser[index] = 1;
      }else { // cac dap an khac bo check
        question.answeruser[index] = 0;
      }
    });

    // đánh dấu là đã lựa chọn
    this.sumQuestionAnswered[question.number] = 1;
    this.countNumberAnswer();
    this.update(question);
  }

  countNumberAnswer(){
    let count = 0;
    this.sumQuestionAnswered.forEach(item => {
      if (item){
        count++;
      }
    });
    this.totalQuestionAnswered = count + this.numberAnswerAfterReload;
  }

  answerMulti(question, indexOfAnswer, event) {
    const x = question;
    let isChecked = false;
    // tslint:disable-next-line:no-shadowed-variable
    question.answers.forEach((ans, index) => {
      if (indexOfAnswer === index && event.checked){ // case check vao checkbox
        question.answeruser[index] = 1;
      }
      if (indexOfAnswer === index && !event.checked){ // case uncheck vao checkbox
        question.answeruser[index] = 0;
      }
      if (question.answeruser[index] === 1) {
        isChecked = true;
      }
    });

    // check câu hỏi đã được đánh trả lời hay chưa
    if (isChecked) {
      this.sumQuestionAnswered[question.number] = 1;
      this.countNumberAnswer();
    }
    this.update(question);
  }

  update(question) {
    question.disabled = true;
    this.highLightQuestionLabel(question);
    this.questCheckedNumber = [...this.listQuestion].filter(question => question.condition === 2).length;
    this.examLessonService.updateAnswer(this.attemptid,
      this.uniqueid, question.slot,
      question.answeruser, question.single, question.type, question.condition != 3 ? 0 : 1).subscribe((data) => {
      question.disabled = false;
    }, error => { // error thi show loi chua luu duoc dap an
      question.disabled = false;
      this.toastrService.handlerError(error);
    });
  }

  highLightQuestionLabel(question) {
    if (this.isAnswerTheQuestion(question) && this.isMarkQuestion(question)){
      question.condition = 3;
    }

    if (this.isAnswerTheQuestion(question) && !this.isMarkQuestion(question)){
      question.condition = 2;
    }

    if (!this.isAnswerTheQuestion(question) && !this.isMarkQuestion(question)){
      question.condition = 1;
    }
  }

  isAnswerTheQuestion(question){
    // Neu da tra loi thi danh dau;
    return (question.answeruser.indexOf(1) >= 0);
  }

  isMarkQuestion(question){
    return question.condition === 3;
  }

  markQuestion(event, question) {
    if (this.isTeacher) {
      return;
    }
    if (event.checked) {
      this.questCheckedNumber++;
      question.condition = 3;
    } else if (this.isAnswerTheQuestion(question)){
      question.condition = 2;
    } else {
      question.condition = 1;
    }
    this.update(question);
  }

  listQuestions(attemptid) {
    this.spinner.show();
    this.quizContentService.listQuestion(attemptid).subscribe((data) => {
      this.listQuestion = data.body.questions;
      this.sumQuestionMustGet = this.listQuestion.length * 0.7;
      // Đếm số câu đã trả lời được khi load trang
      this.listQuestion.forEach(question => {
        if (question.type === 'multichoiceset'){
          let isChecked = false;
          question.answers.forEach(answer => {
            if (answer.checked) {
              isChecked = true;
            }
          });
          if (isChecked) {
            this.numberAnswerAfterReload++;
          }
        } else {
          question.answers.forEach(answer => {
            if (answer.checked) {
              this.numberAnswerAfterReload++;
            }
          });
        }

      });
      this.setDefaultListQuestion();
      this.questCheckedNumber = [...this.listQuestion].filter(question => question.condition === 2).length;
      this.countdown.begin();
      this.spinner.hide();
    }, err => {
      if (err.error.errorcode === 'codingerror') {
        this.spinner.hide();
        this.countdown.stop();
        this.checknoquestion = true;
      }
    });
  }

  setDefaultListQuestion(){
    this.listQuestion.forEach(element => {
      element.answernumbertype = this.ANSWER_NUMBERING[element.answernumbering];
      element.condition = 1;
      element.typeCheckBox = this.TYPE_BUTTON[element.type];
      if (element.questiontext.includes('<figure class=\"media\">')) {
        element.linkVideo = CommonUtil.linkVideoCkeditorToLinkEmbedYoutube(element.questiontext);
        element.linkVideo = this.domSanitizer2.bypassSecurityTrustResourceUrl(element.linkVideo);
      }
      // init answeruser
      element.answeruser = [];
      element.answers.forEach((ans, index) => {
        // mac dinh la tra loi sai toan bo
        element.answeruser[index] = 0;
        if (ans.checked) {
          element.answeruser[index] = 1;
          element.condition = 2;
        }
      });
      if (element.flagged) {
        element.condition = 3;
      }
      element.disabled = false;
    });
  }

  @HostListener('window:beforeunload')
  handleBeforeUnload() {
    if (this.checkattemptisfinish || this.checkattemptislimited
      || this.checkattemptisopen || this.checknoquestion || this.statusQuiz || this.isTeacher) {
      return true;
    } else {
      this.sessionStorage.store('listquestion', this.listQuestion);
      this.sessionStorage.store('uniqueid', this.uniqueid);
      return false;
    }
  }

  openConfirmPopUp() {
    this.open = false;
    this.modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static'
    });
    this.modalDep.componentInstance.title = this.translateService.instant('exam.confirm_submit_eu_title');
    this.modalDep.componentInstance.body = this.translateService.instant('exam.confirm_submit_eu_content');
    this.modalDep.componentInstance.confirmButton = this.translateService.instant('exam.sub_button');
    this.modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.finishAttempt();
      }
    });
  }

  oncChangeShowQuiz() {
    this.isShowQuiz = !this.isShowQuiz;
  };
}
