<div class="modal-header">
  <h5 class="modal-title">{{'course.unregistration_confirm_title' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{'course.unregistration_confirm_content' | translate}}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary"
          (click)="activeModal.dismiss('close')">{{ 'common.cancel' | translate }}</button>
  <button type="button" class="btn btn-danger"
          (click)="activeModal.close('confirm')">Đồng ý</button>
</div>
