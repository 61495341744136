import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LoginService} from '../shared/services/login.service';
import {LocalStorageService} from 'ngx-webstorage';
import {USER_INFO} from '../shared/constants/base.constant';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanDeactivate<CanComponentDeactivate> {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private $localStorage: LocalStorageService,
  ) {
  }

  check: boolean;

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.loginService.isLoggedIn()) {
      this.check = false;
      const roles = this.$localStorage.retrieve(USER_INFO.ROLES);
      const authorities = next.data.authorities;
      const authorities1 = next.data.breadcrumb;

      if (roles) {
        for (const role of roles) {
          if (role.shortname === 'admin') {
            this.check = true;
          }
        }
      }
      if (state.url.includes('admin/') && !this.check) {
        const menus = this.$localStorage.retrieve(USER_INFO.MENUS);
        if (menus) {
          for (const menu of menus) {
            if (menu.code === authorities) {
              return true;
            }
          }
        }
        return false;
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/home']);  // {4}
    }
  }

  canDeactivate(component: CanComponentDeactivate,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {

    const url: string = state.url;
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
