import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-delete-badge-popup',
  templateUrl: './delete-badge-popup.component.html',
  // styleUrls: ['./delete-badge-popup.component.css'],
  providers: [NgbModal, NgbModalConfig]
})
export class DeleteBadgePopupComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;
  @Input() confirmButton: string;
  @Output() onChecked: EventEmitter<any> = new EventEmitter<any>();
  isChecked : boolean =false;
  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  changeValueChecked(checked:any){
    // if(checked){
    //   this.isChecked = true;
      
    //   // debugger;
    // }
    this.onChecked.emit(checked);
    
  }
 
}
