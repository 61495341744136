<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Quản lý vai trò</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý vai trò</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <div class="container-fluid">
    <div class="row w-50">
      <div class="col-12">
        <mat-drawer-container autosize class="example-container">
          <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
            <div class="card card-default">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div>
                      <h6>Danh sách vai trò</h6>
                      <hr>
                      <div class="row mb-2 desc">
                        <div class="col-12">
                          <form class="form-inline">
                            <div class="form-group mx-sm-3 mb-2">
                              <input type="text" class="form-control" placeholder="Tìm kiếm">
                            </div>
                            <!-- <button type="submit" (click)="openRoleCreatePopup()"   class="btn btn-primary mb-2">Tạo vai trò</button> -->
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <mat-selection-list #role [multiple]="false" class="w-100">
                    <mat-list-option *ngFor="let role of typesOfRole" (click)="showListMenu(role.id,role.shortname)">
                      {{traslateRoleName(role.shortname)}}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
            </div>
          </mat-drawer>
          <div class="example-sidenav-content">
            <div class="col-12">
              <div class="card card-default">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div>
                        <h6>Danh sách chức năng</h6>
                        <hr>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <!-- <h6 class="mb-3">Quản trị hệ thống</h6> -->
                      <mat-selection-list [(ngModel)]="menuIds" (ngModelChange)="onNgModelChange($event)">
                        <mat-list-option *ngFor="let menu of listMenuFunc" [value] = "menu.id">
                          {{menu.name}}
                        </mat-list-option>
                      </mat-selection-list>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-center">
                  <button type="button" (click)="cancel()" [disabled]="isDisableCancelBtn" class="btn btn-light btn-lg mr-2"><i class="fas fa-cancel"></i>Hủy</button>
                  <button type="button" (click)="update()" [disabled]="isDisableUpdateBtn" class="btn btn-primary btn-lg"><i
                    class="fas fa-save mr-2"></i>Lưu lại
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-drawer-container>
      </div>
    </div>
  </div>
</div>
