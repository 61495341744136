<div class="modal-header">
  <h5 class="modal-title" *ngIf="!isSurveyDetail && !isNotify">{{titleModal}}</h5>
  <h5 class="modal-title" *ngIf="isSurveyDetail">Chọn khóa học cần xem khảo sát</h5>
  <h5 class="modal-title" *ngIf="isNotify">Chọn kỳ thi cần thông báo</h5>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
          (click)="activeModal.dismiss()"><i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <form>
      <div class="form-row">
        <div class="col-auto">
          <input type="text" class="form-control" placeholder="Tìm kiếm..." name="search" [(ngModel)]="search" *ngIf="!isSurveyDetail"
                 (change)="onKeyUp()">
        </div>
      </div>
    </form>

    <div class="col-12 mt-3 table-responsive overflow-auto" style="height: 50vh;">
      <table class="table table-hover">
        <thead>
        <tr>
          <th *ngIf="!isNotify">
            <mat-icon class="mt-1">
              <mat-checkbox class="example-margin" [(ngModel)]="completedAll"
                            (change)="oncheckboxAll($event.checked)"></mat-checkbox>
            </mat-icon>
          </th>

          <th *ngIf="isNotify">
            <mat-icon class="mt-1">
              <mat-checkbox class="example-margin" [(ngModel)]="completedAll"
                            (change)="oncheckboxAll($event.checked)"></mat-checkbox>
            </mat-icon>
          </th>

          <th>{{thTable.order}}</th>
          <th>{{thTable.col_1}}</th>
          <th>{{thTable.col_2}}</th>
          <th>{{thTable.col_3}}</th>
          <th>{{thTable.col_4}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of courses; let i = index;">
          <td style="width:20px;">
            <mat-icon class="mt-1">
              <mat-checkbox [(ngModel)]="item.completed" (change)="oncheckboxItem(item.id, $event.checked)"
                            class="example-margin"></mat-checkbox>
            </mat-icon>
          </td>
          <td style="width:20px;">{{(pageIndex - 1) * pageSize + i + 1}}</td>
          <td
            *ngIf="!isSurveyDetail && !isNotify">{{item?.fullname?.length <= 40 ? item.fullname : (item.fullname | slice:0:40) + '...' }}</td>
          <td
            *ngIf="isSurveyDetail || isNotify">{{item?.fullname?.length <= 40 ? item.fullname : (item.fullname | slice:0:40) + '...' }}</td>
          <td>{{getDateFromUnix(item.startdate)  | date:'dd/MM/yyyy'}}</td>
          <td>{{getDateFromUnix(item.enddate)| date:'dd/MM/yyyy'}}</td>
          <td>{{item.departmentname?.length <= 40 ? item.departmentname : (item.departmentname | slice:0:40) + '...' }}</td>

        </tr>
        </tbody>
      </table>
      <br />
      <p *ngIf="courses?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
    </div>
  </div>
</div>
<div class="card-footer text-center">
  <b>Đã chọn: {{courseIdsChecked?.length}}</b>
  <div class="row">
    <mat-paginator showFirstLastButtons
        [length]="totalRecord"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex - 1"
        (page)="changePage($event)"
        [hidePageSize]="false"
        [pageSizeOptions]="pageSizeOptions">
    </mat-paginator>
  </div>
</div>
<div class="modal-footer">
  <button type="button" (click)="cancel()" class="btn btn-default round-0 mr-2"><i class="fas fa-close"></i>Hủy</button>
  <button type="button" (click)="save()" class="btn btn-primary" *ngIf="!isSurveyDetail && !isNotify"><i
    class="fas fa-save mr-1"></i>Lưu lại
  </button>
  <button type="button" (click)="save()" class="btn btn-primary" *ngIf="isNotify"><i class="fas fa-save mr-1"></i>Lựa
    Chọn
  </button>
</div>


