import { PageEvent } from '@angular/material/paginator';
import {NgxSpinnerService} from 'ngx-spinner';
import {ExamListService} from '../../shared/services/exam-list.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {LocalStorageService} from 'ngx-webstorage';


@Component({
  selector: 'app-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.css']
})
export class ExamListComponent implements OnInit {

  exams: any[] = [];
  examsConst: any[] = [];
  currentDate: Date = new Date();
  pageIndex: number = 1;
  pageSize: number = 6;
  pageSizeOptions: number[] = [6, 12, 24, 48];
  totalExam: number;
  status: number = 0;

  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(
    private examListService: ExamListService,
    private $localStorage: LocalStorageService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.getListExams();
  }

  getListExams() {
    this.spinner.show();
    const params = {
      limit: this.pageSize,
      page: this.pageIndex,
      status: +this.status
    }
    return this.examListService.listExam(params).subscribe((data) => {
      data.body.results?.forEach(element => {
        if (this.currentDate > moment.unix(element.enddate).toDate()) {
          element.finished = true;
        }else {
          element.finished = false;
        }
      });
      this.exams = data.body.results;
      this.totalExam = data.body.total;
      this.spinner.hide();
    });
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  edit(id) {

  }

  localStore(name, finished) {
    this.$localStorage.store('examName', name);
    this.$localStorage.store('finished', finished);
  }

  delete(id) {
  }

  onChangeDataExam(status) {
    this.status = status;
    this.pageIndex = 1;
    this.getListExams();
  }

  onChangePage(event){
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getListExams();
  }

}
