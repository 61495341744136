<div class="content-wrapper">
  <section class="content-header pb-0">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Quản lý kỳ thi : {{examForm.value.fullname}}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item">
							<a href="#" routerLink="/admin">CMS</a>
						</li>
						<li class="breadcrumb-item active"><a [routerLink]="['/admin/exam']">Quản lý kỳ thi</a></li>
						<li class="breadcrumb-item active">Thông tin kỳ thi</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <section class="content">
    <div class="container-fluid">
  <button  class="btn btn-success" *ngIf="checkRoleFunction('PUBLISH_COURSE',role) === true && !examDetail.published && !examDetail.readonly" (click)="onPublishExam()">Hiển thị kỳ thi</button>
  <button class="btn btn-default" *ngIf="checkRoleFunction('PUBLISH_COURSE',role) === true && examDetail.published  && !examDetail.readonly" (click)="onUnPublishExam()">Ẩn kỳ thi</button>
  <mat-tab-group dynamicHeight selectedIndex="1">
  <mat-tab>
      <div class="container-fluid">
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">infor</mat-icon>
           Thông tin kỳ thi
        </ng-template>
        <form role="form" [formGroup]="examForm">
          <div class="row p-5">
            <div class="col-6">
            <div class="form-group fullname-require">
              <label class="control-label">Tên kì thi</label><span class="text-danger"> *</span>
              <input type="text" class="form-control" placeholder="Tên đầy đủ" required="" data-placement="bottom" formControlName="fullname">
              <div *ngIf="examForm.get('fullname')!.invalid &&(examForm.get('fullname')!.dirty || examForm.get('fullname')!.touched)">
                <small class="text-danger" *ngIf="examForm.get('fullname')?.errors?.required">
                  {{ 'error.validate.input_required' | translate }}
                </small>
                <small class="text-danger" *ngIf="examForm.get('fullname')?.errors?.isBlank">
                  {{ 'error.validate.input_required' | translate }}
                </small>
                <small class="text-danger" *ngIf="examForm.get('fullname')?.errors?.maxlength">
                  {{ 'error.validate.course.nameMaxlength' | translate }}
                </small>
              </div>
            </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Danh mục chủ đề kì thi</label><span class="text-danger"> *</span>
                <select class="form-control select2" style="width: 100%;" formControlName="categoryid">
                <option [value]="0" disabled selected >Chọn danh mục</option>
                <option *ngFor="let item of categoryMap | keyvalue" [value]="item.key">{{item.value}}</option>
                </select>
                <div *ngIf="examForm.get('categoryid')!.invalid &&(examForm.get('categoryid')!.dirty || examForm.get('categoryid')!.touched)">
                  <small class="text-danger" *ngIf="examForm.get('categoryid')?.errors?.required">
                    {{ 'error.validate.input_required' | translate }}
                  </small>
                </div>
               </div>
              </div>
            <div class="col-12">
            <label>Thời gian thi</label><span class="text-danger"> *</span>
            <div class="form-group" >
              <div class="row">
                <div class="col-2">
                  <select class="form-control" formControlName="hourStartDate">
                    <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
                  </select>
                </div>
                <div class="col-2">
                  <select class="form-control" formControlName="minuteStartDate">
                    <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
                  </select>
                </div>
                <div class="col-2">
                    <mat-form-field color="blue" class="mr-3 mb-0 course-input">
                        <mat-label>Từ ngày</mat-label>
                        <input matInput [matDatepicker]="startdateModel" [min]="!examDetail.publishedat ? currentDate : (getDateFromUnix(examDetail.startdate) > currentDate) ? currentDate : getDateFromUnix(examDetail.startdate)" formControlName="startdate">
                        <mat-datepicker-toggle matSuffix [for]="startdateModel"></mat-datepicker-toggle>
                        <mat-datepicker #startdateModel></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-2">
                  <select class="form-control" formControlName="hourEndDate">
                    <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
                  </select>
                </div>
                <div class="col-2">
                  <select class="form-control" formControlName="minuteEndDate">
                    <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
                  </select>
                </div>

                <div class="col-2">
                    <mat-form-field color="blue" class="course-input mb-0">
                      <mat-label>Đến ngày</mat-label>
                      <input matInput [matDatepicker]="enddateModel" [min]="examForm.value.startdate" formControlName="enddate">
                      <mat-datepicker-toggle matSuffix [for]="enddateModel"></mat-datepicker-toggle>
                      <mat-datepicker #enddateModel color="primary"></mat-datepicker>
                    </mat-form-field>
                </div>
                <small class="text-danger" *ngIf="startTimeInvalid">
                  {{ 'error.validate.start_date_invalid' | translate }}
                </small>
                <small class="text-danger" *ngIf="endTimeInvalid">
                  {{ 'error.validate.end_date_invalid' | translate }}
                </small>
              </div>
            </div>

              <div class="col-6">
                <div class="form-group fullname-require">
                  <mat-checkbox [disabled]="true" [checked]="examDetail.badgeid" class="example-margin">Tạo chứng chỉ cho khóa học/ kỳ thi</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="happening!=='yes' && !examDetail.readonly && checkRoleFunction('EDIT_COURSE_INFO',role) === true" class="card-footer text-center">
            <button type="submit" class="btn btn-default" *ngIf="formEnable && examDetail.published !== 1" (click)="cancelUpdateExam()" >Hủy</button>
            <button type="submit" class="btn btn-primary" *ngIf="!formEnable && examDetail.published !== 1" (click)="onUpdateExam()" >Cập nhật</button>
            <button type="submit"  class="btn btn-success" *ngIf="formEnable && examDetail.published !== 1" [disabled]="examForm.invalid" (click)="updateExam()" >Lưu lại</button>
          </div>
          </form>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">book</mat-icon>
      Các bài thi
      </ng-template>
      <div class="container-fluid">
        <app-list-exam
          [published]="examDetail.published"
          [examhasstudent]="examDetail.coursehasstudent"
          [courseId]="examId"
          [happening]="happening"
          *ngIf="showCreateQuiz == false"
          (quizEdit)='showQuiz($event)'
          (showCreateQuizz)='handleShowCreateQuiz($event)'
          (checkCreate)='check($event)'
          [examReadOnly]="examDetail.readonly">
        </app-list-exam>
        <app-create-exam
          [examDetail]="examDetail"
          *ngIf="showCreateQuiz == true"
          [quizInput]="quizOutput"
          [checkCreate]="checkC"
          (showCreateQuizz)='handleShowListQuiz($event)'>
        </app-create-exam>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">assignment_ind</mat-icon>
        {{isTeacher ? 'Danh sách học viên' : 'Thêm học viên'}}
      </ng-template>
      <app-add-user *ngIf="examDetail.id" [courseDetail]="examDetail"></app-add-user>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">group</mat-icon>
        {{isTeacher ? 'Danh sách giảng viên' : 'Thêm giảng viên'}}
    </ng-template>
    <app-add-teacher *ngIf="examDetail.id" [courseDetail]="examDetail"></app-add-teacher>
    </mat-tab>
  </mat-tab-group>
</div>
</section>
</div>
