
    <div class="modal-header">
      <h5 class="modal-title sfbold">Đăng nhập</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()" style="outline:none;">
        <span aria-hidden="true" >&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="loginForm" (keyup.enter)="login()">
        <div class="row">
          <div class="iteamdoan col-12 text-center">
            <img src="/assets/ladipage/img/doan.png" alt="">
          </div>
          <div class="col-12 text-center">
            <p><i>Xin vui lòng đăng nhập để vào thi</i><br>
              Nếu bạn chưa có tài khoản vui lòng đăng ký <a (click)="gotoContact()" class="text-danger sfbold text-underline" style="cursor:pointer;" aria-label="Close">Tại
                đây</a>
            </p>
          </div>
          <div class="col-12 mt-2">
            <div class="form-group">
              <label>Email</label>
              <input type="text" formControlName="username" class="form-control" placeholder=""
                     aria-describedby="helpId">
              <span class="text-danger"
                    *ngIf="loginForm.get('username').touched && loginForm.get('username').invalid && loginForm.get('username').dirty">
                    {{ 'error.validate.input_required' | translate }}
                  </span>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label>Mật khẩu</label>
              <input type="password" formControlName="password" class="form-control" placeholder=""
                     aria-describedby="helpId">
              <span class="text-danger"
                    *ngIf="loginForm.get('password').touched && loginForm.get('password').invalid && loginForm.get('password').dirty">
              {{ 'error.validate.input_required' | translate }}
            </span>
            </div>
          </div>
          <div class="col-12">
            <a (click)="forgetPassword()" class="text-danger sfbold text-underline" style="cursor:pointer;">Quên mật khẩu ?</a>
          </div>
          <div class="col-12" *ngIf="!isConfirmed">
            <a (click)="resendActivationEmail()" class="text-danger sfbold text-underline" style="cursor:pointer;">Gửi lại email xác thực?</a>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer m-auto border-0">
      <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" (click)="close()">Thoát</button>
      <button type="button" class="btn btn-danger" [disabled]="loginForm.invalid" (click)="login()">Đăng nhập</button>
    </div>

