<div class="modal-header">
    <h5 class="modal-title sfbold text-dark">Tài liệu tham khảo</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()" style="outline:none;">

      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
        <div class="col-12 table-responsive overflow-auto" style="height: 55vh;">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>STT</th>
                        <th>Tên tài liệu</th>
                        <th>Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Báo cáo chính trị của BCH Đoàn Khối khoá III</td>
                        <td><a href="https://drive.google.com/file/d/1adS4Hxb_moVeoA5N74I9GxpUaAkoUUP8/view?usp=share_link"> <i class="fas fa-download"></i> Tải xuống</a></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Chương trình hành động thực hiện NQ ĐH ĐK IV</td>
                        <td><a href="https://drive.google.com/file/d/1Q4lFog4-h8yfCz9QWblJ-W9fGKNHODLP/view?usp=share_link"><i class="fas fa-download"></i> Tải xuống</a></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Nghị quyết Đại hội Đoàn Khối khóa IV</td>
                        <td><a href="https://drive.google.com/file/d/1kxR-e4l0ro1vcsfjJ3D30tbKsrvSegbn/view?usp=share_link"><i class="fas fa-download"></i> Tải xuống</a></td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>slide bài giảng Chương trình hành động</td>
                        <td><a href="https://docs.google.com/presentation/d/1ltpmbjTG0a48vFODeMQGrpk9RxqUisqc/edit?usp=share_link&ouid=115527833358631507569&rtpof=true&sd=true"><i class="fas fa-download"></i> Tải xuống</a></td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>slide bài giảng Nghị Quyết</td>
                        <td><a href="https://docs.google.com/presentation/d/1uuqJDR6bCsw8mQVwR0ltgyd56uUi-5j5/edit?usp=share_link&ouid=115527833358631507569&rtpof=true&sd=true"><i class="fas fa-download"></i> Tải xuống</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="modal-footer">    <div class="row  m-auto">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" (click)="close()">Thoát</button>
    </div>
</div>




