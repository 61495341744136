<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row pl-5 pr-5">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">
              Tên bài khảo sát
            </h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="row text-center justify-content-center">
              <div class="col-8">
                <div class="row">
                  <div class="col-12 text-center mt-3">
                    <button class="btn btn-secondary btn-sm" type="submit" *ngIf="isTemplate"
                      (click)="openListCourse()">
                      <i class="nav-icon fas fa-plus mr-1"></i>Chọn khóa học
                    </button>
                  </div>
                  <div class="col-12 text-center mt-3">
                    <h5>{{survey.name}}</h5> <button class="btn btn-default btn-sm ml-2" *ngIf="isTemplate && courseId" (click)="onExport()"><i class="fas fa-file-excel"></i> Xuất excel</button>
                  </div>
                </div>
                <mat-tab-group mat-align-tabs="start">
                  <mat-tab label="Tóm tắt khảo sát">
                    <ng-container *ngIf="survey.id && isHasQuestionCourse">
                      <h6 class="pt-2" *ngIf="isTemplate">Danh sách câu khảo sát dành cho khóa học</h6>
                      <ng-container *ngFor="let question of survey.listquestion; let i = index">
                        <mat-expansion-panel [expanded]="true" class="mt-3"
                          *ngIf="question.isteacher === '0' || !question.isteacher">

                          <mat-expansion-panel-header>
                            <mat-panel-title [innerHTML]="question.content">
                            </mat-panel-title>
                          </mat-expansion-panel-header>

                          <!--                          text question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.TEXT">
                            <p class="ml-2">Số lượng câu trả lời: {{question.countAnswer}}</p>
                            <div class="col-12 overflow-auto" style="max-height: 400px;"
                              *ngFor="let answerText of question?.answers">
                              <div class="alert alert-light mb-1">
                                {{answerText.number}}
                              </div>
                            </div>
                          </div>

                          <!--  select question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.RADIO">
                            <p class="ml-2">Số lượng câu trả lời: {{question.countAnswer}}</p>
                            <div class="col-12 overflow-auto" style="max-height: 400px;">
                              <div class="row text-center justify-content-center">
                                <div class="col-8">
                                  <div style="display: block">
                                    <canvas baseChart [datasets]="question.chart" [labels]="barChartLabels"
                                      [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                                      [chartType]="barChartType">
                                    </canvas>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!--  rate question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.RATE">
                            <p class="ml-2">Số lượng câu trả lời: {{question.countAnswer}}</p>
                            <div class="col-12 overflow-auto" style="max-height: 400px;">
                              <div class="row text-center justify-content-center">
                                <div class="col-8">
                                  <div style="display: block">
                                    <canvas baseChart [datasets]="question.chart" [labels]="barChartLabels"
                                      [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                                      [chartType]="barChartType">
                                    </canvas>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="survey.id && isHasQuestionTeacher">
                      <h6 class="pt-2">Danh sách câu khảo sát dành cho giảng viên</h6>
                      <ng-container *ngFor="let question of survey.listquestion; let i = index">
                        <mat-expansion-panel [expanded]="true" class="mt-3" *ngIf="question.isteacher === '1'">
                          <mat-expansion-panel-header>
                            <mat-panel-title [innerHTML]="question.content">
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <!--                          text question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.TEXT">
                            <p class="ml-2">Số lượng câu trả lời: {{question.countAnswer}}</p>
                            <div class="col-12 overflow-auto" style="max-height: 400px;"
                              *ngFor="let answerText of question?.answers">
                              <div class="alert alert-light mb-1">
                                {{answerText.number}}
                              </div>
                            </div>
                          </div>

                          <!--  select question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.RADIO">
                            <p class="ml-2">Số lượng câu trả lời: {{question.countAnswer}}</p>
                            <div class="col-12 overflow-auto" style="max-height: 400px;">
                              <div class="row text-center justify-content-center">
                                <div class="col-8">
                                  <div style="display: block">
                                    <canvas baseChart [datasets]="question.chart" [labels]="barChartLabels"
                                      [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                                      [chartType]="barChartType">
                                    </canvas>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!--  rate question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.RATE">
                            <p class="ml-2">Số lượng câu trả lời: {{question.countAnswer}}</p>
                            <div class="col-12 overflow-auto" style="max-height: 400px;">
                              <div class="row text-center justify-content-center">
                                <div class="col-8">
                                  <div style="display: block">
                                    <canvas baseChart [datasets]="question.chart" [labels]="barChartLabels"
                                      [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                                      [chartType]="barChartType">
                                    </canvas>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </ng-container>
                    </ng-container>
                  </mat-tab>

                  <mat-tab label="Cá nhân">
                    <div class="col-12" *ngIf="survey.id && users.length > 0">
                      <div class="btn-group mt-2">
                        <button type="button" class="btn btn-default btn-lg mr-1" matTooltip="Trước"
                          matTooltipClass="tooltip-red" (click)="previous()" [disabled]="currentUserIndex === 0"><i
                            class="fas fa-arrow-left mr-1"></i></button>
                        <input type="text" disabled class="border-0 text-center" tabindex="0"
                          value="{{currentUserIndex+1}}" aria-label="Số phản hồi" min="1" max="{{users.length}}"
                          step="1">
                        <button type="button" class="btn btn-default btn-lg mr-1" matTooltip="Sau"
                          [disabled]="currentUserIndex === (users.length - 1)" matTooltipClass="tooltip-red"
                          (click)="next()"><i class="fas fa-arrow-right mr-1"></i></button>
                      </div>
                    </div>
                    <ng-container *ngIf="survey.id && isHasQuestionCourse">
                      <h6 class="pt-2" *ngIf="isTemplate">Danh sách câu khảo sát dành cho khóa học</h6>
                      <ng-container *ngFor="let question of survey.listquestion; let i = index">
                        <mat-expansion-panel [expanded]="true" class="mt-3"
                          *ngIf="question.isteacher === '0' || !question.isteacher">
                          <mat-expansion-panel-header>
                            <mat-panel-title [innerHTML]="question.content">
                            </mat-panel-title>
                          </mat-expansion-panel-header>

                          <!--                          text question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.TEXT">
                            <div class="col-12 overflow-auto" style="max-height: 400px;"
                              *ngFor="let answerText of question.userAnswers">
                              <div class="alert alert-light mb-1">
                                {{answerText.number}}
                              </div>
                            </div>
                          </div>

                          <!--  select question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.RADIO">
                            <div class="col-12 overflow-auto" style="max-height: 400px;"
                              *ngFor="let answerText of question.userAnswers">
                              <div class="alert alert-light mb-1">
                                {{answerText.rankvalue}}
                              </div>
                            </div>
                          </div>


                          <!--  rate question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.RATE">
                            <div class="col-12 overflow-auto" style="max-height: 400px;"
                              *ngFor="let answerText of question.userAnswers">
                              <div class="alert alert-light mb-1">
                                {{answerText.rankvalue}}
                              </div>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="survey.id && isHasQuestionTeacher">
                      <h6 class="pt-2">Danh sách câu khảo sát dành cho giảng viên</h6>
                      <ng-container *ngFor="let question of survey.listquestion; let i = index">
                        <mat-expansion-panel [expanded]="true" class="mt-3" *ngIf="question.isteacher === '1'">
                          <mat-expansion-panel-header>
                            <mat-panel-title [innerHTML]="question.content">
                            </mat-panel-title>
                          </mat-expansion-panel-header>

                          <!--                          text question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.TEXT">
                            <div class="col-12 overflow-auto" style="max-height: 400px;"
                              *ngFor="let answerText of question.userAnswers">
                              <div class="alert alert-light mb-1">
                                {{answerText.number}}
                              </div>
                            </div>
                          </div>

                          <!--  select question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.RADIO">
                            <div class="col-12 overflow-auto" style="max-height: 400px;"
                              *ngFor="let answerText of question.userAnswers">
                              <div class="alert alert-light mb-1">
                                {{answerText.rankvalue}}
                              </div>
                            </div>
                          </div>


                          <!--  rate question-->
                          <div class="row" *ngIf="question.type === QUESTION_TYPE.RATE">
                            <div class="col-12 overflow-auto" style="max-height: 400px;"
                              *ngFor="let answerText of question.userAnswers">
                              <div class="alert alert-light mb-1">
                                {{answerText.rankvalue}}
                              </div>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </ng-container>
                    </ng-container>
                  </mat-tab>

                  <!-- <mat-tab label="Third">Content 3</mat-tab> -->
                </mat-tab-group>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</div>
