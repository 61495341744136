<div class="modal-header">
  <h5 class="modal-title"></h5>
  <button type="button" class="btn btn-tool" data-card-widget="collapse" (click)="activeModal.dismiss()"><i
          class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row">
      <div class="col-lg-12 col-md-auto pb-2">
        <h5>{{courseName}}</h5>
          <div class="overflow mt-3">
             <table class="table table-striped">
              <thead>
                <tr>
                  <th>TT</th>
                  <th>Bài học/Bài thi</th>
                  <th>Trạng thái</th>
                  <th class="text-center">Ngày học</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let history of listHistoryLearning; let i=index">
                  <td>{{i+1}}</td>
                  <td>{{history.coursemodulename}}</td>
                  <td>{{history.completionstate == 1 || history.completionstate == 2 ? 'Đã hoàn thành' : 'Chưa hoàn thành'}}</td>
                  <td class="text-center">{{ getDateFromUnix(history.time) | date:'dd/MM/yyyy'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" (click)="activeModal.dismiss()" class="btn btn-default round-0 mr-2"><i
        class="fas fa-close"></i>Đóng</button>
</div>
