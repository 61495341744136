import {NgxSpinnerService} from 'ngx-spinner';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import {ModuleModel} from '../../../shared/model/module.model';
import CommonUtil from '../../../shared/utils/common-util';
import {TopicService} from '../../../shared/services/topic.service';


@Component({
  selector: 'app-eu-page-content',
  templateUrl: './eu-page-content.component.html',
  styleUrls: ['./eu-page-content.component.css']
})


export class EuPageContentComponent implements OnInit, OnChanges {

  @Input() module: ModuleModel;
  @Input() courseId: number;
  @Input() section: number;

  courseModule: ModuleInfo = {
    name: '',
    course: this.courseId,
    section: this.section,
    introeditor: null,
    modulename: 'page'
  };

  teachers = '';

  constructor(
    private topicService: TopicService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.module) {
      // Cap nhat du lieu
      this.spinner.show();
      this.topicService.getModuleDetail(this.module.id).subscribe(res => {
        this.courseModule = res.body;
        this.teachers = res.body.teachers.length > 0 ? CommonUtil.convertTeacherName(res.body.teachers) : 'Chưa có giảng viên được gán';
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        // this.toastrService.handlerError(err);
      });
    }
  }
}
