<section class="content">
  <div class="container-fluid">
    <!--phan danh sach khoa hoc-->
    <div class="row">
      <mat-drawer-container autosize class="example-container">
        <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
          <div class="card card-default">
            <div class="card-body">
              <div class="row mb-2">

                <!--Search -->
                <div class="col-7">
                  <input type="search" class="form-control form-control-sm mr-2" (change)="onSearchTopic($event.target.value)" placeholder="Tìm chủ đề">
                </div>
                <div class="col-5" *ngIf="!courseDetail.readonly && !courseDetail?.coursehasstudent">
                  <button type="button" data-toggle="modal" data-target="#createTopic"
                          class="btn btn-primary rounded-0 float-right"
                          (click)="createTopic()">
                    <i class="fas fa-plus mr-2"></i>Tạo chủ đề
                  </button>
                </div>
                <!--end-->
              </div>
              <div class="mailbox-controls" *ngIf="!courseDetail.readonly">
                <div class="btn-group">
                  <button type="button" class="btn btn-default btn-sm">
                    <mat-icon matListAvatar class="mt-1">
                      <mat-checkbox [disabled]="courseDetail.published === 1 || courseDetail?.coursehasstudent" [(ngModel)]="isCheckedAll" (change)="selectAllTopic($event.checked)"
                                    class="example-margin"></mat-checkbox>
                    </mat-icon>
                  </button>
                  <button type="button" [disabled]="topicIds.length === 0 || courseDetail.published === 1 || courseDetail?.coursehasstudent"
                          (click)="deleteMultiTopics()" class="btn btn-default btn-sm"><i
                    class="far fa-trash-alt font-size-14"></i>
                  </button>
                </div>
                <div class="float-right mt-2">{{totalTopic}} <small>Chủ đề</small></div>
              </div>
              <div class="panel-group" role="tablist" aria-multiselectable="true" *ngIf="listTopicId">
                <mat-accordion [multi]="true">
                  <div *ngFor="let item of topicContent">
                    <mat-expansion-panel [expanded]="true"
                                         (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false">
                      <mat-expansion-panel-header (click)="selectAdd(item)" [collapsedHeight]="'60px'">
                        <mat-panel-title class="text-over">
                          <div class="form-inline">
                            <mat-icon *ngIf="!courseDetail.readonly && !courseDetail?.coursehasstudent" matListAvatar class="mt-1">
                              <mat-checkbox (click)="$event.stopPropagation();" (change)="selectTopic($event.checked,item)" [disabled]="courseDetail.published === 1"
                                            [checked]="item.checked" class="example-margin"></mat-checkbox>
                            </mat-icon>
                            <div class="text-over">
                              <span class="topic"> {{item.name}} </span>
                              <span class="teacher"></span>
                            </div>
                          </div>
                        </mat-panel-title>
                        <button *ngIf="!courseDetail.readonly && courseDetail.published !== 1" (click)="$event.stopPropagation();" class="btn" mat-button [matMenuTriggerFor]="menu"
                                mat-icon-button aria-label="">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button class="btn" mat-menu-item (click)="editTopic(item)"><i
                            class="fas fa-pencil-alt mr-2"></i> Sửa
                          </button>
                          <button class="btn" *ngIf="!courseDetail?.coursehasstudent" mat-menu-item (click)="deleteTopic(item)"><i
                            class="fas fa-trash mr-2"></i>Xóa
                          </button>
                        </mat-menu>
                      </mat-expansion-panel-header>
                      <div id="{{item.id}}"
                           cdkDropList
                           [cdkDropListData]="item.modules"
                           [cdkDropListConnectedTo]="listTopicId"
                           cdkDropListOrientation="vertical"
                           class="drop-drag-list"
                           (cdkDropListDropped)="drop($event)">
                        <div *ngFor=" let content of item.modules" cdkDrag class="drop-drag-box">
                          <mat-list>
                            <mat-list-item (click)="showTab(content, null)" class="mat-list-button" [ngClass]="{'bd-highlight': content.selected}">
                              <h4 mat-line><b> {{content.name}}</b></h4>
                              <small mat-line><span
                                *ngIf="content!==null">Loại bài giảng: {{content.mimetype}} </span></small>
                              <button mat-icon-button class="btn" mat-button *ngIf="!courseDetail.readonly && !courseDetail?.coursehasstudent" [matMenuTriggerFor]="menu_sub"
                                      mat-icon-button aria-label="">
                                <i class="fas fa-ellipsis-v"></i>
                              </button>
                              <mat-menu #menu_sub="matMenu">
                                <button class="btn" mat-menu-item (click)="deletemodule(content.id)"><i
                                  class="fas fa-trash mr-2"></i>Xóa
                                </button>
                              </mat-menu>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                          </mat-list>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </div>
                </mat-accordion>
              </div>
            </div>
          </div>
        </mat-drawer>
        <div class="example-sidenav-content">
          <div class="card card-default">
            <div class="card-body content-of-topic mt-2">
              <div class="row">
                <div class="col-8">
                  <h6 class="topic mb-2">{{nameSection}}</h6>
                  <div class="form-inline">
                    <div class="btn-group">
                      <button class="btn btn-default btn-flat btn-md mr-2" type="button" mat-button
                      (click)="drawer.toggle()"><i
                class="fas fa-bars"></i>
              </button>
              <button type="button" (click)="previousModule()" class="btn btn-default btn-lg"><i class="fas fa-chevron-left"></i></button>
              <button type="button" (click)="nextModule()" class="btn btn-default btn-lg"><i class="fas fa-chevron-right"></i></button>

                    </div>
                    <p *ngIf="createduser" class="teacher mb-0"><strong>Giảng viên:</strong> {{createduser}}</p>
                    <p *ngIf="createdat" class="teacher ml-5 mb-0"><strong>Ngày
                      tạo:</strong> {{getDateFromUnix(createdat) | date:'dd/MM/yyyy'}}</p></div>
                </div>
                <!-- <div class="col-2">
                  <div class="float-right">
                    <div class="btn-group">
                      <button type="button" [disabled]="!selectedInfo.currentModuleOrder" (click)="previousModule()" class="btn btn-default btn-lg"><i class="fas fa-chevron-left"></i></button>
                      <button type="button" [disabled]="!selectedInfo.currentModuleOrder" (click)="nextModule()" class="btn btn-default btn-lg"><i class="fas fa-chevron-right"></i></button>
                    </div>
                  </div>
                </div> -->
                <div class="col-4" *ngIf="!courseDetail.readonly && !courseDetail?.coursehasstudent">
                  <div class="float-right">
                    <div class="btn-group">
                      <button type="button" matTooltip="Nội dung" matTooltipClass="tooltip-red"
                              [disabled]="!topicId || courseDetail.published === 1"
                              (click)="showTab(null, 'page')" class="btn btn-default btn-lg"><i class="fas fa-pen"></i>
                      </button>
                      <button type="button" matTooltip="Tài liệu/Video" matTooltipClass="tooltip-red"
                              [disabled]="!topicId || courseDetail.published === 1"
                              (click)="showTab(null, 'resource')" class="btn btn-default btn-lg"><i class="fas fa-file"></i>
                      </button>
                      <button type="button" matTooltip="bigbluebuttonbn" matTooltipClass="tooltip-red"
                              [disabled]="!topicId || courseDetail.published === 1"
                              (click)="showTab(null, 'bigbluebuttonbn')" class="btn btn-default btn-lg"><i class="fas fa-share-alt"></i>
                      </button>
                      <button type="button" matTooltip="scorm" matTooltipClass="tooltip-red"
                              [disabled]="!topicId || courseDetail.published === 1"
                              (click)="showTab(null, 'scorm')" class="btn btn-default btn-lg"><i class="fas fa-box"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <!-- tạo bài giảng-->
              <app-page-content
                [module]="currentModule"
                [courseId]="courseId"
                [section]="section"
                *ngIf="tabname === 'page'"
                (reLoadTopics)="onReLoadTopics($event)"
                (hasChange)="onHasChange($event)">
              </app-page-content>

              <app-resource-content
                [module]="currentModule"
                [courseId]="courseId"
                [section]="section"
                *ngIf="tabname === 'resource'"
                (reLoadTopics)="onReLoadTopics($event)"
                (hasChange)="onHasChange($event)">
              </app-resource-content>

              <app-bigbluebutton-content
                [courseId]="courseId"
                [moduleInput]="currentModule"
                [section]="section"
                (hasChange)="onHasChange($event)"
                (reLoadTopics)="onReLoadTopics($event)"
                [courseStartDate]="courseDetail.startdate"
                [courseEndDate]="courseDetail.enddate"
                *ngIf="tabname === 'bigbluebuttonbn'">
              </app-bigbluebutton-content>

              <app-scorm-content
                [module]="currentModule"
                [courseId]="courseId"
                [section]="section"
                (hasChange)="onHasChange($event)"
                (reLoadTopics)="onReLoadTopics($event)"
                *ngIf="tabname === 'scorm'">
              </app-scorm-content>
              <!--kết thúc bài giảng-->

            </div>
          </div>
        </div>
      </mat-drawer-container>
      <!--end session-->
    </div>
  </div>
</section>
