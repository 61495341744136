<div class="container-fluid" *ngIf="!showCreateSingleSurvey">

  <div class="row">
    <div class="card card-default">
      <div class="card-body">
        <div class="row">
          <div class="col-5">
            <div class="form-inline">
              <button type="button" class="btn  btn-primary btn-flat float-left m-2" (click)="showCreateForm()">
                <i class="fas fa-plus mr-2"></i><a class="text-white">Tạo khảo sát</a>
              </button>
              <button type="button" class="btn btn-default btn-flat mr-1" matTooltip="Xuất File Excel" style="display: none;"
                      matTooltipClass="tooltip-red"><i class="fas fa-file-excel  mr-1"></i></button>
              <button type="button" [disabled]="surveyIdsChecked?.length === 0" class="btn btn-default btn-flat mr-1" matTooltip="Xóa"
                      (click)="onDeleteMultipleSurvey()" matTooltipClass="tooltip-red"><i class="fas fa-trash font-size-18"></i>
              </button>
              <span>Số khảo sát đã chọn: {{surveyIdsChecked?.length}}</span>
            </div>
          </div>
          <div class="col-7">
            <form class="form-inline float-right">
              <div class="form-group m-2">
                <label class="mr-2">Tìm kiếm</label>
                <input type="search" class="form-control mr-2" placeholder="Từ khóa..." name="search" [(ngModel)]="searchSurvey.search"
                    (change)="searchSurveySingle(true)">
              </div>
              <div class="card-tools float-right">
                <a class="btn btn-block btn-default btn-flat"
                   role="button" (click)="openSingleSurveySearchPopup()">
                  <i class="fa fa-search"></i> Tìm kiếm nâng cao
                </a>
              </div>
            </form>
          </div>
              <div class="col-12 table-responsive">
                <table class="table table-hover" matSort (matSortChange)="sortData($event)">
                  <thead>
                  <tr>
                    <th>
                      <mat-icon class="mt-1">
                        <mat-checkbox class="example-margin" [(ngModel)]="completedAll" [disabled]="surveyIdsCanCheck === 0" (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                      </mat-icon>
                    </th>
                    <th>STT</th>
                    <th mat-sort-header="name">Tên khảo sát </th>
                    <th mat-sort-header="publishdate">Ngày bắt đầu </th>
                    <th mat-sort-header="enddate">Ngày kết thúc </th>
                    <th mat-sort-header="refdepartmentid">Đơn vị chủ trì</th>
                    <th mat-sort-header="isactive">Trạng thái </th>
                    <th>Thao tác</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let surveySingle of surveySingleList; let i = index;">

                    <td>
                      <mat-icon class="mt-1">
                        <mat-checkbox [disabled]="surveySingle.published === 1" [(ngModel)]="surveySingle.selected" (change)="oncheckboxItem(surveySingle.questionnaireid, $event.checked)" class="example-margin"></mat-checkbox>
                      </mat-icon>
                    </td>

                    <td>{{(searchSurvey.pageIndex-1) * searchSurvey.pageSize + (i + 1)}}</td>
                    <td><a
                      [routerLink]="['/admin/survey/detail', surveySingle.course, 0]" (click)="goToDetail()">{{surveySingle.name}}</a>
                    </td>

                    <td>{{surveySingle.startdate | date:'dd/MM/yyyy'}}</td>
                    <td>{{surveySingle.enddate | date: 'dd/MM/yyyy' }}</td>
                    <td><span matTooltip="{{surveySingle.departmentname}}" matTooltipClass="tooltip-inner">{{surveySingle.departmentname?.length <= 10 ? surveySingle.departmentname : (surveySingle.departmentname | slice:0:15) + '...' }}</span></td>
                    <td>
                      <span class="badge badge-secondary" *ngIf="surveySingle.published == 0">chưa kích hoạt</span>
                      <span class="badge badge-success" *ngIf="surveySingle.published != 0">đã kích hoạt</span>
                    </td>
                    <td>
                      <button *ngIf="surveySingle.published == 0" type="button" matTooltip="Kích hoạt"
                              matTooltipClass="tooltip-red"
                              class="btn btn-default btn-sm mr-1"
                              (click)="publishSurvey(surveySingle.questionnaireid, surveySingle.published, surveySingle.enddate, surveySingle.startdate)">
                        <i class="fas fa-check"></i>
                      </button>
                      <button *ngIf="surveySingle.published != 0" type="button" matTooltip="Dừng khảo sát"
                              matTooltipClass="tooltip-red"
                              class="btn btn-default btn-sm mr-1"
                              (click)="publishSurvey(surveySingle.questionnaireid, surveySingle.published, surveySingle.enddate, surveySingle.startdate)">
                        <i class="fas fa-check"></i>
                      </button>

                      <button type="button" matTooltip="Chỉnh sửa" matTooltipClass="tooltip-red"
                              class="btn btn-default btn-sm mr-1"
                              (click)="editSurvey(surveySingle)">
                        <i class="fas fa-edit"></i>
                      </button>

                      <button type="button" matTooltip="Xóa" matTooltipClass="tooltip-red"
                              (click)="onDeleteSurvey(surveySingle)"
                              class="btn btn-default btn-sm mr-1">
                        <i class="fas fa-trash"></i>
                      </button>
                      <button class="btn btn-default btn-sm" (click)="onExport(surveySingle.questionnaireid)"><i class="fas fa-file-excel"></i></button>
                    </td>
                  </tr>
                  <tr class="text-center" *ngIf="surveySingleList.length === 0"><td colspan="8">Không có khảo sát nào</td></tr>
                  </tbody>
                </table>
              </div>

              <div class="card-footer text-center">
                <div class="row">
                  <mat-paginator showFirstLastButtons
                    [length]="totalRecord"
                    [pageSize]="searchSurvey?.pageSize"
                    (page)="pageEvent = $event;onChangePage($event)"
                    [hidePageSize]="false"
                    [pageSizeOptions]="pageSizeOptions"
                    [pageIndex]="searchSurvey?.pageIndex - 1">
                  </mat-paginator>
                </div>
              </div>
<!--            </table>-->
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  -->
<!--</div>-->
  <app-create-survey *ngIf="showCreateSingleSurvey" (showCreateSurvey)="hideCreateFormSurvey()"
                     [idSurveyUpdate]="idSurveyUpdate" [checkChooseTemplate]="false"></app-create-survey>



