import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-survey-single-detail',
  templateUrl: './survey-single-detail.component.html',
  styleUrls: ['./survey-single-detail.component.css']
})
export class SurveySingleDetailComponent implements OnInit {

  surveyId: number;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.surveyId = Number(this.route.snapshot.paramMap.get('id'));
  }

}
