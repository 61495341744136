import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ModuleInfo} from '../model/moduleinfo.model';
import {AbstractService} from './abstract.service';
import CommonUtil from '../utils/common-util';
import {MOODLE_INFO} from '../constants/base.constant';


type EntityArrayResponse = HttpResponse<any>;
type EntityResponseType = HttpResponse<ModuleInfo>;
type EntityArrayResponseType = HttpResponse<ModuleInfo[]>;

@Injectable({
  providedIn: 'root'
})
export class QuizContentService {

  constructor(private abstractService: AbstractService) {

  }

  createQuizContent(moduleinfo: ModuleInfo): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_create_module');
    delete moduleinfo.id;
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    // console.log(data);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  addQuestion(quizid: number, questionid): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_quiz_add_question');
    const param = {
      quizid: quizid,
      questionids: questionid.toString()
    };
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updateQuizContent(moduleinfo): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_topic_update_module');
    CommonUtil.appendDataToFormData(data, 'moduleinfo', moduleinfo);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }


  listQuiz(courseid, sectionid, keyword?: string): Observable<EntityArrayResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_admin_list_course_quiz');
    data.append('courseid', courseid);
    data.append('sectionid', sectionid);
    if (keyword !== undefined && keyword !== null) data.append('keyword', keyword);
    const returndata = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityArrayResponse) => res));
    return returndata;
  }

  listQuestion(attemptid): Observable<EntityArrayResponse> {
    const data = this.abstractService.getCommonInput('mobifone_quiz_list_question_attempt');
    data.append('attemptid', attemptid);
    CommonUtil.appendDataToFormData(data, 'params', data);
    return this.abstractService.post<ModuleInfo>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }
  quizPreview(params: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_quiz_preview_quiz_enduser');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: HttpResponse<any>) => res));
  }

  addRandomQuestion(params: any){
    const data = this.abstractService.getCommonInput('mobifone_quiz_add_random_questions');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: HttpResponse<any>) => res));
  }


}
