<div class="modal-header">
  <h5 class="modal-title">Tìm kiếm nâng cao</h5>
  <button type="button" class="btn btn-default" data-card-widget="collapse"
          (click)="activeModal.dismiss('close')"><i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>Thời gian đào tạo:</label>
        <div class="row date-require">
          <div class="col-6">
            <mat-form-field color="blue" class="mr-3 w-100 mb-0 course-input">
              <mat-label>Từ ngày</mat-label>
              <input matInput [matDatepicker]="startdateModel" [(ngModel)]="startDate">
              <mat-datepicker-toggle matSuffix [for]="startdateModel"></mat-datepicker-toggle>
              <mat-datepicker #startdateModel></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field color="blue" class="course-input w-100 mb-0">
              <mat-label>Đến ngày</mat-label>
              <input matInput [matDatepicker]="enddateModel" [(ngModel)]="endDate" [min]="startDate">
              <mat-datepicker-toggle matSuffix [for]="enddateModel"></mat-datepicker-toggle>
              <mat-datepicker #enddateModel color="primary"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Nhóm chức danh</label>
        <ng-multiselect-dropdown
          [placeholder]="'Chọn chức danh'"
          [data]="positionList"
          [(ngModel)]="positionData"
          [settings]="dropdownPositionSettings"
          (onDeSelect)="onDeSelectPosition($event)"
          (onDeSelectAll)="onDeSelectAllPosition()"
        >
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Trạng thái</label>
        <select name="statusSearch" class="form-control select2" style="width: 100%;" [(ngModel)]="statusSearch">
          <option value="null" selected>-Chọn trạng thái-</option>
          <option *ngFor="let item of statusSearchData" [value]="item.id">{{item.name}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Lựa chọn đơn vị</label>
        <!-- <select name="departmentid" class="form-control select2" style="width: 100%;" [(ngModel)]="departmentId">
          <option value="null">{{ 'user.choose_department' | translate }}</option>
          <option *ngFor="let item of departmentMap | keyvalue" [value]="item.key">{{item.value}}</option>
        </select> -->
        <ng-select class="form-control select2 custom-select-box"
                    [placeholder]="'user.choose_department' | translate"
                    [items]="departmentMap | keyvalue"
                    bindLabel="value" bindValue="key"
                    [(ngModel)]="departmentId"
                    [editableSearchTerm]="true">
          <ng-template ng-option-tmp let-item="item">
            <div [title]="item.value">{{item.value}}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="resetSearch()">Đặt lại</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('close')">Đóng</button>
  <button type="button" class="btn btn-primary" (click)="onSearchCourses()">Tìm kiếm</button>
</div>
