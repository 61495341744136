import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  course:string = "course";
  courseLable:string = "Số khóa học";
  choosesCourseBtn:string = "Lựa chọn khóa học"
  titleTable:any = {
    courseLabel : 'Số khóa học',
    studentLabel: 'Số học viên theo học'
  }
  constructor() { }

  ngOnInit(): void {
  }

}
