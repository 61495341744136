import {MOODLE_INFO, USER_ROLE} from './../../../shared/constants/base.constant';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import { USER_INFO } from 'src/app/shared/constants/base.constant';
import { UserInfo } from 'src/app/shared/model/user-info.model';
import {LoginService} from '../../../shared/services/login.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../shared/services/user.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-eutopbar',
  templateUrl: './eutopbar.component.html',
  styleUrls: ['./eutopbar.component.css']
})
export class EutopbarComponent implements OnInit, OnDestroy {

  userInfo: UserInfo;
  isStudent = false;
  isTeacher = false;
  isShowUserMenu = false;
  USER_INFO_KEY = USER_INFO.INFO;
  constructor(
    public $localStorage: LocalStorageService,
    private loginService: LoginService,
    private modalService: NgbModal,
    public userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userInfo = this.$localStorage.retrieve(USER_INFO.INFO);

    // Lang nghe cac su kien thay doi USER_INFO tu localstorage
    this.$localStorage.observe(this.USER_INFO_KEY).subscribe(
      uInfo => {
        this.userInfo = uInfo;
      }
    );

    const roles = this.$localStorage.retrieve(USER_INFO.ROLES);
    if (roles) {
      for (const role of roles) {
        if (role.shortname === USER_ROLE.STUDENT) {
          this.isStudent = true;
        }
        if (role.shortname === USER_ROLE.TEACHER) {
          this.isTeacher = true;
        }
      }
    }
  }

  logout(){
    this.loginService.logout();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  showUserMenu(){
    this.isShowUserMenu = !this.isShowUserMenu;
  }


}
