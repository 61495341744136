import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ShowContentService} from '../../../../shared/services/show-content.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-show-quiz',
  templateUrl: './show-quiz.component.html',
  styleUrls: ['./show-quiz.component.css']
})
export class ShowQuizComponent implements OnInit, OnChanges {
  @Input() id;
  quizcontent: string = '';

  constructor(private showService: ShowContentService,
  ) {
  }

  courseModule: {
    id: number,
    course: number,
    module: number,
    name: string,
    modname: string,
    intro?: string,
    instance?: number,
    content?: string,
    contextid?: number,
    timelimit: number,
    timeclose: number,
    timeopen: number,
    grade: number,
    attempts: number

  };

  ngOnInit(): void {
  }

  showTab(event, quizcontent) {
    event.stopPropagation();
    this.quizcontent = quizcontent;
  }

  edit(event) {
    event.stopPropagation();

  }

  ngOnChanges(): void {
    this.getModuleDetail(this.id);
  }

  getModuleDetail(id) {
    this.showService.getFilebyId(id).subscribe((data) => {
      this.courseModule = data.body;
    });
  }

  delete(event) {
    event.stopPropagation();
  }
  panelOpenState = true;

  accordions = [
    {
      title: '1', 
      description: 'Nội dung câu hỏi 1',
      type: 'multichoice'      
    },
    {
      title: '2', 
      description: 'Nội dung câu hỏi 2',
      type: 'truefalse'
    },
    {
      title: '3', 
      description: 'Nội dung câu hỏi 3',
     
    },
    {
      title: '4', 
      description: 'Nội dung câu hỏi 4',     
  
}
  ]
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.accordions, event.previousIndex, event.currentIndex);
  }
}
