import CommonUtil from 'src/app/shared/utils/common-util';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AdminComponent} from './admin.component';
import {CourseComponent} from './course/course.component';
import {CourseCategoryComponent} from './course-category/course-category.component';
import {CourseCategoryEditComponent} from './course-category/course-category-edit/course-category-edit.component';
import {AppheaderComponent} from './core/appheader/appheader.component';
import {CourseContentComponent} from './course-content/course-content.component';

import {AppfooterComponent} from './core/appfooter/appfooter.component';
import {AppmenuComponent} from './core/appmenu/appmenu.component';
import {AdminRoutingModule} from './admin-routing.module';
import {DashboardComponent} from './dashboard/dashboard.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {CourseDetailComponent} from './course/course-detail/course-detail.component';
import {DepartmentDetailComponent} from './department/department-detail/department-detail.component';
import {DepartmentComponent} from './department/department.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppbreadcrumbComponent} from './core/appbreadcrumb/appbreadcrumb.component';
import {UserComponent} from './user/user.component';
import {UserEditComponent} from './user/user-edit/user-edit.component';
import {TreeViewModule} from '@syncfusion/ej2-angular-navigations';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgxSpinnerModule} from 'ngx-spinner';
import {PageContentComponent} from './course-content/page-content/page-content.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {ResourceContentComponent} from './course-content/resource-content/resource-content.component';
import {PositionEditComponent} from './position/position-edit/position-edit.component';
import {QuizContentComponent} from './course-content/quiz-content/quiz-content.component';
import {ShowQuizComponent} from './course-content/quiz-content/show-quiz/show-quiz.component';
import {QuizCreateComponent} from './course-content/quiz-content/quiz-create/quiz-create.component';
import {MultichoiceComponent} from './course-content/quiz-content/multichoice/multichoice.component';
import {TruefalseComponent} from './course-content/quiz-content/truefalse/truefalse.component';
import {MatchingquizComponent} from './course-content/quiz-content/matchingquiz/matchingquiz.component';
import {ShortQuizComponent} from './course-content/quiz-content/short-quiz/short-quiz.component';
import {NumbericalQuizComponent} from './course-content/quiz-content/numberical-quiz/numberical-quiz.component';
import {AddUserComponent} from './course-content/add-user/add-user.component';
import {MatTabsModule} from '@angular/material/tabs';
import {TopicDetailComponent} from './course-content/topic-detail/topic-detail.component';
import {AddTeacherComponent} from './course-content/add-teacher/add-teacher.component';
import {CourseCreateComponent} from './course/course-create/course-create.component';
import {SelectCourseComponent} from './position/position-course/select-course.component';
import {PositionComponent} from './position/position.component';
import {ExamComponent} from './exam/exam.component';
import {CreateExamComponent} from './exam/create-exam/create-exam.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {SurveyComponent} from './survey/survey.component';
import {CreateSurveyComponent} from './survey/create-survey/create-survey.component';
import {MatCardModule} from '@angular/material/card';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {QuestionBankComponent} from './question-bank/question-bank.component';
import {QuestionCreateComponent} from './question-bank/question-create/question-create.component';
import {MatSortModule} from '@angular/material/sort';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MultichoiceSurveyComponent} from './survey/multichoice-survey/multichoice-survey.component';
import {TruefalseSurveyComponent} from './survey/truefalse-survey/truefalse-survey.component';
import {AddSingleUserComponent} from './course-content/add-user/add-single-user/add-single-user.component';
import {SurveyDetailComponent} from './survey/survey-detail/survey-detail.component';
import {ChartsModule} from 'ng2-charts';
import {TextQuestionSurveyComponent} from './survey/text-question-survey/text-question-survey.component';
import {SelectboxSurveyComponent} from './survey/selectbox-survey/selectbox-survey.component';
import {RateSurveyComponent} from './survey/rate-survey/rate-survey.component';
import {ChosenLibComponent} from './course-content/quiz-content/chosen-lib/chosen-lib.component';
import {ListExamComponent} from './exam/list-exam/list-exam.component';
import {CertificateComponent} from './course-content/certificate/certificate.component';
import {ExamDetailComponent} from './exam/exam-detail/exam-detail.component';
import {SurveyGeneralComponent} from './survey/survey-general/survey-general.component';
import {SurveySingleComponent} from './survey/survey-single/survey-single.component';
import {ImportUserComponent} from './user/import-user/import-user.component';
import {BadgeComponent} from './badge/badge.component';
import {BadgeCreateComponent} from './badge/badge-create/badge-create.component';
import {BadgeDetailsComponent} from './badge/badge-details/badge-details.component';
import {BadgeInfoComponent} from './badge/badge-info/badge-info.component';
import {BadgeCourseComponent} from './badge/badge-course/badge-course.component';
import {BadgeStudentComponent} from './badge/badge-student/badge-student.component';
import {QuestionDetailComponent} from './question-bank/question-detail/question-detail.component';
import {BadgeExamComponent} from './badge/badge-exam/badge-exam.component';
import {NotificationComponent} from './notification/notification.component';
import {RoleComponent} from './role/role.component';
import {RoleCreateComponent} from './role/role-create/role-create.component';
import {ReportComponent} from './report/report.component';
import {SelectItemComponent} from './badge/select-item.component';
import {ReportCourseComponent} from './report/report-course/report-course.component';
import {ReportGeneralComponent} from './report/report-general/report-general.component';
import {CourseSearchComponent} from './course/course-search/course-search.component';
import {ExamSearchComponent} from './exam/exam-search/exam-search.component';
import {BankSearchComponent} from './question-bank/bank-search/bank-search.component';
import {QuizSearchComponent} from './course-content/quiz-content/quiz-search/quiz-search.component';
import {ListSearchComponent} from './exam/list-exam/list-search/list-search.component';
import {EditQuestionComponent} from './survey/edit-question/edit-question.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {DeleteBadgePopupComponent} from './badge/delete-badge-popup/delete-badge-popup.component';
import {ForgetPasswordComponent} from './forget-password/forget-password.component';
import {ReportStudentComponent} from './report/report-student/report-student.component';
import {CreateNotificationComponent} from './notification/create-notification/create-notification.component';
import {NotificationHistoryComponent} from './notification/notification-history/notification-history.component';
import {NotificationHistoryDetailComponent} from './notification/notification-history-detail/notification-history-detail.component';
import {NotificationInfoComponent} from './notification/notification-info/notification-info.component';
import {NotificationTemplateComponent} from './notification/notification-template/notification-template.component';
import {ReportExamComponent} from './report/report-exam/report-exam.component';
import {CourseSelectComponent} from './course/course-select/course-select.component';
import {SurveySingleCreateComponent} from './survey/survey-single-create/survey-single-create.component';
import {SurveySingleDetailComponent} from './survey/survey-single-detail/survey-single-detail.component';
import {ResultExamComponent} from './report/result-exam/result-exam.component';
import {ResultDetailExamComponent} from './report/result-exam/result-detail-exam/result-detail-exam.component';
import {ResultExamUserComponent} from './report/result-exam/result-exam-user/result-exam-user.component';
import {SingleSurveySearchComponent} from './survey/single-survey-search/single-survey-search.component';
import {StudentDetailsComponent} from './report/report-student/student-details/student-details.component';
import {StudentLearningHistoryComponent} from './report/report-student/student-learning-history/student-learning-history.component';
import {BigbluebuttonContentComponent} from './course-content/bigbluebutton-content/bigbluebutton-content.component';
import {ScormContentComponent} from './course-content/scorm-content/scorm-content.component';
import { AddSingleMemberComponent } from './notification/create-notification/add-single-member/add-single-member.component';
import { MultichoiceSetComponent } from './course-content/quiz-content/multichoiceset/multichoiceset.component';
import { ChosenRanComponent } from './course-content/quiz-content/chosen-ran/chosen-ran.component';
import {SafePipe} from '../shared/utils/safe-pipe';
import { RandomQuestionComponent } from './course-content/quiz-content/random-question/random-question.component';
import { ResultExamDTNComponent } from './report/result-exam-dtn/result-exam.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    TranslateModule,
    NgbModule,
    TreeViewModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTooltipModule,
    MatToolbarModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatRadioModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    CKEditorModule,
    MatTabsModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    DragDropModule,
    ChartsModule,
    InfiniteScrollModule
  ],
  declarations: [
    AdminComponent,
    CourseComponent,
    CourseCategoryComponent,
    CourseCategoryEditComponent,
    AppheaderComponent,
    AppfooterComponent,
    AppmenuComponent,
    DashboardComponent,
    CourseDetailComponent,
    CourseCreateComponent,
    DepartmentDetailComponent,
    DepartmentComponent,
    UserComponent,
    UserEditComponent,
    AppbreadcrumbComponent,
    CourseContentComponent,
    PageContentComponent,
    ResourceContentComponent,
    PositionComponent,
    PositionEditComponent,
    QuizContentComponent,
    ShowQuizComponent,
    QuizCreateComponent,
    MultichoiceComponent,
    MultichoiceSetComponent,
    TruefalseComponent,
    MatchingquizComponent,
    ShortQuizComponent,
    NumbericalQuizComponent,
    AddUserComponent,
    TopicDetailComponent,
    SelectCourseComponent,
    SelectItemComponent,
    AddTeacherComponent,
    ExamComponent,
    CreateExamComponent,
    SurveyComponent,
    CreateSurveyComponent,
    QuestionBankComponent,
    QuestionCreateComponent,
    MultichoiceSurveyComponent,
    TruefalseSurveyComponent,
    AddSingleUserComponent,
    SurveyDetailComponent,
    TextQuestionSurveyComponent,
    SelectboxSurveyComponent,
    RateSurveyComponent,
    ChosenLibComponent,
    ListExamComponent,
    CertificateComponent,
    ExamDetailComponent,
    SurveyGeneralComponent,
    SurveySingleComponent,
    ImportUserComponent,
    BadgeComponent,
    BadgeCreateComponent,
    BadgeDetailsComponent,
    BadgeInfoComponent,
    BadgeCourseComponent,
    BadgeStudentComponent,
    DeleteBadgePopupComponent,
    QuestionDetailComponent,
    BadgeExamComponent,
    NotificationComponent,
    RoleComponent,
    RoleCreateComponent,
    ReportComponent,
    ReportCourseComponent,
    ReportGeneralComponent,
    ReportStudentComponent,
    ReportExamComponent,
    CourseSearchComponent,
    ExamSearchComponent,
    BankSearchComponent,
    QuizSearchComponent,
    ListSearchComponent,
    EditQuestionComponent,
    NotificationTemplateComponent,
    NotificationHistoryComponent,
    NotificationHistoryDetailComponent,
    NotificationInfoComponent,
    CreateNotificationComponent,
    ForgetPasswordComponent,
    CourseSelectComponent,
    SurveySingleCreateComponent,
    SurveySingleDetailComponent,
    ResultExamComponent,
    ResultDetailExamComponent,
    ResultExamUserComponent,
    SingleSurveySearchComponent,
    StudentDetailsComponent,
    StudentLearningHistoryComponent,
    BigbluebuttonContentComponent,
    ScormContentComponent,
    AddSingleMemberComponent,
    ChosenRanComponent,
    SafePipe,
    RandomQuestionComponent,
    ResultExamDTNComponent
  ],
  entryComponents: [
    ForgetPasswordComponent,
    CreateNotificationComponent,
    NotificationHistoryComponent,
    NotificationHistoryDetailComponent,
    NotificationInfoComponent,
    NotificationTemplateComponent,
    CourseSelectComponent
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: CommonUtil.overridePaginatorIntl() }
  ]
}
)
export class AdminModule {
}
