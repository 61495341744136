<section class="inner_page_breadcrumb">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 text-center">
          <div class="breadcrumb_content mt-2">
            <h4 class="breadcrumb_title">LỘ TRÌNH KHÓA HỌC</h4>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a routerLink="/home">Trang chủ</a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </section>

  <main>
    <!---Phan noi dung bai hoc-->
    <section>
        <div class="container">
          <!-- <div class="row">
            <div class="col-6">
              <h6>{{listPosition.length < 10 ? '0' + listPosition.length : listPosition.length}} Lộ trình</h6>
            </div>
            <div class="col-6">
              <div class="d-flex align-items-center float-right">
                <div class="col-auto">
                  <select class="form-control" >
                    <option value="0"> Toàn bộ </option>
                    <option value="1"> Tham gia </option>
                    <option value="2"> Chưa tham gia </option>
                    <option value="2"> Từ mới đến cũ </option>
                  </select>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row mt-3">
              <!-- <div class="col-6 p-3 d-flex align-items-stretch" *ngFor="let position of listPosition; let i = index">
                <div class="row box-object-route shadow">
                  <div class="col-3">
                    <img  src="{{convertLinkimg(position.filename,position.filearea,position.contextid)}}" class="img-course-route-detail " alt="">
                  </div>
                  <div class="col-6">
                      <h6 class="mb-3">Chức danh</h6>
                      <h2 class="section-title-route">{{position.name}}</h2>
                      <blockquote class="blockstyle"><p class="mb-30">Đạt được thành công không phải là tất cả. Với những người này, họ luôn muốn vươn tới những đỉnh cao mới, những mục tiêu lớn lao hơn. Chính vì vậy, nếu chỉ biết hài lòng với vị trí của mình, bạn sẽ bỏ qua cơ hội, làm cản trở việc học hỏi và phát triển của chính mình. Hãy phát triển bản thân ở nhiều lĩnh vực, không ngừng học hỏi để tạo ra những thành công lớn hơn.</p></blockquote>
                      <a  [routerLink]="['/course-route/detail', position.id, position.name]" class="btn btn-lg btn-primary rounded-pill">
                      <span class="text-white"> <i class="fas fa-anchor mr-2"></i>Khám phá </span>
                    </a>
                  </div>
                  <div class="col-3 mt-2 border-left">

                  </div>
                </div>
              </div> -->
            <div class="col-6" *ngFor="let position of listPosition; let i = index">
              <div class="card-box mb-3">
                <div  class="row align-items-center">
                  <div  class="col-sm-9">
                    <div class="media">
                      <div><img class="img-thumbail mr-2" [src]="convertLinkimg(position.filename,position.filearea,position.contextid)" style="width:120px; height:120px; border-radius:.85rem;"></div>
                      <div class="media-body">
                        <h6 class="mt-3 mb-2">{{position.name}}</h6>
                        <!-- <p class="mb-1"><i class="icofont-book-alt mr-2"></i>
                          <span  style="font-size: 10pt;">Khóa học: {{position.totalcourse}} </span></p>
                        <p class="mb-1"><i class="icofont-checked mr-2"></i>
                          <span  style="font-size: 10pt;">Kỳ thi: {{position.totalexam}} </span></p>                         -->
                          <button type="button" [routerLink]="['/course-route/detail', position.id]" [state]="{ positionName: position.name }" class="btn btn-sm btn-outline-primary mt-3">{{'common.join' | translate}}</button>

                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3 border-left">
                      <div class="reptro-event-item ">
                        <div class="reptro-event-date">
                          <span class="reptro-event-day text-primary"> {{position.totalcourse}}</span>
                          <span class="reptro-event-month mt-2">Khóa học</span>

                          </div>
                          <div class="reptro-event-date mt-2">
                            <span class="reptro-event-day text-danger">{{position.totalexam}}</span>
                            <span class="reptro-event-month mt-2">Kỳ thi</span>

                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
               </div>
            <ng-template #noPosition>
              <!-- <div class="text-center">{{'common.no_record_enduser' | translate}}</div> -->
              <div class="row" style="height: 45vh;">
                <div class="col-12 text-center">
                  <img src="/assets/content-management.svg" alt="" style="height: 25vh;">
                 <p class="mt-3">Bạn chưa có lộ trình khóa học nào cần hoàn thành. Vui lòng quay lại sau !</p>
                </div>
              </div>
            </ng-template>
        </div>

    </section>
  </main>
  <!-- End #main -->
