<div class="content-wrapper">
  <!-- Main content -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>QUẢN LÝ LỘ TRÌNH HỌC TẬP CHỨC DANH</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý lộ trình học tập chức danh</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <section class="content">
    <div class="container-fluid">
      <mat-drawer-container class="example-container">
        <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
          <div class="card card-default">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-auto">
                  <form class="form-inline float-right" role="form" [formGroup]="searchPositionForm">
                    <input formControlName="keyword" type="search" class="form-control form-control-sm mr-2" placeholder="Tim theo tên Chức danh" (change)="onSearch()">
                  </form>
                </div>
                <div class="col-auto">
                  <button type="button" *ngIf="isAdministrator()"
                          (click)="onCreatedPosition()" data-toggle="modal" data-target="#createTopic"
                          class="btn btn-primary border-0 float-right btn-flat">
                    <i class="fas fa-plus mr-2"></i>Tạo chức danh
                  </button>
                </div>
                <!--Search -->

              </div>
              <div class="mailbox-controls">
                <div class="btn-group">
                  <button type="submit"  *ngIf="isAdministrator()"
                          class="btn btn-default btn-md">
                    <mat-icon matListAvatar class="mt-1">
                      <mat-checkbox (change)="selectAll($event.checked)" class="example-margin" [(ngModel)]="checkAll"></mat-checkbox>
                    </mat-icon>
                  </button>
                  <button type="button" *ngIf="isAdministrator()"
                          (click)="onDeleteMultiplePosition()" class="btn btn-default btn-md"><i class="far fa-trash-alt font-size-14"></i>
                  </button>
                </div>
                <div class="float-right mt-2" *ngIf="positions">{{positions.length }}<small> Chức danh</small></div>
              </div>
              <div class="panel-group">
                <mat-list *ngFor="let position of positions;">
                  <mat-list-item class="mat-list-button" [ngClass]="{'bd-highlight': position.selected}" routerLinkActive="is-active"
                                 (click)="displayDetail(position.id)">
                    <h3 mat-line>{{position.name}}</h3>
                    <mat-icon *ngIf="isAdministrator()" matListAvatar class="mt-1">
                      <mat-checkbox class="example-margin" [checked]="position.checked"
                                    (change)="manyDelete($event.checked,position)"></mat-checkbox>
                    </mat-icon>
                    <button *ngIf="isAdministrator()" mat-icon-button class="btn" mat-button [matMenuTriggerFor]="menu_sub" mat-icon-button
                            aria-label="">
                      <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #menu_sub="matMenu">
                      <button class="btn" mat-menu-item (click)="openEditPositionPopup(position); setCurrentSelected(position.id)"><i
                        class="fas fa-pencil-alt mr-2"></i> Sửa
                      </button>
                      <button class="btn" mat-menu-item (click)="onDeleteSinglePosition(position.id)"><i
                        class="fas fa-trash mr-2"></i>Xóa
                      </button>
                    </mat-menu>
                  </mat-list-item>
                  <mat-divider></mat-divider>

                </mat-list>
                <br>
                <p *ngIf="positions?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
            </div>
            </div>
          </div>
        </mat-drawer>
        <div class="example-sidenav-content">
          <!-- <button class="btn btn-default btn-md mt-2 ml-1 mb-1" type="button" mat-button (click)="drawer.toggle()"><i class="fas fa-bars"></i>
            </button> -->
          <div class="card card-default">
            <div class="card-body content-of-topic mt-2">
              <div class="row">
                <div class="col-8">
                  <h6 class="topic mb-0" *ngIf="position">{{position.name}}</h6>
                  <div class="form-inline" *ngIf="position"><p class="teacher mb-0"><strong>Người
                    tạo</strong> {{position.firstname}} {{position.lastname}}</p>
                    <p class="teacher ml-5 mb-0" *ngIf="position"><strong>Ngày
                      tạo</strong> {{getDateFromUnix(position.createdat) | date:'dd/MM/yyyy'}}</p></div>
                </div>
                <div class="col-4">
                  <!-- <div class="float-right">
                    <div class="btn-group">
                      <button type="button" matTooltip="Sửa" matTooltipClass="tooltip-red"
                              class="btn btn-default btn-lg"><i class="fas fa-pencil-alt"></i></button>
                      <button type="button" matTooltip="Xóa" matTooltipClass="tooltip-red"
                              class="btn btn-default btn-lg"><i class="fas fa-trash"></i></button>
                    </div>
                  </div> -->
                </div>
              </div>
              <hr>
              <div class="row" *ngIf="position">
                <div class="card card-default">
                  <div class="card-header pb-0">
                    <h3 class="card-title">Danh sách khóa học</h3>
                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                        class="fas fa-minus"></i></button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="col-12">
                      <div class="table-responsive overflow-auto" style="height: 35vh;">
                        <table class="table table-hover table-head-fixed">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>STT</th>
                            <th style="width:350px;">Tên khóa học</th>
                            <th>Từ ngày</th>
                            <th>Đến ngày</th>
                            <th>Danh mục</th>
                            <th>Đơn vị chủ trì</th>
<!--                            <th>Thao tác</th>-->
                          </tr>
                          </thead>
                          <tbody cdkDropList
                                 [cdkDropListData]="courses"
                                 (cdkDropListDropped)="drop($event, courses, rootCourseOrdernumber)" >
                          <tr *ngFor="let course of courses;let i=index" cdkDrag [cdkDragDisabled]="course.candragging === 0">
                            <td><i class="fas fa-bars drag-handle" ></i></td>
                            <td>{{i + 1}}</td>
                            <td>
                              <span matTooltip="{{course.fullname}}" matTooltipClass="tooltip-inner">
                                {{course.fullname?.length <= 40 ? course.fullname : (course.fullname| slice:0:40) + '...' }}
                              </span>
                            </td>
<!--                            <td *cdkDragPreview></td>-->
                            <td>{{getDateFromUnix(course.startdate) | date:'dd/MM/yyyy'}}</td>
                            <td>{{getDateFromUnix(course.enddate) | date:'dd/MM/yyyy'}}  </td>
                            <td class="test">
                              {{course.categoryname}}
                            </td>
                            <td>
                              {{course.departmentname}}
                            </td>
<!--                            <td>-->
<!--                              <button type="button" (click)="deleteCourse(course.id,course.coursetype)"-->
<!--                                      class="btn btn-default btn-sm mr-1" matTooltip="Xóa"-->
<!--                                      matTooltipClass="tooltip-red">-->
<!--                                <i class="fas fa-trash">-->
<!--                                </i>-->
<!--                              </button>-->
<!--                            </td>-->
                          </tr>
                          </tbody>
                        </table>

                        <!-- <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                          <div class="example-box" *ngFor="let movie of courses" cdkDrag>{{movie.fullname}}</div>
                        </div> -->

                      </div>
<!--                      <div class="row">-->

<!--                        <div class="col-12 text-center">-->
<!--                          <button class="btn btn-secondary btn-sm" (click)="openListCourse('course')" type="submit">-->
<!--                            <i class="nav-icon fas fa-plus mr-1"></i>Thêm khóa học-->
<!--                          </button>-->
<!--                        </div>-->
<!--                      </div>-->
                    </div>
                  </div>


                </div>
                <div *ngIf="position" class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Danh sách kỳ thi</h3>

                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                        class="fas fa-minus"></i></button>
                    </div>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body" style="display: block;">

                    <div class="table-responsive overflow-auto" style="height: 35vh;">
                      <table class="table table-hover table-head-fixed">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>STT</th>
                          <th>Tên kì thi</th>
                          <th>Từ ngày</th>
                          <th>Đến ngày</th>
                          <th>Danh mục</th>
                          <th>Đơn vị chủ trì</th>
<!--                          <th>Thao tác</th>-->
                        </tr>
                        </thead>
                        <tbody cdkDropList
                               [cdkDropListData]="coursesExam"
                               (cdkDropListDropped)="drop($event, coursesExam, rootExamOrdernumber)">
                        <tr *ngFor="let courseExam of coursesExam;let i=index" cdkDrag [cdkDragDisabled]="courseExam.candragging === 0">
                          <td><i class="fas fa-bars"></i></td>
                          <td>{{i + 1}}</td>
                          <td>{{courseExam.fullname}}</td>
                          <td>{{getDateFromUnix(courseExam.startdate) | date:'dd/MM/yyyy'}}</td>
                          <td>{{getDateFromUnix(courseExam.enddate) | date:'dd/MM/yyyy'}}</td>
                          <td class="test">
                            {{courseExam.categoryname}}
                          </td>
                          <td>
                            {{courseExam.departmentname}}
                          </td>
<!--                          <td>-->
<!--                            <button type="button" (click)="deleteCourse(courseExam.id,courseExam.coursetype)"-->
<!--                                    class="btn btn-default btn-sm mr-1" matTooltip="Xóa"-->
<!--                                    matTooltipClass="tooltip-red">-->
<!--                              <i class="fas fa-trash">-->
<!--                              </i>-->
<!--                            </button>-->
<!--                          </td>-->
                        </tr>
                        </tbody>
                      </table>
                    </div>
<!--                    <div class="row">-->
<!--                      <div class="col-12 text-center">-->
<!--                        <button class="btn btn-secondary btn-sm" (click)="openListCourse('exam')" type="submit">-->
<!--                          <i class="nav-icon fas fa-plus mr-1"></i>Thêm kỳ thi-->
<!--                        </button>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-drawer-container>


    </div><!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</div>
