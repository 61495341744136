import CommonUtil from 'src/app/shared/utils/common-util';
import { CourseCardComponent } from './course-card/course-card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {EufooterComponent} from './core/eufooter/eufooter.component';
import {EuheaderComponent} from './core/euheader/euheader.component';
import {EndUserComponent} from './end-user.component';
import {HomeComponent} from './home/home.component';
import {EndUserRoutingModule} from './end-user-routing.module';
import { EutopbarComponent } from './core/eutopbar/eutopbar.component';
import { CourseExploreComponent } from './course-explore/course-explore.component';
import { MyCourseComponent } from './my-course/my-course.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { ExamComponent } from './exam/exam.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { CourseRouteComponent } from './course-route/course-route.component';
import { CourseRouteDetailComponent } from './course-route-detail/course-route-detail.component';
import { ExamListComponent } from './exam-list/exam-list.component';
import { ExamLessonComponent } from './exam-list/exam-lesson/exam-lesson.component';
import { ExamResultComponent } from './exam-result/exam-result.component';
import { SurveyQuestionComponent } from './survey-question/survey-question.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CountdownModule } from 'ngx-countdown';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import { CertificateUserComponent } from './certificate-user/certificate-user.component';
import { DialogService } from '../shared/services/dialog.service';
import { ExamPreviewComponent } from './exam-preview/exam-preview.component';
import {EuPageContentComponent} from './course-detail/eu-page-content/eu-page-content.component';
import {EuResourceContentComponent} from './course-detail/eu-resource-content/eu-resource-content.component';
import {EuBigbluebuttonContentComponent} from './course-detail/eu-bigbluebutton-content/eu-bigbluebutton-content.component';
import {EuCourseContentComponent} from './course-detail/eu-course-content/eu-course-content.component';
import {EuScormContentComponent} from './course-detail/eu-scorm-content/eu-scorm-content.component';
import {EuCourseHistoryContentComponent} from './course-detail/eu-course-history-content/eu-course-history-content.component';
import {EuCourseExamContentComponent} from './course-detail/eu-course-exam-content/eu-course-exam-content.component';
import {EuCourseSurveyContentComponent} from './course-detail/eu-course-survey-content/eu-course-survey-content.component';
import {TreeViewModule} from '@syncfusion/ej2-angular-navigations';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import {MatMenuModule} from "@angular/material/menu";
import { ModalCoursePreviewComponent } from './course-card/modal-course-preview/modal-course-preview.component';
import { RegisterCourseSuccessModalComponent } from './course-card/register-course-success-modal/register-course-success-modal.component';
import { UnRegisterCourseConfirmModalComponent } from './course-card/un-register-course-confirm-modal/un-register-course-confirm-modal.component';
import { CertificateUserDetailComponent } from './certificate-user/certificate-user-detail/certificate-user-detail.component';

@NgModule({
    imports: [
        CommonModule,
        EndUserRoutingModule,
        TranslateModule,
        FormsModule,
        NgbModule,
        MatIconModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatRadioModule,
        NgxSpinnerModule,
        NgSelectModule,
        ReactiveFormsModule,
        CountdownModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatTooltipModule,
        MatPaginatorModule,
        TreeViewModule,
        MatMenuModule,
    ],
  declarations: [
    EufooterComponent,
    EuheaderComponent,
    EndUserComponent,
    HomeComponent,
    EutopbarComponent,
    CourseExploreComponent,
    MyCourseComponent,
    CourseDetailComponent,
    ExamComponent,
    ExamResultComponent,
    CourseRouteComponent,
    CourseCardComponent,
    CourseRouteDetailComponent,
    ExamListComponent,
    ExamLessonComponent,
    ExamPreviewComponent,
    SurveyQuestionComponent,
    CertificateUserComponent,
    EuPageContentComponent,
    EuResourceContentComponent,
    EuBigbluebuttonContentComponent,
    EuScormContentComponent,
    EuCourseContentComponent,
    EuCourseHistoryContentComponent,
    EuCourseExamContentComponent,
    EuCourseSurveyContentComponent,
    EuCourseSurveyContentComponent,
    ChangePasswordComponent,
    UserDetailComponent,
    ModalCoursePreviewComponent,
    RegisterCourseSuccessModalComponent,
    UnRegisterCourseConfirmModalComponent,
    CertificateUserDetailComponent
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: CommonUtil.overridePaginatorIntl() },
    DialogService
  ]

})
export class EndUserModule { }
