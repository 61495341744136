import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css'],
  providers: [NgbModal, NgbModalConfig]
})
export class ConfirmModalComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;
  @Input() confirmButton: string;
  @Input() confirmEmail: false;

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
  }
}
