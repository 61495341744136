<div class="modal-header">
  <h5 class="modal-title sfbold text-dark">Thống kê kết quả thi</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="outline:none;" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center overflow-auto" style="height: 80vh;">
  <table class="table table-striped table-hover" *ngIf="departments?.length > 0">
    <thead>
    <tr class="bgdoandm text-white">
      <th class="text-left text-white sfbold">STT</th>
      <th class="text-left text-white sfbold">Đơn vị</th>
      <th class="text-center text-white sfbold">Số thí sinh</th>
      <th class="text-center text-white sfbold">lượt thi</th>
    </tr>
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td class="text-right pr-3 sfbold">Tổng</td>
        <td class="text-primary text-center sfbold">{{totalStudent}}</td>
        <td class="text-primary text-center sfbold">{{totalAttempt}}</td>
       </tr>
    <tr *ngFor="let department of departments; let i = index;">
      <td class="text-center">{{i+1}}</td>
      <td class="text-left">{{department.name}}</td>
      <td class="text-center">{{department.sothisinh}}</td>
      <td  class="text-center">{{department.slt}}</td>
    </tr>
    </tbody>
  </table>
  <b *ngIf="departments?.length === 0">Bạn chưa có bài thi nào</b>
</div>
<div class="modal-footer">
  <div class="m-auto">
    <button type="button" class="btn btn-primary" style="font-size: 12pt;" (click)="close()"> Đóng</button>
  </div>
</div>
