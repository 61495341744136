import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrCustomService } from './../../shared/services/toastr-custom.service';
import {LocalStorageService} from 'ngx-webstorage';
import {USER_INFO} from './../../shared/constants/base.constant';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {BadgeService} from '../../shared/services/badge.service';

@Component({
  selector: 'app-certificate-user',
  templateUrl: './certificate-user.component.html',
  styleUrls: ['./certificate-user.component.css']
})
export class CertificateUserComponent implements OnInit {

  certificate: any[];
  totalRecord: number = 0;
  userInfo: any;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex = 1;
  pageSize = 10;
  LOCAL_STORAGE_CERTIFICATE = 'certificate';

  constructor(
    private badgeService: BadgeService,
    private $localStorage: LocalStorageService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrCustomService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userInfo = this.$localStorage.retrieve(USER_INFO.INFO);
    this.getCertificateUser();
  }

  getCertificateUser() {
    this.spinner.show();
    const params = {
      limit: this.pageSize,
      page: this.pageIndex
    }
    this.badgeService.getCertificateUser(params).subscribe(
      res => {
      this.certificate = res.body.results;
      this.totalRecord = res.body.total;
      this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      }
    );
  }

  goToDetail(id: any) {
    this.router.navigate(['/certificate-user/detail', id]);
  }

  changePage(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getCertificateUser();
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

}
