import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import { FormBuilder, Validators } from '@angular/forms';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';

@Component({
  selector: 'app-text-question-survey',
  templateUrl: './text-question-survey.component.html',
  styleUrls: ['./text-question-survey.component.css']
})
export class TextQuestionSurveyComponent implements OnInit {

  @Input() index: any;
  @Input() questionInput: any;
  @Output() messageEvent = new EventEmitter<any>();
  @Input() checkChooseTemplate: any;

  public Editor = ClassicEditor;

  editorConfig = CKEDITOR_CONFIG.NOT_UPLOAD;

  createTextBoxQuestion = this.fb.group({
    description: ['', [Validators.required]],
    isteacher: ['0', [Validators.required]],
    index: ['']
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.questionInput) {
      this.createTextBoxQuestion.patchValue({
        description: this.questionInput.description,
        isteacher: this.questionInput.isteacher
      });
    }

    this.messageEvent.emit({form: this.createTextBoxQuestion, index: this.index});
    this.createTextBoxQuestion.valueChanges.subscribe((value) => {
      this.messageEvent.emit({form: this.createTextBoxQuestion, index: this.index});
    });

  }
}
