import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import CommonUtil from '../utils/common-util';
import {MOODLE_INFO} from '../constants/base.constant';
import {map} from 'rxjs/operators';
import {RoleSystem} from '../model/role-system';

type EntityResponseType = HttpResponse<RoleSystem>;
type EntityArrayResponseType = HttpResponse<RoleSystem[]>;

interface RolePageResponse {
  results: RoleSystem[];
  total: number;
}

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private abstractService: AbstractService) {

  }

  getListRoleSystem(): Observable<HttpResponse<RolePageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_role_list_role');
    return this.abstractService.post<RolePageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<RolePageResponse>) => res));
  }

  getListMenu(): Observable<HttpResponse<RolePageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_role_list_menu_with_role');
    return this.abstractService.post<RolePageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<RolePageResponse>) => res));
  }

  getListMenuWithRole(roleids): Observable<HttpResponse<RolePageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_role_list_menu_with_role');
    const param = {
      roleids
    };
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<RolePageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<RolePageResponse>) => res));
  }

  updateListMenuWithRole(roleId: any, menuIds: any): Observable<HttpResponse<RolePageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_role_update_role_menu');

    const params = {
      roleid: roleId,
      menuids: String(menuIds)
    };
    // debugger
    CommonUtil.appendDataToFormData(data, 'params[0]', params);
    return this.abstractService.post<RolePageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<RolePageResponse>) => res));
  }
}
