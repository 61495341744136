import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {NotifycationModel} from '../model/notifycation.model';
import {AbstractService} from './abstract.service';
import {BehaviorSubject, Observable} from 'rxjs';
import CommonUtil from '../utils/common-util';
import {map} from 'rxjs/operators';
import {MOODLE_INFO} from '../constants/base.constant';

type EntityResponseType = HttpResponse<NotifycationModel>;
type EntityArrayResponseType = HttpResponse<NotifycationModel[]>;

interface NotifyPageResponse {
  resutls: NotifycationModel[];
  total: number;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  behaviorSubject = new BehaviorSubject<number>(0);
  currentSubject = this.behaviorSubject.asObservable();

  constructor(private abstractService: AbstractService) {
  }

  changeMessage(message) {
    this.behaviorSubject.next(message);
  }

  // tạo thông báo đột xuất
  createNotifySingle(courseidss: any, titlee: any, contentt: any, examidss: any, positionids?: any, departmentids?: any, userids?: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_notification_create_notification');
    const notification = {
      courseids: courseidss,
      positionids: positionids,
      departmentids: departmentids,
      title: titlee,
      content: contentt,
      examids: examidss,
      userids: userids,
    };
    CommonUtil.appendDataToFormData(data, 'notification', notification);
    return this.abstractService.post<NotifycationModel>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  searchNotifyGeneral(limit: any, page: any, sortcolumn: string, sorttype: string, keyword?: any, id?: any):
    Observable<HttpResponse<NotifyPageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_notification_list_template');
    const params = {
      page: page,
      limit: limit,
      keyword: keyword,
      ids: id,
      sortcolumn: sortcolumn,
      sorttype: sorttype
    };
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<NotifyPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<NotifyPageResponse>) => res));
  }

  searchNotifySingle(limit: any, page: any, sortcolumn: any, sorttype: any, keyWord?: any, id?: any): Observable<HttpResponse<NotifyPageResponse>>{
    const data = this.abstractService.getCommonInput('mobifone_notification_list_notification_manager');
    const params = {
      limit: limit,
      page: page,
      keyword: keyWord,
      sortcolumn: sortcolumn,
      sorttype: sorttype,
      ids: id
    };
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<NotifyPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<NotifyPageResponse>) => res));
  }

  updateNotifyTemplate(notifyTemplate: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_notification_update_template');
    const notify: any = {
      id: notifyTemplate.id,
      title: notifyTemplate.title,
      content: notifyTemplate.content,
      isactive: notifyTemplate.isactive,
    };
    CommonUtil.appendDataToFormData(data, 'template', notify);
    return this.abstractService.post<NotifycationModel>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  deleteNotify(ids: string): Observable<EntityArrayResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_notification_delete_noti_history');
    CommonUtil.appendDataToFormData(data, 'params[ids]', ids);
    return this.abstractService.post<any[]>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponseType) => res));
  }


}
