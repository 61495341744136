import {ExamDetailComponent} from './exam/exam-detail/exam-detail.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './admin.component';
import {CourseComponent} from './course/course.component';
import {CourseCategoryComponent} from './course-category/course-category.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {CourseCreateComponent} from './course/course-create/course-create.component';
import {DepartmentComponent} from './department/department.component';
import {UserComponent} from './user/user.component';
import {AuthGuard} from '../auth/auth.guard';
import {CourseContentComponent} from './course-content/course-content.component';
import {PositionComponent} from './position/position.component';
import {ExamComponent} from './exam/exam.component';
import {CreateExamComponent} from './exam/create-exam/create-exam.component';
import {SurveyComponent} from './survey/survey.component';
import {CreateSurveyComponent} from './survey/create-survey/create-survey.component';
import {SurveyDetailComponent} from './survey/survey-detail/survey-detail.component';
import {CourseDetailComponent} from './course/course-detail/course-detail.component';
import {QuestionBankComponent} from './question-bank/question-bank.component';
import {QuestionCreateComponent} from './question-bank/question-create/question-create.component';
import {BadgeComponent} from './badge/badge.component';
import {BadgeCreateComponent} from './badge/badge-create/badge-create.component';
import {BadgeDetailsComponent} from './badge/badge-details/badge-details.component';
import {NotificationComponent} from './notification/notification.component';
import {CreateNotificationComponent} from './notification/create-notification/create-notification.component';
import {RoleComponent} from './role/role.component';
import {ReportComponent} from './report/report.component';
import {ReportCourseComponent} from './report/report-course/report-course.component';
import {USER_MENU} from '../shared/constants/base.constant';
import {ReportStudentComponent} from './report/report-student/report-student.component';
import { SurveySingleDetailComponent } from './survey/survey-single-detail/survey-single-detail.component';
import { ResultExamComponent } from './report/result-exam/result-exam.component';
import { ResultDetailExamComponent } from './report/result-exam/result-detail-exam/result-detail-exam.component';
import { StudentDetailsComponent } from './report/report-student/student-details/student-details.component';
import { ResultExamDTNComponent } from './report/result-exam-dtn/result-exam.component';


const routes: Routes = [{
  path: 'admin',
  component: AdminComponent,
  children: [
    {path: '', data: {breadcrumb: 'Admin'}, canActivate: [AuthGuard], component: DashboardComponent},
    {path: 'dashboard', data: {breadcrumb: 'Admin', authorities: USER_MENU.REPORT}, canActivate: [AuthGuard], component: DashboardComponent},
    {path: 'course', data: {breadcrumb: 'Admin', authorities: USER_MENU.COURSE}, canActivate: [AuthGuard], component: CourseComponent},
    {
      path: 'course/detail/:id',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.COURSE},
      canActivate: [AuthGuard],
      component: CourseDetailComponent
    },
    {
      path: 'course/content',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.COURSE},
      canActivate: [AuthGuard],
      component: CourseContentComponent
    },
    {
      path: 'course/content/:id',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.COURSE},
      canActivate: [AuthGuard],
      component: CourseContentComponent
    },
    {
      path: 'new-course',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.COURSE},
      canActivate: [AuthGuard],
      component: CourseCreateComponent
    },
    {
      path: 'department',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.DEPARTMENT},
      canActivate: [AuthGuard],
      component: DepartmentComponent
    },
    {
      path: 'course-category',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.COURSE_CATEGORY},
      canActivate: [AuthGuard],
      component: CourseCategoryComponent
    },
    {
      path: 'position',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.POSITION},
      canActivate: [AuthGuard],
      component: PositionComponent
    },
    {path: 'user', data: {breadcrumb: 'Admin', authorities: USER_MENU.USER}, canActivate: [AuthGuard], component: UserComponent},
    {path: 'exam', data: {breadcrumb: 'Admin', authorities: USER_MENU.EXAM}, canActivate: [AuthGuard], component: ExamComponent},
    {
      path: 'create-exam',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.EXAM},
      canActivate: [AuthGuard],
      component: CreateExamComponent
    },
    {
      path: 'exam/detail/:id',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.EXAM},
      canActivate: [AuthGuard],
      component: ExamDetailComponent
    },
    {path: 'survey', data: {breadcrumb: 'Admin', authorities: USER_MENU.SURVEY}, canActivate: [AuthGuard], component: SurveyComponent},
    {
      path: 'create/survey',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.SURVEY},
      canActivate: [AuthGuard],
      component: CreateSurveyComponent
    },
    {
      path: 'survey/detail',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.SURVEY},
      canActivate: [AuthGuard],
      component: SurveyDetailComponent
    },
    {
      path: 'question-bank',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.QUESTION_BANK},
      canActivate: [AuthGuard],
      component: QuestionBankComponent
    },
    {
      path: 'question-bank/create',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.QUESTION_BANK},
      canActivate: [AuthGuard],
      component: QuestionCreateComponent
    },
    // {path: 'update-survey/:id', data: {breadcrumb: 'Admin'}, canActivate: [AuthGuard], component: CreateSurveyComponent}
    {
      path: 'update-survey/:id/:id2',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.SURVEY},
      canActivate: [AuthGuard],
      component: CreateSurveyComponent
    },
    {
      path: 'survey/detail/:id/:id2',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.SURVEY},
      canActivate: [AuthGuard],
      component: SurveyDetailComponent
    },
    {
      path: 'survey/single-detail/:id',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.SURVEY},
      canActivate: [AuthGuard],
      component: SurveySingleDetailComponent
    },
    {path: 'badge', data: {breadcrumb: 'Admin', authorities: USER_MENU.BADGE}, canActivate: [AuthGuard], component: BadgeComponent},
    {
      path: 'badge-create',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.BADGE},
      canActivate: [AuthGuard],
      component: BadgeCreateComponent
    },
    {
      path: 'badge/detail/:id',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.BADGE},
      canActivate: [AuthGuard],
      component: BadgeDetailsComponent
    },
    {
      path: 'notification',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.NOTIFICATION},
      canActivate: [AuthGuard],
      component: NotificationComponent
    },
    {
      path: 'notification/details',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.NOTIFICATION},
      canActivate: [AuthGuard],
      component: CreateNotificationComponent
    },
    {path: 'role', data: {breadcrumb: 'Admin', authorities: USER_MENU.ROLE}, canActivate: [AuthGuard], component: RoleComponent},
    {path: 'report', data: {breadcrumb: 'Admin', authorities: USER_MENU.REPORT}, canActivate: [AuthGuard], component: ReportComponent},
    {
      path: 'report-course',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.REPORT},
      canActivate: [AuthGuard],
      component: ReportCourseComponent
    },
    {
      path: 'notification/detail/:id',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.NOTIFICATION},
      canActivate: [AuthGuard],
      component: CreateNotificationComponent
    },
    {
      path: 'report-student',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.REPORT},
      canActivate: [AuthGuard],
      component: ReportStudentComponent
    },
    {
      path: 'result-exam',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.REPORT},
      canActivate: [AuthGuard],
      component: ResultExamDTNComponent
    },
    {
      path: 'result-exam/detail/:id',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.REPORT},
      canActivate: [AuthGuard],
      component: ResultDetailExamComponent
    },
    {
      path: 'report-student/detail/:id',
      data: {breadcrumb: 'Admin', authorities: USER_MENU.REPORT},
      canActivate: [AuthGuard],
      component: StudentDetailsComponent
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
