<div class="navbar_exam">
  <section class="inner_page_breadcrumb bg-exam fixed-top">
    <div class="container">
      <nav>
        <div class="row">
          <div class="col-xl-12 text-center">
            <div class="breadcrumb_content mt-2">
              <h4 class="breadcrumb_title">{{quizName}}</h4>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </section>
</div>
<main *ngIf="permission; else notPermission" id="main" class="main_exam">
  <!---Phan noi dung bai hoc-->
  <section id="exam">
    <div class="container">
      <div class="row">
        <div *ngIf="checknoquestion === true" class="col-12 text-center border p-3">
          <img src="/assets/crane.svg" style="height: 25vh;" alt="">
          <h5 class="mt-2">Bài thi chưa có câu hỏi ! </h5>
          <span><button (click)="back()" class="btn btn-secondary btn-md rounded-0"><i class="fas fa-arrow-left"></i> Trở lại</button></span>
        </div>
        <div class="col-lg-9 col-md-auto pb-2">
          <div class="overflow mt-3">
            <form *ngFor="let question of listQuestion;let i = index;">
              <div class="card card-default rounded " [id]="question.slot">
                <div class="card-body p-3">
                  <div class="row form-inline">
                    <div class="col-auto">
                      <div class="dot">{{i + 1}}</div>
                    </div>
                  </div>
                  <div class="row mt-3 noselect">
                    <div class="col-lg-12 col-md-6 label-text text-justify noselect pl-3">
                      <span [innerHTML]="question.questiontext"></span>
                      <iframe *ngIf="question.linkVideo" width="420" height="315"
                              [src]="domSanitizer2.bypassSecurityTrustResourceUrl(question.linkVideo)">
                      </iframe>
                    </div>
                    <div class="col-lg-2 col-md-6">
                    </div>
                  </div>
                  <div class="row pl-2 mt-3">
                    <div class="col-12">
                        <div class="form-check bg-light"
                            *ngFor="let answer of question.answers; let i = index;">
                            <ng-container *ngIf="question.typeCheckBox=='checkbox'">
                                <mat-checkbox
                                    [checked]="checkAnswerMultiChoice(answer,question.responsesummary)"
                                    class="example-margin" name="check" [disabled]="true">
                                    <div>
                                        <div class=" form-inline"  *ngIf="question.answernumbertype">{{question.answernumbertype[i]}}.<span [innerHTML]="answer"></span></div></div>
                                </mat-checkbox>
                            </ng-container>
                            <ng-container *ngIf="question.typeCheckBox=='radio'">
                                <mat-radio-button
                                    class="example-margin" [name]="question.slot" [disabled]="true">
                                    <div class="row">
                                        <div class="col-auto" *ngIf="question.answernumbertype">
                                            {{question.answernumbertype[i]}}.</div>
                                        <div class="col-auto">
                                          <span [innerHTML]="answer | translate"></span>
                                        </div>
                                    </div>
                                </mat-radio-button>
                            </ng-container>
                        </div>
                    </div>
                </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="stick-area">
            <div class="d-flex flex-wrap overflow-auto mt-5">
              <div class="dot mr-1 mb-1" *ngFor="let question of listQuestion">
                <div [ngClass]="{'square-gray': question.condition==1}" (click)="nextToElement(question.slot)">
                  <div class="content">{{question.slot}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</main>
<ng-template #notPermission>
  <div class="container">
    <div class="col-12 text-center border p-3 mt-2">
      <img src="/assets/graduation-cap.svg" style="height: 25vh;" alt="">
      <h5 class="mt-3"><strong>{{'error.cannotviewcourse' | translate}}</strong></h5>
    </div>
  </div>
</ng-template>
<!-- End #main -->
