import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-course-success-modal',
  templateUrl: './register-course-success-modal.component.html',
  styleUrls: ['./register-course-success-modal.component.css']
})
export class RegisterCourseSuccessModalComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;
  @Input() confirmButton: string;

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
  }

}
