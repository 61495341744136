import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {RESPONSE, USER_INFO} from '../constants/base.constant';

@Injectable({ providedIn: 'root' })
export class AbstractService {


  readonly httpOptions: { observe; } = {
    observe: 'response'
  };
  readonly httpOptionsFile: { responseType; } = {
    responseType: 'blob'
  };

  constructor(
    private http: HttpClient,
    private $localStorage: LocalStorageService,
) {
}
  post<T>(url: string, body: any) {
    return this.http.post<T>(url, body, this.httpOptions);
  }
  postFile<T>(url: string, body: any) {
    return this.http.post<T>(url, body, this.httpOptionsFile);
  }

  get<T>(url: string) {
    return this.http.get<T>(url, this.httpOptions);
  }

  getCommonInput(functionName: string): FormData {
    const token = this.$localStorage.retrieve(USER_INFO.TOKEN);
    const formData: FormData = new FormData();
    formData.append('wstoken', token);
    formData.append('wsfunction', functionName);
    formData.append('moodlewsrestformat', RESPONSE.CONTENT_TYPE);
    return formData;
  }
}
