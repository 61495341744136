import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SessionStorageService} from 'ngx-webstorage';
import {LoginService} from 'src/app/shared/services/login.service';
import {AbstractService} from 'src/app/shared/services/abstract.service';
import CommonUtil from 'src/app/shared/utils/common-util';
import {MOODLE_INFO, USER_INFO} from 'src/app/shared/constants/base.constant';
import {map} from 'rxjs/operators';

type EntityArrayResponse = HttpResponse<any>;

@Injectable({providedIn: 'root'})
export class ExamLessonService {
  constructor(private abstractService: AbstractService) {

  }


  listAllQuiz(): Observable<EntityArrayResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_end_user_list_all_quiz');
    return this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityArrayResponse) => res));
  }

  startAttempt(quizid): Observable<EntityArrayResponse> {
    const data = this.abstractService.getCommonInput('mobifone_quiz_start_attempt');
    data.append('quizid', quizid);
    const returndata = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityArrayResponse) => res));
    return returndata;
  }

  finishAttempt(attemptid): Observable<EntityArrayResponse> {
    const data = this.abstractService.getCommonInput('mobifone_quiz_finish_attempt');
    const param = {
      attemptid
    };
    CommonUtil.appendDataToFormData(data, 'params', param);
    const returndata = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityArrayResponse) => res));
    return returndata;
  }

  updateAnswer(attemptid, uniqueid, slot, answercorrect, single, type, flagged): Observable<EntityArrayResponse> {
    const data = this.abstractService.getCommonInput('mobifone_quiz_update_answer');
    const param = {
      attemptid,
      uniqueid,
      slot,
      answercorrect,
      single,
      type,
      flagged
    };
    CommonUtil.appendDataToFormData(data, 'params', param);
    const returndata = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityArrayResponse) => res));
    return returndata;
  }

  getCurrentQuizActive(listall: number = 0): Observable<EntityArrayResponse> {
    const data = this.abstractService.getCommonInput('mobifone_quiz_get_current_quiz_active');
    const param = { listall };
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityArrayResponse) => res));
  }

}
