import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TopicDetailComponent} from './topic-detail/topic-detail.component';
import {ConfirmModalComponent} from '../../shared/modal/confirm-modal/confirm-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {NgxSpinnerService} from 'ngx-spinner';
import {Module, TopicCourseModel} from '../../shared/model/topic.course.model';
import {CourseService} from '../../shared/services/course.service';
import {TopicService} from '../../shared/services/topic.service';

@Component({
  selector: 'app-course-content',
  templateUrl: './course-content.component.html',
  styleUrls: ['./course-content.component.css']
})
export class CourseContentComponent implements OnInit, OnDestroy {
  // MLMS 668
  @Input() courseDetail;
  currentModule: any;
  hasModuleChange = false;
  section: number;
  courseId: number;
  tabname = '';
  nameSection: string;
  topicIds: number[] = [];
  topicId: number;
  createduser: string;
  createdat: number;
  topicContent: TopicCourseModel [] = [];
  totalTopic: number;
  panelOpenState = true;
  currentDate: Date = new Date();
  isCheckedAll = false;
  idModuleNewOrEdit: number;

  // MLMS 301
  selectedInfo = {
    currentModuleOrder: null,
    currentTopicIndex: null
  };
  listTopicId = [];
  searchTopic: string;
  currentTopic: any;

  constructor(
    private route: ActivatedRoute,
    private topicService: TopicService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toarstService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private courseService: CourseService,
    private toastrService: ToastrCustomService,
  ) {}

  ngOnInit(): void {
    this.courseId = +this.route.snapshot.paramMap.get('id');
    this.getCourseTopic();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  showTab(module, tabname) {
    if (this.hasModuleChange) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });

      modalDep.componentInstance.title = this.translateService.instant('module.has_module_change_title');
      // tslint:disable-next-line:max-line-length
      modalDep.componentInstance.body = this.translateService.instant('module.has_module_change_body');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.confirm');

      modalDep.result.then((result) => {
        this.hasModuleChange = false;
        this.changeModule(module, tabname);
      });
    } else {
      this.changeModule(module, tabname);
    }
  }

  changeModule(module, tabname) {
    // Thay doi tab
    if (module) {
      this.currentModule = module;
      this.tabname = module.modname;
      this.topicContent.forEach((top, currentTopicIndex) => {
        top.modules?.forEach(mod => {
          if (mod.id === module.id) {
            mod.selected = true;
            // Bai giang nao duoc select thi title cua topic cung duoc hien thi
            this.nameSection = top.name;
            // Luu trang thai cua bai giang dang duoc select
            this.selectedInfo.currentModuleOrder = mod.ordernumber;
            this.selectedInfo.currentTopicIndex = currentTopicIndex;
            // Khi click vao 1 bai giang thi topic cung dc select theo
            this.topicId = top.id;
            this.section = top.section;
          } else {
            mod.selected = false;
          }
        });
      });
    } else {
      // Confirm ẩn hiển thị khóa học trước khi thêm mới bài giảng
      if (this.courseDetail.published === 1) {
        this.checkCoursePublished();
        return;
      }
      // tạo mới bài giảng
      this.currentModule = null;
      this.tabname = tabname;
      // bỏ focus bài giảng
      this.topicContent.forEach((top) => {
        top.modules?.forEach(mod => {
          mod.selected = false;
        });
      });
    }
  }

  selectAllTopic(checked: any) {
    this.topicContent.forEach(element => {
      if (checked) {
        element.checked = true;
        this.topicIds.push(element.id);
      } else {
        element.checked = false;
        this.topicIds = this.topicIds.filter(t => t !== element.id);
      }
    });
  }

  onCheckBoxCheckedAll() {
    if (this.topicContent.length === this.topicIds.length) {
      this.isCheckedAll = true;
    } else {
      this.isCheckedAll = false;
    }
  }

  unSelectAllTopic() {
    this.isCheckedAll = false;
    this.topicIds = [];
  }

  getCourseTopic(search?: string): void {
    this.spinner.show();
    this.topicService.getCourseTopics(this.courseId, search).subscribe((data) => {
      this.topicContent = data.body;
      this.topicContent.forEach(element => {
        if (element.modules !== undefined && element.modules !== null) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < element.modules.length; i++) {
            if (element.modules[i]?.modname === 'resource') {
              element.modules[i].mimetype = 'Tài liệu/Video';
            } else if (element.modules[i]?.modname === 'page') {
              element.modules[i].mimetype = 'Nội dung';
            } else if (element.modules[i]?.modname === 'bigbluebuttonbn') {
              element.modules[i].mimetype = 'Bài giảng trực tuyến';
            } else if (element.modules[i]?.modname === 'scorm') {
              element.modules[i].mimetype = 'Scorm';
            }
          }
        }
        this.listTopicId.push(element.id.toString());
      });
      this.totalTopic = this.topicContent.length;
      if (!this.currentTopic && this.topicContent?.length > 0) {
        this.selectAdd(this.topicContent[0]);
      }
      // nếu có bài giảng vừa được sửa / thêm mới thì auto focus vào bài giảng đó khi load lại list
      if (this.idModuleNewOrEdit) {
        this.topicContent?.forEach((top, currentTopicIndex) => {
          top.modules?.forEach(mod => {
            if (mod.id === this.idModuleNewOrEdit) {
              mod.selected = true;
              this.currentModule = mod;
              this.selectedInfo.currentModuleOrder = mod.ordernumber;
              this.selectedInfo.currentTopicIndex = currentTopicIndex;
            } else {
              mod.selected = false;
            }
          });
        });
        // dùng xong làm mới
        this.idModuleNewOrEdit = null;
      }
      this.unSelectAllTopic();
    });
    this.spinner.hide();
  }


  selectAdd(item) {
    this.currentTopic = item;
    this.section = item.section;
    this.topicId = item.id;
    this.nameSection = item.name;
    // Do 1 topic co the co nhieu manager/teach tao bai giang nen khi click vao topic khong hien thi ten giang vien nua
    this.createduser = null;
    this.createdat = null;
    this.tabname = '';
    this.selectedInfo.currentModuleOrder = null;
    this.selectedInfo.currentTopicIndex = null;
    // Xoa highlight bai giang dang duoc select khi click vao topic title
    this.topicContent.forEach((top, currentTopicIndex) => {
      top.modules?.forEach(mod => {
        mod.selected = false;
      });
    });
  }

  selectTopic(event, topic) {
    if (event) {
      topic.checked = true;
      this.topicIds.push(topic.id);
    } else {
      topic.checked = false;
      this.topicIds = this.topicIds.filter(t => t !== topic.id);
    }
    this.onCheckBoxCheckedAll();
  }

  createTopic() {
    if (this.courseDetail.published === 1) {
      this.checkCoursePublished();
      return;
    }
    const topicName: any = {
      name: '',
      courseid: this.courseId,
      topicid: ''
    };

    const modalTopic = this.modalService.open(TopicDetailComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalTopic.componentInstance.topic = topicName;
    modalTopic.componentInstance.title = 'topic.create_title';
    modalTopic.componentInstance.button = 'common.add';
    modalTopic.componentInstance.newTopic.subscribe(($e) => {
      this.getCourseTopic();
    });

  }

  editTopic(topicInfo) {
    const topicName: any = {
      name: topicInfo.name,
      topicId: topicInfo.id
    };
    const title = 'topic.update_title';
    const button = 'common.save';
    this.openEditTopic(topicName, title, button);
  }

  openEditTopic(item, title, button) {
    const modalTopic = this.modalService.open(TopicDetailComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalTopic.componentInstance.topic = item;
    modalTopic.componentInstance.title = title;
    modalTopic.componentInstance.button = button;
    modalTopic.componentInstance.newTopic.subscribe((data) => {
      this.selectAdd(data);
      this.getCourseTopic();
    });
  }

  deleteTopic(item) {
    this.deleteTopics([item.id]);
  }

  deleteMultiTopics() {
    this.deleteTopics(this.topicIds);
  }

  deleteTopics(ids: number[]) {
    const modalTopic = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    const deleteInfo: any = {
      topicId: ids,
      courseid: this.courseId
    };
    modalTopic.componentInstance.title = this.translateService.instant('topic.confirm_delete_title');
    modalTopic.componentInstance.body = this.translateService.instant('topic.confirm_delete_body');
    modalTopic.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalTopic.result.then((result) => {
      this.spinner.show();
      this.topicService.deleteTopic(deleteInfo).subscribe(
        res => {
          if (ids.includes(this.topicId)) {
            // topic dang duoc select bi xoa thi khong hien thi ten cua no tren title nua
            this.nameSection = null;
            this.topicContent.forEach(top => {
              if (top.id === this.topicId) {
                // Neu dang tao bai giang nhung topic duoc chon de tao bai giang do bi xoa
                // => khong hien thi phan tao bai giang nua
                if (top.section === this.section) {
                  this.tabname = '';
                }
                top.modules?.forEach(mod => {
                  // neu bai giang dang duoc select thuoc topic bi xoa thi se khong hien thi gi ca nua
                  if (mod.selected) {
                    this.createduser = null;
                    this.createdat = null;
                    this.tabname = '';
                    this.selectedInfo.currentModuleOrder = null;
                    this.selectedInfo.currentTopicIndex = null;
                  }
                  // neu bai giang dang duoc select KHONG thuoc topic bi xoa thi hien thi binh thuong
                });
              }
            });
          }
          this.getCourseTopic();

          this.topicIds.forEach(topicId => {
            if (topicId === this.topicId) {
              this.topicId = null;
            }
          });
          this.topicIds = [];
          this.toarstService.success('noti.delete_success');
          this.spinner.hide();
          this.onCheckBoxCheckedAll();
        }, error => {
          // this.getCourseTopic();
          this.toarstService.handlerError(error);
          this.spinner.hide();
          this.onCheckBoxCheckedAll();
        }
      );
      // this.spinner.hide();
    });
  }

  deletemodule(id) {
    const modalModule = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    const cmids: number [] = [];
    cmids.push(id);

    modalModule.componentInstance.title = this.translateService.instant('module.confirm_delete_title');
    modalModule.componentInstance.body = this.translateService.instant('module.confirm_delete_body');
    modalModule.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalModule.result.then((result) => {
        this.spinner.show();
        this.topicService.deleteModule(cmids).subscribe(
          (res) => {
            this.toarstService.success(this.translateService.instant('noti.delete_success'));
            this.spinner.hide();
            this.getCourseTopic();
            // Khi delete xong thi tu dong show noi dung tiep
            let module = this.findNextModule();
            if (module) {
              this.changeModule(module, this.tabname);
            } else {
              module = this.findPreviousModule();
              if (module) {
                this.changeModule(module, this.tabname);
              } else {
                this.currentModule = null;
                this.tabname = null;
              }
            }
          },
          err => {
            this.spinner.hide();
            this.toarstService.handlerError(err);
          }
        );
      },
    );
  }

  drop(event: CdkDragDrop<[Module], any>) {
    // move in front end
    this.dropDragModule(event.previousContainer, event.container, event.previousIndex, event.currentIndex);
    // set param
    let params = {};
    const modules = [];
    this.topicContent.forEach((topic, topicIndex) => {
      // chi set lai order number cho bai giang o cac topic bi anh huong
      // id cua event.container = id cua topic ma cource di chuyen den
      // id cua event.previousContainer = id cua topic ban dau cua course
      // id nay duoc set o html (<div id="{{item.id}}" )
      if (topic.id === +event.container.id || topic.id === +event.previousContainer.id) {
        topic.modules?.forEach((module, moduleIndex) => {
          const mod = {
            moduleid: module.id,
            sectionid: topic.id,
            ordernumber: moduleIndex + 1,
          };
          modules.push(mod);
          // set ordernumber tren front end, phuc vu cho next va previous
          module.ordernumber = mod.ordernumber;
        });
      }

    });
    params = {
      modules,
      dragmoduleid: event.container.data[event.currentIndex].id,
      courseid: this.courseId
    };

    // call backend
    this.spinner.show();
    this.courseService.dropDragModule(params).subscribe(
      res => {
        // Show bai giang duoc keo tha
        const course = event.container.data[event.currentIndex];
        // this.showTab('Showcontent', course.id, course);
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        // tra ve vi tri cu
        this.dropDragModule(event.container, event.previousContainer, event.currentIndex, event.previousIndex);
        this.toarstService.handlerError(error);
        // Show bai giang duoc keo tha
        const course = event.previousContainer.data[event.previousIndex];
        // this.showTab('Showcontent', course.id, course);

      }
    );
  }

  dropDragModule(previousContainer: any, currentContainer: any, previousIndex: any, currentIndex: any) {
    if (previousContainer === currentContainer) {
      moveItemInArray(currentContainer.data, previousIndex, currentIndex);
    } else {
      transferArrayItem(previousContainer.data,
        currentContainer.data,
        previousIndex,
        currentIndex);
    }
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  nextModule() {
    const nextModule = this.findNextModule();
    if (nextModule) {
      this.showTab(nextModule, null);
    }
  }

  previousModule() {
    const previousModule = this.findPreviousModule();
    if (previousModule) {
      this.showTab(previousModule, null);
    }
  }

  findNextModule(): any {
    if (this.selectedInfo.currentModuleOrder === null) {
      return null;
    }
    let nextModule;
    for (let topicIndex = this.selectedInfo.currentTopicIndex; topicIndex < this.topicContent.length; topicIndex++) {

      // tslint:disable-next-line:prefer-for-of
      for (let moduleIndex = 0; moduleIndex < this.topicContent[topicIndex].modules.length; moduleIndex++) {
        const mod = this.topicContent[topicIndex].modules[moduleIndex];
        // tim bai giang tiep theo trong cung topic
        if (topicIndex === this.selectedInfo.currentTopicIndex) {
          // chua tim thay bai giang nao co ordernumber lon hon ordernumber cua bai giang hien tai
          if (nextModule === undefined) {
            // tim thay bai giang co ordernumber lon hon thi tam thoi gan nextModule = mod
            if (mod.ordernumber > this.selectedInfo.currentModuleOrder) {
              nextModule = mod;
            }
          } else {
            /*
              Tiep tuc tim kiem bai giang co ordernumber lon hon gan nhat voi bai giang hien tai
            */
            if (mod.ordernumber > this.selectedInfo.currentModuleOrder
              && mod.ordernumber < nextModule.ordernumber) {
              nextModule = mod;
            }
          }
        } else {
          /*
            neu chua tim duoc bai giang tiep theo trong cung topic voi bai giang hien tai
            => bai giang hien tai la bai giang cuoi cung cua topic hien tai
            => tim kiem bai giang co ordernumber nho nhat cua topic tiep theo
          */

          if (nextModule === undefined) {
            // Gan bai giang tiep theo la bai giang tim thay dau tien cua topic tiep theo
            nextModule = mod;
          } else {
            // tim kiem bai giang co ordernumber nho nhat cua topic tiep theo
            if (nextModule.ordernumber > mod.ordernumber) {
              nextModule = mod;
            }
          }
        }

      }

      /* Sau moi lan tim kiem o topic => kiem tra xem da tim thay bai giang tiep theo chua
       neu da tim thay thi dung vong lap */
      if (nextModule) {
        break;
      }
    }

    /* Tim kiem thanh cong => tra ve bai giang tiep theo
     Khong thanh cong tra ve undefined */
    return nextModule;
  }

  findPreviousModule(): any {
    if (this.selectedInfo.currentModuleOrder === null) {
      return null;
    }
    let nextModule;
    for (let topicIndex = this.selectedInfo.currentTopicIndex; topicIndex >= 0; topicIndex--) {
      // tslint:disable-next-line:prefer-for-of
      for (let moduleIndex = 0; moduleIndex < this.topicContent[topicIndex].modules.length; moduleIndex++) {
        const mod = this.topicContent[topicIndex].modules[moduleIndex];
        // tim bai giang truoc do trong cung topic
        if (topicIndex === this.selectedInfo.currentTopicIndex) {
          // chua tim thay bai giang nao co ordernumber nho hon ordernumber cua bai giang hien tai
          if (nextModule === undefined) {
            // tim thay bai giang co ordernumber nho hon thi tam thoi gan nextModule = mod
            if (mod.ordernumber < this.selectedInfo.currentModuleOrder) {
              nextModule = mod;
            }
          } else {
            /*
              Tiep tuc tim kiem bai giang co ordernumber nho hon gan nhat voi bai giang hien tai
            */
            if (mod.ordernumber < this.selectedInfo.currentModuleOrder
              && mod.ordernumber > nextModule.ordernumber) {
              nextModule = mod;
            }
          }
        } else {
          /*
            neu chua tim duoc bai giang truoc do trong cung topic voi bai giang hien tai
            => bai giang hien tai la bai giang dau tien cua topic hien tai
            => tim kiem bai giang co ordernumber lon nha cua topic truoc do
          */
          if (nextModule === undefined) {
            // Gan bai giang tiep theo la bai giang tim thay dau tien cua topic tiep theo
            nextModule = mod;
          } else {
            // tim kiem bai giang co ordernumber lon nhat cua topic tiep theo
            if (nextModule.ordernumber < mod.ordernumber) {
              nextModule = mod;
            }
          }
        }
      }
      /* Sau moi lan tim kiem o topic => kiem tra xem da tim thay bai giang tiep theo chua
       neu da tim thay thi dung vong lap */
      if (nextModule) {
        break;
      }
    }

    /* Tim kiem thanh cong => tra ve bai giang tiep theo
     Khong thanh cong tra ve undefined */
    return nextModule;
  }

  onSearchTopic(search: string) {
    this.searchTopic = search;
    this.getCourseTopic(search);
  }

  checkCoursePublished() {
    if (this.courseDetail.published === 1) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalDep.componentInstance.title = this.translateService.instant('common.caution');
      modalDep.componentInstance.body = this.translateService.instant('common.noti.required_un_publish_course');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.hide');
      modalDep.result.then((result) => {
        if (result === 'confirm') {
          this.courseService.unPublishCourse(this.courseId).subscribe(
            res => {
              this.toastrService.success(`common.noti.un_publish_success`);
              this.courseDetail.published = 0;
            },
            err => {
              this.toastrService.handlerError(err);
            }
          );
        }
      });
    }
  }

  onHasChange($event) {
    this.hasModuleChange = $event;
  }

  onReLoadTopics($event) {
    this.idModuleNewOrEdit = $event;
    this.getCourseTopic();
  }

}
