import {CertificateUserDetailComponent} from './certificate-user/certificate-user-detail/certificate-user-detail.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EndUserComponent} from './end-user.component';
import {HomeComponent} from './home/home.component';
import {CourseExploreComponent} from './course-explore/course-explore.component';
import {MyCourseComponent} from './my-course/my-course.component';
import {CourseDetailComponent} from './course-detail/course-detail.component';
import {ExamComponent} from './exam/exam.component';
import {AuthGuard} from '../auth/auth.guard';
import {CourseRouteComponent} from './course-route/course-route.component';
import {CourseRouteDetailComponent} from './course-route-detail/course-route-detail.component';
import {ExamListComponent} from './exam-list/exam-list.component';
import {ExamLessonComponent} from './exam-list/exam-lesson/exam-lesson.component';
import {SurveyQuestionComponent} from './survey-question/survey-question.component';
import {CertificateUserComponent} from './certificate-user/certificate-user.component';
import {ExamPreviewComponent} from './exam-preview/exam-preview.component';
import {UserDetailComponent} from './user-detail/user-detail.component';
import {ChangePasswordComponent} from './change-password/change-password.component';

const routes: Routes = [{
  path: '',
  component: EndUserComponent,
  children: [
    { path: '',   redirectTo: '/home', pathMatch: 'full' },
    {path: 'eu', data: {breadcrumb: 'Home'}, canActivate: [AuthGuard], component: HomeComponent},
    {path: 'course-explore', data: {breadcrumb: 'Course Explore'}, canActivate: [AuthGuard], component: CourseExploreComponent},
    {path: 'my-course', data: {breadcrumb: 'My Course'}, canActivate: [AuthGuard], component: MyCourseComponent},
    {path: 'course-register', data: {breadcrumb: 'Course Register'}, canActivate: [AuthGuard], component: MyCourseComponent},
    {path: 'teacher-course', data: {breadcrumb: 'Teacher Course'}, canActivate: [AuthGuard], component: MyCourseComponent},
    {path: 'my-course-detail/:id', canActivate: [AuthGuard], data: {breadcrumb: 'Course Detail'}, component: CourseDetailComponent},
    // tslint:disable-next-line: max-line-length
    {path: 'exam-details/:quizId', canActivate: [AuthGuard], canDeactivate: [AuthGuard], data: {breadcrumb: 'Exam'}, component: ExamComponent},
    // tslint:disable-next-line:max-line-length
    {path: 'exam-preview/:quizId', canActivate: [AuthGuard], canDeactivate: [AuthGuard], data: {breadcrumb: 'Exam'}, component: ExamPreviewComponent},
    {path: 'exam-list', canActivate: [AuthGuard], data: {breadcrumb: 'Exam'}, component: ExamLessonComponent},
    {path: 'exam-lesson/:id', canActivate: [AuthGuard], data: {breadcrumb: 'Exam'}, component: ExamLessonComponent},
    {path: 'course-route', canActivate: [AuthGuard], data: {breadcrumb: 'course-route'}, component: CourseRouteComponent},
    {path: 'certificate-user', canActivate: [AuthGuard], data: {breadcrumb: 'certificate-User'}, component: CertificateUserComponent},
    {path: 'certificate-user/detail/:id', canActivate: [AuthGuard], data: {breadcrumb: 'certificate User Detail'}, component: CertificateUserDetailComponent},
    {path: 'search-result', canActivate: [AuthGuard], data: {breadcrumb: 'search-result'}, component: MyCourseComponent},
    {
      path: 'course-route/detail/:id',
      canActivate: [AuthGuard],
      data: {breadcrumb: 'course-route/detail'},
      component: CourseRouteDetailComponent
    },
    // {path: 'survey-single', canActivate: [AuthGuard], data: {breadcrumb: 'Exam'}, component: SurveyQuestionComponent},
    {path: 'survey-single/:id', canActivate: [AuthGuard], data: {breadcrumb: 'Exam'}, component: SurveyQuestionComponent},
    {path: 'user-detail', canActivate: [AuthGuard], data: {breadcrumb: 'User detail'}, component: UserDetailComponent},
    {path: 'change-password', canActivate: [AuthGuard], data: {breadcrumb: 'Change password'}, component: ChangePasswordComponent},
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EndUserRoutingModule {
}
