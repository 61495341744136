import { CourseService } from './../../../shared/services/course.service';
import { Component, OnInit } from '@angular/core';
import { ToastrCustomService } from 'src/app/shared/services/toastr-custom.service';
import { ReportService } from '../../../shared/services/report.service';
import { FileService } from '../../../shared/services/file.service';

@Component({
  selector: 'app-result-exam',
  templateUrl: './result-exam.component.html',
})
export class ResultExamDTNComponent implements OnInit {

  courses = [];
  examId = "";
  reportType = "";

  constructor(
    private toastrService: ToastrCustomService,
    private reportService: ReportService,
    private fileService: FileService,
    private courseService: CourseService
  ) {}

  ngOnInit(): void {
    this.courseService.searchCourses({ coursetype: 2 }).subscribe((data) => {
      this.courses = data.body.results;
    });
  }

  onExport() {
    const params = {
      courseid: this.examId,
      reporttype: this.reportType,
    };
    this.reportService.exportExamReportDetail(params).subscribe(
      (res) => {
        window.open(this.fileService.getFileFromPathUrl(res.body.path));
      },
      (err) => {
        this.toastrService.handlerError(err);
      }
    );
  }
}
