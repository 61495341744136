<div class="content-wrapper">
	<!-- Content Header (Page header) -->
	<section class="content-header">
		<div class="container-fluid">
			<div class="row mb-2">
				<div class="col-sm-6">
					<h1>Thông tin khóa học : {{courseForm.value.fullname}}</h1>
				</div>
				<div class="col-sm-6">
					<ol class="breadcrumb float-sm-right">
						<li class="breadcrumb-item">
							<a href="#" routerLink="/admin">CMS</a>
						</li>
						<li class="breadcrumb-item active"><a [routerLink]="['/admin/course']">Quản lý khóa học</a></li>
						<li class="breadcrumb-item active">Thông tin khóa học</li>
					</ol>
				</div>
			</div>
		</div>
		<!-- /.container-fluid -->
	</section>
	<!-- Main content -->
	<section class="content">
		<div class="container-fluid">
			<!--phan danh sach khoa hoc-->
			<div class="row">
				<div class="col-12">
					<button class="btn btn-success"
						*ngIf="courseDetail.published !== 1 && checkRoleFunction('PUBLISH_COURSE',role) === true && !courseDetail.readonly"
						(click)="onPublishCourse()">Hiển thị khóa học</button>
					<button class="btn btn-default"
						*ngIf="courseDetail.published === 1 && checkRoleFunction('PUBLISH_COURSE',role) === true && !courseDetail.readonly"
						(click)="onUnPublishCourse()">Ẩn khóa học</button>
					<mat-tab-group dynamicHeight selectedIndex="1">
						<mat-tab>
							<ng-template mat-tab-label>
								<mat-icon class="example-tab-icon">infor</mat-icon>
								Thông tin khóa học
							</ng-template>
							<form role="form" [formGroup]="courseForm">
								<div class="container-fluid">
									<div class="row pl-5 pr-5 pt-4">
										<div class="col-6">
											<div class="form-group fullname-require">
												<label class="control-label">Tên khóa học</label><span
													class="text-danger"> *</span>
												<input type="text" class="form-control" placeholder="Tên đầy đủ"
													required="" data-placement="bottom" formControlName="fullname">
												<div
													*ngIf="courseForm.get('fullname')!.invalid &&(courseForm.get('fullname')!.dirty || courseForm.get('fullname')!.touched)">
													<small class="text-danger"
														*ngIf="courseForm.get('fullname')?.errors?.required">
														{{ 'error.validate.input_required' | translate }}
													</small>
													<small class="text-danger"
														*ngIf="courseForm.get('fullname')?.errors?.isBlank">
														{{ 'error.validate.input_required' | translate }}
													</small>
													<small class="text-danger"
														*ngIf="courseForm.get('fullname')?.errors?.maxlength">
														{{ 'error.validate.course.nameMaxlength' | translate }}
													</small>
												</div>
											</div>
										</div>
										<div class="col-6">
											<div class="form-group"><label>Danh mục chủ đề khóa học</label>
												<span class="text-danger"> *</span>
												<select class="form-control select2" style="width: 100%;"
													formControlName="categoryid">
													<option [value]="0" disabled selected>Chọn danh mục</option>
													<option *ngFor="let item of categoryMap | keyvalue"
														[value]="item.key">{{item.value}}</option>
												</select>
												<div
													*ngIf="courseForm.get('categoryid')!.invalid &&(courseForm.get('categoryid')!.dirty || courseForm.get('categoryid')!.touched)">
													<small class="text-danger"
														*ngIf="courseForm.get('categoryid')?.errors?.required">
														{{ 'error.validate.input_required' | translate }}
													</small>
												</div>
											</div>
										</div>
										<div class="col-6">
											<label class="mb-0">Thời gian đào tạo</label><span class="text-danger"> *</span>
											<div class="form-row">
													<div class="col-auto form-inline">
														<select class="form-control pr-0" style="width:60px;" formControlName="hourStartDate">
															<option *ngFor="let hour of hoursList" [value]="hour.value">
																{{hour.label}}</option>
														</select>
														<select class="form-control ml-1 pr-0" style="width:60px;" formControlName="minuteStartDate">
														<option *ngFor="let minute of minutesList"
															[value]="minute.value">{{minute.label}}</option>
													</select>
													</div>
													<div class="col-auto" style="padding-top:10px;">
														<mat-form-field color="blue" class="mr-3 mb-0 course-input">
															<mat-label>Từ ngày</mat-label>
															<input matInput [matDatepicker]="startdateModel"
                                                             [min]="!courseDetail.publishedat ? currentDate : (getDateFromUnix(courseDetail.startdate) > currentDate) ? currentDate : getDateFromUnix(courseDetail.startdate)" formControlName="startdate">
															<mat-datepicker-toggle matSuffix [for]="startdateModel">
															</mat-datepicker-toggle>
															<mat-datepicker #startdateModel></mat-datepicker>
														</mat-form-field>
													</div>
													<div class="col-auto form-inline">
														<select class="form-control ml-3 pr-0" style="width:60px;" formControlName="hourEndDate">
															<option *ngFor="let hour of hoursList" [value]="hour.value">
																{{hour.label}}</option>
														</select>
														<select class="form-control ml-1 pr-0" style="width:60px;" formControlName="minuteEndDate">
														<option *ngFor="let minute of minutesList"
															[value]="minute.value">{{minute.label}}</option>
													</select>
													</div>
													<div class="col-auto" style="padding-top:10px;">
														<mat-form-field  class="course-input mb-0">
															<mat-label>Đến ngày</mat-label>
															<input matInput [matDatepicker]="enddateModel"
																[min]="courseForm.value.startdate" formControlName="enddate">
															<mat-datepicker-toggle matSuffix [for]="enddateModel">
															</mat-datepicker-toggle>
															<mat-datepicker #enddateModel color="primary">
															</mat-datepicker>
														</mat-form-field>
													</div>
													<small class="text-danger" *ngIf="startTimeInvalid">
														{{ 'error.validate.start_date_invalid' | translate }}
													</small>
													<small class="text-danger" *ngIf="endTimeInvalid">
														{{ 'error.validate.end_date_invalid' | translate }}
													</small>

											</div>
										</div>
										<div class="col-6">
											<div class="form-group mt-4 pt-2" data-select2-id="39">
												<mat-checkbox formControlName="requireenglish" class="example-margin">Yêu cầu tiếng anh</mat-checkbox> <br>
                        <mat-checkbox formControlName="isopen" class="example-margin">Tự ghi danh</mat-checkbox> <br>
                        <mat-checkbox [disabled]="true" [checked]="courseDetail.badgeid" class="example-margin">Tạo chứng chỉ cho khóa học/ kỳ thi</mat-checkbox>
                      </div>
										</div>
										<div class="col-6">
											<label>Miêu tả khóa học</label>
											<textarea rows="4" class="overflow-auto" style="width: 100%; height: 15vh;"
												formControlName="summary">
              						  </textarea>
											<div
												*ngIf="courseForm.get('summary')!.invalid && (courseForm.get('summary')!.dirty || courseForm.get('summary')!.touched)">
												<small class="text-danger" *ngIf="courseForm.get('summary')?.errors">
													{{ 'error.validate.course.nameCourseMaxlength' | translate }}
												</small>
											</div>
										</div>
										<div class="col-6">
											<div class="form-group">
												<label>Ảnh đại diện khóa học</label><span class="text-danger"> *</span>
												<div class="input-group">
													<input type="file" class="custom-file-input form-control"
														*ngIf="formEnable"
														(change)="handleFileInput($event.target.files)">
													<label class="custom-file-label" *ngIf="formEnable">JPEG,
														PNG...</label>
												</div>
												<small class="text-danger" *ngIf="avatarCourseInvalid">
													{{ 'error.validate.input_valid' | translate }}
												</small>
												<img #imgPreview class="img-thumbnail">
											</div>
										</div>
									</div>
									<div class="card-footer text-center" *ngIf="!courseDetail.readonly && checkRoleFunction('EDIT_COURSE_INFO',role) === true">
										<button type="submit" class="btn btn-primary"
											*ngIf="!formEnable && courseDetail.published !== 1" (click)="onUpdateCourse()">Cập
											nhật</button>
										<button type="submit" class="btn btn-default mr-1"
											*ngIf="formEnable && courseDetail.published !== 1"
											(click)="cancelUpdateCourse()">Hủy</button>
										<button type="submit" class="btn btn-success mr-2" [disabled]="courseForm.invalid"
											*ngIf="formEnable && courseDetail.published !== 1"
											(click)="updateCourse()">Lưu lại</button>
									</div>
								</div>
							</form>
						</mat-tab>
						<mat-tab act>
							<ng-template mat-tab-label>
								<mat-icon class="example-tab-icon">book</mat-icon>
								Nội dung khóa học
							</ng-template>
							<app-course-content *ngIf="courseDetail.id" [courseDetail]="courseDetail"></app-course-content>
						</mat-tab>
						<mat-tab>
							<ng-template mat-tab-label>
								<mat-icon class="example-tab-icon">assignment_ind</mat-icon>
                {{isTeacher ? 'Danh sách học viên' : 'Thêm học viên'}}
							</ng-template>
							<app-add-user *ngIf="courseDetail.id" [courseDetail]="courseDetail">
							</app-add-user>
						</mat-tab>
						<mat-tab>
							<ng-template mat-tab-label>
								<mat-icon class="example-tab-icon">group</mat-icon>
                {{isTeacher ? 'Danh sách giảng viên' : 'Thêm giảng viên'}}
							</ng-template>
							<app-add-teacher *ngIf="courseDetail.id" [courseDetail]="courseDetail">
							</app-add-teacher>
						</mat-tab>
						<mat-tab>
							<ng-template mat-tab-label>
								<mat-icon class="example-tab-icon mr-1">school</mat-icon>
								Bài thi
							</ng-template>
							<div>
                <app-quiz-content
                  [courseDetail]="courseDetail"
                  *ngIf="showCreateQuiz == false && courseDetail.id"
                  (checkCreate)='check($event)'
                  (quizEdit)='showQuiz($event)'
                  (showCreateQuizz)='handleShowCreateQuiz($event)'>
                </app-quiz-content>
                <app-quiz-create
                  [courseDetail]="courseDetail"
                  [courseId]="courseId"
                  [quizInput]="quizOutput"
                  [checkCreate]="checkC"
                  *ngIf="showCreateQuiz == true"
                  (showCreateQuizz)='handleShowListQuiz($event)'>
                </app-quiz-create>
							</div>
						</mat-tab>
					</mat-tab-group>
				</div>
			</div>
		</div>
	</section>
</div>
