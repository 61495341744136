import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-un-register-course-confirm-modal',
  templateUrl: './un-register-course-confirm-modal.component.html',
  styleUrls: ['./un-register-course-confirm-modal.component.css']
})
export class UnRegisterCourseConfirmModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
  }

}
