import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-eufooter',
  templateUrl: './eufooter.component.html',
  styleUrls: ['./eufooter.component.css']
})
export class EufooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
