<div class="row">
  <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="modal-xl"
       style="display: block;" aria-modal="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title font-weight-bold">{{'user.forget_password' | translate}}</span>
          <button type="button" class="btn" data-dismiss="modal" aria-label="Close"
                  (click)="close()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <span class="fontSize__14"><span class="text-danger">*</span> Để đặt lại mật khẩu, hãy cung cấp tên đăng nhập hoặc email của bạn ở dưới và nhấn nút "Đặt lại".
            Nếu tên đăng nhập/ email bạn nhập là chính xác thì hệ thống đã gửi email xác nhận mật khẩu cho bạn kèm hướng dẫn về cách tái truy cập.
          </span>
          <form [formGroup]="usernameForm">
            <div class="find_username mt-3">
              <div class="padding-find">
                <span class="fontSize__14">Tìm kiếm bằng tên đăng nhập</span>
                <div class="input-group">
                  <input type="text"
                         class="form-control"
                         formControlName="username"
                         [placeholder]="'Nhập kí danh của bạn'">
                </div>
                <div
                  *ngIf="usernameForm.get('username')!.invalid && (usernameForm.get('username')!.dirty || usernameForm.get('username')!.touched)">
                  <small class="text-danger"
                         *ngIf="usernameForm.get('username')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                  <small class="text-danger"
                         *ngIf="usernameForm.get('username')?.errors?.isBlank">{{ 'error.validate.input_required' | translate }}</small>
                </div>
                <button class="btn btn-sm btn-primary mt-1" [disabled]="usernameForm.get('username')!.invalid" (click)="submit('username')">
                  Tìm kiếm
                </button>
              </div>
            </div>
          </form>
          <hr>
          <form [formGroup]="emailForm">
            <div class="find_email mt-3">
              <div class="padding-find">
                <span class="fontSize__14">Tìm kiếm bằng email</span>
                <div class="input-group">
                  <input type="text"
                         class="form-control"
                         formControlName="email"
                         [placeholder]="'Nhập thư điện tử của bạn'">
                </div>
                <div
                  *ngIf="emailForm.get('email')!.invalid && (emailForm.get('email')!.dirty || emailForm.get('email')!.touched)">
                  <small class="text-danger"
                         *ngIf="emailForm.get('email')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                  <small class="text-danger"
                         *ngIf="emailForm.get('email').errors?.pattern">{{ 'error.validate.email.pattern' | translate }}</small>
                </div>
                <button class="btn btn-sm btn-primary mt-1" [disabled]="emailForm.get('email')!.invalid" (click)="submit('email')">
                  Tìm kiếm
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-sm btn-secondary" (click)="close()">
            Hủy
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
