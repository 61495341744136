import {HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Course} from '../model/course.model';
import {AbstractService} from './abstract.service';
import CommonUtil from '../utils/common-util';
import {MOODLE_INFO, USER_INFO} from '../constants/base.constant';
import {map} from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import {Injectable} from '@angular/core';
import {UserInfo} from '../model/user-info.model';


type EntityResponseType = HttpResponse<Course>;
type EntityArrayResponseType = HttpResponse<Course[]>;

interface CoursePagingResponse {
  results: Course[];
  total: number;
}

interface UserPageResponse {
  results: UserInfo[];
  total: number;
}


@Injectable({providedIn: 'root'})
export class CourseService {

  constructor(private abstractService: AbstractService, private $localStorage: LocalStorageService) {

  }

  searchCourses(params: any): Observable<any> {
    const dataCourse = this.abstractService.getCommonInput('mobifone_course_list_courses');
    CommonUtil.appendDataToFormData(dataCourse, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: any) => res));
  }

  managedCourse(params: any): Observable<any> {
    const dataCourse = this.abstractService.getCommonInput('mobifone_course_list_managed_courses');
    CommonUtil.appendDataToFormData(dataCourse, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: any) => res));
  }

  createNewCourse(param: any, courseFile?: any, isExam?: boolean): Observable<EntityArrayResponseType> {
    const dataCourse = this.abstractService.getCommonInput('mobifone_course_create_course');
    if (courseFile) {
      dataCourse.append('repo_upload_file', courseFile, courseFile.name);
    }
    const courses: Course[] = [];
    courses.push(param);
    CommonUtil.appendDataToFormData(dataCourse, 'courses', courses);
    return this.abstractService.post<Course[]>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: EntityArrayResponseType) => res));
  }

  updateCourse(param: any, courseFile?: any, isExam?: boolean): Observable<EntityArrayResponseType> {
    const dataCourse = this.abstractService.getCommonInput('mobifone_course_update_course');
    if (courseFile && courseFile !== 'cancel') {
      dataCourse.append('repo_upload_file', courseFile, courseFile.name);
    }

    const courses: Course[] = [];
    courses.push(param);
    CommonUtil.appendDataToFormData(dataCourse, 'courses', courses);
    return this.abstractService.post<Course[]>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: EntityArrayResponseType) => res));

  }

  deleteCourse(courseIds: number[]): Observable<any> {
    const dataCourse = this.abstractService.getCommonInput('mobifone_course_delete_course');
    CommonUtil.appendArrayToFormData(dataCourse, 'courseids', courseIds);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: any) => res));
  }

  getCoursesInfo(courseId: number): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_course_detail_course');
    CommonUtil.appendDataToFormData(data, 'courseid', courseId);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  getCoursesInfoEnduser(courseId: number): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_course_detail_course_enduser');
    CommonUtil.appendDataToFormData(data, 'courseid', courseId);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  listStudentsOnCourse(params: any) {
    const data = this.abstractService.getCommonInput('mobifone_course_list_students_course');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  listStudentsOutCourse(params: any) {
    const data = this.abstractService.getCommonInput('mobifone_course_list_add_students_course');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  assignDepartmentPositionTocourse(courseid: number, departmentids: number[], positionids: number[]) {
    const data = this.abstractService.getCommonInput('mobifone_course_assign_department_position_course');
    CommonUtil.appendDataToFormData(data, 'courseid', courseid);
    CommonUtil.appendDataToFormData(data, 'departmentids', departmentids);
    CommonUtil.appendDataToFormData(data, 'positionids', positionids);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  assignStudentToCourse(courseid: number, studentId: number[]) {
    const data = this.abstractService.getCommonInput('mobifone_course_assign_student_course');
    CommonUtil.appendDataToFormData(data, 'courseid', courseid);
    CommonUtil.appendArrayToFormData(data, 'userids', studentId);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  unAssignStudentOnCourse(courseid: number, studentId: number[]) {
    const data = this.abstractService.getCommonInput('mobifone_course_unassign_student_course');
    CommonUtil.appendDataToFormData(data, 'courseid', courseid);
    CommonUtil.appendArrayToFormData(data, 'userids', studentId);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  publishCourse(courseid: number) {
    const data = this.abstractService.getCommonInput('mobifone_course_publish_course');
    CommonUtil.appendDataToFormData(data, 'courseid', courseid);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  unPublishCourse(courseid: number) {
    const data = this.abstractService.getCommonInput('mobifone_course_unpublish_course');
    CommonUtil.appendDataToFormData(data, 'courseid', courseid);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  listDepartmentOnCourse(courseid: any) {
    const data = this.abstractService.getCommonInput('mobifone_course_list_department_course');
    CommonUtil.appendDataToFormData(data, 'params', courseid);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  listPositionOnCourse(courseid: any) {
    const data = this.abstractService.getCommonInput('mobifone_course_list_position_course');
    CommonUtil.appendDataToFormData(data, 'params', courseid);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }
  getRoleUser(){
    return this.$localStorage.retrieve(USER_INFO.ROLES)[0].shortname;
  }

  getListCourseBySurveyId(params: any): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_survey_list_course');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  dropDragModule(params: any): Observable<any>{
    const dataCourse = this.abstractService.getCommonInput('mobifone_topic_drag_drop_module');
    CommonUtil.appendDataToFormData(dataCourse, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: any) => res));
  }

  setProgress(courseId: any, cmId: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_course_set_view_activity');
    CommonUtil.appendDataToFormData(data, 'courseid', courseId);
    CommonUtil.appendDataToFormData(data, 'cmid', cmId);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  getMyCourses(params?: any): Observable<HttpResponse<CoursePagingResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_course_enduser_my_course_student');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<CoursePagingResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<CoursePagingResponse>) => res));
  }

  getMyCourseTeaching(params?: any): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_course_enduser_my_course_teacher');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<Course[]>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponseType) => res));
  }

  getCourseInPosition(search?: string): Observable<EntityArrayResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_course_enduser_position_course');
    if (search) {
      data.append('search', search);
    }

    return this.abstractService.post<Course[]>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityArrayResponseType) => res));
  }

  overviewStudent() {
    const data = this.abstractService.getCommonInput('mobifone_course_total_overview_student');
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: any) => res));
  }

  getListUser(param): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_user_list_user_by_course_enduser');
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  getListExam(courseId: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_course_get_list_exam_enduser');

    const param = {
      courseid: courseId,
      getstatuscompletion: 1,
    };
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  getHistoryLerning(courseId: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_course_learning_history_course_enduser');

    const param = {
      courseid: courseId,
      sorttype: 'DESC',
      sortcolumn: 'id'
    };
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  getProgress(courseId: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_course_get_progress');
    CommonUtil.appendDataToFormData(data, 'courseid', courseId);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  getCourseByPosision(params: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_course_enduser_list_course_by_position');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  assignTeacher(userIds: any, courseId: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_course_assign_teacher_course');
    // const userIdArray : any = [];
    // userIdArray.push(userId);
    CommonUtil.appendDataToFormData(data, 'userids', userIds);
    CommonUtil.appendDataToFormData(data, 'courseid', courseId);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  unassignTeacher(userIds: any, courseId: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_course_unassign_teacher_course');
    const userIdArray: any = [];
    userIdArray.push(userIds);
    CommonUtil.appendDataToFormData(data, 'userids', userIds);
    CommonUtil.appendDataToFormData(data, 'courseid', courseId);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  getListTeacherOfCourse(params: any): Observable<HttpResponse<UserPageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_course_list_teachers_course');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<UserPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<UserPageResponse>) => res));
  }

  getListTeacherCanAdd(params: any): Observable<HttpResponse<UserPageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_course_list_add_teachers_course');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<UserPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<UserPageResponse>) => res));
  }

  getListCourseStudentCanRegister(params: any) {
    const data = this.abstractService.getCommonInput('mobifone_course_enduser_course_student_can_register');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<UserPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<UserPageResponse>) => res));
  }

  userRegisterCourse(courseId: number): Observable<HttpResponse<UserPageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_course_user_register_course');
    CommonUtil.appendDataToFormData(data, 'courseid', courseId);
    return this.abstractService.post<UserPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<UserPageResponse>) => res));
  }

  userUnRegisterCourse(courseId: number): Observable<HttpResponse<UserPageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_course_user_unregister_course');
    CommonUtil.appendDataToFormData(data, 'courseid', courseId);
    return this.abstractService.post<UserPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<UserPageResponse>) => res));
  }

}
