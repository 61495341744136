import { ToastrCustomService } from './../../shared/services/toastr-custom.service';
import { ReportService } from '../../shared/services/report.service';
import {Component, AfterViewInit, NgZone, OnInit} from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit, OnInit {

  public activeIndex: any = 0;
  overviewSystem:any
  barChartData = [
    {data: [0, 0, 0], label: 'Số khóa học'},
    {data: [0, 0, 0], label: 'Số học viên'},
  ];

  barChartData_exam = [
    {data: [150, 110, 150], label: 'Số học viên đạt'},
    {data: [80, 250, 100], label: 'Số học viên không đạt'},
    {data: [0, 0, 0], label: ''}
  ];

  public barChartOptions = {
    scaleShowVerticalLines: true,
    responsive: true
  };

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;
  public pieChartLabels: Label[] = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors: any[];

  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  constructor(
    private ngZone: NgZone,
    private reportService: ReportService,
    private toastrService: ToastrCustomService,
  ) {}

  ngOnInit(): void {
    this.reportCourseInSystem(0);  // category id = 0 => get all
    this.reportOverviewSystemData();
  }

  /**
   * @method ngAfterViewInit
   */
  ngAfterViewInit() {
  }

  reportCourseInSystem(categoryId: number) {
    this.reportService.reportCourseInSystem(1,categoryId).subscribe(
      res => {
      this.barChartLabels = [];
      for (let i = 0; i < res.body.length; i++) {
        this.barChartLabels.push(res.body[i].year);
        this.barChartData[0].data[i] = res.body[i].result.totalcourse;
        this.barChartData[1].data[i] = res.body[i].result.totalstudent;

      }
    },
    err => {
      this.toastrService.handlerError(err);
    });
  }

  _parseInt(val: any) {
    return parseInt(val);
  }

  reportOverviewSystemData(){
    this.reportService.overviewSystemData().subscribe(
      data => {
        this.overviewSystem = data.body;
        this.pieChartLabels = ['Học viên đạt', 'Học viên không đạt']
        this.pieChartData= [this.overviewSystem.completedstudent,this.overviewSystem.uncompletedstudent];
        this.pieChartPlugins = [pluginDataLabels];
        this.pieChartColors =
        [
          {
            backgroundColor: [
              '#86c7f3',
              '#ffa1b5'
            ]
          }
        ];
    },
    err => {
      this.toastrService.handlerError(err);
    });
  }

}
