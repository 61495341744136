<div class="col-12 mt-3 border">
  <!-- /.card-header -->
  <form [formGroup]="createSelectQuestion">
    <h6 class="mt-3">Nhập nội dung câu hỏi Select</h6>
    <div class="card-body" style="display: block;">
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Nhập nội dung <span class="text-danger">*</span></p>
        </div>
        <div class="col-md-9">
          <ckeditor [editor]="Editor" data="" formControlName="description" [config]="editorConfig"></ckeditor>
          <div
            *ngIf="createSelectQuestion.get('description')!.invalid &&(createSelectQuestion.get('description')!.dirty || createSelectQuestion.get('description')!.touched)">
            <small class="text-danger" *ngIf="createSelectQuestion.get('description')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Các câu trả lời có thể có <span class="text-danger">*</span></p>
        </div>

        <div class="col-md-9" formArrayName="allchoices">
          <div *ngFor="let choice of allchoices.controls;let i = index" class="row mt-2">
            <div [formGroupName]="i" class="col-md-12">
                <label>Đáp án {{i+1}}</label>
                <textarea formControlName="text" (keydown.enter)="onKeydown($event)" class="form-control"></textarea>
                <div
                  *ngIf="allchoices.controls[i].get('text')!.invalid &&(allchoices.controls[i].get('text')!.dirty || allchoices.controls[i].get('text')!.touched)">
                  <small class="text-danger" *ngIf="allchoices.controls[i].get('text')?.errors?.required">
                    {{ 'error.validate.input_required' | translate }}
                  </small>
                </div>
            </div>
            <div class="col-md-12 mt-2 text-left">
              <button type="submit" *ngIf="i > 0" (click)="removeAnswer(i)" class="btn btn-danger btn-sm"><i class="nav-icon fas fa-plus mr-1"></i>Xóa</button>
            </div>
          </div>
        </div>
        
        <div class="col-md-12 text-left">
          <button type="submit" (click)="addAnswer()" class="btn btn-secondary btn-sm"><i class="nav-icon fas fa-plus mr-1"></i>Thêm đáp án</button>
        </div>
      </div>
      <div class="row mt-3 border" *ngIf="checkChooseTemplate">
        <div class="col-md-3 mt-3 text-right">
          <p>Chọn đối tượng được khảo sát <span class="text-danger">*</span></p>
        </div>
        <div class="col-md-9 mt-3">

          <mat-radio-group formControlName="isteacher">
            <mat-radio-button value="1">Giảng viên</mat-radio-button>
            <mat-radio-button value="0">Khóa học</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </form>
</div>
