import { CourseCategory } from './../../../shared/model/course-category.model';
import CommonUtil from 'src/app/shared/utils/common-util';
import { CourseCategoryService } from '../../../shared/services/course-category.service';
import { ToastrCustomService } from './../../../shared/services/toastr-custom.service';
import { QuestionBankService } from '../../../shared/services/question-bank.service';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {CustomValidators} from '../../../shared/utils/custom-validators';
import { v1 as uuidv1 } from 'uuid';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';
import {NgxSpinnerService} from 'ngx-spinner';
import { TOPIC_CONST } from 'src/app/shared/constants/base.constant';

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.css']
})
export class QuestionDetailComponent implements OnInit {

  @Input() index: any;
  @Input() questionInput: any;
  @Input() questionTrueFalseInput: any;
  @Output() messageEvent = new EventEmitter<any>();
  questionTypeMulti = 'multichoice';
  questionTypeMultiset = 'multichoiceset';
  questionTypeTrueFalse = 'truefalse';
  questionForm: FormGroup;
  public Editor = ClassicEditor;
  questionEditorConfig = CKEDITOR_CONFIG.DEFAULT;
  answerEditorConfig = CKEDITOR_CONFIG.NOT_UPLOAD;

  editorData = '';
  anwserNumber = 0;
  isLevel1 = true;
  isLevel2 = false;
  isLevel3 = false;
  formEnable = false;
  isUpdate = false;
  categoryMap = new Map();
  courseCategoryList: CourseCategory[] = [];
  btnSaveDisable = false;
  TOPIC_CONST = TOPIC_CONST;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private questionBankService: QuestionBankService,
    private toastrService: ToastrCustomService,
    private courseCategoryService: CourseCategoryService,
    private spinner: NgxSpinnerService,
  ) {}


  ngOnInit(): void {
    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      this.courseCategoryList = response.body;
      CommonUtil.convertCategoryListToMap(this.courseCategoryList, this.categoryMap);
    });
    this.initForm();
    if (!this.formEnable) { this.questionForm.disable(); }

    this.questionForm.valueChanges.subscribe($ques => {
      if (!$ques.questiontext || ($ques.answer && !$ques.answer[0]?.text)) {
        this.btnSaveDisable = true;
        return;
      }
      for (let i = 0; i < $ques?.answer?.length; i++) {
        if (!$ques?.answer[i]?.text || !$ques?.answer[i]?.grade) {
          this.btnSaveDisable = true;
          return;
        }
      }
      this.btnSaveDisable = false;
    });

    this.prepareQuestiontext();
  }

  prepareQuestiontext(){
    this.spinner.show();
    this.questionBankService.prepareQuestiontextForUpdate(this.questionInput.id).subscribe(
      res => {
        this.questionForm.patchValue({
          questiontext: res.body.questiontext
        });
        this.spinner.hide();
      }, error => {
        this.toastrService.handlerError(error);
        this.spinner.hide();
      }
    );
  }

  initForm() {
    if (this.questionInput.qtype === this.questionTypeMulti || this.questionInput.qtype === this.questionTypeMultiset) {
      this.questionForm = this.fb.group({
        id: [this.questionInput.id],
        questiontext: [this.questionInput.questiontext, [Validators.required, CustomValidators.notBlankValidator]],
        defaultmark: [this.questionInput.defaultmark],
        answernumbering: [this.questionInput.answernumbering],
        level: [this.questionInput.level, Validators.required],
        single: [this.questionInput.single],
        categoryid: [this.questionInput.categoryid],
        answer: this.fb.array([])
      });
      this.questionInput.answer.forEach((answer) => {
        this.addAnswer(answer);
      });
    } else {
      this.questionForm = this.fb.group({
        id: [this.questionInput.id],
        questiontext: [this.questionInput.questiontext, [Validators.required, CustomValidators.notBlankValidator]],
        level: [this.questionInput.level, Validators.required],
        defaultmark: [this.questionInput.defaultmark],
        answercorrect: [this.questionInput.answercorrect],
        categoryid: [this.questionInput.categoryid]
      });
    }
    this.changeLevel(this.questionInput.level);
  }

  get answer(): FormArray {
    return this.questionForm.get('answer') as FormArray;
  }

  newAnwser(answer?: any): FormGroup {
    const form = this.fb.group({
      text: ['', [Validators.required, CustomValidators.notBlankValidator]],
      grade: ['0.0']
    });
    if (answer) {
      form.controls.text.setValue(answer.text);
      if (answer.grade.toString().indexOf('.') === -1) {
        form.controls.grade.setValue(answer.grade.toFixed(1));
      }
      else { form.controls.grade.setValue(answer.grade); }
    }
    return form;
  }

  addAnswer(answer?: any) {
    this.anwserNumber += 1;
    this.answer.push(this.newAnwser(answer));
  }

  removeAnswer(i: number) {
    this.answer.removeAt(i);
  }

  onChangeCkeditorAnswer({editor}: ChangeEvent, indexAnswer?: number) {
    if (!editor) {
      return;
    }
    const data = editor.getData();
    if (data) {
      this.questionForm.value.answer[indexAnswer].text = data;
    }
  }

  updateQues() {
    if (this.questionForm.value.questiontext.search('<img') !== -1) {
      this.questionForm.value.questiontext = this.questionForm.value.questiontext.replaceAll('<img', '<img class="img-fluid"');
    }
    this.questionForm.value.answer?.forEach(ans => {
      if (ans.text.search('<img') !== -1) {
        // nếu ảnh chưa có alt thì add thêm, nếu ảnh đã có alt thì replate để tránh gây lỗi khi làm bài thi có 2 ảnh có alt bị trùng
        if (ans.text.search('alt="') === -1) {
          ans.text = ans.text.replaceAll('<img', '<img class="img-fluid" alt="' + uuidv1() + '"');
        } else {
          ans.text = ans.text.replaceAll('<img', '<img class="img-fluid"').replaceAll('></figure>', ' alt="' + uuidv1() + '"></figure>');
        }
      }
    });
    this.questionBankService.updateQuestion(this.questionForm.value).subscribe(
      res => {
        this.toastrService.success(`common.noti.update_success`);
        this.activeModal.close('ok');
      },
      err => {
        this.toastrService.handlerError(err);
    });
  }

  changeLevel(levelid) {
    if (levelid === 1) {
      this.isLevel1 = true;
      this.isLevel2 = false;
      this.isLevel3 = false;
    } else if (levelid === 2) {
      this.isLevel1 = false;
      this.isLevel2 = true;
      this.isLevel3 = false;
    } else {
      this.isLevel1 = false;
      this.isLevel2 = false;
      this.isLevel3 = true;
    }
    this.questionForm.controls.level.setValue(levelid);
  }

  onUpdateQues() {
    this.questionForm.enable();
    this.formEnable = true;
  }

  cancelUpdateQues() {
    this.questionForm.disable();
    this.formEnable = false;
    this.changeLevel(this.questionInput.level);
    this.initForm();
  }


}
