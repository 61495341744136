import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import CommonUtil from 'src/app/shared/utils/common-util';
import { ToastrCustomService } from 'src/app/shared/services/toastr-custom.service';
import { SurveyService } from '../../../shared/services/survey.service';
import { Survey } from 'src/app/shared/model/survey';
import { Router } from '@angular/router';
import {LIST_HOURS, LIST_MINUTES} from '../../../shared/constants/base.constant';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-survey-single-create',
  templateUrl: './survey-single-create.component.html',
  styleUrls: ['./survey-single-create.component.css']
})
export class SurveySingleCreateComponent implements OnInit {
  currentDate: Date = new Date();
  public Editor = ClassicEditor;
  createSurveyForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(200)]],
    // introeditor: ['', [Validators.required, Validators.maxLength(255)]],
    enddate: ['0', Validators.required],
    hoursEndDate: ['', Validators.required],
    minuteEndDate: ['', Validators.required],
    qtype: [1],
    startdate: ['0', Validators.required],
    hourStartDate: ['', Validators.required],
    minuteStartDate: ['', Validators.required],
  });

  survey: Survey = {
    name: '',
    sectionid: null,
    introeditor: '',
    ismaster: null,
    published: null,
    publishedat: null,
    startdate: null,
    enddate: '',
    qtype: 1,
    id: null,
    questionnaireid: null,
    intro: '',
    cmid: null,
    listquestion: null,
    course: null,
    isactive: null,
    refdepartmentid: null,
    activatedat: null,
    departmentid: null,
    selected: null,
    isteacher: null,
    sid: null
  };

  hoursList = LIST_HOURS;
  minutesList = LIST_MINUTES;

  constructor(public activeModal: NgbActiveModal,
              private fb: FormBuilder,
              private toastrService: ToastrCustomService,
              private surveyService: SurveyService,
              private router: Router,
              private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  createNewSurvey() {
    if (this.createSurveyForm.invalid) {
      CommonUtil.markFormGroupTouched(this.createSurveyForm);
      return;
    }

    this.survey.name = this.createSurveyForm.value.name;
    this.survey.qtype = this.createSurveyForm.value.qtype;
    this.survey.isactive = this.createSurveyForm.value.isactive;
    this.survey.introeditor = this.createSurveyForm.value.introeditor;

    this.survey.enddate = CommonUtil.convertDateToTime(
      this.createSurveyForm.value.enddate,
      this.createSurveyForm.value.hoursEndDate,
      this.createSurveyForm.value.minuteEndDate);

    this.survey.startdate = CommonUtil.convertDateToTime(
      this.createSurveyForm.value.startdate,
      this.createSurveyForm.value.hourStartDate,
      this.createSurveyForm.value.minuteStartDate);

    if (this.survey.enddate <= this.survey.startdate) {
      this.toastrService.error('error.enddatebeforestartdate');
      return;
    }
    const current = (new Date()).getTime() / 1000;
    if (this.survey.startdate < current) {
      this.spinner.hide();
      this.toastrService.error('error.startdatebeforecurrentdate');
      return;
    }

    this.spinner.show();
    this.surveyService.createSingleSurvey(this.survey).subscribe(res => {
      this.survey = res.body;
      this.activeModal.dismiss();
      this.router.navigate([`/admin/survey/single-detail/${this.survey.id}`]);
      this.toastrService.success('common.noti.create_success');
      this.spinner.hide();
    }, err => {
      this.toastrService.handlerError(err);
      this.spinner.hide();
    }
    );
  }
}
