<div class="row d-block">
  <h5>{{courseModule.name}}</h5>
  <small class="text-dark">Giảng viên: {{teachers?.length > 100 ? (teachers | slice:0:100) : teachers}}</small>
</div>
<br>
<div [innerHTML]="courseModule.intro"></div>

<div class="row mt-3" >
  <div class="col-3">
    <ejs-treeview #scormScoTree [fields]="scormScoTreeData" (nodeSelected)='nodeSelected($event)'></ejs-treeview>
  </div>
  <div class="col-8">
    <iframe #scormCourse [src]="scormContentUrl" width="750" height="600"></iframe>
  </div>
</div>
