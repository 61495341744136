<div class="modal-header">
  <h5 class="modal-title">Tìm kiếm nâng cao</h5>
  <button type="button" class="btn btn-default" data-card-widget="collapse"
          (click)="activeModal.dismiss('close')"><i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>Chọn chủ đề</label>
        <select class="custom-select" [(ngModel)]="categoryId" name="single">
          <option value="0">Tất cả</option>
          <option *ngFor="let item of categoryMap | keyvalue"
                  [value]="item.key">{{item.value}}</option>
        </select>
      </div>
      <div class="form-group mt-3">
        <label>Dạng câu hỏi</label>
        <select class="custom-select" [(ngModel)]="qtype" name="single">
          <option value="">Tất cả</option>
          <option value="multichoice">{{'qtype.multichoice' | translate}}</option>
          <option value="multichoiceset">{{'qtype.multichoiceset' | translate}}</option>
          <option value="truefalse">{{'qtype.truefalse' | translate}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Chủ đề câu hỏi</label><br/>
        <select name="level" class="form-control" (change)="searchLevel($event.target.value)">
          <option value>Chọn chủ đề câu hỏi</option>
          <option [value]="topic.value" *ngFor="let topic of TOPIC_CONST">
            {{topic.lable}}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="resetSearch()">Đặt lại</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('close')">Đóng</button>
  <button type="button" class="btn btn-primary" (click)="onSearchQuestion()">Tìm kiếm</button>
</div>
