<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close('confirm')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{body}}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger"
          (click)="activeModal.close('confirm')">{{confirmButton}}</button>
</div>
