import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {MOODLE_INFO} from '../constants/base.constant';
import {map} from 'rxjs/operators';

type EntityResponse = HttpResponse<any>;

@Injectable({
  providedIn: 'root'
})
export class ShowContentService {

  constructor(private abstractService: AbstractService) {
    
  }

  getFilebyId(moduleid): Observable<EntityResponse> {
    const data = this.abstractService.getCommonInput('mobifone_topic_module_detail');
    data.append('coursemoduleid', moduleid);
    const returnvalue = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityResponse) => res));
    return returnvalue;
  }

  getFileUrl(fileid, filename) {
    const data = MOODLE_INFO.FILE_URL + fileid + '/mod_resource/content/0/' + filename;
    return data;
  }
}
