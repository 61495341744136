<div class="content-wrapper">
    <!-- Main content -->
    <section class="content-header">
      <div class="container-fluid">
          <div class="row">
            <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-info"><i class="fas fa-user-alt"></i></span>

                <div class="info-box-content">
                  <span class="info-box-text">Số học viên</span>
                  <span class="info-box-number">{{overviewSystem?.totalstudent ? overviewSystem?.totalstudent : 0}}</span>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <!-- /.col -->
            <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-success"><i class="fas fa-book"></i></span>

                <div class="info-box-content">
                  <span class="info-box-text">Số khóa học</span>
                  <span class="info-box-number">{{overviewSystem?.totalcourse ? overviewSystem?.totalcourse : 0}}</span>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <!-- /.col -->
            <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-warning"><i class="far fa-copy"></i></span>

                <div class="info-box-content">
                  <span class="info-box-text">Số kỳ thi</span>
                  <span class="info-box-number">{{overviewSystem?.totalexam ? overviewSystem?.totalexam : 0}}</span>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <!-- /.col -->
            <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-danger"><i class="far fa-star"></i></span>

                <div class="info-box-content">
                  <span class="info-box-text">Số giảng viên</span>
                  <span class="info-box-number">{{overviewSystem?.totalteacher ? overviewSystem?.totalteacher : 0}}</span>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <!-- /.col -->
          </div>

      </div>
      <div class="row">
        <div class="col-6">
            <div class="card card-default">
                <div class="card-header">
                    <h6>Thống kê khóa học năm</h6>
                </div>
                <div class="card-body">
              <canvas baseChart
              [datasets]="barChartData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [chartType]="barChartType">
            </canvas>
            </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card card-default">
                <div class="card card-header">
                    <h6>Thống kê học viên đạt kỳ thi</h6>
                </div>
                <div class="card-body">
                  <div class="card-body">
                    <canvas baseChart
                    [data]="pieChartData"
                    [labels]="pieChartLabels"
                    [chartType]="pieChartType"
                    [options]="pieChartOptions"
                    [plugins]="pieChartPlugins"
                    [legend]="pieChartLegend"
                    [colors]="pieChartColors">
                  </canvas>
                    </div>
                </div>
            </div>
        </div>
      </div>
      </section>
</div>
