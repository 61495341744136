import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';

@Component({
  selector: 'app-multichoice-survey',
  templateUrl: './multichoice-survey.component.html',
  styleUrls: ['./multichoice-survey.component.css']
})
export class MultichoiceSurveyComponent implements OnInit {
  public Editor = ClassicEditor;

  editorConfig = CKEDITOR_CONFIG.NOT_UPLOAD;

  constructor() { }

  ngOnInit(): void {
  }

}
