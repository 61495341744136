<div class="col-12 mt-3 border">
  <!-- /.card-header -->
  <form [formGroup]="createRateQuestion">
    <h6 class="mt-3">Nhập nội dung câu hỏi Rate</h6>
    <div class="card-body" style="display: block;">
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Nhập nội dung <span class="text-danger">*</span></p>
        </div>
        <div class="col-md-9">
          <ckeditor [editor]="Editor" data="" formControlName="description" [config]="editorConfig"></ckeditor>
          <div
            *ngIf="createRateQuestion.get('description')!.invalid &&(createRateQuestion.get('description')!.dirty || createRateQuestion.get('description')!.touched)">
            <small class="text-danger" *ngIf="createRateQuestion.get('description')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Số sao</p>
        </div>
        <div class="col-md-9">
          <input type="number" min="1" max="10" placeholder="5" formControlName="length"/>
          <div
            *ngIf="createRateQuestion.get('length')!.invalid &&(createRateQuestion.get('length')!.dirty || createRateQuestion.get('length')!.touched)">
            <small class="text-danger" *ngIf="createRateQuestion.get('length')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
            <small class="text-danger" *ngIf="createRateQuestion.get('length')?.errors?.rangeValue">
              {{ 'error.validate.quiz.rangeValue' | translate }}
            </small>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Nhãn với sao tương ứng</p>
        </div>
        <div class="col-md-9">
          <textarea placeholder="Nhãn tương ứng" formControlName="allnameddegrees"></textarea>
        </div>
      </div>
      <div class="row mt-2" *ngIf="checkChooseTemplate">
        <div class="col-md-3 text-right">
          <p>Chọn đối tượng được khảo sát <span class="text-danger">*</span></p>
        </div>
        <div class="col-md-9">

          <mat-radio-group formControlName="isteacher">
            <mat-radio-button   value="1" >Giảng viên</mat-radio-button>
            <mat-radio-button   value="0" >Khóa học</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 text-center">
    </div>
  </form>
</div>



