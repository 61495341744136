import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-enduser',
  templateUrl: './end-user.component.html',
  styleUrls: ['./end-user.component.css']
})
export class EndUserComponent implements OnInit {

  title = 'lms-frontend';
  notShowHeader: boolean = false;
  notShowFooter: boolean = false;
  constructor(private zone: NgZone, private router: Router, private route: ActivatedRoute,) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/exam-details/')) {
          this.notShowHeader= true;
          this.notShowFooter= true;
        }
        // else if(event.url.includes('/exam-lesson/') || event.url.includes('/my-course-detail/') || event.url.includes('/exam-list') || event.url.includes('/my-course')|| event.url.includes('/certificateUser')|| event.url.includes('/my-course')|| event.url.includes('/course-route')){
        //   this.notShowHeader = false;
        //   this.notShowFooter= true;
        // } 
        else {
          // console.log(event.url);
          this.notShowFooter= false;
          this.notShowHeader= false;
        }
      }
    });
  }
  ngOnInit() {
  }

}
