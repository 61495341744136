<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- Brand Logo -->
  <a href="#" class="brand-link">
    <img src="/assets/LMS_Logo.png"
         alt="AdminLTE Logo"
         class="brand-image img-circle" style="opacity: .8">

    <span class="brand-text font-weight-light"><img src="assets/logo2.png" class="" alt=""></span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
     <div class="image">
       <img src="{{userInfo.userpictureurl}}" class="img-circle elevation-2" alt="User Image">
     </div>
      <div class="info">
        <a class="d-block">{{userInfo.firstname }} {{userInfo.lastname}}</a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->

        <li *ngIf="checkCourse" class="nav-header">{{ 'admin.course_group' | translate }}</li>
        <li *ngIf="course" class="nav-item has-treeview">
          <a [routerLink]="['/admin/course']" [routerLinkActive]="['active']" class="nav-link">
            <i class="nav-icon fas fa-book-reader"></i>
            <p>{{ 'admin.course' | translate }}</p>
          </a>
        </li>
        <li  *ngIf="courseCategory"class="nav-item has-treeview">
          <a [routerLink]="['/admin/course-category']" [routerLinkActive]="['active']" class="nav-link">
            <i class="nav-icon fas fa-folder-open"></i>
            <p>{{ 'admin.course_category' | translate }}</p>
          </a>
        </li>
        <li *ngIf="exam" class="nav-item has-treeview">
          <a [routerLink]="['/admin/exam']" [routerLinkActive]="['active']" class="nav-link">
            <i class="nav-icon fas fa-book"></i>
            <p>{{ 'admin.exam' | translate }}</p>
          </a>
        </li>
        <li *ngIf="badge" class="nav-item has-treeview">
          <a [routerLink]="['/admin/badge']" [routerLinkActive]="['active']" class="nav-link">
            <i class="nav-icon fas fa-id-badge"></i>
            <p>{{ 'admin.badge' | translate }}</p>
          </a>
        </li>
        <li *ngIf="survey" class="nav-item has-treeview">
          <a [routerLink]="['/admin/survey']" [routerLinkActive]="['active']" class="nav-link">
            <i class="nav-icon fas fa-question"></i>
            <p>{{ 'admin.survey' | translate }}</p>
          </a>
        </li>
        <li *ngIf="questionBank" class="nav-item has-treeview">
          <a [routerLink]="['/admin/question-bank']" [routerLinkActive]="['active']" class="nav-link">
            <i class="nav-icon fa fa-university"></i>
            <p>{{ 'admin.questionbank' | translate }}</p>
          </a>
        </li>
        <li *ngIf="checkDepartment" class="nav-header">{{ 'admin.department_user' | translate }}</li>
        <li class="nav-item has-treeview">
          <a *ngIf="department" [routerLink]="['/admin/department']" [routerLinkActive]="['active']" class="nav-link">
            <i class="nav-icon fas fa-building"></i>
            <p>{{ 'admin.department' | translate }}</p>
          </a>
        </li>
        <li *ngIf="position" class="nav-item has-treeview">
          <a [routerLink]="['/admin/position']" [routerLinkActive]="['active']" class="nav-link">
            <i class="nav-icon fas fa-user-tag"></i>
            <p>{{ 'admin.program_position' | translate }}</p>
          </a>
        </li>
        <li *ngIf="user" class="nav-item has-treeview">
          <a [routerLink]="['/admin/user']" [routerLinkActive]="['active']" class="nav-link">
            <i class="nav-icon fas fa-user"></i>
            <p>{{ 'admin.user' | translate }}</p>
          </a>
        </li>
        <li class="nav-header">{{ 'admin.notification' | translate }}</li>
        <li *ngIf="notification" class="nav-item has-treeview">
          <a [routerLink]="['/admin/notification']" [routerLinkActive]="['active']" class="nav-link">
            <i class="nav-icon fas fa-bell"></i>
            <p>{{ 'admin.notification_user' | translate }}</p>
          </a>
        </li>
        <li *ngIf="role" class="nav-item has-treeview">
          <a [routerLink]="['/admin/role']" [routerLinkActive]="['active']" class="nav-link">
            <i class="nav-icon fas fa-robot"></i>
            <p>{{ 'admin.role' | translate }}</p>
          </a>
        </li>
        <li class="nav-header" *ngIf="checkReport">{{ 'admin.report' | translate }}</li>
        <li  *ngIf="report" class="nav-item has-treeview menu-open">
          <a href="javascript:;" role="button" class="nav-link">
            <i class="nav-icon fas fa-file-contract"></i>
            <p>
             Quản lý báo cáo
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['/admin/dashboard']" [routerLinkActive]="['active']" class="nav-link">
                <i class="fas fa-angle-right nav-icon"></i>
                <p>Dashboard</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/admin/result-exam']" [routerLinkActive]="['active']" class="nav-link">
                <i class="fas fa-angle-right nav-icon"></i>
                <p>Kết quả thi</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/admin/report']" [routerLinkActive]="['active']" class="nav-link">
                <i class="fas fa-angle-right nav-icon"></i>
                <p>Báo cáo tổ chức khóa học</p>
              </a>
            </li>
            <li class="nav-item">
              <a  [routerLink]="['/admin/report-student']" [routerLinkActive]="['active']" class="nav-link">
                <i class="fas fa-angle-right nav-icon"></i>
                <p>Báo cáo tiến độ học viên</p>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a [routerLink]="['/admin/report-exam']" [routerLinkActive]="['active']" class="nav-link">
                <i class="fas fa-angle-right nav-icon"></i>
                <p>Báo cáo kỳ thi</p>
              </a>
            </li> -->
          </ul>
        </li>

      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>

