<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Đoàn TW</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="assets/ladipage/img/favicon.png" rel="icon">
  <link href="assets/ladipage/img/apple-touch-icon.png" rel="apple-touch-icon">


  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- =======================================================
  * Template Name: Vesperr - v2.3.1
  * Template URL: https://bootstrapmade.com/vesperr-free-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>
  <!-- <div class="logodoan">
  <img src="assets/img/flagvn.png" alt="">
</div> -->
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top d-flex align-items-center">
    <div class="container d-flex align-items-center">
      <div class="logo">
        <!-- <h1><a href="#"><span style="opacity: 0.1;">logo</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
      </div>
      <div class="mr-auto mt-0 p-0">
        <!-- <img src="assets/img/doan.png" width="70%" alt=""> -->
        <!-- <h1 class="text-light"><a href="index.html"><span><img src="assets/img/doan.png" width="90%" height="90%" alt=""></span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
      </div>
      <nav class="nav-menu d-none d-lg-block">
        <ul>
          <li class="active"><a href="#index.html"></a></li>
          <li><a pageScroll [pageScrollOffset]="70" [pageScrollDuration]="500"   href="#about">Giới thiệu</a></li>
          <li><a pageScroll [pageScrollOffset]="60"  [pageScrollDuration]="500"  href="#dashboard">Bảng thống kê</a></li>
          <li><a (click)="openDocumentPopup()" style="cursor: pointer;"><i class="fas fa-download mr-2"></i>Tài liệu
              tham khảo</a></li>
          <li *ngIf="!isLogin"><a pageScroll  [pageScrollOffset]="60"  [pageScrollDuration]="500" href="#contact">Đăng ký tham gia</a></li>
          <!-- <li *ngIf="isLogin" class="get-started mt-1"><a (click)="onLogin()" style="cursor:pointer;">Vào thi</a></li> -->
          <li *ngIf="isLogin">
            <div ngbDropdown class="d-inline-block dropdown show">
              <a class="dropdown-toggle" type="button" data-toggle="dropdown" id="dropdownMenuLink" ngbDropdownToggle>
                <i class="fas fa-user-circle" style="font-size: 20pt; color: #ece436; line-height: 20pt;"></i>
              </a>
              <div class="dropdown-menu p-1" ngbDropdownMenu aria-labelledby="dropdownMenuLink">
                <p class="dropdown-item sfbold p-3">{{currentUser.username}}</p>
                <hr>
                <a class="dropdown-item text-dark pl-2 pr-2" style="cursor: pointer;" (click)="openUpdateUserPopup()"><i class="fas fa-edit"></i> Sửa thông tin cá nhân</a>
                <a class="dropdown-item text-danger pl-2 pr-2" style="cursor: pointer;" (click)="logout()"><i class="fas fa-sign-out-alt"></i>Đăng xuất</a>
              </div>
            </div>
          </li>
          <li *ngIf="!isLogin" class="get-started mt-1"><a (click)="onLogin()" style="cursor:pointer;">Đăng nhập</a>
          </li>
        </ul>
      </nav><!-- .nav-menu -->
    </div>
  </header><!-- End Header -->
  <div class="container fixmobile">
    <nav class="menu">
      <a pageScroll href="#about" class="menu-item">
        <span class="material-icons">info</span>
        <span class="menu-item-label">Giới thiệu</span>
      </a>
      <a pageScroll href="#dashboard" class="menu-item">
        <span class="material-icons">leaderboard</span>
        <span class="menu-item-label">Thống kê</span>
      </a>
      <a (click)="openDocumentPopup()" class="menu-item">
        <span class="material-icons"> description</span>
        <span class="menu-item-label">Tài liệu</span>
      </a>
      <a *ngIf="!isLogin" pageScroll href="#contact" class="menu-item">
        <span class="material-icons">how_to_reg</span>
        <span class="menu-item-label">Đăng ký</span>
      </a>
      <a *ngIf="!isLogin" (click)="onLogin()" class="menu-item">
        <span class="material-icons">account_circle</span>
        <span class="menu-item-label">Đăng nhập</span>
      </a>
      <a *ngIf="isLogin" class="menu-item" (click)="oncChangeShowAccount()">
        <span class="material-icons">how_to_reg</span>
        <span class="menu-item-label">Tài khoản</span>
      </a>
    </nav>
    <div class="row bg-white shadow-lg" *ngIf="isShowAccount"  data-aos="fade-up" data-aos-delay="10"
     style="position: fixed; width: 90%; margin: auto;  bottom:120px;  transition: 0.5s ease; padding:10px; border-radius: 20px;">
      <div class="col-12">
        <p>{{currentUser.username}}</p>
        <hr>
        <div class="w-100 mb-3">
          <a  (click)="openUpdateUserPopup()"> Sửa thông tin cá nhân</a>
        </div>
        <div class="w-100 mb-3"><a class="text-danger" (click)="logout()">Đăng xuất</a>
        </div>
      </div>
    </div>
  </div>
  <section id="hero" class="d-flex align-items-center p-0 bgheader" style="margin-top: 0px; position: relative; height: 500px;">
    <ul class="bg-bubbles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    <div class="container" style="position: absolute; bottom: 10px;">

      <div class="row">
        <div
          class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 aos-init aos-animate"
          data-aos="fade-up" data-aos-delay="200">



          <!-- <div *ngIf="!isLogin" data-aos="fade-up" data-aos-delay="800"
            class="aos-init text-center mt-3 w-100 aos-animate">
            <a pageScroll href="#contact" class="btn-danger btn rounded scrollto radius20 p-2 shadow-lg mb-3"
              style="width: 200px;"><i class="icofont-rounded-right mr-2"></i>Đăng ký tham gia<i
                class="icofont-rounded-left ml-2"></i></a>
          </div> -->
          <div class="d-flex">
            <div *ngIf="isLogin" data-aos="fade-up" data-aos-delay="800"
              class="aos-init text-center mt-3 w-100 aos-animate">
              <a (click)="onLogin()" class="btn-danger text-white btn rounded radius20 p-2 shadow-lg mb-3"
                style="width: 150px;"><i class="icofont-rounded-right mr-2"></i>Vào thi<i
                  class="icofont-rounded-left ml-2"></i></a>
              <button (click)="showPopupExamResult()" class="btn-warning btn radius20 rounded p-2 shadow-lg mb-3 ml-3"
                style="width: 150px;"><i class="icofont-rounded-right mr-2"></i>Kết quả thi<i
                  class="icofont-rounded-left ml-2"></i></button>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-5 order-1 order-lg-2 hero-img aos-init aos-animate text-center" data-aos="zoom-in"
          data-aos-delay="200">
          <img src="../../assets/ladipage/img/olympic1.png" class="animated leftdoan" style="margin-top:120px;" alt="">
        </div> -->
      </div>
    </div>
    <div class="dang_ky" *ngIf="!isLogin">
      <a href="/home#contact">
        <img src="../../assets/ladipage/img/dang_ky.png" alt="">
      </a>
    </div>

  </section><!-- End Hero -->

  <main id="main">
    <!-- ======= About Us Section ======= -->
    <!-- <section id="about" class="about">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Giới thiệu</h2>
        </div>

        <div class="row content">
          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="150">
            <p style="line-height: 30px; text-align: justify;">
              Thực hiện chương trình công tác Đoàn và phong trào thanh niên năm 2021, nhằm triển khai có hiệu quả đề án <span class="sfbold text-danger">“Nâng cao năng lực tiếng Anh cho thanh thiếu niên Việt Nam giai đoạn 2018 – 2022”</span> của Trung ương Đoàn TNCS Hồ Chí Minh, Ban Thường vụ Đoàn Khối tổ chức Cuộc thi Olympic tiếng Anh Khối Doanh nghiệp Trung ương lần thứ III, năm 2021
            </p>
            <ul>
              <li style="line-height: 30px; text-align: justify;"><i class="ri-check-double-line"></i>Cuộc thi nhằm khơi dậy, phát huy niềm đam mê học tập, nâng cao trình độ ngoại ngữ trong cán bộ Đoàn, đoàn viên, thanh niên Khối Doanh nghiệp Trung ương; góp phần đẩy mạnh các phong trào hành động cách mạng, các chương trình đồng hành với thanh niên vì sự phát triển bền vững của doanh nghiệp và đất nước.
              </li>
              <li style="line-height: 30px; text-align: justify;"><i class="ri-check-double-line"></i> Tạo sân chơi lành mạnh, tăng cường
                giao lưu, trao đổi kinh nghiệm
                giữa cán bộ, đoàn viên thanh niên các đơn vị trong Khối.
              </li>

            </ul>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 text-center" data-aos="fade-up" data-aos-delay="300">
            <img src="../../assets/ladipage/img/sv.png" class="img-fluid" style="width:100%;" alt="">
          </div>
        </div>

      </div>
    </section> -->
    <!-- End About Us Section -->


    <!-- <section id="testimonials" class="testimonials pt-0 pb-0 section-bg khlz" style="height: 150px; background-size: 100% 100%;">
      <div class="container">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="9"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="10"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="11"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="12"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="testimonial-item">
                <h3>
                  Youth of state-owned enterprise's bloc<br>
                  "innovation - vanguard - unity - Development"
                </h3>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <h3>
                    Tuổi trẻ khối doanh nghiệp trung ương <br>
                    "Sáng tạo - tiên phong - đoàn kết - phát triển"
                  </h3>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <h3>
                    YOUTH OF STATE-OWNED ENTERPRISES’BLOC<br>
                    PIONEERING IN INTERNATIONAL INTEGRATION
                  </h3>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <h3>
                    Tuổi trẻ khối doanh nghiệp trung ương<br>
                    Xung kích trong hội nhập quốc tế
                  </h3>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <h3>
                 Youth union of the state - owned enterprise's bloc<br>
                  supporting youth in study, science research and technology capability
                  </h3>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <h3>
                    Đoàn khối doanh nghiệp TW đồng hành với thanh niên <br>
                    Trong học tập, nghiên cứu khoa học, làm chủ công nghệ
                  </h3>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <h3>
                    YOUTH UNION OF THE STATE-OWNED ENTERPRISES’ BLOC<br>
                    Supporting youth in Study, Science Research and Technology Capability
                  </h3>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <h3>
                    Đoàn khối doanh nghiệp trung ương đồng hành với thanh niên<br>
                    Trong nâng cao kỹ năng, chuyên môn nghiệp vụ
                  </h3>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <h3>
                    YOUTH OF THE STATE-OWNED ENTERPRISES’ BLOC<br>
                    PIONEERING AND INNOVATIVE FOR SUSTAINABLE DEVELOPMENT OF ENTERPRISES
                  </h3>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <h3>
                    Tuổi trẻ khối doanh nghiệp Trung ương xung kích, sáng tạo<br>
                    Vì sự phát triển bền vững của doanh nghiệp
                  </h3>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <h3>
                    YOUTH OF THE STATE-OWNED ENTERPRISES’ BLOC<br>
                    Nurture your Soul, Sharpen your Mind, Build mighty Dreams
                  </h3>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <h3>
                    Tuổi trẻ khối doanh nghiệp trung ương<br>
                    Dưỡng tâm trong, rèn trí sáng, xây hoài bão lớn
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Testimonials Section -->


    <!-- ======= Counts Section ======= -->
    <section id="counts" class="counts" style="background-color: aliceblue;">
      <div class="container mt-5">
        <!-- <div class="section-title" data-aos="fade-up">
        <h2>Lộ trình kỳ thi</h2>
      </div> -->
        <div class="row">
          <!-- <div class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-xl-start" data-aos="fade-right" data-aos-delay="150">
            <img src="assets/img/counts-img.svg" alt="" class="img-fluid">
          </div> -->

          <!-- <div class="col-xl-12 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-up" data-aos-delay="300">
            <div class="content d-flex flex-column justify-content-center">
              <div class="row">
                <div class="col-12 mt-3">
                  <img src="../../assets/ladipage/img/timeline.png" class="w-100" alt="">
                </div>
              </div>
            </div>
          </div> -->


          <!-- End .content-->


        </div>
      </div>
    </section><!-- End Counts Section -->
    <!-- ======= Testimonials Section ======= -->

    <!-- ======= Contact Section ======= -->
    <section id="dashboard" class="dashboard">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Thống kê</h2>
        </div>
        <div class="row" *ngIf="departmentStatistic.length > 0">
          <div class="col-12" data-aos="fade-up" data-aos-delay="100">
            <table class="table table-striped table-hover table-light">
              <thead>
                <tr class="bgdoandm text-white">
                  <th class="text-center">STT</th>
                  <th>Đơn vị</th>
                  <th class="text-center">Số thí sinh</th>
                  <th class="text-center">Lượt thi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                 <td></td>
                 <td class="text-right pr-3 sfbold">Tổng</td>
                 <td class="text-primary text-center sfbold">{{totalStudent}}</td>
                 <td class="text-primary text-center sfbold">{{totalAttempt}}</td>
                </tr>
                <tr *ngFor="let department of departmentsDisplayTable; let i = index;">
                  <td class="text-left">{{i+1}}</td>
                  <td class="text-left">{{department.name}}</td>
                  <td class="text-center">{{department.sothisinh}}</td>
                  <td class="text-center">{{department.slt}}</td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="col-12 text-center" data-aos="fade-up" data-aos-delay="300">
            <button class="btn btn-outline-danger radius20" (click)="openStatistical()" style="width:150px;">Xem chi
              tiết</button>
          </div>
        </div>
        <div class="row" *ngIf="departmentsDisplayTable.length == 0">
          <div class="col-12 text-center" style="height:120px;" data-aos="fade-up" data-aos-delay="400">
            <img src="../../assets/ladipage/img/clipboard.svg" class="mb-4" width="50px" height="50px"
              style="opacity: 0.6;" alt="">
            <h6>Hiện tại chưa có kết quả thống kê !</h6>
          </div>
        </div>
      </div>
    </section><!-- End Contact Section -->
    <section id="contact" class="contact" *ngIf="!isLogin">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Đăng ký</h2>
        </div>

        <div class="row">

          <div class="col-lg-5 col-md-12 text-center" data-aos="fade-up" data-aos-delay="100">
            <div class="contact-about">
              <img src="../../assets/ladipage/img/dk.png" class="img-fluid" alt="">
            </div>
          </div>
          <div class="col-lg-7 col-md-12" data-aos="fade-up" data-aos-delay="200">
            <form [formGroup]="registerUserForm">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Email đăng ký</label><span class="text-danger"> *</span>
                    <input type="text" formControlName="email" class="form-control" placeholder="Nhập email đăng ký"
                      aria-describedby="helpId">
                    <div
                      *ngIf="registerUserForm.get('email')!.invalid && (registerUserForm.get('email')!.dirty || registerUserForm.get('email')!.touched)">
                      <small class="text-danger" *ngIf="registerUserForm.get('email')?.errors?.required">{{
                        'error.validate.input_required' | translate }}</small>
                      <small class="text-danger" *ngIf="registerUserForm.get('email').errors?.pattern">{{
                        'error.validate.email.pattern' | translate }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Số điện thoại</label><span class="text-danger"> *</span>
                    <input type="text" name="" formControlName="phone1" class="form-control"
                      placeholder="Nhập số điện thoại" aria-describedby="helpId">
                    <div
                      *ngIf="registerUserForm.get('phone1')!.invalid && (registerUserForm.get('phone1')!.dirty || registerUserForm.get('phone1')!.touched)">
                      <small class="text-danger" *ngIf="registerUserForm.get('phone1')?.errors?.required">{{
                        'error.validate.input_required' | translate }}</small>
                      <small class="text-danger" *ngIf="registerUserForm.get('phone1').errors?.pattern">{{
                        'error.validate.phone_number.invalid' | translate }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Họ</label><span class="text-danger"> *</span>
                    <input type="text" name="" formControlName="firstname" class="form-control" placeholder="Nhập họ"
                      aria-describedby="helpId">
                    <div
                      *ngIf="registerUserForm.get('firstname')!.invalid && (registerUserForm.get('firstname')!.dirty || registerUserForm.get('firstname')!.touched)">
                      <small class="text-danger" *ngIf="registerUserForm.get('firstname')?.errors?.required">{{
                        'error.validate.input_required' | translate }}</small>
                      <small class="text-danger" *ngIf="registerUserForm.get('firstname')?.errors?.isBlank">{{
                        'error.validate.input_required' | translate }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Tên</label><span class="text-danger"> *</span>
                    <input type="text" name="" formControlName="lastname" class="form-control" placeholder="Nhập tên"
                           aria-describedby="helpId">
                    <div
                      *ngIf="registerUserForm.get('lastname')!.invalid && (registerUserForm.get('lastname')!.dirty || registerUserForm.get('lastname')!.touched)">
                      <small class="text-danger" *ngIf="registerUserForm.get('lastname')?.errors?.required">{{
                        'error.validate.input_required' | translate }}</small>
                      <small class="text-danger" *ngIf="registerUserForm.get('lastname')?.errors?.isBlank">{{
                        'error.validate.input_required' | translate }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group position-relative">
                    <label>Ngày sinh</label><span class="text-danger"> *</span>
                      <input matInput [matDatepicker]="startdateModel" placeholder="Ngày/tháng/năm" formControlName="dob"
                             class="form-control" [min]="minDob" [max]="maxDob">
                      <span style="position: absolute; right: 5px; top:30px;">
                        <mat-datepicker-toggle matSuffix [for]="startdateModel"></mat-datepicker-toggle>
                        <mat-datepicker style="outline: none;" #startdateModel></mat-datepicker>
                      </span>
                      <div
                      *ngIf="registerUserForm.get('dob')!.invalid && (registerUserForm.get('dob')!.dirty || registerUserForm.get('dob')!.touched)">
                      <small class="text-danger" *ngIf="registerUserForm.get('dob')?.errors?.required">{{
                        'error.validate.input_required' | translate }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>CMTND/CCCD</label><span class="text-danger"> *</span>
                    <input type="text" class="form-control" formControlName="icq" aria-describedby="helpId"
                      placeholder="Nhập CMTND/CCCD">
                    <div
                      *ngIf="registerUserForm.get('icq')!.invalid && (registerUserForm.get('icq')!.dirty || registerUserForm.get('icq')!.touched)">
                      <small class="text-danger" *ngIf="registerUserForm.get('icq')?.errors?.required">{{
                        'error.validate.input_required' | translate }}</small>
                      <small class="text-danger" *ngIf="registerUserForm.get('icq')?.errors?.isBlank">{{
                        'error.validate.input_required' | translate }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Đơn vị</label><span class="text-danger"> *</span>
                    <ng-select formControlName="departmentid" class="form-control select2 custom-select-box">
						          <ng-option *ngFor="let department of listDepartment" [value]="department.id">{{department.name}}</ng-option>
                    </ng-select>
                    <small
                      *ngIf="registerUserForm.get('departmentid')!.invalid && registerUserForm.get('departmentid')!.touched"
                      class="text-danger">{{ 'error.departmentempty' | translate }}</small>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Đơn vị cấp dưới</label><span class="text-danger"> *</span>
                    <input type="text" class="form-control" formControlName="subdepartment" aria-describedby="helpId"
                      placeholder="">
                    <div
                      *ngIf="registerUserForm.get('subdepartment')!.invalid && (registerUserForm.get('subdepartment')!.dirty || registerUserForm.get('subdepartment')!.touched)">
                      <small class="text-danger" *ngIf="registerUserForm.get('subdepartment')?.errors?.required">{{
                        'error.validate.input_required' | translate }}</small>
                      <small class="text-danger" *ngIf="registerUserForm.get('subdepartment')?.errors?.isBlank">{{
                        'error.validate.input_required' | translate }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Giới tính</label><span class="text-danger"> *</span>
                    <select class="form-control" formControlName="gender">
                      <option value="1">Nam</option>
                      <option value="0">Nữ</option>
                    </select>
                    <small *ngIf="registerUserForm.get('gender')!.invalid && registerUserForm.get('gender')!.touched"
                           class="text-danger">{{ 'error.validate.input_required' | translate }}</small>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Mật khẩu</label><span class="text-danger"> *</span>
                    <input type="password" class="form-control" formControlName="password" aria-describedby="helpId"
                      placeholder="Nhập mật khẩu" (change)="onChangePassword()">
                    <div
                      *ngIf="registerUserForm.get('password')!.invalid && (registerUserForm.get('password')!.dirty || registerUserForm.get('password')!.touched)">
                      <small class="text-danger" *ngIf="registerUserForm.get('password')?.errors?.required">
                        {{ 'error.validate.input_required' | translate }}
                      </small>
                      <small *ngIf="registerUserForm.get('password').errors?.pattern" class="text-danger">{{
                        'error.validate.password.pattern' | translate }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Lập lại Mật khẩu</label><span class="text-danger"> *</span>
                    <input type="password" class="form-control" formControlName="repassword" aria-describedby="helpId"
                      placeholder="Xác nhận mật khẩu" (change)="onChangePassword()">
                    <div
                      *ngIf="registerUserForm.get('repassword')!.invalid && (registerUserForm.get('repassword')!.dirty || registerUserForm.get('repassword')!.touched)">
                      <small class="text-danger" *ngIf="registerUserForm.get('repassword')?.errors?.required">
                        {{ 'error.validate.input_required' | translate }}
                      </small>
                      <small *ngIf="registerUserForm.get('repassword').errors?.pattern" class="text-danger">{{
                        'error.validate.password.pattern' | translate }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <small class="text-blue">Mật khẩu chứa tối thiểu 08 ký tự bao gồm chữ thường, chữ hoa & số</small>
                </div>
                <div class="col-12 text-center" data-aos="fade-up" data-aos-delay="400">
                  <button class="btn-danger btn rounded scrollto radius20 p-2" style="width: 200px;"
                    (click)="onRegisterUser()" [disabled]="loginService.isLoggedIn()">
                    <i class="icofont-rounded-right mr-2"></i>Đăng ký tham gia<i
                      class="icofont-rounded-left ml-2"></i></button>
                </div>

              </div>

            </form>
          </div>

        </div>
      </div>
    </section><!-- End Contact Section -->
  </main><!-- End #main -->



  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container" style="margin-bottom:50px;">
      <div class="row d-flex align-items-center text-lg-left text-md-left text-xs-center">
        <div class="col-lg-5 col-md-6">
          <div class="copyright">
            &copy; Copyright <span class="sfbold">ĐOÀN KHỐI DOANH NGHIỆP TRUNG ƯƠNG</span>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <p class="mb-1"><i class="icofont-road"></i> Địa chỉ: Số 105b - 107 Quán Thánh, Q. Ba Đình, Hà Nội</p>
              <p class="mb-1"><i class="icofont-phone"></i> Điện thoại: 080.45645 (8h00 - 17h00 Thứ 2 - Thứ 6)</p>
              <p class="mb-1"><i class="icofont-email"></i>  vanphongdtnk@gmail.com</p>
            </div>
          </div>
          <div class="credits">
            <!-- All the links in the footer should remain intact. -->
            <!-- You can delete the links only if you purchased the pro version. -->
            <!-- Licensing information: https://bootstrapmade.com/license/ -->
            <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/vesperr-free-bootstrap-template/ -->
            <!-- Designed by <a href="#">Mobifone</a> -->
          </div>
        </div>
        <div class="col-lg-7 col-md-6">
          <!-- <nav class="footer-links text-lg-right text-center pt-2 pt-lg-0">
          <a href="#intro" class="scrollto">Home</a>
          <a href="#about" class="scrollto">About</a>
          <a href="#">Privacy Policy</a>
          <a href="#">Terms of Use</a>
        </nav> -->
         <div class="logombfa">
           <div class="itemlogo">
             <img src="../../assets/ladipage/img/logomobifone.png" alt="">
             <h6>Designed By MobiFone</h6>
           </div>
         </div>

        </div>
      </div>
    </div>
  </footer><!-- End Footer -->

  <!-- <a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a> -->
</body>

</html>
