import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MOODLE_INFO} from '../constants/base.constant';
import {AbstractService} from './abstract.service';
import CommonUtil from '../utils/common-util';
import {map} from 'rxjs/operators';
import {Question} from '../model/question.model';


type EntityArrayResponse = HttpResponse<any>;
type EntityResponseType = HttpResponse<Question>;
type EntityArrayResponseType = HttpResponse<Question[]>;

interface QuestionPageResponse {
  results: Question[];
  total: number;
}

@Injectable({providedIn: 'root'})
export class QuestionBankService {
  constructor(private abstractService: AbstractService) {

  }

  getListQuestion(params: any): Observable<HttpResponse<QuestionPageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_question_list_question');
    CommonUtil.appendDataToFormData(data, 'params', params);
    return this.abstractService.post<QuestionPageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<QuestionPageResponse>) => res));
  }

  createQuestion(question: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_question_create_question');
    delete question.id;
    delete question.index;
    delete question.single;
    delete question.categoryname;
    delete question.timecreated;
    delete question.slot;
    CommonUtil.appendDataToFormData(data, 'question', question);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  updateQuestion(question: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_question_update_question');
    delete question.single;
    delete question.index;
    // delete question.quizid;
    delete question.qtype;
    CommonUtil.appendDataToFormData(data, 'question', question);
    return this.abstractService.post<Question>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: EntityResponseType) => res));
  }

  deleteQuestion(question: any): Observable<any> {
    const dataCourse = this.abstractService.getCommonInput('mobifone_question_delete_question');
    CommonUtil.appendDataToFormData(dataCourse, 'question', question);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: any) => res));
  }

  deleteQuestionOnExam(params: any): Observable<any> {
    const dataCourse = this.abstractService.getCommonInput('mobifone_quiz_remove_question');
    CommonUtil.appendDataToFormData(dataCourse, 'params', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: any) => res));
  }

  prepareQuestiontextForUpdate(questionid: number): Observable<any> {
    const data = this.abstractService.getCommonInput('mobifone_question_admin_prepare_data_for_update_question');
    CommonUtil.appendDataToFormData(data, 'questionid', questionid);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: any) => res));
  }

}
