import { NgxSpinnerService } from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {ToastrCustomService} from './../../../shared/services/toastr-custom.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {QuestionBankService} from '../../../shared/services/question-bank.service';
import {CourseCategoryService} from '../../../shared/services/course-category.service';
import CommonUtil from 'src/app/shared/utils/common-util';
import {CourseCategory} from 'src/app/shared/model/course-category.model';
import {TranslateService} from '@ngx-translate/core';
import {LEVEL_QUESTION} from '../../../shared/constants/base.constant';
import {Router} from '@angular/router';
import { v1 as uuidv1 } from 'uuid';

@Component({
  selector: 'app-question-create',
  templateUrl: './question-create.component.html',
  styleUrls: ['./question-create.component.css']
})
export class QuestionCreateComponent implements OnInit, OnDestroy {

  quizcontent = '';
  courseCategoryList: CourseCategory[] = [];
  categoryMap = new Map();
  levelArray: any = [];
  titleNumber: any = 1;
  questionsTrueFalse: any = [];
  questionsMulti: any = [];
  questionsMultiset: any = [];
  questionTypeMulti = 'multichoice';
  questionTypeMultiset = 'multichoiceset';
  questionTypeTrueFalse = 'truefalse';
  categoryid = 1;
  accordions: any = [];
  panelOpenState = true;
  saveEnabled = false;
  countSuccess: number;

  constructor(
    private questionBankService: QuestionBankService,
    private courseCategoryService: CourseCategoryService,
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      this.courseCategoryList = response.body;
      CommonUtil.convertCategoryListToMap(this.courseCategoryList, this.categoryMap);
    });
    this.levelArray = Object.assign([], LEVEL_QUESTION);
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.accordions, event.previousIndex, event.currentIndex);
  }

  convertLevelQuestion(levelid) {
    const levelName = LEVEL_QUESTION.find(lev => lev.id = levelid);
    const translate = `level_question.${levelName.level}`;
    if (levelName.level !== null) {
      return this.translateService.instant(translate);
    } else {
      return '';
    }
  }

  addQuestion(typeValue: any, index?: number, ques?: any) {
    this.saveEnabled = false;
    let question = null;
    if (typeValue === this.questionTypeMulti && this.questionsMulti.length > 0 && index && !ques) {
      this.questionsMulti.forEach(ques => {
        if (ques.index === index) {
          question = {...ques};
          question.index = this.titleNumber;
          this.receiveMulti(question);
        }
      });
    } else if (typeValue === this.questionTypeTrueFalse && this.questionsTrueFalse.length > 0 && index && !ques) {
      this.questionsTrueFalse.forEach(ques => {
        if (ques.index === index) {
          question = {...ques};
          question.index = this.titleNumber;
          this.receiveTrueFalse(question);
        }
      });
    } else if (typeValue === this.questionTypeMultiset && this.questionsMultiset.length > 0 && index && !ques){
      this.questionsMultiset.forEach(ques => {
        if (ques.index === index) {
          question = {...ques};
          question.index = this.titleNumber;
          this.receiveMultiset(question);
        }
      });
    } if (ques) {
      question = {...ques};
      question.index = this.titleNumber;
      switch (typeValue){
        case this.questionTypeMulti:
          this.questionsMulti.push(question);
          break;
        case this.questionTypeMultiset:
          this.questionsMultiset.push(question);
          break;
        case this.questionTypeTrueFalse:
          this.questionsTrueFalse.push(question);
          break;
      }
      // typeValue === this.questionTypeMulti ? this.questionsMulti.push(question) : this.questionsTrueFalse.push(question);
    }
    const newAco = [
      {
        index: this.titleNumber,
        questionNumber: this.titleNumber,
        title: this.titleNumber,
        description: 'Câu hỏi ' + this.titleNumber,
        type: typeValue,
        defaultmark: 4,
        question: question ? question : null,
        btnSaveDisable: question ? false : true
      }
    ];
    this.titleNumber += 1;
    this.accordions.push(...newAco);
  }

  removeQuestion(accordion: any) {
    if (this.questionsMulti.length > 0 || this.questionsTrueFalse.length > 0 || this.accordions) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalDep.componentInstance.title = this.translateService.instant('question.delete_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('question.delete_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
      modalDep.result.then((result) => {
        if (result === 'confirm') {
          this.accordions.splice(this.accordions.indexOf(accordion), 1);
          this.titleNumber -= 1;
        }
      });
    }
  }

  createQuestions(accordion?: any) {
    if (this.questionsTrueFalse.length <= 0 && this.questionsMulti.length <= 0 && this.questionsMultiset.length <= 0) {
      return;
    }
    this.spinner.show();
    if (accordion) {
      if (accordion.type === this.questionTypeMulti) {
        const ques = this.questionsMulti.filter(question => question.index === accordion.index);
        if (ques.lenght === 0) {
          return;
        }
        const index = ques[0].index;
        const single = ques[0].single;
        if (ques[0].questiontext.search('<img') !== -1) {
          ques[0].questiontext = ques[0].questiontext.replaceAll('<img', '<img class="img-fluid"');
        }
        ques[0].answer?.forEach(ans => {
          if (ans.text.search('<img') !== -1) {
            // nếu ảnh chưa có alt thì add thêm, nếu ảnh đã có alt thì replate để tránh gây lỗi khi làm bài thi có 2 ảnh có alt bị trùng
            if (ans.text.search('alt="') === -1) {
              ans.text = ans.text.replaceAll('<img', '<img class="img-fluid" alt="' + uuidv1() + '"');
            } else {
              ans.text = ans.text.replaceAll('<img', '<img class="img-fluid"')
                .replaceAll('></figure>', ' alt="' + uuidv1() + '"></figure>');
            }
          }
        });
        ques[0].categoryid = this.categoryid;
        this.questionBankService.createQuestion(ques[0]).subscribe(
          res => {
            this.questionsMulti.splice(this.questionsMulti.indexOf(ques[0]), 1);
            this.accordions.splice(this.accordions.indexOf(accordion), 1);
            this.titleNumber -= 1;
            this.spinner.hide();
            this.toastrService.success(`common.noti.create_success`);
          },
          err => {
            this.spinner.hide();
            ques[0].index = index;
            ques[0].single = single;
            this.toastrService.handlerError(err);
          });
      } else if (accordion.type === this.questionTypeTrueFalse) {
        const ques = this.questionsTrueFalse.filter(question => question.index === accordion.index);
        if (ques.lenght === 0) {
          return;
        }
        const index = ques[0].index;
        const single = ques[0].single;
        if (ques[0].questiontext.search('<img') !== -1) {
          ques[0].questiontext = ques[0].questiontext.replaceAll('<img', '<img class="img-fluid"');
        }
        ques[0].categoryid = this.categoryid;
        this.questionBankService.createQuestion(ques[0]).subscribe(
          res => {
            this.questionsTrueFalse.splice(this.questionsTrueFalse.indexOf(ques[0]), 1);
            this.accordions.splice(this.accordions.indexOf(accordion), 1);
            this.titleNumber -= 1;
            this.spinner.hide();
            this.toastrService.success(`common.noti.create_success`);
          },
          err => {
            this.spinner.hide();
            ques[0].index = index;
            ques[0].single = single;
            this.toastrService.handlerError(err);
          });
      }
      else if (accordion.type === this.questionTypeMultiset){
        const ques = this.questionsMultiset.filter(question => question.index === accordion.index);
        if (ques.lenght === 0) {
          return;
        }
        const index = ques[0].index;
        const single = ques[0].single;
        if (ques[0].questiontext.search('<img') !== -1 && ques[0].questiontext.search('<img class="img-fluid"') === -1) {
          ques[0].questiontext = ques[0].questiontext.replace('<img', '<img class="img-fluid"');
        }
        ques[0].answer?.forEach(ans => {
          if (ans.text.search('<img') !== -1 && ans.text.search('<img class="img-fluid"') === -1) {
            ans.text = ans.text.replace('<img', '<img class="img-fluid"');
          }
        });
        ques[0].categoryid = this.categoryid;
        this.questionBankService.createQuestion(ques[0]).subscribe(
          res => {
            this.questionsMultiset.splice(this.questionsMultiset.indexOf(ques[0]), 1);
            this.accordions.splice(this.accordions.indexOf(accordion), 1);
            this.titleNumber -= 1;
            this.spinner.hide();
            this.toastrService.success(`common.noti.create_success`);
          },
          err => {
            this.spinner.hide();
            ques[0].index = index;
            ques[0].single = single;
            this.toastrService.handlerError(err);
          });
      }
    } else {
      this.countSuccess = 0;
      this.questionsTrueFalse.forEach(question => {
        const index = question.index;
        const single = question.single;
        if (question.questiontext.search('<img') !== -1) {
          question.questiontext = question.questiontext.replaceAll('<img', '<img class="img-fluid"');
        }
        question.categoryid = this.categoryid;
        this.questionBankService.createQuestion(question).subscribe(
          res => {
            this.countSuccess++;
            if (this.countSuccess === (this.questionsMulti.length + this.questionsTrueFalse.length)) {
              this.toastrService.success(`common.noti.create_success`);
              this.backToListQues();
              this.questionsTrueFalse = [];
              this.accordions = [];
            }
            this.spinner.hide();
          },
          err => {
            this.spinner.hide();
            question.index = index;
            question.single = single;
            this.toastrService.handlerError(err);
          });
      });
      this.questionsMulti.forEach((question) => {
        const index = question.index;
        const single = question.single;
        if (question.questiontext.search('<img') !== -1) {
          question.questiontext = question.questiontext.replaceAll('<img', '<img class="img-fluid"');
        }
        question.answer?.forEach(ans => {
          if (ans.text.search('<img') !== -1) {
            // nếu ảnh chưa có alt thì add thêm, nếu ảnh đã có alt thì replate để tránh gây lỗi khi làm bài thi có 2 ảnh có alt bị trùng
            if (ans.text.search('alt="') === -1) {
              ans.text = ans.text.replaceAll('<img', '<img class="img-fluid" alt="' + uuidv1() + '"');
            } else {
              ans.text = ans.text.replaceAll('<img', '<img class="img-fluid"')
                .replaceAll('></figure>', ' alt="' + uuidv1() + '"></figure>');
            }
          }
        });
        question.categoryid = this.categoryid;
        this.questionBankService.createQuestion(question).subscribe(
          res => {
            this.countSuccess++;
            if (this.countSuccess === (this.questionsMulti.length + this.questionsTrueFalse.length)) {
              this.toastrService.success(`common.noti.create_success`);
              this.backToListQues();
              this.questionsTrueFalse = [];
              this.accordions = [];
            }
            this.spinner.hide();
          },
          err => {
            this.spinner.hide();
            question.index = index;
            question.single = single;
            this.toastrService.handlerError(err);
          });
      });
    }

  }

  cancelCreateQues() {
    if (this.accordions.length > 0) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalDep.componentInstance.title = this.translateService.instant('question.cancel_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('question.cancel_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.continute');
      modalDep.result.then((result) => {
        if (result === 'confirm') {
          this.accordions = [];
          this.questionsTrueFalse = [];
          this.questionsMulti.length = [];
          this.questionsMultiset.length = [];
          this.titleNumber = 1;
        }
      });
    }
  }

  backToListQues() {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('question.back_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('question.back_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.continute');
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.router.navigate(['admin/question-bank']);
      }
    });
  }

  receiveTrueFalse($ques) {
    if (!$ques.questiontext) {
      this.accordions.forEach((accordion) => {
        if (accordion.index === $ques.index) {
          accordion.btnSaveDisable = true;
        }
      });
      this.saveEnabled = false;
      return;
    }
    this.saveEnabled = true;
    this.accordions.forEach((accordion) => {
      if (accordion.index === $ques.index) {
        accordion.btnSaveDisable = false;
      }
    });
    let recover = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.questionsTrueFalse.length; i++) {
      if (this.questionsTrueFalse[i].index === $ques.index) {
        this.questionsTrueFalse[i].name = $ques.name;
        this.questionsTrueFalse[i].questiontext = $ques.questiontext;
        this.questionsTrueFalse[i].level = $ques.level;
        this.questionsTrueFalse[i].defaultmark = $ques.defaultmark;
        this.questionsTrueFalse[i].answercorrect = $ques.answercorrect;
        recover = true;
      }
    }
    $ques.qtype = this.questionTypeTrueFalse;
    if (!recover) {
      this.questionsTrueFalse.push($ques);
    }
  }

  receiveMulti($ques) {
    if (!$ques.questiontext || !$ques.answer[0].text) {
      this.accordions.forEach((accordion) => {
        if (accordion.index === $ques.index) {
          accordion.btnSaveDisable = true;
        }
      });
      this.saveEnabled = false;
      return;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < $ques.answer.length; i++) {
      if (!$ques.answer[i].text || !$ques.answer[i].grade) {
        this.accordions.forEach((accordion) => {
          if (accordion.index === $ques.index) {
            accordion.btnSaveDisable = true;
          }
        });
        this.saveEnabled = false;
        return;
      }
    }
    this.saveEnabled = true;
    this.accordions.forEach((accordion) => {
      if (accordion.index === $ques.index) {
        accordion.btnSaveDisable = false;
      }
    });
    let recover = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.questionsMulti.length; i++) {
      if (this.questionsMulti[i].index === $ques.index) {
        this.questionsMulti[i].name = $ques.name;
        this.questionsMulti[i].questiontext = $ques.questiontext;
        this.questionsMulti[i].defaultmark = $ques.defaultmark;
        this.questionsMulti[i].answernumbering = $ques.answernumbering;
        this.questionsMulti[i].answer = $ques.answer;
        this.questionsMulti[i].level = $ques.level;
        recover = true;
      }
    }
    $ques.qtype = this.questionTypeMulti;
    if (!recover) {
      this.questionsMulti.push($ques);
    }
  }

  receiveMultiset($ques) {
    if (!$ques.questiontext || !$ques.answer[0].text) {
      this.accordions.forEach((accordion) => {
        if (accordion.index === $ques.index) {
          accordion.btnSaveDisable = true;
        }
      });
      this.saveEnabled = false;
      return;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < $ques.answer.length; i++) {
      if (!$ques.answer[i].text || !$ques.answer[i].grade) {
        this.accordions.forEach((accordion) => {
          if (accordion.index === $ques.index) {
            accordion.btnSaveDisable = true;
          }
        });
        this.saveEnabled = false;
        return;
      }
    }
    this.saveEnabled = true;
    this.accordions.forEach((accordion) => {
      if (accordion.index === $ques.index) {
        accordion.btnSaveDisable = false;
      }
    });
    let recover = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.questionsMultiset.length; i++) {
      if (this.questionsMultiset[i].index === $ques.index) {
        this.questionsMultiset[i].name = $ques.name;
        this.questionsMultiset[i].questiontext = $ques.questiontext;
        this.questionsMultiset[i].defaultmark = $ques.defaultmark;
        this.questionsMultiset[i].answernumbering = $ques.answernumbering;
        this.questionsMultiset[i].answer = $ques.answer;
        this.questionsMultiset[i].level = $ques.level;
        recover = true;
      }
    }
    $ques.qtype = this.questionTypeMultiset;
    if (!recover) {
      this.questionsMultiset.push($ques);
    }
  }


  onSaveQues(panel: any) {
    if (this.questionsTrueFalse.length <= 0 && this.questionsMulti.length <= 0 && this.questionsMultiset.length <= 0) {
      return;
    }
    this.createQuestions(panel);
  }

  onRemoveQuestion(index: number){
    this.accordions.splice(index, 1);
  }

}
