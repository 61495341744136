<div class="modal-header">
  <h4 class="modal-title">{{ titleForm | translate }}</h4>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
          (click)="activeModal.close('Close click')"><i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form role="form" [formGroup]="editProgramForm">
    <div class="form-group">
      <label>Tên chức danh </label><span class="text-danger d-inline-block pl-2">*</span>
      <input type="text" class="form-control" placeholder="Tên chức danh" formControlName="name" required="" [(ngModel)]="positionClone.name">
      <div *ngIf="editProgramForm.get('name')!.invalid && (editProgramForm.get('name')!.dirty || editProgramForm.get('name')!.touched)">
        <small class="text-danger" *ngIf="editProgramForm.get('name')?.errors?.isBlank">
          {{ 'error.validate.input_required' | translate }}
        </small>
        <small class="text-danger" *ngIf="editProgramForm.get('name')?.errors?.required">
          {{ 'error.validate.input_required' | translate }}
        </small>
        <small class="text-danger" *ngIf="editProgramForm.get('name')?.errors?.maxlength">
          {{ 'error.validate.position.nameMaxlength' | translate }}
        </small>
      </div>
      <label>Mã chức danh </label><span class="text-danger d-inline-block pl-2">*</span>
      <input type="text" class="form-control" placeholder="Mã chức danh" [(ngModel)]="positionClone.code" formControlName="code" (change)="mustTypeIdDepartment()">
      <div *ngIf="editProgramForm.get('code')!.invalid && (editProgramForm.get('code')!.dirty || editProgramForm.get('code')!.touched);else other_content">
        <small class="text-danger" *ngIf="editProgramForm.get('code')?.errors?.isBlank">
          {{ 'error.validate.input_required' | translate }}
        </small>
        <small class="text-danger" *ngIf="editProgramForm.get('code')?.errors?.required">
          {{ 'error.validate.input_required' | translate }}
        </small>
        <small class="text-danger" *ngIf="editProgramForm.get('code')?.errors?.maxlength">
          {{ 'error.validate.position.codeMaxlength' | translate }}
        </small>
      </div>
      <ng-template #other_content>
        <small class="text-danger" *ngIf="!validateCodePos">
          {{ 'position.code_error' | translate }}
        </small>
      </ng-template>

    </div>
    <div class="form-group">
      <label>Mô tả </label>
      <textarea class="form-control" placeholder="Mô tả chi tiết" [(ngModel)]="positionClone.description"  formControlName="description"></textarea>
    </div>
<!--    <div *ngIf="editProgramForm.get('description')!.invalid && (editProgramForm.get('description')!.dirty || editProgramForm.get('description')!.touched);">-->
<!--      <small class="text-danger" *ngIf="editProgramForm.get('description')?.errors?.maxlength">-->
<!--        {{ 'error.validate.position.descriptionMaxlength' | translate }}-->
<!--      </small>  -->
<!--    </div>-->
  </form>
</div>
<div class="modal-footer text-center">
  <button type="button" class="btn ml-1 btn-secondary rounded-0 mr-1"
          (click)="activeModal.dismiss('close')">{{ 'common.cancel' | translate }}</button>
  <div *ngIf="position">
    <button type="submit" class="btn btn-primary"  (click)="editProgram()">
      {{'common.save' | translate }}
    </button>
    <div>
    </div>
  </div>
</div>
