import { Component, OnInit, OnDestroy } from '@angular/core';
import {NotifycationModel} from '../../../shared/model/notifycation.model';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {FormBuilder} from '@angular/forms';
import {PageEvent} from '@angular/material/paginator';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Sort} from '@angular/material/sort';
import {NotificationHistoryDetailComponent} from '../notification-history-detail/notification-history-detail.component';
import {NotificationService} from '../../../shared/services/notification.service';

@Component({
  selector: 'app-notify-single',
  templateUrl: './notification-history.component.html',
  styleUrls: ['./notification-history.component.css']
})
export class NotificationHistoryComponent implements OnInit, OnDestroy {

  pageIndex = 1;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number = 0;
  completedAll = false;
  pageEvent: PageEvent;
  listNotify: NotifycationModel[] = [];
  sortcolumn = 'createdat';
  sorttype = 'desc';
  notifyIds: number[] = [];
  notifyIdsChecked: number[] = [];
  searchForm = this.fb.group({
    keySearch: []
  });

  constructor(
    private notifycationService: NotificationService,
    private toastrService: ToastrCustomService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getListNotifySingle(this.pageSize, this.pageIndex, this.sortcolumn, this.sorttype);
  }

  getListNotifySingle(pageSize: any, pageIndex: any, sortcolumn?: any, sorttype?: any, keyWord?: any) {
    this.spinner.show();
    this.notifyIds = [];
    this.notifycationService.searchNotifySingle(pageSize, pageIndex, this.sortcolumn, this.sorttype, keyWord).subscribe(res => {
      const result: any = res.body;
      this.listNotify = result.results || [];
      this.totalRecord = result.total;
      this.pageIndex = pageIndex;
      this.pageSize = pageSize;
      for (let i = 0; i < this.listNotify.length; i++) {
        if (this.listNotify[i].createdat !== null && this.listNotify[i].createdat !== undefined) {
          this.listNotify[i].createdat = new Date(this.listNotify[i].createdat * 1000);
        }
        if (this.notifyIdsChecked?.includes(this.listNotify[i]?.id)) {
          this.listNotify[i].selected = true;
          this.notifyIds?.push(this.listNotify[i].id);
        }
      }
      if (this.notifyIds?.length > 0 && (this.notifyIds?.length === this.listNotify?.length)) {
        this.completedAll = true;
      } else {
        this.completedAll = false;
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide()
      this.toastrService.handlerError(error);
    });
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  searchNotify() {
    this.pageIndex = 1;
    this.notifyIdsChecked = [];
    this.getListNotifySingle(this.pageSize, this.pageIndex, this.sortcolumn, this.sorttype, this.searchForm.value.keySearch?.toLowerCase());
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.listNotify.forEach(c => {
        c.selected = checked;
        if (!this.notifyIds.includes(c.id)) {
          this.notifyIds.push(c.id);
        }
        if (!this.notifyIdsChecked?.includes(c.id)) {
          this.notifyIdsChecked?.push(c.id);
        }
      });
      this.completedAll = true;
    } else {
      this.notifyIds?.forEach(id => {
        this.notifyIdsChecked?.splice(this.notifyIdsChecked?.indexOf(id), 1);
      });
      this.notifyIds = [];
      this.listNotify?.forEach(c => {
        c.selected = false;
      });
      this.completedAll = false;
    }
  }

  oncheckboxItem(courseId: number, checked: any) {
    if (checked) {
      this.listNotify?.forEach(c => {
        if (c.id === courseId) {
          c.selected = true;
          this.notifyIds?.push(courseId);
          this.notifyIdsChecked?.push(courseId);
          return;
        }
      });
      if (this.notifyIds?.length > 0 && this.notifyIds?.length === this.listNotify?.length && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.completedAll = false;
      this.listNotify?.forEach(c => {
        if (c.id === courseId) {
          c.selected = false;
          this.notifyIds?.splice(this.notifyIds?.indexOf(courseId), 1);
          this.notifyIdsChecked?.splice(this.notifyIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
  }

  onDeleteMultipleSurvey() {
    this._onDeleteSurveys(this.notifyIdsChecked);
  }

  _onDeleteSurveys(notifyIds?: number[]) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('notification.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('notification.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.spinner.show();
        this.notifycationService.deleteNotify(notifyIds?.toString()).subscribe(res => {
          [...notifyIds]?.forEach(courseId => {
            this.notifyIds.splice(this.notifyIds.indexOf(courseId), 1);
            this.notifyIdsChecked.splice(this.notifyIdsChecked.indexOf(courseId), 1);
          });
          this.getListNotifySingle(this.pageSize, this.pageIndex, this.sortcolumn, this.sorttype);
          this.spinner.hide();
          this.toastrService.success('common.noti.delete_success');
        }, error => {
          this.spinner.hide();
          this.toastrService.handlerError(error);
        });
      }
    });
  }

  sortData(sort: Sort) {
    this.sortcolumn = sort.active;
    this.sorttype = sort.direction;
    // this.pageIndex = 1;
    this.getListNotifySingle(this.pageSize, this.pageIndex, this.sortcolumn, this.sorttype);
  }

  openDetailNotify(notify) {
    const notifyDetail = {
      title: notify.title,
      content: notify.content,
      recipient: notify.recipient
    };
    const modalDep = this.modalService.open(NotificationHistoryDetailComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.notify = notifyDetail;

  }

}
