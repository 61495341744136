<div class="container-fluid">
  <div class="card card-default">
    <div class="card-body">
      <div class="row">
        <div class="col-12 mt-2 mb-2">

          <button class="btn btn-secondary btn-sm" type="submit" (click)="openListCourse('exam')" *ngIf="!isNotify && !readonly">
            <i class="nav-icon fas fa-plus mr-1"></i>Chọn kỳ thi
          </button>

          <button class="btn btn-secondary btn-sm" type="submit" (click)="openListCourseForNotify('exam')"
                  *ngIf="isNotify" value="{{idCoursesForNotify}}">
            <i class="nav-icon fas fa-plus mr-1"></i>Chọn kỳ thi cho thông báo
          </button>

        </div>
      </div>
      <div class="col-12 table-responsive">
        <div class="row">
          <form class="form-inline float-right" role="form">
            <div class="form-group m-2" *ngIf="!isNotify">
              <label class="mr-2">Tìm kiếm</label>
              <input type="search" class="form-control mr-2" placeholder="Từ khóa..." (change)="onChange($event)">
            </div>
            <div class="form-group m-2" *ngIf="isNotify">
              <label class="mr-2">Tìm kiếm</label>
              <input type="search" class="form-control mr-2" placeholder="Từ khóa..."
                     (input)="searchByNotify($event?.target?.value)">
            </div>
          </form>
        </div>
        <table class="table table-hover">
          <thead>
          <tr>
            <th>STT</th>
            <th>Tên kỳ thi</th>
            <th>Ngày bắt đầu</th>
            <th>Ngày kết thúc</th>
            <!-- <th>Trạng thái</th>                                                         -->
            <th *ngIf="!readonly">Thao tác</th>
          </tr>
          </thead>
          <tbody *ngIf="!isNotify">
          <tr *ngFor="let item of examInBadge; let i = index;">
            <td style="width:20px;">{{i + 1 + (searchBadgeExam.pageIndex - 1) * searchBadgeExam.pageSize}}</td>
            <td>{{item.fullname}}</td>
            <td>{{getDateFromUnix(item.startdate)  | date:'dd/MM/yyyy'}}</td>
            <td>{{getDateFromUnix(item.enddate)| date:'dd/MM/yyyy'}}</td>
            <!-- <td><span class="badge badge-success">Hoàn thành</span></td>    -->
            <td *ngIf="!readonly">
              <button type="button" class="btn btn-default btn-sm mr-1" matTooltip="Xóa"
                      matTooltipClass="tooltip-red" (click)="unAssignExam(item.id)"><i
                class="fas fa-trash mr-1"></i></button>
            </td>
          </tr>
          </tbody>

          <tbody *ngIf="isNotify">
          <tr *ngFor="let item of examArray; let i = index;">
            <td style="width:20px;">{{i + 1}}</td>
            <td>{{item.fullname}}</td>
            <td>{{getDateFromUnix(item.startdate)  | date:'dd/MM/yyyy'}}</td>
            <td>{{getDateFromUnix(item.enddate)| date:'dd/MM/yyyy'}}</td>
            <!-- <td><span class="badge badge-success">Hoàn thành</span></td>    -->
            <td>
              <button type="button" class="btn btn-default btn-sm mr-1" matTooltip="{{isNotify ? 'Gỡ' : 'Xóa'}}"
                      matTooltipClass="tooltip-red" (click)="unAssignExam(item.id)"><i
                class="fas fa-trash mr-1"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
        <br />
        <p *ngIf="examInBadge?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
      </div>
      <div class="card-footer text-center">
        <div *ngIf="!isNotify" class="row">
          <mat-paginator showFirstLastButtons
                         [length]="totalRecord"
                         [pageSize]="searchBadgeExam.pageSize"
                         [pageIndex]="searchBadgeExam.pageIndex - 1 "
                         (page)="changePage($event)"
                         [hidePageSize]="false"
                         [pageSizeOptions]="pageSizeOptions">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
