<div class="modal-header">
  <h5 class="modal-title">{{'exam_result.title' | translate}}</h5>
  <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<div class="modal-body">
  <div class="row mb-3">
    <div class="col-12 text-center">
      <p>Lượt thi</p>
      <h4 class="text-primary sfbold">
        {{turnOfExam}}
      </h4>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-md-6 text-center">
      <p>Tổng số câu</p>
      <h5 class="text-primary sfbold">{{totalquestion}}</h5>
    </div>
    <div class="col-lg-3 col-md-6 text-center">
      <p>Số câu đúng</p>
      <h5 class="text-success sfbold">{{correctquestion}}</h5>
    </div>
    <div class="col-lg-3 col-md-6 text-center">
      <p>Thời gian làm bài</p>
      <h5 class="text-black sfbold">{{timeResult}}</h5>
    </div>
    <div class="col-lg-3 col-md-6 text-center">
      <p>Kết quả</p>
      <h5 class="text-primary sfbold">{{sumgrades + "/" + gradequiz}}</h5>
    </div>
  </div>
  <!-- <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th class="text-center">Tổng số câu</th>
        <th class="text-center">Số câu đúng</th>
        <th class="text-center">Thời gian làm bài</th>
        <th class="text-center">Số điểm</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-center">{{totalquestion}}</td>
        <td class="text-center">{{correctquestion}}</td>
        <td class="text-center">{{timeResult}}</td>
        <td class="text-center">{{sumgrades}}</td>
      </tr>
    </tbody>
  </table> -->
</div>
<div class="modal-footer">
    <div class="m-auto">
        <button type="button" class="btn btn-primary" [disabled]="!statusQuiz"
        (click)="activeModal.close('confirm')">{{'exam_result.back' | translate}}</button>
      </div>

</div>
