<div class="modal-header">
  <h4 class="modal-title">Tạo mới chứng chỉ</h4>
  <button type="button" class="btn btn-tool" (click)="activeModal.dismiss()"><i class="fas fa-times"></i>
  </button>
</div>
<form role="form" [formGroup]="badgeForm">
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Tên chứng chỉ <span class="text-danger">*</span></label>
          <div>
            <input type="text" class="form-control" placeholder="Nhập tên chứng chỉ" formControlName="name">
          </div>
          <div *ngIf="badgeForm.get('name')!.invalid &&(badgeForm.get('name')!.dirty || badgeForm.get('name')!.touched)">
            <small class="text-danger" *ngIf="badgeForm.get('name')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
            <small class="text-danger" *ngIf="badgeForm.get('name')?.errors && !badgeForm.get('name')?.errors?.required">
              {{ 'error.validate.course.nameMaxlength' | translate }}
            </small>
            <small class="text-danger" *ngIf="badgeForm.get('name')?.errors?.isBlank">
              {{ 'error.validate.input_required' | translate }}
            </small>
          </div>
        </div>
      </div>
      <div class="col-12">

        <div class="form-group">
          <label>Miêu tả</label>
          <textarea type="text" class="form-control" placeholder="Miêu tả ngắn" formControlName="description"></textarea>
          <div *ngIf="badgeForm.get('description')!.invalid &&(badgeForm.get('description')!.dirty || badgeForm.get('description')!.touched)">
            <small class="text-danger" *ngIf="badgeForm.get('description')?.errors && !badgeForm.get('description')?.errors?.required">
              {{ 'error.validate.course.nameMaxlength' | translate }}
            </small>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label>Thời gian chứng chỉ <span class="text-danger">*</span></label>
        <div class="form-group">
          <div class="row">
            <mat-radio-group aria-label="Select an option" #radioGroup="matRadioGroup">
              <mat-radio-button class="example-radio-button mr-2" value="0" (click)="setradio('0')"  [checked]='true' >Vô thời hạn</mat-radio-button>
              <mat-radio-button class="example-radio-button mr-2" value="expiredate" (click)="setradio('expiredate')" >Chọn ngày hết hạn</mat-radio-button>
              <mat-radio-button class="example-radio-button" value="expireperiod" (click)="setradio('expireperiod')">Hạn sử dụng</mat-radio-button>
              <div *ngIf="isSelected('0')">
              </div>
              <div *ngIf="isSelected('expiredate')" >
                <mat-form-field color="blue" class="mr-3 w-100 mb-0 course-input">
                  <mat-label>Ngày hết hạn</mat-label>
                  <input matInput [matDatepicker]="startdateModel" [min]="currentDate" [(ngModel)]="expiredate"  formControlName="expiredate">
                  <mat-datepicker-toggle matSuffix [for]="startdateModel"></mat-datepicker-toggle>
                  <mat-datepicker #startdateModel></mat-datepicker>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>

              <div *ngIf="isSelected('expireperiod')" class="form-group">
                <div class="col-12 row">
                <input type="number" class="form-control fix-width col-md-3" [(ngModel)]="expireperiod" formControlName="expireperiod" size="4">
                <div class="col-md-9">
                  <label>ngày kể từ ngày nhận chứng chỉ</label>
                </div>
              </div>
              <div *ngIf="badgeForm.get('expireperiod')!.invalid &&(badgeForm.get('expireperiod')!.dirty || badgeForm.get('expireperiod')!.touched)">
                <small class="text-danger" *ngIf="badgeForm.get('expireperiod')?.errors?.required">
                  {{ 'error.validate.input_required' | translate }}
                </small>
                <small class="text-danger" *ngIf="badgeForm.get('expireperiod')?.errors?.invalidNumber">
                  {{ 'error.validate.number' | translate }}
                </small>
              </div>
              </div>
            </mat-radio-group>

          </div>
        </div>
      </div>
      <!-- <div class="col-12">
        <div class="form-group">
          <label>Chọn mẫu <span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="file" class="form-control-file" (change)="handleFileInput($event.target.files)" >
            <small class="text-danger" *ngIf="badeFileErrorRequired && !badeFileInvalid">
              {{ 'error.validate.input_required' | translate }}
            </small>
            <small class="text-danger" *ngIf="badeFileInvalid">
              {{ 'error.validate.input_valid' | translate }}
            </small>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="modal-footer text-center">
      <button type="button" (click)="activeModal.dismiss()" class="btn ml-1 btn-secondary rounded-0 mr-1">Hủy</button>
      <button type="submit" (click)="createNewBadge()" class="btn ml-1 btn-primary rounded-0">Tạo mới</button>
  </div>
</form>
