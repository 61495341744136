import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ModuleModel} from '../../../shared/model/module.model';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import CommonUtil from '../../../shared/utils/common-util';
import * as moment from 'moment';
import {TopicService} from '../../../shared/services/topic.service';
import {CourseService} from '../../../shared/services/course.service';
import {BbbRecording} from '../../../shared/model/bbb-recording.model';

@Component({
  selector: 'app-eu-bigbluebutton-content',
  templateUrl: './eu-bigbluebutton-content.component.html',
  styleUrls: ['./eu-bigbluebutton-content.component.css']
})
export class EuBigbluebuttonContentComponent implements OnInit, OnChanges {
  @Input() moduleInput: ModuleModel;
  @Input() courseId: number;
  @Input() section: number;

  currentDate: Date = new Date();

  courseModule: ModuleInfo = {
    name: '',
    course: this.courseId,
    section: this.section,
    introeditor: null,
    modulename: 'bigbluebuttonbn',
  };

  openingtime: Date;
  closingtime: Date;
  teachers = '';

  canJoinMeetingTime;
  bbbRecordings: BbbRecording[] = [];


  constructor(
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private topicService: TopicService,
    private courseService: CourseService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.moduleInput) {
      // Cap nhat du lieu
      this.spinner.show();
      this.topicService.getModuleDetail(this.moduleInput.id).subscribe(res => {
          this.courseModule = res.body;
          this.teachers = res.body.teachers.length > 0 ? CommonUtil.convertTeacherName(res.body.teachers) : 'Chưa có giảng viên được gán';
          this.openingtime = moment.unix(this.courseModule.openingtime).toDate();
          this.closingtime = moment.unix(this.courseModule.closingtime).toDate();
          const timeOffset = 15 * 60; // 15 minutes
          this.canJoinMeetingTime = moment.unix(this.courseModule.openingtime - timeOffset).toDate();
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          this.toastrService.handlerError(err);
        });

      this.getListBBBRecord();
    }
  }

  joinMeeting() {
    this.spinner.show();
    this.topicService.studentJoinMeeting(this.courseModule.id).subscribe(
      data => {
        this.toastrService.success('done');
        const url = data.body.meetingurl;
        window.open(url.toString(), '_blank');
        // join meeting -> Tinh la hoan thanh bai giang
        this.courseService.setProgress(this.courseId, this.moduleInput.id).subscribe(res => {});
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      }
    );
  }

  getListBBBRecord(){
    // get bbb recording
    const params = {
      courseid: this.courseId,
      moduleid: this.moduleInput.id
    };
    this.spinner.show();
    this.topicService.listBBBRecord(params).subscribe(
      res => {
        this.spinner.hide();
        this.bbbRecordings = res.body;
      },
      error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      }
    );
  }
}
