import { Component, OnInit } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-document-popup',
  templateUrl: './document-popup.component.html',
  styleUrls: ['./document-popup.component.css']
})
export class DocumentPopupComponent implements OnInit {

  constructor(    private modalService: NgbModal,
    private activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
  }
  close() { 
    this.activeModal.dismiss('close');
  }

}
