import { USER_ROLE } from 'src/app/shared/constants/base.constant';
import { USER_FUNCTIONS } from './../../../shared/constants/base.constant';
import {NgxSpinnerService} from 'ngx-spinner';
import {CourseCategory} from './../../../shared/model/course-category.model';
import {CourseCategoryService} from '../../../shared/services/course-category.service';
import {ActivatedRoute} from '@angular/router';
import {Course} from 'src/app/shared/model/course.model';
import {CourseService} from '../../../shared/services/course.service';
import {ToastrCustomService} from 'src/app/shared/services/toastr-custom.service';
import {FormBuilder, Validators} from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import CommonUtil from 'src/app/shared/utils/common-util';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import {ConfirmModalComponent} from '../../../shared/modal/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {CustomValidators} from '../../../shared/utils/custom-validators';
import {LIST_HOURS, LIST_MINUTES} from '../../../shared/constants/base.constant';

@Component({
  selector: 'app-exam-detail',
  templateUrl: './exam-detail.component.html',
  styleUrls: ['./exam-detail.component.css']
})
export class ExamDetailComponent implements OnInit, OnDestroy {
  courseId: number;
  showCreateQuiz = false;
  checkC = false;
  quizOutput: ModuleInfo;
  examForm = this.fb.group({
    id: [],
    fullname: ['', [Validators.required, CustomValidators.notBlankValidator, Validators.maxLength(200)]],
    startdate: [, Validators.required],
    enddate: [, Validators.required],
    hourStartDate: ['', Validators.required],
    minuteStartDate: ['', Validators.required],
    hourEndDate: ['', Validators.required],
    minuteEndDate: ['', Validators.required],
    categoryid: ['', Validators.required],
  });

  examDetail: any = {
    id: null,
    fullname: '',
    startdate: null,
    enddate: null,
    published: null,
    categoryid: null,
    publishedat: null,
    readonly: false
  };

  hoursList = LIST_HOURS;
  minutesList = LIST_MINUTES;

  startTimeInvalid = false;
  endTimeInvalid = false;

  currentDate: Date = new Date();
  happening: string;
  examId: number;
  formEnable = false;
  courseCategoryList: CourseCategory[] = [];
  categoryMap = new Map();
  role: string;
  isTeacher: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrCustomService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private courseCategoryService: CourseCategoryService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private translateService: TranslateService,
  ) {}


  ngOnInit(): void {
    this.role = this.courseService.getRoleUser();
    if (this.role === USER_ROLE.TEACHER) {
      this.isTeacher = true;
    }
    this.examId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.getInfoExam(this.examId);
    this.examForm.disable();
    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      this.courseCategoryList = response.body;
      CommonUtil.convertCategoryListToMap(this.courseCategoryList, this.categoryMap);
    });
    if (this.happening === undefined || this.happening === null) {
      this.route.queryParams.subscribe(params => {
        if (params) {
          this.happening = params.happening;
        }
      });
    }
    if (this.happening === 'yes') {
      const modalModule = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalModule.componentInstance.title = this.translateService.instant('warning');
      modalModule.componentInstance.body = this.translateService.instant('happening');
      modalModule.componentInstance.confirmButton = this.translateService.instant('ok');
    }
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  updateExam() {

    if (this.examForm.invalid) {
      CommonUtil.markFormGroupTouched(this.examForm);
      return;
    }

    const startdate = CommonUtil.convertDateToTime(
      this.examForm.value.startdate,
      this.examForm.value.hourStartDate,
      this.examForm.value.minuteStartDate
    );
    const enddate = CommonUtil.convertDateToTime(
      this.examForm.value.enddate,
      this.examForm.value.hourEndDate,
      this.examForm.value.minuteEndDate
    );

    if (!this.examDetail.publishedat && (startdate < this.currentDate.getTime() / 1000)) {
      this.startTimeInvalid = true;
      return;
    } else {
      this.startTimeInvalid = false;
    }

    if  (enddate < startdate) {
      this.endTimeInvalid = true;
      return;
    } else {
      this.endTimeInvalid = false;
    }

    const updateExam: Course = {
      id: this.examDetail.id,
      summary: this.examForm.value.summary ? this.examForm.value.summary.trim() : '',
      fullname: this.examForm.value.fullname.trim(),
      startdate: startdate,
      enddate: enddate,
      categoryid: this.examForm.value.categoryid,
      coursetype: 2
    };

    this.spinner.show();
    this.courseService.updateCourse(updateExam, null, true).subscribe(
      res => {
        this.spinner.hide();
        this.toastrService.success('common.noti.update_success');
        this.getInfoExam(this.examId);
        this.examForm.disable();
        this.formEnable = false;
      },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      }
    );
  }

  onUpdateExam() {
    if (this.examDetail.published === 1) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalDep.componentInstance.title = this.translateService.instant('common.caution');
      modalDep.componentInstance.body = this.translateService.instant('common.noti.required_un_publish_exam');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.hide');
      modalDep.result.then((result) => {
        if (result === 'confirm') {
          this.courseService.unPublishCourse(this.examId).subscribe(
            res => {
              this.toastrService.success(`common.noti.un_publish_exam_success`);
              this.examDetail.published = 0;
              this.examForm.enable();
              this.formEnable = true;
              this.happening = 'no';
            },
            err => {
              this.toastrService.handlerError(err);
            }
          );
        }
      });
    } else {
      this.examForm.enable();
      this.formEnable = true;
      if (this.examForm.invalid) {
        CommonUtil.markFormGroupTouched(this.examForm);
        return;
      }
    }

  }

  cancelUpdateExam() {
    this.examForm.disable();
    this.formEnable = false;
    this.initFormExamDetail(this.examDetail);
    this.startTimeInvalid = false;
    this.endTimeInvalid = false;
  }

  initFormExamDetail(exam: Course) {
    const startDate = moment.unix(exam.startdate).toDate();
    const endDate = moment.unix(exam.enddate).toDate();

    this.examForm.patchValue({
      fullname: exam.fullname,
      summary: exam.summary,
      startdate: startDate,
      hourStartDate: startDate.getHours(),
      minuteStartDate: startDate.getMinutes(),
      enddate: endDate,
      hourEndDate: endDate.getHours(),
      minuteEndDate: endDate.getMinutes(),
      categoryid: exam.categoryid
    });

  }

  getInfoExam(courseId: any) {
    this.courseService.getCoursesInfo(courseId).subscribe(response => {
      this.examDetail = response.body;
      this.initFormExamDetail(response.body);
    });
  }

  onPublishExam() {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('exam.publish_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('exam.publish_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.show');
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.spinner.show();
        this.courseService.publishCourse(this.examId).subscribe(
          res => {
            this.examDetail.published = 1;
            if (this.formEnable) {
              this.cancelUpdateExam();
            }
            if (moment.unix(this.examDetail.startdate).toDate()
              <= this.currentDate && this.currentDate <= moment.unix(this.examDetail.enddate).toDate()) {
              this.happening = 'yes';
            }
            this.spinner.hide();
            this.toastrService.success(`common.noti.publish_exam_success`);
          },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          }
        );
      }
    });
  }

  onUnPublishExam() {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('exam.unpublish_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('exam.unpublish_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.hide');
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.spinner.show();
        this.courseService.unPublishCourse(this.examId).subscribe(
          res => {
            this.spinner.hide();
            this.toastrService.success(`common.noti.un_publish_exam_success`);
            this.examDetail.published = 0;
            this.happening = 'no';
          },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          }
        );
      }
    });
  }

  checkRoleFunction(userFunction: any, role: any): boolean {
    return USER_FUNCTIONS[userFunction].includes(role);
  }

  showQuiz(value) {
    this.quizOutput = value;
  }

  handleShowCreateQuiz(event) {
    this.showCreateQuiz = true;
  }

  check(event) {
    this.checkC = event;
  }

  handleShowListQuiz(event) {
    this.showCreateQuiz = false;
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date).toDate();
    }
    return null;
  }

}
