import {Component, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, Validators} from "@angular/forms";
import {UserService} from "../../shared/services/user.service";
import {ToastrCustomService} from "../../shared/services/toastr-custom.service";
import {NgxSpinnerService} from "ngx-spinner";
import {RoleService} from "../../shared/services/role.service";
import {TranslateService} from "@ngx-translate/core";
import {LocalStorageService} from "ngx-webstorage";
import {CustomValidators} from "../../shared/utils/custom-validators";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  usernameForm = this.fb.group({
    username: [null, [Validators.required, CustomValidators.notBlankValidator]],
  });

  emailForm = this.fb.group({
    email: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')]],
  });

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private userService: UserService,
    private toastCustomService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private roleService: RoleService,
    private translateService: TranslateService,
    private $localStorage: LocalStorageService) {
  }

  ngOnInit(): void {
  }

  submit(code?: any): void {
    if (code === 'username') {
      // find username with param is username => return object => exist, otherwise not found
      const username = this.usernameForm.controls['username'].value.trim();
      this.userService.findUserByUsername(username).subscribe(
        (success) => {
          // console.log(success);
          if (success) {
            this.toastCustomService.success('common.noti.send_mail_success');
            this.close();
          } else {
            this.spinner.hide();
            this.toastCustomService.handlerError(success);
          }
        },
        (error) => {
          // console.log(error);
          this.spinner.hide();
          this.toastCustomService.handlerError(error);
        })
    } else if (code === 'email') {
      // find email with param is email => return object => exist, otherwise not found
      const email = this.emailForm.controls['email'].value.trim;
      this.userService.findUserByEmail(email).subscribe(
        (success) => {
          // console.log(success);
          if (success) {
            this.toastCustomService.success('common.noti.send_mail_success');
            this.close();
          } else {
            this.spinner.hide();
            this.toastCustomService.handlerError(success);
          }
        },
        (error) => {
          // console.log(error);
          this.spinner.hide();
          this.toastCustomService.handlerError(error);
        });
    }
  }

  close() {
    this.spinner.hide();
    this.activeModal.dismiss('close');
  }
}
