import {Component, Directive, Input, OnInit} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {USER_INFO, USER_MENU} from '../../../shared/constants/base.constant';

@Component({
  selector: 'app-appmenu',
  templateUrl: './appmenu.component.html',
  styleUrls: ['./appmenu.component.css']
})

export class AppmenuComponent implements OnInit {
  isLoggedIn: boolean;
  menus: any;
  roles: any;

  constructor(private $localStorage: LocalStorageService,) {
  }

  check: boolean;
  checkCourse: boolean;
  checkDepartment: boolean;
  checkReport: boolean;
  course: boolean;
  courseCategory: boolean;
  exam: boolean;
  badge: boolean;
  survey: boolean;
  questionBank: boolean;
  department: boolean;
  position: boolean;
  user: boolean;
  notification: boolean;
  role: boolean;
  report: boolean;
  userInfo:any;
  ngOnInit(): void {
    this.userInfo = this.$localStorage.retrieve(USER_INFO.INFO);
    this.checkCourse = true;
    this.checkDepartment = true;
    this.checkReport = true;
    this.check = false;
    this.menus = this.$localStorage.retrieve('menus');
    this.roles = this.$localStorage.retrieve('roles');
    if (this.roles !== undefined) {
      for (let j = 0; j < this.roles.length; j++) {
        if (this.roles[j].shortname === 'admin') {
          this.check = true;
        }
      }
    }
    if (this.check) {
      this.course = true;
      this.courseCategory = true;
      this.exam = true;
      this.badge = true;
      this.survey = true;
      this.questionBank = true;
      this.department = true;
      this.position = true;
      this.user = true;
      this.notification = true;
      this.role = true;
      this.report = true;
    } else {
      this.course = false;
      this.courseCategory = false;
      this.exam = false;
      this.badge = false;
      this.survey = false;
      this.questionBank = false;
      this.department = false;
      this.position = false;
      this.user = false;
      this.notification = false;
      this.role = false;
      this.report = false;
    }
    if (!this.check) {
      for (let i = 0; i < this.menus.length; i++) {
        if (this.menus[i].code === USER_MENU.POSITION) {
          this.position = true;
        }
        if (this.menus[i].code === USER_MENU.COURSE) {
          this.course = true;
        }
        if (this.menus[i].code === USER_MENU.COURSE_CATEGORY) {
          this.courseCategory = true;
        }
        if (this.menus[i].code === USER_MENU.EXAM) {
          this.exam = true;
        }
        if (this.menus[i].code === USER_MENU.BADGE) {
          this.badge = true;
        }
        if (this.menus[i].code === USER_MENU.SURVEY) {
          this.survey = true;
        }
        if (this.menus[i].code === USER_MENU.QUESTION_BANK) {
          this.questionBank = true;
        }
        if (this.menus[i].code === USER_MENU.DEPARTMENT) {
          this.department = true;
        }
        if (this.menus[i].code === USER_MENU.USER) {
          this.user = true;
        }
        if (this.menus[i].code === USER_MENU.NOTIFICATION) {
          this.notification = true;
        }
        if (this.menus[i].code === USER_MENU.ROLE) {
          this.role = true;
        }
        if (this.menus[i].code === USER_MENU.REPORT) {
          this.report = true;
        }
      }
    }
    if (!this.course && !this.courseCategory && !this.exam && !this.badge && !this.survey && !this.questionBank) {
      this.checkCourse = false;
    }
    if (!this.department && !this.position && !this.user) {
      this.checkDepartment = false;
    }
    if (!this.notification && !this.role) {
      this.checkDepartment = false;
    }
  }

}
