import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {FormBuilder, Validators, FormArray, FormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SurveyService} from '../../../shared/services/survey.service';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import { EventManagerService } from 'src/app/shared/services/event-manager.service';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';
import {CustomValidators} from "../../../shared/utils/custom-validators";

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.css']
})
export class EditQuestionComponent implements OnInit {

  @Input() question: any;
  @Input() questionNaireId: any;
  @Input() checkChooseTemplate = false;
  @Output() quesUpdate = new EventEmitter<any>();

  Editor = ClassicEditor;
  editorConfig = CKEDITOR_CONFIG.NOT_UPLOAD;

  isText: boolean;
  isRate: boolean;
  isSelect: boolean;
  strContent: any = '';
  anwserNumber = 1;
  constructor(private fb: FormBuilder,
              public activeModal: NgbActiveModal,
              private surveyService: SurveyService,
              private toastrService: ToastrCustomService,
              private eventManager: EventManagerService) {
  }


  createTextBoxQuestion = this.fb.group({
    textContent: ['', Validators.required],
    forTeacher: ['', Validators.required]
  });

  createRateQuestion = this.fb.group({
    textContent: ['', Validators.required],
    forTeacher: ['', Validators.required],
    length: ['5', [CustomValidators.checkRangeValue(1, 10), Validators.required]],
    allnameddegrees: [''],
  });

  createSelectQuestion = this.fb.group({
    textContent: ['', Validators.required],
    selectContent: this.fb.array([]),
    forTeacher: ['', Validators.required]
  });

  ngOnInit(): void {
    if (this.question.type === 'Rate (scale 1..5)') {
      let allnameddegrees = '';
      const extradata = JSON.parse(this.question.extradata);
      Object.entries(extradata).forEach(
        (entry) => {
          const [key, value] = entry;
          allnameddegrees += key + '=' + value + '\n';
        }
      );
      this.isRate = true;
      this.isText = false;
      this.isSelect = false;
      this.createRateQuestion.patchValue({
        textContent: this.question.content,
        forTeacher: this.question.isteacher,
        length: this.question.length,
        allnameddegrees,
      });
    }
    if (this.question.type === 'Text Box') {
      this.isText = true;
      this.isRate = false;
      this.isSelect = false;
      this.createTextBoxQuestion.patchValue({
        textContent: this.question.content,
        forTeacher: this.question.isteacher
      });
    }
    if (this.question.type === 'Radio Buttons') {
      this.isSelect = true;
      this.isText = false;
      this.isRate = false;
      if (this.question.choices !== undefined) {
        for (let i = 0; i < this.question.choices.length; i++) {
          this.addAnswer({
              text: this.question.choices[i].content
          });
        }
      }

      this.createSelectQuestion.patchValue({
        textContent: this.question.content,
        forTeacher: this.question.isteacher
      });
    }
  }

  updateQuestion() {
    const params = {
      qnid: this.questionNaireId,
      qsid: this.question.id,
      questioncontent: null,
      questionchoice: null,
      isteacher: 0,
      questionname: name,
      length: 5,
      allnameddegrees: ''
    };
    if (this.isText) {
      if (this.createTextBoxQuestion.invalid) { return; }
      const array: any[] = [1];
      params.questionchoice = array;
      params.questioncontent = this.createTextBoxQuestion.value.textContent;
      params.isteacher = this.createTextBoxQuestion.value.forTeacher;
      this.surveyService.updateQuestion(params)
        .subscribe(res => {
          this.toastrService.success('common.noti.update_success');
          this.notiSaveSuccess();
          this.quesUpdate.emit(true);
        }, error => {
          this.toastrService.handlerError(error);
        });
    } else if (this.isRate) {
      if (this.createRateQuestion.invalid) { return; }
      const arrayAnswer: any[] = ['Bình chọn'];
      params.questionchoice = arrayAnswer;
      params.questioncontent = this.createRateQuestion.value.textContent;
      params.isteacher = this.createRateQuestion.value.forTeacher;
      params.length = this.createRateQuestion.value.length;
      params.allnameddegrees = this.createRateQuestion.value.allnameddegrees;
      this.surveyService.updateQuestion(params).subscribe(res => {
          this.toastrService.success('common.noti.update_success');
          this.notiSaveSuccess();
          this.quesUpdate.emit(true);
        }, error => {
          this.toastrService.handlerError(error);
        });
    } else {
      if (this.createSelectQuestion.invalid) { return; }
      let arrayAnswer: any[] = [];
      this.createSelectQuestion.value.selectContent.forEach(element => {
        arrayAnswer.push(element.text);
      });
      params.questionchoice = arrayAnswer;
      params.questioncontent = this.createSelectQuestion.value.textContent;
      params.isteacher = this.createSelectQuestion.value.forTeacher;
      this.surveyService.updateQuestion(params).subscribe(res => {
          this.toastrService.success('common.noti.update_success');
          this.notiSaveSuccess();
          this.quesUpdate.emit(true);
        }, error => {
          this.toastrService.handlerError(error);
        });
    }

    this.activeModal.dismiss();
  }

  notiSaveSuccess() {
    this.eventManager.broadcast({
      name: 'updateQuestionSuccess',
      content: 'Update Question Success',
    });
  }

  //------for select question

  get selectContent(): FormArray {
    return this.createSelectQuestion.get('selectContent') as FormArray;
  }

  addAnswer(answer?: any) {
    this.anwserNumber += 1;
    this.selectContent.push(this.newAnwser(answer));
  }

  newAnwser(answer?: any): FormGroup {
    let form = this.fb.group({
      text: ['', [Validators.required]]
    });
    if (answer) {
      form.controls['text'].setValue(answer.text);
    }
    return form;
  }

  removeAnswer(i: number) {
    this.selectContent.removeAt(i);
  }

  onKeydown(event){
    event.preventDefault();
  }
}
