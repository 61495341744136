<div class="modal-header">
  <h5 class="modal-title sfbold text-dark">{{'exam_result.title' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="outline:none;" (click)="activeModal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center overflow-auto" style="height: 80vh;">
  <table class="table table-striped table-hover" *ngIf="examResult?.length > 0">
    <thead>
      <tr class="bg-blue">
        <th class="text-center text-white sfbold">STT</th>
        <th class="text-center text-white sfbold">Bài thi</th>
        <th class="text-center text-white sfbold">Lượt thi</th>
        <th class="text-center text-white sfbold">Ngày thi</th>
        <th class="text-center text-white sfbold">Thời gian làm bài</th>
        <th class="text-center text-white sfbold">Kết quả</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of examResult; let i = index">
        <td class="text-center">{{i + 1}}</td>
        <td class="text-center">{{item.name}}</td>
        <td class="text-center">{{item.attempt}}</td>
        <td class="text-center">{{getDateFromUnix(item.timestart)}}</td>
        <td class="text-center">{{item.timeResult}}</td>
        <td class="text-center">{{item.gradeuser + "/" + item.grade}}</td>
      </tr>
    </tbody>
  </table>
  <b *ngIf="examResult?.length === 0">Bạn chưa có bài thi nào</b>
</div>
<div class="modal-footer">
  <div class="m-auto">
    <button type="button" class="btn btn-primary" style="font-size: 12pt;" (click)="activeModal.close('confirm')">{{'common.close' |
      translate}}</button>
  </div>

</div>
<ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
  <p class="loading">{{ 'common.loading' | translate }}</p>
</ngx-spinner>
