import {FILE_UPLOAD} from './../../../shared/constants/base.constant';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {IntroeditorModel} from '../../../shared/model/introeditor.model';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {ModuleModel} from '../../../shared/model/module.model';
import CommonUtil from '../../../shared/utils/common-util';
import {TopicService} from '../../../shared/services/topic.service';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FileService} from '../../../shared/services/file.service';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';
@Component({
  selector: 'app-resource-content',
  templateUrl: './resource-content.component.html',
  styleUrls: ['./resource-content.component.css']
})
export class ResourceContentComponent implements OnInit, OnChanges {




  @Input() module: ModuleModel;
  @Input() courseId: number;
  @Input() section: number;
  @Input() courseReadOnly: boolean;
  @Input() courseFinished: boolean;

  @Output() hasChange = new EventEmitter<boolean>();
  @Output() reLoadTopics = new EventEmitter<number>();

  @ViewChild('videoCourse') videoCourse: ElementRef;

  public Editor = ClassicEditor;

  editorConfig = CKEDITOR_CONFIG.DEFAULT;

  fileInvalid = false;

  courseModule: ModuleInfo = {
    name: '',
    course: this.courseId,
    section: this.section,
    introeditor: null,
    modulename: 'resource'
  };

  fileToUpload: File = null;
  file: File;
  introeditor: IntroeditorModel;
  editorData = '';
  title = 'ng8fileuploadexample';
  validateDes = true;
  verifyFile = true;
  moduleForm = this.fb.group({
    name: ['', Validators.required],
    timelimit: ['', Validators.required]
  });

  urlSafe: SafeResourceUrl;

  isNew = false;
  isCreateOrEdit = false;
  teachers = '';
  isVideoResourse: boolean;

  constructor(
    private translateService: TranslateService,
    private topicService: TopicService,
    private toastrService: ToastrCustomService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    private  sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Mac dinh la Tao bai giang moi
    this.courseModule.id = null;
    this.urlSafe = '';
    this.moduleForm.patchValue({
      name: '',
      timelimit: null
    });
    this.editorData = '';
    this.courseModule.filename = '';
    this.fileToUpload = null;
    this.isNew = true;
    this.isCreateOrEdit = true;
    this.moduleForm.reset();
    // Neu co data => update bai giang
    if (this.module) {
      // Cap nhat du lieu
      this.spinner.show();
      this.isNew = false;
      this.isCreateOrEdit = false;
      this.topicService.getModuleDetail(this.module.id).subscribe(res => {
        this.courseModule = res.body;
        this.teachers = res.body.teachers.length > 0 ? CommonUtil.convertTeacherName(res.body.teachers) : 'Chưa có giảng viên được gán';
        this.moduleForm.patchValue({
          name: this.courseModule.name,
          timelimit: this.courseModule.timelimit / 60
        });
        this.editorData = this.courseModule.intro;
        const urlFile = this.fileService.getFileUrl(this.courseModule.contextid, this.courseModule.filename, '/mod_resource/content/0/');
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
        if (FILE_UPLOAD.MIME_TYPE_RESOURCE.includes(this.courseModule.mimetype)) {
          this.videoCourse?.nativeElement.load();
          this.isVideoResourse = true;
        } else {
          this.isVideoResourse = false;
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      });
    }
  }


  // type document file valid: doc, docx, xlm, xlsx, xlsm, pdf, powerpoint, rar, zip, excel, jpg, npg, ppt, pptx, jar, gif, exe, html, htm
  handleFileInput(files: FileList) {
    this.verifyFile = true;
    if (!files?.[0].type) {
      if (files[0].name.lastIndexOf('.flv') !== -1) {
        this.fileToUpload = files.item(0);
        this.fileInvalid = false;
        this.hasChange.emit(true);
        return;
      }
    } else {
      for (const type of FILE_UPLOAD.MIME_TYPE_RESOURCE) {
        if (files?.[0].type && files?.[0].type.includes(type)) {
          this.fileToUpload = files.item(0);
          this.fileInvalid = false;
          this.hasChange.emit(true);
          return;
        }
      }
    }
    const param = {file_name: files[0].name};
    this.translateService.get(`common.noti.img_invalid`, param).subscribe(message => {
      this.toastrService.error(message);
    });
    this.fileToUpload = null;
    this.fileInvalid = true;
  }

  submitForm() {
    // validate de hien thi message loi
    if (this.moduleForm.invalid || this.editorData === '' || (this.fileToUpload === null && this.isNew)) {
      if (this.moduleForm.invalid){
        CommonUtil.markFormGroupTouched(this.moduleForm);
      }
      if (this.editorData === '') {
        this.validateDes = false;
      } else {
        this.validateDes = true;
      }
      if (this.fileToUpload === null && this.isNew) {
        this.verifyFile = false;
      } else {
        this.verifyFile = true;
      }
      return;
    }

    this.introeditor = {text: '', format: 1, itemid: 0};

    this.spinner.show();
    const createOrUpdate: ModuleInfo = {
      name: '',
      course: this.courseId,
      section: this.section,
      introeditor: null,
      modulename: 'resource'
    };

    createOrUpdate.name = this.moduleForm.value.name;
    createOrUpdate.timelimit = this.moduleForm.value.timelimit * 60;
    this.introeditor.text = this.editorData;
    createOrUpdate.introeditor = this.introeditor;

    if (!this.courseModule.id) {
      // Tao bai giang moi
      this.topicService.createResourceContent(createOrUpdate, this.fileToUpload).subscribe(res => {
        this.courseModule.id = res.body.coursemodule;
        this.courseModule.name = this.moduleForm.value.name;
        this.courseModule.timelimit = this.moduleForm.value.timelimit * 60;
        this.courseModule.intro = this.editorData;
        this.courseModule.filename = this.fileToUpload.name;
        this.courseModule.mimetype = this.fileToUpload.type;
        this.courseModule.contextid = res.body.contextid;
        this.fileToUpload = null;
        const urlFile = this.fileService.getFileUrl(this.courseModule.contextid, this.courseModule.filename, '/mod_resource/content/0/');
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
        this.spinner.hide();
        this.toastrService.success(`common.noti.create_success`);
        this.isCreateOrEdit = false;
        this.isNew = false;
        this.moduleForm.reset();
        this.hasChange.emit(false);
        this.reLoadTopics.emit(res.body?.coursemodule);
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
    } else {
      // Cap nhat bai giang cu
      createOrUpdate.coursemodule = this.courseModule.id;
      this.topicService.updateResourceContent(createOrUpdate, this.fileToUpload).subscribe(res => {
        this.courseModule.name = this.moduleForm.value.name;
        this.courseModule.timelimit = this.moduleForm.value.timelimit * 60;
        this.courseModule.intro = this.editorData;
        if (this.fileToUpload) {
          this.courseModule.filename = this.fileToUpload.name;
          this.courseModule.mimetype = this.fileToUpload.type;
          this.courseModule.contextid = res.body.contextid;
        }
        this.spinner.hide();
        const urlFile = this.fileService.getFileUrl(this.courseModule.contextid, this.courseModule.filename, '/mod_resource/content/0/');
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
        this.toastrService.success(`common.noti.update_success`);
        this.isCreateOrEdit = false;
        this.isNew = false;
        this.moduleForm.reset();
        this.hasChange.emit(false);
        this.reLoadTopics.emit(res.body?.coursemodule);
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
    }

  }

  cancel() {
    this.isCreateOrEdit = false;
  }

  public onIntroChange({editor}: ChangeEvent) {
    if (!editor) {
      return;
    }
    const data = editor.getData();
    this.editorData = data;
    this.validateDes = true;
    this.hasChange.emit(true);
  }

  numericOnly(event): boolean {
    const patt = /^([0-9])$/;
    return patt.test(event.key);
  }

  showEdit() {
    this.spinner.show();
    this.topicService.prepareDataForUpdate(this.courseModule.id).subscribe(
      res => {
        this.editorData = res.body.intro;
        this.spinner.hide();
        this.isCreateOrEdit = true;
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      }
    );
  }

  onDataChange() {
    this.hasChange.emit(true);
  }


}
