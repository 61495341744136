
<div class="modal-header">
  <h5 class="modal-title" *ngIf="!surveyId&& coursetype==1&&!isNotify && !isReportSreen">Chọn danh sách khóa học</h5>
  <h5 class="modal-title" *ngIf="isNotify">Chọn khóa học cần thông báo</h5>
  <h5 class="modal-title" *ngIf="isReportSreen">Chọn khóa học cần báo cáo</h5>
  <h5 class="modal-title" *ngIf="surveyId">Chọn khóa học cần xem khảo sát</h5>
  <h5 class="modal-title" *ngIf="coursetype==2">Chọn danh sách kì thi</h5>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
  (click)="activeModal.dismiss()"><i class="fas fa-times"></i>
</button>
</div>
<div class="modal-body">
       <div class="row">
          <div class="form-row">
            <div class="col-auto">
              <form class="form-inline float-right" role="form">
                <input name="search" [(ngModel)]="search" type="text" class="form-control" placeholder="Tìm kiếm..." (change)="onSearch()">
              </form>
            </div>
          </div>
            <div class="col-12 mt-3 table-responsive overflow-auto" style="height: 50vh;">
                  <table class="table table-hover">
                    <thead>
                    <tr *ngIf="coursetype==1">
                      <th>
                        <mat-icon class="mt-1">
                          <mat-checkbox *ngIf="!surveyId" class="example-margin" [(ngModel)]="completedAll" (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                        </mat-icon>
                      </th>
                      <th>STT</th>
                      <th>Tên khóa học</th>
                      <th>Từ ngày</th>
                      <th>Đến ngày</th>
                      <th>Đơn vị chủ trì</th>
                      <th class="d-none">Chức danh</th>
                    </tr>
                    <tr *ngIf="coursetype==2">
                      <th>
                        <mat-icon class="mt-1">
                          <mat-checkbox class="example-margin" [(ngModel)]="completedAll" (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                        </mat-icon>
                      </th>
                      <th>STT</th>
                      <th>Tên kì thi</th>
                      <th>Từ ngày</th>
                      <th>Đến ngày</th>
                      <th class="">Danh Mục</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="coursetype==1">
                      <tr *ngFor="let item of courses; let i = index;">
                        <td style="width:25px;" >
                          <mat-icon class="mt-1">
                            <mat-checkbox *ngIf="!surveyId" [(ngModel)]="item.completed" (change)="oncheckboxItem(item.id, $event.checked)" class="example-margin"></mat-checkbox>
                            <mat-radio-button *ngIf="surveyId" (change)="onRadiocheckboxItem(item.id)"></mat-radio-button>
                          </mat-icon>
                        </td>
                        <td style="width:30px;" >{{(pageIndex - 1) * pageSize + i + 1}}</td>
                        <td *ngIf="!surveyId && !isNotify">{{item.fullname.length <= 40 ? item.fullname : (item.fullname | slice:0:40) + '...' }}</td>
                        <td *ngIf="surveyId">{{item.fullname.length <= 40 ? item.fullname : (item.fullname | slice:0:40) + '...' }}</td>
                        <td *ngIf="isNotify">{{item.fullname.length <= 40 ? item.fullname : (item.fullname | slice:0:40) + '...' }}</td>
                        <td>{{getDateFromUnix(item.startdate) | date:'dd/MM/yyyy'}}</td>
                        <td>{{getDateFromUnix(item.enddate) | date:'dd/MM/yyyy'}}</td>
                        <td>{{item.departmentname}}</td>
                        <td class="d-none">{{item.positionname}}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="coursetype==2">
                      <tr *ngFor="let item of courses; let i = index;">
                        <td style="width:25px;" >
                          <mat-icon class="mt-1">
                            <mat-checkbox [(ngModel)]="item.completed" (change)="oncheckboxItem(item.id, $event.checked)" class="example-margin"></mat-checkbox>
                          </mat-icon>
                        </td>
                        <td style="width:30px;" >{{(pageIndex - 1) * pageSize + i + 1}}</td>
                        <td *ngIf="!surveyId && !isNotify">{{item.fullname.length <= 40 ? item.fullname : (item.fullname | slice:0:40) + '...' }}</td>
                        <td *ngIf="surveyId">{{item.fullname.length <= 40 ? item.fullname : (item.fullname | slice:0:40) + '...' }}</td>
                        <td *ngIf="isNotify">{{item.fullname.length <= 40 ? item.fullname : (item.fullname | slice:0:40) + '...' }}</td>
                        <td>{{getDateFromUnix(item.startdate) | date:'dd/MM/yyyy'}}</td>
                        <td>{{getDateFromUnix(item.enddate) | date:'dd/MM/yyyy'}}</td>
                        <td class="d-block">{{item.categoryname}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <p *ngIf="courses?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
                </div>
        </div>
    </div>
    <div class="card-footer text-center">
      <b *ngIf="!surveyId">Đã chọn: {{courseIdsChecked?.length}}</b>
      <div class="row">
        <mat-paginator showFirstLastButtons
            [length]="totalRecord"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex - 1"
            (page)="changePage($event)"
            [hidePageSize]="false"
            [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </div>
    </div>
<div class="modal-footer">
    <button type="button" (click)="cancel()" class="btn btn-default round-0 mr-2"><i class="fas fa-close"></i>Hủy</button>
    <button type="button" (click)="save()"  class="btn btn-primary"  ><i class="fas fa-save mr-1"></i>Lựa Chọn</button>
</div>


