<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Kết quả thi</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
            <li class="breadcrumb-item active">Báo cáo thi</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-3 mb-3">
              <div class="col-3">
                <div class="form-group form-inline">
                  <label for="">Lựa chọn kỳ thi</label>
                  <select class="form-control form-control-sm ml-2" name="" id="" [(ngModel)]="searchExam.coursetype"
                    (change)="onChangeType($event)">
                    <option value="">Toàn bộ</option>
                    <option value="1">Theo khóa học</option>
                    <option value="2">Độc lập</option>
                  </select>
                </div>
              </div>
              <div class="col-9">
                <div class="form-group form-inline float-right">
                  <label for="">Tìm kiếm</label>
                  <input type="search" #keyword (change)="onSearch($event)"
                    class="form-control form-control-sm ml-2" name="" style="width:250px;" id=""
                    aria-describedby="helpId" placeholder="Tìm kiếm tên kỳ thi, khóa học">
                  <button type="button" (click)="onExportListExamReport()" mattooltip="Xuất File Excel"
                    mattooltipclass="tooltip-red" class="btn btn-default btn-lg ml-1"><i
                      class="fas fa-file-excel"></i></button>
                  <!-- <button class="btn btn-default rounded-0 ml-2"><i class="fas fa-search mr-2"></i> Tìm kiếm nâng
                    cao</button> -->
                </div>
              </div>
            </div>
            <label for="">Danh sách</label>
            <div class="table-responsive">
              <table class="table table-hover table-striped">
                <thead>
                  <tr>
                    <th>TT</th>
                    <th style="width:280px;">Tên khóa học/kỳ thi</th>
                    <!-- <th>Tên khóa học</th> -->
                    <th class="text-center">Số lượng bài thi</th>
                    <th class="text-center">Học viên tham gia</th>
                    <th class="text-center">HV hoàn thành</th>
                    <th class="text-center">HV chưa hoàn thành</th>
                    <th>Thao tác</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let course of courses; let i = index;">
                    <td>{{(pageIndex - 1) * pageSize + i + 1}}</td>
                    <td>{{course.fullname?.length <= 40 ? course.fullname : (course.fullname | slice:0:40) + '...' }}</td>
                    <td class="text-center">{{course?.numberofquiz ? course?.numberofquiz : 0}}</td>
                    <td class="text-center">{{course?.totalstudent}}</td>
                    <td class="text-center">{{course?.studentpassed}}</td>
                    <td class="text-center">{{course?.studentfailed}}</td>
                    <td> <a class="btn btn-default btn-sm" [routerLink]="['/admin/result-exam/detail', course.id]"
                        (click)="goToDetail()"><i class="fas fa-eye"></i></a>
                      <button class="btn btn-default btn-sm ml-2" (click)="onExport(course.id)"><i class="fas fa-file-excel"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br>
              <p *ngIf="courses?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
            </div>
            <div class="card-footer text-center"  *ngIf="courses?.length > 0">
              <div class="row">
                <mat-paginator showFirstLastButtons [length]="totalRecord" [pageSize]="pageSize"
                  [pageIndex]="pageIndex - 1 " (page)="changePage($event)" [hidePageSize]="false"
                  [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
