<section class="inner_page_breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 text-center">
        <div class="breadcrumb_content mt-2">
          <h4 class="breadcrumb_title">CHỨNG CHỈ {{certificate.name | uppercase}}</h4>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="/home">Trang chủ</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section>
<main>
  <section id="certificate">
      <div class="container">
        <div class="row">
          <div class="col-6"></div><div class="col-6"><div class="d-flex align-items-center float-right">
          </div></div></div>
          <div class="certificate">
            <div class="namecourse text-uppercase ">
                {{certificate.name}}
            </div>
            <div class="usercertificate text-uppercase font-weight-bold">
              {{ userInfo.firstname }} {{userInfo.lastname}}
            </div>
            <div class="datecertificate text-uppercase">
              <p>Ngày cấp:  <strong>{{getDateFromUnix(certificate.dateissued) | date:'dd/MM/yyyy'}}</strong></p>
            </div>
            <div class="dateexpired text-uppercase">
              <!-- <p>Ngày hết hạn: <strong>{{item.dateexpire ? (getDateFromUnix(item.dateexpire) | date:'dd/MM/yyyy') : 'Vô thời hạn'}}</strong></p> -->
              <p>Số hiệu: <strong>MOBIFONE-{{certificate.issuedid}}</strong></p>
            </div>
            <br />
        </div>
      </div>
  </section>
</main>
