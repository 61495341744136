<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Quản lý Khảo sát</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý khảo sát</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <section class="content">
    <div class="container-fluid">
      <!--phan danh sach khoa hoc-->
      <mat-tab-group [selectedIndex]="searchSurvey.currentTabIndex" (selectedTabChange)="onTabChange($event)">
        <mat-tab>
          <ng-template mat-tab-label>
           Mẫu khảo sát khóa học
          </ng-template>
          <app-survey-single></app-survey-single>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
          Khảo sát độc lập
          </ng-template>
         <app-survey-general></app-survey-general>
        </mat-tab>
      </mat-tab-group>

    </div><!-- /.container-fluid -->
  </section>
</div>
