<div class="container">
  <div class="row">
    <div class="col-lg-12 col-md-6 box-object">
      <div class="bs-example" *ngIf="listExam?.length >0; else noExam">
        <div class="row" *ngIf="!isTeacher">
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box">
              <!-- <span class="info-box-icon bg-info opactity_bg"><i class="fas fa-pen-fancy"></i></span> -->

              <div class="info-box-content">
                <span class="info-box-text">Số bài thi <small></small></span>
                <h2 class="info-box-number text-primary">
                  {{numberOfExam}}
                </h2>
              </div>
              <!-- /.info-box-content -->
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box">
              <!-- <span class="info-box-icon bg-info opactity_bg"><i class="fas fa-pen-fancy"></i></span> -->

              <div class="info-box-content">
                <span class="info-box-text">Bài thi qua<small></small></span>
                <h2 class="info-box-number text-success">
                  {{numberOfExamPassed}}
                </h2>
              </div>
              <!-- /.info-box-content -->
            </div>
          </div>
          <div class="clearfix hidden-md-up"></div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box">
              <!-- <span class="info-box-icon bg-info opactity_bg"><i class="fas fa-pen-fancy"></i></span> -->

              <div class="info-box-content">
                <span class="info-box-text">Bài thi chưa qua <small></small></span>
                <h2 class="info-box-number text-danger">
                  {{numberOfExamFailed}}
                </h2>
              </div>
              <!-- /.info-box-content -->
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box">
              <!-- <span class="info-box-icon bg-info opactity_bg"><i class="fas fa-pen-fancy"></i></span> -->

              <div class="info-box-content">
                <span class="info-box-text">Bài thi chưa thi <small></small></span>
                <h2 class="info-box-number text-secondary">
                  {{numberOfExamNotTaken}}
                </h2>
              </div>
            </div>
          </div>

        </div>
        <table class="table table-hover">
          <thead>
          <tr class="bg-light">
            <th >STT</th>
            <th >Bài thi</th>
            <th >Bắt đầu</th>
            <th >Kết thúc</th>
            <th >Thời gian(phút)</th>
            <th *ngIf="!isTeacher">Số lần làm bài</th>
            <th >Cách thức lấy điểm</th>
            <th class="text-center" *ngIf="!isTeacher">Điểm</th>
            <th class="text-center" *ngIf="!isTeacher">Trạng thái</th>
            <th >Thao tác</th>
          </tr>
          </thead>
          <tbody >
          <tr *ngFor="let item  of listExam ; let i = index;">
            <td>{{i+1}}</td>
            <td  style="width:450px;">{{item.name}}</td>
            <td>{{getDateFromUnix(item.timeopen) | date:'dd/MM/yyyy'}}</td>
            <td>{{getDateFromUnix(item.timeclose) | date:'dd/MM/yyyy'}}</td>
            <td class="text-center">{{item.timelimit/60}}</td>
            <td class="text-center" *ngIf="!isTeacher">{{item.attempts}}</td>
            <td class="text-center">{{item.grademethodName | translate}}</td>
            <td class="text-center" *ngIf="!isTeacher">{{item.usegades}}/{{item.grade}}</td>
            <td class="text-center" *ngIf="!isTeacher"><span class="badge badge-success">{{item.completionStateName | translate}}</span></td>
            <td class="text-center">
              <button type="button" *ngIf="!isTeacher" (click)="localStore(item.name, item.overduehandling)" [disabled]="getDateFromUnix(course?.enddate)?.toDate() < currentDate" [routerLink]="['/exam-details',item.id]"
                      class="btn btn-sm btn-primary">
                Vào thi
              </button>
              <button type="button" *ngIf="isTeacher" (click)="localStore(item.name, item.overduehandling)" [routerLink]="['/exam-preview',item.id]"
                      class="btn btn-sm btn-primary">
                Xem
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <ng-template #noExam>
        <div class="row">
          <div class="col-12 text-center border bg-light p-3">
            <img src="/assets/img-program.svg" alt="">
            <p>{{'common.no_user_test' | translate}}</p>
          </div>
        </div>
        <!-- <div class="no-course text-center p-3">{{'common.no_record_enduser' | translate}}</div> -->
      </ng-template>
    </div>
  </div>
</div>
