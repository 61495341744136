<form role="form" [formGroup]="questionForm">
  <select name="level" formControlName="level" class="form-control">
    <option value>Chọn chủ đề câu hỏi</option>
    <option [value]="topic.value" *ngFor="let topic of TOPIC_CONST">
      {{topic.lable}}
    </option>
  </select>
  <div
    *ngIf="questionForm.get('level')!.invalid && (questionForm.get('level')!.dirty || questionForm.get('level')!.touched)">
    <small class="text-danger" *ngIf="questionForm.get('level')?.errors?.required">
      {{ 'error.validate.input_required' | translate }}
    </small>
  </div>
  <input type="text" formControlName="id" hidden>
  <input type="text" formControlName="questiontext" hidden>
  <mat-action-row *ngIf="canEdit">
    <button type="button" matTooltip="Chỉnh sửa" (click)="onTurnOnEditmode()"
            [disabled]="isPublished" *ngIf="isViewMode"
            matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i class="fas fa-edit"></i>
    </button>
    <button type="button" matTooltip="Lưu" (click)="onSaveQuestion(questionForm.value)"
            [disabled]="isPublished || questionForm.invalid" *ngIf="!isViewMode"
            matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i
      class="fas fa-save mr-1"></i></button>
    <button type="button" matTooltip="Xóa"
            [disabled]="isPublished" (click)="onRemoveQuestion()"
            matTooltipClass="tooltip-red"
            class="btn btn-default btn-lg"><i class="fas fa-trash"></i></button>
  </mat-action-row>
</form>


