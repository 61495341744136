<section class="inner_page_breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 text-center">
        <div class="breadcrumb_content mt-1">
          <h4 class="breadcrumb_title">{{course?.fullname}}</h4>
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <a routerLink="/home">Trang chủ</a>
            </li>
            <li class="breadcrumb-item">
              <a routerLink="/my-course">Khóa học</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#"></a>
            </li>
          </ol>
          <div class="progress mt-0" style="margin-left: 20%; margin-right: 20%;" *ngIf="!isTeacher">
            <div class="progress-bar progress-bar-success" role="progressbar" attr.aria-valuenow="{{progress}}"
                 aria-valuemin="0"
                 aria-valuemax="100" [ngStyle]="{'width.%': progress}">{{progress}}% Hoàn thành
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
</section>

<main id="main" *ngIf="permission; else notPermission" style="min-height: 65vh;">
  <!---Phan noi dung bai hoc-->
  <section id="tabs" class="p-0">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav>
            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a class="nav-item" [ngClass]="{
                          'active': openTab==1,
                           'nav-link': true}" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab"
                 aria-controls="nav-home" aria-selected="true"><i class="icofont-learn"></i> Nội dung</a>
              <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab"
                 aria-controls="nav-profile" aria-selected="false"><i class="icofont-info-circle"></i>Thông tin</a>
              <a *ngIf="!isTeacher && getDateFromUnix(course?.startdate)?.toDate() < currentDate" class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab"
                 aria-controls="nav-contact" aria-selected="false">
                <i class="icofont-history"></i> Lịch sử học tập</a>
              <a *ngIf="isTeacher || (!isTeacher && getDateFromUnix(course?.startdate)?.toDate() < currentDate)" class="nav-item" [ngClass]="{
                          'active': openTab==2,
                           'nav-link': true}" id="nav-exam-tab" data-toggle="tab" href="#nav-exam" role="tab"
                 aria-controls="nav-exam" aria-selected="false">
                <i class="icofont-instrument mr-2"></i> Bài Thi </a>
              <a *ngIf="!isTeacher && getDateFromUnix(course?.startdate)?.toDate() < currentDate" class="nav-item nav-link" id="nav-quiz-tab" data-toggle="tab" href="#nav-quiz" role="tab"
                 aria-controls="nav-quiz" aria-selected="false">
                <i class="icofont-paper"></i> Khảo sát</a>
            </div>
          </nav>
          <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
            <div class="tab-pane fade" [ngClass]="{
                          'active': openTab==1,
                           'show': openTab==1}" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              <app-eu-course-content [courseId]="courseId" [course]="course"></app-eu-course-content>
            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <div class="container profile-page ">
                <h6 class="">THÔNG TIN</h6>
                <div class="row mb-3">
                  <div class="col-4">
                    <img [src]="courseImageUrl" class="rounded" style="height: 25vh; width: 100%;" alt="">
                  </div>
                  <div class="col-8">
                    <h6 class="text-dark">Mô tả khóa học</h6>
                    <div [innerHTML]="course?.summary" class="overflow-auto"></div>
                    <a href="javascript:;" class="linkedin">
                      <i class="icofont-calendar mr-2"></i>
                      <span style="font-size: 10pt;">{{getDateFromUnix(course?.startdate) | date:'dd/MM/yyyy'}} - {{getDateFromUnix(course?.enddate) | date:'dd/MM/yyyy'}} </span></a>
                      <div class="mt-4" *ngIf="course?.enrol === 'self'">
                        <button class="btn btn-outline-primary" (click)="onUnRegisterCourse()">Hủy đăng ký khóa học</button>
                      </div>
                    </div>
                </div>
                <h6 class="">GIẢNG VIÊN</h6>
                <div class="row" *ngIf="listTeacher.results?.length > 0;  else elseBlock">
                  <div class="col-4" *ngFor="let teacher of listTeacher.results">
                    <div class="card">
                      <div class="card-body" style="display: flex;">
                        <!-- avatar default -->
                        <div class="person-circle chr-{{teacher.firstname | uppercase | slice:0:1}}"
                             *ngIf="!teacher.userpictureurl"
                             style="cursor: pointer;">
                          {{teacher.firstname | uppercase | slice:0:1 }}{{teacher.lastname | uppercase | slice:0:1 }}
                        </div>

                        <!-- avatar custom-->
                        <img class="person-circle"
                             *ngIf="teacher.userpictureurl"
                             src="{{teacher.userpictureurl}}"
                             alt=""/>

                        <div class="" style="max-width: 315px;">
                          <h6 class="m-t-0 m-b-0 text-primary"><span>{{teacher.firstname}}</span><span class="ml-1">{{teacher.lastname}}</span></h6>
                          <!-- <span class="job_post">Giảng viên BA</span> -->
                          <div mb-0><i class="icofont-stock-mobile mr-2"></i>{{teacher.phone1}}</div>
                          <div mt-0><i class="icofont-mail mr-2"></i>{{teacher.email}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-template #elseBlock>
                  <div class="no-course text-center p-3">{{'common.no_record_enduser' | translate}}</div>
                </ng-template>
                <h6 class="">HỌC VIÊN</h6>
                <div class="row" *ngIf="listOtherStudent?.length > 0; else hasNotStudents">
                  <div class="col-4" *ngFor="let student of listOtherStudent">
                    <div class="card">
                      <div class="card-body" style="display: flex;">
                        <!-- avatar default-->
                        <div class="person-circle chr-{{student.firstname | uppercase | slice:0:1}}"
                             *ngIf="!student.userpictureurl"
                             style="cursor: pointer;">
                          {{student.firstname | uppercase | slice:0:1 }}{{student.lastname | uppercase | slice:0:1 }}
                        </div>
                        <!-- avatar custom-->
                        <img class="person-circle"
                             *ngIf="student.userpictureurl"
                             src="{{student.userpictureurl}}"
                             alt=""/>


                        <div class="" style="max-width: 315px;">
                          <h6 class="m-t-0 m-b-0 text-primary"><span class="mr-1">{{student.firstname}}</span><span>{{student.lastname}}</span></h6>
                          <span class="job_post"></span>
                          <div mb-0><i class="icofont-stock-mobile mr-2"></i>{{student.deparmentname}}</div>
                          <div mt-0><i class="icofont-mail mr-2"></i>{{student.email}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-center mb-5">
                  <div class="row mb-3">
                    <mat-paginator showFirstLastButtons
                        [length]="totalStudent"
                        [pageSize]="pageSize"
                        [pageIndex]="pageIndex - 1"
                        (page)="changePage($event)"
                        [hidePageSize]="false"
                        [pageSizeOptions]="pageSizeOptions">
                    </mat-paginator>
                  </div>
                </div>
                <ng-template #hasNotStudents>
                  <div class="no-course text-center p-3">{{'common.no_record_enduser' | translate}}</div>
                </ng-template>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
              <app-eu-course-history-content [courseId]="courseId"></app-eu-course-history-content>
            </div>
            <div class="tab-pane fade" [ngClass]="{
                          'active': openTab==2,
                           'show': openTab==2}" id="nav-exam" role="tabpanel" aria-labelledby="nav-exam-tab">
              <app-eu-course-exam-content [courseId]="courseId" [course]="course"></app-eu-course-exam-content>
            </div>
            <div class="tab-pane fade" id="nav-quiz" role="tabpanel" aria-labelledby="nav-quiz-tab">
              <app-eu-course-survey-content [courseId]="courseId"  [course]="course"></app-eu-course-survey-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<ng-template  #notPermission>
  <div class="container">
    <div class="col-12 text-center border p-3 mt-2">
      <img src="/assets/graduation-cap.svg" style="height: 25vh;" alt="">
      <h5 class="mt-3"><strong>{{'error.cannotviewcourse' | translate}}</strong></h5>
    </div>
  </div>
</ng-template>
<!-- End #main -->
