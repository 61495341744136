import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ABC, abc, iii, III, T123 } from 'src/app/shared/constants/base.constant';
import CommonUtil from 'src/app/shared/utils/common-util';
import { ReportService } from '../../../../shared/services/report.service';
import {ToastrCustomService} from '../../../../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-student-learning-history',
  templateUrl: './student-learning-history.component.html',
  styleUrls: ['./student-learning-history.component.css']
})
export class StudentLearningHistoryComponent implements OnInit {

  listHistoryLearning = [];
  @Input() courseId: number;
  @Input() courseName: number;
  @Input() userId: number;
  constructor(public activeModal: NgbActiveModal,
              private reportService: ReportService,
              public domSanitizer2: DomSanitizer,
              private toastrService: ToastrCustomService,
              private spinner: NgxSpinnerService
              ) { }

  ngOnInit(): void {
    this.getLearningHistory();
  }

  getLearningHistory() {
    const param = {
      courseid: this.courseId,
      userid: this.userId,
      sorttype: 'DESC',
      sortcolumn: 'id'
    };
    this.spinner.show();
    return this.reportService.getHistoryLerning(param).subscribe((data) => {
      this.listHistoryLearning = data.body;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });

  }
  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }


}
