import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-exam',
  templateUrl: './report-exam.component.html',
  styleUrls: ['./report-exam.component.css']
})
export class ReportExamComponent implements OnInit {

  exam:string = "exam";
  courseLable:string = "Số kỳ thi"
  choosesCourseBtn:string = "Lựa chọn kỳ thi"
  titleTable = {
    courseLabel : 'Số kỳ thi',
    studentLabel: 'Số học viên tham gia thi'
  }
  constructor() { }

  ngOnInit(): void {
  }

}
