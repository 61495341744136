<div class="modal-header">
    <h5 class="modal-title">Tìm kiếm nâng cao</h5>
    <button type="button" class="btn btn-default" data-card-widget="collapse"
            (click)="activeModal.dismiss('close')"><i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
        <div class="col-12">
          <label>Thời gian thi</label>
                            <div class="form-group" >
                              <div class="row">
                                <div class="col-6">
                                     <mat-form-field color="blue" class="mr-3 course-input">
                                       <mat-label>Từ ngày</mat-label>
                                       <input matInput >
                                       <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                       <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                   </div>

                                <div class="col-6">
                                    <mat-form-field color="blue" class="course-input">
                                    <mat-label>Đến ngày</mat-label>
                                    <input matInput>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2 color="primary"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                              </div>
                            </div>
        </div>
        <div class="col-12">
          <div class="form-group mt-3">
            <label>Dạng đề thi</label>
            <select class="custom-select" name="single" >
                <option value="0">-Toàn bộ -</option>
                <option value="1" selected="">Bài Thi</option>
                <option value="1" selected="">Tự kiểm tra</option>
              </select>
           </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label>Trạng thái</label>
            <select class="custom-select      " name="single">
                <option value="0">Kết thúc</option>
                <option value="1" selected="">Chưa kết thúc</option>

              </select>
           </div>
        </div>
       </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary"  (click)="activeModal.dismiss('close')">Đóng</button>
    <button type="button" class="btn btn-primary">Tìm kiếm</button>
  </div>
