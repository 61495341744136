import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { LoginService } from 'src/app/shared/services/login.service';
import { ToastrCustomService } from 'src/app/shared/services/toastr-custom.service';
import { ReportService } from '../../../../shared/services/report.service';
import { StudentLearningHistoryComponent } from '../student-learning-history/student-learning-history.component';
import {FileService} from '../../../../shared/services/file.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.css']
})
export class StudentDetailsComponent implements OnInit, OnDestroy {
  id: any;
  sub: any;
  listCourse: any;
  userFullname: string;
  departmentName: string;

  constructor(
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private fileService: FileService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.sub = this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.reportCourseStudent(this.id);
    });
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  reportCourseStudent(id: any) {
    this.spinner.show();
    this.reportService.reportStudentCourse(id, 1).subscribe(
      (data) => {
        this.spinner.hide();
        this.listCourse = data.body.results;
        this.userFullname = data.body.userfullname;
        this.departmentName = data.body.departmentname;
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
  }

  convertCompletion(completion) {
    if (completion !== null && completion === 100) {
      return 'Hoàn thành';
    }
    return 'Chưa hoàn thành';
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  openLearningHistory(courseId: any, courseName: any){
    const modalDep = this.modalService.open(StudentLearningHistoryComponent, {
      size: 'xl' as any,
      centered: false,
      backdrop: 'static',
      scrollable: true
    });
    modalDep.componentInstance.courseId = courseId;
    modalDep.componentInstance.courseName = courseName;
    modalDep.componentInstance.userId = this.id;
  }

  onExportLearningDetail(){
    const params = {
      userid: this.id,
      coursetype: 1
    };
    this.spinner.show();
    this.reportService.exportLearningProgressDetail(params).subscribe(res => {
      window.open(this.fileService.getFileFromPathUrl(res.body.path));
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastrService.handlerError(err);
    });
  }

}
