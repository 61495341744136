<div class="content-wrapper" style="min-height: 928.604px">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Quản lý ngân hàng câu hỏi</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý ngân hàng câu hỏi</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="col-12">
        <div class="card card-default">
          <div class="card-header">
            <!-- <h3 class="card-title">Danh sách câu hỏi mới</h3> -->
            <div class="row">
              <div class="col-6">
                <div class="form-inline">
                  <button type="button" [routerLink]="['/admin/question-bank/create']" [routerLinkActive]="['active']" class="btn btn-primary btn-flat float-left m-2" ><i class="fas fa-plus"></i> <a class="text-white">Tạo câu hỏi </a></button>
                  <button type="button" [disabled]="questionIdsChecked?.length === 0" class="btn btn-default btn-flat  mr-1" matTooltip="Xóa" (click)="onDeleteMultipleQues()" matTooltipClass="tooltip-red"><i class="fas fa-trash mr-1"></i></button>
                  <span>Số câu hỏi đã chọn: {{questionIdsChecked?.length}}</span>
                </div>
              </div>
              <div class="col-6">
                <form class="form-inline float-right">
                  <div class="form-group m-2">
                    <label for="" class="mr-2">Tìm kiếm</label>
                    <input
                      type="search" style="width:250px;" (change)="search($event.target.value)"
                      class="form-control form-control-sm mr-2"
                      id=""
                      placeholder="Tên hoặc nội dung câu hỏi..."
                    />
                  </div>
                  <div class="card-tools float-right">
                    <a class="btn btn-block btn-default btn-flat" (click)="openQuestionSearchPopup()"><i class="fa fa-search"></i> Tìm kiếm nâng cao
                    </a></div>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body" style="display: block">
            <div class="col-12 table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>
                      <mat-icon class="mt-1">
                        <mat-checkbox class="example-margin" [(ngModel)]="completedAll" (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                      </mat-icon>
                    </th>
                    <th>STT</th>
                    <th>Nội dung câu hỏi</th>
                    <th>Danh mục</th>
                    <th>Loại câu hỏi</th>
                    <th>Mức độ</th>
                    <th>Số đáp án</th>
                    <th>Ngày tạo</th>
                    <th>Thao tác</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let question of listQuestion; let i = index">
                    <td>
                      <mat-icon class="mt-1">
                        <mat-checkbox [(ngModel)]="question.completed" (change)="oncheckboxItem(question.id, $event.checked)" class="example-margin"></mat-checkbox>
                      </mat-icon>
                    </td>
                    <td>{{(searchQues.pageIndex - 1) * searchQues.pageSize + i + 1}}</td>
                    <td style="width:350px;"><p [innerHTML]="question.questiontext?.length <= 90 ? question.questiontext: (question.questiontext | slice:0:90) + '...' "></p></td>
                    <td>{{ question.categoryname }}</td>
                    <td *ngIf="question.qtype==='multichoice'">{{'qtype.multichoice' | translate}}</td>
                    <td *ngIf="question.qtype==='multichoiceset'">{{'qtype.multichoiceset' | translate}}</td>
                    <td *ngIf="question.qtype==='truefalse'">{{'qtype.truefalse' | translate}}</td>
                    <td><span matTooltip="{{question.levelName}}" matTooltipClass="tooltip-inner">{{question.levelName?.length <= 40 ? question.levelName : (question.levelName | slice:0:40) + '...' }}</span></td>
                    <td>{{ question.answer ? question.answer.length : 2 }}</td>
                    <td>{{ getDateFromUnix(question.timecreated) | date:'dd/MM/yyyy' }}</td>
                    <td>
                    <button (click)="onUpdateQuestion(question, true)" class="btn btn-sm btn-default"> <i class="fa fa-edit"></i></button>
                    <button (click)="onDeleteSingleQues(question.id)"  data-toggle="modal" data-target="#exampleModal" class="btn btn-sm btn-default"> <i class="fa fa-trash"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <p *ngIf="listQuestion?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
            </div>
            <div class="card-footer text-center">
              <div class="row">
                <mat-paginator showFirstLastButtons
                    [length]="totalRecord"
                    [pageSize]="searchQues.pageSize"
                    [pageIndex]="searchQues.pageIndex - 1 "
                    (page)="changePage($event)"
                    [hidePageSize]="false"
                    [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</div>
