<div class="row">
  <div class="modal fade show" data-backdrop="static" data-keyboard="false"
       style="display: block; padding-right: 17px;" aria-modal="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <div *ngIf="isChangePassword">
            <h4 class="modal-title" *ngIf="user.id">{{'user.change_password'| translate}}</h4>
          </div>
          <div *ngIf="!isChangePassword">
            <h4 class="modal-title" *ngIf="!user.id">{{'user.create_title' | translate}}</h4>
            <h4 class="modal-title" *ngIf="user.id">{{'user.update_title'| translate}}</h4>
          </div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="createUserForm">
          <div class="modal-body">
            <div class="row">
              <div [hidden]="isChangePassword" class="col-6">
                <div class="form-group">
                  <label>{{'user.first_name' | translate}}</label><span class="text-danger"> *</span>
                  <input name="firstname" type="text" class="form-control" placeholder="Họ" minlength="2"
                         formControlName="firstname"/>
                  <div
                    *ngIf="createUserForm.get('firstname')!.invalid && (createUserForm.get('firstname')!.dirty || createUserForm.get('firstname')!.touched)">
                    <span *ngIf="createUserForm.get('firstname').errors?.minlength" class="text-danger">
                      {{ 'error.validate.name.minlength' | translate }}
                    </span>
                    <small class="text-danger"
                           *ngIf="createUserForm.get('firstname')?.errors?.isBlank">{{ 'error.validate.input_required' | translate }}</small>
                    <small class="text-danger"
                           *ngIf="createUserForm.get('firstname')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                  </div>
                </div>
              </div>
              <div [hidden]="isChangePassword" class="col-6">
                <div class="form-group">
                  <input name="id" type="hidden" formControlName="id"/>
                  <label>{{'user.last_name' | translate}}</label><span class="text-danger"> *</span>
                  <input name="lastname" type="text" class="form-control" placeholder="Tên"
                         minlength="2" formControlName="lastname"/>
                  <div
                    *ngIf="createUserForm.get('lastname')!.invalid && (createUserForm.get('lastname')!.dirty || createUserForm.get('lastname')!.touched)">
                    <span *ngIf="createUserForm.get('lastname').errors?.minlength"
                          class="text-danger">{{ 'error.validate.name.minlength' | translate }}</span>
                    <small class="text-danger"
                           *ngIf="createUserForm.get('lastname')?.errors?.isBlank">{{ 'error.validate.input_required' | translate }}</small>
                    <small class="text-danger"
                           *ngIf="createUserForm.get('lastname')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                  </div>
                </div>
              </div>
              <div [hidden]="isChangePassword" class="col-6">
                <div class="form-group">
                  <label>{{'user.auth' | translate}}</label><span class="text-danger"> *</span>
                  <select class="form-control select2" style="width: 100%;" name="roleid"
                          (change)="onChangeAuthMethod()"
                          formControlName="auth">
                    <option value="null" disabled>{{'user.choose_auth'| translate}}</option>
                    <option *ngFor="let method of authMethods"
                            value="{{method.value}}">{{'user.' + method.label | translate}}</option>
                  </select>
                  <div
                    *ngIf="createUserForm.get('auth')!.invalid && (createUserForm.get('auth')!.dirty || createUserForm.get('auth')!.touched)">
                    <small class="text-danger"
                           *ngIf="createUserForm.get('auth')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                  </div>

                </div>
              </div>
              <div [hidden]="isChangePassword" class="col-6">
                <div class="form-group">
                  <label>{{'user.user_name' | translate}}</label><span class="text-danger"> *</span>
                  <input [readOnly]="user.id" name="username" type="text" class="form-control"
                         placeholder="Tên đăng nhập"
                         formControlName="username"/>
                  <div
                    *ngIf="createUserForm.get('username')!.invalid && (createUserForm.get('username')!.dirty || createUserForm.get('username')!.touched)">
                    <small class="text-danger"
                           *ngIf="createUserForm.get('username')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                    <small class="text-danger"
                           *ngIf="createUserForm.get('username')?.errors?.isBlank">{{ 'error.validate.input_required' | translate }}</small>
                  </div>
                </div>
              </div>
              <div [hidden]="isChangePassword" class="col-6">
                <div class="form-group">
                  <label>{{'user.phone_number' | translate}}</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-phone"></i></span>
                    </div>
                    <input name="phone1" type="text" class="form-control"
                           [placeholder]="'Số điện thoại'"
                           data-inputmask="'mask': ['999-999-9999 [x99999]', '+099 99 99 9999[9]-9999']"
                           data-mask="" im-insert="true"
                           formControlName="phone1"/>
                  </div>
                  <div
                    *ngIf="createUserForm.get('phone1')!.invalid && (createUserForm.get('phone1')!.dirty || createUserForm.get('phone1')!.touched)">
                    <small class="text-danger"
                           *ngIf="createUserForm.get('phone1')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                    <small class="text-danger"
                           *ngIf="createUserForm.get('phone1').errors?.pattern">{{ 'error.validate.phone_number.invalid' | translate }}</small>
                  </div>
                </div>
              </div>
              <div [hidden]="isChangePassword" class="col-6">
                <div class="form-group">
                  <label>{{'user.email' | translate}}</label><span class="text-danger"> *</span>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-envelope"></i></span>
                    </div>
                    <input [placeholder]="'Email'" name="email" type="text" class="form-control" im-insert="true"
                           formControlName="email"/>
                  </div>
                  <div
                    *ngIf="createUserForm.get('email')!.invalid && (createUserForm.get('email')!.dirty || createUserForm.get('email')!.touched)">
                    <small class="text-danger"
                           *ngIf="createUserForm.get('email')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                    <small class="text-danger"
                           *ngIf="createUserForm.get('email').errors?.pattern">{{ 'error.validate.email.pattern' | translate }}</small>
                  </div>
                </div>
              </div>

              <div *ngIf="!user?.id || isChangePassword" class="col-6">
                <div class="form-group">
                  <label>{{'user.password' | translate}}</label><span *ngIf="!user?.id || isChangePassword"
                                                                      class="text-danger"> *</span>
                  <input name="password" type="password" class="form-control"
                         placeholder="Mật khẩu mặc định" formControlName="password"
                         minlength="8" maxlength="20" (change)="onChangePassword()">
                  <div
                    *ngIf="createUserForm.get('password')!.invalid && (createUserForm.get('password')!.dirty || createUserForm.get('password')!.touched)">
                    <small class="text-danger" *ngIf="createUserForm.get('password')?.errors?.required">
                      {{ 'error.validate.input_required' | translate }}
                    </small>
                    <small *ngIf="createUserForm.get('password').errors?.minlength"
                           class="text-danger">{{ 'error.validate.password.minlength' | translate }}</small>
                    <small *ngIf="createUserForm.get('password').errors?.maxlength"
                           class="text-danger">{{ 'error.validate.password.maxlength' | translate }}</small>
                    <small *ngIf="createUserForm.get('password').errors?.pattern"
                           class="text-danger">{{ 'error.validate.password.pattern' | translate }}</small>
                  </div>
                </div>
              </div>
              <div *ngIf="!user?.id || isChangePassword" class="col-6">
                <div class="form-group">
                  <label>{{'user.repassword' |translate}}</label><span *ngIf="!user?.id || isChangePassword"
                                                                       class="text-danger"> *</span>
                  <input formControlName="repassword" type="password" class="form-control" (change)="onChangePassword()"
                         placeholder="{{'user.repassword' |translate}}">
                  <div *ngIf="createUserForm.get('repassword')!.invalid &&
                           (createUserForm.get('repassword')!.dirty || createUserForm.get('repassword')!.touched)">
                    <small class="text-danger"
                           *ngIf="createUserForm.get('repassword')?.errors?.required">
                      {{ 'error.validate.input_required' | translate }}
                    </small>
                  </div>
                  <small class="text-danger"
                         [hidden]="createUserForm.get('repassword')?.errors?.required"
                         *ngIf="!isPasswordMatch">{{'error.validate.password.confirmpassword' | translate}}</small>
                </div>
              </div>

              <div [hidden]="isChangePassword" class="col-6">
                <div class="form-group" data-select2-id="47">
                  <label>{{'user.position' | translate}}</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Chọn chức danh'"
                    [settings]="dropdownPositionSettings"
                    [data]="positionList"
                    (onSelect)="onChangePosition()"
                    (onDropDownClose)="onChangePosition()"
                    formControlName="selectedPositions"
                  >
                  </ng-multiselect-dropdown>
                  <div
                    *ngIf="createUserForm.get('selectedPositions')!.invalid">
                    <small class="text-danger"
                           *ngIf="createUserForm.get('selectedPositions')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                  </div>
                </div>
              </div>
              <div [hidden]="isChangePassword" class="col-6">
                <div class="form-group">
                  <label>{{'user.description' | translate}}</label>
                  <input name="description" type="text" class="form-control" placeholder="Thông tin mô tả"
                         formControlName="description"/>
                </div>
              </div>
              <div [hidden]="isChangePassword" class="col-6">
                <div class="form-group" data-select2-id="47">
                  <label>{{'user.role' | translate}}</label><span class="text-danger"> *</span>
                  <select class="form-control select2" style="width: 100%;" name="roleid"
                          (change)="onChangeRole()"
                          formControlName="roleid">
                    <option value="null" disabled>{{'user.choose_role'| translate}}</option>
                    <option *ngFor="let role of roleList"
                            [value]="role.id">
                      {{'user.' + role.shortname | translate}}
                    </option>
                  </select>
                  <div
                    *ngIf="createUserForm.get('roleid')!.invalid && (createUserForm.get('roleid')!.dirty || createUserForm.get('roleid')!.touched)">
                    <small class="text-danger"
                           *ngIf="createUserForm.get('roleid')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                  </div>
                </div>
              </div>
              <div [hidden]="isChangePassword" class="col-6">
                <div class="form-group"><span class="text-danger"></span>
                  <label>{{'user.department' | translate}}</label>
                  <!--                  <select name="departmentid" class="form-control select2" style="width: 100%;"-->
                  <!--                          formControlName="departmentid"-->
                  <!--                          (change)="onChangeDepartment()">-->
                  <!--                    <option value="null">{{ 'user.choose_department' | translate }}</option>-->
                  <!--                    <option *ngFor="let item of departmentMap | keyvalue" [value]="item.key">{{item.value}}</option>-->
                  <!--                  </select>-->
                  <ng-select class="form-control select2 custom-select-box"
                             [placeholder]="user.id && createUserForm.get('departmentid').value ? '' : 'user.choose_department' | translate"
                             [items]="departmentMap | keyvalue"
                             bindLabel="value" bindValue="key"
                             formControlName="departmentid"
                             [ngbTooltip]="departmenttooltip"
                             (change)="onChangeDepartment()"
                             [editableSearchTerm]="true">
                    <ng-template ng-option-tmp let-item="item">
                      <div [title]="item.value">{{item.value}}</div>
                    </ng-template>
                    <ng-template #departmenttooltip>
                      {{createUserForm.get('departmentid').value ?
                      departmentMap.get(createUserForm.get('departmentid').value) :
                      'user.choose_department' | translate}}
                    </ng-template>
                  </ng-select>
                  <div
                    *ngIf="showDepartmentRequired || createUserForm.get('departmentid')!.invalid">
                    <small class="text-danger"
                           *ngIf="createUserForm.get('departmentid')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                  </div>
                </div>
              </div>
              <div [hidden]="isChangePassword" class="col-6">
                <div class="form-group" data-select2-id="47" *ngIf="showManagedDepartment">
                  <label>{{'user.managed_department_id' | translate}}</label>
                  <!--                  <select name="manageddepartmentid" class="form-control select2" style="width: 100%;"-->
                  <!--                          formControlName="manageddepartmentid">-->
                  <!--                    <option *ngFor="let item of departmentMap | keyvalue" [value]="item.key">{{item.value}}</option>-->
                  <!--                  </select>-->

                  <ng-select class="form-control select2 custom-select-box" style="width: 100%;"
                             [placeholder]="createUserForm.get('manageddepartmentid').value ? '' : 'user.choose_department' | translate"
                             [items]="departmentMap | keyvalue"
                             bindLabel="value" bindValue="key"
                             [ngbTooltip]="manageddepartmenttooltip"
                             formControlName="manageddepartmentid"
                             [editableSearchTerm]="true">
                    <ng-template ng-option-tmp let-item="item">
                      <div [title]="item.value">{{item.value}}</div>
                    </ng-template>
                    <ng-template #manageddepartmenttooltip>
                      {{createUserForm.get('manageddepartmentid').value ?
                      departmentMap.get(createUserForm.get('manageddepartmentid').value) :
                      'user.choose_department' | translate}}
                    </ng-template>
                  </ng-select>
                  <div
                    *ngIf="createUserForm.get('manageddepartmentid')!.invalid && (createUserForm.get('manageddepartmentid')!.dirty || createUserForm.get('manageddepartmentid')!.touched)">
                    <small class="text-danger"
                           *ngIf="createUserForm.get('manageddepartmentid')?.errors?.required">{{ 'error.validate.input_required' | translate }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer text-center">
            <div>
              <button type="button" class="btn btn-default"
                      (click)="close()">
                {{ 'common.cancel' | translate }}
              </button>
              <button type="submit" [disabled]="disabled" class="btn ml-1 btn-primary rounded-0" (click)="onSubmit()">
                {{ 'common.save' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
