import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RoleCreateComponent} from './role-create/role-create.component';
import {RoleService} from '../../shared/services/role.service';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {element} from 'protractor';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import CommonUtil from 'src/app/shared/utils/common-util';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  typesOfRole: any[];
  typesOfModule: any;

  // roleIds: number[]=[];
  roleId: number;
  roleName: string;
  typesOfModuleChecked: any;
  checked: boolean;
  listMenuFunc: any;
  menuIds: number[] = [];
  isDisableUpdateBtn: boolean = true;
  isDisableCancelBtn: boolean = true;

  constructor(
    private modalService: NgbModal,
    private roleService: RoleService,
    private toastrService: ToastrCustomService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.roleService.getListRoleSystem().subscribe((data) => {
      this.typesOfRole = data.body.results;
      if (this.typesOfRole !== undefined) {
        this.typesOfRole.forEach(element => {
            if (element.shortname === 'admin') {
              this.typesOfRole.splice(this.typesOfRole.indexOf(element), 1);
            }
          }
        );
      }
    });
  }

  getListMenu(role) {
    this.roleService.getListMenu().subscribe((data) => {
      this.listMenuFunc = CommonUtil.filterMenu(data.body, role);
    });
  }

  showListMenu(id, shortName) {
    this.roleId = id;
    // this.roleIds.push(id);
    this.roleName = shortName;
    this.roleService.getListMenuWithRole(this.roleId).subscribe((data) => {
      this.typesOfModule = data.body;
      // console.log(this.typesOfModule);
      this.isDisableCancelBtn = true;
      this.isDisableUpdateBtn = true;
      this.menuIds = [];
      this.menuIds.push(...this.typesOfModule.map(a => a.id));
      // console.log(this.menuIds);
    });
    this.getListMenu(this.roleName);
  }

  getMenuOfRole() {
    this.roleService.getListMenuWithRole(this.roleId).subscribe((data) => {
      this.typesOfModule = data.body;
      this.isDisableCancelBtn = true;
      this.isDisableUpdateBtn = true;
      this.menuIds = [];
      this.menuIds.push(...this.typesOfModule.map(a => a.id));
    });
  }

  update() {
    // debugger;
    this.spinner.show();
    this.roleService.updateListMenuWithRole(this.roleId, this.menuIds).subscribe((res) => {
        this.spinner.hide();
        this.toastrService.success(`common.noti.update_success`);
        this.getMenuOfRole();
        this.getListMenu(this.roleName);
      },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      });
  }

  cancel() {
    this.getMenuOfRole();
    this.isDisableCancelBtn = true;
    this.isDisableUpdateBtn = true;
  }

  onNgModelChange(event) {
    // console.log('menu array: ', event);
    this.isDisableUpdateBtn = false;
    this.isDisableCancelBtn = false;
    this.menuIds = event;
  }

  traslateRoleName(name: string): string {
    return this.translateService.instant(`user.${name}`);
  }
}
