<div class="container-fluid">
  <div class="card card-default">
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <h6 >Chọn người nhận</h6>
          <span>Số người đã chọn: {{userIdsChecked?.length}}</span>
        </div>
        <div class="col-8">
          <!--Search -->
          <form class="form-inline float-right" role="form">
            <div class="form-group m-2">
              <h6 class="mr-2">Tìm kiếm</h6>
              <input type="search" class="form-control mr-2" placeholder="Từ khóa..." (change)="onSearchUser($event.target.value)">
            </div>
          </form>
        </div>
      </div>

      <div class="table-responsive ">
        <table class="table table-hover mt-3">
          <thead>
          <tr>
            <th>
              <mat-icon class="mt-1">
                <mat-checkbox class="example-margin" [(ngModel)]="isCheckAll"
                              (change)="oncheckboxAll($event.checked)"></mat-checkbox>
              </mat-icon>
            </th>
            <th>STT</th>
            <th>Họ tên</th>
            <th>Chức danh</th>
            <th>Đơn vị</th>
            <th>Email</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of users ; let i = index;">
            <td>
              <mat-icon class="mt-1">
                <mat-checkbox [(ngModel)]="item.checked"
                              (change)="oncheckboxItem(item.id, $event.checked)"
                              class="example-margin" [checked]="item.checked"></mat-checkbox>
              </mat-icon>
            </td>
            <td>{{(searchUser.pageIndex - 1) * searchUser.pageSize + i + 1}}</td>
            <td><a matTooltip="{{item.firstname + ' ' + item.lastname}}"
                    matTooltipClass="tooltip-inner">{{(item.lastname?.length + item.firstname?.length) <= 30 ? item.firstname + ' ' + item.lastname : (item.firstname + ' ' + item.lastname | slice:0:30) + '...' }}</a>
            </td>
            <td>
              <span matTooltip="{{getPositionNames(item)}}" matTooltipClass="tooltip-inner">
                {{getPositionNames(item)?.length <= 30 ? getPositionNames(item) : (getPositionNames(item) | slice:0:20) + '...' }}
              </span>
            </td>
            <td matTooltip="{{item.departmentname}}"
                matTooltipClass="tooltip-inner">{{item.departmentname?.length <= 30 ? item.departmentname : (item.departmentname | slice:0:30) + '...' }}</td>
            <td>{{item.email}}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p *ngIf="users?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
      </div>

    </div>
    <div class="card-footer text-center">
      <div class="row">
        <mat-paginator showFirstLastButtons
                        [length]="totalRecord"
                        [pageSize]="searchUser.pageSize"
                        [pageIndex]="searchUser.pageIndex - 1 "
                        (page)="changePage($event)"
                        [hidePageSize]="false"
                        [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>


