import {PageEvent} from '@angular/material/paginator';
import {DataService} from './../../../shared/services/data.service';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmModalComponent} from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import {ToastrCustomService} from 'src/app/shared/services/toastr-custom.service';
import {LocalStorageService} from 'ngx-webstorage';
import {USER_FUNCTIONS, USER_INFO} from 'src/app/shared/constants/base.constant';
import {CourseService} from '../../../shared/services/course.service';

@Component({
  selector: 'app-add-teacher',
  templateUrl: './add-teacher.component.html',
  styleUrls: ['./add-teacher.component.css']
})
export class AddTeacherComponent implements OnInit, OnDestroy {
  @Input() courseId: any;
  // MLMS 668
  @Input() courseDetail;
  id: any;
  role: any;
  happening: string;
  choiceButtonDisabled = true;
  newDynamic: any = [];
  listTeacherAdd: any;
  listTeacherOfCourse: any[] = [];
  teacherIds: number[] = [];
  teacherIdsChecked: number[] = [];
  teacherIdsAssign: number[] = [];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  completedAll = false;
  lastSort: any;
  currentDate: Date = new Date();
  searchOutCourse: string;

  // MatPaginator Output
  pageEvent: PageEvent;

  searchTeacher = {
    searchOnCourse : '',
    pageIndex: 1,
    pageSize: 10
  }

  constructor(
    private courseService: CourseService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrCustomService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private dataService: DataService,
    private $localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.role = this.$localStorage.retrieve(USER_INFO.ROLES)[0].shortname;
    if (this.happening === undefined || this.happening === null) {
      this.route.queryParams.subscribe(params => {
        if (params) {
          this.happening = params.happening;
        }
      });
    }
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id);
    this.getListTeacherAdd(this.id);
    this.getListTeacherOfCourse();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  getListTeacherAdd(courseId: any, search?: string) {
    const param = {
      limit: 0,
      courseid: courseId,
      search: search
    };
    this.spinner.show();
    return this.courseService.getListTeacherCanAdd(param).subscribe((data) => {
      this.listTeacherAdd = data.body.results;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  getListTeacherOfCourse() {
    this.completedAll = false;
    let params = {
      limit: this.searchTeacher.pageSize,
      page: this.searchTeacher.pageIndex,
      courseid: this.id,
      search: this.searchTeacher.searchOnCourse
    };
    this.spinner.show();
    this.teacherIds = [];
    return this.courseService.getListTeacherOfCourse(params).subscribe((data) => {
      data.body?.results?.forEach((c: any) => {
        if (this.teacherIdsChecked?.includes(c.id)) {
          c.completed = true;
          this.teacherIds?.push(c.id);
        }
      });
      if (this.teacherIds?.length > 0 && (this.teacherIds?.length === data.body?.results?.length)) {
        this.completedAll = true;
      } else {
        this.completedAll = false;
      }
      this.listTeacherOfCourse = data.body.results;
      this.totalRecord = data.body.total;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  assignTeacher() {
    if (this.teacherIdsAssign?.length > 0) {
      this.spinner.show();
      this.courseService.assignTeacher(this.teacherIdsAssign, this.id).subscribe(
      res => {
        this.spinner.hide();
        this.choiceButtonDisabled = true;
        this.teacherIdsAssign = [];
        this.toastrService.success('common.noti.assign_teacher_success');
        this.getListTeacherAdd(this.id);
        this.getListTeacherOfCourse();
        this.dataService.assignTeacherSuccess.next(true);
      },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      });
    }
  }

  unAssignTeacher(teacherIds: number[]) {
    if (teacherIds?.length > 0) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });

      modalDep.componentInstance.title = this.translateService.instant('unassign_teacher.delete_confirm_title');
      modalDep.componentInstance.body = this.translateService.instant('unassign_teacher.delete_confirm_content');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.unassign');
      modalDep.result.then((result) => {
        if (result === 'confirm') {
          this.spinner.show();
          this.courseService.unassignTeacher(teacherIds, this.id).subscribe(
            res => {
              this.getListTeacherAdd(this.id);
              this.getListTeacherOfCourse();
              this.teacherIds = [];
              [...teacherIds]?.forEach(studentId => {
                this.teacherIds.splice(this.teacherIds.indexOf(studentId), 1);
                this.teacherIdsChecked.splice(this.teacherIdsChecked.indexOf(studentId), 1);
              });
              this.spinner.hide();
              this.toastrService.success(`common.noti.unassign_teacher_success`);
            },
            err => {
              this.spinner.hide();
              this.toastrService.handlerError(err);
            }
          );
        }
      });
    }
  }

  addTeacherIds(teacherId: number, checked: boolean) {
    if (checked && this.teacherIdsAssign.indexOf(teacherId) === -1) {
      this.teacherIdsAssign.push(teacherId);
    } else if (!checked) {
      this.teacherIdsAssign.splice(this.teacherIdsAssign.indexOf(teacherId), 1);
    }
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.listTeacherOfCourse.forEach(c => {
        c.completed = checked;
        if (!this.teacherIds.includes(c.id)) {
          this.teacherIds.push(c.id);
        }
        if (!this.teacherIdsChecked?.includes(c.id)) {
          this.teacherIdsChecked?.push(c.id);
        }
      });
      this.completedAll = true;
    } else {
      this.teacherIds?.forEach(id => {
        this.teacherIdsChecked?.splice(this.teacherIdsChecked?.indexOf(id), 1);
      });
      this.teacherIds = [];
      this.listTeacherOfCourse?.forEach(c => {
        c.completed = false;
      });
      this.completedAll = false;
    }
  }

  oncheckboxItem(courseId: number, checked: any) {
    if (checked) {
      this.listTeacherOfCourse?.forEach(c => {
        if (c.id === courseId) {
          c.completed = true;
          this.teacherIds?.push(courseId);
          this.teacherIdsChecked?.push(courseId);
          return;
        }
      });
      if (this.teacherIds?.length > 0 && this.teacherIds?.length === this.teacherIds?.length && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.completedAll = false;
      this.listTeacherOfCourse?.forEach(c => {
        if (c.id === courseId) {
          c.completed = false;
          this.teacherIds?.splice(this.teacherIds?.indexOf(courseId), 1);
          this.teacherIdsChecked?.splice(this.teacherIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
  }

  checkRoleFunction(userFunction: any, role: any): boolean{
    return USER_FUNCTIONS[userFunction].includes(role);
  }

  onSearchTeacherOnCourse(search: string) {
    this.searchTeacher.pageIndex = 1;
    this.searchTeacher.searchOnCourse = search;
    this.teacherIdsChecked = [];
    this.getListTeacherOfCourse();
  }

  onSearchTeacherOutCourse(search: string) {
    this.getListTeacherAdd(this.id, search);
    this.searchOutCourse = search;
  }

  changePage(event){
    this.searchTeacher.pageIndex = event.pageIndex + 1;
    this.searchTeacher.pageSize = event.pageSize;
    this.getListTeacherOfCourse();
  }
}
