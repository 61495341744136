<div class="modal-header">
  <h4 class="modal-title">{{ title | translate }}</h4>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
          (click)="activeModal.close('Close click')"><i class="fas fa-minus"></i>
  </button>
</div>
<div class="modal-body">
  <form role="form" [formGroup]="editCategoryForm">
    <input type="hidden" class="form-control" formControlName="id">
    <div class="form-group">
      <label>Tên danh mục</label><span class="text-danger"> *</span>
      <input type="text" class="form-control" placeholder="Tên danh mục" formControlName="name">
      <div *ngIf="editCategoryForm?.get('name')!.invalid &&
      (editCategoryForm?.get('name')!.dirty || editCategoryForm?.get('name')!.touched)">
      <span class="text-danger" *ngIf="editCategoryForm?.get('name')?.errors?.required">
        {{ 'error.validate.input_required' | translate }}
      </span>
    </div>
    <small class="text-danger" *ngIf="editCategoryForm?.get('name')?.invalid && !editCategoryForm?.get('name')?.hasError('required')">
      {{ 'error.validate.course.nameMaxlength' | translate }}
    </small>
    </div>

    <div class="form-group">
      <label>Danh mục cha</label><span class="text-danger"></span>
      <ng-select class="form-control select2 custom-select-box" formControlName="parent"
                 [items]="categoryMap | keyvalue"  bindLabel="value" bindValue="key" [editableSearchTerm]="true">
        <!--            <ng-option *ngFor="let item of departmentMap| keyvalue" [value]="item.key" >{{item.value}}</ng-option>-->
      </ng-select>
<!--      <select class="form-control select2"  style="width: 100%;" formControlName="parent"  [(ngModel)]="cloneCategory.parent">-->
<!--        &lt;!&ndash; <option [value]="0"  [selected]="cloneCategory.parent == null" >Chọn danh mục</option> &ndash;&gt;-->
<!--        <option  *ngFor="let item of categoryMap | keyvalue" [value]="item.key"  [selected]="item.key == cloneCategory.parent">{{item.value}}</option>-->
<!--      </select>-->
    </div>

    <div class="form-group">
      <label>Mô tả</label>
      <textarea class="form-control" placeholder="Mô tả chi tiết"  formControlName="description"></textarea>
      <small class="text-danger" *ngIf="editCategoryForm?.get('description')?.errors && !editCategoryForm?.get('description')?.errors?.required">
        {{ 'error.validate.course.nameCourseMaxlength' | translate }}
      </small>
    </div>
  </form>
</div>
<div class="modal-footer text-center">
  <button type="button" class="btn ml-1 btn-secondary rounded-0 mr-1"
  (click)="activeModal.dismiss('close')">{{ 'common.cancel' | translate }}</button>
  <div *ngIf="category">
    <button type="submit" class="btn btn-primary" (click)="createOrUpdateCourseCategory()">
      {{ button | translate }}
    </button>
  </div>
</div>




