<div class="content-wrapper" style="min-height: 928.604px">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Quản lý chứng chỉ</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
              <li class="breadcrumb-item active">Quản lý chứng chỉ</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="col-12">
          <div class="card card-default">
            <div class="card-header">
              <!-- <h3 class="card-title">Danh sách chứng chỉ</h3> -->
             <div class="row">
               <div class="col-6">
                <button   type="button" (click)="openBadgeCreatePopup()"   data-toggle="modal" class="btn btn-primary float-left btn-flat" ><i class="fas fa-plus"></i> Tạo chứng chỉ
                </button>
                <button type="button" [disabled]="badgeIdsChecked?.length === 0" class="btn btn-default btn-flat mr-1 ml-2" matTooltip="Xóa" (click)="onDeleteMultipleBadge()" matTooltipClass="tooltip-red"><i class="fas fa-trash font-size-18"></i></button>
                <span>Số chứng chỉ đã chọn: {{badgeIdsChecked?.length}}</span>
              </div>
               <div class="col-6">
                <form class="form-inline float-right">
                  <div class="form-group m-2">
                    <label class="mr-2">Tìm kiếm</label>
                    <input
                      type="search" name="search"
                      [(ngModel)]="searchBadge.keyword"
                      class="form-control form-control-sm mr-2"
                      placeholder="Tên chứng chỉ"
                      (change)="onKeyUp()"
                    />
                  </div>
                </form>
               </div>
             </div>
             <div class="caution">
               <span class="text-danger"><i>(*) {{'badge.caution' | translate}}</i></span>
             </div>
            </div>
            <div class="card-body" style="display: block">
              <div class="col-12 table-responsive">
                <table class="table table-hover" matSort (matSortChange)="sortData($event)">
                  <thead>
                    <tr>
                      <th>
                        <mat-icon class="mt-1">
                          <mat-checkbox class="example-margin" [(ngModel)]="completedAll" [disabled]="badgeIdsCanChecked === 0" (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                        </mat-icon>
                    </th>
                      <th mat-sort-header="id">STT</th>
                      <th style="width:20%" mat-sort-header="name">Tên chứng chỉ</th>
                      <th>Mô tả</th>
                      <th style="width:10%;" mat-sort-header="expireperiod">HSD (ngày)</th>
                      <th style="width:10%;" mat-sort-header="expiredate">Ngày hết hạn</th>
                      <th style="width:10%;" mat-sort-header="status">Trạng thái</th>
                      <th style="width:10%;"mat-sort-header="timecreated">Ngày tạo</th>
                      <th style="width:10%;">Thao tác</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let badge of badges; let i=index">
                      <td >
                        <mat-icon class="mt-1">
                          <mat-checkbox [(ngModel)]="badge.completed" (change)="oncheckboxItem(badge.id, $event.checked)" class="example-margin" [disabled]="badge.status === 1 || badge.status === 3"></mat-checkbox>
                        </mat-icon>
                      </td>
                      <td >{{(searchBadge.pageIndex - 1) * searchBadge.pageSize + i + 1}}</td>
                      <td (click)="goToBadgeDetail(badge.id)" >{{badge.name}}</td>
                      <td>{{limitString(badge?.description)}}</td>
                      <td>{{(badge.expireperiod || badge.expiredate) ? convertDays(badge.expireperiod) : 'Vô thời hạn'}}</td>
                      <td>{{getDateFromUnix(badge.expiredate)  | date:'dd/MM/yyyy'}}</td>
                      <td>
                        <span class="badge"[ngClass]="getStatusBadge(badge.status).colorBtn">{{getStatusBadge(badge.status).name | translate}}</span>
                        </td>
                      <td>{{getDateFromUnix(badge.timecreated) | date:'dd/MM/yyyy'}}</td>
                      <td class="project-actions" width="10%">
                        <div *ngIf="badge.readonly; else elseManage">
                          <button type="button" class="btn btn-default btn-sm mr-1" (click)="goToBadgeDetail(badge.id)" matTooltip="Xem" matTooltipClass="tooltip-red"><i class="fas fa-eye"></i></button>
                        </div>
                        <ng-template #elseManage>
                          <button type="button" class="btn btn-default btn-sm mr-1"  matTooltip="Chỉnh sửa" matTooltipClass="tooltip-red" (click)="goToBadgeDetail(badge.id)">
                            <i class="fas fa-edit">
                            </i>
                          </button>
                          <button  type="button" class="btn btn-default btn-sm mr-1" [disabled]="badge.status === 1 || badge.status === 3" matTooltip="Xóa" matTooltipClass="tooltip-red" (click)="deleteBadge([badge.id])">
                            <i class="fas fa-trash">
                            </i>
                          </button>
                        </ng-template>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              <p *ngIf="badges?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
              </div>
              <div class="card-footer text-center">
                <div class="row">
                  <mat-paginator showFirstLastButtons
                                 [length]="totalRecord"
                                 [pageSize]="searchBadge.pageSize"
                                 [pageIndex]="searchBadge.pageIndex - 1 "
                                 (page)="changePage($event)"
                                 [hidePageSize]="false"
                                 [pageSizeOptions]="pageSizeOptions">
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
