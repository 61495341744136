<section class="inner_page_breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 text-center">
        <div class="breadcrumb_content mt-2">
          <h4 class="breadcrumb_title">{{ 'euchangepassword.title' | translate }}</h4>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="/home">Trang chủ</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section>


<main id="main">
  <section id="route">
    <div class="container">

      <form role="form" [formGroup]="changePasswordForm">

        <div class="form-group row mt-3">
          <p class="col-3 text-right mb-1">{{ 'euchangepassword.username' | translate }}: </p>
          <label class="col-4">{{ user.username}}</label>
        </div>

        <div class="form-group row mt-3">
          <p class="col-3 text-right mb-1">{{ 'euchangepassword.currentpassword' | translate }}: <span
            class="text-danger">*</span></p>

          <div class="col-4">
            <input type="password" class="form-control"
                   placeholder="{{ 'euchangepassword.currentpassword' | translate }}"
                   formControlName="currentpassword">

            <!-- Error message -->
            <small class="text-danger"
                   *ngIf="(changePasswordForm?.get('currentpassword')!.dirty
             || changePasswordForm?.get('currentpassword')!.touched)
             && changePasswordForm?.get('currentpassword')?.hasError('required')">
              {{ 'error.validate.input_required' | translate }}
            </small>
          </div>

        </div>

        <div class="form-group row mt-3">
          <p class="col-3 text-right mb-1">{{ 'euchangepassword.newpassword' | translate }}: <span
            class="text-danger">*</span></p>
          <div class="col-4">
            <input type="password" class="form-control"
                   placeholder="{{ 'euchangepassword.newpassword' | translate }}"
                   minlength="8" maxlength="20"
                   formControlName="newpassword">

            <!-- Error message -->
            <div *ngIf="(changePasswordForm?.get('newpassword')!.dirty
             || changePasswordForm?.get('newpassword')!.touched)">

              <small class="text-danger"
                     *ngIf="changePasswordForm?.get('newpassword')?.hasError('required')">
                {{ 'error.validate.input_required' | translate }}
              </small>
              <small class="text-danger"
                     *ngIf="changePasswordForm.get('newpassword').hasError('minlength')">
                {{ 'error.validate.password.minlength' | translate }}
              </small>
              <small class="text-danger"
                     *ngIf="changePasswordForm.get('newpassword').hasError('maxlength')">
                {{ 'error.validate.password.maxlength' | translate }}
              </small>
              <small class="text-danger"
                     *ngIf="changePasswordForm.get('newpassword').hasError('pattern')">
                {{ 'error.validate.password.pattern' | translate }}
              </small>
            </div>
          </div>
        </div>

        <div class="form-group row mt-3">
          <p class="col-3 text-right mb-1">{{ 'euchangepassword.confirmpassword' | translate }}: <span
            class="text-danger">*</span></p>
          <div class="col-4">
            <input type="password" class="form-control"
                   placeholder="{{ 'euchangepassword.confirmpassword' | translate }}"
                   formControlName="confirmpassword">

            <!-- Error message -->
            <div *ngIf="(changePasswordForm?.get('confirmpassword')!.dirty
             || changePasswordForm?.get('confirmpassword')!.touched)">
              <small class="text-danger"
                     *ngIf="changePasswordForm?.get('confirmpassword')?.hasError('required')">
                {{ 'error.validate.input_required' | translate }}
              </small>
              <small class="text-danger"
                     *ngIf="changePasswordForm.hasError('confirmpassword')">
                {{ 'error.validate.password.confirmpassword' | translate }}
              </small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-4 text-left">
            <button type="button"  (click)="showConfirmPopup()" [disabled]="changePasswordForm.invalid" class="btn btn-primary">
            <i class="fas fa-save mr-2"></i>  {{ 'common.save' | translate }}
            </button>
            <button class="btn btn-secondary ml-2" (click)="cancel()">Hủy</button>
          </div>
        </div>

      </form>




    </div>
  </section>
</main>
