import { DepartmentService } from '../../../shared/services/department.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {CourseCategoryService} from '../../../shared/services/course-category.service';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CourseService} from '../../../shared/services/course.service';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {CourseCategory} from '../../../shared/model/course-category.model';
import CommonUtil from '../../../shared/utils/common-util';
import {LIST_HOURS, LIST_MINUTES} from '../../../shared/constants/base.constant';
import {Course} from '../../../shared/model/course.model';
import {CustomValidators} from '../../../shared/utils/custom-validators';


@Component({
  selector: 'app-course-create',
  templateUrl: './course-create.component.html',
  styleUrls: ['./course-create.component.css']
})
export class CourseCreateComponent implements OnInit {

  isCreateExam =  false;

  courseType = 1;

  courseForm = this.fb.group({
    fullname: ['', [Validators.required, CustomValidators.notBlankValidator, Validators.maxLength(200)]],
    categoryid: [null, Validators.required],
    summary: [''],
    requireenglish: [],
    isopen: [],
    startdate: [, Validators.required],
    enddate: [, Validators.required],
    hourStartDate: ['', Validators.required],
    minuteStartDate: ['', Validators.required],
    hourEndDate: ['', Validators.required],
    minuteEndDate: ['', Validators.required],
    iscreatebadge: []
  });

  hoursList = LIST_HOURS;
  minutesList = LIST_MINUTES;
  courseCategoryList: CourseCategory[] = [];
  categoryMap = new Map();


  course: Course = {
    fullname: '',
    startdate: null,
    enddate: null
  };

  courseFile: any;
  avatarCourseInvalid: any;
  avatarCourseErrorRequired: any;
  startTimeInvalid = false;
  endTimeInvalid = false;

  currentDate: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrCustomService,
    private courseService: CourseService,
    private courseCategoryService: CourseCategoryService,
    public activeModal: NgbActiveModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private departmentService: DepartmentService
  ) {}

  ngOnInit(): void {
    if (this.isCreateExam) {
      this.courseType = 2;
    }
    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      this.courseCategoryList = response.body;
      CommonUtil.convertCategoryListToMap(this.courseCategoryList, this.categoryMap);
    });
  }

  createNewCourse() {

      // Danh dau la da cham de hien thi message loi
    if (this.courseForm.invalid) {
      CommonUtil.markFormGroupTouched(this.courseForm);
      return;
    }
    if (!this.courseFile && !this.isCreateExam) {
      this.avatarCourseErrorRequired = true;
      return;
    } else {
      this.avatarCourseErrorRequired = false;
    }

    this.course.startdate = CommonUtil.convertDateToTime(this.courseForm.value.startdate,
      this.courseForm.value.hourStartDate, this.courseForm.value.minuteStartDate);

    this.course.enddate = CommonUtil.convertDateToTime(this.courseForm.value.enddate,
      this.courseForm.value.hourEndDate, this.courseForm.value.minuteEndDate);

    if (this.course.startdate < this.currentDate.getTime() / 1000) {
      this.startTimeInvalid = true;
      return;
    } else {
      this.startTimeInvalid = false;
    }

    if  (this.course.enddate < this.course.startdate) {
      this.endTimeInvalid = true;
      return;
    } else {
      this.endTimeInvalid = false;
    }

    this.spinner.show();

    // Set value
    this.course.fullname = this.courseForm.value.fullname.trim();
    this.course.categoryid = this.courseForm.value.categoryid;
    this.course.summary = this.courseForm.value.summary ? this.courseForm.value.summary.trim() : '';
    this.course.coursetype = this.courseType;

    if (!this.isCreateExam) {
      this.course.requireenglish = +this.courseForm.value.requireenglish;
      this.course.isopen = +this.courseForm.value.isopen;
    }
    this.course.iscreatebadge = +this.courseForm.value.iscreatebadge;
    this.courseService.createNewCourse(this.course, this.courseFile, this.isCreateExam).subscribe(res => {
        this.toastrService.success('common.noti.create_success');
        this.activeModal.dismiss();
        if (this.isCreateExam) {
          this.router.navigate(['admin/exam/detail', res.body[0].id]);
        } else {
          this.router.navigate(['admin/course/detail', res.body[0].id]);
        }
        this.spinner.hide();
        // const departmentIds = [];
        // this.departmentService.getUserDepartmentTree(0).subscribe((data) => {
        //   data.body.forEach(department => {
        //     departmentIds.push(department.id);
        //   });
        //   this.courseService.assignDepartmentPositionTocourse(
        //     res.body[0].id, departmentIds, []
        //   ).subscribe((data) => {
        //     if (this.isCreateExam) {
        //       this.router.navigate(['admin/exam/detail', res.body[0].id]);
        //     } else {
        //       this.router.navigate(['admin/course/detail', res.body[0].id]);
        //     }
        //     this.spinner.hide();
        //   },
        //   err => {
        //     this.spinner.hide();
        //     this.toastrService.handlerError(err);
        //   });
        // });
      },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      }
    );
  }

  handleFileInput(files: FileList) {
    if (files.length > 0) {
      this.avatarCourseErrorRequired = false;
      if (files[0].type !== 'image/jpeg'
        && files[0].type !== 'image/jpg'
        && files[0].type !== 'image/png'
        && files[0].type !== 'image/bmp'
        && files[0].type !== 'image/gif') {
        this.avatarCourseInvalid = true;
      } else {
        this.courseFile = files[0];
        this.avatarCourseInvalid = false;
      }
    }
  }

  startDateChange($event) {
    if (this.courseForm.value.startdate) {
      this.course.startdate = CommonUtil.convertDateToTime(this.courseForm.value.startdate,
        this.courseForm.value.hourStartDate, this.courseForm.value.minuteStartDate);
    }
  }

  endDateChange($event) {
    if (this.courseForm.value.enddate) {
      this.course.enddate = CommonUtil.convertDateToTime(this.courseForm.value.enddate,
        this.courseForm.value.hourEndDate, this.courseForm.value.minuteEndDate);
    }
  }
}
