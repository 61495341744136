import { EndUserExamResultComponent } from './landing-page/end-user-exam-result/end-user-exam-result.component';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToastrModule} from 'ngx-toastr';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {ToastrCustomService} from './shared/services/toastr-custom.service';
import {HttpErrorInterceptor} from './shared/interceptor/http-error-interceptor.service';
import {LoginComponent} from './login/login.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {CommonModule, LOCATION_INITIALIZED} from '@angular/common';
import {AdminModule} from './admin/admin.module';
import {EndUserModule} from './end-user/end-user.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ConfirmModalComponent} from './shared/modal/confirm-modal/confirm-modal.component';
import {MatListModule} from '@angular/material/list';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {InfoModalComponent} from './shared/modal/info-modal/info-modal.component';
import { BbbLogoutComponent } from './bbb-logout/bbb-logout.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {MatRadioModule} from '@angular/material/radio';
import {LandingPageComponent} from './landing-page/landing-page.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import {ActivationEmailComponent} from './landing-page/activation-email/activation-email.component';
import { StatisticalPopupComponent } from './landing-page/statistical-popup/statistical-popup.component';
import { DocumentPopupComponent } from './landing-page/document-popup/document-popup.component';



export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.setDefaultLang('vi');
      translate.use('vi').subscribe(() => {
      }, err => {
        console.error(`Problem with language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ConfirmModalComponent,
    InfoModalComponent,
    BbbLogoutComponent,
    LandingPageComponent,
    ActivationEmailComponent,
    EndUserExamResultComponent,
    StatisticalPopupComponent,
    DocumentPopupComponent
  ],
  imports: [
    AdminModule,
    EndUserModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatListModule,
    MatExpansionModule,
    MatListModule,
    NgxWebstorageModule.forRoot({prefix: 'lms', separator: '-'}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
    ToastrModule.forRoot(),
    MatRadioModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule
  ],
  providers: [
    ToastrCustomService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `/assets/i18n/`, '.json');
}
