<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Quản lý chứng chỉ</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý chứng chỉ</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <section class="content">
    <div class="container-fluid">
      <button class="btn " [ngClass]="{'btn-success' : activedBadge === false,'btn-default' : activedBadge === true }"
              (click)="chageStatusBadge()">{{activedBadge === false ? 'Sử dụng chứng chỉ' : 'Khóa chứng chỉ'}}</button>
      <!--phan danh sach khoa hoc-->
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            Thông tin chứng chỉ
          </ng-template>
          <app-badge-info [badgeId]="id"></app-badge-info>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Danh sách khóa học
          </ng-template>
          <app-badge-course [badgeId]="id" [readonly]="badge?.readonly"></app-badge-course>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Danh sách kỳ thi
          </ng-template>
          <app-badge-exam [badgeId]="id" [readonly]="badge?.readonly"></app-badge-exam>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Danh sách học viên
          </ng-template>
          <app-badge-student [readonly]="badge?.readonly" [badgeId]="id"></app-badge-student>
        </mat-tab>
      </mat-tab-group>
    </div><!-- /.container-fluid -->
  </section>
</div>
