<div class="modal-header">
  <h5 class="modal-title">Thêm mới học viên</h5>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
  (click)="activeModal.dismiss()"><i class="fas fa-times"></i>
</button>
</div>
<div class="modal-body">
  <div class="row">
    <p class="col-12">Danh sách học viên chưa có trong khóa học</p>
    <!-- select search students out course -->
    <!-- <div class="col-12 mt-2 ">
      <div class="row">
        <div class="col-12">
          <ng-multiselect-dropdown
            [settings]="dropdownPositionSettings"
            [data]="studentsOutCourse"
            placeholder="Chọn học viên"
            [(ngModel)]="students"
            (onDeSelect)="onDeSelect($event)"
            (onDeSelectAll)="onDeSelectAll()"
          >
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div> -->
    <div class="col-4">
      <input type="text" (change)="onSearchStudent($event.target.value)" class="form-control" placeholder="Tìm kiếm tên học viên">
    </div>
    <div class="col-12 mt-3 table-responsive overflow-auto" style="height: 50vh;">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>
            <mat-icon class="mt-1">
              <mat-checkbox class="example-margin" [(ngModel)]="completedAll"
                            (change)="oncheckboxAll($event.checked)"></mat-checkbox>
            </mat-icon>
          </th>
          <th>STT</th>
          <th>Họ tên</th>
          <th>Email</th>
          <th>Đơn vị</th>
          <th>Chức danh</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let student of studentsOutCourse; let i = index">
          <td>
            <mat-icon class="mt-1">
              <mat-checkbox [checked]="student.checked" (change)="onCheckBoxItem(student.id, $event.checked)" class="example-margin"></mat-checkbox>
            </mat-icon>
          </td>
          <td>{{i + 1 + (searchStudent.page - 1) * searchStudent.limit}}</td>
          <td>{{student.fullname}}</td>
          <td>{{student.email}}</td>
          <td matTooltip="{{student.departmentname}}" matTooltipClass="tooltip-inner">{{student.departmentname?.length <= 50 ? student.departmentname : (student.departmentname | slice:0:50) + '...' }}</td>
          <td matTooltip="{{student.positionname}}" matTooltipClass="tooltip-inner">{{student.positionname?.length <= 50 ? student.positionname : (student.positionname | slice:0:50) + '...' }}</td>
        </tr>
        </tbody>
      </table>
      <p *ngIf="studentsOutCourse?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
    </div>
    <div class="card-footer text-center col-12" style="z-index: 100000;">
      <div class="row">
        <mat-paginator showFirstLastButtons
                       [length]="totalRecord"
                       [pageSize]="searchStudent.limit"
                       [pageIndex]="searchStudent.page - 1 "
                       (page)="changePage($event)"
                       [hidePageSize]="false"
                       [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </div>
      <br>
      <div class="row">
        <div class="col-12">
          <p class="mr-auto">Số học viên đã chọn: <span class="font-weight-bold">{{studentIdsChecked?.length}}</span></p>
          <button type="submit" (click)="activeModal.dismiss('close')" class="btn ml-1 btn-secondary rounded-0 mr-2" data-dismiss="modal">Hủy</button>
          <button type="submit" class="btn btn-primary" [disabled]="studentIdsChecked?.length === 0" (click)="activeModal.close(studentIdsChecked)">Chọn</button>
        </div>
      </div>
    </div>
</div>


