import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, Validators} from '@angular/forms';
import * as moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {Badge} from 'src/app/shared/model/badge.model';
import {ToastrCustomService} from 'src/app/shared/services/toastr-custom.service';
import CommonUtil from 'src/app/shared/utils/common-util';
import {BadgeService} from '../../../shared/services/badge.service';
import {ChangeDetection} from "@angular/cli/lib/config/schema";
import {CustomValidators} from '../../../shared/utils/custom-validators';

@Component({
  selector: 'app-badge-info',
  templateUrl: './badge-info.component.html',
  styleUrls: ['./badge-info.component.css']
})
export class BadgeInfoComponent implements OnInit, AfterViewInit {

  @Input() badgeId: any;
  @ViewChild('imgPreview') imgPreview: ElementRef;

  formEnable = false;
  badge: any;
  radio: any = [
    {
      name: 'Vô thời hạn',
      value: 'nolimit',
    },
    {
      name: 'Chọn thời gian hết hạn',
      value: 'expiredate',
    },
    {
      name: 'Hạn sử dụng',
      value: 'expireperiod',
    }
  ];
  chosenItem = null;
  badgeEditForm = this.fb.group({
    id: [],
    name: ['', [Validators.required, CustomValidators.notBlankValidator, Validators.maxLength(50)]],
    radio: [],
    description: ['', Validators.maxLength(200)],
    expiredate: ['', Validators.required],
    expireperiod: ['', [Validators.required, CustomValidators.isNumberic]]
  });
  expiredate: any = null;
  expireperiod: any;
  badgeFile: any;
  badeFileInvalid: any;
  badeFileErrorRequired: any;
  selectedLink: string = "nolimit";
  ischecked = false;
  currentDate: Date = new Date();
  isUpdate = false;

  constructor(
    private fb: FormBuilder,
    private badgeService: BadgeService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrCustomService,
  ) {
    this.badgeEditForm.disable();
  }

  ngOnInit(): void {
    this.getInfoBadge(this.badgeId);
  }

  ngAfterViewInit(): void {

  }

  setradio(e: string): void {
    if (this.formEnable === true) {
      this.selectedLink = e;
      switch (e) {
        case "expiredate":
          this.badgeEditForm.removeControl('expireperiod');
          this.badgeEditForm.addControl('expiredate', new FormControl('', Validators.required));
          // this.selectedLink = "nolimit";
          // this.chosenItem = this.radio[0].value;
          if (!this.formEnable) {
            this.initFormCourseDetail(this.badge);
          }
          break;
        case "expireperiod":
          this.badgeEditForm.removeControl('expiredate');
          this.badgeEditForm.addControl('expireperiod', new FormControl('', [Validators.required, CustomValidators.isNumberic]));
          // this.setFormControlValue("expireperiod")
          // this.selectedLink = "nolimit";
          // this.chosenItem = this.radio[0].value;
          if (!this.formEnable) {
            this.initFormCourseDetail(this.badge);
          }
          break;
        default:
          this.badgeEditForm.removeControl('expireperiod');
          this.badgeEditForm.removeControl('expiredate');
          break;
      }
    } else {
      return;
    }
  }

  initFormCourseDetail(badge: Badge): void {
    const keyForm = Object.keys(this.badgeEditForm.value);
    for (const key of keyForm) {
      if (key === 'expireperiod') {
        if (badge[key] !== null) {
          this.selectedLink = key;
          this.chosenItem = key;
          this.expireperiod = Math.floor(this.badge[key] / 86400);
          this.badgeEditForm.controls['expireperiod'].setValue(this.expireperiod);
        }
      } else if (key === 'expiredate') {
        if (badge[key] !== null) {
          this.selectedLink = key;
          this.chosenItem = key;
          this.ischecked = true;
          this.badgeEditForm.controls[key].setValue(moment.unix(badge[key]).toDate());
        }
      } else {
        this.badgeEditForm.controls[key].setValue(badge[key]);
      }
    }
    this.badge.image = badge.image;
    this.imgPreview.nativeElement.src = badge.image;
  }

  getInfoBadge(badgeId: any) {
    // this.selectedLink = "nolimit";
    // this.chosenItem = this.radio[0].value;
    this.badgeService.detailBadge(badgeId).subscribe((data) => {
      this.badge = data.body?.results[0];
      // this.initFormCourseDetail(data.body.results[0]);
      if (data.body?.results[0]?.image) {
        this.badge.image = data.body.results[0].image;
        if (this.imgPreview != undefined) {
          this.imgPreview.nativeElement.src = data.body.results[0].image;
        }
      }
      this.badgeEditForm.controls['id'].setValue(badgeId);
      this.badgeEditForm.controls['name'].setValue(this.badge.name);
      this.badgeEditForm.controls['description'].setValue(this.badge.description);
      if ((this.badge.expiredate === null && this.badge.expireperiod === null) || (this.badge.expiredate === 0 && this.badge.expireperiod === 0)) {
        this.selectedLink = 'nolimit';
        this.chosenItem = 'nolimit';
      } else if (this.badge.expiredate && this.badge.expireperiod === null) {
        this.selectedLink = 'expiredate';
        this.chosenItem = 'expiredate';
        this.ischecked = true;
        this.badgeEditForm.controls['expiredate'].setValue(moment.unix(this.badgeEditForm['expiredate']).toDate());
      } else if (this.badge.expiredate === null && this.badge.expireperiod) {
        this.selectedLink = 'expireperiod';
        this.chosenItem = 'expireperiod';
        this.expireperiod = Math.floor(this.badge['expireperiod'] / 86400);
        this.badgeEditForm.controls['expireperiod'].setValue(this.expireperiod);
      }
    });
  }

  isSelected(name: string): boolean {
    if (!this.selectedLink) {
      return false;
    }
    this.setFormControlValue(this.selectedLink);

    return (this.selectedLink === name);
  }

  updateCourse() {
    if (this.selectedLink == "expireperiod") {
      this.badgeEditForm.removeControl('expiredate');
    }
    if (this.selectedLink == "expiredate") {
      this.badgeEditForm.removeControl('expireperiod');
    }
    if (this.selectedLink == "nolimit") {
      this.badgeEditForm.removeControl('expireperiod');
      this.badgeEditForm.removeControl('expiredate');
    }
    if (this.badgeEditForm.invalid) {
      if (this.badgeEditForm.invalid) {
        CommonUtil.markFormGroupTouched(this.badgeEditForm);
      }
      return;
    }
    if (this.selectedLink == "expiredate") {
      this.badgeEditForm.value.expiredate = this.badgeEditForm.value.expiredate.getTime() / 1000
    }
    if (this.selectedLink == "expireperiod") {
      this.badgeEditForm.value.expireperiod = this.badgeEditForm.value.expireperiod * 86400;
    }
    if (this.selectedLink == "nolimit") {
      this.badgeEditForm.value.expiredate = 0;
      this.badgeEditForm.value.expireperiod = 0;
    }
    this.spinner.show();
    this.badgeService.updateBadge(this.badgeEditForm.value, this.badgeFile).subscribe(
      res => {
        this.toastrService.success('common.noti.update_success');
        this.spinner.hide();
        this.getInfoBadge(this.badgeId);
        this.badgeEditForm.disable();
        this.formEnable = false;
      },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError('common.noti.update_error');
      }
    );
  }

  onUpdateBadge() {
    this.badgeEditForm.enable();
    this.formEnable = true;
  }

  cancelUpdateBadge() {
    this.badgeEditForm.removeControl('expireperiod');
    this.badgeEditForm.removeControl('expiredate');
    this.badgeEditForm.addControl('expiredate', new FormControl('', Validators.required));
    this.badgeEditForm.addControl('expireperiod',
      new FormControl('', [Validators.required, CustomValidators.isNumberic]));
    this.getInfoBadge(this.badgeId);
    this.badgeEditForm.disable();
    this.formEnable = false;
  }

  handleFileInput(files: FileList) {
    if (files.length > 0) {
      this.badeFileErrorRequired = false;
      if (files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg'
        && files[0].type !== 'image/png' && files[0].type !== 'image/bmp'
        && files[0].type !== 'image/gif') {
        this.badeFileInvalid = true;
      } else {
        this.imgPreview.nativeElement.src = window.URL.createObjectURL(files[0]);
        this.badgeFile = files[0];
        this.badeFileInvalid = false;
      }
    }
  }

  setFormControlValue(name) {
    if (this.badge !== undefined && this.formEnable === false) {
      if (name === 'expiredate') {
        return this.badgeEditForm.controls[name].setValue(moment.unix(this.badge[name]).toDate());
      }
      if (name === 'expireperiod') {
        this.expireperiod = Math.floor(this.badge[name] / 86400);
        return this.badgeEditForm.controls[name].setValue(this.expireperiod);
      }
    }
    return null;
  }
}
