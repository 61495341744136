<div class="row mb-5">
  <div class="col-lg-3 col-md-6">
    <div *ngIf="topicContent.length> 0; else noSection; let i = index">
      <mat-accordion [multi]="true">
        <mat-expansion-panel [expanded]="true" *ngFor="let section of topicContent;let i=index">
          <mat-expansion-panel-header>
            <mat-panel-title matTooltip="{{section?.name}}" matTooltipClass="tooltip-inner">
              <a href="javascript:;"
                 [attr.aria-expanded]="i == 0 ? true : false"
              >{{section?.name?.length <= 40 ? section?.name : (section?.name | slice:0:40) + '...' }}</a>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list id="{{'submenu'+section.id}}" [multiple]="false">
            <mat-list-option *ngFor="let module of section.modules; let j = index" (click)="showTab(module)" [selected]="module.selected">
                          <span matTooltip="{{module?.name}}" matTooltipClass="tooltip-inner" ><span>{{module?.name?.length <= 35 ? module?.name : (module?.name | slice:0:35) + '...' }}</span>
                          <br/><small>{{module?.mimetype}} - {{(module?.timelimit ? module?.timelimit / 60 : 0) | number}} phút</small></span>
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <ng-template #noSection>
      <!-- <p class="text-center p-5 mt-5">{{'common.no_record_enduser' | translate}}</p> -->
    </ng-template>
  </div>
  <div class="col-lg-9 col-md-6 box-object overflow-auto" style="height: 85vh;">
    <div class="row">
      <div class="col-12">

        <app-eu-page-content
          [module]="currentModule"
          [courseId]="courseId"
          [section]="section"
          *ngIf="tabname === 'page'">
        </app-eu-page-content>

        <app-eu-resource-content
          [module]="currentModule"
          [courseId]="courseId"
          [section]="section"
          *ngIf="tabname === 'resource'">
        </app-eu-resource-content>

        <app-eu-bigbluebutton-content
          [courseId]="courseId"
          [moduleInput]="currentModule"
          [section]="section"
          *ngIf="tabname === 'bigbluebuttonbn'">
        </app-eu-bigbluebutton-content>

        <app-eu-scorm-content
          [module]="currentModule"
          [courseId]="courseId"
          [section]="section"
          *ngIf="tabname === 'scorm'">
        </app-eu-scorm-content>

      </div>
      <div class="col-12 mt-3">
        <button class="btn btn-default float-right" *ngIf="!nextButtonHide" (click)="nextModuleSelected()"><i class="fas fa-arrow-right mr-2"></i>Tiếp theo</button>
        <button class="btn btn-default float-left" *ngIf="!nextButtonHide" (click)="previousModuleSelected()"><i class="fas fa-arrow-left mr-2"></i>Quay lại</button>
      </div>
    </div>
    <div *ngIf="!currentModule" class="row p-4" style="font-size: 11pt;">
      <div class="col-12 text-center border bg-light p-3">
        <img src="/assets/img-course.svg" alt="">
        <p>Chưa có nội dung, vui lòng quay lại thời gian tới</p>
      </div>
      <!-- <p class="fix-position p-3 mt-3">{{'common.no_record_enduser' | translate}}</p> -->
    </div>
  </div>
</div>
