import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ABC, abc, iii, III, T123 } from 'src/app/shared/constants/base.constant';
import { QuizContentService } from 'src/app/shared/services/quiz-content.service';
import CommonUtil from 'src/app/shared/utils/common-util';

@Component({
  selector: 'app-exam-preview',
  templateUrl: './exam-preview.component.html',
  styleUrls: ['./exam-preview.component.css']
})
export class ExamPreviewComponent implements OnInit {

  listQuestion = [];
  quizid:number;
  quizName:string;
  elmnt: any;
  checknoquestion: boolean;
  permission:boolean = true;
  constructor(
    private reportService: QuizContentService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public domSanitizer2: DomSanitizer) { }

  ngOnInit(): void {
    // tslint:disable-next-line:radix
    this.quizid = parseInt(this.route.snapshot.paramMap.get('quizId'))
    // console.log(this.quizid);
    this.getReportExamByUser();

  }

  getReportExamByUser() {
    let params = {
      quizid: this.quizid
    };

    this.spinner.show();
    this.reportService.quizPreview(params).subscribe((data) => {
      this.quizName = data.body.quizname;
      this.listQuestion = data.body.results;
      if(this.listQuestion.length == 0){
        this.checknoquestion = true;
      }
      this.listQuestion.forEach(element => {
        if (element.answernumbering === 'abc') {
          element.answernumbertype = abc;
        } else if (element.answernumbering === 'ABCD') {
          element.answernumbertype = ABC;
        } else if (element.answernumbering === '123') {
          element.answernumbertype = T123;
        } else if (element.answernumbering === 'iii') {
          element.answernumbertype = iii;
        } else if (element.answernumbering === 'IIII') {
          element.answernumbertype = III;
        }
        if (element.qtype === 'truefalse') {
          element.typeCheckBox = 'radio';
        } else if (element.qtype === 'multichoice') {
          if (element.single === 1) {
            element.typeCheckBox = 'radio';
          }
          if (element.single === 0) {
            element.typeCheckBox = 'checkbox';
          }
        } else if (element.qtype === 'multichoiceset') {
          element.typeCheckBox = 'checkbox';
        } else if (element.qtype === 'random') {
          element.questiontext = 'Câu hỏi ngẫu nhiên';
        }
        element.condition = 1;
        if (element.questiontext.includes('<figure class=\"media\">')) {
          element.linkVideo = CommonUtil.linkVideoCkeditorToLinkEmbedYoutube(element.questiontext);
        }
      });
      this.spinner.hide()
    },
    (err)=>{
      this.permission = false;
      this.spinner.hide()
    });
  }

  removeLineBreak(someText) {
    someText = someText.replace(/(\r\n|\n|\r)/gm, '').trim();
    return someText;
  }

  checkAnswer(answer, userAnswer) {
    answer = answer.replace('<p>', '').replace('</p>', '').replace(/&nbsp;/gi, '').trim();
    if (userAnswer) {
      return answer === this.removeLineBreak(userAnswer);
    } else {
      return false;
    }
  }

  checkAnswerMultiChoice(answer, userAnswer) {
    answer = answer.replace('<p>', '').replace('</p>', '').replace(/&nbsp;/gi, '').trim();
    if (userAnswer) {
      userAnswer = this.removeLineBreak(userAnswer).split(';');
      userAnswer = userAnswer.map(a => a.trim());
      return userAnswer.includes(answer);
    } else {
      return false;
    }
  }

  nextToElement(slot) {
    this.elmnt = document.getElementById(slot);
    this.elmnt.scrollIntoView(false);
  }

  back() {
    // this.$localStorage.store('openTab', 2);
    window.history.back();
  }

  @HostListener('window:beforeunload')
  handleBeforeUnload() {
    if (this.checknoquestion){
      return true;
    }else{
      return false;
    }
  }
}
