import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-statistical-popup',
  templateUrl: './statistical-popup.component.html'})
export class StatisticalPopupComponent implements OnInit {

  @Input() departments: [];
  @Input() totalStudent: 0;
  @Input() totalAttempt: 0;

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.activeModal.dismiss('close');
  }

}
