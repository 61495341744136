import CommonUtil from 'src/app/shared/utils/common-util';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AbstractService} from 'src/app/shared/services/abstract.service';
import {MOODLE_INFO} from 'src/app/shared/constants/base.constant';
import {map} from 'rxjs/operators';

type EntityArrayResponse = HttpResponse<any>;

@Injectable({providedIn: 'root'})
export class ExamListService {

  constructor(
    private abstractService: AbstractService
  ) {}

  listExam(params?: any): Observable<EntityArrayResponse> {
    const data = this.abstractService.getCommonInput('mobifone_course_enduser_my_exam_student');
    CommonUtil.appendDataToFormData(data, 'params', params);
    const returndata = this.abstractService.post(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityArrayResponse) => res));
    return returndata;
  }

}
