import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Component, Input, OnInit} from '@angular/core';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import * as moment from 'moment';
import {CourseService} from '../../../shared/services/course.service';

@Component({
  selector: 'app-eu-course-history-content',
  templateUrl: './eu-course-history-content.component.html',
  styleUrls: ['./eu-course-history-content.component.css']
})
export class EuCourseHistoryContentComponent implements OnInit {

  @Input() courseId: number;

  listHistoryLearning: any = [];

  constructor(
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private courseService: CourseService,
  ) {
  }

  ngOnInit(): void {
    this.getLearningHistory();
  }

  getLearningHistory() {
    return this.courseService.getHistoryLerning(this.courseId).subscribe((data) => {
      this.listHistoryLearning = data.body;
    }, err => {
      // this.toastrService.handlerError(err);
    });
  }

  convertToDate(date) {
    if (date) {
      return moment.unix(date).toDate();
    }
    return null;
  }

}
