import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CourseCategoryService} from '../../../shared/services/course-category.service';
import CommonUtil from '../../../shared/utils/common-util';
import {CourseCategory} from '../../../shared/model/course-category.model';
import { TOPIC_CONST } from 'src/app/shared/constants/base.constant';

@Component({
  selector: 'app-bank-search',
  templateUrl: './bank-search.component.html',
  styleUrls: ['./bank-search.component.css']
})
export class BankSearchComponent implements OnInit {

  searchQues: any;

  active0: string;
  active1: string;
  active2: string;
  active3: string;
  courseCategoryList: CourseCategory[] = [];
  categoryMap = new Map();
  categoryId = 0;
  qtype = '';
  level = 0;
  TOPIC_CONST = TOPIC_CONST;

  constructor(
    public activeModal: NgbActiveModal,
    private courseCategoryService: CourseCategoryService
  ) {}

  ngOnInit(): void {
    if (this.searchQues.level) {
      this.searchLevel(this.searchQues.level);
    } else {
      this.active0 = 'active2';
    }
    if (this.searchQues.categoryid) {
      this.categoryId = this.searchQues.categoryid;
    }
    if (this.searchQues.qtype) {
      this.qtype = this.searchQues.qtype;
    }

    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      this.courseCategoryList = response.body;
      CommonUtil.convertCategoryListToMap(this.courseCategoryList, this.categoryMap);
    });
  }

  searchLevel(index) {
    this.level = +index;
  }

  onSearchQuestion() {
    const searchParams = {
      categoryId: this.categoryId,
      qtype: this.qtype,
      level: this.level,
    };
    this.activeModal.close(searchParams);
  }

  onChangeDataLevelSearch(type) {
    this.qtype = type;
  }

  resetSearch() {
    this.categoryId = 0;
    this.searchLevel(0);
    this.qtype = '';
  }

}
