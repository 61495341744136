import { NgxSpinnerService } from 'ngx-spinner';
import { PageEvent } from '@angular/material/paginator';
import { CourseService } from '../../../shared/services/course.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {Course} from '../../../shared/model/course.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {COURSE_TYPE} from '../../../shared/constants/base.constant';
import * as moment from 'moment';

@Component({
  selector: 'app-course-select',
  templateUrl: './course-select.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./course-select.component.css']
})
export class CourseSelectComponent implements OnInit {
  public activeIndex: any = 0;
  @Input() open: string;
  @Input() usedToReport: number;
  @Input() titleModal: string;
  @Output() loadAll = new EventEmitter();
  completedAll = false;
  course: Course[] = [];
  exam: Course[] = [];
  courses = new Array();
  courseIds: number[] = [];
  courseIdsChecked: number[] = [];
  coursetype: number;
  search: any;
  @Input() isReportSreen: boolean;
  @Input() arrayidCourses: any[];
  ////
  @Input() listCourses: Course[] = [];
  @Input() listExam: Course[] = [];
  @Output() transferCourseIds = new EventEmitter<string>();
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  pageIndex: number = 1;
  pageSize: number = 10;

  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(
    public activeModal: NgbActiveModal,
    private programPositionService: CourseService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    if (this.open === 'course') {
      this.coursetype = COURSE_TYPE.COURSE;
    } else if (this.open === 'exam') {
      this.coursetype = COURSE_TYPE.EXAM;
    }
    this.getListCourse();
  }

  onRadiocheckboxItem(courseId: number) {
    this.courseIdsChecked[0] = courseId;
  }

  oncheckboxItem(courseId: number, checked: any) {
    if (checked) {
      this.courses?.forEach(c => {
        if (c.id === courseId) {
          c.completed = true;
          this.courseIds?.push(courseId);
          this.courseIdsChecked?.push(courseId);
          return;
        }
      });
      // nếu số khóa checked bằng số khóa học có thể checked trong page thì set checkboxall = true
      if (this.courseIds?.length > 0 && this.courseIds?.length === this.courses?.length && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.completedAll = false;
      this.courses?.forEach(c => {
        if (c.id === courseId) {
          c.completed = false;
          this.courseIds?.splice(this.courseIds?.indexOf(courseId), 1);
          this.courseIdsChecked?.splice(this.courseIdsChecked?.indexOf(courseId), 1);
          return;
        }
      });
    }
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.courses.forEach(c => {
        c.completed = checked;
        if (!this.courseIds.includes(c.id)) {
          this.courseIds.push(c.id);
        }
        if (!this.courseIdsChecked?.includes(c.id)) {
          this.courseIdsChecked?.push(c.id);
        }
      });
      this.completedAll = true;
    } else {
      this.courseIds?.forEach(id => {
        this.courseIdsChecked?.splice(this.courseIdsChecked?.indexOf(id), 1);
      });
      this.courseIds = [];
      this.courses?.forEach(c => {
        c.completed = false;
      });
      this.completedAll = false;
    }
  }

  save() {
    if (this.courseIdsChecked?.length === 0) {
      this.toastrService.error('error.needSelectCourse');
      return;
    }
    // push list id course to screen course report
    if (this.isReportSreen) {
      this.activeModal.close(this.courseIdsChecked);
    }
  }

  // lay ds khoa hoc cho phan chuc danh
  getListCourse() {
    this.spinner.show();
    const params = {
      limit: this.pageSize,
      page: this.pageIndex,
      coursetype: this.coursetype,
      search:  this.search,
      usedtoreport:this.usedToReport
    };
    this.courseIds = [];
    this.programPositionService.managedCourse(params).subscribe(
      data => {
        data.body?.results?.forEach(c => {
          if (this.courseIdsChecked?.includes(c.id)) {
            c.completed = true;
            this.courseIds?.push(c.id);
          }
        });
        // nếu số khóa học được checked = số khóa học có thể checked thì set completedAll = true
        if (this.courseIds?.length > 0 && (this.courseIds?.length === data.body?.results?.length)) {
          this.completedAll = true;
        } else {
          this.completedAll = false;
        }
        this.courses = data.body.results;
        this.totalRecord = data.body.total;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      }
    );
  }

  onChangeKeyword() {
    this.pageIndex = 1;
    this.courseIdsChecked = [];
    this.getListCourse();
  }

  changePage(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getListCourse();
  }


  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }
}

