<div class="content-wrapper" *ngIf="readyLoad">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Quản lý thông báo</h1>
          <button type="button" class="btn btn-primary btn-lg" (click)="createNotity()" *ngIf="!isUpdate" style="margin-top: 2%;"><i
            class="fas fa-save mr-2"></i>Gửi Thông Báo
          </button>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/notification']">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý thông báo</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <section class="content">
    <div class="container-fluid">
      <!--phan danh sach khoa hoc-->
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            Mẫu thông báo
          </ng-template>
          <app-notify-info [newInforNotifi]="inforNotifi" ></app-notify-info>
        </mat-tab>
        <mat-tab [disabled]="isUpdate">
          <ng-template mat-tab-label>
            Danh sách khóa học
          </ng-template>
          <app-badge-course [isNotify]="true"></app-badge-course>
        </mat-tab>
        <mat-tab [disabled]="isUpdate">
          <ng-template mat-tab-label>
            Danh sách kỳ thi
          </ng-template>
          <app-badge-exam [isNotify]="true"></app-badge-exam>
        </mat-tab>
        <mat-tab *ngIf="showFullRoles" [disabled]="isUpdate">
          <ng-template mat-tab-label>
            Danh sách đơn vị/chức danh
          </ng-template>
          <app-add-user (departmentidsEmit)="getDepartmentIds($event)" (positionidsEmit)=getPositionIds($event) [isNotify]="true"></app-add-user>
        </mat-tab>
        <mat-tab *ngIf="showFullRoles" [disabled]="isUpdate">
          <ng-template mat-tab-label>
            Danh sách người dùng
          </ng-template>
          <app-add-single-member (useridsEmit)="getUserIds($event)"></app-add-single-member>
        </mat-tab>
      </mat-tab-group>
    </div><!-- /.container-fluid -->
  </section>
</div>
