import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {DEFAULT_COURSE_IMAGE, USER_ROLE} from './../../shared/constants/base.constant';
import {USER_INFO} from 'src/app/shared/constants/base.constant';
import {LocalStorageService} from 'ngx-webstorage';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {CourseService} from '../../shared/services/course.service';
import {PositionService} from '../../shared/services/position.service';
import {FileService} from '../../shared/services/file.service';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  myCourse: any = [];
  myCourseTeacher: any = [];
  // courseInPosition: any = [];
  listPosition = [];
  overviewStudent: any;
  isTeacher = false;
  listCourseRegister: any = [];

  paramSearchDefault = {
    limit: 8,  // page size default
    page: 1   // page index default
  };

  constructor(
    private courseService: CourseService,
    private positionService: PositionService,
    private $localStorage: LocalStorageService,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    private toastrCustomService: ToastrCustomService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.getMyCourse();
    // this.getCourseInPosition();
    this.onOverviewStudent();
    this.getListPosition();
    this.getTeacherCourse();
    this.getListCourseStudentCanRegister();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  getMyCourse() {
    this.spinner.show();
    return this.courseService.getMyCourses(this.paramSearchDefault).subscribe((data) => {
      this.myCourse = data.body;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastrCustomService.handlerError(err);
    });
  }

  getTeacherCourse(){
    const roles = this.$localStorage.retrieve(USER_INFO.ROLES);
    if (roles) {
      for (const role of roles) {
        if (role.shortname === USER_ROLE.TEACHER) {
          this.isTeacher = true;
          this.courseService.getMyCourseTeaching(this.paramSearchDefault).subscribe(res => {
            this.myCourseTeacher = res.body.results;
          }, err => {
            this.toastrCustomService.handlerError(err);
          });
        }
      }
    }
  }

  onOverviewStudent() {
    return this.courseService.overviewStudent().subscribe((data) => {
      this.overviewStudent = data.body;
    }, err => {
      this.toastrCustomService.handlerError(err);
    });
  }

  getListPosition() {
    return this.positionService.getListRoadMap().subscribe((data) => {
      this.listPosition = data.body;
    }, err => {
      this.toastrCustomService.handlerError(err);
    });
  }

  reloadListCourseStudent($event) {
    if ($event) {
      this.getMyCourse();
      this.getListCourseStudentCanRegister();
    }
  }

  getListCourseStudentCanRegister() {
    this.spinner.show();
    const params =  {
      limit: 8,
      page: 1
    }
    this.courseService.getListCourseStudentCanRegister(params).subscribe(
      (res: any) => {
        res.body?.results?.forEach(course => {
          course.isCanRegister = true;
        });
        this.listCourseRegister = res.body.results;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.toastrCustomService.handlerError(err);
      }
    )
  }

  convertLinkimg(filename, filearea, contextId) {
    if (filename && filearea && contextId) {
      const urlFile = this.fileService.getFileUrl(contextId, filename, '/course/overviewfiles/');
      return this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
    }
    return DEFAULT_COURSE_IMAGE;
  }

}
