import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';
@Component({
  selector: 'app-detail-notify',
  templateUrl: './notification-history-detail.component.html',
  styleUrls: ['./notification-history-detail.component.css']
})
export class NotificationHistoryDetailComponent implements OnInit {

  @Input() notify: any;
  Editor = ClassicEditor;

  editorConfig = CKEDITOR_CONFIG.NOT_UPLOAD;

  constructor( public activeModal: NgbActiveModal,
               private fb: FormBuilder) { }

  detailNotify = this.fb.group({
    titleNotify: [],
    contentNotify: [],
    recipient: []
  });

  ngOnInit(): void {
    this.detailNotify.patchValue({
      titleNotify: this.notify.title,
      contentNotify: this.notify.content,
      recipient: this.notify.recipient
    });
    this.detailNotify.get('titleNotify').disable();
    this.detailNotify.get('contentNotify').disable();
    this.detailNotify.get('recipient').disable();
  }

}
