import { LOCAL_STORAGE_KEY } from './../../../shared/constants/base.constant';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Survey } from '../../../shared/model/survey';
import { SurveyService } from '../../../shared/services/survey.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent } from '../../../shared/modal/confirm-modal/confirm-modal.component';
import { ToastrCustomService } from '../../../shared/services/toastr-custom.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'ngx-webstorage';
import { USER_INFO, USER_ROLE } from 'src/app/shared/constants/base.constant';

@Component({
  selector: 'app-survey-single',
  templateUrl: './survey-single.component.html',
  styleUrls: ['./survey-single.component.css']
})
export class SurveySingleComponent implements OnInit, OnDestroy {

  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  completedAll = false;
  lastSort: any;
  pageEvent: PageEvent;
  showCreateSingleSurvey: boolean;
  idSurveyUpdate: any;
  surveyTemplateList: Survey[] = [];
  date: any;
  isManager: boolean;

  searchSurvey = {
    search: '',
    pageIndex: 0,
    pageSize: 10,
    sortColumn: 'id',
    sortType: 'desc',
  };

  constructor(
    private surveyService: SurveyService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private $localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    const roles = this.$localStorage.retrieve(USER_INFO.ROLES);
    this.isManager = roles[0].shortname === USER_ROLE.MANAGER;
    const currentSearch = this.$localStorage.retrieve(LOCAL_STORAGE_KEY.SEARCH.SURVEY_TEMPLATE_SEARCH);
    if (currentSearch) {
      // Kiem tra xem truoc do co luu du lieu tim kiem khong
      this.searchSurvey.search = currentSearch.search;
      this.searchSurvey.pageIndex = currentSearch.pageIndex;
      this.searchSurvey.pageSize = currentSearch.pageSize;
      this.searchSurvey.sortColumn = currentSearch.sortColumn;
      this.searchSurvey.sortType = currentSearch.sortType;
      // Xoa di sau khi su dung
      this.$localStorage.clear(LOCAL_STORAGE_KEY.SEARCH.SURVEY_TEMPLATE_SEARCH);
    }
    this.searchTemplateSurvey();
    this.showCreateSingleSurvey = false;
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    window.location.href = '/admin/survey';
  }

  showCreateForm() {
    this.idSurveyUpdate = null;
    this.showCreateSingleSurvey = true;
  }

  editSurvey(idSurvey: any) {
    this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.SURVEY_TEMPLATE_SEARCH, this.searchSurvey);
    this.idSurveyUpdate = idSurvey;
    this.showCreateSingleSurvey = true;
  }

  hideCreateFormSurvey() {
    this.ngOnInit();
  }

  getSurveyTemplateList() {
    const params = {
      limit: this.searchSurvey.pageSize,
      page: this.searchSurvey.pageIndex + 1,
      search: this.searchSurvey.search,
      sortcolumn: this.searchSurvey.sortColumn,
      sorttype: this.searchSurvey.sortType
    };
    this.spinner.show();
    return this.surveyService.searchTemplateSurvey(params).subscribe(surveyList => {
      this.surveyTemplateList = surveyList.body.results;
      this.totalRecord = surveyList.body.total;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  publishSurvey(questionnaireId, qtype) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('survey.publish_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('survey.publish_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.publish');
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.spinner.show();
        let lengthListQuestion = 0;
        this.surveyService.getDetailSurvey(questionnaireId).subscribe(res => {
          const survey = res.body;
          lengthListQuestion = survey.listquestion.length;
          if (lengthListQuestion < 1) {
            this.spinner.hide();
            this.toastrService.error('survey.alert_length_list_question');
            return;
          } else {
            this.spinner.hide();
            this.publishSurveyAfterCheckSurveyHasQuestion(questionnaireId, qtype);
          }
        }, error => {
          this.spinner.hide();
          this.toastrService.handlerError(error);
          return;
        });
      }
    });

  }

  publishSurveyAfterCheckSurveyHasQuestion(questionnaireId, qtype) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('survey.publish_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('survey.publish_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.publish');

    modalDep.result.then(() => {

      ////
      this.spinner.show();
      ////
      this.date = Math.round((new Date().getTime() / 1000));
      this.surveyService.publishTemplateSurvey(questionnaireId, this.date, qtype).subscribe(() => {
        ////
        this.spinner.hide();
        ////

        this.toastrService.success('common.noti.publish_survey');
        this.getSurveyTemplateList();
      },
        err => {

          ////
          this.spinner.hide();
          ////
          this.toastrService.handlerError(err);
        });
    });
  }

  searchTemplateSurvey(event?: any) {
    if (event) {
      this.searchSurvey.pageIndex = 0;
    }
    this.getSurveyTemplateList();
  }

  ////
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData(sort: Sort) {
    this.searchSurvey.sortColumn = sort.active;
    this.searchSurvey.sortType = sort.direction;
    this.getSurveyTemplateList();
  }

  ////

  onDeleteSurvey(id: any) {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('survey.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('survey.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');

    modalDep.result.then(() => {
      this.surveyService.deleteTemplateSurvey(id).subscribe(() => {
        this.toastrService.success('common.noti.delete_success');
        this.getSurveyTemplateList();
      }, error => {
        this.toastrService.handlerError(error);
      });
    });
  }

  onChangePage(event) {
    this.searchSurvey.pageIndex = event.pageIndex;
    this.searchSurvey.pageSize = event.pageSize;
    this.getSurveyTemplateList();
  }

  goToDetail() {
    this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.SURVEY_TEMPLATE_SEARCH, this.searchSurvey);
  }
}
