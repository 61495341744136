import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {CourseCategory} from '../../../shared/model/course-category.model';
import {CourseCategoryService} from '../../../shared/services/course-category.service';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import CommonUtil from '../../../shared/utils/common-util';

@Component({
  selector: 'app-course-category-edit',
  templateUrl: './course-category-edit.component.html',
  styleUrls: ['./course-category-edit.component.css']
})
export class CourseCategoryEditComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private categoryService: CourseCategoryService,
    private modalService: NgbModal,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
  ) {
  }

  @Input() categories: any;
  @Input() category: CourseCategory;
  @Input() title;
  @Input() button;
  @Output() updateCategory = new EventEmitter<CourseCategory>();

  editCategoryForm = this.fb.group({
    id: [''],
    name: ['', [Validators.required, Validators.maxLength(200)]],
    parent: [''],
    description: ['', [Validators.maxLength(255)]],
  });

  cloneCategory: CourseCategory;
  categoryMap = new Map();

  ngOnInit(): void {
    if (this.category.id) { // case update
      this.cloneCategory = {...this.category};
      this.categoryService.getCourseCategoryTree(this.category.id).subscribe((data) => {
        const childList = data.body;
        // Loai bo don vi va cac don vi con khoi select box
        const newList = this.categories.filter((elem) => !childList.find(({id}) => elem.id === id));
        this.categories = newList;
        this.resetData();

      });
    } else { // case add new
      this.resetData();
    }

    this.editCategoryForm.patchValue({
      id: this.category.id,
      name: this.category.name,
      description: this.category.description,
      parent: this.category.parent
    });
  }


  createOrUpdateCourseCategory() {
    if (this.editCategoryForm.invalid) {
      // Danh dau la da cham de hien thi message loi
      CommonUtil.markFormGroupTouched(this.editCategoryForm);
      return;
    }
    this.spinner.show();
    if(this.category.id){// case update

      this.categoryService.updateCourseCategory(this.editCategoryForm.value).subscribe(
        res => {
          this.updateCategory.emit(this.category);
          this.toastrService.success(`common.noti.update_success`);
          this.close();
        },
        err => {
          this.spinner.hide();
          this.toastrService.handlerError(err);
        }
      );
    }else { // case add new
      this.categoryService.createCourseCategory(this.editCategoryForm.value).subscribe(
        res => {
          this.updateCategory.emit(this.category);
          this.toastrService.success(`common.noti.create_success`);
          this.close();
        },
        err => {
          this.spinner.hide();
          this.toastrService.handlerError(err);
        }
      );
    }

  }

  close() {
    this.spinner.hide();
    this.activeModal.dismiss('close');
  }

  resetData() {
    this.categoryMap.clear();

    this.categories.forEach(cat => {
      if (cat.parent != null) {
        const parentName = this.categoryMap.get(cat.parent);
        let name = '';
        if (parentName) {
          name = parentName + ' / ' + cat.name;
        } else {
          name = cat.name;
        }
        this.categoryMap.set(cat.id, name);
      } else {
        this.categoryMap.set(cat.id, cat.name);
      }
    });
  }


}
