import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AbstractService} from 'src/app/shared/services/abstract.service';
import {Observable} from 'rxjs';
import CommonUtil from 'src/app/shared/utils/common-util';
import {MOODLE_INFO} from 'src/app/shared/constants/base.constant';
import {map} from 'rxjs/operators';
import {Badge} from 'src/app/shared/model/badge.model';

type EntityResponseType = HttpResponse<Badge>;
type EntityArrayResponseType = HttpResponse<Badge[]>;

interface BadgePageResponse {
  results: Badge[];
  total: number;
}

@Injectable({providedIn: 'root'})
export class BadgeService {
  constructor(private abstractService: AbstractService) {
  }

  listBadge(param): Observable<any> {
    const dataCourse = this.abstractService.getCommonInput('mobifone_badge_list_badge');
    CommonUtil.appendDataToFormData(dataCourse, 'params', param);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: any) => res));
  }

  detailBadge(badgeId: any): Observable<HttpResponse<BadgePageResponse>> {
    const data = this.abstractService.getCommonInput('mobifone_badge_list_badge');
    const param = {
      ids: badgeId,
    };
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<BadgePageResponse>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<BadgePageResponse>) => res));
  }

  createBadge(param: any, badgeFile?: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_badge_create_badge');
    if (badgeFile) {
      data.append('image', badgeFile, badgeFile.name);
    }
    CommonUtil.appendDataToFormData(data, 'badge', param);
    return this.abstractService.post<Badge>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityResponseType) => res));
  }

  updateBadge(param: any, badgeFile?: any): Observable<EntityResponseType> {
    const data = this.abstractService.getCommonInput('mobifone_badge_update_badge');
    if (badgeFile) {
      data.append('image', badgeFile, badgeFile.name);
    }
    CommonUtil.appendDataToFormData(data, 'badge', param);
    return this.abstractService.post<Badge>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: EntityResponseType) => res));
  }

  deleteBadge(badgeId: any, keepBU: any): Observable<any> {
    const dataCourse = this.abstractService.getCommonInput('mobifone_badge_delete_badge');
    const badge = {
      ids: badgeId,
      keep: keepBU
    };
    CommonUtil.appendDataToFormData(dataCourse, 'params', badge);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: any) => res));
  }

  updateCourseToBadge(badgeId: any, courseIds: string, action: any): Observable<any> {
    const dataCourse = this.abstractService.getCommonInput('mobifone_badge_update_criteria_course');
    const param = {
      badgeid: badgeId,
      courseids: courseIds,
      type: action
    };
    CommonUtil.appendDataToFormData(dataCourse, 'params', param);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: any) => res));
  }

  getistCourseCriteria(param): Observable<any> {
    const dataCourse = this.abstractService.getCommonInput('mobifone_badge_list_course_criteria');
    CommonUtil.appendDataToFormData(dataCourse, 'params', param);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, dataCourse).pipe(map((res: any) => res));
  }

  activeCourse(badgeId: any, enable: any) {
    const data = this.abstractService.getCommonInput('mobifone_badge_change_status_badge');
    const params = {
      id: badgeId,
      enable: enable
    };
    CommonUtil.appendDataToFormData(data, 'badge', params);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data).pipe(map((res: any) => res));
  }

  getCertificateUser(param?: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_badge_enduser_list_badge');
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  getListStudentOnBadge(param?: any): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_badge_list_student_criteria');
    CommonUtil.appendDataToFormData(data, 'params', param);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  getDetailBadge(id?: number): Observable<HttpResponse<any>> {
    const data = this.abstractService.getCommonInput('mobifone_badge_enduser_detail_badge');
    CommonUtil.appendDataToFormData(data, 'id', id);
    return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
      .pipe(map((res: HttpResponse<any>) => res));
  }

  // revokeBadgeOfStudent(param?: any) {
  //   const data = this.abstractService.getCommonInput('mobifone_badge_revoke_certificate_student');
  //   CommonUtil.appendDataToFormData(data, 'params', param);
  //   return this.abstractService.post<any>(MOODLE_INFO.SERVICE_URL, data)
  //     .pipe(map((res: HttpResponse<boolean>) => res));
  // }

}
