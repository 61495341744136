import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable, isDevMode} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private $localStorage: LocalStorageService,
              private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body?.errorcode || event.body?.exception) {
              if ('invalidtoken' === event.body?.errorcode || 'accessexception' === event.body?.errorcode) {
                this.$localStorage.clear();
                this.router.navigate(['/home']);
                return;
              }

              // Chuyen 200 voi error code thanh 400 error
              if (isDevMode()) {
                console.log('Error response body: ', event.body);
              }
              // TODO: map to specific error
              throw new HttpErrorResponse({
                error: event.body,
                headers: event.headers,
                status: 400,
                statusText: 'Warning',
                url: event.url
              });
            }
            return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (isDevMode()) {
            console.error('Error response body: ', error);
          }
          return throwError(error);
        })
      );
  }
}
