import {environment} from '../../../environments/environment';

export const DEFAULT_COURSE_IMAGE = 'assets/end-user/img/crouse/7.jpg';

export const USER_INFO = {
  TOKEN: 'authenticationtoken',
  INFO: 'userinfo',
  ROLES: 'roles',
  MENUS: 'menus',
};

export const OVER_DUE_HANDLING_2 = [
  {key: 'autosubmit', value: 'type_one_description'},
  {key: 'graceperiod', value: 'type_two_description'},
  {key: 'autoabandon', value: 'type_three_description'},
];

export const OVER_DUE_HANDLING = {
  AUTO_SUBMIT: 'autosubmit',
  GRACE_PERIOD: 'graceperiod',
  AUTO_ABANDON: 'autoabandon',
  AUTO_SUBMIT_DESCRIPTION: 'type_one_description',
  GRACE_PERIOD_DESCRIPTION: 'type_two_description',
  AUTO_ABANDON_DESCRIPTION: 'type_three_description'
};

export const GRADE_METHOD_2 = [
  {key: 1, value: 'type_one_description_g'},
  // {key: 2, value: 'type_two_description_g'},
  {key: 3, value: 'type_three_description_g'},
  {key: 4, value: 'type_four_description_g'},
];

export const GRADE_METHOD = {
  QUIZ_GRADEHIGHEST: 1,
  QUIZ_GRADEAVERAGE: 2,
  QUIZ_ATTEMPTFIRST: 3,
  QUIZ_ATTEMPTLAST: 4,
  QUIZ_GRADEHIGHEST_DESCRIPTION: 'type_one_description_g',
  QUIZ_GRADEAVERAGE_DESCRIPTION: 'type_two_description_g',
  QUIZ_ATTEMPTFIRST_DESCRIPTION: 'type_three_description_g',
  QUIZ_ATTEMPTLAST_DESCRIPTION: 'type_four_description_g'
};
export const COMPLETION_STATE = {
  YET_EXAM: 0,
  USED_EXAM: 1,
  PASS_EXAM: 2,
  FAIL_EXAM: 3,
  YET_EXAM_DESCRIPTION: 'yet_exam_description_c',
  USED_EXAM_DESCRIPTION: 'used_exam_description_c',
  PASS_EXAM_DESCRIPTION: 'pass_exam_description_c',
  FAIL_EXAM_DESCRIPTION: 'fail_exam_description_c'
};
export const MOODLE_INFO = {
  LOGIN_URL: environment.apiUrl + '/login/token.php',
  SERVICE_URL: environment.apiUrl + '/webservice/rest/server.php',
  FILE_URL: environment.apiUrl + '/webservice/pluginfile.php/',
  SCORM_FILE_URL: environment.apiUrl + '/local/scormfile.php/',
  FORGOT_PASSWORD_URL: environment.apiUrl + '/local/forgotpassword.php',
  RESET_PASSWORD_URL: environment.apiUrl + '/local/resetpassword.php',
  REGISTER_USER_URL: environment.apiUrl + '/local/signup.php',
  PUBLIC_DEPARTMENT: environment.apiUrl + '/local/public_department.php',
  ACTIVATION_ACCOUNT: environment.apiUrl + '/local/activation-account.php',
  STATISTICAL_DEPARTMENT: environment.apiUrl + '/local/statistical-department.php',
  RESEND_VALIDATION_EMAIL: environment.apiUrl + '/local/resend_activation_email.php'
};

export const RESPONSE = {
  CONTENT_TYPE: 'json',
};

export const MODAL = {
  CLOSE: 'close',
  CONFIRM: 'confirm',
};

export const COURSE_TYPE = {
  COURSE: 1,
  EXAM: 2,
};

export const USER_ROLE = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  TEACHER: 'teacher',
  STUDENT: 'student'
};

export const USER_AUTH = {
  MANUAL: 'manual',
  LDAP: 'ldap',
};

export const USER_MENU = {
  COURSE: 'course',
  COURSE_CATEGORY: 'course-category',
  EXAM: 'exam',
  BADGE: 'badge',
  SURVEY: 'survey',
  QUESTION_BANK: 'question-bank',
  DEPARTMENT: 'department',
  POSITION: 'position',
  USER: 'user',
  NOTIFICATION: 'notification',
  ROLE: 'role',
  REPORT: 'report'
};
export const LEVEL_QUESTION = [
  {
    id: 1,
    level: 'easy'
  },
  {
    id: 2,
    level: 'medium'
  },
  {
    id: 3,
    level: 'difficul'
  }
];

export const QUESTION_TYPE = {
  TEXT: 'Text Box',
  RADIO: 'Radio Buttons',
  RATE: 'Rate (scale 1..5)'
};

export const URL_IMPORT_CSV_USER_TEMPLATE = {
  URL: environment.apiUrl + '/local/mobifone_user/import_template.xlsx'
};

export const MODULE_TYPE = [
  {
    type: 'resource',
    name: 'modType.resource',
    subType: [
      {
        mineType: ['video/mp4'],
        name: 'modType.mimeType.video'
      },
      {
        mineType: ['application/msword', 'application/doc', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/java-archive', 'application/vnd.ms-excel', 'text/xml', 'application/xml', 'application/zip', 'application/x-zip-compressed', 'application/rar', 'application/x-rar-compressed', 'application/pdf', 'image/png', 'image/jpeg', 'image/gif', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.rar', 'text/plain', 'text/html', 'application/x-msdownload'],
        name: 'modType.mimeType.doc'
      }
    ]
  },
  {
    type: 'page',
    name: 'modType.page',
    subType: []
  }
];

export const STATUS_BADGES = [
  {
    statusId: 0, // khóa, chưa có người nhận
    name: 'status_badge.lock_not_receiver',
    colorBtn: 'badge-secondary'
  },
  {
    statusId: 1, // được sử dụng, chưa có người nhận
    name: 'status_badge.open_not_receiver',
    colorBtn: 'badge-success'
  },
  {
    statusId: 2, // khóa, đã có người nhận
    name: 'status_badge.lock_has_received',
    colorBtn: 'badge-dark'
  },
  {
    statusId: 3, // được sử dụng, đã có người nhận
    name: 'status_badge.open_has_recevied',
    colorBtn: 'badge-info'
  }
];

export const USER_FUNCTIONS = {
  // Function - permission array is

  //COURSE
  CREATE_COURSE: ['admin', 'manager'],
  EDIT_COURSE_INFO: ['admin', 'manager'],
  EDIT_COURSE_MODULE: ['admin', 'manager', 'teacher'],
  ASSIGN_TEACHER_TO_COURSE: ['admin', 'manager'],
  ASSIGN_STUDENT_TO_COURSE: ['admin', 'manager'],
  UNASSIGN_TEACHER_TO_COURSE: ['admin', 'manager'],
  UNASSIGN_STUDENT_TO_COURSE: ['admin', 'manager'],
  SHOW_LIST_TEACHER_IN_COURSE: ['admin', 'manager', 'teacher', 'student'],
  SHOW_LIST_STUDENT_IN_COURSE: ['admin', 'manager', 'teacher', 'student'],
  EDIT_EXAM_IN_COURSE: ['admin', 'manager'],
  EDIT_BADGE_IN_COURSE: ['admin', 'manager'],
  SHOW_LIST_COURSE: ['admin', 'manager', 'teacher', 'student'],
  COMPLETE_COURSE: ['student'],
  DELETE_COURSE: ['admin', 'manager'],
  PUBLISH_COURSE: ['admin', 'manager'],

  // EXAM
  CREATE_EXAN: ['admin', 'manager'],
  CREATE_MODULE_EXAM: ['admin', 'manager'],
  SHOW_MODULE_EXAM: ['admin', 'manager', 'teacher', 'student'],
  SHOW_DETAIL_MODULE_EXAM: ['admin', 'manager', 'teacher', 'student'],
  COMPLETE_EXAM: ['student'],
  EDIT_MODULE_EXAM: ['admin', 'manager'],
  DELETE_MODULE_EXAM: ['admin', 'manager'],
  QUESTION_BANK: ['admin', 'manager'],
  DELETE_EXAM: ['admin', 'manager'],
  PUBLISH_EXAM: ['admin', 'manager'],

  // STUDENT MANAGEMENT
  CREATE_STUEDNT: ['admin', 'manager'],
  EDIT_STUDENT: ['admin', 'manager'],
  DELETE_STUDENT: ['admin', 'manager'],
  SHOW_LIST_STUDENT: ['admin', 'manager'],
  SHOW_DETAIL_STUDENT: ['admin', 'manager']
};

export const ROLE_MENU = {
  admin: ['course', 'course-category', 'exam', 'badge', 'survey', 'question-bank', 'department', 'position', 'user', 'notification', 'role', 'report'],
  manager: ['course', 'exam', 'badge', 'survey', 'question-bank', 'department', 'position', 'user', 'notification', 'report'],
  teacher: ['course', 'exam', 'question-bank', 'notification'],
  student: [],
};


export const FILE_UPLOAD = {
  MIME_TYPE_RESOURCE: ['application/msword', 'application/doc', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/java-archive', 'application/vnd.ms-excel', 'text/xml', 'application/xml', 'application/zip', 'application/x-zip-compressed', 'application/rar', 'application/x-rar-compressed', 'application/pdf', 'image/png', 'image/jpeg', 'image/gif', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.rar', 'text/plain', 'text/html', 'application/x-msdownload', 'video/mp4', 'video/x-flv', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv', 'video/avi', 'video/x-ms-wm'],
  MIME_TYPE_VIDEO: ['video/mp4', 'video/x-flv', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv', 'video/x-ms-wm', 'video/avi'],
  TYPE_DOCUMENT: ['doc', 'docx', 'xlm', 'xlsx', 'xlsm', 'pdf', 'powerpoint', 'rar', 'zip', 'excel', 'jpg', 'jpeg', 'png', 'ppt', 'pptx', 'jar', 'gif', 'exe', 'html', 'htm'],
  TYPE_VIDEO: ['mp4', 'mov', 'flv', 'avi', 'wmv'],
  SCORM: ['xml', 'zip']
};


export const LIST_HOURS = [
  {value: '', label: 'Giờ'},
  {value: 0, label: '00'},
  {value: 1, label: '01'},
  {value: 2, label: '02'},
  {value: 3, label: '03'},
  {value: 4, label: '04'},
  {value: 5, label: '05'},
  {value: 6, label: '06'},
  {value: 7, label: '07'},
  {value: 8, label: '08'},
  {value: 9, label: '09'},
  {value: 10, label: '10'},
  {value: 11, label: '11'},
  {value: 12, label: '12'},
  {value: 13, label: '13'},
  {value: 14, label: '14'},
  {value: 15, label: '15'},
  {value: 16, label: '16'},
  {value: 17, label: '17'},
  {value: 18, label: '18'},
  {value: 19, label: '19'},
  {value: 20, label: '20'},
  {value: 21, label: '21'},
  {value: 22, label: '22'},
  {value: 23, label: '23'},
];

export const LIST_MINUTES = [
  {value: '', label: 'Phút'},
  {value: 0, label: '00'},
  {value: 1, label: '01'},
  {value: 2, label: '02'},
  {value: 3, label: '03'},
  {value: 4, label: '04'},
  {value: 5, label: '05'},
  {value: 6, label: '06'},
  {value: 7, label: '07'},
  {value: 8, label: '08'},
  {value: 9, label: '09'},
  {value: 10, label: '10'},
  {value: 11, label: '11'},
  {value: 12, label: '12'},
  {value: 13, label: '13'},
  {value: 14, label: '14'},
  {value: 15, label: '15'},
  {value: 16, label: '16'},
  {value: 17, label: '17'},
  {value: 18, label: '18'},
  {value: 19, label: '19'},
  {value: 20, label: '20'},
  {value: 21, label: '21'},
  {value: 22, label: '22'},
  {value: 23, label: '23'},
  {value: 24, label: '24'},
  {value: 25, label: '25'},
  {value: 26, label: '26'},
  {value: 27, label: '27'},
  {value: 28, label: '28'},
  {value: 29, label: '29'},
  {value: 30, label: '30'},
  {value: 31, label: '31'},
  {value: 32, label: '32'},
  {value: 33, label: '33'},
  {value: 34, label: '34'},
  {value: 35, label: '35'},
  {value: 36, label: '36'},
  {value: 37, label: '37'},
  {value: 38, label: '38'},
  {value: 39, label: '39'},
  {value: 40, label: '40'},
  {value: 41, label: '41'},
  {value: 42, label: '42'},
  {value: 43, label: '43'},
  {value: 44, label: '44'},
  {value: 45, label: '45'},
  {value: 46, label: '46'},
  {value: 47, label: '47'},
  {value: 48, label: '48'},
  {value: 49, label: '49'},
  {value: 50, label: '50'},
  {value: 51, label: '51'},
  {value: 52, label: '52'},
  {value: 53, label: '53'},
  {value: 54, label: '54'},
  {value: 55, label: '55'},
  {value: 56, label: '56'},
  {value: 57, label: '57'},
  {value: 58, label: '58'},
  {value: 59, label: '59'},

];

export const ANSWER_NUMBERING = {
  abc: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k'],
  ABCD: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K'],
  T123: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  iii: ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii'],
  III: ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VII']
};

export const ABC = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'J',
    'K'

  ]
;
export const abc = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'j',
  'k',

];
export const T123 = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',

];
export const iii = [
  'i',
  'ii',
  'iii',
  'iv',
  'v',
  'vi',
  'vii',
  'viii',

];
export const III = [
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VII'

];

export const LOCAL_STORAGE_KEY =  {
  SEARCH: {
    COURSE_SEARCH: 'cms_course_search',
    EXAM_SEARCH: 'cms_exam_search',
    BADGE_SEARCH: 'cms_badge_search',
    REPORT_STUDENT_SEARCH: 'cms_report_student_search',
    REPORT_CHECKED_DEPARTMENT: 'cms_report_checked_department',
    SURVEY_SEARCH: 'cms_survey_search',
    SURVEY_SINGLE_SEARCH: 'cms_survey_single_search',
    SURVEY_TEMPLATE_SEARCH: 'cms_survey_template_search',
    REPORT_EXAM_SEARCH: 'cms_report_exam_search',
    NOTIFICATION_TEMPLATE_SEARCH: 'cms_notifi_template_search'
  }

}

export const EXAM_ATTEMPS = [
  {lable: "Không giới hạn", value: 0},
  {lable: 1, value: 1},
  {lable: 2, value: 2},
  {lable: 3, value: 3},
  {lable: 4, value: 4},
  {lable: 5, value: 5},
  {lable: 6, value: 6},
  {lable: 7, value: 7},
  {lable: 8, value: 8},
  {lable: 9, value: 9},
  {lable: 10, value: 10},
]

export const TOPIC_CONST = [
  {lable: "Tiếng anh thông dụng và nâng cao", value: 1},
  {lable: "Kiến thức cơ bản về chính trị, kinh tế - xã hội, lịch sử, địa lý, văn hoá Việt Nam và các nước trên Thế giới", value: 2},
  {lable: "Lịch sử hình thành và phát triển của Đảng Cộng Sản Việt Nam", value: 3},
  {lable: "Điều lệ Đoàn TNCS Hồ Chí Minh, kiến thức về công tác Đoàn và phong trào thanh niên, lịch sử 90 năm thành lập Đoàn TNCS Hồ Chí Minh", value: 4},
  {lable: "Tiếng Anh chuyên ngành: Kiến thức cơ bản về Kinh tế, Quản trị Doanh nghiệp, Hội nhập Quốc tế", value: 5},
]
