import { ToastrCustomService } from './../../../../shared/services/toastr-custom.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ABC, abc, iii, III, T123 } from 'src/app/shared/constants/base.constant';
import CommonUtil from 'src/app/shared/utils/common-util';
import { ReportService } from '../../../../shared/services/report.service';

@Component({
  selector: 'app-result-exam-user',
  templateUrl: './result-exam-user.component.html',
  styleUrls: ['./result-exam-user.component.css']
})
export class ResultExamUserComponent implements OnInit {

  listQuestion = [];
  @Input() quizAttemptid: number;
  @Input() studentName: string;

  constructor(
    public activeModal: NgbActiveModal,
    private reportService: ReportService,
    public domSanitizer2: DomSanitizer,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrCustomService,
  ) {}

  ngOnInit(): void {
    this.getReportExamByUser();
  }

  getReportExamByUser() {
    const params = {
      id: this.quizAttemptid
    };
    this.spinner.show();
    this.reportService.getReportExamByUser(params).subscribe((data) => {
      this.listQuestion = data.body.questions;
      this.listQuestion.forEach(element => {
        if (element.answernumbering === 'abc') {
          element.answernumbertype = abc;
        } else if (element.answernumbering === 'ABCD') {
          element.answernumbertype = ABC;
        } else if (element.answernumbering === '123') {
          element.answernumbertype = T123;
        } else if (element.answernumbering === 'iii') {
          element.answernumbertype = iii;
        } else if (element.answernumbering === 'IIII') {
          element.answernumbertype = III;
        }
        if (element.type === 'truefalse') {
          element.typeCheckBox = 'radio';
        } else if (element.type === 'multichoice') {
          if (element.single === 1 ) {
            element.typeCheckBox = 'radio';
          }
          if (element.single === 0 ) {
            element.typeCheckBox = 'checkbox';
          }
        }
        else if (element.type === 'multichoiceset') {
            element.typeCheckBox = 'checkbox';
        }
        element.condition = 1;
        if (element.questiontext.includes('<figure class=\"media\">')) {
          element.linkVideo = CommonUtil.linkVideoCkeditorToLinkEmbedYoutube(element.questiontext);
        }
        this.spinner.hide();
      });
    }, err => {
      this.spinner.hide();
      this.toastrService.handlerError(err);
    });
  }
}
