import {DEFAULT_COURSE_IMAGE, USER_ROLE} from './../../../shared/constants/base.constant';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalComponent} from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {FileService} from '../../../shared/services/file.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {CourseService} from '../../../shared/services/course.service';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {CourseCategory} from '../../../shared/model/course-category.model';
import {CourseCategoryService} from '../../../shared/services/course-category.service';
import CommonUtil from '../../../shared/utils/common-util';
import * as moment from 'moment';
import {Course} from '../../../shared/model/course.model';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import {LIST_HOURS, LIST_MINUTES, USER_FUNCTIONS} from 'src/app/shared/constants/base.constant';
import {CustomValidators} from '../../../shared/utils/custom-validators';

@Component({
  selector: 'app-course-create',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css']
})
export class CourseDetailComponent implements OnInit {
  role: string;
  courseForm = this.fb.group({
    fullname: ['', [Validators.required, CustomValidators.notBlankValidator, Validators.maxLength(200)]],
    summary: [''],
    requireenglish: [],
    isopen: [],
    categoryid: [null, Validators.required],
    startdate: [, Validators.required],
    enddate: [, Validators.required],
    hourStartDate: ['', Validators.required],
    minuteStartDate: ['', Validators.required],
    hourEndDate: ['', Validators.required],
    minuteEndDate: ['', Validators.required],
  });

  hoursList = LIST_HOURS;
  minutesList = LIST_MINUTES;

  quizOutput: ModuleInfo;
  // Luu thong tin hien tai cua course
  // MLMS 668
  courseDetail: any = {
    id: null,
    summary: '',
    fullname: '',
    startdate: null,
    enddate: null,
    published: null,
    requireenglish: null,
    isopen: null,
    categoryid: null,
    publishedat: null,
    readonly: false
  };
  urlSafe: SafeResourceUrl;
  courseCategoryList: CourseCategory[] = [];
  categoryMap = new Map();
  currentDate: Date = new Date();
  courseId: number;
  avatarCourse: any;
  avatarCourseInvalid: any;
  showCreateQuiz = false;
  formEnable = false;
  checkC = false;
  startTimeInvalid = false;
  endTimeInvalid = false;
  isTeacher = false;
  @ViewChild('imgPreview') imgPreview: ElementRef;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrCustomService,
    private courseService: CourseService,
    private courseCategoryService: CourseCategoryService,
    private route: ActivatedRoute,
    private fileservice: FileService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.role = this.courseService.getRoleUser();
    if (this.role === USER_ROLE.TEACHER) {
      this.isTeacher = true;
    }
    this.courseId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      this.courseCategoryList = response.body;
      CommonUtil.convertCategoryListToMap(this.courseCategoryList, this.categoryMap);
    });
    this.getInfoCourse(this.courseId);
    this.courseForm.disable();
  }

  initFormCourseDetail(course: any) {
    const startDate = moment.unix(course.startdate).toDate();
    const endDate = moment.unix(course.enddate).toDate();
    this.courseForm.patchValue({
      fullname: course.fullname,
      summary: course.summary,
      startdate: startDate,
      hourStartDate: startDate.getHours(),
      minuteStartDate: startDate.getMinutes(),
      enddate: endDate,
      hourEndDate: endDate.getHours(),
      minuteEndDate: endDate.getMinutes(),
      requireenglish: course.requireenglish,
      categoryid: course.categoryid,
      isopen: course.isopen
    });
    if (course.filename) {
      this.courseDetail.img = this.fileservice.getFileUrl(course.contextid, course.filename, '/course/overviewfiles/');
    } else {
      this.courseDetail.img = DEFAULT_COURSE_IMAGE;
    }
    this.imgPreview.nativeElement.src = this.courseDetail.img;

  }

  getInfoCourse(courseId: any) {
    this.courseService.getCoursesInfo(courseId).subscribe(response => {
      this.courseDetail = response.body;
      this.courseId = this.courseDetail.id;
      this.initFormCourseDetail(response.body);
    });
  }

  onPublishCourse() {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('course.publish_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('course.publish_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.show');
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.spinner.show();
        this.courseService.publishCourse(this.courseId).subscribe(
          res => {
            this.courseDetail.published = 1;
            if (this.formEnable) {
              this.cancelUpdateCourse();
            }
            this.spinner.hide();
            this.toastrService.success(`common.noti.publish_success`);
          },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          }
        );
      }
    });
  }

  onUnPublishCourse() {
    const modalDep = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalDep.componentInstance.title = this.translateService.instant('course.unpublish_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('course.unpublish_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.hide');
    modalDep.result.then((result) => {
      if (result === 'confirm') {
        this.spinner.show();
        this.courseService.unPublishCourse(this.courseId).subscribe(
          res => {
            this.spinner.hide();
            this.toastrService.success(`common.noti.un_publish_success`);
            this.courseDetail.published = 0;
          },
          err => {
            this.spinner.hide();
            this.toastrService.handlerError(err);
          }
        );
      }
    });

  }

  handleFileInput(files: FileList) {
    if (files.length > 0) {
      if (files[0].type !== 'image/jpeg'
        && files[0].type !== 'image/jpg'
        && files[0].type !== 'image/png'
        && files[0].type !== 'image/bmp'
        && files[0].type !== 'image/gif') {
        const param = {file_name: files[0].name};
        this.translateService.get(`common.noti.img_invalid`, param).subscribe(message => {
          this.toastrService.error(message);
        });
      } else {
        this.avatarCourse = files[0];
        this.imgPreview.nativeElement.src = URL.createObjectURL(files[0]);
        // this.courseDetail.img = URL.createObjectURL(files[0]);
        this.avatarCourseInvalid = false;
      }
    }
  }

  updateCourse() {

    if (this.courseForm.invalid) {
      CommonUtil.markFormGroupTouched(this.courseForm);
      return;
    }

    const startdate = CommonUtil.convertDateToTime(
      this.courseForm.value.startdate,
      this.courseForm.value.hourStartDate,
      this.courseForm.value.minuteStartDate
    );
    const enddate = CommonUtil.convertDateToTime(
      this.courseForm.value.enddate,
      this.courseForm.value.hourEndDate,
      this.courseForm.value.minuteEndDate
    );

    if (!this.courseDetail.publishedat && (startdate < this.currentDate.getTime() / 1000)) {
      this.startTimeInvalid = true;
      return;
    } else {
      this.startTimeInvalid = false;
    }

    if  (enddate < startdate) {
      this.endTimeInvalid = true;
      return;
    } else {
      this.endTimeInvalid = false;
    }

    this.spinner.show();

    const updateCourse: Course = {
      id: this.courseDetail.id,
      summary: this.courseForm.value.summary ? this.courseForm.value.summary.trim() : '',
      fullname: this.courseForm.value.fullname.trim(),
      startdate: startdate,
      enddate: enddate,
      requireenglish: + this.courseForm.value.requireenglish,
      isopen: + this.courseForm.value.isopen,
      categoryid: this.courseForm.value.categoryid,
      publishedat: this.courseDetail.publishedat
    };


    this.courseService.updateCourse(updateCourse, this.avatarCourse).subscribe(
      res => {
        this.spinner.hide();
        this.toastrService.success('common.noti.update_success');
        this.getInfoCourse(this.courseId);
        this.courseForm.disable();
        this.formEnable = false;
      },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      }
    );
  }

  check(event) {
    this.checkC = event;
  }

  onUpdateCourse() {
    if (this.courseDetail.published === 1) {
      const modalDep = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalDep.componentInstance.title = this.translateService.instant('common.caution');
      modalDep.componentInstance.body = this.translateService.instant('common.noti.required_un_publish_course');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.hide');
      modalDep.result.then((result) => {
        if (result === 'confirm') {
          this.courseService.unPublishCourse(this.courseId).subscribe(
            res => {
              this.toastrService.success(`common.noti.un_publish_success`);
              this.courseDetail.published = 0;
              this.courseForm.enable();
              this.formEnable = true;
            },
            err => {
              this.toastrService.handlerError(err);
            }
          );
        }
      });
    } else {
      this.courseForm.enable();
      this.formEnable = true;
      if (this.courseForm.invalid) {
        CommonUtil.markFormGroupTouched(this.courseForm);
        return;
      }
    }
  }

  cancelUpdateCourse() {
    this.courseForm.disable();
    this.formEnable = false;
    this.initFormCourseDetail(this.courseDetail);
    this.avatarCourse = null;
    this.avatarCourseInvalid = false;
    this.startTimeInvalid = false;
    this.endTimeInvalid = false;
  }

  handleShowCreateQuiz(event) {
    this.showCreateQuiz = true;
  }

  showQuiz(value) {
    this.quizOutput = value;
  }

  handleShowListQuiz(event) {
    this.showCreateQuiz = false;
  }

  back() {
    window.history.back();
  }

  checkRoleFunction(userFunction: any, role: any): boolean {
    return USER_FUNCTIONS[userFunction].includes(role);
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date).toDate();
    }
    return null;
  }

}
