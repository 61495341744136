<form role="form" [formGroup]="badgeEditForm">
  <div class="container-fluid">
    <div class="card card-default">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Tên chứng chỉ <span class="text-danger">*</span></label>
              <div>
                <input type="text" class="form-control" required="" data-placement="bottom" placeholder="Tên"
                  name="name" formControlName="name" />
              </div>
              <div *ngIf="badgeEditForm.get('name')!.invalid &&(badgeEditForm.get('name')!.dirty || badgeEditForm.get('name')!.touched)">
                <small class="text-danger" *ngIf="badgeEditForm.get('name')?.errors?.required">
                  {{ 'error.validate.input_required' | translate }}
                </small>
                <small class="text-danger" *ngIf="badgeEditForm.get('name')?.errors && !badgeEditForm.get('name')?.errors?.required">
                  {{ 'error.validate.course.nameMaxlength' | translate }}
                </small>
                <small class="text-danger" *ngIf="badgeEditForm.get('name')?.errors?.isBlank">
                  {{ 'error.validate.input_required' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>Miêu tả</label>
              <textarea type="text" class="form-control" placeholder="Miêu tả ngắn"
                formControlName="description"></textarea>
                <div *ngIf="badgeEditForm.get('description')!.invalid &&(badgeEditForm.get('description')!.dirty || badgeEditForm.get('description')!.touched)">
                  <small class="text-danger" *ngIf="badgeEditForm.get('description')?.errors && !badgeEditForm.get('description')?.errors?.required">
                    {{ 'error.validate.course.nameMaxlength' | translate }}
                  </small>
                </div>
            </div>
          </div>
          <div class="col-6">
            <label>Thời gian chứng chỉ <span class="text-danger">*</span></label>
            <div class="form-group">
              <div class="row">
                <mat-radio-group formControlName="radio" [(ngModel)]="chosenItem">
                  <mat-radio-button class="example-radio-button mr-2" *ngFor="let item of radio" [value]="item.value" (click)="setradio(item.value)">{{item.name}}</mat-radio-button>
                  <!-- <mat-radio-button class="example-radio-button" value="expiredate" (click)="setradio('expiredate')">Chọn ngày hết hạn</mat-radio-button>
                  <mat-radio-button class="example-radio-button" value="expireperiod" (click)="setradio('expireperiod')">Hạn sử dụng</mat-radio-button> -->
                  <div *ngIf="isSelected('nolimit')">
                  </div>

                  <div *ngIf="isSelected('expiredate')" >
                    <mat-form-field color="blue" class="mr-3 w-100 mb-0 course-input">
                      <mat-label>Ngày hết hạn</mat-label>
                      <input matInput [matDatepicker]="startdateModel" [min]="currentDate" formControlName="expiredate">
                      <mat-datepicker-toggle matSuffix [for]="startdateModel"></mat-datepicker-toggle>
                      <mat-datepicker #startdateModel></mat-datepicker>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>

                  <div *ngIf="isSelected('expireperiod')">
                    <div class="col-12 row">
                      <input type="number" class="form-control fix-width col-md-3" [min]="1" formControlName="expireperiod" size="4">
                      <div class="col-md-9">
                        <i>ngày kể từ ngày nhận chứng chỉ</i>
                      </div>
                    </div>
                    <div *ngIf="badgeEditForm.get('expireperiod')?.invalid &&(badgeEditForm.get('expireperiod')?.dirty || badgeEditForm.get('expireperiod')?.touched)">
                      <small class="text-danger" *ngIf="badgeEditForm.get('expireperiod')?.errors?.required">
                        {{ 'error.validate.input_required' | translate }}
                      </small>
                      <small class="text-danger" *ngIf="badgeEditForm.get('expireperiod')?.errors?.invalidNumber">
                        {{ 'error.validate.number' | translate }}
                      </small>
                    </div>
                  </div>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <!-- <div class="col-6">
            <div class="form-group">
              <label>Chọn mẫu <span class="text-danger">*</span></label>
              <div class="input-group">
                <input type="file" class="custom-file-input form-control" *ngIf="formEnable" (change)="handleFileInput($event.target.files)">
								<label class="custom-file-label" *ngIf="formEnable">JPEG, PNG...</label>
              </div>
              <small class="text-danger" *ngIf="badeFileErrorRequired && !badeFileInvalid">
                {{ 'error.validate.input_required' | translate }}
              </small>
              <small class="text-danger" *ngIf="badeFileInvalid">
                {{ 'error.validate.input_valid' | translate }}
              </small>
              <img [src]="badge?.image" #imgPreview class="rounded img-thumbnail" width="304" height="236">
            </div>
          </div> -->
        </div>
        <div class="card-footer text-center" *ngIf="badge && !badge.readonly">
          <button type="submit" class="btn btn-primary" *ngIf="!formEnable" (click)="onUpdateBadge()">
            Cập nhật
          </button>
          <button type="submit" class="btn btn-success" *ngIf="formEnable" (click)="updateCourse()">
            Lưu lại
          </button>
          <button type="submit" class="btn btn-default" *ngIf="formEnable" (click)="cancelUpdateBadge()">
            Hủy
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
