import {NgxSpinnerService} from 'ngx-spinner';
import {Component, OnInit} from '@angular/core';
import {PositionService} from '../../shared/services/position.service';
import {FileService} from '../../shared/services/file.service';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {DomSanitizer} from '@angular/platform-browser';
import {DEFAULT_COURSE_IMAGE} from '../../shared/constants/base.constant';

@Component({
  selector: 'app-course-route',
  templateUrl: './course-route.component.html',
  styleUrls: ['./course-route.component.css']
})
export class CourseRouteComponent implements OnInit {

  listPosition = [];
  totalOfCourse = 0;
  totalOfExam = 0;

  constructor(
    private positionService: PositionService,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    private toastrCustomService: ToastrCustomService,
    private sanitizer: DomSanitizer,
  ) {}


  ngOnInit(): void {
    this.getListPosition();
  }

  getListPosition(){
    this.spinner.show();
    return this.positionService.getListRoadMap().subscribe((data) => {
      this.listPosition = data.body;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastrCustomService.handlerError(err);
    });
  }

  convertLinkimg(filename, filearea, contextId){
    if (filename && filearea && contextId) {
      const urlFile = this.fileService.getFileUrl(contextId, filename, '/course/overviewfiles/');
      return this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
    }
    return DEFAULT_COURSE_IMAGE;
  }
}
