import { LOCAL_STORAGE_KEY } from './../../shared/constants/base.constant';
import {BadgeService} from '../../shared/services/badge.service';
import { Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BadgeCreateComponent} from './badge-create/badge-create.component';
import {TranslateService} from '@ngx-translate/core';
import {ToastrCustomService} from 'src/app/shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {PageEvent} from '@angular/material/paginator';
import * as moment from 'moment';
import CommonUtil from 'src/app/shared/utils/common-util';
import {Sort} from '@angular/material/sort';
import {DeleteBadgePopupComponent} from './delete-badge-popup/delete-badge-popup.component';
import {STATUS_BADGES} from 'src/app/shared/constants/base.constant';
import {LocalStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.css']
})
export class BadgeComponent implements OnInit, OnDestroy {

  badges: any[] = [];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalRecord: number;
  pageEvent: PageEvent;
  completedAll = false;
  keepBadgeForU = 0;
  badgeIdsChecked: number[] = [];
  badgeIds: number[] = [];
  badgeIdsCanChecked: number = 0;

  searchBadge = {
    keyword: '',
    categoryid: null,
    pageIndex: 1,
    pageSize: 10,
    sortColumn: 'timecreated',
    sortType: 'desc',
  };

  constructor(
    private modalService: NgbModal,
    private badgeService: BadgeService,
    private translateService: TranslateService,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private $localStorage: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {

    const currentSearch = this.$localStorage.retrieve(LOCAL_STORAGE_KEY.SEARCH.BADGE_SEARCH);
    if (currentSearch) {
      // Kiem tra xem truoc do co luu du lieu tim kiem khong
      this.searchBadge.keyword = currentSearch.keyword;
      this.searchBadge.pageIndex = currentSearch.pageIndex;
      this.searchBadge.pageSize = currentSearch.pageSize;
      this.searchBadge.sortColumn = currentSearch.sortColumn;
      this.searchBadge.sortType = currentSearch.sortType;
      // Xoa di sau khi su dung
      this.$localStorage.clear(LOCAL_STORAGE_KEY.SEARCH.BADGE_SEARCH);
    }

    this.getListBadge();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  openBadgeCreatePopup() {
    const modalDep = this.modalService.open(BadgeCreateComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
  }

  getListBadge() {
    const params = {
      keyword: this.searchBadge.keyword,
      limit: this.searchBadge.pageSize,
      page: this.searchBadge.pageIndex,
      sortcolumn: this.searchBadge.sortColumn,
      sorttype: this.searchBadge.sortType
    };
    this.spinner.show();
    this.badgeIdsCanChecked = 0;
    this.badgeIds = [];
    return this.badgeService.listBadge(params).subscribe(data => {
      data.body.results.forEach(b => {
        if (this.badgeIdsChecked?.includes(b.id)) {
          b.completed = true;
          this.badgeIds?.push(b.id);
        }
        if (!this.checkDisable(b)) {
          this.badgeIdsCanChecked++;
        }
      });
      if (this.badgeIds?.length > 0 && (this.badgeIds?.length === this.badgeIdsCanChecked)) {
        this.completedAll = true;
      } else {
        this.completedAll = false;
      }
      this.badges = data.body.results;
      // this.badges.forEach(c => {
      //   c.completed = false;
      // });
      this.totalRecord = data.body.total;
      this.spinner.hide();
    },
    err => {
      this.spinner.hide();
      this.toastrService.handlerError(err);
    });
  }

  deleteBadge(badgeid: number[]) {
    const modalDep = this.modalService.open(DeleteBadgePopupComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    modalDep.componentInstance.title = this.translateService.instant('badge.delete_confirm_title');
    modalDep.componentInstance.body = this.translateService.instant('badge.delete_confirm_content');
    modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
    modalDep.componentInstance.onChecked.subscribe(($e) => {
      if ($e === true) {
        this.keepBadgeForU = 1;
      } else {
        this.keepBadgeForU = 0;
      }
    });

    modalDep.result.then((result) => {
      this.spinner.show();
      this.badgeService.deleteBadge(String(badgeid), this.keepBadgeForU).subscribe(
        res => {
          this.toastrService.success(`common.noti.delete_success`);
          this.getListBadge();
          [...badgeid]?.forEach(courseId => {
            this.badgeIds.splice(this.badgeIds.indexOf(courseId), 1);
            this.badgeIdsChecked.splice(this.badgeIdsChecked.indexOf(courseId), 1);
          });
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          this.toastrService.handlerError(err);
        }
      );
    });

  }

  onDeleteMultipleBadge() {
    this.deleteBadge(this.badgeIdsChecked);
  }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  convertDays(sec: any) {
    if (sec !== null) {
      return Math.floor(sec / 86400);
    }
    return '';
  }

  limitString(stringRaw) {
    return CommonUtil.limitWord(stringRaw, 30);
  }

  onKeyUp() {
    this.searchBadge.pageIndex = 1;
    this.badgeIdsChecked = [];
    this.getListBadge();
  }

  sortData(sort: Sort) {
    this.searchBadge.pageIndex = 1;
    this.searchBadge.sortColumn = sort.active;
    this.searchBadge.sortType = sort.direction;
    this.getListBadge();
  }

  oncheckboxAll(checked: any) {
    if (checked) {
      this.completedAll = true;
      this.badges.forEach(c => {
        if (this.checkDisable(c)) {
          c.completed = !checked;
        } else {
          c.completed = checked;
          if (!this.badgeIds.includes(c.id)) {
            this.badgeIds.push(c.id);
          }
          if (!this.badgeIdsChecked?.includes(c.id)) {
            this.badgeIdsChecked?.push(c.id);
          }
        }
      });
    } else {
      this.badgeIds?.forEach(id => {
        this.badgeIdsChecked?.splice(this.badgeIdsChecked?.indexOf(id), 1);
      });
      this.badgeIds = [];
      this.badges?.forEach(c => {
        c.completed = false;
      });
      this.completedAll = false;
    }
  }

  oncheckboxItem(badgeId: number, checked: any) {
    if (checked) {
      this.badges?.forEach(c => {
        if (c.id === badgeId) {
          c.completed = true;
          this.badgeIds?.push(badgeId);
          this.badgeIdsChecked?.push(badgeId);
          return;
        }
      });
      // nếu số khóa checked bằng số khóa học có thể checked trong page thì set checkboxall = true
      if (this.badgeIds?.length > 0 && this.badgeIds?.length === this.badgeIdsCanChecked && !this.completedAll) {
        this.completedAll = true;
      }
    } else {
      this.completedAll = false;
      this.badges?.forEach(c => {
        if (c.id === badgeId) {
          c.completed = false;
          this.badgeIds?.splice(this.badgeIds?.indexOf(badgeId), 1);
          this.badgeIdsChecked?.splice(this.badgeIdsChecked?.indexOf(badgeId), 1);
          return;
        }
      });
    }
  }

  getStatusBadge(status: number) {
    const statusBadge = STATUS_BADGES.find(s => s.statusId === status);
    return statusBadge;
  }

  getColorStatusBadge(status: number) {
    const statusBadge = STATUS_BADGES.find(s => s.statusId === status);
    return statusBadge.name;
  }

  changePage(event) {
    this.searchBadge.pageIndex = event.pageIndex + 1;
    this.searchBadge.pageSize = event.pageSize;
    this.getListBadge();
  }

  goToBadgeDetail(badgeid) {
    this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.BADGE_SEARCH, this.searchBadge);
    this.router.navigate(['/admin/badge/detail', badgeid]);
  }

  checkDisable(c) {
    return c.status === 1 || c.status === 3;
  }

  setSelectedItems(id?: number, checked?: boolean) {
    this.badges.forEach(item => {
      if (item.id === id) {
        item.completed = checked;
        return;
      }
    });
  }
}
