<div class="container-fluid">
  <div class="col-12">
    <div class="card card-default">
      <div class="card-header">
        <!-- <h3 class="card-title">Danh sách thông báo</h3> -->
        <div class="row">
          <div class="col-6">
            <div class="form-inline">
              <button type="button" data-toggle="modal" class="btn btn-primary btn-flat m-2">
                <i class="fas fa-plus"></i>
                <a class="text-white" [routerLink]="['/admin/notification/details']" [routerLinkActive]="['active']">
                  Tạo thông báo đột xuất
                </a>
              </button>
              <button type="button" [disabled]="notifyIdsChecked?.length === 0" class="btn btn-default btn-flat mr-1"
                      matTooltip="Xóa"
                      (click)="onDeleteMultipleSurvey()" matTooltipClass="tooltip-red"><i
                class="fas fa-trash font-size-18"></i>
              </button>
              <span>Số thông báo đã chọn: {{notifyIdsChecked?.length}}</span>
            </div>
          </div>
          <div class="col-6">
            <form class="form-inline float-right" [formGroup]="searchForm">
              <div class="form-group m-2">
                <label class="mr-2">Tìm kiếm</label>
                <input
                  formControlName="keySearch"
                  type="search"
                  class="form-control mr-2"
                  placeholder="Từ khóa..."
                  (change)="searchNotify()"/>
              </div>
            </form>
          </div>
        </div>

      </div>
      <div class="card-body" style="display: block">
        <div class="col-12 table-responsive">
          <table class="table table-hover" matSort (matSortChange)="sortData($event)">
            <thead>
            <tr>
              <th>
                <mat-icon class="mt-1">
                  <mat-checkbox class="example-margin" [disabled]="listNotify===undefined||listNotify?.length===0" [(ngModel)]="completedAll"
                                (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                </mat-icon>
              </th>
              <th>STT</th>
              <th style="width:20%" mat-sort-header="title">Tiêu đề thông báo</th>
              <th style="width:40%">Người nhận</th>
              <th style="width:15%">Đơn vị chủ trì</th>
              <th style="width:5%">Trạng thái</th>
              <th style="width:10%" mat-sort-header="createdat">Ngày tạo</th>
              <th style="width:10%">Thao tác</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let notify of listNotify; let i = index;">

              <td>
                <mat-icon class="mt-1">
                  <mat-checkbox [(ngModel)]="notify.selected" (change)="oncheckboxItem(notify.id, $event.checked)"
                                class="example-margin"></mat-checkbox>
                </mat-icon>
              </td>

              <td>{{(pageIndex - 1) * pageSize + i + 1}}</td>
              <td>{{notify.title}}</td>
              <td><p [matTooltip] ="notify.recipient" [innerHTML]="notify.recipient?.length <= 90 ? notify.recipient: (notify.recipient | slice:0:90) + '...' "></p></td>
              <td>{{notify.departmentname}}</td>
              <td><span class="badge badge-success">Đã Gửi</span></td>
              <td>{{notify.createdat | date: 'dd/MM/yyyy'}}</td>
              <td>
                <button type="button" matTooltip="Xóa" matTooltipClass="tooltip-red"
                        (click)="_onDeleteSurveys([notify.id])"
                        class="btn btn-default btn-sm mr-1"><i class="fas fa-trash"></i></button>

                <button type="button" matTooltip="Xem chi tiết" matTooltipClass="tooltip-red"
                        (click)="openDetailNotify(notify)"
                        class="btn btn-default btn-sm mr-1"><i class="fas fa-eye"></i>
                </button>

              </td>
            </tr>
            </tbody>
          </table>
          <br>
          <p *ngIf="listNotify?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
        </div>


        <div class="card-footer text-center" *ngIf="totalRecord > 0">
          <div class="row">
            <mat-paginator showFirstLastButtons
                           [length]="totalRecord"
                           [pageSize]="pageSize"
                           [pageIndex]="pageIndex - 1"
                           (page)="pageEvent = $event;getListNotifySingle( $event.pageSize, ($event.pageIndex+1), sortcolumn, sorttype)"
                           [hidePageSize]="false"
                           [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>
          </div>
        </div>


        <div class="text-center"></div>
      </div>
    </div>
  </div>
</div>
