import { ToastrCustomService } from './../../../shared/services/toastr-custom.service';
import { PageEvent } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';
import { BadgeService } from './../../../shared/services/badge.service';
import { Component, OnInit, Input} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-badge-student',
  templateUrl: './badge-student.component.html',
  styleUrls: ['./badge-student.component.css']
})
export class BadgeStudentComponent implements OnInit {

  @Input() readonly: boolean;
  @Input() badgeId: number;

  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageEvent: PageEvent;
  studentonBadge: any[];
  totalRecord: number;

  searchBadgeStudent = {
    badgeid: null,
    search : '',
    page: 1,
    limit: 10,
    sortcolumn: 'dateissued',
    sorttype: 'desc'
  }

  constructor(
    private badgeService: BadgeService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrCustomService,
  ) {}

  ngOnInit(): void {
    this.searchBadgeStudent.badgeid = this.badgeId;
    this.getListStudentOnBadge();
  }

  getListStudentOnBadge() {
    this.spinner.show();
    this.badgeService.getListStudentOnBadge(this.searchBadgeStudent).subscribe(
      bad => {
        this.studentonBadge = bad.body.results;
        this.totalRecord = bad.body.total;
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      });
  }

  onChangeSearch(event) {
    this.searchBadgeStudent.search = event.target.value;
    this.searchBadgeStudent.page = 1;
    this.getListStudentOnBadge();
  }

  changePage(event) {
    this.searchBadgeStudent.page = event.pageIndex + 1;
    this.searchBadgeStudent.limit = event.pageSize;
    this.getListStudentOnBadge();
  }

  // revokeBadgeOfStudent(userId: number) {
  //   const modalDep = this.modalService.open(ConfirmModalComponent, {
  //     size: 'lg',
  //     centered: true,
  //     backdrop: 'static'
  //   });

  //   modalDep.componentInstance.title = this.translateService.instant('badge.revoke_confirm_title');
  //   modalDep.componentInstance.body = this.translateService.instant('badge.revoke_confirm_content');
  //   modalDep.componentInstance.confirmButton = this.translateService.instant('common.confirm');

  //   modalDep.result.then((result) => {
  //     if (result === 'confirm') {
  //       const params = {
  //         badgeid: this.badgeId,
  //         studentid: userId
  //       }
  //       this.badgeService.revokeBadgeOfStudent(params).subscribe(
  //         res => {
  //           this.spinner.hide();
  //           this.toastrService.success(`badge.revoke_success`);
  //           this.getListStudentOnBadge();
  //         },
  //         err => {
  //           this.spinner.hide();
  //           this.toastrService.handlerError(err);
  //         }
  //       )
  //     }
  //   });
  // }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

}
