<div class="modal-header">
  <h5 class="modal-title" *ngIf="!isCreateExam">Tạo mới khóa học</h5>
  <h5 class="modal-title" *ngIf="isCreateExam">Tạo mới kỳ thi</h5>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
  (click)="activeModal.dismiss()"><i class="fas fa-times"></i>
</button>
</div>
<div class="modal-body">
  <div class="row">
    <form role="form" [formGroup]="courseForm" >
      <div class="row">
        <div class="col-12">
          <div class="form-group fullname-require">
            <label class="control-label">
              <span *ngIf="!isCreateExam">Tên khóa học</span>
              <span *ngIf="isCreateExam">Tên kỳ thi</span>
            </label><span class="text-danger"> *</span>
            <input type="text" class="form-control" placeholder="Tên đầy đủ" required="" data-placement="bottom" formControlName="fullname">
            <div *ngIf="courseForm.get('fullname')!.invalid &&(courseForm.get('fullname')!.dirty || courseForm.get('fullname')!.touched)">
              <small class="text-danger" *ngIf="courseForm.get('fullname')?.errors?.required">
                {{ 'error.validate.input_required' | translate }}
              </small>
              <small class="text-danger" *ngIf="courseForm.get('fullname')?.errors?.isBlank">
                {{ 'error.validate.input_required' | translate }}
              </small>
              <small class="text-danger" *ngIf="courseForm.get('fullname')?.errors?.maxlength">
                {{ 'error.validate.course.nameMaxlength' | translate }}
              </small>

            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label>Danh mục chủ đề
              <span *ngIf="!isCreateExam">khóa học</span>
              <span *ngIf="isCreateExam">kỳ thi</span>
            </label><span class="text-danger"> *</span>
            <select class="form-control select2" style="width: 100%;" formControlName="categoryid">
              <option [value]="null" disabled selected >Chọn danh mục</option>
              <option *ngFor="let item of categoryMap | keyvalue" [value]="item.key">{{item.value}}</option>
            </select>
            <div *ngIf="courseForm.get('categoryid')!.invalid &&(courseForm.get('categoryid')!.dirty || courseForm.get('categoryid')!.touched)">
              <small class="text-danger" *ngIf="courseForm.get('categoryid')?.errors?.required">
                {{ 'error.validate.input_required' | translate }}
              </small>
            </div>
            </div>
        </div>
        <div class="col-6 pt-4">
          <mat-checkbox formControlName="requireenglish" *ngIf="!isCreateExam" class="example-margin col-6">Yêu cầu tiếng anh</mat-checkbox>
          <mat-checkbox formControlName="isopen" *ngIf="!isCreateExam" class="example-margin col-6">Tự ghi danh</mat-checkbox>
          <mat-checkbox formControlName="iscreatebadge" class="example-margin col-6">Tạo chứng chỉ cho khóa học/ kỳ thi</mat-checkbox>
        </div>
        <div class="col-6">
          <label for="" class="mb-0">Thời gian bắt đầu</label><span class="text-danger"> *</span>
          <div class="form-row">
            <div class="col-auto form-inline">
                <select class="form-control" formControlName="hourStartDate">
                  <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
                </select>
                <select class="form-control ml-1" formControlName="minuteStartDate">
                  <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
                </select>
          </div>
            <div class="col-auto" >
                  <mat-form-field  class="mr-3 mb-0 course-input">
                        <mat-label>Ngày bắt đầu</mat-label>
                        <input matInput [matDatepicker]="startdateModel" [min]="currentDate" formControlName="startdate">
                        <mat-datepicker-toggle matSuffix [for]="startdateModel"></mat-datepicker-toggle>
                        <mat-datepicker #startdateModel></mat-datepicker>
                    </mat-form-field>
                    </div>
          </div>
        </div>
          <div class="col-6">
            <label class="mb-0">Thời gian kết thúc</label><span class="text-danger">*</span>
           <div class="form-row">
             <div class="col-auto form-inline">
                <select class="form-control" formControlName="hourEndDate">
                  <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
                </select>
                <select class="form-control ml-1" formControlName="minuteEndDate">
                  <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
                </select>
              </div>
              <div class="col-auto">
                  <mat-form-field color="blue" class="course-input mb-0">
                    <mat-label>Ngày kết thúc</mat-label>
                    <input matInput [matDatepicker]="enddateModel" [min]="courseForm.value.startdate" formControlName="enddate">
                    <mat-datepicker-toggle matSuffix [for]="enddateModel"></mat-datepicker-toggle>
                    <mat-datepicker #enddateModel color="primary"></mat-datepicker>
                  </mat-form-field>

            </div>
            <small class="text-danger" *ngIf="startTimeInvalid">
              {{ 'error.validate.start_date_invalid' | translate }}
            </small>
            <small class="text-danger" *ngIf="endTimeInvalid">
              {{ 'error.validate.end_date_invalid' | translate }}
            </small>
            </div>
          </div>
        <div class="col-12" *ngIf="!isCreateExam">
          <div>
            <label>Miêu tả khóa học</label>
          </div>
          <div>
            <textarea rows="4" style="width: 100%;" formControlName="summary">
            </textarea>
            <div *ngIf="courseForm.get('summary')!.invalid && (courseForm.get('summary')!.dirty || courseForm.get('summary')!.touched)">
              <small class="text-danger" *ngIf="courseForm.get('summary')?.errors?.maxlength">
                {{ 'error.validate.course.nameCourseMaxlength' | translate }}
              </small>
            </div>
          </div>
        </div>
        <div class="col-6" *ngIf="!isCreateExam" >
            <div class="form-group">
                <label>Ảnh đại diện khóa học</label><span class="text-danger"> *</span>
                <div class="input-group">
                      <input type="file" class="form-control-file" (change)="handleFileInput($event.target.files)" >
                        <small class="text-danger" *ngIf="avatarCourseErrorRequired && !avatarCourseInvalid">
                          {{ 'error.validate.input_required' | translate }}
                        </small>
                        <small class="text-danger" *ngIf="avatarCourseInvalid">
                          {{ 'error.validate.input_valid' | translate }}
                        </small>
                    </div>
                </div>
            </div>
      </div>
      <div class="card-footer text-center">
        <button type="button" (click)="activeModal.dismiss('close')" class="btn ml-1 btn-secondary rounded-0 mr-2" data-dismiss="modal">Đóng</button>
        <button type="button" class="btn btn-primary" [disabled]="courseForm.invalid" (click)="createNewCourse()" >Lưu lại</button>
      </div>
    </form>
  </div>
</div>


