import {NgxSpinnerService} from 'ngx-spinner';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {ModuleInfo} from '../../../shared/model/moduleinfo.model';
import {IntroeditorModel} from '../../../shared/model/introeditor.model';
import {PageModel} from '../../../shared/model/page.model';
import {ModuleModel} from '../../../shared/model/module.model';
import CommonUtil from '../../../shared/utils/common-util';
import {TopicService} from '../../../shared/services/topic.service';
import {CKEDITOR_CONFIG} from '../../../shared/constants/ckeditor.constant';


@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.css']
})


export class PageContentComponent implements OnInit, OnChanges {
  public Editor = ClassicEditor;

  editorConfig = CKEDITOR_CONFIG.DEFAULT;


  @Input() module: ModuleModel;
  @Input() courseId: number;
  @Input() section: number;
  @Input() courseReadOnly: boolean;
  @Input() courseFinished: boolean;

  @Output() hasChange = new EventEmitter<boolean>();
  @Output() reLoadTopics = new EventEmitter<number>();

  editorDataDescription = '';
  editorDataContent = '';
  courseModule: ModuleInfo = {
    name: '',
    course: this.courseId,
    section: this.section,
    introeditor: null,
    modulename: 'page'
  };

  page: PageModel;
  introeditor: IntroeditorModel;

  validateDes = true;
  validateCont = true;
  isNew = false;
  isCreateOrEdit = false;
  teachers = '';

  moduleForm = this.fb.group({
    name: ['', Validators.required],
    timelimit: ['', Validators.required]
  });


  constructor(
    private topicService: TopicService,
    private toastrService: ToastrCustomService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.module) {
      // Cap nhat du lieu

      this.spinner.show();
      this.isNew = false;
      this.isCreateOrEdit = false;
      this.topicService.getModuleDetail(this.module.id).subscribe(res => {
        this.courseModule = res.body;
        this.teachers = res.body.teachers.length > 0 ? CommonUtil.convertTeacherName(res.body.teachers) : 'Chưa có giảng viên được gán';
        this.moduleForm.patchValue({
          name: this.courseModule.name,
          timelimit: this.courseModule.timelimit / 60
        });
        this.editorDataDescription = this.courseModule.intro;
        this.editorDataContent = this.courseModule.content;
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      });
    } else {
      // Tao bai giang moi
      this.courseModule.id = null;
      this.moduleForm.patchValue({
        name: '',
        timelimit: null
      });
      this.editorDataDescription = '';
      this.editorDataContent = '';
      this.isNew = true;
      this.isCreateOrEdit = true;
      this.moduleForm.reset();
    }
  }

  submitForm() {
    // validate de hien thi message loi
    if (this.moduleForm.invalid || this.editorDataDescription === '' || this.editorDataContent === '') {
      if (this.moduleForm.invalid){
        CommonUtil.markFormGroupTouched(this.moduleForm);
      }

      if (this.editorDataDescription === '') {
        this.validateDes = false;
      } else {
        this.validateDes = true;
      }

      if (this.editorDataContent === '') {
        this.validateCont = false;
      } else {
        this.validateCont = true;
      }

      return;
    }


    this.spinner.show();
    this.introeditor = {text: '', format: 1, itemid: 0};
    this.page = {text: '', format: 1, itemid: 0};

    const createOrUpdate: ModuleInfo = {
      name: '',
      course: this.courseId,
      section: this.section,
      introeditor: null,
      modulename: 'page'
    };

    createOrUpdate.name = this.moduleForm.value.name;
    createOrUpdate.timelimit = this.moduleForm.value.timelimit * 60;
    this.page.text = this.editorDataContent;
    this.introeditor.text = this.editorDataDescription;
    createOrUpdate.page = this.page;
    createOrUpdate.introeditor = this.introeditor;

    if (!this.courseModule.id) {
      // Tao bai giang moi
      this.topicService.createPageContent(createOrUpdate).subscribe(res => {
        this.courseModule.id = res.body.coursemodule;
        this.courseModule.name = this.moduleForm.value.name;
        this.courseModule.timelimit = this.moduleForm.value.timelimit * 60;
        this.courseModule.intro = this.editorDataDescription;
        this.courseModule.content = this.editorDataContent;
        this.spinner.hide();
        this.toastrService.success(`common.noti.create_success`);
        this.isCreateOrEdit = false;
        this.isNew = false;
        this.hasChange.emit(false);
        this.reLoadTopics.emit(res.body?.coursemodule);
        this.moduleForm.reset();
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
    } else {
      // Cap nhat bai giang cu
      createOrUpdate.coursemodule = this.courseModule.id;
      this.topicService.updatePageContent(createOrUpdate).subscribe(res => {
        this.courseModule.name = this.moduleForm.value.name;
        this.courseModule.timelimit = this.moduleForm.value.timelimit * 60;
        this.courseModule.intro = this.editorDataDescription;
        this.courseModule.content = this.editorDataContent;
        this.spinner.hide();
        this.toastrService.success(`common.noti.update_success`);
        this.isCreateOrEdit = false;
        this.isNew = false;
        this.hasChange.emit(false);
        this.reLoadTopics.emit(res.body?.coursemodule);
        this.moduleForm.reset();
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
    }
  }


  cancel() {
    // Chi co tac dung khi cap nhat
    this.isCreateOrEdit = false;
  }

  public onChangeIntro({editor}: ChangeEvent) {
    if (!editor) {
      return;
    }
    const data = editor.getData();
    this.validateDes = true;
    this.editorDataDescription = data;
    this.hasChange.emit(true);
  }

  public onChangeContent({editor}: ChangeEvent) {
    if (!editor) {
      return;
    }
    const data = editor.getData();
    this.validateCont = true;
    this.editorDataContent = data;
    this.hasChange.emit(true);
  }

  numericOnly(event): boolean {
    const patt = /^([0-9])$/;
    return patt.test(event.key);
  }

  showEdit() {
    this.spinner.show();

    this.topicService.prepareDataForUpdate(this.courseModule.id).subscribe(
      res => {
        this.editorDataDescription = res.body.intro;
        this.editorDataContent = res.body.content;
        this.spinner.hide();
        this.isCreateOrEdit = true;
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      }
    );
  }

  onDataChange() {
    this.hasChange.emit(true);
  }

}
