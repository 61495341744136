<div class="row">
  <div class="col-lg-9 col-md-6 box-object">
    <div class="scrollbar mt-3" *ngIf="survey.listquestion && survey.listquestion.length > 0 && !isSendSurvey" >
      <!-- them khao sat-->
      <div class="container">
        <div class="col-12 mt-3 mb-5">
          <h5 class="mt-3">{{survey.name}}</h5>
          <ng-container  *ngIf="isHasQuestionCourse">
            <h6 class="text-success"><i class="fas fa-journal-whills mr-2"></i>KHẢO
              SÁT KHÓA HỌC</h6>
            <div *ngFor="let question of survey.listquestion">
              <ng-container *ngIf="!question.isteacher || question.isteacher === '0'">
                <div class="card card-default  border rounded" *ngIf="question.type === textQuestionType">
                  <div class="card-body p-3">
                    <div class="row form-inline">
                      <span class="ml-2 mt-2" [innerHTML]=question.content></span>
                    </div>
                  </div>
                  <div class="form-group p-3 mb-0">
                    <input type="text" [(ngModel)]="question.answer" name="{{question.id}}"
                           class="form-control border-left-0 border-top-0 border-right-0 "
                           placeholder="Nhập trả lời..."/>
                  </div>
                </div>
                <div class="card card-default  border rounded" *ngIf="question.type === radioQuestionType">
                  <div class="card-body p-3">
                    <div class="row form-inline">
                      <span class="ml-2 mt-2" [innerHTML]=question.content></span>
                    </div>
                  </div>
                  <div class="pl-3 pr-3" >
                    <mat-radio-group name="{{question.id}}" [(ngModel)]="question.answer">
                      <mat-radio-button  class="w-10 mr-4" *ngFor="let choice of question.choices" value="{{choice.id}}" >{{choice.content}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <div class="card card-default  border rounded" *ngIf="question.type === rateQuestionType">
                  <div class="card-body">
                    <div class="row form-inline">
                      <span class="ml-2 mt-2" [innerHTML]=question.content></span>
                    </div>
                  </div>
                  <table style="width: 100%">
                    <tr>
                      <td style="text-align: center; word-break: break-word;" class="rate"
                          [style.width.%]="100 / question.length"
                        *ngFor="let i of arrayStartFrom(question.length, 1)">
                        {{question.extradata[i] ? question.extradata[i] : ""}} <br/>
                        {{i}}
                      </td>
                    </tr>
                    <tr *ngFor="let choice of question.choices">
                      <td style="text-align: center" class="rate"
                        *ngFor="let i of arrayStartFrom(question.length, 1)" >
                        <mat-radio-button (change)="checkRateQuestion(choice, i)"
                          class="w-10" value="{{i}}" name="{{question.id + '_' + choice.id}}">
                        </mat-radio-button>
                      </td>
                    </tr>
                  </table>
<!--                  <div class="pl-3 pr-3" *ngFor="let choice of question.choices">-->
<!--                    <div class="form-inline">-->
<!--                      <mat-radio-group  name="{{question.id + '_' + choice.id}}" [(ngModel)]="choice.answer">-->
<!--                        <mat-radio-button class="w-10 mr-4" *ngFor="let i of arrayStartFrom(question.length, 1)" value="{{i}}" >{{i}}</mat-radio-button>-->
<!--                      </mat-radio-group>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="isHasQuestionTeacher">
            <h6 class="text-success"><i
              class="fas fa-chalkboard-teacher mr-2"></i>KHẢO SÁT GIẢNG VIÊN</h6>
            <div *ngFor="let question of survey.listquestion">
              <ng-container *ngIf="question.isteacher === '1'">
                <div class="card card-default  border rounded" *ngIf="question.type === textQuestionType">
                  <div class="card-body p-3">
                    <div class="row form-inline">
                      <span class="ml-2 mt-2" [innerHTML]=question.content></span>
                    </div>
                  </div>
                  <div class="form-group p-3 mb-0">
                    <input type="text" [(ngModel)]="question.answer" name="{{question.id}}"
                           class="form-control border-left-0 border-top-0 border-right-0 "
                           placeholder="Nhập trả lời..."/>
                  </div>
                </div>
                <div class="card card-default  border rounded" *ngIf="question.type === radioQuestionType">
                  <div class="card-body p-3">
                    <div class="row form-inline">
                      <span class="ml-2 mt-2" [innerHTML]=question.content></span>
                    </div>
                  </div>
                  <div class="pl-3 pr-3">
                    <mat-radio-group name="{{question.id}}" [(ngModel)]="question.answer">
                      <mat-radio-button  class="w-10 mr-4" *ngFor="let choice of question.choices" value="{{choice.id}}" ><p class="mt-3" style= "word-break: break-all;">{{choice.content}}</p></mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <div class="card card-default  border rounded" *ngIf="question.type === rateQuestionType">
                  <div class="card-body">
                    <div class="row form-inline">
                      <span class="ml-2 mt-2" [innerHTML]=question.content></span>
                    </div>
                  </div>
                  <table style="width: 100%">
                    <tr>
                      <td style="text-align: center; word-break: break-word;" class="rate"
                          [style.width.%]="100 / question.length"
                          *ngFor="let i of arrayStartFrom(question.length, 1)">
                        {{question.extradata[i] ? question.extradata[i] : ""}} <br/>
                        {{i}}
                      </td>
                    </tr>
                    <tr *ngFor="let choice of question.choices">
                      <td style="text-align: center" class="rate"
                          *ngFor="let i of arrayStartFrom(question.length, 1)" >
                        <mat-radio-button (change)="checkRateQuestion(choice, i)"
                                          class="w-10" value="{{i}}" name="{{question.id + '_' + choice.id}}">
                        </mat-radio-button>
                      </td>
                    </tr>
                  </table>
<!--                  <div class="pl-3 pr-3" *ngFor="let choice of question.choices">-->
<!--                    <div class="form-inline">-->
<!--                      <mat-radio-group  name="{{question.id + '_' + choice.id}}" [(ngModel)]="choice.answer">-->
<!--                        <mat-radio-button-->
<!--                          class="w-10 mr-4" *ngFor="let i of arrayStartFrom(question.length, 1)" value="{{i}}" >-->
<!--                          {{i}}-->
<!--                        </mat-radio-button>-->
<!--                      </mat-radio-group>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="no-course text-center p-3" *ngIf="noSurvey && !(getDateFromUnix(course?.enddate)?.toDate() < currentDate)">{{'common.no_survey' | translate}}</div>
    <div class="no-course text-center p-3" *ngIf="isSendSurvey && !(getDateFromUnix(course?.enddate)?.toDate() < currentDate)">Cảm ơn bạn đã tham gia khảo sát</div>
    <div class="no-course text-center p-3" *ngIf="hasResponse">Bạn đã hoàn thành khảo sát</div>
    <div class="no-course text-center p-3" *ngIf="!hasResponse && getDateFromUnix(course?.enddate)?.toDate() < currentDate">Đã hết hạn làm bài khảo sát</div>
  </div>
  <div class="col-lg-3 col-md-6">
    <div class="row">
      <div class="col-12 text-center">
        <img src="/assets/survey.svg" style="height: 25vh;" alt="">
        <p class="text-muted">Hãy giúp chúng tôi nâng cao chất lượng khóa học, bằng ý kiến đóng góp của
          bạn!</p>
      </div>
    </div>
    <div class="row centerbutton m-2">
      <button type="button" [disabled]="hasResponse || noSurvey || isSendSurvey || getDateFromUnix(course?.enddate)?.toDate() < currentDate" class="btn btn-primary rounded-0" (click)="sendAnswer()">
        Gửi khảo sát
      </button>
    </div>
    <!-- <div *ngFor="let question of survey.listquestion; let i = index">
      <div class="square">
        <div class="content">{{i+1}}</div>
      </div>
    </div> -->
  </div>
</div>
