import { ReportService } from '../../../shared/services/report.service';
import { ChartDataSets } from 'chart.js';
import CommonUtil from 'src/app/shared/utils/common-util';
import { CourseCategory } from './../../../shared/model/course-category.model';
import { CourseCategoryService } from '../../../shared/services/course-category.service';
import { LoginService } from '../../../shared/services/login.service';
import { ToastrCustomService } from './../../../shared/services/toastr-custom.service';
import { Component, Input, OnInit } from '@angular/core';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {FileService} from '../../../shared/services/file.service';
import {FormBuilder} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-report-general',
  templateUrl: './report-general.component.html',
  styleUrls: ['./report-general.component.css']
})
export class ReportGeneralComponent implements OnInit {

  @Input() courseType: any;
  @Input() courseLable: string;
  @Input() titleTable: {
    courseLabel: string,
    studentLabel: string,
  };

  barChartData: ChartDataSets[];

  barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  barChartPlugins = [pluginDataLabels];
  selected = 'option1';
  barChartLabels = [];
  barChartType = 'bar';
  barChartLegend = true;
  categoryMap = new Map();
  courseCategoryList: CourseCategory[] = [];
  categoryId = 0;

  constructor(
    private reportService: ReportService,
    private toastrService: ToastrCustomService,
    private fileService: FileService,
    private courseCategoryService: CourseCategoryService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.barChartData = this.setBarChart();
    this.reportCourseInSystem(0);  // category id = 0 => get all
    this.spinner.show();
    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      this.courseCategoryList = response.body;
      CommonUtil.convertCategoryListToMap(this.courseCategoryList, this.categoryMap);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  reportCourseInSystem(categoryId: number) {
    this.categoryId = categoryId;
    this.spinner.show();
    this.reportService.reportCourseInSystem(this.courseType, categoryId).subscribe(
      res => {
        this.barChartLabels = [];
        for (let i = 0; i < res.body.length; i++) {
          this.barChartLabels.push(res.body[i].year);
          this.barChartData[0].data[i] = res.body[i].result.totalcourse;
          this.barChartData[1].data[i] = res.body[i].result.totalstudent;
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      });
  }

  reportExportCourseInSystem() {
    this.spinner.show();
    this.reportService.reportExportCourseInSystem(this.courseType, this.categoryId).subscribe(
      res => {
        this.spinner.hide();
        window.open(this.fileService.getFileFromPathUrl(res.body.path));
      },
      err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      }
    );
  }

  _parseInt(val: any) {
    return parseInt(val);
  }

  setBarChart(){
    return [
      {data: [0, 0, 0], label: this.courseLable},
      {data: [0, 0, 0], label: 'Số học viên'}
    ];
  }

}
