import { USER_INFO, USER_ROLE } from './../../../shared/constants/base.constant';
import { LocalStorageService } from 'ngx-webstorage';
import { DataService } from 'src/app/shared/services/data.service';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-euheader',
  templateUrl: './euheader.component.html',
  styleUrls: ['./euheader.component.css']
})
export class EuheaderComponent implements OnInit {

  isLoggedIn: boolean;
  @ViewChild('keyword') keyword: ElementRef;

  isTeacher: boolean = false;

  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private dataService: DataService,
    private $localStorage: LocalStorageService
  ) {}

  searchForm = this.fb.group({
    keyWord: ['']
  });

  ngOnInit(): void {

    const roles = this.$localStorage.retrieve(USER_INFO.ROLES);
    if (roles) {
      for (const role of roles) {
        if (role.shortname === USER_ROLE.TEACHER) {
          this.isTeacher = true;
        }
      }
    }

    this.dataService.searchKeyWord?.subscribe(search => {
      this.keyword.nativeElement.value = search;
    });
  }

  onSearch(keyword: string) {
    this._router.navigate(['search-result', {search: keyword}]);
    this.keyword.nativeElement.value = '';
  }
}
