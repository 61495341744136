<div class="modal-header">
  <h5 class="modal-title">Cập nhật câu hỏi</h5>
  <button type="button" class="btn btn-tool" data-card-widget="collapse"
          (click)="activeModal.dismiss()"><i class="fas fa-times"></i>
  </button>
</div>
<div class="col-12 mt-3 border" *ngIf="isText">
  <!-- /.card-header -->
  <form [formGroup]="createTextBoxQuestion">
    <h6 class="mt-3">Nhập nội dung câu hỏi Text</h6>
    <div class="card-body" style="display: block;">
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Nhập nội dung <span class="text-danger">*</span></p>
        </div>
        <div class="col-md-9">
          <ckeditor [editor]="Editor" data="" formControlName="textContent" [config]="editorConfig"></ckeditor>
          <div *ngIf="createTextBoxQuestion.get('textContent')!.invalid &&(createTextBoxQuestion.get('textContent')!.dirty || createTextBoxQuestion.get('textContent')!.touched)">
            <small class="text-danger" *ngIf="createTextBoxQuestion.get('textContent')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
        </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="checkChooseTemplate">
        <div class="col-md-3 text-right">
          <p>Chọn đối tượng được khảo sát <span class="text-danger">*</span></p>
        </div>
        <div class="col-md-9">

          <mat-radio-group name="survey" formControlName="forTeacher">
            <mat-radio-button   value="1" >Giảng viên</mat-radio-button>
            <mat-radio-button   value="0" >Khóa học</mat-radio-button>
          </mat-radio-group>
          <div *ngIf="createTextBoxQuestion.get('forTeacher')!.invalid &&(createTextBoxQuestion.get('forTeacher')!.dirty || createTextBoxQuestion.get('forTeacher')!.touched)">
            <small class="text-danger" *ngIf="createTextBoxQuestion.get('forTeacher')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
        </div>
        </div>
      </div>
    </div>
  </form>

  <div class="card-footer text-center">
    <button type="submit"  class="btn btn-default mr-2" (click)="activeModal.dismiss()" >Đóng</button>
    <button type="submit"  class="btn btn-primary mr-2" (click)="updateQuestion()">Cập nhật</button>
  </div>

</div>

<div class="col-12 mt-3 border" *ngIf="isRate">
  <!-- /.card-header -->
  <form [formGroup]="createRateQuestion">
    <h6 class="mt-3">Nhập nội dung câu hỏi Rate</h6>
    <div class="card-body" style="display: block;">
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Nhập nội dung <span class="text-danger">*</span></p>
        </div>
        <div class="col-md-9">
          <ckeditor [editor]="Editor" data="" formControlName="textContent" [config]="editorConfig"></ckeditor>
          <div *ngIf="createRateQuestion.get('textContent')!.invalid &&(createRateQuestion.get('textContent')!.dirty || createRateQuestion.get('textContent')!.touched)">
            <small class="text-danger" *ngIf="createRateQuestion.get('textContent')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
        </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Số sao</p>
        </div>
        <div class="col-md-9">
          <input type="number" min="1" max="10" placeholder="5" formControlName="length"/>
          <div
            *ngIf="createRateQuestion.get('length')!.invalid &&(createRateQuestion.get('length')!.dirty || createRateQuestion.get('length')!.touched)">
            <small class="text-danger" *ngIf="createRateQuestion.get('length')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
            <small class="text-danger" *ngIf="createRateQuestion.get('length')?.errors?.rangeValue">
              {{ 'error.validate.quiz.rangeValue' | translate }}
            </small>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Nhãn với sao tương ứng</p>
        </div>
        <div class="col-md-9">
          <textarea placeholder="Nhãn tương ứng" formControlName="allnameddegrees"></textarea>
        </div>
      </div>
      <div class="row mt-2" *ngIf="checkChooseTemplate">
        <div class="col-md-3 text-right">
          <p>Chọn đối tượng được khảo sát <span class="text-danger">*</span></p>
        </div>
        <div class="col-md-9">

          <mat-radio-group name="survey" formControlName="forTeacher">
            <mat-radio-button   value="1" >Giảng viên</mat-radio-button>
            <mat-radio-button   value="0" >Khóa học</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </form>

  <div class="card-footer text-center">
    <button type="submit"  class="btn btn-default mr-2" (click)="activeModal.dismiss()" >Đóng</button>
    <button type="submit" class="btn btn-primary mr-2" (click)="updateQuestion()">Cập nhật</button>
  </div>

</div>

<div class="col-12 mt-3 border" *ngIf="isSelect">
  <!-- /.card-header -->
  <form [formGroup]="createSelectQuestion">
    <h6 class="mt-3">Nhập nội dung câu hỏi Select</h6>
    <div class="card-body" style="display: block;">
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Nhập nội dung <span class="text-danger">*</span></p>
        </div>
        <div class="col-md-9">
          <ckeditor [editor]="Editor" data="" formControlName="textContent" [config]="editorConfig"></ckeditor>
          <div *ngIf="createSelectQuestion.get('textContent')!.invalid &&(createSelectQuestion.get('textContent')!.dirty || createSelectQuestion.get('textContent')!.touched)">
            <small class="text-danger" *ngIf="createSelectQuestion.get('textContent')?.errors?.required">
              {{ 'error.validate.input_required' | translate }}
            </small>
        </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Các câu trả lời có thể có <span class="text-danger">*</span></p>
        </div>
        <div class="col-md-9" formArrayName="selectContent">
          <div *ngFor="let choice of selectContent.controls;let i = index" class="row mt-2">
            <div [formGroupName]="i" class="col-md-12">
                <label>Đáp án {{i+1}}</label>
                <textarea formControlName="text" (keydown.enter)="onKeydown($event)" class="form-control"></textarea>
                <div
                  *ngIf="selectContent.controls[i].get('text')!.invalid &&(selectContent.controls[i].get('text')!.dirty || selectContent.controls[i].get('text')!.touched)">
                  <small class="text-danger" *ngIf="selectContent.controls[i].get('text')?.errors?.required">
                    {{ 'error.validate.input_required' | translate }}
                  </small>
                </div>
            </div>
            <div class="col-md-12 mt-2 text-left">
              <button type="submit" *ngIf="i > 0" (click)="removeAnswer(i)" class="btn btn-danger btn-sm"><i class="nav-icon fas fa-plus mr-1"></i>Xóa</button>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-left">
          <button type="submit" (click)="addAnswer()" class="btn btn-secondary btn-sm"><i class="nav-icon fas fa-plus mr-1"></i>Thêm đáp án</button>
        </div>
      </div>
      <div class="row mt-3 border" *ngIf="checkChooseTemplate">
        <div class="col-md-3 mt-3 text-right">
          <p>Chọn đối tượng được khảo sát <span class="text-danger">*</span></p>
        </div>
        <div class="col-md-9 mt-3">

          <mat-radio-group name="survey" formControlName="forTeacher">
            <mat-radio-button value="1" >Giảng viên </mat-radio-button>
            <mat-radio-button value="0" >Khóa học</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </form>

  <div class="card-footer text-center">
    <button type="submit"  class="btn btn-default mr-2" (click)="activeModal.dismiss()" >Đóng</button>
    <button type="submit"  class="btn btn-primary mr-2" (click)="updateQuestion()">Cập nhật</button>
  </div>

</div>











