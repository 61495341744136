<div class="content-wrapper" style="min-height: 825px;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Thêm mới câu hỏi</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
              <li class="breadcrumb-item active"><a [routerLink]="['/admin/question-bank']">Quản lý ngân hàng câu hỏi</a></li>
              <li class="breadcrumb-item active">Tạo mới câu hỏi</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card card-default">
              <div class="card-body" style="display: block;">
                <div class="row mt-3 mb-3">
                  <div class="col-md-5 text-right">
                    <label>Chọn danh mục</label>
                  </div>
                  <div class="col-md-3">
                    <select class="custom-select" name="grademethod" [(ngModel)]="categoryid">
                     <option *ngFor="let item of categoryMap | keyvalue" [value]="item.key" [selected]="item.key == 1">{{item.value}}</option>
                    </select>
                  </div>
                </div>
<!--                <div class="row">-->
<!--                  <div class="col-12 text-center">-->
<!--                   <button type="button" *ngIf="accordions.length > 0" (click)="cancelCreateQues()" class="btn btn-default btn-md mr-2"><i class="nav-icon fas fa-close mr-1"></i>Hủy </button>-->
<!--                   <button type="button" *ngIf="accordions.length > 0" [disabled]="!saveEnabled" (click)="createQuestions()" class="btn btn-primary btn-md" ><i class="nav-icon fas fa-save mr-1"></i>Lưu lại </button>-->
<!--                  </div>-->
<!--                </div>-->
                <!-- <hr> -->
                <div class="container">
                        <div class="btn-sticky shadow ">
                            <div aria-label="Vertical button group" role="group" class="btn-group-vertical">
                            <button type="button" matTooltip="Một lựa chọn" matTooltipClass="tooltip-red" class="btn btn-default btn-lg" (click)="addQuestion('multichoice')"><i class="fas fa-check"></i></button>
                            <button type="button" matTooltip="Nhiều lựa chọn" matTooltipClass="tooltip-red" class="btn btn-default btn-lg" (click)="addQuestion('multichoiceset')"><i class="fas fa-check-double"></i></button>
                           <button type="button" matTooltip="Đúng sai" matTooltipClass="tooltip-red" class="btn btn-default btn-lg" (click)="addQuestion('truefalse')"><i class="fa fa-cubes"></i></button>
                            </div>
                          </div>
                <div class="row p-4 d-flex">
                    <div class="col-12 align-self-center p-2">
                        <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
                            <mat-expansion-panel *ngFor="let panel of accordions; let i = index"
                              (opened)="panelOpenState = true"
                              (closed)="panelOpenState = false">
                              <mat-expansion-panel-header>
                                 <mat-panel-title class="right-aligned-header">{{ panel.title }}</mat-panel-title>
                                 <mat-icon class="mr-1 icon_drag">swap_vert</mat-icon>
                                <mat-panel-description>{{ panel.description }}</mat-panel-description>
                              </mat-expansion-panel-header>
                              <ng-container *ngIf="panel.type == 'multichoice'">
                                <app-multichoice
                                  [data]="{
                                    question: panel.question,
                                    canEdit: true,
                                    isPublished: false,
                                    moduleId: null,
                                    categoryId: this.categoryid,
                                    index: i
                                   }"
                                  (deleteQuestionEvent)="onRemoveQuestion($event)"
                                  (createQuestionEvent)="onRemoveQuestion($event)"
                                  (cloneQuestion)="addQuestion(panel.type, panel.index, $event.value)"
                                  [index]="panel.index" [questionInput]="panel.question">

                                </app-multichoice>
                              </ng-container>

                              <ng-container *ngIf="panel.type == 'multichoiceset'">
                                <app-multichoiceset
                                  [data]="{
                                    question: panel.question,
                                    canEdit: true,
                                    isPublished: false,
                                    moduleId: null,
                                    categoryId: this.categoryid,
                                    index: i
                                   }"
                                  (deleteQuestionEvent)="onRemoveQuestion($event)"
                                  (createQuestionEvent)="onRemoveQuestion($event)"
                                  [index]="panel.index" [questionInput]="panel.question">

                                </app-multichoiceset>
                              </ng-container>

                              <ng-container *ngIf="panel.type == 'truefalse'">
                                <app-truefalse
                                  input="panel"
                                  [data]="{
                                    question: panel.question,
                                    canEdit: true,
                                    isPublished: false,
                                    moduleId: null,
                                    categoryId: this.categoryid,
                                    index: i
                                   }"
                                  (deleteQuestionEvent)="onRemoveQuestion($event)"
                                  (createQuestionEvent)="onRemoveQuestion($event)"

                                  [index]="panel.index" [questionInput]="panel.question">

                                </app-truefalse>
                              </ng-container>

<!--                              <ng-container *ngIf="panel.type == 'multichoice'">-->
<!--                                <app-multichoice [index]="panel.index" [questionInput]="panel.question" (messageEvent)="receiveMulti($event)"></app-multichoice>-->
<!--                              </ng-container>-->
<!--                              <ng-container *ngIf="panel.type == 'multichoiceset'">-->
<!--                                <app-multichoiceset [index]="panel.index" [questionInput]="panel.question" (messageEvent)="receiveMultiset($event)"></app-multichoiceset>-->
<!--                              </ng-container>-->
<!--                              <ng-container *ngIf="panel.type == 'truefalse'">-->
<!--                                <app-truefalse input="panel" [index]="panel.index" [questionInput]="panel.question" (messageEvent)="receiveTrueFalse($event)"></app-truefalse>-->
<!--                              </ng-container>-->
<!--                              <mat-action-row>-->
<!--                                <button type="button"  matTooltip="Sao chép" (click)="addQuestion(panel.type, panel.index)" matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i class="fas fa-copy"></i></button>-->
<!--                                &lt;!&ndash; <button type="button"  matTooltip="Chỉnh sửa" *ngIf="panel.question && panel.question?.id" matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i class="fas fa-edit"></i></button> &ndash;&gt;-->
<!--                                <button type="button" matTooltip="Lưu" *ngIf="!panel.question?.id" [disabled]="panel.btnSaveDisable" (click)="onSaveQues(panel)" matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i class="fas fa-save mr-1"></i></button>-->
<!--                                <button type="button" matTooltip="Xóa" (click)="removeQuestion(panel)" matTooltipClass="tooltip-red" class="btn btn-default btn-lg"><i class="fas fa-trash"></i></button>-->
<!--                              </mat-action-row>-->
                            </mat-expansion-panel>
                          </mat-accordion>
                    </div>
                </div>
                </div>
              </div>
              <!-- <div class="card-footer text-center"><button type="submit" class="btn btn-default"><i class="nav-icon fas fa-close"></i> Hủy</button><button type="button" class="btn btn-success ml-2"><i class="nav-icon fas fa-save mr-1"></i><a class="text-white" href="list-question.php">Lưu và tạo mới</a></button> </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
