<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<div class="modal-body">
  {{body}}
  <div class= "d-flex mt-1">
    <div class="mr-1">
      <mat-icon matListAvatar>
        <mat-checkbox  class="example-margin" [(ngModel)]="isChecked" (change)="changeValueChecked($event.checked)"></mat-checkbox>
      </mat-icon>
    </div>
    <div>
      <i>Giữ chứng chỉ cho học viên</i>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary"
          (click)="activeModal.dismiss('close')">{{ 'common.cancel' | translate }}</button>
  <button type="button" class="btn btn-danger"
          (click)="activeModal.close('confirm')">{{confirmButton}}</button>
</div>
