<div class="modal-header">
  <h5 class="modal-title">Tải File lên hệ thống</h5>
  <h5 class="modal-title">File mẫu: <a href="{{urlCSVTemplte}}">File mẫu</a></h5>
</div>
<div class="modal-body">
  <div class="row">
    <form role="form">
      <div class="row">
        <div class="col-7">
          <div class="form-group fullname-require">

          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Upload File</label>
            <div class="input-group">
              <input type="file" name="fileToUpload" (change)="handleFileInput($event?.target?.files)"
                     multiple="false">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card-footer text-center">
  <button type="submit" (click)="activeModal.dismiss('close')" class="btn ml-1 btn-secondary rounded-0 mr-2"
          data-dismiss="modal">Hủy
  </button>
  <button type="submit" class="btn btn-primary" [disabled]="isDisable" (click)="importFileUser()">Tải lên</button>
</div>
