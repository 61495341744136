import { Component, Input, OnInit } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-exam-result',
  templateUrl: './exam-result.component.html',
  styleUrls: ['./exam-result.component.css'],
  providers: [NgbModal, NgbModalConfig],
})
export class ExamResultComponent implements OnInit {
  @Input() title: string;
  @Input() correctquestion: any;
  @Input() totalquestion: any;
  @Input() grade: number;
  @Input() gradequiz: number;
  @Input() confirmButton: string;
  @Input() sumgrades: string;
  @Input() statusQuiz: string;
  @Input() timeResult: number;
  @Input() turnOfExam: number;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
