<section class="section-spacing text-center" id="features">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12" *ngIf="listHistoryLearning?.length > 0; else noHistory">
        <ul class="timeline" *ngFor="let history of listHistoryLearning; let i=index">
          <li class="wow bounceInUp" [ngClass]="{'timeline': i%2 ===0, 'timeline-inverted': i%2 !==0}">
            <div class="timeline-badge warning"><i class="fa fa-crosshairs"></i></div>
            <div class="date-info">
              <span class="day">{{convertToDate(history.time)| date:'dd'}}</span><span
              class="month">{{convertToDate(history.time) | date:'MM'}}</span><span
              class="year">{{convertToDate(history.time)| date:'yyyy'}}</span>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h6 class="timeline-title">{{history.coursesectionname}}</h6>
              </div>
              <div class="timeline-body">
                <div class="row">
                  <div class="col-10">
                    <p style="font-size: 11pt;">{{history.coursemodulename}}</p>
                  </div>
                  <!-- <div class="col-2"><span class="dot-score" >45</span></div> -->
                </div>
                <!-- <div class="progress mt-2">
                        <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:70%;">70% Hoàn thành
                        </div>
                      </div> -->
              </div>
            </div>
          </li>
        </ul>
      </div>
      <ng-template #noHistory>
        <div class="col-md-12 text-center p-3">{{'common.no_record_enduser' | translate}}</div>
      </ng-template>
    </div>
  </div>
</section>
