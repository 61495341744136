<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Quản lý đơn vị</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý đơn vị</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <!--phan danh sach khoa hoc-->
      <div class="row">
        <mat-drawer-container autosize class="example-container">
          <mat-drawer #drawer class="example-sidenav" mode="side" opened>
            <div class="card card-default">
              <div class="card-body">
                <div class="row">
                  <div class="col-7">
                    <h6>Cây đơn vị</h6>
                  </div>
                  <div class="col-5">
                    <button type="button" data-toggle="modal" data-target="#createTopic"
                            class="btn btn-primary rounded-0 float-right"
                            (click)="clear()">
                      Đặt lại tìm kiếm
                    </button>
                  </div>
                </div>
                <hr>
                <form>
                  <ejs-treeview #departmentTree [fields]="departmentTreeData" (nodeSelecting)='nodeSelected($event)'></ejs-treeview>
                </form>
              </div>
            </div>
            </mat-drawer>
            <div class="example-sidenav-content">
              <div class="card card-default">
                <div class="card-body">
                  <h6>Danh sách đơn vị</h6>
                  <hr>
                  <div class="row mb-2">

                    <div class="col-8 ">
                      <!-- Neu khong phai admin thi khong tao/ xoa department -->
                      <button type="button" *ngIf="isAdmin" class="btn btn-default btn-lg mr-1" matTooltip="Tạo mới" matTooltipClass="tooltip-red" (click)="onCreateDepartment()"><i class="fas fa-plus mr-1"></i></button>
                      <button type="button" *ngIf="isAdmin" class="btn btn-default btn-lg mr-1" matTooltip="Xóa nhiều" matTooltipClass="tooltip-red"
                        [disabled]="departmentIdsChecked?.length === 0"  (click)="onDeleteMultipleDepartment()">
                        <i class="fas fa-trash"></i>
                      </button>
                      <span *ngIf="isAdmin">Số đơn vị đã chọn: {{departmentIdsChecked?.length}}</span>
                    </div>
                    <!--Search -->
                    <div class="col-4 mt-2">
                      <div class="form-inline float-right" role="form">
                        <div class="form-group m-2">
                          <label class="mr-2">Tìm kiếm</label>
                          <input style="width:250px;"
                                 type="search" class="form-control form-control-sm mr-2"
                                 placeholder="Mã-Tên đơn vị"  [(ngModel)]="keyword" (change)="onChangeKeyWord()">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <table class="table table-hover" matSort (matSortChange)="sortData($event)">
                      <thead>
                      <tr>
                        <th>
                            <!-- Neu khong phai admin thi khong hien thi checkbox -->
                            <mat-icon class="custom-m-top" *ngIf="isAdmin">
                              <mat-checkbox class="example-margin" [(ngModel)]="completedAll" (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                            </mat-icon>
                         </th>
                        <th>STT</th>
                        <th mat-sort-header="code">Mã đơn vị</th>
                        <th mat-sort-header="name">Tên đơn vị</th>
                        <th mat-sort-header="parentname">Trực thuộc đơn vị</th>
                        <!-- Khong phai admin thi khong hien thi cot nay -->
                        <th style="width:20%;" *ngIf="isAdmin">Thao tác</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let item of departmentList; let countPosition = index; ">
                        <td>
                          <!-- Neu khong phai admin thi khong hien thi checkbox -->
                          <ng-template [ngIf]="item.id != 1 && isAdmin">
                            <mat-icon class="mt-1">
                              <mat-checkbox [(ngModel)]="item.completed" (change)="oncheckboxItem(item.id, $event.checked)" class="example-margin"></mat-checkbox>
                            </mat-icon>
                          </ng-template>
                        </td>
                        <td>{{(pageIndex - 1)*pageSize + countPosition + 1}}</td>
                        <td>{{item.code}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.parentname}}</td>
                        <!-- Neu khong phai admin thi khong hien thi -->
                        <td class="project-actions"*ngIf="isAdmin">
                          <button type="button"  matTooltip="Chỉnh sửa" matTooltipClass="tooltip-red" (click)="onEditDepartment(item)" class="btn btn-default btn-sm mr-1"><i class="fas fa-edit"></i></button>
                          <ng-template [ngIf]="item.parentname !== null">
                            <button type="button" matTooltip="Xóa" matTooltipClass="tooltip-red" (click)="onDeleteSingleDepartment(item.id)" class="btn btn-default btn-sm mr-1"><i class="fas fa-trash"></i></button>
                          </ng-template>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <br />
                    <p *ngIf="departmentList?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
                  </div>

                  <div class="card-footer text-center">
                    <div class="row">
                      <mat-paginator showFirstLastButtons
                                     [length]="totalRecord"
                                     [pageSize]="pageSize"
                                     [pageIndex]="pageIndex - 1"
                                     (page)="changePage($event)"
                                     [hidePageSize]="false"
                                     [pageSizeOptions]="pageSizeOptions">
                      </mat-paginator>
                    </div>
                  </div>

                </div>
              </div>
            </div>
              </mat-drawer-container>
      </div>
    </div>
  </section>
</div>
