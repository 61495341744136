import {LocalStorageService} from 'ngx-webstorage';
import {ExamListService} from '../../shared/services/exam-list.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {CourseCategoryService} from '../../shared/services/course-category.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataService} from 'src/app/shared/services/data.service';
import * as moment from 'moment';
import {CourseService} from '../../shared/services/course.service';
import {ToastrCustomService} from '../../shared/services/toastr-custom.service';
import {TreeViewComponent} from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'app-my-course',
  templateUrl: './my-course.component.html',
  styleUrls: ['./my-course.component.css']
})
export class MyCourseComponent implements OnInit, OnDestroy {

  MY_COURSE_SEARCH_END_USER = 'my_course_search_eu';
  @ViewChild('keyword') demoInput: ElementRef;
  // tree
  @ViewChild('categoryTree')
  public categoryTree: TreeViewComponent;
  public categoryTreeData; // data for tree
  panelOpenState = false;
  myCourses = [];
  myExams = [];
  search: string;
  token: string;
  categoryId = 0;
  categoryIdExam = 0;
  isTeacher = false;
  pageIndex = 1;
  pageSize = 6;
  pageIndexExam = 1;
  pageSizeExam = 6;
  pageSizeCourseOptions: number[] = [6, 12, 24, 48];
  pageSizeExamOptions: number[] = [6, 12, 24, 48];
  totalCourse: number;
  totalExam: number;
  subscription: Subscription;
  isSearchScreen: boolean = false;
  isTeacherCourseScreen: boolean = false;
  isRegisterScreen: boolean = false;
  url = 'my-course';
  courseStatusSearch: number;
  examStatusSearch: number;

  // MatPaginator Output
  pageEvent: PageEvent;
  pageEventExam: PageEvent;

  constructor(
    private courseService: CourseService,
    private examListService: ExamListService,
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private courseCategoryService: CourseCategoryService,
    private route: ActivatedRoute,
    private $localStorage: LocalStorageService,
    private toastrService: ToastrCustomService
  ) {}

  ngOnInit(): void {
    this.courseCategoryService.getCourseCategoryTree(null).subscribe(response => {
      response.body.unshift({id: 0, name: 'Tất cả', parent: null});
      const dataSource = response.body;
      this.setUpTree(dataSource);
      this.categoryTreeData = { dataSource, id: 'id', parentID: 'parent', text: 'name', hasChildren: 'haschild', selected: 'isSelected' };
    });

    this.route.params.subscribe((params) => {
      this.url = this.route.snapshot.routeConfig.path;
      // case: là màn kết quả tìm kiếm khóa học - kỳ thi
      if (this.url === 'search-result') {
        this.isSearchScreen = true;
        this.search = params.search;
        this.pageSize = 8;
        this.pageSizeCourseOptions = [8, 12, 24, 48];
        if (params.search) {
          this.dataService.searchKeyWord.next(params.search);
        }
        this.searchMyExams();
        this.searchMyCourse();
      } else if (this.url === 'teacher-course') {
        // case: là màn khóa học đang giảng dạy của giảng viên
        this.isTeacher = true;
        this.isTeacherCourseScreen = true;
        this.pageSize = 8;
        this.pageSizeCourseOptions = [8, 12, 24, 48];
        this.getMyCourseTeaching();
      } else if (this.url === 'course-register') {
        // case: là màn list khóa học học viên tự đăng ký
        this.isTeacher = false;
        this.isRegisterScreen = true;
        this.getListCourseStudentCanRegister();
      } else {
        // case: là màn khóa học được gán của học viên
        this.isTeacher = false;
        this.searchMyCourse();
      }
    });
  }

  ngOnDestroy(): void {
    this.dataService.searchKeyWord.next(null);
    this.subscription?.unsubscribe();
  }

  searchMyCourse() {
    this.spinner.show();
    const params = {
      search: this.search,
      categoryid: this.categoryId,
      limit: this.pageSize,
      page: this.pageIndex,
      status: this.courseStatusSearch
    };
    return this.courseService.getMyCourses(params).subscribe((data) => {
      this.myCourses = data.body.results;
      this.totalCourse = data.body.total;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastrService.handlerError(err);
    });
  }

  searchMyExams() {
    this.spinner.show();
    const params = {
      search: this.search,
      categoryid: this.categoryIdExam,
      limit: this.pageSizeExam,
      page: this.pageIndexExam,
      status: this.examStatusSearch
    };
    return this.examListService.listExam(params).subscribe((data) => {
      this.myExams = data.body.results;
      this.totalExam = data.body.total;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastrService.handlerError(err);
    });
  }

  getMyCourseTeaching() {
    this.spinner.show();
    const params = {
      search: this.search,
      categoryid: this.categoryId,
      limit: this.pageSize,
      page: this.pageIndex,
    };
    return this.courseService.getMyCourseTeaching(params).subscribe((data) => {
      this.myCourses = data.body.results;
      this.totalCourse = data.body.total;
      this.spinner.hide();
    });
  }

  getListCourseStudentCanRegister() {
    this.spinner.show();
    const params = {
      search: this.search,
      categoryid: this.categoryId,
      limit: this.pageSize,
      page: this.pageIndex,
    };
    this.courseService.getListCourseStudentCanRegister(params).subscribe(
      (res: any) => {
        res.body?.results?.forEach(course => {
          course.isCanRegister = true;
        });
        this.myCourses = res.body.results;
        this.totalCourse = res.body.total;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
      }
    )
  }

  registerSucc($event) {
    if ($event) {
      this.getListCourseStudentCanRegister();
    }
  }

  onFilterCourse(courseFilter: string) {
    this.pageIndex = 1;
    switch (courseFilter) {
      // case list all course
      case '0':
        this.courseStatusSearch = 0;
        break;
      // case list course finished
      case '1':
        this.courseStatusSearch = 1;
        break;
      // case list course unfinished
      case '2':
        this.courseStatusSearch = 2;
        break;
    }
    this.searchMyCourse();
  }

  onFilterExam(examFilter: string) {
    this.pageIndexExam = 1;
    switch (examFilter) {
      // case list all exam
      case '0':
        this.examStatusSearch = 0;
        break;
      // case list exam finished
      case '1':
        this.examStatusSearch = 1;
        break;
      // case list exam unfinished
      case '2':
        this.examStatusSearch = 2;
        break;
    }
    this.searchMyExams();
  }

  // onChangCategoryCourse($event) {
  //   this.categoryId = $event.option.value;
  //   this.searchMyCourse();
  // }

  // onChangCategoryExam($event) {
  //   this.categoryIdExam = $event.option.value;
  //   this.searchMyExams();
  // }

  getDateFromUnix(date) {
    if (date) {
      return moment.unix(date);
    }
    return null;
  }

  localStore(name, finished) {
    this.$localStorage.store('examName', name);
    this.$localStorage.store('finished', finished);
  }

  setUpTree(dataSource: any) {
    dataSource.forEach(value => {
      if (value.parent === 0) // = 0 la root tree
      {
        value.parent = null; // set = null nham muc dich hien thi
        value.expanded = true; // muc dich expand root luon khi khoi tao tree
      } else {
        value.expanded = false;
      }
    });
  }

  nodeSelected(event) {
    this.pageIndex = 1;
    this.categoryId = +this.categoryTree.getNode(event.node).id;
    if (this.isSearchScreen) {
      this.searchMyExams();
      this.searchMyCourse();
    } else if (this.isTeacherCourseScreen) {
      this.getMyCourseTeaching();
    } else if (this.isRegisterScreen) {
      this.getListCourseStudentCanRegister();
    } else {
      this.searchMyCourse();
    }
  }

  changePageExam(event) {
    this.pageIndexExam = event.pageIndex + 1;
    this.pageSizeExam = event.pageSize;
    this.searchMyExams();
  }

  changePage(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if (this.isSearchScreen) {
      this.searchMyExams();
      this.searchMyCourse();
    } else if (this.isTeacherCourseScreen) {
      this.getMyCourseTeaching();
    } else if (this.isRegisterScreen) {
      this.getListCourseStudentCanRegister();
    } else {
      this.searchMyCourse();
    }
  }
}
