<section class="inner_page_breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 text-center">
        <div class="breadcrumb_content mt-4">
          <h4 class="breadcrumb_title">LỘ TRÌNH CHỨC DANH {{positionName}}</h4>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/home']">Trang chủ</a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/course-route']">Lộ trình</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#"></a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section>

<main id="main">
  <!---Phan noi dung bai hoc-->
  <section id="route">
    <div class="container">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="pills-course-tab" data-toggle="pill" href="#pills-course" role="tab" aria-controls="pills-course" aria-selected="true">Các Khóa học </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="pills-exam-tab" data-toggle="pill" href="#pills-exam" role="tab" aria-controls="pills-exam" aria-selected="false">Các kỳ thi</a>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-course" role="tabpanel" aria-labelledby="pills-course-tab">
          <div class="row form-inline" *ngIf="listCourseOfPosition?.length > 0 ; else noCourse">
            <p>Số khóa học hoàn thành: <span class="font-weight-bold font-size-16">{{courseCompletion}}/{{listCourseOfPosition.length}}</span></p>
            <p class="text-primay font-italic ml-2 mt-2">Học viên lưu ý hoàn thành từng khóa học theo lộ trình</p>
          </div>
          <div class="row mt-1">
            <div class="col-lg-3 col-md-12  d-flex align-items-stretch" *ngFor="let course of listCourseOfPosition; let i = index">
              <app-course-card [course] = "course" [courseInPosition] = true [index]="i+1"></app-course-card>
            </div>
          </div>
          <ng-template #noCourse>
            <div class="container text-center">{{'common.no_record_enduser' | translate}}</div>
          </ng-template>
        </div>
        <div class="tab-pane fade" id="pills-exam" role="tabpanel" aria-labelledby="pills-exam-tab">

          <div class="row mt-3">
            <div  *ngFor="let exam of listExamOfPosition;let i = index" class="col-lg-6 col-md-12">
              <div class="card-box mb-2">
                <div class="row align-items-center">
                  <div class="col-lg-10 col-md-10">
                    <div class="media">
                      <!-- <div (click)="localStore(item.fullname, item.finished)" [routerLink]="['/exam-lesson',item.id]" class="person-circle chr-{{item.fullname | uppercase | slice:0:1}}" style="cursor: pointer;">{{item.fullname | uppercase | slice:0:1 }}</div> -->
                      <div class="media-body">
                        <h5 class="mt-0 mb-2">{{exam.fullname}}</h5>
                        <p class="mb-1"> <i class="icofont-calendar mr-2"></i>
                          <span style="font-size: 10pt;">{{convertTotDate(exam.startdate)  | date:'dd/MM/yyyy'}} - {{convertTotDate(exam.enddate) | date:'dd/MM/yyyy'}} </span></p>
                        <p class="mb-0" *ngIf="exam.completion == 100"><i class="icofont-tasks mr-2"></i> Đã hoàn thành</p>
                        <p class="mb-0" *ngIf="exam.completion < 100"><i class="icofont-tasks mr-2"></i> Chưa hoàn thành</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-2">
                    <div class="text-center button-list">
                      <button [routerLink]="['/exam-lesson', exam.id]" class="btn btn-sm btn-primary" class="btn btn-sm btn-primary">Tham gia</button>
                    </div>
                  </div>
                </div> <!-- end row -->
              </div>
            </div>
          </div>

          <!-- <div *ngIf="listExamOfPosition?.length > 0 ; else noExam">
            <div class="row form-inline">
              <p class="mr-5">Số kỳ thi hoàn thành:<span class="font-weight-bold font-size-16">{{examCompletion}}/{{listExamOfPosition.length}}</span></p>
              <p>Môn thi đạt:<span class="ml-1 font-weight-bold font-size-16">03/05</span></p>
            </div>
            <table class="table mt-3">
              <thead>
              <tr>
                <th>STT</th>
                <th>Kỳ thi</th>
                <th>Bắt đầu</th>
                <th>Kết thúc</th>
                <th class="text-center">Trạng thái</th>
                <th class="text-center">Thao tác</th>
              </tr>
              </thead>
              <tbody *ngFor="let exam of listExamOfPosition;let i = index">
              <tr>
                <td>{{i+1}}</td>
                <td class="p-2">{{exam.fullname}}</td>
                <td>{{convertTotDate(exam.startdate)  | date:'dd/MM/yyyy'}}</td>
                <td>{{convertTotDate(exam.enddate) | date:'dd/MM/yyyy'}}</td>
                <td class="text-center"><span class="label label-default">Chưa thi</span></td>
                <td class="text-center"><button type="button" onclick="location.href='my-exam-list.html'" class="btn btn-sm btn-primary">Vào thi</button></td>
              </tr>
              </tbody>
            </table>
          </div> -->
          <ng-template #noExam>
            <div class="container text-center">{{'common.no_record_enduser' | translate}}</div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</main>
<!-- End #main -->
