import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from 'src/assets/ckeditor5-build-classic';
import {CKEDITOR_CONFIG} from '../../../../shared/constants/ckeditor.constant';

@Component({
  selector: 'app-numberical-quiz',
  templateUrl: './numberical-quiz.component.html',
  styleUrls: ['./numberical-quiz.component.css']
})
export class NumbericalQuizComponent implements OnInit {
  public Editor = ClassicEditor;

  editorConfig = CKEDITOR_CONFIG.NOT_UPLOAD;
  constructor() { }

  ngOnInit(): void {
  }

}
