import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {LoginService} from "../../shared/services/login.service";
import {InfoModalComponent} from "../../shared/modal/info-modal/info-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmModalComponent} from "../../shared/modal/confirm-modal/confirm-modal.component";

@Component({
  selector: 'app-activation-email',
  templateUrl: './activation-email.component.html'
})
export class ActivationEmailComponent implements OnInit {

  token: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private loginService: LoginService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParams.token;
    const params = {
      token: this.token
    };
    this.spinner.show();

    // check token to activate email
    this.loginService.checkTokenToActivateAccount(params).subscribe(res => {
      this.spinner.hide();
      this.router.navigate(['/home']);
      const modalEmailConfirm = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalEmailConfirm.componentInstance.title = this.translateService.instant('login.activation_success.title');
      modalEmailConfirm.componentInstance.body = this.translateService.instant('login.activation_success.content');
      modalEmailConfirm.componentInstance.confirmButton = this.translateService.instant('common.close');
    }, error => {
      this.spinner.hide();
      const modalDep = this.modalService.open(InfoModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalDep.componentInstance.title = this.translateService.instant('warning');
      // tslint:disable-next-line:max-line-length
      modalDep.componentInstance.body = this.translateService.instant('error.forgot_password.tokeninvalid');
      modalDep.componentInstance.confirmButton = this.translateService.instant('common.confirm');

      modalDep.result.then((result) => {
        this.router.navigate(['/home']);
      });
    });
  }

}
