<!-- ======= Top Bar ======= -->
<section id="topbar" class="d-none d-lg-block">
  <div class="container clearfix">
    <!-- <div class="contact-info float-left mt-2">
      <i class="icofont-envelope"></i>
      <a href="mailto:support@mobifone.com">daotao@mobifone.com</a>
      <i class="icofont-phone"></i>
      <a href="#">1900 1900</a>
    </div> -->
    <div class="social-links float-right">
      <ng-container *ngIf="userInfo">
        <a [routerLink]="isTeacher ? '/admin/course' : '/admin'" *ngIf="!isStudent" style="color: white" class="mr-3">Trang quản lý</a>
<!--        <a style="color: white" [routerLink]="'/change-password'">-->
<!--          &lt;!&ndash; Lang nghe thay doi tu localstorage de update avatar &ndash;&gt;-->
<!--          <img src="{{$localStorage.retrieve(USER_INFO_KEY).userpictureurl}}" class="user-image">{{ userInfo.lastname }} {{userInfo.firstname}}-->
<!--        </a>-->
        <!-- #docregion mat-menu-trigger-for -->
        <a style="color: white;" class="btn" mat-button [matMenuTriggerFor]="menu">
          <img [src]="userInfo.userpictureurl" class="user-image">
          {{ userInfo.firstname }} {{userInfo.lastname}}
        </a>
        <!-- #enddocregion mat-menu-trigger-for -->
        <mat-menu #menu="matMenu">
          <a mat-menu-item [routerLink]="'/user-detail'">{{ 'user_detail_popup.title' | translate }}</a>
          <a mat-menu-item [routerLink]="'/change-password'" *ngIf="userInfo.auth === 'manual'">
            {{ 'euchangepassword.title' | translate }}
          </a>
        </mat-menu>
        <a (click)="logout()" class="btn" style="color: white"> <i class="icofont-logout font-size-15 mr-1"></i> Đăng xuất</a>
      </ng-container>
      <a href="login" *ngElse>
        <i
          class="icofont-users-alt-4"
          style="color: aliceblue; font-size: 12pt;"
        ></i>
        <span style="color: white;">Đăng nhập</span>
      </a>
    </div>
  </div>
</section>
