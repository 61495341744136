<div class="modal-header">
    <h5 class="modal-title">Tạo mới khảo sát độc lập</h5>
    <button type="button" class="btn btn-tool" data-card-widget="collapse" (click)="activeModal.dismiss()"><i
            class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="createSurveyForm">
        <div class="form-group row">
            <div class="col-3 text-right mb-1">
                <p>Tên bài khảo sát <span class="text-danger">*</span></p>
            </div>
            <div class="col-9">
                <input name="name" type="text" class="form-control" placeholder="Nhập tên bài..."
                    formControlName="name" />
                <div
                    *ngIf="createSurveyForm.get('name')!.invalid &&(createSurveyForm.get('name')!.dirty || createSurveyForm.get('name')!.touched)">
                    <small class="text-danger" *ngIf="createSurveyForm.get('name')?.errors?.required">
                        {{ 'error.validate.input_required' | translate }}
                    </small>
                    <small class="text-danger"
                        *ngIf="createSurveyForm.get('name')?.errors && !createSurveyForm.get('name')?.errors?.required">
                        {{ 'error.validate.course.nameMaxlength' | translate }}
                    </small>
                </div>

            </div>
        </div>

        <div class="form-group row">
            <div class="col-3 mt-3 text-right mb-1">Thời gian bắt đầu <span class="text-danger">*</span></div>
            <div class="col-8 mt-3">
                <div class="row">
                    <div class="col-md-2">
                        <select class="form-control" formControlName="hourStartDate"  >
                          <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <select class="form-control" formControlName="minuteStartDate" style="padding-left: 4px;">
                          <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field>
                            <mat-label>Chọn ngày</mat-label>
                            <input matInput formControlName="startdate" [matDatepicker]="startdate"  [min]="currentDate" >
                            <mat-datepicker-toggle matSuffix [for]="startdate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #startdate></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-3 mt-3 text-right mb-1">Thời gian kết thúc <span class="text-danger">*</span></div>
            <div class="col-8 mt-3">
                <div class="row">
                    <div class="col-md-2">
                        <select class="form-control" formControlName="hoursEndDate">
                          <option *ngFor="let hour of hoursList" [value]="hour.value">{{hour.label}}</option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <select class="form-control" formControlName="minuteEndDate" style="padding-left: 4px;">
                          <option *ngFor="let minute of minutesList" [value]="minute.value">{{minute.label}}</option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field>
                            <mat-label>Chọn ngày</mat-label>
                            <input matInput formControlName="enddate" [matDatepicker]="enddate"  [min]="currentDate" >
                            <mat-datepicker-toggle matSuffix [for]="enddate" >
                            </mat-datepicker-toggle>
                            <mat-datepicker #enddate></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="card-footer text-center">
        <button type="button" (click)="activeModal.dismiss('close')" class="btn ml-1 btn-secondary rounded-0 mr-2"
            data-dismiss="modal">Đóng</button>
        <button type="button" class="btn btn-primary" [disabled]="createSurveyForm.status === 'INVALID'" (click)="createNewSurvey()">Lưu lại</button>
    </div>
</div>
