import { LOCAL_STORAGE_KEY } from './../../../shared/constants/base.constant';
import { Department } from 'src/app/shared/model/department.model';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import {ReportService} from '../../../shared/services/report.service';
import {ToastrCustomService} from 'src/app/shared/services/toastr-custom.service';
import { DepartmentService } from '../../../shared/services/department.service';
import { TreeViewComponent } from '@syncfusion/ej2-angular-navigations';
import { LocalStorageService } from 'ngx-webstorage';
import {FileService} from '../../../shared/services/file.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-report-student',
  templateUrl: './report-student.component.html',
  styleUrls: ['./report-student.component.css']
})
export class ReportStudentComponent implements OnInit, AfterViewInit {

  @ViewChild('keyword') keyword: ElementRef;
  @ViewChild('departmentTree')
  public departmentTree: TreeViewComponent;
  public departmentTreeData;

  constructor(
    private reportService: ReportService,
    private toastrService: ToastrCustomService,
    private departmentService: DepartmentService,
    private $localStorage: LocalStorageService,
    private fileService: FileService,
    private spinner: NgxSpinnerService
  ) {}

  searchUser = {
    keyWord: '',
    pageIndex: 1,
    pageSize: 10,
    coursetype: '',
  };
  totalRecord: number;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  departmentList: Department[] = [];
  checkedDepartmentIds = [];
  listStudent: any;
  selectStudent: any;
  currentSelectedDepartmentId: number;
  userId: any;
  listCourse: any;
  listExam: any;
  courseType = [
    {
      id: 1,
      name: 'Khóa học',
    },
    {
      id: 2,
      name: 'Kỳ thi'
    }
  ];

  dropdownStudentsSettings = {
    allowSearchFilter: true,
    singleSelection: true,
    idField: 'id',
    textField: 'fullname',
    itemsShowLimit: 10,
  };

  selected: any = 1;

  ngOnInit(): void {
    const currentSearch = this.$localStorage.retrieve(LOCAL_STORAGE_KEY.SEARCH.REPORT_STUDENT_SEARCH);
    const depart = this.$localStorage.retrieve(LOCAL_STORAGE_KEY.SEARCH.REPORT_CHECKED_DEPARTMENT);
    // this.getListStudent();
    if (currentSearch && depart) {
      // Kiem tra xem truoc do co luu du lieu tim kiem khong
      this.searchUser.keyWord = currentSearch.keyWord;
      this.searchUser.pageIndex = currentSearch.pageIndex;
      this.searchUser.pageSize = currentSearch.pageSize;
      this.checkedDepartmentIds  = depart;
      // Xoa di sau khi su dung
      this.$localStorage.clear(LOCAL_STORAGE_KEY.SEARCH.REPORT_STUDENT_SEARCH);
      this.$localStorage.clear(LOCAL_STORAGE_KEY.SEARCH.REPORT_CHECKED_DEPARTMENT);
    }
    this.listAllTree();
    this.getListStudent();
  }

  ngAfterViewInit() {
    this.keyword.nativeElement.value = this.searchUser?.keyWord;
  }

  reportCourseStudent() {
    this.spinner.show();
    this.reportService.reportStudentCourse(this.userId, this.selected).subscribe(
      (data) => {
        this.listCourse = data.body.results;
        // console.log(data);
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      });
  }

  getListStudent() {
    const params = {
      limit:  this.searchUser.pageSize,
      page:  this.searchUser.pageIndex,
      keyword: this.searchUser.keyWord,
      departmentids: this.checkedDepartmentIds.toString(),
      // sortcolumn: this.sortcolumn,
      // sorttype: this.sorttype
    };
    this.spinner.show();
    this.reportService.getListStudent(params).subscribe((data) => {
      this.listStudent = data.body.results;
      this.totalRecord = data.body.total;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

  onItemChange(value) {
    this.userId = value;
    this.reportCourseStudent();
  }



  onSearch(event){
    this.searchUser.pageIndex = 1;
    this.searchUser.keyWord = event.target.value;
    this.getListStudent();
  }

  changePage(event) {
    this.searchUser.pageIndex = event.pageIndex + 1;
    this.searchUser.pageSize = event.pageSize;
    this.getListStudent();
  }


  onSelectStudentEvent(event?: any) {
    this.onItemChange(event.id);
  }

  onDeSelectStudentEvent(event?: any) {
    if (event) {
      this.listCourse = [];
    }
  }

  listAllTree() {
    this.spinner.show();
    this.departmentService.getUserDepartmentTree(0).subscribe((data) => {
      this.departmentList = data.body;
      // set thang cha = null de no hien thi len duoc cay
      this.departmentList[0].parentid = null;
      this.departmentList.forEach(value => {
        // value.expanded = value.haschild !== null;
        this.departmentList.filter(department => department.id === value.parentid);
        value.expanded = value.parentid == null;
        // value.isChecked = true;
        // this.checkedDepartmentIds.push(value.id);
      });
      this.departmentTreeData = {
        dataSource: this.departmentList,
        id: 'id',
        parentID: 'parentid',
        text: 'name',
        hasChildren: 'haschild'
      };
      // this.loading.next(true);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.handlerError(error);
    });
  }

   // chọn 1 đơn vị
   nodeDepartmentSelected(e: any) {
    this.currentSelectedDepartmentId = +this.departmentTree.selectedNodes;
    this.getListStudent();
  }

  public nodeCheck(args: any): void {
    const checkedNode: any = [args.node];
    if (args.event.target.classList.contains('e-fullrow') || args.event.key == 'Enter') {
      // @ts-ignore
      const getNodeDetails: any = this.departmentTree.getNodeData(args.node);
      if (getNodeDetails.isChecked == 'true') {
        this.departmentTree.uncheckAll(checkedNode);
      } else {
        this.departmentTree.checkAll(checkedNode);
      }
    }
  }

   // chọn nhiều đơn vị
   nodeDepartmentChecked(e) {
    this.checkedDepartmentIds = [];
    this.departmentTree.checkedNodes.forEach((node) => {
      this.checkedDepartmentIds.push(parseInt(node, 10));
    });
    this.searchUser.pageIndex = 1;
    this.getListStudent();
    // debugger;
  }

  onExportLearningProgressStudents(){
    const params = {
      departmentids: this.checkedDepartmentIds.toString(),
    };
    this.spinner.show();
    this.reportService.exportLearningProgressStudents(params).subscribe(
      res => {
        this.spinner.hide();
        window.open(this.fileService.getFileFromPathUrl(res.body.path));
    }, err => {
        this.spinner.hide();
        this.toastrService.handlerError(err);
    });
  }

  goToDetail(){
    this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.REPORT_STUDENT_SEARCH, this.searchUser);
    this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.REPORT_CHECKED_DEPARTMENT, this.checkedDepartmentIds);
  }
}
