<div class="col-12 mt-3 border"> 
    <!-- /.card-header -->
     <h6 class="mt-3">Nhập nội dung câu hỏi</h6>
    <div class="card-body" style="display: block;">
      <div class="row">
        <div class="col-md-3 text-right">
       <p>Câu hỏi</p>
        </div>
        <div class="col-md-9"> <input type="text" class="form-control" size="55" placeholder="Tên đầy đủ">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Nhập nội dung</p>
        </div>
        <div class="col-md-9">
          <ckeditor [editor]="Editor" data="" [config]="editorConfig"></ckeditor>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 text-right">
          <p>Đánh dấu câu hỏi</p>
        </div>
        <div class="col-md-1">
          <input type="text" class="form-control " name="defaultmark" value="1" size="7">
        </div>
      </div>
      <div class="row mt-2">
          <div class="col-md-3 text-right">
          <p>Lựa chọn kiểu đáp án</p>
        </div>
        <div class="col-md-2">
          <select class="custom-select" name="correctanswer">
            <option value="0">Sai</option>
            <option value="1">Đúng</option>
        </select>
        </div>
      </div>    
</div>



