import { USER_FUNCTIONS, USER_INFO, USER_ROLE, LOCAL_STORAGE_KEY } from './../../shared/constants/base.constant';
import {Sort} from '@angular/material/sort';
import {ConfirmModalComponent} from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import {PageEvent} from '@angular/material/paginator';
import {CourseCreateComponent} from '../course/course-create/course-create.component';
import {ToastrCustomService} from 'src/app/shared/services/toastr-custom.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CourseService} from '../../shared/services/course.service';
import { Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ExamSearchComponent} from './exam-search/exam-search.component';
import {LocalStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {Course} from '../../shared/model/course.model';

@Component({
    selector: 'app-exam',
    templateUrl: './exam.component.html',
    styleUrls: ['./exam.component.css']
})
export class ExamComponent implements OnInit, OnDestroy {

    courses = new Array();
    pageSizeOptions: number[] = [10, 25, 50, 100];
    totalRecord: number;
    completedAll = false;
    happening: string;
    currentDate: Date = new Date();
    // MatPaginator Output
    pageEvent: PageEvent;
    isDisable = true;
    role: string;
    searchDisable: boolean = false;
    isManage: boolean = false;
    managedDepartmentId: number;
    // list id course checked trong cả màn
    courseIdsChecked: number[] = [];
    // list id course checked trong current page
    courseIds: number[] = [];
    courseCanChecked: number = 0;

    searchExam = {
        search: '',
        startDate: null,
        endDate: null,
        categoryid: null,
        pageIndex: 1,
        pageSize: 10,
        sortColumn: 'timecreated',
        sortType: 'desc',
        coursetype: 2,
        departmentId: null,
        positionIds: [],
        status: null,
        positionData: [],
    };

    constructor(
        private courseService: CourseService,
        private modalService: NgbModal,
        private translateService: TranslateService,
        private toastrService: ToastrCustomService,
        private spinner: NgxSpinnerService,
        private $localStorage: LocalStorageService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.role = this.$localStorage.retrieve(USER_INFO.ROLES)[0].shortname;
        const roles = this.$localStorage.retrieve(USER_INFO.ROLES);
        if (roles) {
          for (const role of roles) {
            if (role.shortname === USER_ROLE.MANAGER) {
              this.isManage = true;
            }
          }
        }
        const currentSearch = this.$localStorage.retrieve(LOCAL_STORAGE_KEY.SEARCH.EXAM_SEARCH);
        if (currentSearch) {
            // Kiem tra xem truoc do co luu du lieu tim kiem khong
            this.searchExam.search = currentSearch.search;
            this.searchExam.startDate = currentSearch.startDate;
            this.searchExam.endDate = currentSearch.endDate;
            this.searchExam.categoryid = currentSearch.categoryid;
            this.searchExam.pageIndex = currentSearch.pageIndex;
            this.searchExam.pageSize = currentSearch.pageSize;
            this.searchExam.sortColumn = currentSearch.sortColumn;
            this.searchExam.sortType = currentSearch.sortType;
            this.searchExam.status = currentSearch.status;
            this.searchExam.departmentId = currentSearch.departmentId;
            this.searchExam.positionIds = currentSearch.positionIds;
            this.searchExam.positionData = currentSearch.positionData;
            // Xoa di sau khi su dung
            this.$localStorage.clear(LOCAL_STORAGE_KEY.SEARCH.EXAM_SEARCH);
        }
        this.getListExam();
    }

    ngOnDestroy(): void {
      this.modalService.dismissAll();
    }

    getListExam() {
        const params = {
            limit: this.searchExam.pageSize,
            page: this.searchExam.pageIndex,
            sortcolumn: this.searchExam.sortColumn,
            sorttype: this.searchExam.sortType,
            startdate: this.searchExam.startDate,
            enddate: this.searchExam.endDate,
            departmentids: this.searchExam.departmentId,
            positionids: this.searchExam.positionIds,
            search: this.searchExam.search,
            coursetype: this.searchExam.coursetype,
            status: this.searchExam.status
        };
        this.spinner.show();
        this.courseCanChecked = 0;
        this.courseIds = [];
        this.courseService.searchCourses(params).subscribe(data => {
          if (!this.searchExam.search && data.body.results.length === 0 && !this.searchExam.status
            && !this.searchExam.startDate && !this.searchExam.endDate && !this.searchExam.positionIds && !this.searchExam.departmentId) {
            this.searchDisable = true;
          } else {
            this.searchDisable = false;
          }
          this.managedDepartmentId = data.body.manageddepartmentid;
          this.courses = data.body.results;
          this.totalRecord = data.body.total;
          this.courses.forEach(element => {
              this.currentDate = new Date();
              if (this.currentDate > moment.unix(element.enddate).toDate()) {
                  element.status = 'Đã kết thúc';
                  element.statusNumber = 3;
                  element.happening = 'no';
              }
              if (this.currentDate < moment.unix(element.startdate).toDate()) {
                  element.status = 'Chưa bắt đầu';
                  element.statusNumber = 1;
                  element.happening = 'no';
              }
              if (moment.unix(element.startdate).toDate() <= this.currentDate && this.currentDate <= moment.unix(element.enddate).toDate()) {
                  element.status = 'Đang diễn ra';
                  element.statusNumber = 2;
                  element.happening = 'yes';
                  if (element.published === 0) {
                      element.statusNumber = 1;
                      element.happening = 'no';
                  }
              }
              if (element.published === 0) {
                  element.status = '';
              }
              // khi next trang, nếu đã có kỳ thi được check từ trước thì set checked cho kỳ thi đó
              if (this.courseIdsChecked?.includes(element.id)) {
                element.completed = true;
                this.courseIds?.push(element.id);
              }
              if (!this.checkDisable(element) && ((this.isManage && this.managedDepartmentId == element.refdepartmentid) || !this.isManage)) {
                this.courseCanChecked++;
              }
          });
          // nếu số kỳ thi được checked = số kỳ thi có thể checked thì set completedAll = true
          if (this.courseIds?.length > 0 && (this.courseIds?.length === this.courseCanChecked)) {
            this.completedAll = true;
          } else {
            this.completedAll = false;
          }
          this.spinner.hide();
      },
      err => {
          this.spinner.hide();
          this.toastrService.handlerError(err);
      });
    }

    oncheckboxAll(checked: any) {
      if (checked) {
        this.courses.forEach(c => {
          // nếu user là QLDV thì chỉ checked vào khóa học do đơn vị nó quản lý
          if ((this.isManage && this.managedDepartmentId === c.refdepartmentid) || !this.isManage) {
            if (this.checkDisable(c)) {
              c.completed = !checked;
            } else {
              c.completed = checked;
              if (!this.courseIds.includes(c.id)) {
                this.courseIds.push(c.id);
              }
              if (!this.courseIdsChecked?.includes(c.id)) {
                this.courseIdsChecked?.push(c.id);
              }
            }
          }
        });
        this.completedAll = true;
      } else {
        this.courseIds?.forEach(id => {
          this.courseIdsChecked?.splice(this.courseIdsChecked?.indexOf(id), 1);
        });
        this.courseIds = [];
        this.courses?.forEach(c => {
          c.completed = false;
        });
        this.completedAll = false;
      }
    }

    oncheckboxItem(courseId: number, checked: any) {
      if (checked) {
        this.courses?.forEach(c => {
          if (c.id === courseId) {
            c.completed = true;
            this.courseIds?.push(courseId);
            this.courseIdsChecked?.push(courseId);
            return;
          }
        });
        // nếu số kỳ thi checked bằng số kỳ thi có thể checked trong page thì set checkboxall = true
        if (this.courseIds?.length > 0 && this.courseIds?.length === this.courseCanChecked && !this.completedAll) {
          this.completedAll = true;
        }
      } else {
        this.completedAll = false;
        this.courses?.forEach(c => {
          if (c.id === courseId) {
            c.completed = false;
            this.courseIds?.splice(this.courseIds?.indexOf(courseId), 1);
            this.courseIdsChecked?.splice(this.courseIdsChecked?.indexOf(courseId), 1);
            return;
          }
        });
      }
    }

    onDeleteSingleCourse(courseId: number) {
        this.onDeleteCourses([courseId]);
    }

    onDeleteMultipleCourse() {
        this.onDeleteCourses(this.courseIdsChecked);
    }

    onCancelExam(item) {
        const modalDep = this.modalService.open(ConfirmModalComponent, {
            size: 'lg',
            centered: true,
            backdrop: 'static'
        });
        modalDep.componentInstance.title = this.translateService.instant('exam.cancel_confirm_title');
        modalDep.componentInstance.body = this.translateService.instant('exam.cancel_confirm_content');
        modalDep.componentInstance.confirmButton = this.translateService.instant('exam.confirm_cancel');
        modalDep.result.then((result) => {
            if (result === 'confirm') {
                let end = new Date();
                const updateExam: Course = {
                    id: item.id,
                    fullname: item.fullname,
                    categoryid: item.categoryid,
                    startdate: item.startdate,
                    enddate: Math.floor(end.getTime() / 1000),
                    requireenglish: 0,
                    publishedat: item.publishedat
                };
                this.courseService.updateCourse(updateExam, 'cancel', true).subscribe(res => {
                        this.toastrService.success('exam.cancel_success');
                        this.getListExam();
                    },
                    err => {
                        this.toastrService.handlerError(err);
                    }
                );
            }
        });
    }

    private onDeleteCourses(courseIds?: number[]) {
        const modalDep = this.modalService.open(ConfirmModalComponent, {
            size: 'lg',
            centered: true,
            backdrop: 'static'
        });
        modalDep.componentInstance.title = this.translateService.instant('exam.delete_confirm_title');
        modalDep.componentInstance.body = this.translateService.instant('exam.delete_confirm_content');
        modalDep.componentInstance.confirmButton = this.translateService.instant('common.delete');
        modalDep.result.then((result) => {
            if (result === 'confirm' && courseIds?.length > 0) {
                this.spinner.show();
                this.courseService.deleteCourse(courseIds).subscribe(
                    res => {
                        this.toastrService.success(`common.noti.delete_success`);
                        [...courseIds]?.forEach(courseId => {
                            this.courseIds?.splice(this.courseIds?.indexOf(courseId), 1);
                            this.courseIdsChecked?.splice(this.courseIdsChecked?.indexOf(courseId), 1);
                        });
                        this.getListExam();
                        this.spinner.hide();
                    },
                    err => {
                        this.spinner.hide();
                        this.toastrService.handlerError(err);
                    });
            }
        });
    }

    sortData(sort: Sort) {
        this.searchExam.pageIndex = 1;
        this.searchExam.sortColumn = sort.active;
        this.searchExam.sortType = sort.direction;
        this.getListExam();
    }

    changePage(event) {
        this.searchExam.pageIndex = event.pageIndex + 1;
        this.searchExam.pageSize = event.pageSize;
        this.getListExam();
    }

    getDateFromUnix(date) {
        if (date) {
            return moment.unix(date);
        }
        return null;
    }

    openExamCreatePopup() {
        const modalDep = this.modalService.open(CourseCreateComponent, {
            size: 'lg',
            centered: true,
            backdrop: 'static'
        });

        modalDep.componentInstance.isCreateExam = true;
    }

    openExamSearchPopup() {
      if (!this.searchDisable) {
        const modalDep = this.modalService.open(ExamSearchComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static'
        });
        modalDep.componentInstance.searchExam = this.searchExam;

        modalDep.result.then((result) => {
            if (result) {
                const teacherIds = [result.status];
                const positionIds = [];
                if (result.startDate) {
                    this.searchExam.startDate = result.startDate.getTime() / 1000;
                } else {
                    this.searchExam.startDate = null;
                }
                if (result.endDate) {
                    this.searchExam.endDate = result.endDate.getTime() / 1000;
                } else {
                    this.searchExam.endDate = null;
                }
                if (result.departmentId) {
                  this.searchExam.departmentId = result.departmentId;
                } else {
                  this.searchExam.departmentId = null;
                }

                if (result.positionData) {
                    this.searchExam.positionData = result.positionData;
                  } else {
                    this.searchExam.positionData = null;
                  }

                if (result.status) {
                    this.searchExam.status = parseInt([result.status].toString());
                } else {
                    this.searchExam.status = null;
                }

                // TODO: Add more search
                result.positionData?.forEach((position) => {
                    positionIds.push(position.id);
                });
                if (positionIds.length > 0) {
                    this.searchExam.positionIds = positionIds;
                  } else {
                    this.searchExam.positionIds = null;
                  }
                this.searchExam.pageIndex = 1;
                this.courseIdsChecked = [];
                this.getListExam();
            }
        });

        modalDep.componentInstance.isCreateExam = true;
      }

    }

    goToExamDetail(exam) {
        this.$localStorage.store(LOCAL_STORAGE_KEY.SEARCH.EXAM_SEARCH, this.searchExam);
        this.router.navigate(['/admin/exam/detail', exam.id], {queryParams: {happening: exam.happening}});
    }

    checkDisable(c) {
      return (moment.unix(c.startdate).toDate() < this.currentDate && this.currentDate < moment.unix(c.enddate).toDate() && c.published === 1) || c.published === 1;
    }

    checkRoleFunction(userFunction:any,role:any):boolean{
      return USER_FUNCTIONS[userFunction].includes(role)
    }

    onChangeKeyword() {
      this.searchExam.pageIndex = 1;
      this.courseIdsChecked = [];
      this.getListExam();
    }
}
