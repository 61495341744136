<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Quản lý Danh mục chủ đề khóa học</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý Danh mục</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <mat-drawer-container autosize class="example-container">
          <mat-drawer #drawer class="example-sidenav" mode="side" opened>
            <div class="card card-default">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <h6>Danh mục chủ đề khóa học</h6>
                  </div>
                  <!-- <div class="col-5">
                    <button type="button" data-toggle="modal" data-target="#createTopic"
                            class="btn btn-primary rounded-0 float-right"
                            (click)="clear()">
                      Đặt lại tìm kiếm
                    </button>
                  </div> -->
                </div>
                <hr>
                <form>
                  <ejs-treeview #categoryTree [fields]="categoryTreeData"
                                (nodeSelecting)='nodeSelected($event)'></ejs-treeview>
                </form>
              </div>
            </div>
          </mat-drawer>
          <div class="example-sidenav-content">
            <div class="card card-default">
              <div class="card-body">
                <h6>Danh mục chủ đề khóa học</h6>
                <hr>
                <div class="row mb-2">
                  <div class="col-8 ">
                    <button type="button"
                            class="btn btn-default btn-lg mr-1"
                            matTooltip="Tạo mới"
                            matTooltipClass="tooltip-red"
                            (click)="onCreateCourseCategory()">
                      <i class="fas fa-plus mr-1"></i>
                    </button>
                    <button type="button" data-toggle="modal"
                            [disabled]="courseCateIdsChecked?.length === 0" matTooltip="Xóa"
                            matTooltipClass="tooltip-red" class="btn btn-default btn-lg"
                            (click)="onDeleteMultipleCourse()">
                      <i class="fas fa-trash"></i></button>
                      <span> Số danh mục đã chọn: {{courseCateIdsChecked?.length}}</span>
                  </div>
                  <!--Search -->
                  <!--Search -->
                  <div class="col-4 mt-2">
                    <div class="form-inline float-right" role="form">
                      <div class="form-group m-2">
                        <label class="mr-2">Tìm kiếm</label>
                        <input style="width:250px;"
                               type="search" class="form-control form-control-sm mr-2"
                               placeholder="Tên danh mục" [(ngModel)]="keyword" (change)="onChangeKeyWord()">
                      </div>
                    </div>
                  </div>
                </div>

                <table class="table table-hover" matSort (matSortChange)="sortData($event)">
                  <thead>
                  <tr>
                    <th>
                      <mat-icon class="custom-m-top">
                        <mat-checkbox class="example-margin" [(ngModel)]="completedAll"
                                      (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                      </mat-icon>
                    </th>
                    <th>STT</th>
                    <th mat-sort-header="name">Tên danh mục</th>
                    <th mat-sort-header="parentname">Danh mục cha</th>
                    <th mat-sort-header="description" style="width:220px;">Mô tả</th>
                    <th>Thao tác</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let category of categories; let i = index; ">
                    <td>
                      <mat-icon class="mt-1">
                        <mat-checkbox [(ngModel)]="category.completed"
                                      (change)="oncheckboxItem(category.id, $event.checked)"
                                      class="example-margin"></mat-checkbox>
                      </mat-icon>
                    </td>
                    <td>{{(pageIndex - 1) * pageSize + i + 1}}</td>
                    <td>{{category.name}}</td>
                    <td>{{category.parentname ? category.parentname : 'Top'}}</td>
                    <td>{{category.description}}</td>
                    <td class="project-actions">
                      <button type="button" class="btn btn-default btn-sm mr-1" matTooltip="Chỉnh sửa"
                              matTooltipClass="tooltip-red" (click)="onUpdateCourseCategory(category)">
                        <i class="fas fa-edit">
                        </i>
                      </button>
                      <button type="button" class="btn btn-default btn-sm mr-1" matTooltip="Xóa"
                              matTooltipClass="tooltip-red"
                              (click)="onDeleteSingleCourse(category.id)">
                        <i class="fas fa-trash">
                        </i>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <br/>
                <p *ngIf="categories?.length === 0"
                   class="text-center">{{ 'common.no_search_result' | translate}}</p>
              </div>

              <div class="card-footer text-center">
                <div class="row">
                  <mat-paginator showFirstLastButtons
                                 [length]="totalRecord"
                                 [pageSize]="pageSize"
                                 [pageIndex]="pageIndex - 1"
                                 (page)="changePage($event)"
                                 [hidePageSize]="false"
                                 [pageSizeOptions]="pageSizeOptions">
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </mat-drawer-container>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</div>
