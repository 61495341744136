import CommonUtil from 'src/app/shared/utils/common-util';
import { FileService } from './../../../shared/services/file.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-modal-course-preview',
  templateUrl: './modal-course-preview.component.html',
  styleUrls: ['./modal-course-preview.component.css']
})
export class ModalCoursePreviewComponent implements OnInit {

  courseInfo: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fileservice: FileService,
  ) {}

  ngOnInit(): void {
    this.courseInfo.img = this.fileservice.getFileUrl(this.courseInfo.contextid, this.courseInfo.filename, '/course/overviewfiles/');
  }

  convertTeacherName(teachers: any[]) {
    return CommonUtil.convertTeacherName(teachers);
  }

}
