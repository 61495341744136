<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Báo cáo tiến độ học viên</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']">CMS</a></li>
            <li class="breadcrumb-item active">Quản lý báo cáo</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <!--phan danh sach khoa hoc-->
      <div class="row">
        <mat-drawer-container autosize class="example-container">
          <mat-drawer #drawer class="example-sidenav" mode="side" opened>
            <div class="card card-default">
              <div class="card-body">
                <h6>Cây đơn vị</h6>
                <hr>
                <ejs-treeview #departmentTree
                              [fields]="departmentTreeData"
                              (nodeChecked)='nodeDepartmentChecked($event)'
                              [showCheckBox]='true'>
                </ejs-treeview>
              </div>
            </div>
            </mat-drawer>
            <div class="example-sidenav-content">
              <div class="card card-default">
                <div class="card-body">
                  <h6>Danh sách học viên</h6>
                  <hr>
                  <div class="row mb-2">
                    <div class="col-8 ">
                    </div>
                    <!--Search -->
                    <div class="col-4 mt-2">
                      <!-- <form class="form-inline float-right" role="form"> -->
                        <div class="form-group m-2">
                          <label class="mr-2">Tìm kiếm</label>
                          <input #keyword (change)="onSearch($event)" style="width:250px;" type="search" class="form-control form-control-sm mr-2" placeholder="Tên học viên">
                        </div>
                      <!-- </form> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-2">
                      <a class="btn btn-sm btn-success text-white" (click)="onExportLearningProgressStudents()"><i class="fas fa-file-excel mr-2"></i>Xuất File Excel </a>
                    </div>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>TT</th>
                          <th>Tên học viên</th>
                          <th>Email</th>
                          <th>Đơn vị</th>
                          <th class="text-center">Khóa học đăng ký</th>
                          <th class="text-center">Khóa học đã vào học</th>
                          <th class="text-center">Khóa học đã hoàn thành</th>
                          <th class="text-center">Thao tác</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let student of listStudent; let i = index">
                          <td>{{(searchUser.pageIndex - 1) * searchUser.pageSize + i + 1}}</td>
                          <td>{{ student.fullname }}</td>
                          <td>{{ student.email }}</td>
                          <td>{{ student.departmentname }}</td>
                          <td class="text-center">{{ student.assignedcourse }}</td>
                          <td class="text-center">{{ student.enrolledcourse }}</td>
                          <td class="text-center">{{ student.compeletedcourse }}</td>
                          <td class="text-center"><a [routerLink]="['/admin/report-student/detail',student.id]" class="btn btn-default btn-sm" (click)="goToDetail()"><i class="fas fa-eye"></i></a></td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <p *ngIf="listStudent?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
                  </div>
                  <div class="card-footer text-center"  *ngIf="listStudent?.length > 0">
                    <div class="row">
                      <mat-paginator showFirstLastButtons
                          [length]="totalRecord"
                          [pageSize]="searchUser.pageSize"
                          [pageIndex]="searchUser.pageIndex - 1"
                          (page)="changePage($event)"
                          [hidePageSize]="false"
                          [pageSizeOptions]="pageSizeOptions">
                      </mat-paginator>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              </mat-drawer-container>
      </div>
    </div>
  </section>
</div>

