<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Kết quả thi</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
            <li class="breadcrumb-item active">Báo cáo thi</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="form-group form-inline">
              <b>Xuất báo cáo</b>
              <br />
              <label for="">Chọn loại báo cáo</label>
              <select class="form-control form-control-sm ml-2" name="" id="" [(ngModel)]="reportType">
                <option value="">Theo phương pháp chấm điểm</option>
                <option [value]="1">Tất cả lần thi</option>
              </select>
              <select class="form-control form-control-sm ml-2" name="" id="" [(ngModel)]="examId">
                <option value="">Chọn kỳ thi</option>
                <option [value]="item.id" *ngFor="let item of courses">{{item.fullname}}</option>
              </select>
              <button class="btn btn-default btn-sm ml-2" (click)="onExport()" [disabled]="!examId"><i class="fas fa-file-excel"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
