import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // message source
  private messageSource = new BehaviorSubject(null);
  currentMessage = this.messageSource.asObservable();

  // message source multichoice
  private messageMultichoice = new BehaviorSubject(null);
  currentMessMulti = this.messageMultichoice.asObservable();

  // message source currentPage
  private messagePage = new BehaviorSubject(null);
  currentPage = this.messagePage.asObservable();

  // message source isTeacher
  private messageIsTeacher = new BehaviorSubject(null);
  isTeacher = this.messageIsTeacher.asObservable();

  // message source departments & positions of notify
  private messageNotify = new BehaviorSubject(null);
  isMessageNotify = this.messageNotify.asObservable();

  public assignTeacherSuccess = new Subject<boolean>();

  public searchKeyWord = new Subject<string>();

  constructor() {
    this.value = '';
  }

  // method change source message
  changeMessage(message: any, qtype?: string, isCreate?: boolean) {
    if (qtype && (qtype === 'multichoice' || qtype === 'multichoiceset')) this.messageMultichoice.next([message, isCreate]);
    else this.messageSource.next([message, isCreate]);
  }

  // method push current page
  currentPageMessage(pageIndex: any, pageSize: any) {
    if (pageIndex && pageSize) {this.messagePage.next([pageIndex, pageSize]);}
    else this.messagePage.next(null);
  }

  // isTeacherMessage(isTeacher: boolean) {
  //   if (isTeacher !== null && isTeacher !== undefined) {
  //       this.messageIsTeacher.next(isTeacher);
  //   }
  // }

  // object to {'departmentids': departmentids, 'positionids': positionids}
  changeMessageByNotify(message: any) {
    this.messageNotify.next(message);
  }

  ////
  private contentNoti: string;
  contentNotis = [];
  private nameNoti: string;
  nameNotis = [];

  private value: string;
  listeners = [];

  private exam: string;
  exams = [];

  nameChange(fn) {
    this.nameNotis.push(fn);
  }
  set setName(value: string) {
    this.nameNoti = value;
    this.nameNotis.forEach((fn) => {
      fn(value);
    });
  }

  onContentChange(fn) {
    this.contentNotis.push(fn);
  }
  set setContentNoti(value: string) {
    this.contentNoti = value;
    this.contentNotis.forEach((fn) => {
      fn(value);
    });
  }

  onDataChange(fn) {
    this.listeners.push(fn);
  }
  set setData(value: string) {
    this.value = value;
    this.listeners.forEach((fn) => {
      fn(value);
    });
  }

  onDataChangeExam(fn) {
    this.exams.push(fn);
  }
  set setDataExam(value: string) {
    this.exam = value;
    this.exams.forEach((fn) => {
      fn(value);
    });
  }



  ////
}
