import {environment} from '../../../environments/environment';
import {CustomUploadAdapterPlugin} from '../utils/upload-adapter';

export const CKEDITOR_CONFIG = {
  DEFAULT: {
    extraPlugins: [ CustomUploadAdapterPlugin],
  },
  NOT_UPLOAD: {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    },
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: 'en'
  }
};

