import {Component, EventEmitter, Input, isDevMode, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DepartmentService} from '../../../shared/services/department.service';
import {Department} from '../../../shared/model/department.model';
import {ToastrCustomService} from '../../../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import CommonUtil from '../../../shared/utils/common-util';
import {CustomValidators} from '../../../shared/utils/custom-validators';

@Component({
  selector: 'app-department-detail',
  templateUrl: './department-detail.component.html',
  styleUrls: ['./department-detail.component.css']
})

export class DepartmentDetailComponent implements OnInit {

  departmentNotType: boolean;
  idDepartmentNotType: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private departmentService: DepartmentService,
    private modalService: NgbModal,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
  ) {
  }

  @Input() departmentList: Department[] = [];
  @Input() department: Department;
  @Input() title;
  @Input() button;
  @Input() treeStatus:any;

  departmentMap = new Map();

  @Output() newDepartment = new EventEmitter<Department>();
  @Output() update = new EventEmitter<boolean>();

  createDepartmentForm = this.fb.group({
    name: ['', [CustomValidators.notBlankValidator,Validators.required]],
    id: [''],
    description: [''],
    // parentid: ['', Validators.required],
    code: ['', [CustomValidators.notBlankValidator, Validators.required]]
  });

  validateCodeDep = true;
  // parentId: any;
  ngOnInit(): void {
    if (this.department.id) {
    //  this.createDepartmentForm.value.parentid = this.department.parentid;

      this.departmentService.listDepartmentTree(this.department.id).subscribe((data) => {
        const childList = data.body;
        // console.log(data.body);
        // Loai bo don vi va cac don vi con khoi select box
        const newList = this.departmentList.filter((elem) => !childList.find(({ id }) => elem.id === id));
        this.departmentList = newList;
        this.resetData();
      });
    } else {
      this.resetData();
    }

    // if(this.department.parentid){
    //   this.createDepartmentForm.patchValue({
    //     parentid: this.department.parentid
    //   });
    // }
  }

  mustTypeNameDepartment(){
    if (this.createDepartmentForm.value.name.trim() === '') {
      this.createDepartmentForm.get('name').setErrors({invalid: true});
      this.departmentNotType = true;
    } else {
      this.createDepartmentForm.value.name = this.createDepartmentForm.value.name.trim();
      this.departmentNotType = false;
    }
  }

  mustTypeIdDepartment(){
    const code =  this.department.code;
    if (code == '') {
      this.validateCodeDep = true;
    } else if (!code.match(/^[A-Za-z0-9-]+$/g)){
      this.validateCodeDep = false;
    } else {
      this.validateCodeDep = true;
    }
  }

  createOrUpdateDepartment() {
    //if (!this.department.parentid) {
    if (this.createDepartmentForm.invalid) {
      // Danh dau la da cham de hien thi message loi
      CommonUtil.markFormGroupTouched(this.createDepartmentForm);
      return;
    }
    if (this.validateCodeDep == false){
      return;
    }
    // this.department.parentid =  this.createDepartmentForm.value.parentid;
    // }

    this.spinner.show();
    delete this.department.parentid;
    if (this.department.id) {
      // this.parentId = this.department.parentid;
      this.departmentService.updateDepartment(this.department).subscribe(res => {
          const department: Department = res.body;
          // console.log(department);
          this.newDepartment.emit(department);
          this.update.emit(true);
          this.toastrService.success('common.noti.update_success');
          this.close();
        },
        err => {
          this.toastrService.handlerError(err);
          this.spinner.hide();
        });
    } else {
      this.departmentService.createDepartment(this.department).subscribe(res => {
          const department: Department = res.body;
          if (isDevMode()) {
            console.log('Department: ', department);
          }
          this.newDepartment.emit(department);
          this.toastrService.success('common.noti.create_success');
          this.close();
        },
        err => {
          this.toastrService.handlerError(err);
          this.spinner.hide();
        });
    }
  }

  close(){
    this.spinner.hide();
    this.activeModal.dismiss('close');
  }

  resetData(){
    this.departmentMap.clear();

    this.departmentList.forEach(department => {
      this.departmentMap.set(department.id, department.name);
      // if (department.parentid !== null) {
      //   const parentName = this.departmentMap.get(department.parentid);
      //   let name = '';
      //   if (parentName) {
      //     name = parentName + ' / ' + department.name;
      //   } else {
      //     name = department.name;
      //   }
      //   this.departmentMap.set(department.id, name);
      // } else {
      //   this.departmentMap.set(department.id, department.name);
      // }
    });
  }
}
