<div class="">
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Quản lý khóa học</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#" routerLink="/admin">CMS</a></li>
              <li class="breadcrumb-item active">Quản lý khóa học</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card card-default">
              <div class="card-header">
                <div class="row">
                  <div class="col-6">
                    <!-- <h6>Danh sách khóa học</h6> -->
                    <div class="form-inline">
                      <button type="button" *ngIf="checkRoleFunction('CREATE_COURSE', role)" (click)="openCourseCreatePopup()" class="btn  btn-primary btn-flat mr-2"><i class="fas fa-plus mr-1"></i> <a class="text-white">Tạo khóa học </a></button>
                      <!-- <button type="button" [disabled]="courses.length === 0" class="btn  btn-default btn-flat  mr-2" matTooltip="Xuất File Excel" matTooltipClass="tooltip-red"><i class="fas fa-file-excel text-black-50 mr-1 font-size-18"></i></button> -->
                      <button type="button" *ngIf="checkRoleFunction('CREATE_COURSE', role)"  [disabled]="courseIdsChecked?.length === 0" class="btn btn-default btn-flat mr-1" matTooltip="Xóa" (click)="onDeleteMultipleCourse()" matTooltipClass="tooltip-red"><i class="fas fa-trash font-size-18"></i></button>
                      <span *ngIf="checkRoleFunction('DELETE_COURSE', role)">Số khóa học đã chọn: {{courseIdsChecked?.length}}</span>
                    </div>
                  </div>
                  <div class="col-6">
                    <form class="form-inline float-right">
                      <div class="form-group m-2">
                        <label class="mr-2">Tìm kiếm</label>
                        <input type="search" name="search" [(ngModel)]="searchCourse.search" class="form-control form-control-sm mr-1" style="width:250px;" (change)="onChangeKeyword()" placeholder="Tìm kiếm theo tên khóa học..."
                               autocomplete="off">
                      </div>
                      <div class="card-tools float-right">
                        <a class="btn btn-block btn-default btn-flat"
                           role="button"  (click)="openCourseSearchPopup()">
                          <i class="fa fa-search"></i> Tìm kiếm nâng cao
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="card-body" style="display: block;">
                <div class="table-responsive">
                  <table class="table table-hover" matSort (matSortChange)="sortData($event)">
                    <thead>
                    <tr class="bg-light">
                      <th *ngIf="checkRoleFunction('CREATE_COURSE', role)">
                        <mat-icon class="mt-1">
                          <mat-checkbox class="example-margin" [(ngModel)]="completedAll" [disabled]="courseCanChecked === 0" (change)="oncheckboxAll($event.checked)"></mat-checkbox>
                        </mat-icon>
                      </th>
                      <!-- fullname startdate enddate positionname requireenglish teachers -->
                      <th mat-sort-header="id">STT</th>
                      <th style="width:20%" mat-sort-header="fullname">Tên khóa học</th>
                      <th style="width:8%" mat-sort-header="startdate">Từ ngày</th>
                      <th style="width:8%" mat-sort-header="enddate">Đến ngày  </th>
                      <th style="width:12%" mat-sort-header="positionname">Chức danh</th>
                      <th style="width:10%" mat-sort-header="refdepartmentid">Đơn vị chủ trì</th>
                      <!-- <th mat-sort-header="certificate">Chứng chỉ</th> -->

                      <th style="width:10%" mat-sort-header="published">Hiển thị</th>
                      <th style="width:10%" mat-sort-header="status">Trạng thái</th>
                      <th style="width:10%"> Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                     <tr *ngFor="let item of courses; let i = index;">
                        <td *ngIf="checkRoleFunction('DELETE_COURSE', role)">
                          <mat-icon class="mt-1">
                            <mat-checkbox [disabled]="this.checkDisable(item) || (isManage && managedDepartmentId !== item.refdepartmentid)" [(ngModel)]="item.completed" (change)="oncheckboxItem(item.id, $event.checked)" class="example-margin"></mat-checkbox>
                          </mat-icon>
                        </td>
                        <td>{{(searchCourse.pageIndex - 1) * searchCourse.pageSize + i + 1}}</td>
                        <td><a (click)="goToCourseDetail(item.id)"><span matTooltip="{{item.fullname}}" matTooltipClass="tooltip-inner">{{item.fullname?.length <= 40 ? item.fullname : (item.fullname | slice:0:40) + '...' }}</span></a></td>
                        <td>{{getDateFromUnix(item.startdate) | date:'dd/MM/yyyy'}}</td>
                        <td>{{getDateFromUnix(item.enddate) | date:'dd/MM/yyyy'}}</td>
                        <td><span matTooltip="{{item.positionname}}" matTooltipClass="tooltip-inner">{{item.positionname?.length <= 10 ? item.positionname : (item.positionname | slice:0:12) + '...' }}</span></td>
                        <td><span matTooltip="{{item.departmentname}}" matTooltipClass="tooltip-inner">{{item.departmentname?.length <= 10 ? item.departmentname : (item.departmentname | slice:0:15) + '...' }}</span></td>
                        <!-- <td>{{item.engrequirementid}}</td> -->
                        <!-- <td><span matTooltip="{{item.teacherName}}" matTooltipClass="tooltip-inner">{{item.teacherName?.length <= 10 ? item.teacherName : (item.teacherName | slice:0:15) + '...' }}</span></td> -->
                        <td><span class="badge badge-{{item.published === 1 ? 'primary' : 'warning'}}">{{item.published === 1 ? 'Đang hiển thị' : 'Chưa hiển thị'}}</span></td>
                        <td><span *ngIf="item.published === 1" class="badge badge-{{item.style}}">{{item.status}}</span></td>
                        <td style="width:100px;">
                          <div *ngIf="isManage && managedDepartmentId !== item.refdepartmentid; else elseManage">
                            <button type="button" class="btn btn-default btn-sm mr-1 {{item.id}}" (click)="goToCourseDetail(item.id)" matTooltip="Xem" matTooltipClass="tooltip-red"><i class="fas fa-eye"></i></button>
                          </div>
                          <ng-template #elseManage>
                            <button type="button" class="btn btn-default btn-sm mr-1 {{item.id}}" (click)="goToCourseDetail(item.id)" matTooltip="Sửa" matTooltipClass="tooltip-red"><i class="fas fa-edit"></i></button>
                            <button *ngIf="checkRoleFunction('DELETE_COURSE', role)" type="button" class="btn btn-default btn-sm mr-1 {{item.id}}" [disabled]="this.checkDisable(item)" matTooltip="Xóa" matTooltipClass="tooltip-red" (click)="onDeleteSingleCourse(item.id)"><i class="fas fa-trash mr-1"></i></button>
                          </ng-template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <p *ngIf="courses?.length === 0" class="text-center">{{ 'common.no_search_result' | translate}}</p>
                </div>
                <div class="card-footer text-center">
                  <div class="row">
                    <mat-paginator showFirstLastButtons
                        [length]="totalRecord"
                        [pageSize]="searchCourse.pageSize"
                        [pageIndex]="searchCourse.pageIndex - 1 "
                        (page)="changePage($event)"
                        [hidePageSize]="false"
                        [pageSizeOptions]="pageSizeOptions">
                    </mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</div>

