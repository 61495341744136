import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrCustomService} from '../shared/services/toastr-custom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmModalComponent} from '../shared/modal/confirm-modal/confirm-modal.component';
import {TranslateService} from '@ngx-translate/core';
import CommonUtil from '../shared/utils/common-util';
import {LoginService} from '../shared/services/login.service';
import {InfoModalComponent} from '../shared/modal/info-modal/info-modal.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  usernameForm = this.fb.group({
    username: ['', Validators.required]
  });
  emailForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')]]
  });

  showEmailForm = true;

  username: any = 'username';

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrCustomService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private router: Router,
    private translateService: TranslateService,
    private loginService: LoginService
  ) {
    this.activatedRoute.queryParams.subscribe(value => {
      if (value) {
        this.username = value.username ? value.username : this.username;
      }
    });
  }

  ngOnInit(): void {
  }

  onSubmitUsernameForm(){
    if (this.usernameForm.invalid) { // show error
      CommonUtil.markFormGroupTouched(this.usernameForm);
      return;
    }
    const params = this.usernameForm.value;
    this.onSubmit(params);
  }

  onSubmitEmailForm(){
    if (this.emailForm.invalid) { // show error
      CommonUtil.markFormGroupTouched(this.emailForm);
      return;
    }
    const params = this.emailForm.value;
    this.onSubmit(params);
  }

  onSubmit(params){
    this.spinner.show();
    this.loginService.forgotPassword(params).subscribe(
      res => {
        this.spinner.hide();
        this.toastrService.success(`common.noti.update_success`);
        const modalDepSuccess = this.modalService.open(InfoModalComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static'
        });
        modalDepSuccess.componentInstance.title = this.translateService.instant('login.forgot_password_success.title');
        modalDepSuccess.componentInstance.body = this.translateService.instant('login.forgot_password_success.content');
        modalDepSuccess.componentInstance.confirmButton = this.translateService.instant('common.close');
      },
      error => {
        this.spinner.hide();
        this.toastrService.handlerError(error);
      }
    );
  }

  backToLogin() {
    this.router.navigate(['/home']);
  }

  isShowEmailForm(event) {
    this.showEmailForm = event.value;
  }
}
