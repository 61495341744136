<div class="container-fluid" *ngIf="!showCreateSingleSurvey">

  <div class="row">
    <div class="card card-default">
      <div class="card-body">
        <div class="row">
          <div class="col-5">
            <div class="form-inline" >
              <button type="button"  class="btn  btn-primary btn-flat m-2" (click)="showCreateForm()"  [hidden]="isManager" >
                <i class="fas fa-plus"></i><a class="text-white" >Tạo khảo sát</a>
              </button>
              <button type="button" class="btn btn-default btn-flat mr-1" matTooltip="Xuất File Excel" style="display: none;"
                      matTooltipClass="tooltip-red"><i class="fas fa-file-excel  mr-1"></i></button>
            </div>
          </div>
          <div class="col-7">
            <form class="form-inline float-right">
              <div class="form-group m-2">
                <label class="mr-2">Tìm kiếm</label>
                <input type="search" class="form-control mr-2"  placeholder="Từ khóa..." name="search" [(ngModel)]="searchSurvey.search"
                       (change)="searchTemplateSurvey(true)">
              </div>
            </form>
          </div>
          <div class="col-12 table-responsive">


           <table class="table table-hover" matSort (matSortChange)="sortData($event)">
              <thead>
              <tr>
                <th>STT</th>
                <th mat-sort-header="name">Tên khảo sát </th>
                <th>Trạng thái </th>
                <th *ngIf="!isManager">Thao tác</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let surveyTemplate of surveyTemplateList; let i = index;">
                <td>{{searchSurvey?.pageIndex * searchSurvey?.pageSize + (i + 1)}}</td>
                <td><a
                  [routerLink]="['/admin/survey/detail', surveyTemplate.questionnaireid,1]" (click)="goToDetail()">{{surveyTemplate.name}}</a>
                </td>
                <td>
                  <span class="badge badge-secondary" *ngIf="surveyTemplate.isactive == 0">chưa kích hoạt</span>
                  <span class="badge badge-success" *ngIf="surveyTemplate.isactive != 0">đã kích hoạt</span>
                </td>
                <td>
                  <ng-container *ngIf="!isManager">
                  <button type="button" matTooltip="Kích hoạt" matTooltipClass="tooltip-red" [disabled]="surveyTemplate.isactive != 0"
                          class="btn btn-default btn-sm mr-1"
                          (click)="publishSurvey(surveyTemplate.questionnaireid, surveyTemplate.qtype)"><i
                    class="fas fa-check"></i></button>

                  <button type="button" matTooltip="Chỉnh sửa" matTooltipClass="tooltip-red"
                          class="btn btn-default btn-sm mr-1"
                          (click)="editSurvey(surveyTemplate.questionnaireid)" [disabled]="surveyTemplate.isactive != 0">
                    <i class="fas fa-edit"></i>
                  </button>

                  <button type="button" matTooltip="Xóa" matTooltipClass="tooltip-red"
                          (click)="onDeleteSurvey(surveyTemplate.questionnaireid)" [disabled]="surveyTemplate.isactive != 0"
                          class="btn btn-default btn-sm mr-1">
                    <i class="fas fa-trash"></i>
                  </button>
                </ng-container>
                </td>
              </tr>
              <tr class="text-center" *ngIf="surveyTemplateList.length === 0"><td colspan="4">Không có khảo sát nào</td></tr>
              </tbody>
            </table>
          </div>

          <div class="card-footer text-center"  *ngIf="surveyTemplateList?.length > 0">
            <div class="row">
              <mat-paginator showFirstLastButtons
                [length]="totalRecord"
                [pageSize]="searchSurvey?.pageSize"
                (page)="pageEvent = $event;onChangePage($event)"
                [hidePageSize]="false"
                [pageSizeOptions]="pageSizeOptions"
                [pageIndex]="searchSurvey?.pageIndex">
              </mat-paginator>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-create-survey *ngIf="showCreateSingleSurvey" (showCreateSurvey)="hideCreateFormSurvey()"
                   [idSurveyUpdate]="idSurveyUpdate" [checkChooseTemplate]="true"></app-create-survey>
